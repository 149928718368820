import React from "react";
import PropTypes from "prop-types";

function Count({ notificationsCount }) {
	return <div className="count">{notificationsCount > 8 ? `9+` : notificationsCount}</div>;
}

Count.defaultProps = {
	notificationsCount: 0,
};

Count.propTypes = {
	notificationsCount: PropTypes.number,
};

export default Count;
