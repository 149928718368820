import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { ROUTES } from "Constants";
import { SessionContext } from "mobile/context/SessionContext";
import ToggleOption from "../modals/ToggleOption";
import RadioCheckToggle from "../partials/RadioCheckToggle";

function AttendanceModal({
	modal,
	setModal,
	activity,
	acfPageOptions,
	attendanceType,
	updatePlayerData,
	history,
}) {
	const [checkedAttendanceType, setCheckedAttendanceType] = useState(
		attendanceType?.Value
	);
	const [defaultAttendanceType, setDefaultAttendanceType] = useState(false);
	const { sessionAttendanceType, setSessionAttendanceType } =
		useContext(SessionContext);
	function onUpdateValue(name, value) {
		updatePlayerData({ [name]: value });
	}
	function navigateToSessionPage(eventLink) {
		if (defaultAttendanceType) {
			onUpdateValue("AttendanceType", String(checkedAttendanceType));
			localStorage.setItem("hasDefaultAttendanceType", true);
		}
		setSessionAttendanceType(checkedAttendanceType);
		history.push(eventLink);
	}

	const id = activity?.StoreTileId;

	return (
		!localStorage.getItem("hasDefaultAttendanceType") &&
		modal && (
			<ToggleOption
				modal={modal}
				setModal={setModal}
				title={acfPageOptions.acf?.attendance_modal?.title}
				checkboxValue={defaultAttendanceType}
				setCheckboxValue={setDefaultAttendanceType}
				toggleValue={checkedAttendanceType}
				setToggleValue={setCheckedAttendanceType}
				warning={acfPageOptions.acf?.attendance_modal?.warning}
				modalAction={() =>
					navigateToSessionPage(ROUTES.SESSION.replace(":tileId", id))
				}
				toggleLabelOne={acfPageOptions.acf?.attendance_modal?.virtual}
				toggleLabelTwo={acfPageOptions.acf?.attendance_modal?.in_person}
				checkboxLabel={acfPageOptions.acf?.attendance_modal?.label}
				btnLabel={acfPageOptions.acf?.attendance_modal?.button}
				subtitle={acfPageOptions.acf?.attendance_modal?.subtitle}
			/>
		)
	);
}

AttendanceModal.defaultProps = {
	modal: false,
	setModal: () => {},
	activity: {},
	acfPageOptions: null,
	attendanceType: null,
	updatePlayerData: () => {},
};

AttendanceModal.propTypes = {
	modal: PropTypes.bool,
	setModal: PropTypes.func,
	activity: PropTypes.object,
	acfPageOptions: PropTypes.object,
	attendanceType: PropTypes.string,
	updatePlayerData: PropTypes.func,
	history: PropTypes.object.isRequired,
};

export default AttendanceModal;
