/** @format */
import React from 'react';
import PropTypes from 'prop-types';

function Duration({ played }) {
	function padTo2Digits(num) {
		return num.toString().padStart(2, '0');
	}

	return (
		<div className="time">
			<span className="minutes">{padTo2Digits(Math.floor(played / 60))}</span>:
			<span className="seconds">{padTo2Digits(played % 60)}</span>
		</div>
	);
}
Duration.defaultProps = {
	played: 0,
};

Duration.propTypes = {
	played: PropTypes.number,
};
export default Duration;
