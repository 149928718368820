import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import NewQuestion from "containers/Questions/NewQuestion";
import QuestionZeroState from "components/ComPanel/Questions/QuestionZeroState";
import Question from "containers/Questions/Question"
import ChatBorder from "components/ComPanel/Chat/ChatBorder";
import { COMPANEL_TABS } from "Constants";

function QA({
	acfPageOptions,
	questions,
	fetchQuestions,
	activeTab,
	setActiveTab,
	channel,
	getQuestions,
}) {
	const questionsEnd = useRef(null);
	const questionContainer = useRef(null);
	const [sendAsQuestion, setSendAsQuestion] = useState(false);
	const [questionsRef, setQuestionsRef] = useState(questions);
	const [firstVisit, setFirstVisit] = useState(true);

	useEffect(() => {
		if (channel) {
			getQuestions(channel);
		}
	}, [activeTab, fetchQuestions]);

	useEffect(() => {
		const fetchQuestionsInterval = setInterval(() => {
			if (channel) {
				getQuestions(channel);
			}
		}, 30000);

		return () => {
			clearInterval(fetchQuestionsInterval);
		};
	}, []);

	let renderQuestions = <QuestionZeroState acfPageOptions={acfPageOptions} />;
	if (questions.length > 0) {
		renderQuestions = questions.map((question) => {
			return (
				<Question
					acfPageOptions={acfPageOptions}
					questionData={question}
					key={question.id}
				/>
			);
		});
	}

	function scrollToBottom() {
		document
			.querySelector(".questions-list")
			.scrollTo({
				top: document.querySelector(".questions-list").scrollHeight,
				behavior: "smooth",
			});
	}

	function canScroll() {
		if (questionContainer?.current) {
			const { scrollTop, scrollHeight } = questionContainer.current;

			return scrollTop >= scrollHeight / 3;
		}

		return false;
	}

	useEffect(() => {
		if (
			(activeTab === COMPANEL_TABS.QA &&
				canScroll() &&
				questionsRef.length !== questions.length) ||
			firstVisit
		) {
			setQuestionsRef(questions);
			setFirstVisit(false);
			scrollToBottom();
		}
	}, [questions]);

	return (
		<div className="qa">
			<div className="chat-questions-container">
				<div
					className="questions-list"
					id="questions-list"
					ref={questionContainer}
				>
					{renderQuestions}
					<div ref={questionsEnd} />
				</div>

				<ChatBorder />

				<div className="controls question-tab">
					<div className="question-ctn">
						<NewQuestion
							sendAsQuestion={sendAsQuestion}
							setSendAsQuestion={setSendAsQuestion}
							setActiveTab={setActiveTab}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

QA.defaultProps = {
	questions: [],
	fetchQuestions: false,
	acfPageOptions: null,
	activeTab: null,
	channel: null,
	getQuestions: () => {},
	setActiveTab: () => {},
};

QA.propTypes = {
	questions: PropTypes.array,
	fetchQuestions: PropTypes.bool,
	acfPageOptions: PropTypes.object,
	activeTab: PropTypes.string,
	channel: PropTypes.string,
	getQuestions: PropTypes.func,
	setActiveTab: PropTypes.func,
};

export default QA;
