import dayjs from 'dayjs';
import { toLocalTime } from 'utils/dateUtils';

/**
 * Returns every half hours on a day 
 */

export const getHalfHour = () => {
	const minHour = toLocalTime(dayjs()).startOf("day");

	const halfHourDay = 48;

	const halfHours = Array.from({ length: halfHourDay}, (_, item) => {

		return minHour.add(item * 30, "minutes")
	})

	return halfHours;
}