import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { ROUTES } from "Constants";
import Points from "mobile/components/profile/header/Points";
import UploadAvatar from "mobile/components/profile/header/UploadAvatar";
import Avatar from "mobile/components/containers/profile/Avatar";
import ProfileNavigation from "mobile/components/profile/header/ProfileNavigation";
import BadgesTab from "mobile/components/containers/profile/BadgesTab";
import LeaderboardTab from "mobile/components/containers/profile/LeaderboardTab";
//import logPageView from "utils/logPageView"; // Log PageView : managed in the tab itself.
import ProfileTab from "../components/containers/profile/ProfileTab";

function Profile({
	playfabId,
	updatePlayerData,
	uploadAvatar,
	getLeaderboardOnPosition,
	acfPageOptions,
	getFriendsList,
	navigation,
	leaderboardOnPosition,
	sessions,
}) {
	const { active_tab } = useParams();

	const nodeRef = React.useRef(null);

	useEffect(() => {
		getLeaderboardOnPosition(playfabId);
		getFriendsList();
	}, []);

	const [showUploadAvatarModal, setShowUploadAvatarModal] = useState(false);
	
	const profileTabs = [
		{
			id: 0,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_profile,
			is_displayed:
				navigation.acf?.navigation_acf?.profile?.display_tab_profile,
			url: "",
		},
		{
			id: 1,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_badges,
			is_displayed: navigation.acf?.navigation_acf?.profile?.display_tab_badges,
			url: ROUTES.PROFILE_BADGES,
		},
		{
			id: 2,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_leaderboard,
			is_displayed:
				navigation.acf?.navigation_acf?.profile?.display_tab_leaderboard,
			url: ROUTES.PROFILE_LEADERBOARD,
		},
		{
			id: 3,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_contact_list,
			is_displayed:
				navigation.acf?.navigation_acf?.profile?.display_tab_contact_list,
			url: ROUTES.PROFILE_CONTACTS,
		},
	];

	const [activeTab, setActiveTab] = useState(active_tab || 0);

	useEffect(() => {
		const defaultTab = profileTabs.find((o) => o.is_displayed === true);
		if (active_tab) {
			const tab = profileTabs.find(
				(profileTab) => profileTab.url === `/profile/${active_tab}`
			);
			setActiveTab(tab?.id || defaultTab?.id);
		}
		if (!active_tab && defaultTab) setActiveTab(defaultTab?.id);
	}, [navigation]);

	function renderProfileContent() {
		switch (activeTab) {
			case 0:
				return <ProfileTab />;
			case 1:
				return <BadgesTab />;

			case 2:
				return <LeaderboardTab />;

			default:
		}
		return null;
	}

	function onClickEditAvatar() {
		setShowUploadAvatarModal(true);
	}

	return (
		<div className="mobile-profile">
			<CSSTransition
				in
				appear
				timeout={1000}
				classNames="fade"
				nodeRef={nodeRef}
			>
				<div>
					<Container>
						<Row className="px-3 pt-4 align-items-center">
							<Col xs={9}>
								<Avatar onClickEditAvatar={() => onClickEditAvatar()} />
							</Col>
							<Col xs={3}>
								<Points
									acfPageOptions={acfPageOptions}
									leaderboardOnPosition={leaderboardOnPosition}
									sessions={sessions}
									playfabId={playfabId}
								/>
							</Col>
						</Row>
						<Row className="px-3">
							<ProfileNavigation
								navigation={navigation}
								profileTabs={profileTabs}
								activeTab={activeTab}
								setActiveTab={setActiveTab}
							/>
						</Row>
					</Container>
					{renderProfileContent()}
					<UploadAvatar
						show={showUploadAvatarModal}
						setShow={setShowUploadAvatarModal}
						uploadAvatar={uploadAvatar}
						updatePlayerData={updatePlayerData}
						acfPageOptions={acfPageOptions}
					/>
				</div>
			</CSSTransition>
		</div>
	);
}

Profile.defaultProps = {
	playfabId: "",
	updatePlayerData: () => {},
	uploadAvatar: () => {},
	getLeaderboardOnPosition: () => {},
	acfPageOptions: null,
	getFriendsList: () => {},
	navigation: null,
	leaderboardOnPosition: [],
	sessions: [],
};

Profile.propTypes = {
	playfabId: PropTypes.string,
	updatePlayerData: PropTypes.func,
	getLeaderboardOnPosition: PropTypes.func,
	uploadAvatar: PropTypes.func,
	acfPageOptions: PropTypes.object,
	getFriendsList: PropTypes.func,
	navigation: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	leaderboardOnPosition: PropTypes.array,
	sessions: PropTypes.array,
};

export default Profile;
