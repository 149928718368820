import React, { useRef } from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { AsStackUtility } from "as-stack-sdk/utility";
import { REACT_APP_LOGIN_REDIRECT_URL } from "Constants";
import Confirmation from "./Confirmation";

function LogOut({ acfPageOptions, showLogOutModal, setShowLogOutModal }) {
	const modalRef = useRef();

	function logOutPlatform() {
		const utility = new AsStackUtility();
		const sessionTicket = utility.GetCookie("SessionTicket");
		utility.SetCookie("SessionTicket", sessionTicket, -1);
		Sentry.setUser(null);
		window.location.href = REACT_APP_LOGIN_REDIRECT_URL;
	}

	function CloseModal(value) {
		if (value === true) {
			logOutPlatform();
		}
		setShowLogOutModal(false);
	}

	return (
		<CSSTransition
			in={showLogOutModal}
			timeout={600}
			appear
			nodeRef={modalRef}
			unmountOnExit
		>
			<Confirmation
				ref={modalRef}
				onAnswer={(e) => CloseModal(e)}
				text={
					acfPageOptions.acf?.global?.logout_message ||
					"Are you sure you want to log out?"
				}
				modal={showLogOutModal}
				setModal={setShowLogOutModal}
				acfPageOptions={acfPageOptions}
			/>
		</CSSTransition>
	);
}

LogOut.defaultProps = {
	acfPageOptions: null,
	showLogOutModal: false,
	setShowLogOutModal: () => {},
};

LogOut.propTypes = {
	acfPageOptions: PropTypes.object,
	showLogOutModal: PropTypes.bool,
	setShowLogOutModal: PropTypes.func,
};

export default LogOut;
