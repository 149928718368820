import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { statsSelector, statSelector } from 'selector/stats';
import { writeTelemetryEvent } from 'redux/playfab';
import AllChats from 'components/Chat/AllChats';
import {
	getRandomRooms,
	createRandomRooms,
	inviteToRandomRoom,
	getUsers,
	setActiveRoom,
	resetActiveRoom,
} from 'redux/rooms';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		acfPageOptions: state.wpcontent?.acfPageOptions,
		rooms: state.rooms,
		activeRoom: state.rooms?.activeRoom,
		users: state.rooms?.users,
		mod_status: statSelector(state, { statName: 'user_moderator_room' }),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		getRandomRooms,
		createRandomRooms,
		inviteToRandomRoom,
		getUsers,
		setActiveRoom,
		resetActiveRoom,
		writeTelemetryEvent,
	}, dispatch)
)(AllChats);
