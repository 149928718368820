import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Agenda from "mobile/pages/Agenda";
import { activitiesSelector } from "selector/catalog";
import {
	allUserTilesSelector,
	allTilesSelector,
	attendanceTypeSelector,
} from "selector/agenda";
import { channelsSelector } from "selector/channels";
import { regionSelector, playerLangGroupSelector } from "selector/player";
import {
	writePlayerEvent,
	purchaseTile,
	writeTelemetryEvent,
	getStoreLoadout,
	getItemInventory,
} from "redux/playfab";

function mapStateToProps(state) {
	return {
		activities: activitiesSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		lang: state.env.lang,
		myAgendaTiles: allUserTilesSelector(state),
		eventTiles: allTilesSelector(state),
		channels: channelsSelector(state),
		playerRegion: regionSelector(state),
		groupLang: playerLangGroupSelector(state),
		userAttendanceType: attendanceTypeSelector(state),
	};
}

export default withRouter(
	connect(mapStateToProps, (dispatch) =>
		bindActionCreators(
			{
				writePlayerEvent,
				purchaseTile,
				writeTelemetryEvent,
				getStoreLoadout,
				getItemInventory,
			},
			dispatch
		)
	)(Agenda)
);
