/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Chat from "containers/Chat/Chat";
import QA from "containers/ComPanel/QA";
import TabSwitcher from "components/ComPanel/TabSwitcher";
import Availabilities from "components/ComPanel/Availabilities";
import PanelCollapse from "components/ComPanel/PanelCollapse";
import { ComPanelContextProvider } from "components/ComPanel/Context/ComPanelContext";
import EmojiController from "components/Events/Emojis/EmojiController";
import { COMPANEL_TABS } from "Constants";

const ComPanel = ({
	activity,
	acfPageOptions,
	show_time_slot,
	show_chat,
	show_qa,
	tabClass,
	defaultTab,
	channel,
	withEmojis,
	collapsable,
	partnerData,
	partnerStatus,
	partnerName,
	groupLang,
}) => {
	const [activeTab, setActiveTab] = useState(defaultTab);
	const activityRightColumn = document.querySelector(".activity-right-column");
	const topLeft = document.querySelector(".top-left");

	useEffect(() => {
		if (!show_chat && !show_qa && !show_time_slot) {
			if (activityRightColumn) {
				activityRightColumn.classList.add("close-chat");
			}
			if (topLeft) {
				topLeft.style.width = "100%";
				topLeft.style.flex = "1";
			}
		}

		if (!collapsable) {
			if (activityRightColumn) {
				activityRightColumn.classList.remove("close-chat");
			}
		}

		if (defaultTab === "none") {
			if (topLeft) {
				topLeft.style.width = "100%";
				topLeft.style.flex = "1";
			}
			if (activityRightColumn) {
				activityRightColumn.classList.add("close-chat");
			}
		}

		setActiveTab(defaultTab);
	}, [defaultTab]);

	useEffect(() => {
		if (defaultTab === "none") {
			if (topLeft) {
				topLeft.style.width = "100%";
				topLeft.style.flex = "1";
			}
			if (activityRightColumn) {
				activityRightColumn.classList.add("close-chat");
			}
		}
	}, [activityRightColumn, topLeft]);

	function RenderActiveTab() {
		switch (activeTab) {
			case COMPANEL_TABS.CHAT:
				return (
					<Chat
						activity={activity}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						withEmojis={withEmojis}
						partnerStatus={partnerStatus}
						partnerName={partnerName}
						showPartnerStatus
					/>
				);

			case COMPANEL_TABS.QA:
				return (
					<QA
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						channel={channel}
					/>
				);

			case COMPANEL_TABS.AVAILABILITIES:
				return (
					<Availabilities
						acfPageOptions={acfPageOptions}
						partnerData={partnerData}
						groupLang={groupLang}
					/>
				);

			default:
				return null;
		}
	}

	return (
		<ComPanelContextProvider>
			{(show_chat || show_qa || show_time_slot) && (
				<div className="com-panel">
					<TabSwitcher
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						show_chat={show_chat}
						show_qa={show_qa}
						show_time_slot={show_time_slot}
						acfPageOptions={acfPageOptions}
						class={tabClass}
						activity={activity}
						partnerStatus={partnerStatus}
					/>

					{activeTab === "chat" ? (
						<Chat
							activity={activity}
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							withEmojis={withEmojis}
							partnerStatus={partnerStatus}
							partnerName={partnerName}
							showPartnerStatus
						/>
					) : (
						<QA
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							channel={channel}
						/>
					)}

					<PanelCollapse
						show_time_slot={show_time_slot}
						show_chat={show_chat}
						show_qa={show_qa}
						acfPageOptions={acfPageOptions}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						collapsable={collapsable}
						partnerStatus={partnerStatus}
					/>
					<div className="chat-left-column" />
				</div>
			)}
			{withEmojis && <EmojiController defaultTab={defaultTab} />}
		</ComPanelContextProvider>
	);
};

ComPanel.defaultProps = {
	activity: null,
	acfPageOptions: null,
	show_time_slot: null,
	show_chat: null,
	show_qa: null,
	tabClass: null,
	defaultTab: null,
	channel: null,
	withEmojis: null,
	collapsable: false,
	partnerData: null,
	partnerName: "",
	partnerStatus: false,
	groupLang: null,
};

ComPanel.propTypes = {
	activity: PropTypes.object,
	acfPageOptions: PropTypes.object,
	show_time_slot: PropTypes.bool,
	show_chat: PropTypes.bool,
	show_qa: PropTypes.bool,
	tabClass: PropTypes.string,
	defaultTab: PropTypes.string,
	channel: PropTypes.string,
	withEmojis: PropTypes.bool,
	collapsable: PropTypes.bool,
	partnerData: PropTypes.object,
	partnerName: PropTypes.string,
	partnerStatus: PropTypes.bool,
	groupLang: PropTypes.string,
};

export default ComPanel;
