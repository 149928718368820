//includes logo
//lang selector if more then one lang
import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";

import { PLAYFAB_EVENTS } from "Constants";
import dayjs from "dayjs";

function HomeHeader({ acfPageOptions, lang, writePlayerEvent, isDesktop }) {

	function dropdownItems(group) {
		if (lang === group.slug)
			return (
				<Dropdown.Item
					key={group?.value}
					onClick={async() => {
						dayjs.locale(group?.slug);
						await writePlayerEvent({
							name: PLAYFAB_EVENTS.change_region,
							body: {
								region: group?.value,
							},
						});
						window.location.replace("/");
					}}
					active
				>
					{group?.label}
				</Dropdown.Item>
			);

		return (
			<Dropdown.Item
				onClick={async() => {
					dayjs.locale(group?.slug);
					await writePlayerEvent({
						name: PLAYFAB_EVENTS.change_region,
						body: {
							region: group?.value,
						},
					});
					window.location.replace("/");
				}}
				key={group?.value}
			>
				{group?.label}
			</Dropdown.Item>
		);
	}

	return (
		<div
			className={`${
				isDesktop ? "d-home-header" : "p-1 container-fluid m-home-header"
			}`}
		>
			<div className="col-lg-12">
				<div className="ps-2 justify-content-between head-ctn">
					<div className="m-logo">
						<div className="img-wrap">
							{acfPageOptions.acf?.home?.brand_image && (
								<img
									src={acfPageOptions.acf?.home?.brand_image?.sizes?.large}
									alt=""
								/>
							)}
						</div>
					</div>

					{!isDesktop && (
						<Dropdown>
							<Dropdown.Toggle
								id="dropdown-button-dark-example1"
								variant="secondary"
								className="text-uppercase"
							>
								{lang}
							</Dropdown.Toggle>

							<Dropdown.Menu variant="dark">
								{acfPageOptions?.acf?.global?.group_language?.groups_list &&
									acfPageOptions?.acf?.global?.group_language?.groups_list.map(
										(group) => dropdownItems(group)
									)}
							</Dropdown.Menu>
						</Dropdown>
					)}
				</div>
			</div>
		</div>
	);
}

HomeHeader.defaultProps = {
	acfPageOptions: null,
	lang: "en",
	writePlayerEvent: () => {},
	isDesktop: false,
};

HomeHeader.propTypes = {
	acfPageOptions: PropTypes.object,
	lang: PropTypes.string,
	writePlayerEvent: PropTypes.func,
	isDesktop: PropTypes.bool,
};

export default HomeHeader;
