/** @format */

import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components/Buttons/Button";
import Field from "components/Partials/Field";
import TriviaIndexModal from "./TriviaIndex";

export default function Trivia({
	acfPageOptions,
	timerLength,
	points,
	answers,
	id,
	mission_id,
	contextId,
	sendEventInput,
	question,
	layout,
	fullScreen,
	icon,
	setPoints,
	setFirstRender,
}) {
	const [showModal, setShowModal] = useState(true);
	const [inputAnswer, setInputAnswer] = useState("");
	const [userChoice, setUserChoice] = useState(null);

	function onClickAnswer(answer) {
		const triggerValue = answer.trigger;
		const displayValue = answer.display;

		setUserChoice(answer);
		setTimeout(() => {
			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "answer to gamification",
				eventAction: mission_id + " - " + question,
				eventLabel: displayValue,
			});

			sendEventInput({
				mission_id,
				objective_id: id,
				'answer': triggerValue,
				instance_id: contextId,
			}).then((response) => {
				if (response.payload.code === 200) {
					const objective = response.payload.data.Result.ObjectiveRewards;

					const points = objective.find(
						(obj) => obj.dataKey === "points"
					).dataVal;

					setPoints(points);
					setFirstRender(false);
				}
			});
		}, 1200);
	}

	function sendWrittenAnswer(answer) {
		const writtenValue = answer.answer;
		setUserChoice(answer);

		setTimeout(() => {
			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "answer to gamification",
				eventAction: mission_id + " - " + question,
				eventLabel: writtenValue,
			});

			sendEventInput({
				mission_id,
				objective_id: id,
				'answer': writtenValue,
				instance_id: contextId,
			}).then((response) => {
				if (response.payload.code === 200) {
					const objective = response.payload.data.Result.ObjectiveRewards;

					const points = objective.find(
						(obj) => obj.dataKey === "points"
					).dataVal;

					setPoints(points);
					setFirstRender(false);
				}
			});
		}, 1200);
	}

	const renderWritten = () => {
		return (
			<div
				className={fullScreen ? "answers-wrapper-landscape" : "answers-wrapper"}
			>
				{(answers || []).map((answer) => {
					if (answer.image) {
						return (
							<div key={answer.trigger} className="answer-image-wrapper">
								<img src={answer.image} alt="answer" className="answer-image" />
							</div>
						);
					}

					return null;
				})}

				{(answers || []).map((answer) => {
					if (answer.answer) {
						return (
							<div key={answer.trigger}>
								<Field
									key="trivia"
									name="trivia"
									value={inputAnswer}
									onBlur={(value) => setInputAnswer(value)}
									icon={icon}
								/>

								{userChoice !== null && (
									<div className="right-answer">{answer.answer}</div>
								)}
								<Button
									action={() => sendWrittenAnswer(answer)}
									classes="secondary large"
									text={acfPageOptions.acf?.trivia?.send_answer}
								/>
							</div>
						);
					}

					return null;
				})}
			</div>
		);
	};

	const answersHasImage = (data) => {
		return data.reduce((_, curr) => {
			if (curr.image) {
				return true;
			}

			return false;
		});
	};

	const renderTriviaButtonStatus = (answer) => {
		if (!userChoice) {
			return "";
		}

		if (userChoice && !answer.point) {
			return "";
		}

		if (userChoice && answer.answer) {
			return "right-answer";
		}

		if (userChoice.trigger === answer.trigger) {
			return "wrong-answer";
		}

		return "";
	};

	const renderAnswers = () => {
		const triviaHasImage = answersHasImage(answers);

		return (
			<div
				className={
					triviaHasImage && fullScreen
						? "answer-image-container"
						: "answers-container"
				}
			>
				{!fullScreen && <p className="question">{question}</p>}

				{answers?.map((answer) => {
					if (answer.image) {
						return (
							<div
								key={answer.image}
								className={
									fullScreen
										? "answer-image-wrapper-landscape"
										: "answer-image-wrapper"
								}
							>
								{fullScreen && <p className="question">{question}</p>}

								<img src={answer.image} alt="answer" className="answer-image" />
							</div>
						);
					}
					return null;
				})}

				<div
					className={
						fullScreen ? "answer-wrapper-landscape" : "answers-wrapper"
					}
				>
					{answers?.map((answer) => {
						if (answer.image) {
							return null;
						}

						if (answer.image) {
							return null;
						}

						return (
							<div
								role="button"
								tabIndex="-1"
								onKeyDown={() => onClickAnswer(answer)}
								onClick={() => onClickAnswer(answer)}
								key={answer.trigger}
								className={`answered ${
									userChoice && renderTriviaButtonStatus(answer)
								}`}
							>
								{answer.display}
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	return (
		<div className="trivia-modal">
			{showModal && <div className="overlay" />}
			<TriviaIndexModal
				modal={showModal}
				setModal={setShowModal}
				onHide={() => setShowModal(false)}
				timerLength={timerLength}
				points={points}
				fullScreen={fullScreen}
				userChoice={userChoice}
				acfPageOptions={acfPageOptions}
			>
				{layout === "written" ? renderWritten() : renderAnswers()}
			</TriviaIndexModal>
		</div>
	);
}
Trivia.defaultProps = {
	acfPageOptions: null,
	answers: [],
	mission_id: "",
	question: "",
	sendEventInput: () => {},
	id: 0,
	contextId: "",
	layout: "",
	fullScreen: false,
	timerLength: 100,
	icon: null,
	setPoints: () => {},
	points: 0,
	setFirstRender: () => {},
};

Trivia.propTypes = {
	acfPageOptions: PropTypes.object,
	question: PropTypes.string,
	timerLength: PropTypes.number,
	points: PropTypes.number,
	sendEventInput: PropTypes.func,
	answers: PropTypes.array,
	mission_id: PropTypes.string,
	id: PropTypes.number,
	contextId: PropTypes.string,
	layout: PropTypes.string,
	fullScreen: PropTypes.bool,
	icon: PropTypes.string,
	setPoints: PropTypes.func,
	setFirstRender: PropTypes.func,
};
