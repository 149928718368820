import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { Modal } from "react-bootstrap";
import Button from "components/Buttons/Button";

const MAX_IMAGE_SIZE = 250;

function UploadAvatar({
	show,
	setShow,
	uploadAvatar,
	updatePlayerData,
	acfPageOptions,
}) {
	const [crop, setCrop] = useState({ aspect: 1 / 1, unit: "%", width: 50 });
	const [src, setSrc] = useState(null);
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);
	const [croppedImageBlob, setCroppedImageBlob] = useState(null);
	const [imageRef, setImageRef] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showWarning, setShowWarning] = useState(false);

	function closeModal() {
		setShow(false);
		setSrc(null);
		setImageRef(null);
		setIsLoading(false);
		setCroppedImageUrl(null);
		setCroppedImageBlob(null);
	}

	function onImageLoaded(image) {
		setImageRef(image);
	}

	function onCropComplete() {
		makeClientCrop();
	}

	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => setSrc(reader.result));
			reader.readAsDataURL(e.target.files[0]);
		}
	}

	async function makeClientCrop() {
		if (imageRef && crop.width && crop.height) {
			const blob = await getCroppedImg(imageRef, "newFile.jpeg");
			setCroppedImageBlob(blob);
			setCroppedImageUrl(window.URL.createObjectURL(blob));
		}
	}

	function getCroppedImg(image, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;

		const width = MAX_IMAGE_SIZE;

		setShowWarning(crop.width * scaleX < MAX_IMAGE_SIZE);

		canvas.width = width;
		canvas.height = width;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			width,
			width
		);

		return new Promise((resolve) => {
			canvas.toBlob(
				(blob) => {
					blob.name = fileName;
					resolve(blob);
				},
				"image/jpeg",
				0.8
			);
		});
	}

	const id = `picture_field_${Math.random()}`;

	function onClickAccept() {
		setIsLoading(true);

		uploadAvatar(croppedImageBlob)
			.then((response) => {
				updatePlayerData({
					Avatar: response.payload.data.AvatarUrl,
				}).then(() => {
					setShow(false);
				});
			})
			.catch(() => {
				setShow(false);
				setSrc(null);
				setImageRef(null);
				setIsLoading(false);
				setCroppedImageUrl(null);
				setCroppedImageBlob(null);
			});
	}

	function getLoading() {
		return (
			<div className="loadingWrapper">
				<div className="loading-inner-gif">
					<img alt='loading' src={acfPageOptions?.acf?.theme?.logo?.loading?.sizes?.large} className="loader" />
				</div>
				<span className="title">
					{
						acfPageOptions?.acf?.profile?.profile_tab
							?.uploading_profile_picture_label
					}
				</span>
			</div>
		);
	}

	function getContent() {
		if (isLoading) return getLoading();
		return (
			<>
				<div className="d-flex justify-content-between flex-column align-items-center">
					<span className="text-center p-2 title">
						{
							acfPageOptions?.acf?.profile?.profile_tab
								?.profile_picture_upload_modal_title
						}
					</span>
					<div className="d-flex flex-row justify-between mt-2">
						<div className="input d-flex  justify-content-center">
							<label
								className={` ${src ? "replace-upload" : "start-upload"}`}
								htmlFor={id}
								data-placeholder={
									acfPageOptions?.acf?.profile?.profile_tab
										?.start_uploading_profile_picture_label
								}
								data-replace={
									acfPageOptions?.acf?.profile?.profile_tab
										?.replace_profile_picture_label
								}
							>
								<input
									className={src ? "" : "empty"}
									type="file"
									onChange={onSelectFile}
									accept="image/*"
									id={id}
								/>
							</label>
						</div>
						{croppedImageUrl && (
							<div className="accept">
								{!showWarning && croppedImageUrl && (
									<button
										className="accept-button"
										type="button"
										onClick={() => onClickAccept()}
									>
										{
											acfPageOptions?.acf?.profile?.profile_tab
												?.accept_profile_picture_upload_label
										}
									</button>
								)}
							</div>
						)}
					</div>
				</div>
				{/* eslint-disable react/jsx-no-bind */}
				{src && (
					<ReactCrop
						src={src}
						crop={crop}
						onImageLoaded={onImageLoaded}
						onComplete={onCropComplete}
						onChange={(newCrop) => setCrop(newCrop)}
						keepSelection
						circularCrop
					/>
				)}
				{croppedImageUrl && (
					<div className="bottom">
						{showWarning && (
							<div className="warning">
								{
									acfPageOptions?.acf?.profile?.profile_tab
										?.profile_picture_crop_warning
								}
							</div>
						)}
					</div>
				)}
			</>
		);
	}

	const modalRef = useRef();

	return (
		show && (
			<div>
				<Modal
					show={show}
					onHide={() => closeModal()}
					animation={false}
					aria-labelledby="contained-modal-title-vcenter"
					centered
					className="upload-avatar"
				>
					<Modal.Header closeButton />
					<Modal.Body>{getContent()}</Modal.Body>
				</Modal>
			</div>
		)
	);
}

UploadAvatar.defaultProps = {
	uploadAvatar: () => {},
	updatePlayerData: () => {},
	show: false,
	setShow: () => {},
	acfPageOptions: null,
};

UploadAvatar.propTypes = {
	uploadAvatar: PropTypes.func,
	updatePlayerData: PropTypes.func,
	show: PropTypes.bool,
	setShow: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default UploadAvatar;
