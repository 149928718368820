import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActivityFooter from 'components/Activity/ActivityFooter';
import { notificationSelector } from 'selector/inventory';
import { statSelector } from 'selector/stats';
import { regionSelector, userNameSelector } from 'selector/player';
import { addSeenNotification } from 'redux/inventory';
import { consumeInventoryItem } from 'redux/playfab';
import { clearActivityFooterContent } from 'redux/wpcontent';

function mapStateToProps(state) {
	return {
		notification: notificationSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		loadedWpContent: state.wpcontent.activityFooterContent,
		xp: statSelector(state, { statName: 'xp' }),
		region: regionSelector(state),
		username: userNameSelector(state),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		addSeenNotification,
		consumeInventoryItem,
		clearActivityFooterContent,
	}, dispatch)
)(ActivityFooter);
