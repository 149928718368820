import { REACT_APP_AS_STACK_APP_ID, REACT_APP_STACK_API, REACT_APP_MANAGER_API } from 'Constants.js';
import { AsStackUtility } from './utility.js';

class AsStackSDK {
	appId = null;

	utility = null;

	#sessionTicket = null;

	#playfabId = null;

	#entityToken = null;

	constructor(appId) {
		this.appId = appId;
		this.utility = new AsStackUtility();
		this.#sessionTicket = this.utility.GetCookie('SessionTicket');
		this.#playfabId = this.utility.GetCookie('PlayFabId');
		this.#entityToken = this.utility.GetCookie('EntityToken');
	}

	GetCallParams(api, endpoint, data) {
		const escape = '?stackauth=9fa6f124';
		const url = REACT_APP_STACK_API + '/' + api + '/' + endpoint + escape;
		const formData = this.utility.ToUrlEncoded(data);
		const headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('X-App-Id', this.appId);
		if (this.#sessionTicket) {
			headers.append('X-Authentication', this.#sessionTicket);
		}
		return {
			url,
			formData,
			headers,
		};
	}

	GetCallTelemetryParams(api, endpoint, data) {
		const escape = '?stackauth=9fa6f124';
		const url = REACT_APP_STACK_API + '/' + api + '/' + endpoint + escape;
		const formData = this.utility.ToUrlEncoded(data);
		const headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('X-App-Id', this.appId);
		if (this.#entityToken) {
			headers.append('X-EntityToken', this.#entityToken);
		}
		return {
			url,
			formData,
			headers,
		};
	}

	GetCallManagerParams(api, endpoint, data) {
		// This was only for the hive calls and was block the call with the invalid param error
		// const escape = '?stackauth=9fa6f124';
		const url = REACT_APP_MANAGER_API + '/' + api + '/' + endpoint;
		const formData = this.utility.ToUrlEncoded(data);
		const headers = new Headers();
		// This needed to be form encoded like all the other calls be we switched to json
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('X-App-Id', this.appId);
		if (this.#sessionTicket) {
			headers.append('X-Authentication', this.#sessionTicket);
		}
		return {
			url,
			formData,
			headers,
		};
	}

	IsAuthenticated() {
		return this.#sessionTicket;
	}

	GetPlayFabId() {
		return this.#playfabId;
	}

	GetSessionTicket() {
		return this.#sessionTicket;
	}

	GetEntityToken() {
		return this.#entityToken;
	}

	Auth(endpoint, data) {
		return new Promise((resolve, reject) => {
			this.QueryStack('auth', endpoint, data).then((rsp) => {
				if (rsp.data.LoginResult) {
					this.#sessionTicket = rsp.data.LoginResult.SessionTicket;
					this.#entityToken = rsp.data.LoginResult.EntityToken.EntityToken;
					this.#playfabId = rsp.data.LoginResult.PlayFabId;
					this.utility.SetCookie('SessionTicket', this.#sessionTicket, 1);
					this.utility.SetCookie('EntityToken', this.#entityToken, 1);
					this.utility.SetCookie('PlayFabId', this.#playfabId, 365);
				}
				return rsp;
			}).then(resolve, reject);
		});
	}

	Client(endpoint, data) {
		return new Promise((resolve, reject) => {
			this.QueryStack('client', endpoint, data).then(resolve, reject);
		});
	}

	Telemetry(endpoint, data) {
		return new Promise((resolve, reject) => {
			this.QueryTelemetry('client', endpoint, data).then(resolve, reject);
		});
	}

	Analytics(endpoint, data) {
		return new Promise((resolve, reject) => {
			this.QueryStack('analytics', endpoint, data).then(resolve, reject);
		});
	}

	Service(endpoint, data) {
		return new Promise((resolve, reject) => {
			this.QueryStack('service', endpoint, data).then(resolve, reject);
		});
	}

	Manager(endpoint, data) {
		return new Promise((resolve, reject) => {
			this.QueryManager('client', endpoint, data).then(resolve, reject);
		});
	}

	QueryStack(api, endpoint, data) {
		const {
			url,
			formData,
			headers,
		} = this.GetCallParams(api, endpoint, data);
		return new Promise((resolve, reject) => {
			fetch(url, {
				method: 'POST',
				body: formData,
				headers,
			}).then(
				response => response.json()
			).then((rsp) => {
				resolve(rsp);
			}).catch((e) => {
				reject(e);
			});
		});
	}

	QueryTelemetry(api, endpoint, data) {
		const {
			url,
			formData,
			headers,
		} = this.GetCallTelemetryParams(api, endpoint, data);
		return new Promise((resolve, reject) => {
			fetch(url, {
				method: 'POST',
				body: formData,
				headers,
			}).then(
				response => response.json()
			).then((rsp) => {
				resolve(rsp);
			}).catch((e) => {
				reject(e);
			});
		});
	}

	QueryManager(api, endpoint, data) {
		const {
			url,
			formData,
			headers,
		} = this.GetCallManagerParams(api, endpoint, data);
		return new Promise((resolve, reject) => {
			fetch(url, {
				method: 'POST',
				body: formData,
				headers,
			}).then(
				response => response.json()
			).then((rsp) => {
				resolve(rsp);
			}).catch((e) => {
				reject(e);
			});
		});
	}
}
export const instance = new AsStackSDK(REACT_APP_AS_STACK_APP_ID);
export { AsStackSDK };