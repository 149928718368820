import { createSelector } from '@reduxjs/toolkit';

export const regionsStateSelector = (state) => state.wpcontent?.regions || [];
export const departmentsStateSelector = (state) => state.wpcontent?.departments || [];

/**
*Get the regions from the WordPress sorted for the player's profile
*/
export const regionsOptionsSelector = createSelector([
	regionsStateSelector,
], (regions) => {
	return regions.map(region => ({
		label: region.title.rendered,
		value: region.slug,
	})).sort((a, b) => a.label.localeCompare(b.label));
});

/**
*Get the departments from the Wordpress sorted for the player's profile
*/
export const departmentsOptionsSelector = createSelector([
	departmentsStateSelector,
], (departments) => {
	return departments.map(department => ({
		label: department.title.rendered,
		value: department.slug,
	})).sort((a, b) => a.label.localeCompare(b.label));
});
