import { createSelector } from '@reduxjs/toolkit';
import { now, toUTC, toUnix } from 'utils/dateUtils';

export const openingDateStateSelector = (state) => state.wpcontent?.acfPageOptions?.acf?.global?.opening_timestamp;
export const closingDateStateSelector = (state) => state.wpcontent?.acfPageOptions?.acf?.global?.closing_timestamp;

/**
*Return if the platform is closed or open, Responsible of showing or no the ThankYou component
*The time is set in the Wordpress ACF
*/
export const isPlatformClosedSelector = createSelector([
	openingDateStateSelector,
], (openingDate) => {
	if (!openingDate) {
		return null;
	}
	const currentDate = toUTC(now()).format('YYYY-MM-DD HH:mm:ss');
	return toUnix(currentDate) < toUnix(openingDate);
});

/**
*Return if the Event is over and the platform is closed, Responsible of showing the EventOver component
*/
export const isEventOverSelector = createSelector([
	closingDateStateSelector,
], (closingDate) => {
	if (!closingDate) {
		return null;
	}
	const currentDate = toUTC(now()).format('YYYY-MM-DD HH:mm:ss');
	return toUnix(currentDate) > toUnix(closingDate);
});
