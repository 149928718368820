import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Highlighter from "react-highlight-words";
import ProfilePicture from 'assets/placeholders/profile-picture.png';
import Button from "components/Buttons/Button";

function ContactModalSearch({
	acfContactList,
	acfIcons,
	usersList,
	showSearchModal,
	setShowSearchModal,
	setUsersSearchList,
	usersSearchList,
	setUserSelected,
	sendFriendRequest,
	getFriendsList,
}) {
	const [searchField, setSearchField] = useState("");
	const ADDED_STATUS = acfContactList?.status_added_label;
	const REQUESTED_STATUS = acfContactList?.status_pending_label;
	const INVITED_STATUS = acfContactList?.status_invite_label;

	function getStatusButton(user) {
		const { status  } = user;
		let statusClass = INVITED_STATUS === status && "primary";

		if (status === ADDED_STATUS) {
			statusClass = "tertiary";
		} else if (status === REQUESTED_STATUS) {
			statusClass = "secondary";
		}

		return (
			<Button
				action={async() => {
					if (INVITED_STATUS === status) {
						await sendFriendRequest({
							FriendId: user.playfabId,
							TokenItemId: acfContactList?.friendship_token,
						});
						getFriendsList();
					}
				}}
				text={status.toUpperCase()}
				classes={`${statusClass} large`}
				orientation="right"
			/>
		);
	}

	function onSearchUser(value) {
		const results = [];

		// TODO GET ALL THE INFORMATION FROM THE USERS
		usersList.find((user) => {
			if (user.displayName.toLowerCase().includes(value)) {
				results.push(user);
			}

			return false;
		});

		setUsersSearchList(results);
	}

	function getHighlightedLabel(searchWords, textToHighlight) {
		return (
			<Highlighter
				highlightClassName="highlight"
				searchWords={searchWords}
				autoEscape
				textToHighlight={textToHighlight}
			/>
		);
	}

	return (
		<Modal
			centered
			show={showSearchModal}
			onHide={() => {
				setShowSearchModal(false);
				setUsersSearchList(usersList);
			}}
		>
			<Modal.Body id="modal-body">
				<div className="modal-content">
					<div className="modal-search-container">
						<input
							type="text"
							className=""
							onChange={(e) => {
								if (e.target.value.length >= 1) {
									onSearchUser(e.target.value.toLowerCase());
									setSearchField(e.target.value);
								} else {
									setSearchField("");
									setUsersSearchList(usersList);
								}
							}}
						/>
						<img
							src={acfIcons?.magnifying_glass?.url}
							alt="search user"
							className="search-icon"
						/>
						<div className="modal-search-counter">
							<h5>{usersSearchList.length} results</h5>
						</div>
						<div className="modal-search-results">
							{usersSearchList.map((user) => {
								return (
									<div className="modal-search-user" key={user.playfabId}>
										<button
											type="button"
											className="action-button"
											onClick={() => {
												setUserSelected(user);
												setShowSearchModal(false);
												setUsersSearchList(usersList);
											}}
										>
											<div className="user-info-container">
												<img
													className="profile-image"
													src={user.avatar || ProfilePicture}
													alt="profile"
												/>
												<div className="detail-info">
													<h5 className="name">
														{getHighlightedLabel(
															[searchField],
															user.displayName
														)}
													</h5>
													<div className="user-info">
														<span>{user.location}</span>
														<span>{user.company}</span>
														<span>{user.job}</span>
													</div>
												</div>
											</div>
											<div className="user-info-action">
												{getStatusButton(user)}
											</div>
										</button>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

ContactModalSearch.propTypes = {
	acfContactList: PropTypes.object,
	acfIcons: PropTypes.object,
	usersList: PropTypes.array,
	showSearchModal: PropTypes.bool,
	setShowSearchModal: PropTypes.func,
	setUsersSearchList: PropTypes.func,
	usersSearchList: PropTypes.array,
	setUserSelected: PropTypes.func,
	sendFriendRequest: PropTypes.func,
	getFriendsList: PropTypes.func,
};

ContactModalSearch.defaultProps = {
	acfContactList: {},
	acfIcons: PropTypes.object,
	usersList: [],
	showSearchModal: false,
	setShowSearchModal: () => {},
	setUsersSearchList: () => {},
	usersSearchList: [],
	setUserSelected: () => {},
	sendFriendRequest: () => {},
	getFriendsList: () => {},
};

export default ContactModalSearch;