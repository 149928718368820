/**
 * @format
 */

import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import uuid from "react-uuid";
import parse from "html-react-parser";
import { ROUTES } from "Constants";
import Layout from "../Layout/Title";

function Privacy({ privacyData, fetchWPNoticesData, playerGroup, pageSlug }) {
	const [dataLoaded, setDataLoaded] = useState(false);
	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		};

		return fetchWPNoticesData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	return (
		dataLoaded && (
			<Layout
				titleSlot={privacyData?.acf?.privacy_policy?.page_title}
				pageClass="terms-desktop"
				isLoading={
					!dataLoaded ||
					(dataLoaded &&
						Object.keys(privacyData).length === 0 &&
						privacyData.constructor === Object)
				}
				backText="Back"
				backLink={ROUTES.NOTICES}
			>
				<div className="terms">
					<Container className="notices-anchors">
						<div className="terms-content">
							{parse(privacyData?.acf?.privacy_policy?.description)}
						</div>
						<div>
							<ul className="terms-list list-unstyled">
								{privacyData?.acf?.privacy_policy?.blocks &&
									privacyData.acf.privacy_policy.blocks.map(
										(termsTitles, i) => (
											<li className="rounded p-2 mb-2">
												<a
													href={`#privacy_policy-${i}`}
													key={termsTitles.title}
													className=""
												>
													{termsTitles.title}
												</a>
											</li>
										)
									)}
							</ul>
						</div>
					</Container>
					<Container>
						{privacyData?.acf?.privacy_policy?.blocks &&
							privacyData.acf.privacy_policy.blocks.map((termsTexts, i) => (
								<Fragment key={uuid()}>
									<div className="term-wrapper" id={`privacy_policy-${i}`}>
										<h4 className="py-3">{termsTexts.title}</h4>
										{parse(termsTexts.text)}
									</div>
								</Fragment>
							))}
					</Container>
				</div>
			</Layout>
		)
	);
}

Privacy.defaultProps = {
	privacyData: null,
	pageSlug: null,
	fetchWPNoticesData: () => {},
	playerGroup: null,
};

Privacy.propTypes = {
	privacyData: PropTypes.object,
	fetchWPNoticesData: PropTypes.func,
	playerGroup: PropTypes.string,
	pageSlug: PropTypes.string,
};

export default Privacy;
