/** @format */

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Tile from "components/Partials/LeftPanel/Tile";
import { setActiveDay } from "redux/side_agenda";
import { myAgendaDaysSelector, myAgendaActiveDayTilesSelector } from 'selector/myAgenda';
import {
	writePlayerEvent,
	getStoreLoadout,
	getItemInventory,
} from "redux/playfab";

function mapStateToProps(state) {
	return {
		...state.side_agenda,
		days: myAgendaDaysSelector(state),
		tiles: myAgendaActiveDayTilesSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			setActiveDay,
			writePlayerEvent,
			getStoreLoadout,
			getItemInventory,
		},
		dispatch
	)
)(Tile);
