/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const ColLg = ({
	children,
}) => {
	return (
		<div className="col-lg">
			{children}
		</div>
	);
}

ColLg.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ColLg;
