import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import parse from "html-react-parser";
import Avatar from "components/Partials/Avatar";
import { DEFAULT_USERNAME, ROUTES } from "Constants";
import Button from 'components/Buttons/Button'

function ProfileCard({ playfabId, playerData, setActiveProfileCard, acfPageOptions }) {
	const ref = useRef();
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		return () => {
			setActiveProfileCard(null);
		};
	}, []);

	useEffect(() => {
		if (playfabId && playerData) {
			setVisible(true);
		}

		return () => {
			setVisible(false);
		};
	}, [playfabId, playerData]);

	function onClickClose() {
		setVisible(false);

		setTimeout(() => {
			setActiveProfileCard(null);
		}, 610);
	}

	return (
		playerData && (
			<CSSTransition
				key={playfabId}
				in={visible}
				timeout={600}
				appear
				unmountOnExit
				nodeRef={ref}
			>
				<div className="profile-card" ref={ref}>
					<button type="button" className="close" onClick={onClickClose}>
						<img src={acfPageOptions?.acf?.theme?.icon?.general?.exit?.image1?.url} alt="Close" />
					</button>
					<div className="profile-container">
						<div className="left-section">
							<div className="avatar">
								<Avatar avatar={playerData.data?.Avatar || null} />
							</div>
						</div>
						<div className="right-section">
							<div className="name">
								{playerData?.displayName && parse(playerData?.displayName || DEFAULT_USERNAME)}
							</div>
							{playerData?.data?.Location &&
								<div className="location">
									{playerData?.data?.Location}
								</div>
							}

							<Button
								link={ROUTES.PUBLIC_PROFILES.replace(":profileId", playfabId)}
								classes="primary small"
								text='View profile'
							/>
						</div>
					</div>
				</div>
			</CSSTransition>
		)
	);
}

ProfileCard.propTypes = {
	playerData: PropTypes.object,
	playfabId: PropTypes.string,
	setActiveProfileCard: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

ProfileCard.defaultProps = {
	playerData: null,
	playfabId: "",
	setActiveProfileCard: () => {},
	acfPageOptions: () => {},
};

export default ProfileCard;
