import { createSlice } from "@reduxjs/toolkit";
import * as MicrosoftTeams from "@microsoft/teams-js";
import { createDebouncedAsyncAction } from "utils/reduxhelpers";
import debugInfos from "utils/debugInfos";
import { setAuthStep } from "./auth";

MicrosoftTeams.app.initialize();

const timeout = 2000;

// window.mt = MicrosoftTeams; //TODO: remove if not required? wasn't working... as it was trying to attach to window before it existed

export const fetchTeamsData = createDebouncedAsyncAction(
	"teams/fetch",
	(data, { dispatch }) => {
		return new Promise((resolve, reject) => {
			let resolved = false;
			if (window.location.href.indexOf("localhost") === -1) {
				MicrosoftTeams.app.getContext((context) => {
					if (!resolved) {
						dispatch(setAuthStep("teamsLoading"));
						dispatch(setAuthStep("teamsAuth"));

						if (!context.userObjectId) {
							dispatch(setAuthStep("teamsADIDMissing"));
							resolved = true;
							reject(context);
						} else {
							resolve(context);
							resolved = true;
						}
					}
				});

				setTimeout(() => {
					if (!resolved) {
						dispatch(setAuthStep("teamsNoContext"));
						resolved = true;
					}
				}, 3000);
			} else {
				setTimeout(() => {
					if (!resolved) {
						const infos = debugInfos;

						if (localStorage.getItem("override_teams_id")) {
							infos.userObjectId = localStorage.getItem("override_teams_id");
						}

						if (!infos.userObjectId) {
							dispatch(setAuthStep("teamsADIDMissing"));
							resolved = true;
							reject(infos);
						} else {
							dispatch(setAuthStep("teamsAuth"));
							resolve(debugInfos);
							resolved = true;
						}
					}
				}, timeout);
			}
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				...action.payload,
			};
		},
	}
);

const teams = createSlice({
	name: "teams",
	reducers: {},
	extraReducers: {
		...fetchTeamsData.reducers,
	},
	initialState: {},
});

export default teams;