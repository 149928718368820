import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import RandomRoomSingle from 'components/Chat/RandomRoomSingle';

import { chatJoinRoom, chatLeaveRoom, clearMessages } from 'redux/chat';
import { writeTelemetryEvent, getJitsiJwt } from 'redux/playfab';
import { getRandomRooms } from 'redux/rooms';
import { userNameSelector } from 'selector/player';
import { randomRoomAllowSelector, randomRoomSingleSelector } from 'selector/randomRoom';

function mapStateToProps(state, props) {
	const room = randomRoomSingleSelector(state, props);
	return {
		username: userNameSelector(state),
		playfabId: state.playfab?.PlayFabId,
		rooms: state?.rooms?.List,
		activeRoom: room,
		canJoin: randomRoomAllowSelector(state, props),
		acfPageOptions: state.wpcontent.acfPageOptions,
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		chatJoinRoom,
		chatLeaveRoom,
		clearMessages,
		writeTelemetryEvent,
		getJitsiJwt,
		getRandomRooms,
	}, dispatch)
)(RandomRoomSingle));
