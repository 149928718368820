import React from "react";
import PropTypes from "prop-types";

function WelcomeMessage({ message }) {
	return (
		<div className="d-flex justify-content-center w-100 text-center welcome-message">
			<h4 className="w-75">{message}</h4>
		</div>
	);
}

WelcomeMessage.defaultProps = {
	message: "",
};

WelcomeMessage.propTypes = {
	message: PropTypes.string,
};

export default WelcomeMessage;
