import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ProfilePicture from "assets/icons/WL_icon-avatar.png";

function MentionsSuggestions({ messageAuthors, inputMessage, setInputMessage }) {
	const input = document.getElementById("input");
	const usersSuggestions = document.getElementById("users");
	function getMatches(nameInput) {
		return messageAuthors.filter((name) => name.includes(nameInput));
	}

	function createList(suggestions) {
		suggestions.innerHTML = "";

		const list = document.createElement("ul");
		list.classList = "list";

		return list;
	}

	function hide(element) {
		element.style.display = "none";
	}

	function show(element) {
		element.style.display = "block";
	}

	function createImage() {
		const image = document.createElement("img");

		image.src = ProfilePicture;
		image.width = 15;
		image.height = 15;

		return image;
	}

	function createItem(match) {
		const node = document.createElement("li");
		node.classList = "item";
		const image = createImage();
		const name = document.createElement("p");
		name.innerHTML = match;
		node.append(image);
		node.append(name);
		node.onclick = () => {
			const words = input.value.split(" ");
			words.pop();
			setInputMessage(`${words.join(" ")} ${match} `);
			hide(usersSuggestions);
			input.focus();
		};

		return node;
	}

	useEffect(() => {
		if (usersSuggestions && messageAuthors) {
			const words = inputMessage.split(" ");
			const lastWord = words[words.length - 1];
			if (lastWord.includes("@")) {
				const matches = getMatches(lastWord.slice(1));
				const list = createList(usersSuggestions);

				matches.forEach((match) => {
					list.appendChild(createItem(match));
				});

				usersSuggestions.append(list);
				show(usersSuggestions);
			} else {
				hide(usersSuggestions);
			}
		}
	}, [inputMessage, usersSuggestions]);

	return (
		<div id="users" className="users">
			<ul className="list " />
		</div>
	);
}

MentionsSuggestions.defaultProps = {
	messageAuthors: null,
	inputMessage: null,
	setInputMessage: () => {},
};

MentionsSuggestions.propTypes = {
	messageAuthors: PropTypes.array,
	inputMessage: PropTypes.string,
	setInputMessage: PropTypes.func,
};

export default MentionsSuggestions;
