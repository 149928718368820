/** @format */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import parse from "html-react-parser";
import { ROUTES } from "Constants";
import Layout from "components/Layout/Title";

import logPageView from "utils/logPageView";

/*
 * Page displaying all the speakers of the event
 */

function Speakers({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	speakersData,
	acfPageOptions,
	acfNavigation,
}) {
	if (
		acfNavigation?.acf &&
		!acfNavigation?.acf?.navigation_acf?.speakers?.show
	) {
		return <Redirect to="/404" />;
	}

	useEffect(() => {
		//Log PageView
		logPageView(
			"speaker",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	function renderSpeakerCard(speakerData) {
		if (speakerData.acf?.as_single_page === "yes") {
			return (
				<Link
					className="speaker-listing-block"
					key={`${speakerData.slug}`}
					to={ROUTES.SPEAKER.replace(":speakerId", speakerData.slug)}
				>
					{speakerData.acf?.photo && (
						<div className="image-wrap">
							<img src={speakerData.acf?.photo?.sizes?.large} alt="" />
						</div>
					)}
					<div className="text-wrap">
						<p className="type">{speakerData.acf?.type}</p>
						<h4 className="title">{parse(speakerData.title?.rendered)}</h4>
						<p className="role">{speakerData.acf?.subtitle}</p>
						{speakerData.acf?.location && (
							<p className="location">
								<i className="icon-location" />
								<span>{speakerData.acf?.location}</span>
							</p>
						)}
					</div>
				</Link>
			);
		}

		return (
			<div
				className="speaker-listing-block -no-link"
				key={`${speakerData.slug}`}
			>
				{speakerData.acf?.photo && (
					<div className="image-wrap">
						<img src={speakerData.acf?.photo?.sizes?.large} alt="" />
					</div>
				)}
				<div className="text-wrap">
					<p className="type">{speakerData.acf?.type}</p>
					<h5 className="title">{parse(speakerData.title?.rendered)}</h5>
					<p className="role">{speakerData.acf?.subtitle}</p>
					{speakerData.acf?.location && (
						<p className="location">
							<i className="icon-location" />
							<span>{speakerData.acf?.location}</span>
						</p>
					)}
				</div>
			</div>
		);
	}

	return (
		<Layout
			pageClass="speakers-page"
			titleSlot={acfPageOptions.acf?.speakers?.title}
			rightSlot={
				<div className="logo-img -right">
					<div className="wrapper">
						<img
							src={acfPageOptions.acf?.theme?.logo?.main?.sizes?.large}
							alt=""
						/>
					</div>
				</div>
			}
			isLoading={!speakersData || (speakersData && speakersData.length === 0)}
			backgroundUrl={
				acfPageOptions?.acf?.speakers_options?.background_overwrite
					? acfPageOptions?.acf?.speakers_options?.background_overwrite
					: ""
			}
		>
			<div className="speakers-listing-wrap">
				{speakersData &&
					speakersData.length > 0 &&
					speakersData.map((speakers) => renderSpeakerCard(speakers))}
			</div>
		</Layout>
	);
}

Speakers.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	speakersData: null,
	acfPageOptions: null,
	acfNavigation: null,
};

Speakers.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	speakersData: PropTypes.array,
	acfPageOptions: PropTypes.object,
	acfNavigation: PropTypes.any,
};

export default Speakers;
