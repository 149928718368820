import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingAuth from 'components/LoadingAuth';
import {
	AddOrUpdateContactEmail,
} from 'redux/playfab';
import { setAuthStep } from 'redux/auth';
import { isAccountCompletedSelector, isAccountDeclinedSelector, playerHasEmailSelector } from 'selector/player';
import { setLoading } from 'redux/loading';

function mapStateToProps(state) {
	return {
		currentState: state.auth?.step,
		isChatAuthSet: state.auth?.chatAuthSet,
		teamsUserPrincipalName: state.teams?.userPrincipalName,
		isMainChatConnected: state.chat?.chatReady,
		isEmailSet: playerHasEmailSelector(state),
		isAccountCompleted: isAccountCompletedSelector(state),
		isAccountDeclined: isAccountDeclinedSelector(state),
		isDataLoaded: state.auth?.dataLoaded,
		acfPageOptions: state.wpcontent.acfPageOptions,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		AddOrUpdateContactEmail,
		setAuthStep,
		setLoading,
	}, dispatch)
)(LoadingAuth);
