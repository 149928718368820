import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Jitsi from 'components/Activity/Jitsi';
import { writePlayerEvent, getJitsiJwt } from 'redux/playfab';
import { userModeratorSelector } from 'selector/channels';
import { userNameSelector } from 'selector/player';


function mapStateToProps(state) {
	return {
		jitsiJwtToken: state.playfab?.jitsiJwt?.[0],
		userRole: userModeratorSelector(state),
		displayName: userNameSelector(state),
		userId: state.playfab?.PlayFabId,
		acfPageOptions: state.wpcontent.acfPageOptions,
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		getJitsiJwt,
		writePlayerEvent,
	}, dispatch)
)(Jitsi));
