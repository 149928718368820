import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PartnersContent from './PartnersContent';
import EventsContent from './EventsContent';
import TechPartnersContent from './TechPartnersContent';

function WPContent({ 
	postType,
	postSlug,
	wpContent,
	acfPageOptions,
	fetchWPContentData,
	onLoaded,
	authMethod,
}) {
	useEffect(() => {	
		if (postType && postSlug) {
			const data = {
				postType,
				postSlug,
			};
			fetchWPContentData(data);
		}
	}, []);

	let WPDom = null;
	if (Object.keys(wpContent).length !== 0) {
		if (postType === 'partners') {
			WPDom = <PartnersContent wpContent={wpContent} onLoaded={onLoaded} acfPageOptions={acfPageOptions} authMethod={authMethod} />;
		} else if (postType === 'events') {
			WPDom = <EventsContent wpContent={wpContent} onLoaded={onLoaded} acfPageOptions={acfPageOptions} />;
		} else if (postType === 'techpartners') {
			WPDom = <TechPartnersContent wpContent={wpContent} onLoaded={onLoaded} acfPageOptions={acfPageOptions} authMethod={authMethod} />;
		}
	} else {
		WPDom = 'Loading Content';
	}

	return (
		wpContent && wpContent?.slug === postSlug ? (
			<div className="wp-page-container">
				{WPDom}
			</div>
		) : null
	);
}

WPContent.defaultProps = {
	postType: null,
	postSlug: null,
	wpContent: null, 
	acfPageOptions: null, 
	fetchWPContentData: () => {},
	onLoaded: () => {},
	authMethod: null,
};

WPContent.propTypes = {
	postType: PropTypes.string,
	postSlug: PropTypes.string,
	wpContent: PropTypes.object,
	acfPageOptions: PropTypes.object,
	fetchWPContentData: PropTypes.func,
	onLoaded: PropTypes.func,
	authMethod: PropTypes.string,
};

export default WPContent;
