import React from 'react';
import PropTypes from 'prop-types';
import ProfilePicture from 'assets/placeholders/profile-picture.png';
import Button from 'components/Buttons/Button';

function ParticipantCard({
	user,
	inviteList,
	setInviteList,
	addParticipantList, 
	removeParticipantList, 
	acfPageOptions,
}) {

	const {add_button_label, remove_button_label} = acfPageOptions?.acf?.chat_rooms?.add_participants_modal || {};
	
	const addToInviteList = (invitedUser) => {
		if (!inviteList) return null;
		return setInviteList([...inviteList, invitedUser?.playfabId]);
	};

	const removeFromInviteList = (removedUser) => {
		if (!inviteList) return null;
		let arrayList = inviteList;
		for (let i = 0; i < arrayList.length; i += 1) {
			if (arrayList[i] === removedUser.playfabId) {
				arrayList = arrayList.filter(e => e !== removedUser.playfabId);
			}
		}
		return setInviteList(arrayList);
	};

	return (
		<div className="participant-card">
			<div className="participant-profile">
				<div className="profile-info">
					<div className="avatar-ctn">
						<div className="avatar">
							<img src={user?.avatar ? user?.avatar: ProfilePicture} alt={user?.displayName && user?.displayName} />
						</div>
					</div>
					<p>{user?.displayName && user?.displayName}</p>
				</div>
			</div>
			{!inviteList.includes(user.playfabId) ? (
				<Button
					action={() => {
						addToInviteList(user);
						addParticipantList(user);
					}}
					classes="primary large button-invite"
					text={add_button_label}
				/>)
				:
				<Button
					action={() => {
						removeFromInviteList(user);
						removeParticipantList(user);
					}}
					classes="primary large button-remove"
					text={remove_button_label}
				/>}
		</div>
	);
}

ParticipantCard.defaultProps = {
	user: null,
	acfPageOptions: null,
	inviteList: [],
	setInviteList: () => { },
	addParticipantList: () => { }, 
	removeParticipantList: () => { }, 
};

ParticipantCard.propTypes = {
	user: PropTypes.object,
	acfPageOptions: PropTypes.object,
	inviteList: PropTypes.array,
	setInviteList: PropTypes.func,
	addParticipantList: PropTypes.func, 
	removeParticipantList: PropTypes.func, 
};

export default ParticipantCard;
