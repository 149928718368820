import { createSelector } from "@reduxjs/toolkit";

const customListingsSelector = (state) => state.wppage.custom_listings;
const customListingCategoriesSelector = (state) => state.wppage.custom_listing_categories;
const listingCategoryFromRouteSelector = (state, props) => props.match?.params?.categorySlug;
const listingSlugFromRouteSelector = (state, props) => props.match?.params?.listingSlug;

export const getCustomListingsByCategories = createSelector(
	[customListingsSelector, customListingCategoriesSelector],
	(customListings, customListingCategories) => {
		if (!customListingCategories || customListingCategories?.length === 0 || !customListings || customListings.length === 0) {
			return null;
		}

		const listings = customListingCategories.reduce((carry, category) => {
			const listingsInCategory = customListings.filter(
				(listing) => listing.custom_listing_category.indexOf(category.id) > -1
			);

			if (listingsInCategory.length === 0) {
				return carry;
			}

			return [
				...carry,
				{
					category,
					listings: listingsInCategory,
				},
			];
		}, []);

		return listings;
	}
);

export const getCustomListingFromCategory = createSelector(
	[getCustomListingsByCategories, listingCategoryFromRouteSelector],
	(listings, categorySlug) => {
		if (listings === null || categorySlug === null) {
			return null;
		}
		return listings.find((listing) => listing.category.slug === categorySlug);
	}
);

export const getCustomListingFromSlug = createSelector(
	[getCustomListingsByCategories, listingSlugFromRouteSelector],
	(listingsByCategories, listingSlug) => {
		if (listingsByCategories === null || listingSlug === null) {
			return null;
		}

		let category = null;
		let listing = null;

		/* eslint-disable */
		for (const o of listingsByCategories) {
			const matchingListing = o.listings.find((l) => l.slug === listingSlug);

			if (matchingListing) {
				category = o.category;
				listing = matchingListing;
				break;
			}
		}

		if (category && listing) {
			return {
				category,
				listing,
			};
		}

		return null;
	}
);
