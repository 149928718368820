import React from "react";
import PropTypes from "prop-types";

function ToggleSwitch({ checked, onChange }) {
	return (
		<div className="toggle-item">
			<label className="switch">
				<input checked={checked} type="checkbox" onChange={onChange} />
				<span className="slider round" />
			</label>
		</div>
	);
}

ToggleSwitch.propTypes = {
	onChange: () => {},
	checked: () => {},
};

ToggleSwitch.defaultProps = {
	onChange: PropTypes.func,
	checked: PropTypes.func,
};

export default ToggleSwitch;
