import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LogOutModal from 'components/Partials/LogOutModal';

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		acfIcon: state.wpcontent?.acfPageOptions?.acf?.theme?.icon?.general,
	};
}

export default connect(mapStateToProps, (dispatch) => bindActionCreators({}, dispatch))(LogOutModal);
