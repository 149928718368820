import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { now, toLocalTime } from 'utils/dateUtils';
import { activitiesSelector } from './catalog';
import { inventorySelector } from './inventory';
import { userModeratorSelector, hasAccessToActivity, activityValidTime } from './channels';

const tileIdFromRouteSelector = (state, props) => props.match?.params?.tileId;
const sessionTileId = (state, props) => props.tileId;
const storeLoadoutSelector = (state) => state.playfab?.StoreLoadout;
const wordpressChannelsSelector = (state) => state.wppage.channels;
const myAgendaActiveDaySideSelector = (state) => state.side_agenda.activeDay;
/**
 * Populate channels with additionnal info from the store loadout and the Playfab inventory
 */

export const myAgendaChannelsSelector = createSelector(
	[storeLoadoutSelector, activitiesSelector, inventorySelector, wordpressChannelsSelector, userModeratorSelector],
	(storeLoadout, activities, inventory, wordpressChannels, playerUserModerator) => {
		if (!storeLoadout || !activities || !inventory || !wordpressChannels) return null;
		const loadout = storeLoadout.reduce((channelArr, channel) => {
			/**
			 * Build the tiles: filter out unvalid tiles and add additional info the tiles
			 */
			if (channel.tiles.length > 0) {
				const colorCode = channel.name.substr(channel.name.length - 1);

				const validTiles = channel.tiles.reduce((tileArr, tile) => {
					const catalogActivity = activities.find((activity) => activity.itemId === tile?.content?.ItemId);

					// If the tiles is not an activity, it's not added to the channel
					if (catalogActivity) {
						const inventoryActivity = inventory.find(
							(item) => item.itemId === tile?.content?.ItemId && item?.playfab?.InstanceData?.StoreTileId === tile.id
						);

						const isPublic = parseInt(catalogActivity.data.public, 10) === 1;
						const isInInventory = Boolean(inventoryActivity);
						const isQuestionBox = parseInt(catalogActivity.data.question_box, 10) === 1;

						let {
							date_moderator = '',
							date_open = '',
							date_begin = '',
							date_end = '',
							date_close = '',
						} = tile.customPayload;
						date_moderator = toLocalTime(date_moderator);
						date_open = toLocalTime(date_open);
						date_begin = toLocalTime(date_begin);
						date_end = toLocalTime(date_end);
						date_close = toLocalTime(date_close);

						const hasExpired = date_end.isValid() && date_end.isBefore(now());
						const stock = tile.limitedEdition ? tile.limitedEdition - tile.totalPurchase : null;
						const isHappeningToday = date_begin.isSameOrBefore(now(), 'day') && date_end.isSameOrAfter(now(), 'day');

						const show = date_begin.isValid() && (!date_end.isValid() || !hasExpired) && isHappeningToday;

						/**
						 * Add additionnal activity and inventory info to the tiles under activity
						 */

						const newTile = {
							...tile,
							activity: {
								...tile.content,
								...catalogActivity,
								...inventoryActivity?.playfab?.InstanceData,
								StoreTileId: tile.id,
								canPurchase: !isInInventory && (stock === null || stock > 0) && tile.status === 'available',
								canUnregister: isInInventory,
								item_instance_id: inventoryActivity?.playfab?.ItemInstanceId,
								date_moderator,
								date_open,
								date_begin,
								date_end,
								date_close,
								isInInventory,
								isPublic,
								colorCode,
								isQuestionBox,
								stock,
								customPayload: tile.customPayload,
							},
							show,
						};

						newTile.activity.canJoin = hasAccessToActivity(newTile.activity, playerUserModerator);
						newTile.activity.isHappening = activityValidTime(newTile.activity);
						delete newTile.content;

						/**
						 * Group same activity tiles while preferring the one with the lowest available
						 * stock that is not 0
						 */
						const sameActivityTileInArray = tileArr.find(
							(t) =>
								t.activity.ItemId === newTile.activity.ItemId &&
								t.activity.date_begin.isSame(newTile.activity.date_begin) &&
								t.activity.date_end.isSame(newTile.activity.date_end)
						);
						if (sameActivityTileInArray) {
							// Replace tile with new one
							if (
								(sameActivityTileInArray.stock === 0 && newTile.stock > 0) ||
								sameActivityTileInArray.stock > newTile.stock
							) {
								return [...tileArr.filter((t) => t.activity.ItemId !== newTile.activity.ItemId), newTile];
							}

							// Skip over this tile, the one we had in array was "more fitting"
							return tileArr;
						}

						// Add tile as usual
						return [...tileArr, newTile];
					}

					return tileArr;
				}, []);

				//Sort the tiles by date

				validTiles.sort((a, b) => {
					if (a.activity.date_begin.isBefore(b.activity.date_begin)) {
						return -1;
					}

					if (b.activity.date_begin.isBefore(a.activity.date_begin)) {
						return 1;
					}

					return 0;
				});

				const tiles_to_show = validTiles.filter((t) => t.show); //.slice(0, 5);

				// Build the final channel object with the initial channel info, tiles, and wordress data
				const newChannelObj = {
					...channel,
					wp_data: wordpressChannels.find(
						(c) => c.slug.toLowerCase() === channel.name.replace(/ /g, '-').toLowerCase()
					),
					tiles: validTiles,
					is_locked: tiles_to_show.length === 0,
					tiles_to_show,
				};

				return [...channelArr, newChannelObj];
			}

			return channelArr;
		}, []);

		//Filter channels that are not linked to WordPress channels
		return loadout.filter((channel) => {
			if (channel.name.includes('appointment')) {
				return channel;
			}
			if (!channel || !channel?.wp_data) {
				return null;
			}
			return channel;
		});
	}
);

/**
 * Return every days of the agenda (each day that an event takes place)
 */

export const myAgendaHoursSelector = createSelector([myAgendaActiveDaySideSelector], (activeDay) => {
	if (!activeDay) return null;

	const minHour = toLocalTime(activeDay).startOf('day');
	const maxHour = toLocalTime(activeDay).endOf('day');

	const timeSpanInHours = maxHour.diff(minHour, 'hour') + 1;

	const agendaHours = Array.from({ length: timeSpanInHours }, (d, i) => {
		return minHour.add(i, 'hour');
	});

	return agendaHours;
});

/**
 * Extract tiles from the channel
 */

export const myAgendaChannelTilesSelector = createSelector([myAgendaChannelsSelector], (channels) => {
	if (!channels) return null;

	return channels.reduce((carry, curr) => {
		return [...carry, ...curr.tiles];
	}, []);
});

/**
 * Return every days of the agenda (each day that an event takes place)
 */

export const myAgendaDaysSelector = createSelector([myAgendaChannelsSelector], (channels) => {
	if (!channels) return null;
	const agendaDays = channels.reduce((carry, curr) => {
		curr.tiles.forEach((tile) => {
			if (tile.activity.date_begin.isValid()) {
				const dateBeginExists = carry.filter((d) => d.isSame(tile.activity.date_begin, 'day')).length > 0;
				if (!dateBeginExists) {
					carry.push(tile.activity.date_begin);
				}
			}

			if (tile.activity.date_end.isValid()) {
				const dateEndExists = carry.filter((d) => d.isSame(tile.activity.date_end, 'day')).length > 0;

				if (!dateEndExists) {
					carry.push(tile.activity.date_end);
				}
			}
		});

		return carry;
	}, []);

	agendaDays.sort((a, b) => (a.isAfter(b) ? 1 : -1));

	return agendaDays;
});

/**
 * Filter out the registerd tiles
 */

export const myAggendaRegisteredTilesSelector = createSelector([myAgendaChannelTilesSelector], (tiles) => {
	if (!tiles) return null;

	return tiles.filter((tile) => tile.activity.isInInventory);
});

/**
 * Filter the featured tiles
 */

export const myAgendaFeaturedTilesSelector = createSelector([myAgendaChannelTilesSelector], (tiles) => {
	if (!tiles) return null;
	//TODO: fix bad boolean "0" for true and 1 for false
	// return tiles.filter((tile) => Boolean(tile.activity?.data?.is_featured));
	return tiles.filter((tile) => tile.activity?.data?.is_featured === 1);
});

/**
 * Return the tiles for the active day
 */

export const myAgendaActiveDayTilesSelector = createSelector(
	[myAggendaRegisteredTilesSelector, myAgendaActiveDaySideSelector],
	(registeredTiles, activeDay) => {
		if (!registeredTiles || !activeDay) return null;
		const curDay = dayjs(activeDay);
		const startOfDay = curDay.startOf('day');
		const endOfDay = curDay.endOf('day');
		const mappedTiles = registeredTiles.reduce((c, tile) => {
			const { date_begin, date_end } = tile.activity;

			const begin = dayjs(date_begin);
			const end = dayjs(date_end);

			if (begin.isBefore(endOfDay) && end.isAfter(startOfDay)) {
				c.push({
					...tile,
					begin: begin.isBefore(startOfDay) ? startOfDay : begin,
					end: end.isAfter(endOfDay) ? endOfDay : end,
				});
			}

			return c;
		}, []);

		return mappedTiles;
	}
);

/**
 * Return the tile matching the tile Id
 */

export const myAgendaSingleTileSelector = createSelector([myAgendaChannelTilesSelector, tileIdFromRouteSelector], (tiles, tileId) => {
	if (!tiles) return null;

	return tiles.find((t) => t.id === tileId);
});

/**
 * Return the activity of a given tile
 */

export const myAgendaActivitySelector = createSelector([myAgendaSingleTileSelector], (tile) => {
	if (!tile) return null;
	return tile.activity;
});



/**************MOBILE SELECTOR************************************************************************************/

/**
 * Return the tile matching the tile Id
 */

export const sessionSingleTileSelector = createSelector([myAgendaChannelTilesSelector, sessionTileId], (tiles, tileId) => {
	if (!tiles) return null;

	return tiles.find((t) => t.id === tileId);
});

/**
 * Return the activity of a given tile
 */

export const sessionActivitySelector = createSelector([sessionSingleTileSelector], (tile) => {
	if (!tile) return null;
	return tile.activity;
});




