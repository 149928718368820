import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	getLeaderboard,
	triggerBlockNotifications,
	getLeaderboardOnPosition,
	triggerMyLeaderBoard,
	triggerGeneralLeaderBoard,
} from 'redux/playfab';
import GeneralLeaderboard from 'components/Profile/GeneralLeaderboard';

function mapStateToProps(state) {
	return {
		generalLeaderboard: state.playfab?.GeneralLeaderboard,
		leaderboardOnPosition: state.playfab?.OnPositionLeaderboard,
		playfabId: state.playfab?.PlayerProfile.PlayerId,
		leaderBoardBool: state.playfab?.show_personnal_leaderboard,
		acfPageOptions: state.wpcontent?.acfPageOptions,
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		getLeaderboard,
		triggerBlockNotifications,
		getLeaderboardOnPosition,
		triggerGeneralLeaderBoard,
		triggerMyLeaderBoard,
	}, dispatch)
)(GeneralLeaderboard));
