/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import Layout from "components/Layout/Title";
import Gallery from "containers/ContentGallery/Gallery";

import logPageView from 'utils/logPageView';

/*
 * Page displaying all the available Interactive Galleries
 */

function InteractiveGallery({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	galleryData,
	fetchWPInteractiveGalleriesData,
	acfPageOptions,
	playerGroup,
	galleryFilters,
	navigation,
}) {
	const [filterParam, setFilterParam] = useState(null);
	useEffect(() => {
		if (galleryData?.length === 0) {
			const data = {
				playerGroup,
			};
			fetchWPInteractiveGalleriesData(data);
		}
	});

	useEffect(() => {
		setFilterParam(acfPageOptions?.acf?.content_gallery?.all_filters);
	},[acfPageOptions])

	useEffect(() => {
		//Log PageView
		logPageView('interactive gallery', playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);


	return (
		<Layout
			titleSlot={navigation?.acf?.navigation_acf?.interactive_gallery?.title}
			pageClass='interactive-gallery'
			backgroundUrl={acfPageOptions?.acf?.interactive_gallery?.background ? acfPageOptions?.acf?.interactive_gallery?.background : ""}
		>
			<Gallery displayFilters galleryData={galleryData} galleryFilters={galleryFilters} setFilterParam={setFilterParam} filterParam={filterParam} />
		</Layout>
	);
}

InteractiveGallery.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	galleryData: [],
	galleryFilters: [],
	acfPageOptions: null,
	fetchWPInteractiveGalleriesData: () => {},
	playerGroup: null,
	navigation: null,
};

InteractiveGallery.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	galleryData: PropTypes.array,
	galleryFilters: PropTypes.array,
	acfPageOptions: PropTypes.object,
	fetchWPInteractiveGalleriesData: PropTypes.func,
	playerGroup: PropTypes.string,
	navigation: PropTypes.object,
};

export default InteractiveGallery;
