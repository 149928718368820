import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MyAgenda from 'components/Partials/LeftPanel/MyAgenda';
import { setActiveDay } from 'redux/side_agenda';
import {  playerLangGroupSelector } from 'selector/player';
import { myAgendaDaysSelector, myAgendaActiveDayTilesSelector, myAgendaHoursSelector } from 'selector/myAgenda';

function mapStateToProps(state) {
	return {
		...state.side_agenda,
		days: myAgendaDaysSelector(state),
		tiles: myAgendaActiveDayTilesSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		groupLang: playerLangGroupSelector(state),
		agendaHours: myAgendaHoursSelector(state),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		setActiveDay,
	}, dispatch)
)(MyAgenda);
