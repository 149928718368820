import React, { useEffect, useState, setState } from "react";
import PropTypes from "prop-types";
import MainModal from "components/Modal";

function TriviaIndexModal({
	acfPageOptions,
	overwriteHeading,
	modal,
	setModal,
	heading,
	children,
	footing,
	timerLength,
	points,
	clickOffClose,
	enabledEscKey,
	className,
	fullScreen,
	userChoice,
}) {
	const [seconds, setSeconds] = useState(
		timerLength > 60
			? timerLength - Math.floor(timerLength / 60) * 60
			: timerLength
	);
	const [minutes, setMinutes] = useState(
		timerLength > 60 ? Math.floor(timerLength / 60) : 0
	);
	const [timerBarPercent, setTimerBarPercent] = useState(100);

	const handleClose = () => setModal(false);

	function displayTimerText() {
		if (userChoice && !userChoice.point) {
			return null;
		}
		
		if (userChoice && userChoice.answer) {
			return "Good job!";
		}

		if (userChoice && !userChoice.answer) {
			return <span className="trivia-message-error">Ooops!</span>;
		}

		if (timerBarPercent >= 5)
			return minutes > 0 ? `${minutes}:${("0" + seconds).slice(-2)}` : seconds;

		return null;
	}

	const renderTriviaStatusColor = () => {
		if (!userChoice) {
			return null;
		}

		if (userChoice && !userChoice.point) {
			return null;
		}

		if (userChoice && userChoice.answer) {
			return "timer-bar-success";
		}
		if (userChoice && !userChoice.answer) {
			return "timer-bar-error";
		}

		return null;
	};

	useEffect(() => {
		let interval = null;
		if ((timerLength && seconds >= 0) || minutes >= 1) {
			if (seconds < 1) {
				if (!minutes) {
					handleClose();
				} else {
					setSeconds(59);
					setMinutes(minutes - 1);
				}
			}
			interval = setInterval(() => {
				setSeconds(seconds - 1);
				setTimerBarPercent(((minutes * 60 + seconds - 1) / timerLength) * 100);
			}, 1000);
		} else if (!timerLength && seconds !== 0) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [timerLength, seconds]);

	return (
		<div className="trivia-container">
			<MainModal
				modal={modal}
				setModal={setModal}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-BootstrapModal-title-vcenter"
				centered
				backdrop={clickOffClose ? true : "static"} //disables clicking off BootstrapModal to close
				keyboard={enabledEscKey} //disables esc to close
				className={`${fullScreen ? "modal-content-landscape" : ""}`}
				acfPageOptions={acfPageOptions}
			>
				{overwriteHeading && (
					<div className="trivia-modal-header">
						<div
							className="trivia-modal-title"
							id="contained-BootstrapModal-title-vcenter"
						>
							{overwriteHeading}
						</div>
					</div>
				)}

				{!overwriteHeading && (
					<div className={timerLength && "with-timer p-0 modal-header"}>
						{points && <div className="points">{points} PTS</div>}
						<div
							id="contained-BootstrapModal-title-vcenter"
							className={
								"trivia-modal-title" &&
								timerLength &&
								"w-100 d-flex justify-content-end"
							}
						>
							{heading}
							{timerLength && (
								<div
									className={`timer-bar text-start ${renderTriviaStatusColor()}`}
									style={{
										width: `${timerBarPercent}%`,
									}}
								>
									<span className="px-1 timer-percent">
										{displayTimerText()}
									</span>
								</div>
							)}
						</div>
					</div>
				)}

				<div className="trivia-modal-body">{children}</div>
				{footing && <div className="trivia-modal-footer">{footing}</div>}
			</MainModal>
		</div>
	);
}

TriviaIndexModal.defaultProps = {
	acfPageOptions: null,
	overwriteHeading: null,
	modal: null,
	setModal: () => {},
	heading: null,
	footing: null,
	timerLength: null,
	points: null,
	clickOffClose: false,
	enabledEscKey: false,
	className: null,
	fullScreen: false,
	userChoice: null,
};

TriviaIndexModal.propTypes = {
	modal: PropTypes.bool,
	setModal: PropTypes.func,
	acfPageOptions: PropTypes.object,
	overwriteHeading: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	heading: PropTypes.string,
	children: PropTypes.node.isRequired,
	footing: PropTypes.object,
	timerLength: PropTypes.number,
	points: PropTypes.number,
	clickOffClose: PropTypes.bool,
	enabledEscKey: PropTypes.bool,
	className: PropTypes.string,
	fullScreen: PropTypes.bool,
	userChoice: PropTypes.object,
};

export default TriviaIndexModal;
