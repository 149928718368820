import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Container, Row } from "react-bootstrap";
import Select from "react-select";
import { TechSupportContext } from "mobile/context/TechSupportContext";
import { ReactComponent as ArrowDown } from "assets/icons/pack1/navigation/dropdown/icon-arrow-bottom.svg";

//Custom Icon for the select
function CustomArrow() {
	return <ArrowDown />;
}

function Form({ supportOptions, requestSupport, chatSupportConnect, acfPageOptions }) {
	const {
		request,
		setRequest,
		selectSupportValue,
		setSelectSupportValue,
		formSubmitted,
		setFormSubmitted,
	} = useContext(TechSupportContext);

	const customStyles = {
		control: (provided) => ({
			...provided,
			background: "transparent",
			outline: "none",
			border: "none",
			borderRadius: "none",
		}),
		container: (provided) => ({
			...provided,
			outline: "none",
		}),
		input: (provided) => ({
			...provided,
			color: "white",
		}),
		placeholder: (provided) => ({
			...provided,
			color: "white",
		}),
		singleValue: (provided) => ({
			...provided,
			color: "white",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "white",
			transform: "scale(0.8)",
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		option: (provided, state) => ({
			...provided,
			color: "white",
			background: state.isSelected ? "rgba(255, 255, 255, 0.1)" : "transparent",
		}),
	};

	function handleTechSupportSubmit(ev) {
		ev.preventDefault();
		if (request !== "") {
			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "support",
				eventAction: "message support sent",
				eventLabel: request,
			});

			requestSupport({
				Subject:
					selectSupportValue !== ""
						? selectSupportValue
						: supportOptions[0]?.option,
				Message: request,
			}).then(() => {
				chatSupportConnect().then(() => {
					setFormSubmitted(true);
				});
			});
		}
	}

	function handleSelectSupportValue(ev) {
		setSelectSupportValue(ev.target.value);
	}

	return (
		<Container className="support-form">
			<h4 className="title pt-1">
				{acfPageOptions?.acf?.chat_support?.contact_form_title}
			</h4>
			<Row>
				<form>
					<div className="select-wrapper">
						<Select
							placeholder={acfPageOptions?.acf?.chat_support?.select_topic}
							components={{ DropdownIndicator: CustomArrow }}
							styles={customStyles}
							options={supportOptions.map((inputValue) => {
								return { ...inputValue?.option, label: inputValue?.option };
							})}
							onChange={(option) => setSelectSupportValue(option.label)}
							menuPlacement="bottom"
							isSearchable={false}
							className={`mobile-select ${
								selectSupportValue !== "" ? `filled` : ""
							}`}
							classNamePrefix="react-select"
						/>
					</div>
					<textarea
						className="p-3"
						id="text_area_support"
						onChange={(ev) => setRequest(ev.target.value)}
						placeholder={acfPageOptions?.acf?.chat_support?.form_type_request}
					/>
					<div className="d-flex justify-content-center">
						<button
							type="button"
							className={`submit-button ${
								request !== "" && selectSupportValue !== "" ? "" : "disabled"
							}`}
							onClick={(ev) => handleTechSupportSubmit(ev)}
							disabled={!request}
						>
							{acfPageOptions?.acf?.chat_support?.form_submit}
						</button>
					</div>
				</form>
			</Row>
		</Container>
	);
}

Form.defaultProps = {
	supportOptions: [],
	requestSupport: () => {},
	chatSupportConnect: () => {},
	acfPageOptions: {},
};

Form.propTypes = {
	supportOptions: PropTypes.array,
	requestSupport: PropTypes.func,
	chatSupportConnect: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default Form;