import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { activitiesSelector } from 'selector/catalog';
import { getCatalog, writeTelemetryEvent } from 'redux/playfab';
import { allUserTilesSelector, allTilesSelector } from 'selector/agenda';
import MainHub from 'components/Pages/MainHub';
import { statsSelector } from 'selector/stats';
import { playerGroupSelector } from 'selector/player';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		activities: activitiesSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		lang: state.env.lang,
		myAgendaTiles: allUserTilesSelector(state),
		eventTiles: allTilesSelector(state),
		playerGroup: playerGroupSelector(state),
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		getCatalog,
		writeTelemetryEvent,
	}, dispatch)
)(MainHub));
