import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomListing from 'components/CustomListings/CustomListing';
import { getCustomListingFromSlug } from 'selector/customListings';

function mapStateToProps(state, props) {
	return {
		acfPageOptions: state?.wpcontent?.acfPageOptions,
		...getCustomListingFromSlug(state, props),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
	}, dispatch)
)(CustomListing);
