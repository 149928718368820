import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { PLAYFAB_EVENTS } from "Constants";
import Button from "components/Buttons/Button";
import Checkbox from "components/Buttons/Checkbox";
import { ComPanelContext } from "components/ComPanel/Context/ComPanelContext";

const MAX_LENGTH_INPUT = 240;
function NewQuestion({
	channel,
	postQuestion,
	writePlayerEvent,
	sendAsQuestion,
	acfPageOptions,
	setSendAsQuestion,
}) {
	const [questionSent, setQuestionSent] = useState(false);
	const [questionValue, setQuestionValue] = useState(sendAsQuestion ? inputMessage: '');
	const [askAnnoymously, setAskAnnoymously] = useState(false);
	const [askLive, setAskLive] = useState(false);
	const totalLength = questionValue.length <= 28;
	const liveChatPanel = acfPageOptions?.live_chat_panel;
	const { inputMessage, setInputMessage } = useContext(ComPanelContext);

	function sendQuestion() {
		const live = askLive ? 1 : 0;
		const anonymous = askAnnoymously ? 1 : 0;

		try {
			if (inputMessage !== "") {
				postQuestion({
					RoomId: channel,
					Message: inputMessage,
					Anonymous: anonymous,
					Live: live,
				});

				window.dataLayer.push({
					'event': 'generic_event',
					'eventCategory': 'qa',
					'eventAction': 'message sent',
					'eventLabel': inputMessage,
				});

				writePlayerEvent({
					name: PLAYFAB_EVENTS.player_questionbox_ask,
					body: { RoomId: channel },
				});

				writePlayerEvent({
					name: PLAYFAB_EVENTS.player_meeting_event,
					body: {
						HasChatted: 1,
						RoomId: channel,
						Type: "question",
					},
				});

				resetForm();
				setQuestionSent(true);
			}

		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
		return null;
	}

	function askNewQuestion() {
		setQuestionSent(false);
	}

	function handleDefaultSubmit(e) {
		e.preventDefault();
		sendQuestion();
	}

	function handleQuestionValue(ev) {
		if(ev.target.value.length <= MAX_LENGTH_INPUT) {
			setInputMessage(ev.target.value);

			if (sendAsQuestion) {
				setInputMessage(ev.target.value);
			}
		}
	}

	function resetForm() {
		setQuestionValue('');
		setInputMessage('');
		setAskAnnoymously(false);
		setAskLive(false);
		setSendAsQuestion(false);
	}

	return (
		<div className="new-question-container">
			{!questionSent ? (
				<form className="question-form" onSubmit={handleDefaultSubmit}>
					<div className="text-container">
						<div className="textarea-container">
							<textarea
								placeholder={acfPageOptions?.live_chat_panel?.question_placeholder || "Type your question here..."}
								maxLength={MAX_LENGTH_INPUT}
								name="question"
								className={`input-textarea ${totalLength ? 'min-height' : 'max-height'}`}
								autoComplete="off"
								cols="4"
								onChange={(ev) => handleQuestionValue(ev)}
								value={inputMessage}
							/>
							<div className="counter">
								{`${inputMessage.length}/${MAX_LENGTH_INPUT}`}
							</div>
						</div>
					</div>
					<div className={`checkbox-container ${!acfPageOptions.live_chat_panel?.show_ask_anonymously || !acfPageOptions?.live_chat_panel?.show_ask_live ? "only-one" : ""}`}>
						{acfPageOptions.live_chat_panel?.show_ask_anonymously && (
							<Checkbox checked={askAnnoymously} onChange={() => setAskAnnoymously(!askAnnoymously)} checkboxId="ask_anonymously" label={acfPageOptions?.live_chat_panel?.ask_anonymously} />
						)}
						{acfPageOptions.live_chat_panel?.show_ask_live && (
							<Checkbox checked={askLive} onChange={() => setAskLive(!askLive)} checkboxId="ask_live" label={acfPageOptions?.live_chat_panel?.ask_live} />
						)}
					</div>
					<div className="navigation">
						<Button
							action={() => resetForm()}
							classes="secondary large fit"
							text={acfPageOptions?.live_chat_panel?.cancel_button_label || 'Cancel'}
						/>
						<Button
							action={() => sendQuestion()}
							classes="primary large"
							text={liveChatPanel?.submit_button_label}
						/>
					</div>
				</form>
			) : (
				<div className="question-sent">
					<span className="message">
						{parse(liveChatPanel?.question_confirmation_message)}
					</span>
					<button
						type="button"
						className="new-question-button"
						onClick={askNewQuestion}
					>
            			{liveChatPanel?.new_question_label}
					</button>
				</div>
			)}
		</div>
	);
}

NewQuestion.defaultProps = {
	channel: null,
	postQuestion: () => {},
	writePlayerEvent: () => {},
	sendAsQuestion: false,
	setSendAsQuestion: () => {},
	acfPageOptions: {},
};

NewQuestion.propTypes = {
	channel: PropTypes.string,
	postQuestion: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	sendAsQuestion: PropTypes.bool,
	setSendAsQuestion: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default NewQuestion;
