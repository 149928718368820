/** @format */

import React from 'react';
import PropTypes from 'prop-types';

function Speaker({ thumbnail, type, name, jobTitle }) {
	return (
		<div className="single-speaker">
			<div className="speaker-img">
				<img src={thumbnail} alt={name} />
				{type && <div className="speaker-type">{type}</div>}
			</div>
			<div className="speaker-info">
				<div className="name">{name}</div>
				<div className="job-title">{jobTitle}</div>
			</div>
		</div>
	);
}

Speaker.defaultProps = {
	thumbnail: null,
	type: null,
	name: null,
	jobTitle: null,
};

Speaker.propTypes = {
	thumbnail: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,
	jobTitle: PropTypes.string,
};

export default Speaker;
