import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';


function NavOption({
	link,
	icon,
	activeIcon,
	text,
	logOut,
	onClickShowLogOutModal,
	onClick,
	withLink,
	highlight,
	enableActive,
	order,
}) {

	return (
		<li style={{order}} className={logOut === "true" ? 'link-ctn log-out' : 'link-ctn'} >
			{withLink ?
				<NavLink exact className={`link ${enableActive ? 'active' : ''}`} to={link}>
					<div className="content">
						<img className="normalIcon" src={icon} alt="" />
						<img className="activeIcon" src={activeIcon} alt="" />
						<span>{text}</span>
					</div>
				</NavLink>
				:
				<button type="button" onClick={logOut ? () => onClickShowLogOutModal() : ()=> onClick()} className="link">
					<img className="normalIcon" src={icon} alt="" />
					<img className="activeIcon" src={activeIcon} alt="" />
					<span className={`${highlight ? 'highlight-text' : ''}`}>{text}</span>
				</button>
			}
		</li>
	);
}

NavOption.propTypes = {
	link: PropTypes.string,
	icon: PropTypes.string,
	activeIcon: PropTypes.string,
	text: PropTypes.string,
	logOut: PropTypes.string,
	onClickShowLogOutModal: PropTypes.func,
	onClick: PropTypes.func,
	withLink: PropTypes.bool,
	highlight: PropTypes.bool,
	enableActive: PropTypes.bool,
	order: PropTypes.string,
};

NavOption.defaultProps = {
	link: '',
	icon: '',
	activeIcon: '',
	text: '',
	logOut: '',
	onClickShowLogOutModal: () => {},
	onClick: null,
	withLink: true,
	highlight: false,
	enableActive: false,
	order: '',
};

export default NavOption;