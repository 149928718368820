import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { SessionContext } from "mobile/context/SessionContext";
import { Accordion } from "react-bootstrap";
import { ReactComponent as FaqIcon } from "assets/icons/pack1/menus/main-menu/icon-faq.svg";
import Faq from "mobile/pages/containers/Faq";
import Form from "./Form";
import Chat from "../containers/techsupport/Chat";

function TechSupport({
	chatSupportConnect,
	channel,
	supportOptions,
	requestSupport,
	showCompanelNavigation,
	setShowCompanelNavigation,
	acfPageOptions,
}) {
	const { minimizeToggle } = useContext(SessionContext);
	return (
		<>
			<div className="tech-support pt-5">
				{!channel ? (
					<Form
						supportOptions={supportOptions}
						requestSupport={requestSupport}
						chatSupportConnect={chatSupportConnect}
						acfPageOptions={acfPageOptions}
					/>
				) : (
					<Chat
						supportOptions={supportOptions}
						showCompanelNavigation={showCompanelNavigation}
						setShowCompanelNavigation={setShowCompanelNavigation}
					/>
				)}
			</div>
			<Accordion className={`faq-accordion-tech-support ${minimizeToggle ? "session-minimized" : ''}`}>
				<Accordion.Item eventKey="0">
					{/*TODO Create settings icon cogweheel ACF and the accordion button :after */}
					<Accordion.Header>
						<FaqIcon />
						<span className="p-3">{acfPageOptions?.acf?.chat_support?.faq_title}</span>
					</Accordion.Header>
					<Accordion.Body>
						<Faq />
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	);
}

TechSupport.defaultProps = {
	channel: "",
	supportOptions: [],
	requestSupport: () => {},
	chatSupportConnect: () => {},
	showCompanelNavigation: false,
	setShowCompanelNavigation: () => {},
	acfPageOptions: null,
};

TechSupport.propTypes = {
	channel: PropTypes.string,
	supportOptions: PropTypes.array,
	requestSupport: PropTypes.func,
	chatSupportConnect: PropTypes.func,
	showCompanelNavigation: PropTypes.bool,
	setShowCompanelNavigation: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default TechSupport;