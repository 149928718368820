import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchWPPageData } from 'redux/wppage';
import WPPage from 'components/Pages/WPPage';
import { writeTelemetryEvent } from 'redux/playfab';
import { playerGroupSelector } from 'selector/player';

function mapStateToProps(state) {
	return {
		wpPageContent: state.wppage.pageContent,
		wpPage: state.wppage,
		playerGroup: playerGroupSelector(state),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		fetchWPPageData,
		writeTelemetryEvent,
	}, dispatch)
)(WPPage);
