import React from "react";
import { object, array, string, func } from "prop-types";
import AgendaCardItem from "mobile/components/agenda/AgendaCardItem";

function AgendaCard({
	data,
	channels,
	userAttendanceType,
	purchaseTile,
	writeTelemetryEvent,
	writePlayerEvent,
	getStoreLoadout,
	getItemInventory,
	acfPageOptions,
}) {

	return (
		<div key={data.id}>
			<div className="row mobile-date align-items-end">
				<div>
					<AgendaCardItem
						userAttendanceType={userAttendanceType}
						channels={channels}
						tile={data}
						purchaseTile={purchaseTile}
						writeTelemetryEvent={writeTelemetryEvent}
						writePlayerEvent={writePlayerEvent}
						getStoreLoadout={getStoreLoadout}
						getItemInventory={getItemInventory}
						acfPageOptions={acfPageOptions}
					/>
				</div>
			</div>
		</div>
	);
}

AgendaCard.defaultProps = {
	acfPageOptions: null,
};

AgendaCard.propTypes = {
	data: object.isRequired,
	acfPageOptions: object,
	channels: array.isRequired,
	userAttendanceType: string.isRequired,
	purchaseTile: func.isRequired,
	writeTelemetryEvent: func.isRequired,
	writePlayerEvent: func.isRequired,
	getStoreLoadout: func.isRequired,
	getItemInventory: func.isRequired,
};

export default AgendaCard;
