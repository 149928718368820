/** @format */
/* eslint-disable no-unsafe-optional-chaining */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout/Title";
import Gallery from "containers/ContentGallery/Gallery";
import logPageView from "utils/logPageView";

/*
 * Page displaying all the available Interactive Galleries
 */

function DealersLounge({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	galleryData,
	fetchWPInteractiveGalleriesData,
	acfPageOptions,
	playerGroup,
	galleryFilters,
	chatJoinRoom,
	chatLeaveRoom,
	username,
	clearMessages,
	navigation,
}) {
	const [filterParam, setFilterParam] = useState(null);
	useEffect(() => {
		if (galleryData?.length === 0) {
			const data = {
				playerGroup,
			};
			fetchWPInteractiveGalleriesData(data);
		}
	});

	useEffect(() => {
		setFilterParam(acfPageOptions?.acf?.content_gallery?.all_filters);
	}, [acfPageOptions]);

	useEffect(() => {
		//Log PageView
		logPageView("dealer lounge", playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);

	useEffect(() => {
		const channel = acfPageOptions?.acf?.dealer_lounge?.playfab_channel_key;
		if (channel && username) {
			chatJoinRoom({
				channel,
				username,
				playfabId,
			});
		}

		return () => {
			chatLeaveRoom({
				channel,
			});
			clearMessages();
		};
	}, [username, acfPageOptions]);

	return (
		<Layout
			titleSlot={navigation?.acf?.navigation_acf?.dealers_lounge?.title}
			pageClass='interactive-gallery'
			backgroundUrl={acfPageOptions?.acf?.dealer_lounge?.background ? acfPageOptions?.acf?.dealer_lounge?.background : ""}
		>
			<div className='dealer-lounge'>
				<div className='dealer-lounge-media'>
					<Gallery
						displayComPanel
						galleryData={galleryData}
						galleryFilters={galleryFilters}
						setFilterParam={setFilterParam}
						filterParam={filterParam}
						displayBooking
						displayFilters
					/>
				</div>
			</div>
		</Layout>
	);
}

DealersLounge.defaultProps = {
	playerStatistics: null,
	playfabId: "",
	leaderboardOnUserPosition: [],
	playerData: null,
	galleryData: [],
	galleryFilters: [],
	acfPageOptions: null,
	fetchWPInteractiveGalleriesData: () => {},
	playerGroup: null,
	chatJoinRoom: () => {},
	chatLeaveRoom: () => {},
	clearMessages: () => {},
	username: "",
	navigation: null,
};

DealersLounge.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	galleryData: PropTypes.array,
	galleryFilters: PropTypes.array,
	acfPageOptions: PropTypes.object,
	fetchWPInteractiveGalleriesData: PropTypes.func,
	playerGroup: PropTypes.string,
	chatJoinRoom: PropTypes.func,
	chatLeaveRoom: PropTypes.func,
	clearMessages: PropTypes.func,
	username: PropTypes.string,
	navigation: PropTypes.object,
};

export default DealersLounge;
