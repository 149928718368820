import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
	getDayTwoDigits,
	getTimeStyleShort,
	getWeekdayShort,
	toLocalTime,
	getMonthLong,
} from 'utils/dateUtils';

function Availabilities({partnerData, acfPageOptions, groupLang}) {

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<div className="availabilities">
			<div className='partner-block'>
				<div className='partner-name'>
					<h3>{parse(partnerData?.title?.rendered)}</h3>
					<span className='availability'>{acfPageOptions.acf?.partner?.availabilities_prompt}</span>
				</div>
				<Slider 
					infinite={settings.infinite}
					slidesToShow={settings.slidesToShow}
					speed={settings.speed}
					dots={settings.dots}
					slidesToScroll={settings.slidesToScroll}
				>
					{acfPageOptions.acf &&
						acfPageOptions.acf?.partner?.time_slots &&
						acfPageOptions.acf?.partner?.time_slots.map((theSlot) => {
							return (
								<div className='time-slots' key={theSlot.day + theSlot.month}>
									<div className='slot'>
										<div className='weekday-container'>
											<span className='day'>{getWeekdayShort(toLocalTime(theSlot.active_start[0]?.hour_start).$d, groupLang)}</span>
											<span className='month'>{getMonthLong(toLocalTime(theSlot.active_start[0]?.hour_start).$d, groupLang)}</span>
										</div>
										<div className='date'>
											<h3 className='number'>{getDayTwoDigits(toLocalTime(theSlot.active_start[0]?.hour_start).$d, groupLang)}</h3>
										</div>
									</div>
									<div className='hours'>
										{theSlot.active_start.map((hour, idx) => (
											<div className='hour' key={theSlot.day + theSlot.month + hour.hour_start}>
												<span className='value'>{getTimeStyleShort(toLocalTime(hour.hour_start).$d, 'en-Us')}</span>
												<span className='text'>-</span>
												<span className='value'>{getTimeStyleShort(toLocalTime(theSlot.active_end[idx]?.hour_end).$d, 'en-Us')}</span>
											</div>
										))}
									</div>
								</div>
							);
						})}
				</Slider>
			</div>
		</div>
	);
};

Availabilities.defaultProps = {
	acfPageOptions: null,
	partnerData: null,
	groupLang: null,
};

Availabilities.propTypes = {
	acfPageOptions: PropTypes.object,
	partnerData: PropTypes.object,
	groupLang: PropTypes.string,
};

export default Availabilities;
