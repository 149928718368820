import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function StreamStatus({ message, title, link, url }) {
	return (
		<div className="d-flex flex-column justify-content-center align-items-center mobile-stream-status">
			<div className="d-flex flex-column p-3 align-items-center">
				<span className="title text-center">{title}</span>
				<span className="message text-center">{message}</span>
			</div>
			<Link className="link" to={url}>
				<span>{link}</span>
			</Link>
		</div>
	);
}

StreamStatus.defaultProps = {
	message: null,
	title: null,
	link: null,
	url: '/',
};

StreamStatus.propTypes = {
	message: PropTypes.string,
	title: PropTypes.string,
	link: PropTypes.string,
	url: PropTypes.string,
};

export default StreamStatus;
