/** @format */

import { REACT_APP_PAGE_ICON, REACT_APP_APP_NAME } from "Constants";
import { maintenanceCheck } from "utils/maintenanceCheck";

maintenanceCheck();

export default () => {
	const favicon = document.getElementById("favicon");

	favicon.href = REACT_APP_PAGE_ICON;
	document.title = REACT_APP_APP_NAME;
};
