import React from 'react';
import PropTypes from 'prop-types';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

function CustomTime({ children, eventKey, inputValue, forValue, acfPageOptions, slot }) {
	const decoratedOnClick = useAccordionButton(
		eventKey,
		() =>
			(document.getElementById('bookMeetingTimeLabel').innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.selected_time}: <span class="value" id="selected-time" data-slot=${slot}>${inputValue}</span>`)
	);

	return (
		<div className='bookmeeting-radio-ctn -time'>
			<input type='radio' id={forValue} onChange={decoratedOnClick} name='time' value={inputValue} />
			<label htmlFor={forValue}>{children}</label>
		</div>
	);
}

CustomTime.propTypes = {
	children: PropTypes.object,
	eventKey: PropTypes.string,
	inputValue: PropTypes.string,
	forValue: PropTypes.string,
	acfPageOptions: PropTypes.object,
	slot: PropTypes.string,
};

CustomTime.defaultProps = {
	children: null,
	eventKey: '0',
	inputValue: '',
	forValue: '',
	acfPageOptions: null,
	slot: null,
};

export default CustomTime;
