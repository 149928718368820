import React from "react";
import PropTypes from "prop-types";
import ProfilePicture from "assets/placeholders/profile-picture.png";

function TopThreeCard({ user, position, pointsLabelShort }) {
	const positions = {
		0: "first",
		1: "second",
		2: "third",
	};

	function getLastnameInitial(username) {
		const fullName = username.split(" ");
		const firstName = username.split(" ")[0] || username;
		let lastName = "";
		if (fullName.length > 1) {
			lastName = " " + username.split(" ").pop().charAt(0) + ".";
		} else {
			lastName = "";
		}
		return firstName + lastName;
	}

	return (
		<div
			className={`d-flex flex-column justify-content-center align-items-center ${positions[position]}-place top-position`}
		>
			<div className="d-flex position-relative justify-content-center align-items-end">
				<img
					className="rounded-circle w-100"
					src={user.Profile.AvatarUrl ? user.Profile.AvatarUrl : ProfilePicture}
					alt=""
				/>
				<span className="position-absolute rounded-circle text-center rank-position">{position + 1}</span>
			</div>
			<span className="text-center">{getLastnameInitial(user?.Profile?.DisplayName)}</span>
			<span>{`${user?.StatValue} ${pointsLabelShort}`}</span>
		</div>
	);
}

TopThreeCard.defaultProps = {
	user: null,
	pointsLabelShort: "",
	position: "",
};

TopThreeCard.propTypes = {
	user: PropTypes.object,
	pointsLabelShort: PropTypes.string,
	position: PropTypes.number,
};

export default TopThreeCard;
