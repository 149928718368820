import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ComPanel from 'components/ComPanel/ComPanel';
import { playerLangGroupSelector } from 'selector/player';

function mapStateToProps(state) {
	return {
		newMessages: state.chat?.newMessages,
		acfPageOptions: state.wpcontent.acfPageOptions,
		channel: state.chat.channel,
		groupLang: playerLangGroupSelector(state),
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({

	}, dispatch)
)(ComPanel));
