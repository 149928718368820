/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-indent-props */
/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useStore } from "react-redux";
import dayjs from "dayjs";
import { now, toLocalTime } from 'utils/dateUtils'
import VideoEventReporter from "components/VideoPlayer/VideoEventReporter";
import VideoPropertyWatcher from "components/VideoPlayer/VideoPropertyWatcher";
import Rule from "Rule";
import DEBUG_DATA from "utils/VideoPlayerDebug";
import { onMessageAction } from "redux/chat";

const TYPE_MP4 = "video/mp4";
const TYPE_ISM = "application/vnd.ms-sstr+xml";
const PRE_RECORDED_STATUS = 'Prerecorded';

const { amp } = window;

let instances = [];

window.__getVideoInstances = () => instances; // eslint-disable-line

const defaultOptions = {
	nativeControlsForTouch: true,
	disableFullscreenButton: true,
	controls: true,
	autoplay: true,
	width: 640,
	height: 480,
	logo: { enabled: false },
	azureHtml5JS: {
		maxTotalRetries: 1000,
		maxRetryPerSegment: 1000,
	},
	hotKeys: {
		enableFullscreen: false,
	},
};

const doneCues = [];

function VideoPlayer({
	activity,
	src,
	adjustedTime,
	options = {},
	setBubbleVolume,
	volume,
	setStartTime,
	startTime,
	muted,
	setMuted,
	setUrl,
	feedSwitched,
	setFeedSwitched,
	setBubbleSrc,
	channel,
	setState,
}) {
	if (!src) {
		console.error("src null")
		return null;
	}
	const playerRef = useRef();
	const [player, setPlayer] = useState(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isPreRecordedStarted, setIsPreRecordedStarted] = useState(false);
	const debugRef = useRef();
	const type = src.indexOf(".mp4") >= 0 ? TYPE_MP4 : TYPE_ISM;
	const opts = useMemo(
		() => ({
			...defaultOptions,
		}),
		[options]
	);


	let timer = null;
	let preRecordedTimer = null;

	const enteredTime = (Date.now() - activity.date_begin.valueOf()) / 1000;
	let percentageCompleted = 0;
	let totalLength = 0;
	let videoStarted = false;
	let videoTwentyFive = false;
	let videoFifty = false;
	let videoSeventyFive = false;
	let videoNinety = false;
	let videoComplete = false;

	const dispatch = useDispatch();
	const state = useStore().getState();

	function getSecondsVideoPlayer() {
		return player.currentMediaTime() || player.currentTime() || 0;
	}

	function calculateTimeElapsep() {
		const endTime = Date.now();
		const begin = activity?.customPayload?.second_feed_begin || 0;
		const diffBegin = (Date.now() - activity.date_begin.valueOf()) / 1000;
		if(activity.customPayload.second_feed_link && (src === activity.customPayload.second_feed_link && begin >= activity.customPayload.second_feed_begin)) {
			return (diffBegin - activity.customPayload.second_feed_begin);
		}
		if (startTime) {
			return (endTime - startTime) / 1000;
		}
		return 0;
	}

	function canPlay() {
		if (activity?.data?.activity_type !== PRE_RECORDED_STATUS) {
			const seconds = getSecondsVideoPlayer();
			const t = Math.min(calculateTimeElapsep(), player.duration());
			if (t && seconds < t) {
				return t;
			}

			return null;
		}

		return false;
	}
	function play() {
		// if((((Date.now() - activity.date_begin.valueOf()) / 1000) >= activity?.customPayload?.second_feed_begin) && activity?.customPayload?.second_feed_begin) {
		// 	player.currentTime(((Date.now() - activity.date_begin.valueOf()) / 1000) - activity.customPayload.second_feed_begin);
		// }
		if (activity?.data?.activity_type !== PRE_RECORDED_STATUS) {
			player.currentTime(getSecondsVideoPlayer() + calculateTimeElapsep());
		}
		else if (activity?.data?.activity_type === PRE_RECORDED_STATUS) {
			player.currentTime(((Date.now() - activity.date_begin.valueOf()) / 1000));
		}
	}

	useEffect(() => {
		if (player) {
			setTimeout(() => {
				if (muted) {
					player.muted(true);
				} else {
					player.volume(volume);
				}
			}, 200);
		}
	}, [player]);

	useEffect(() => {
		if (!startTime) {
			setStartTime(Date.now());
		}

		if (player && document) {
			const video = document
				.getElementsByClassName("vjs-player")[0];


			video.addEventListener("click", () => {
				if (!isPlaying) {
					play();
				}

				setIsPlaying(!player.paused());
			})
			document.addEventListener("keydown", (e) => {
				//Spacebar
				if(window.event.keyCode === 32){
					//only play/pause if not using the spacebar for something else
					if(e.target.classList[0] !== "vjs-controls-enabled" &&
					e.target.classList[0] !== "vjs-tech" &&
					e.target.classList[0] !== "input-textarea" &&
					e.target.classList[0] !== "input" &&
					e.target.classList[0] !== "bottom-container"){
						if(player.wasPaused || !player.hasStarted_){
							play();
							player.play();
						} else {
							player.pause();
						}

						//if player selected sync but play is handled by player itself
						if(e.target.classList[0] !== "vjs-controls-enabled"){
							play();
						}
						setIsPlaying(!player.paused());
					}
				}
			});
		}

		return () => {
			if (player) {
				setMuted(player.muted());
				setBubbleVolume(player.volume());
			}
		};
	}, [player, startTime]);

	useEffect(() => {
		function time() {
			if (player) {
				if((toLocalTime(activity.date_end).$d <= toLocalTime(Date.now()).$d)) {
					setState('ended');
					return;
				}
				const seconds = player.currentMediaTime() || player.currentTime() || 0;
				const currentTime = (Date.now() - activity.date_begin.valueOf()) / 1000;

				totalLength = player.duration();
				percentageCompleted = ( (currentTime - enteredTime) / totalLength) * 100;

				// is 0
				if (!videoStarted && percentageCompleted > 0 && percentageCompleted < 100) {
					videoStarted = true;
					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "play",
						eventLabel: activity.DisplayName,
					});
				}
				// is 25
				if (!videoTwentyFive && percentageCompleted > 25 && percentageCompleted < 100) {
					videoTwentyFive = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "25%",
						eventLabel: activity.DisplayName,
					});
				}
				// is 50
				if (!videoFifty && percentageCompleted > 50 && percentageCompleted < 100) {
					videoFifty = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "50%",
						eventLabel: activity.DisplayName,
					});
				}
				// is 75
				if (!videoSeventyFive && percentageCompleted > 75 && percentageCompleted < 100) {
					videoSeventyFive = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "75%",
						eventLabel: activity.DisplayName,
					});
				}
				// is 90
				if (!videoNinety && percentageCompleted > 90 && percentageCompleted < 100) {
					videoNinety = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "90%",
						eventLabel: activity.DisplayName,
					});
				}
				// is 100
				if (!videoComplete && percentageCompleted > 99 && percentageCompleted < 100) {
					videoComplete = true;

					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "live_video",
						eventAction: "100%",
						eventLabel: activity.DisplayName,
					});
				}

				DEBUG_DATA.currentMediaTime = new Date(
					(player.currentMediaTime() || 0) * 1000
				)
					.toISOString()
					.substr(11, 8);
				DEBUG_DATA.currentTime = new Date((player.currentTime() || 0) * 1000)
					.toISOString()
					.substr(11, 8);
				if (
					activity?.data?.event_stream &&
					activity?.data?.event_stream !== [] &&
					activity?.data?.event_stream !== undefined &&
					activity?.data?.event_stream !== null
				) {
					activity?.data?.event_stream.forEach((cue) => {
						if (currentTime >= cue.ts && !doneCues.includes(cue.ts)) {
							doneCues.push(cue.ts);
							const isGrantMission =
								cue.action === "_GRANT_ITEM_" && cue.data.charAt(0) === "m";

							if (currentTime - cue.ts < 10 || isGrantMission) {
								let { data } = cue;

								if (typeof data === "object") {
									data = JSON.stringify({
										...data,
										expiration: dayjs()
											.add(data.expiration || 30, "seconds")
											.toString(),
									});
								}

								if(cue.action === "_START_OBJECTIVE_") {
									data = JSON.stringify({
										item_id: cue.data,
										expiration: dayjs()
											.add(data.expiration || 30, "seconds")
											.toString(),
									});
								}
								if(cue.action === "_LEADERBOARD_") {
									data = JSON.stringify({
										expiration: dayjs()
											.add(data.expiration || 30, "seconds")
											.toString(),
										'instanceId': state?.chat?.channel,
										statName: "points",
									})
								}

								onMessageAction(dispatch)('_SYSTEM_', data, state?.playfab?.PlayFabId, cue.action, null);
							}
						}
					});
				}

				const t = Math.min(adjustedTime, player.duration());
				if (t && seconds < t && activity?.data?.activity_type ) {
					player.currentTime(t);
				}

				if(currentTime >= activity?.customPayload?.second_feed_begin) {
					if( feedSwitched === false) {
						setTimeout(() => {
							setFeedSwitched(true);
						}, 1000);
					}
				}
			}

			timer = setTimeout(time, 100);
		}

		timer = setTimeout(time, 100);

		return () => {
			clearTimeout(timer);
		};
	}, [player, activity.data, adjustedTime, channel]);


	useEffect(() => {
		function checkPrerecordedStartTime(check) {
			if (player) {
				if (activity?.data?.activity_type === PRE_RECORDED_STATUS && !check) {
					player.pause();
					document.querySelector('.vjs-big-play-button').style.display = 'none';
					if (activity.date_begin.isBefore(now())) {
						document.querySelector('.vjs-big-play-button').style.display = 'block';
						player.play();
						setIsPreRecordedStarted(true);
						return true;
					}
				}
			}
			preRecordedTimer = setTimeout(checkPrerecordedStartTime, 100);
			return false;
		}

		if (activity?.data?.activity_type === PRE_RECORDED_STATUS) {
			checkPrerecordedStartTime(isPreRecordedStarted);
		}

		return () => {
			clearTimeout(preRecordedTimer);
		};
	}, [player, activity.data, adjustedTime, channel]);

	useEffect(() => {
		return () => {
			instances = instances.reduce((c, p) => {
				if (p) {
					p.dispose();
					return c;
				}
				c.push(p);
				return c;
			}, []);
		};
	}, []);

	useEffect(() => {
		if (!player) {
			const p = amp(playerRef.current, opts, () => {
				instances.push(this);
			});
			setPlayer(p);
		}

		return () => {
			cancelAnimationFrame(timer);
		};
	}, [player, opts, src, type]);

	useEffect(() => {
		if (player) {
			player.src([{ src, type }]);
			setBubbleSrc(src);
		}
	}, [player, src, type]);

	const propWatchers = (p) =>
		!p
			? []
			: [
				{
					method: "videoHeight",
					rule: Rule.fromObject(
						{
							input: {
								object: "player",
								property: "videoHeight()",
							},
							compareTo: [
								{
									object: "player",
									property: "height()",
									operator: "<",
								},
							],
						},
						{ window, player: p }
					),
				},
				{
					method: "videoBufferData",
					granular: "bufferLevel",
					rule: Rule.fromObject(
						{
							input: {
								object: "player",
								property: "videoBufferData().bufferLevel",
							},
							compareTo: [
								{
									value: 5,
									operator: "<",
								},
							],
						},
						{ window, player: p }
					),
				},
				{
					method: "videoBufferData",
					granular: "perceivedBandwidth",
					rule: Rule.fromObject(
						{
							input: {
								object: "player",
								property: "videoBufferData().perceivedBandwidth",
							},
							compareTo: [
								{
									value: 3 * 1000 * 1000, // in mbps
									operator: "<",
								},
							],
						},
						{ window, player: p }
					),
				},
			  ];

	function getWatchers() {
		if (!player) return null;
		return (
			type !== "video/mp4" &&
			propWatchers(player).map((w) => (
				<VideoPropertyWatcher
					key={JSON.stringify(w.method + "_" + w.granular)}
					player={player}
					method={w.method}
					granular={w.granular}
					rule={w.rule}
				/>
			))
		);
	}

	useEffect(() => {
		if(player) {
			const newSrc = activity?.customPayload?.second_feed_link;
			setUrl(newSrc);
			setBubbleSrc(newSrc);
			const videoPlayer = document.querySelector(".azuremediaplayer");
			if(src !== newSrc) videoPlayer.classList.add('video-transition-fadein');
			player.currentTime(canPlay());
		}
	}, [feedSwitched]);

	useEffect(() => {
		//Change the video src to the second channel if the user joins after the event start and it is past the feed switch time
		const newSrc = activity?.customPayload?.second_feed_link;
		//the time here is in seconds
		const time = (Date.now() - activity.date_begin.valueOf()) / 1000;
		if(player && newSrc) {
			if(time >= activity?.customPayload?.second_feed_begin) {
				if( feedSwitched === false) {
					setTimeout(() => {
						setFeedSwitched(true);
					}, 1000);
				}
			}
		}
	}, [player, src, type])

	return (
		<>
			<div className="video-player">
				<div className="debug" ref={debugRef} />

				<video
					ref={playerRef}
					id="video-stream"
					className="azuremediaplayer amp-default-skin amp-big-play-centered"
					tabIndex="0"
				/>
				<VideoEventReporter player={player} event="error" hasInitial />
				<VideoEventReporter
					player={player}
					event="downloadbitratechanged"
					method="currentDownloadBitrate"
				/>
				<VideoEventReporter
					player={player}
					event="playbackbitratechanged"
					method="currentPlaybackBitrate"
				/>

				{getWatchers()}
			</div>
		</>
	);
}

VideoPlayer.defaultProps = {
	src: "",
	activity: {},
	options: {},
	adjustedTime: null,
	muted: false,
	setMuted: null,
	setBubbleVolume: null,
	volume: 1,
	setStartTime: null,
	startTime: null,
	setUrl: () => {},
	feedSwitched: false,
	setFeedSwitched: () => {},
	setBubbleSrc: () => {},
	channel: null,
	setState: () => {},
};

VideoPlayer.propTypes = {
	src: PropTypes.string,
	options: PropTypes.object,
	activity: PropTypes.object,
	adjustedTime: PropTypes.number,
	muted: PropTypes.bool,
	setBubbleVolume: PropTypes.func,
	volume: PropTypes.number,
	setStartTime: PropTypes.func,
	startTime: PropTypes.number,
	setMuted: PropTypes.func,
	setUrl: PropTypes.func,
	feedSwitched: PropTypes.bool,
	setFeedSwitched: PropTypes.func,
	setBubbleSrc: PropTypes.func,
	channel: PropTypes.string,
	setState: PropTypes.func,
};

export default VideoPlayer;
