/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const ColSm = ({
	children,
}) => {
	return (
		<div className="col-sm">
			{children}
		</div>
	);
}

ColSm.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ColSm;
