/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import parse from "html-react-parser";

function SelectField({
	name,
	options,
	value,
	onChange,
	acfPageOptions,
	disabled,
	placeholder,
}) {
	if (!options || options.length === 0) return "Loading...";

	const slug = name.toLowerCase().replace(" ", "_");

	//TODO: Move thoses styles to scss

	const customStyles = {
		control: (provided) => ({
			...provided,
			background: acfPageOptions?.acf?.theme?.color?.background?.primary,
			border: `2px solid ${acfPageOptions?.acf?.theme?.color?.secondary}`,
			outline: "none",
			padding: "0.8em",
			boxShadow: "none",
			"&:hover": {
				border: `2px solid ${acfPageOptions?.acf?.theme?.color?.secondary}`,
			},
		}),
		container: (provided) => ({
			...provided,
			outline: "none",
		}),
		input: (provided) => ({
			...provided,
			color: "white",
			fontSize: "1.3em",
		}),
		placeholder: (provided) => ({
			...provided,
			color: "white",
			fontSize: "1.3em",
		}),
		singleValue: (provided) => ({
			...provided,
			color: "white",
			fontSize: "1.3em",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "white",
			transform: "scale(0.8)",
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		option: (provided, state) => ({
			...provided,
			color: "white",
			fontSize: "1.3em",
			background: state.isFocused
				? acfPageOptions?.acf?.theme?.color?.background?.secondary
				: acfPageOptions?.acf?.theme?.color?.background?.primary,
		}),
		menu: (provided) => ({
			...provided,
			background: "#262A32",
		}),
		menuList: (provided) => ({
			...provided,
			padding: "0",
		}),
	};

	const val = options.find((option) => option.value === value);

	return (
		<div className="field select-field">
			<label htmlFor={slug}>{name && parse(name)}</label>
			<div className="input-wrapper">
				<Select
					placeholder={placeholder || "Select..."}
					styles={customStyles}
					options={options}
					value={val}
					onChange={onChange}
					menuPlacement="bottom"
					formatOptionLabel={(data) => {
						return <span>{data?.label && parse(data?.label)}</span>;
					}}
					isDisabled={disabled}
				/>
			</div>
		</div>
	);
}

SelectField.propTypes = {
	name: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.string,
	onChange: PropTypes.func,
	acfPageOptions: PropTypes.object,
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
};

SelectField.defaultProps = {
	name: "",
	options: [],
	value: "",
	onChange: () => {},
	acfPageOptions: {},
	disabled: false,
	placeholder: "",
};

export default SelectField;
