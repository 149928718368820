/** @format */

import React, {useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import MenuIcons from "../MenuIcons";

function NavOption({
	link,
	text,
	showText,
	logOut,
	onClickShowLogOutModal,
	onClick,
	withLink,
	itemName,
	highlight,
	highlightable,
	order,
}) {
	
	const [highlighted, setHighlighted] = useState(highlight);
	
	function handleHighlight() {
		if (highlightable) {
			setHighlighted(!highlighted);
		}
	}

	const location = useLocation();
	
	return (
		<li
			style={{ order }}
			className={logOut === "true" ? "link-ctn log-out" : "link-ctn"}
		>
			{withLink ? (
				<NavLink
					exact
					className={`d-bloc position-relative w-100 text-decoration-none link ${
						location.pathname === link ? "active" : ""
					}`}
					to={link}
					onClick={() => { onClick(); handleHighlight(); }}
				>
					<div className="content d-flex align-items-center">
						<MenuIcons itemName={itemName} />
						{showText && <span className="ms-3">{text}</span>}
					</div>
				</NavLink>
			) : (
				<button
					type="button"
					onClick={logOut ? () => onClickShowLogOutModal() : () => { onClick(); handleHighlight(); }}
					className="link d-flex align-items-center"
				>
					<MenuIcons itemName={itemName} />
					<span className={`ms-3 ${highlighted ? "highlight-text" : ""}`}>
						{text}
					</span>
				</button>
			)}
		</li>
	);
}

NavOption.propTypes = {
	link: PropTypes.string,
	itemName: PropTypes.string,
	text: PropTypes.string,
	showText: PropTypes.bool,
	logOut: PropTypes.string,
	onClickShowLogOutModal: PropTypes.func,
	onClick: PropTypes.func,
	withLink: PropTypes.bool,
	highlight: PropTypes.bool,
	highlightable: PropTypes.bool,
	order: PropTypes.string,
};

NavOption.defaultProps = {
	link: "",
	itemName: "",
	text: "",
	showText: true,
	logOut: "",
	onClickShowLogOutModal: () => {},
	onClick: null,
	withLink: true,
	highlight: false,
	highlightable: false,
	order: "",
};

export default NavOption;
