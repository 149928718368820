import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getQuestions, clearNewQuestions, postQuestion, broadcastMessage, clearNewMessages } from "redux/chat";
import { questionsAcceptedSelector, chatMessageMentionsSelector } from "selector/chat";
import { userNameSelector } from "selector/player";
import { statSelector } from "selector/stats";
import { USER_LEVELS, CATALOG } from "Constants";
import { itemInventorySelector } from "selector/inventory";
import { setHasChatted } from "redux/tracking";
import { writePlayerEvent, writeTelemetryEvent } from "redux/playfab";
import ComPanel from "../../companel/index";

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		channel: state.chat.channel,
		newQuestions: state.chat?.newQuestions,
		questions: questionsAcceptedSelector(state),
		fetchQuestions: state.chat?.fetch,
		connection: state.chat.connection,
		messages: state.chat.messages,
		ownPlayfabId: state.playfab?.PlayFabId,
		displayName: userNameSelector(state, {
			fallback: state.teams.userPrincipalName,
		}),
		isModerator: statSelector(state, { statName: "userlevel" }) === USER_LEVELS.MODERATOR,
		canChat: !itemInventorySelector(state, { itemId: CATALOG.disable_chat }),
		hasChatted: state.tracking?.hasChatted,
		newMessages: state.chat?.newMessages,
		messageAuthors: chatMessageMentionsSelector(state),
	};
}

export default withRouter(
	connect(mapStateToProps, (dispatch) =>
		bindActionCreators(
			{
				getQuestions,
				clearNewQuestions,
				postQuestion,
				broadcastMessage,
				setHasChatted,
				writePlayerEvent,
				writeTelemetryEvent,
				clearNewMessages,
			},
			dispatch
		)
	)(ComPanel)
);
