import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	broadcastMessage,
} from 'redux/chat';
import ChatSupportMessage from 'components/ChatSupport/ChatSupportMessage';
import { statSelector } from 'selector/stats';
import { USER_LEVELS, CATALOG } from 'Constants';
import { itemInventorySelector } from 'selector/inventory';
import { getOtherPlayerData } from 'redux/playfab';
import { setActiveProfileCard } from 'redux/profile_card';
import { otherPlayerDataSelector } from 'selector/chat';
import { supportMessageSelector } from 'selector/supportChat';

function mapStateToProps(state, props) {
	const message = supportMessageSelector(state, props);
	return {
		...message,
		playfabId: state.playfab?.PlayFabId,
		isModerator: statSelector(state, { statName: 'userlevel' }) === USER_LEVELS.MODERATOR,
		canChat: !itemInventorySelector(state, { itemId: CATALOG.disable_chat }),
		otherPlayerData: message && otherPlayerDataSelector(state, { PlayFabId: message.pfid }),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		broadcastMessage,
		getOtherPlayerData,
		setActiveProfileCard,
	}, dispatch)
)(ChatSupportMessage);
