/* eslint-disable no-unsafe-optional-chaining */
import { HubConnectionBuilder } from "@microsoft/signalr";
import { createSlice } from "@reduxjs/toolkit";
import { createDebouncedAsyncAction } from "utils/reduxhelpers";
import { debugTracker } from "middleware/tracker";
import { REACT_APP_CHAT_API, ROUTES } from "Constants";
import { userNameSelector, chatAuthSelector } from "selector/player";
import { instance } from "as-stack-sdk/sdk";
import uuid from "react-uuid";
import { createToast } from "./toastNotifications";

export const ON_MESSAGE_EVENT = "broadcastMessage";
export const MESSAGE_EMOJI = "_EMOJI_";
export const MESSAGE_SYSTEM = "_SYSTEM_";
export const MESSAGE_TRACKER_DEBUG = "_TRACKER_DEBUG_";
export const MESSAGE_MODERATOR = "_MODERATOR_";
export const SUPPORT_STATE = "_SUPPORT_STATE_";

/** @type {HubConnection} */
let chatSupportConnection = null;
const MAX_MESSAGES_LENGTH = 200;
const stack = instance;
export const chatSupportConnect = createDebouncedAsyncAction(
	"chatSupport/connect",
	(data, { dispatch, getState }) => {
		return new Promise((resolve) => {
			const state = getState();
			chatSupportConnection = new HubConnectionBuilder()
				.withUrl(REACT_APP_CHAT_API + "?username=" + chatAuthSelector(state))
				.withAutomaticReconnect({
					nextRetryDelayInMilliseconds: () => {
						return 500;
					},
				})
				//.configureLogging(window.signalR.LogLevel.Trace)
				.build();

			chatSupportConnection.start({ withCredentials: false }).then(() => {
				chatSupportConnection.onreconnected = () => {
					const channel = state?.chatSupport?.channel;
					const username = userNameSelector(state);

					if (channel && username) {
						dispatch(
							chatSupportJoinRoom({
								channel,
								displayName: username,
							})
						);
					}
				};

				resolve({
					connection: true,
					messages: [],
				});
			});
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				...action.payload,
			};
		},
	}
);

export const chatSupportLeaveRoom = createDebouncedAsyncAction(
	"chatSupport/leave-room",
	(data) => {
		return new Promise((resolve) => {
			if (data.channel) {
				chatSupportConnection.send("LeaveRoom", data.channel).then(() => {
					return resolve();
				});
			} else {
				resolve();
			}
		});
	},
	{
		fulfilled: (state) => {
			return {
				...state,
				channel: null,
			};
		},
	}
);

export const chatSupportJoinRoom = createDebouncedAsyncAction(
	"chatSupport/join-room",
	(data, { dispatch }) => {
		return new Promise((resolve) => {
			chatSupportConnection.send("JoinRoom", data.channel).then(() => {
				dispatch(
					broadcastMessage({
						username: MESSAGE_SYSTEM,
						message: data.username + " JOINED",
						channel: data.channel,
						pfid: data.playfabId,
					})
				);
				resolve({
					channel: data.channel,
				});
			});
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				channel: action.payload.channel,
			};
		},
	}
);

export const broadcastMessage = createDebouncedAsyncAction(
	"chatSupport/broadcastMessage",
	(data) => {
		return chatSupportConnection
			.send(ON_MESSAGE_EVENT, data.username, {
				type: data.type,
				content: data.message,
				group: data.channel,
				pfid: data.playfabId,
				key: null,
			})
			.then(() => ({ ...data }));
	},
	{}
);

export const onMessageAction = (state, dispatch) => (n, m, pfid, type) => {
	const username = n;
	if (username === "_SYSTEM_") {
		const dataType = type;
		if (dataType === "_SUPPORT_STATE_" && m === "Agent Online") {
			dispatch(triggerChat());
			dispatch(triggerNewMessages());
		} else if (dataType === "_SUPPORT_STATE_" && m === "Issue Resolved") {
			dispatch(triggerResolve());
		} else {
			dispatch(triggerNewMessages());
			dispatch(
				createToast({
					content: `${state.wpcontent.acfOptions?.chat_support?.tech_name}: ${m}`,
					type: "Toast",
					id: uuid(),
					author: "_SYSTEM_",
					target_url: ROUTES.TECHSUPPORT,
				})
			);
		}
	}

	if (n === MESSAGE_MODERATOR) {
		// do nothing :)
	} else if (n === MESSAGE_EMOJI) {
		const event = new CustomEvent("emoji_chat", {
			detail: { data: m, id: "event-" + Date.now() },
		});
		window.dispatchEvent(event);
	} else if (n === MESSAGE_TRACKER_DEBUG) {
		const u = MESSAGE_TRACKER_DEBUG;
		dispatch(
			chatSupportMessageCallback({
				id: u + new Date().getTime(),
				author: u,
				message: JSON.stringify(debugTracker(), null, 4),
			})
		);
	} else {
		dispatch(
			chatSupportMessageCallback({
				id: n + new Date().getTime(),
				author: n,
				message: m,
				pfid,
			})
		);
	}
};

export const chatSupportListen = createDebouncedAsyncAction(
	"chatSupport/listen",
	(data, { getState, dispatch }) => {
		const state = getState();
		chatSupportConnection.on(ON_MESSAGE_EVENT, (n, m) => {
			try {
				const { content, pfid, type } = JSON.parse(m);
				return onMessageAction(state, dispatch)(n, content, pfid, type);
			} catch (e) {
				return onMessageAction(state, dispatch)(n, m, null, null);
			}
		});

		return Promise.resolve();
	},
	{}
);

export const chatSupportStopListen = createDebouncedAsyncAction(
	"chatSupport/stop-listen",
	() => {
		chatSupportConnection.off(ON_MESSAGE_EVENT);
		return Promise.resolve();
	},
	{}
);

export const chatSupportMessageCallback = createDebouncedAsyncAction(
	"chatSupport/messageCallback",
	(msg) => {
		return Promise.resolve({
			messages: [msg],
		});
	},
	{
		fulfilled: (state, action) => {
			const messages = [
				...state?.messages,
				...action.payload.messages.map((x) => {
					return {
						...x,
						timestamp: Date.now(),
					};
				}),
			]
				.reverse()
				.slice(0, MAX_MESSAGES_LENGTH)
				.reverse();
			return {
				...state,
				messages,
				newMessages: state.newMessages + action.payload.messages.length,
			};
		},
	}
);

export const requestSupport = createDebouncedAsyncAction(
	"chatSupport/requestSupport",
	stackAction((requestSupportChannel) => {
		return stack.Manager("RequestSupportChannel", {
			Subject: requestSupportChannel.Subject,
			Message: requestSupportChannel.Message,
		});
	}),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				channel: action.payload.SupportChannel.channel,
				subject: action.payload.SupportChannel.subject,
				openDate: action.payload.SupportChannel.openDate,
			};
		},
	}
);

function stackAction(action) {
	return (payload, { dispatch, getState }) => {
		return action(payload, dispatch, getState).then((resp) => {
			return resp;
		});
	};
}

const chatSupport = createSlice({
	name: "chatSupport",
	reducers: {
		clearMessages: (state) => {
			return {
				...state,
				messages: [],
			};
		},
		triggerChat: (state) => {
			return {
				...state,
				joined_room: true,
				is_resolved: false,
			};
		},
		triggerResolve: (state) => {
			return {
				...state,
				is_resolved: true,
				joined_room: false,
			};
		},
		triggerCancelled: (state) => {
			return {
				...state,
				is_resolved: true,
				joined_room: false,
			};
		},
		triggerNewMessages: (state) => {
			return {
				...state,
				new_messages: true,
			};
		},
		triggerNoNewMessages: (state) => {
			return {
				...state,
				new_messages: false,
			};
		},

		clearNewMessages: (state) => {
			return {
				...state,
				newMessages: 0,
			};
		},

		closeSupportSession: () => {
			return {
				messages: [],
				emojis: [],
				joined_room: false,
				channel: "",
				connection: false,
				subject: "",
				openDate: "",
				is_resolved: false,
				new_messages: false,
			};
		},
	},
	extraReducers: {
		...chatSupportConnect.reducers,
		...chatSupportMessageCallback.reducers,
		...chatSupportJoinRoom.reducers,
		...chatSupportLeaveRoom.reducers,
		...chatSupportListen.reducers,
		...requestSupport.reducers,
	},
	initialState: {
		messages: [],
		emojis: [],
		joined_room: false,
		channel: "",
		connection: false,
		subject: "",
		openDate: "",
		is_resolved: false,
		new_messages: false,
		newMessages: 0,
	},
});

export default chatSupport;

export const {
	setChatChannel,
	clearMessages,
	triggerChat,
	triggerResolve,
	triggerCancelled,
	triggerNewMessages,
	triggerNoNewMessages,
	closeSupportSession,
	clearNewMessages,
} = chatSupport.actions;
