import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchWPSpeakersData } from 'redux/wppage';
import Speakers from 'components/Pages/Speakers';
import { writeTelemetryEvent } from 'redux/playfab';
import { playerGroupSelector } from 'selector/player';

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		speakersData: state.wppage.speakers,
		tagsData: state.wppage.tags,
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
		acfNavigation: state?.wpcontent?.navigation,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		fetchWPSpeakersData,
		writeTelemetryEvent,
	}, dispatch)
)(Speakers);
