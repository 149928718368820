import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import parse from "html-react-parser";
import ActivityCard from 'containers/AgendaActivityCard';
import LoadingInnerContent from 'containers/LoadingInnerContent';
import {
	now,
} from 'utils/dateUtils';

import logPageView from 'utils/logPageView';
/*
* Home Page
*/

function MainHub({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	acfPageOptions,
	history,
	eventTiles,
}) {

	const [showModal, setShowModal] = useState(true);
	let percentageCompleted = 0;
	let totalLength = 0;
	let videoStarted = false;
	let videoTwentyFive = false;
	let videoFifty = false;
	let videoSeventyFive = false;
	let videoNinety = false;
	let videoComplete = false;

	useEffect(() => {
		setShowModal(!localStorage.getItem('seen_intro_modal'));
	}, []);

	useEffect(() => {
		history.push('/');
		if(playerStatistics) logPageView('home', playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);

	function onTimeUpdate() {
		getPercentage();
	}

	function getPercentage() {
		const myPlayer = document.querySelector(".videoModal");

		totalLength = myPlayer.duration;
		percentageCompleted = (myPlayer.currentTime / totalLength) * 100;

		// is 0
		if (!videoStarted && percentageCompleted > 0) {
			videoStarted = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "play",
				eventLabel: "Welcome Modal",
			});
		}
		// is 25
		if (!videoTwentyFive && percentageCompleted > 25) {
			videoTwentyFive = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "25%",
				eventLabel: "Welcome Modal",
			});
		}
		// is 50
		if (!videoFifty && percentageCompleted > 50) {
			videoFifty = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "50%",
				eventLabel: "Welcome Modal",
			});
		}
		// is 75
		if (!videoSeventyFive && percentageCompleted > 75) {
			videoSeventyFive = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "75%",
				eventLabel: "Welcome Modal",
			});
		}

		// is 90
		if (!videoNinety && percentageCompleted > 90) {
			videoNinety = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "90%",
				eventLabel: "Welcome Modal",
			});
		}

		// is 100
		if (!videoComplete && percentageCompleted > 99) {
			videoComplete = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "100%",
				eventLabel: "Welcome Modal",
			});
		}
	}

	function displayEvents() {
		//** FOR LIGHTSPEED WE ADD ITEM ID BECAUSE SOME BREAKOUTS ACTUALLY HAVE LIVE STREAM** */
		const EVENT_TO_REMOVE_1_NOAM = "st-6646539b7a6669.86286226";
		const EVENT_TO_REMOVE_2_NOAM = "st-660dbec2577e45.61577249";
		const EVENT_TO_REMOVE_3_APAC = "st-6658bbe6f1d8b3.12677227";
		const EVENT_TO_REMOVE_4_APAC = "st-664e74c447e8b5.37700359";
		const homePageEvents = eventTiles.filter(tile => tile?.activity?.data?.activity_type !== 'DealersLounge' && tile?.activity?.data?.activity_type !== 'MeetingRoom' && tile?.activity?.StoreTileId !== EVENT_TO_REMOVE_1_NOAM && tile?.activity?.StoreTileId !== EVENT_TO_REMOVE_2_NOAM&& tile?.activity?.StoreTileId !== EVENT_TO_REMOVE_3_APAC&& tile?.activity?.StoreTileId !== EVENT_TO_REMOVE_4_APAC && !tile?.activity.date_end.isBefore(now())).slice(0, acfPageOptions.acf?.home?.number_of_events).map(tile => {
			// eslint-disable-next-line react/jsx-props-no-spreading
			return <ActivityCard key={tile.id} {...tile} link={acfPageOptions.acf?.home?.link_to_event_page} withDate className="is-happening" />;
		});

		if(homePageEvents.length === 0) return null;

		return(
			<div className="live-agenda">
				<h4 className="agenda-title">
					{acfPageOptions.acf?.home?.live_events_section_title}
				</h4>
				<div className="live-events">
					{homePageEvents}
				</div>
			</div>
		)
	}

	function closeModal() {
		localStorage.setItem('seen_intro_modal', true);
		setShowModal(false);
	}

	function renderModal() {
		const nodeRef = React.useRef(null)
		const activeClass = (showModal && (acfPageOptions.acf?.welcome_modal?.video_link || acfPageOptions.acf?.welcome_modal?.description)) ? 'show-modal' : null;
		return (
			<CSSTransition in={showModal} timeout={600} unmountOnExit nodeRef={nodeRef}>
				<div className={`intro-modal ${activeClass}`}>
					<div className="wrapper">
						<button type="button" className="close-btn" onClick={closeModal}>
							{/* TODO: going to to need a awy to select close light or close dark */}
							<img src={acfPageOptions?.acf?.theme?.icon?.general?.exit?.image2?.url} alt="Close button" />
						</button>
						<div className="content">
							<h3 className="title">{acfPageOptions.acf?.welcome_modal?.title}</h3>
							<h6 className="text">{acfPageOptions.acf?.welcome_modal?.description}</h6>
							{acfPageOptions.acf?.welcome_modal?.video_link && (
								<div className="video">
									<video className="videoModal" src={acfPageOptions.acf?.welcome_modal?.video_link} controls onTimeUpdate={onTimeUpdate}/>
								</div>
							)}
						</div>
					</div>
				</div>
			</CSSTransition>
		);
	}

	return (
		<LoadingInnerContent isLoading={acfPageOptions.acf?.home === null || acfPageOptions.acf?.home?.length === 0} className="loadingWrapper">
			{renderModal()}
			<div className="page-wrapper homepage">
				<div className="sidenav-left-column" />
				<div className="content-column">
					<div className="background" style={{backgroundImage: acfPageOptions?.acf?.home?.background.url ? `url(${acfPageOptions?.acf?.home?.background.url})` : null}} />
					<div className="site-wrapper">
						<div className="inner-wrapper">
							<div className="home-header">
								<div className="home-content">
									<div className='home-content-wrapper'>
										<img className="title-img" src={ acfPageOptions.acf?.home?.title_image?.url } alt="" />
										<h2 className="title-ctn">{acfPageOptions.acf?.home?.main_title && parse(acfPageOptions.acf?.home?.main_title)}</h2>
										<div className="subtitle">{acfPageOptions.acf?.home?.subtitle && parse(acfPageOptions.acf?.home?.subtitle)}</div>
										<div className="description">{acfPageOptions.acf?.home?.description && parse(acfPageOptions.acf?.home?.description)}</div>
									</div>
								</div>
								<div className="logo-img -relative">
									<div className="wrapper">{acfPageOptions.acf?.home?.brand_image && <img src={acfPageOptions.acf?.home?.brand_image?.sizes?.large} alt="" />}</div>
								</div>
							</div>
							{eventTiles && eventTiles.length > 0 && acfPageOptions.acf?.home?.number_of_events && (
								displayEvents()
							)}
						</div>
					</div>
				</div>
			</div>
		</LoadingInnerContent>
	);
}

MainHub.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	acfPageOptions: null,
	lang: 'en',
	myAgendaTiles: [],
	eventTiles: [],
};

MainHub.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	acfPageOptions: PropTypes.object,
	lang: PropTypes.string,
	myAgendaTiles: PropTypes.array,
	eventTiles: PropTypes.array,
	history: PropTypes.object.isRequired,
};

export default MainHub;
