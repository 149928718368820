export function setMobileStyleTheme(mobileTheme){
	const root = document.documentElement;

	function parseUrl(url) {
		return `url(${url})`
	}

	//// SET Logo
	root?.style.setProperty('--m-logo-main', mobileTheme?.acf?.mobiletheme?.logo?.main?.url ? mobileTheme?.acf?.mobiletheme?.logo?.main?.url : '');
	root?.style.setProperty('--m-logo-secondary', mobileTheme?.acf?.mobiletheme?.logo?.secondary?.url ? mobileTheme?.acf?.mobiletheme?.logo?.secondary?.url : '');
	root?.style.setProperty('--m-logo-website-icon', mobileTheme?.acf?.mobiletheme?.logo?.website_icon?.url ? mobileTheme?.acf?.mobiletheme?.logo?.website_icon?.url : '');

	//// SET Colors
	root?.style.setProperty('--m-primary-color', mobileTheme?.acf?.mobiletheme?.color?.primary ? mobileTheme?.acf?.mobiletheme?.color?.primary : '');
	root?.style.setProperty('--m-primary-text-color', mobileTheme?.acf?.mobiletheme?.color?.text.primary ? mobileTheme?.acf?.mobiletheme?.color?.text.primary : '');

	//// SET Backgrounds
	root?.style.setProperty('--m-background-primary', mobileTheme?.acf?.mobiletheme?.background?.primary?.url ? parseUrl(mobileTheme?.acf?.mobiletheme?.background?.primary?.url) : '');

	//// SET FONTS
	root?.style.setProperty('--m-title-font', mobileTheme?.acf?.mobiletheme?.text?.font_heading ? mobileTheme?.acf?.mobiletheme?.text?.font_heading : '');

	if(mobileTheme?.acf?.mobiletheme?.text?.font_heading === 'Gotham'){
		root?.style.setProperty('--m-heading-m-size', '23px');
		root?.style.setProperty('--m-heading-m-line-height', '102.5%');

		root?.style.setProperty('--m-heading-s-size', '21px');
		root?.style.setProperty('--m-heading-s-line-height', '102.5%');

		root?.style.setProperty('--m-heading-xs-size', '19px');
		root?.style.setProperty('--m-heading-xs-line-height', '110%');

		root?.style.setProperty('--m-heading-xss-size', '17px');
		root?.style.setProperty('--m-heading-xss-line-height', '102.5%');

		root?.style.setProperty('--m-heading-xsss-size', '15px');
		root?.style.setProperty('--m-heading-xsss-line-height', '102.5%');
	} else {
		root?.style.setProperty('--m-heading-m-size', '22px');
		root?.style.setProperty('--m-heading-m-line-height', '102.5%');

		root?.style.setProperty('--m-heading-s-size', '20px');
		root?.style.setProperty('--m-heading-s-line-height', '102.5%');

		root?.style.setProperty('--m-heading-xs-size', '18px');
		root?.style.setProperty('--m-heading-xs-line-height', '110%');

		root?.style.setProperty('--m-heading-xss-size', '16px');
		root?.style.setProperty('--m-heading-xss-line-height', '102.5%');

		root?.style.setProperty('--m-heading-xsss-size', '14px');
		root?.style.setProperty('--m-heading-xsss-line-height', '102.5%');
	}

	root?.style.setProperty('--m-content-font', mobileTheme?.acf?.mobiletheme?.text?.font_body ? mobileTheme?.acf?.mobiletheme?.text?.font_body : '');

	if(mobileTheme?.acf?.mobiletheme?.text?.font_body === 'Gotham'){
		root?.style.setProperty('--m-body-m-size', '15px');
		root?.style.setProperty('--m-body-m-line-height', '135%');

		root?.style.setProperty('--m-body-s-size', '13px');
		root?.style.setProperty('--m-body-s-line-height', '130%');

		root?.style.setProperty('--m-body-xs-size', '11px');
		root?.style.setProperty('--m-body-xs-line-height', '103.5%');
	} else {
		root?.style.setProperty('--m-body-m-size', '14px');
		root?.style.setProperty('--m-body-m-line-height', '135%');

		root?.style.setProperty('--m-body-s-size', '12px');
		root?.style.setProperty('--m-body-s-line-height', '130%');

		root?.style.setProperty('--m-body-xs-size', '10px');
		root?.style.setProperty('--m-body-xs-line-height', '103.5%');
	}

	//// SET Buttons
	//Primary
	root?.style.setProperty('--m-button-primary-background-color', mobileTheme?.acf?.mobiletheme?.button?.primary?.background_color ? mobileTheme?.acf?.mobiletheme?.button?.primary?.background_color : '');
	root?.style.setProperty('--m-button-primary-border-color', mobileTheme?.acf?.mobiletheme?.button?.primary?.border_color ? mobileTheme?.acf?.mobiletheme?.button?.primary?.border_color : '');
	root?.style.setProperty('--m-button-primary-border-size', mobileTheme?.acf?.mobiletheme?.button?.primary?.border_size ? mobileTheme?.acf?.mobiletheme?.button?.primary?.border_size : '');
	root?.style.setProperty('--m-button-primary-text-color', mobileTheme?.acf?.mobiletheme?.button?.primary?.text_color ? mobileTheme?.acf?.mobiletheme?.button?.primary?.text_color : '');

	//Secondary
	root?.style.setProperty('--m-button-secondary-background-color', mobileTheme?.acf?.mobiletheme?.button?.secondary?.background_color ? mobileTheme?.acf?.mobiletheme?.button?.secondary?.background_color : '');
	root?.style.setProperty('--m-button-secondary-border-color', mobileTheme?.acf?.mobiletheme?.button?.secondary?.border_color ? mobileTheme?.acf?.mobiletheme?.button?.secondary?.border_color : '');
	root?.style.setProperty('--m-button-secondary-border-size', mobileTheme?.acf?.mobiletheme?.button?.secondary?.border_size ? mobileTheme?.acf?.mobiletheme?.button?.secondary?.border_size : '');
	root?.style.setProperty('--m-button-secondary-text-color', mobileTheme?.acf?.mobiletheme?.button?.secondary?.text_color ? mobileTheme?.acf?.mobiletheme?.button?.secondary?.text_color : '');

	//Tertiary
	root?.style.setProperty('--m-button-tertiary-background-color', mobileTheme?.acf?.mobiletheme?.button?.tertiary?.background_color ? mobileTheme?.acf?.mobiletheme?.button?.tertiary?.background_color : '');
	root?.style.setProperty('--m-button-tertiary-border-color', mobileTheme?.acf?.mobiletheme?.button?.tertiary?.border_color ? mobileTheme?.acf?.mobiletheme?.button?.tertiary?.border_color : '');
	root?.style.setProperty('--m-button-tertiary-border-size', mobileTheme?.acf?.mobiletheme?.button?.tertiary?.border_size ? mobileTheme?.acf?.mobiletheme?.button?.tertiary?.border_size : '');
	root?.style.setProperty('--m-button-tertiary-text-color', mobileTheme?.acf?.mobiletheme?.button?.tertiary?.text_color ? mobileTheme?.acf?.mobiletheme?.button?.tertiary?.text_color : '');

	//Inverted
	root?.style.setProperty('--m-button-inverted-background-color', mobileTheme?.acf?.mobiletheme?.button?.inverted?.background_color ? mobileTheme?.acf?.mobiletheme?.button?.inverted?.background_color : '');
	root?.style.setProperty('--m-button-inverted-border-color', mobileTheme?.acf?.mobiletheme?.button?.inverted?.border_color ? mobileTheme?.acf?.mobiletheme?.button?.inverted?.border_color : '');
	root?.style.setProperty('--m-button-inverted-border-size', mobileTheme?.acf?.mobiletheme?.button?.inverted?.border_size ? mobileTheme?.acf?.mobiletheme?.button?.inverted?.border_size : '');
	root?.style.setProperty('--m-button-inverted-text-color', mobileTheme?.acf?.mobiletheme?.button?.inverted?.text_color ? mobileTheme?.acf?.mobiletheme?.button?.inverted?.text_color : '');

	// Icon
	root?.style.setProperty('--m-button-icon-background-color', mobileTheme?.acf?.mobiletheme?.button?.icon?.background_color ? mobileTheme?.acf?.mobiletheme?.button?.icon?.background_color : '');
	root?.style.setProperty('--m-button-icon-border-color', mobileTheme?.acf?.mobiletheme?.button?.icon?.border_color ? mobileTheme?.acf?.mobiletheme?.button?.icon?.border_color : '');
	root?.style.setProperty('--m-button-icon-border-size', mobileTheme?.acf?.mobiletheme?.button?.icon?.border_size ? mobileTheme?.acf?.mobiletheme?.button?.icon?.border_size : '');
	root?.style.setProperty('--m-button-icon-text-color', mobileTheme?.acf?.mobiletheme?.button?.icon?.text_color ? mobileTheme?.acf?.mobiletheme?.button?.icon?.text_color : '');
	
}
