import { createSlice } from '@reduxjs/toolkit';

/**
* Keep track of the notifications seen by the user
*/

const inventory = createSlice({
	name: 'inventory',
	reducers: {
		addSeenNotification: (state, action) => {
			return {
				...state,
				seen_notifications: [
					...state.seen_notifications,
					action.payload.id,
				].filter((item, index, a) => a.indexOf(item) === index),
			};
		},
	},
	extraReducers: {
	},
	initialState: {
		seen_notifications: [],
	},
});

export default inventory;

export const { addSeenNotification } = inventory.actions;
