import { createSlice } from '@reduxjs/toolkit';

const auth = createSlice({
	name: 'auth',
	reducers: {

		/**
		*Set the user's JWT token from the login
		*/
		setJWT: (state, action) => {
			return {
				...state,
				userJWT: action.payload,
				method: 'JWTLogIn',
			};

		},

		/**
		*Set the login method used by the user, Web or Teams
		*/
		setLoginMethod: (state, action) => {
			return {
				...state,
				method: action.payload,
			};
		},

		/**
		*Set the step the player has reached during the authentication process
		*/
		setAuthStep: (state, action) => {
			return {
				...state,
				step: action.payload,
			};
		},

		/**
		*Set if the WordPress data is loaded
		*/
		setDataLoaded: (state) => {
			return {
				...state,
				dataLoaded: true,
			};
		},

		/**
		*Set if the chat is authenticated
		*/
		setChatAuth: (state) => {
			return {
				...state,
				chatAuthSet: true,
			};
		},

	},
	extraReducers: {
	},
	initialState: {
		userJWT: null,
		method: null,
		dataLoaded: false,
		chatAuthSet: false,
		step: 'welcomeInit',
	},
});

export default auth;

export const {
	setJWT,
	setLoginMethod,
	setAuthStep,
	setDataLoaded,
	setChatAuth,
} = auth.actions;