export default function parseItemData(item, lang = 'en') {
	const obj = {
		...item,
		data: item.data.reduce((c, d) => {
			let key = d.dataKey.toLowerCase().trim().replace(' ', '_');

			const lngIndex = key.indexOf('_' + lang);
			if (lngIndex >= 0) {
				key = key.substring(0, lngIndex);
			}
			c[key] = d.dataVal;
			return c;
		}, {}),
	};
	return obj;
}
