import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import NewQuestion from 'components/ComPanel/Questions/NewQuestion';
import { postQuestion } from 'redux/chat';
import {
	writePlayerEvent,
} from 'redux/playfab';

function mapStateToProps(state) {
	return {
		channel: state.chat.channel,
		acfPageOptions: state.wpcontent.acfPageOptions.acf,
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		postQuestion,
		writePlayerEvent,
	}, dispatch)
)(NewQuestion));
