const LOG_KEY = 'streamdata';
const LOG_HISTORY_LENGTH = 200;

export default function logLocalStorage(key, value) {
	const ls = JSON.parse(localStorage.getItem(LOG_KEY) || '{}');
	if (!ls[key]) ls[key] = [];
	ls[key].push(value);
	if (ls[key].length > LOG_HISTORY_LENGTH) {
		ls[key].reverse();
		ls[key] = ls[key].slice(0, LOG_HISTORY_LENGTH);
		ls[key].reverse();
	}
	localStorage.setItem(LOG_KEY, JSON.stringify(ls));
}
