import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import uuid from 'react-uuid';
import Card from "./Card";
import MediaModal from "./MediaModal";

function MediasList({ medias, acfPageOptions, filterParam }) {
	const [showMediaModal, setShowMediaModal] = useState(false);
	const [viewedMedia, setViewedMedia] = useState(null);

	useEffect(() => {
		if(  viewedMedia) {
			setShowMediaModal(true);
		}
	}, [viewedMedia])

	return (
		<>
			{filterParam?.logo?<div className="list-header"> <img src={filterParam?.logo?.sizes?.thumbnail} alt={filterParam?.category_label} /></div> : (filterParam?.category_label && <h4>{filterParam?.category_label}</h4>)}
			<div className='medias'>
				{medias.map((media) => {
					if(media.media_type === 'pdf') {
						return <Card key={uuid()} setViewedMedia={setViewedMedia} setShowMediaModal={setShowMediaModal} media={media} acfPageOptions={acfPageOptions} single={medias.length <= 1}/>
					}

					return <Card key={uuid()} setViewedMedia={setViewedMedia} setShowMediaModal={setShowMediaModal} media={media} acfPageOptions={acfPageOptions} single={medias.length <= 1} />
				})}
				<MediaModal acfPageOptions={acfPageOptions} media={viewedMedia} setViewedMedia={setViewedMedia} showMediaModal={showMediaModal} setShowMediaModal={setShowMediaModal} />
			</div>
		</>
	);
}

MediasList.defaultProps = {
	medias: [],
	acfPageOptions: null,
	filterParam: null,
};

MediasList.propTypes = {
	medias: PropTypes.array,
	acfPageOptions: PropTypes.object,
	filterParam: PropTypes.object,
};

export default MediasList;
