import React from 'react';
import PropTypes from 'prop-types';

function LoadingGif({acfPageOptions, status, cardLoader}) {
	return (
		<div className={`loading-gif-wrapper ${status}`}>
			<div className={`loading-inner-gif ${cardLoader}`}>
				<img alt='loading' src={acfPageOptions?.acf?.theme?.logo?.loading?.sizes?.large} className="loader" />
			</div>
		</div>
	)
}

LoadingGif.propTypes = {
	acfPageOptions: PropTypes.object,
	status: PropTypes.string,
	cardLoader: PropTypes.string,
}

LoadingGif.defaultProps = {
	acfPageOptions: null,
	status: null,
	cardLoader: null,
}

export default LoadingGif;