/** @format */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as MicrosoftTeams from "@microsoft/teams-js";
import parse from "html-react-parser";

import { ROUTES } from "Constants";
import ActivityCard from "containers/Channel/ActivityCard";
import { now } from "utils/dateUtils";
import Layout from "components/Layout/Title";

import logPageView from 'utils/logPageView';
import NotFound from "containers/Pages/NotFound";

/*
 * Single Speaker page
 */

function Speaker({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	speaker,
	fetchWPChannelsData,
	fetchWPActivities,
	fetchWPSpeakersData,
	acfPageOptions,
	authMethod,
	playerGroup,
}) {
	if (!speaker) {
		return <NotFound />;
	}
	const events =
		speaker?.tiles?.filter(
			(tile) => !tile?.activity.date_end.isBefore(now())
		) || [];
	useEffect(() => {
		const data = {
			playerGroup,
		};
		fetchWPChannelsData(data);
		fetchWPActivities(data);
		fetchWPSpeakersData(data);
	}, []);

	useEffect(() => {
		//Log PageView
		logPageView('speakers', playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);

	function goToLink(url) {
		return !authMethod
			? window.open(url)
			: MicrosoftTeams.executeDeepLink(url);
	}

	function renderActivities() {
		if (!speaker?.tiles || speaker?.tiles?.length === 0) return null;

		return (
			<div className="small-channel-tiles">
				<h4 className="event-section-title">
					{acfPageOptions.acf?.speaker?.event_title || "EVENTS"}
				</h4>
				<div className="list">
					{speaker.tiles
						.filter(
							(tile) => !tile?.activity.date_end.isBefore(now())
						)
						.map((tile) => (
							// eslint-disable-next-line react/jsx-props-no-spreading
							<ActivityCard key={tile.id} {...tile} small link />
						))}
				</div>
			</div>
		);
	}

	return (
		<Layout
			titleSlot={acfPageOptions.acf?.speaker?.title}
			pageClass="speaker-single-page"
			backLink={ROUTES.SPEAKERS}
			backText={acfPageOptions.acf?.speaker?.back_button}
			backgroundUrl={acfPageOptions?.acf?.speaker?.background ? acfPageOptions?.acf?.speaker?.background : ''}
		>
			<div className="single-speaker-wrapper">
				<div
					className={`single-speaker-inner ${
						speaker?.tiles?.length === 0
							? "single-speaker-inner-alone"
							: ""
					}`}
				>
					<div className="row">
						{speaker?.acf?.photo && (
							<div className="speaker-col-image col appear-anim">
								<div className="single-speaker-thumbnail">
									<img
										src={speaker?.acf?.photo?.sizes?.large}
										className="wp-post-image"
										alt=""
									/>
								</div>
							</div>
						)}
						<div
							className={`speaker-col-content col appear-anim ${
								!speaker?.acf?.photo ? "no-photo" : ""
							}`}
						>
							<div className="speaker-entry-content">
								<p className="type">{speaker?.acf?.type}</p>
								<h2 className="entry-title">
									{speaker?.title?.rendered &&
										parse(speaker?.title?.rendered)}
								</h2>
								<p className="role">{speaker?.acf?.subtitle}</p>
								{speaker?.acf?.location && (
									<p className="location">
										<i className="icon-location" />
										<span>{speaker?.acf?.location}</span>
									</p>
								)}
								<div className="wysiwyg">
									{speaker?.content?.rendered &&
										parse(speaker?.content?.rendered)}
								</div>
								<ul className="speaker-meta">
									{speaker?.acf?.company && (
										<li className="speaker-meta-item">
											<span className="meta-title">
												{
													acfPageOptions.acf?.speaker
														?.company
												}
											</span>
											<div className="meta-content">
												<span>
													{speaker?.acf?.company}
												</span>
											</div>
										</li>
									)}

									{speaker?.acf?.phone && (
										<li className="speaker-meta-item">
											<span className="meta-title">
												{acfPageOptions.acf?.speaker?.phone}
											</span>
											<div className="meta-content">
												<span>
													{speaker?.acf?.phone}
												</span>
											</div>
										</li>
									)}

									{speaker?.acf?.email && (
										<li className="speaker-meta-item">
											<span className="meta-title">
												{acfPageOptions.acf?.speaker?.email}
											</span>
											<div className="meta-content">
												<span>
													{speaker?.acf?.email}
												</span>
											</div>
										</li>
									)}

									{speaker?.acf?.website && (
										<li className="speaker-meta-item ">
											<span className="meta-title">
												{
													acfPageOptions.acf?.speaker
														?.website
												}
											</span>
											<button
												className="meta-content"
												type="button"
												onClick={() =>
													goToLink(
														speaker?.acf?.website
													)
												}
											>
												{speaker?.acf?.website}
											</button>
										</li>
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
				{events.length > 0 && renderActivities()}
			</div>
		</Layout>
	);
}

Speaker.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	speaker: null,
	acfPageOptions: null,
	authMethod: null,
	playerGroup: null,
};

Speaker.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	speaker: PropTypes.object,
	acfPageOptions: PropTypes.object,
	fetchWPChannelsData: PropTypes.func.isRequired,
	fetchWPActivities: PropTypes.func.isRequired,
	fetchWPSpeakersData: PropTypes.func.isRequired,
	authMethod: PropTypes.string,
	playerGroup: PropTypes.string,
};

export default Speaker;
