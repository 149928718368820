import { createSelector } from '@reduxjs/toolkit';
import { channelTilesSelector} from './channels';
import { notificationSelector } from './inventory';

const wordpressSpeakersSelector = state => state.wppage?.speakers;
const speakerSlugFromRoute = (state, props) => props.match?.params?.speakerId;

/**
* Find the speaker matching the route slug and add its tiles
*/

export const singleSpeakerSelector = createSelector(
	[wordpressSpeakersSelector, speakerSlugFromRoute, channelTilesSelector],
	(wordpressSpeakers, slug, tiles) => {
		if (!wordpressSpeakers || !slug || !tiles) return null;

		const speaker = wordpressSpeakers.find((s) => s.slug === slug);
		if (!speaker) return null;

		const speakerTiles = tiles.filter((tile) => {
		//Check before using filter method
			if (!tile || !tile.activity || !tile.activity.speakers) return false;
			return tile.activity.speakers.filter((s) => s.slug === slug).length > 0;
		});

		return {
			...speaker,
			tiles: speakerTiles.slice(0, 2),
		};
	}
);


/**
* Find the speaker matching a notification target URL
*/

export const singleNotifSpeakerSelector = createSelector(
	[wordpressSpeakersSelector, notificationSelector],
	(wordpressSpeakers, notification) => {
		if (!wordpressSpeakers || !notification) return null;

		const speaker = wordpressSpeakers.find(s => s.slug === notification?.target_url);
		if (!speaker) return null;

		return speaker;
	},
);
