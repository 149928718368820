/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from "html-react-parser";
import {ReactComponent as IconSearch} from "assets/icons/pack1/speakers/icon-search.svg";
import {ReactComponent as IconClose} from "assets/icons/pack1/speakers/icon-close-bold.svg";
import {ReactComponent as IconVideo} from "assets/icons/pack1/gallery/icon-gallery-video.svg";
import {ReactComponent as IconAudio} from "assets/icons/pack1/gallery/icon-gallery-audio.svg";
import {ReactComponent as IconImage} from "assets/icons/pack1/gallery/icon-gallery-image.svg";
import {ReactComponent as IconPdf} from "assets/icons/pack1/gallery/icon-gallery-pdf.svg";
import {ReactComponent as IconFilters} from "assets/icons/pack1/gallery/icon-filter.svg";
import dayjs from 'dayjs';
import { Accordion } from 'react-bootstrap';
import Checkbox from 'mobile/components/buttons/Checkbox';
import Button from 'mobile/components/buttons/Button';
import logPageView from "utils/logPageView";

function Galleries({ 
	galleriesData, 
	acfPageOptions,
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData, 
}) {
	const [showAllGalleries, setShowAllGalleries] = useState(true);
	const [noMatches, setNoMatches] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	const [showFilters, setShowFilters] = useState(false);

	const [videoType, setVideoType] = useState(false);
	const [imageType, setImageType] = useState(false);
	const [pdfType, setPdfType] = useState(false);
	const [audioType, setAudioType] = useState(false);

	const [isFiltered, setIsFiltered] = useState(false);
	const [filteredSelection, setFilteredSelection] = useState([]);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"galleries",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	function filterGalleries(filter, searchInput) {
		let galleriesResults = [];
		let finalResults = [];
		const galleriesArray = galleriesData.slice();

		let filteredNames = [];

		if(filter){
			setShowFilters(false);
			setIsFiltered(true);

			let selected = [];

			const checkedBoxes = document.querySelectorAll('input[name=media_type]:checked');
			selected = Array.from(checkedBoxes).map(x => x.value);

			if(selected.length > 0){
				setFilteredSelection(selected);

				filteredNames = galleriesArray.filter((el) => {
					return selected.some((f) => {
						return f === el.acf.media_type;
					});
				});
			} else {
				clearFilter();
				return;
			}
		} else {
			filteredNames = galleriesArray.filter((gallery) => {
				const searchStr = searchInput
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '');

				const nameMatches = gallery.title.rendered
					? gallery.title.rendered
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.includes(searchStr)
					: null;

				const typeMatches = gallery.acf.media_type
					? gallery.acf.media_type
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.includes(searchStr)
					: null;

				return nameMatches || typeMatches;
			});
		}

		if (filteredNames.length > 0) {
			setNoMatches(false);
			setShowAllGalleries(false);
			galleriesResults = [...filteredNames];
		} else {
			setSearchResults([]);
			setShowAllGalleries(false);
			setNoMatches(true);
		}

		// Removing duplicates
		finalResults = [...new Set(galleriesResults)];
		setSearchResults(finalResults);
	}

	function renderAllGalleries(galleries) {
		const allGalleriesArray = galleries.slice();
		// Sort the dates in array alphabetically
		const orderAllArray = allGalleriesArray.sort((a, b) =>
			a.acf.gallery_date.localeCompare(b.acf.gallery_date)
		);

		const groupsArray = orderAllArray.reduce((previousValue, currentValue) => {
			// get first date of name of current element
			const group = dayjs(currentValue.acf.gallery_date).format('DD MMMM');

			// if there is no property in accumulator with this letter create it
			if (!previousValue[group])
				previousValue[group] = { group, children: [currentValue] };
			// if there is push current element to children array for that letter
			else previousValue[group].children.push(currentValue);
			// return accumulator
			return previousValue;
		}, {});

		// since data at this point is an object, to get array of values
		// we use Object.values method
		const completeArray = Object.values(groupsArray);

		if (!completeArray) return null;

		return (
			<>
				{isFiltered && (
					<div className="filter-by-wrapper">
						<p>{acfPageOptions.acf?.gallery?.filtered_by}</p>
						{filteredSelection.map((choice) => {
							return (
								<span key={choice} onClick={() => removeChoice(choice)} onKeyDown={() => removeChoice(choice)} role="button" tabIndex="-1">{choice.slice(0,1).toUpperCase() + choice.slice(1, choice.length)} <IconClose /></span>
							);
						})}
						<span className="clear-filters" onClick={() => clearFilter()} onKeyDown={() => clearFilter()} role="button" tabIndex="-1">{acfPageOptions.acf?.gallery?.clear_filters} <IconClose /></span>
					</div>
				)}
				{searchResults && searchResults.length > 0 && (
					<div className="results">{acfPageOptions.acf?.gallery?.results} ({allGalleriesArray.length})</div>
				)}
				{completeArray.map((arrayGroup) => {
					return (
						<div
							className={`group-${arrayGroup.group.toLowerCase()}`}
							key={`group-${arrayGroup.group.toLowerCase()}`}
						>
							<div className="separator"><p>{arrayGroup.group}</p> <div className="line" /></div>
							<div className="letter-group">
								<div className="galleries-wrapper">
									{arrayGroup.children.map((child) => {
										return (
											<div key={child.id} className="gallery-block">
												<div className="date-ctn">
													<span className="date">{dayjs(child?.acf?.gallery_date).format('DD MMM')}</span>
													<span className="duration">{child?.acf?.duration}</span>
												</div>
												{child?.acf?.media_type === 'video' && (
													<div className="content-ctn" onClick={() => openInNewTab(child?.acf?.video_link)} onKeyDown={() => openInNewTab(child?.acf?.video_link)} role="button" tabIndex="-1">
														<img src={child?.acf?.preview_image?.url} alt={parse(child?.title?.rendered)} />
														<IconVideo />
													</div>
												)}
												{child?.acf?.media_type === 'audio' && (
													<div className="content-ctn" onClick={() => openInNewTab(child?.acf?.audio_link)} onKeyDown={() => openInNewTab(child?.acf?.audio_link)} role="button" tabIndex="-1">
														<img src={child?.acf?.preview_image?.url} alt={parse(child?.title?.rendered)} />
														<IconAudio />
													</div>
												)}
												{child?.acf?.media_type === 'image' && (
													<div className="content-ctn" onClick={() => openInNewTab(child?.acf?.image_link?.url)} onKeyDown={() => openInNewTab(child?.acf?.image_link)} role="button" tabIndex="-1">
														<img src={child?.acf?.preview_image?.url} alt={parse(child?.title?.rendered)} />
														<IconImage />
													</div>
												)}
												{child?.acf?.media_type === 'pdf' && (
													<div className="content-ctn" onClick={() => openInNewTab(child?.acf?.pdf_link)} onKeyDown={() => openInNewTab(child?.acf?.pdf_link)} role="button" tabIndex="-1">
														<img src={child?.acf?.preview_image?.url} alt={parse(child?.title?.rendered)} />
														<IconPdf />
													</div>
												)}

												<div className="title-ctn">{parse(child?.title?.rendered)}</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					);
				})}
			</>
		);
	}

	function searchNames(e) {
		e.preventDefault();
		const inputValue = document.querySelector('.galleries-form input').value.trim();
		if (inputValue.length <= 0) {
			clearFilter();
		} else {
			filterGalleries(false, inputValue);
		}
	}

	function clearFilter(){
		setShowAllGalleries(true);
		renderAllGalleries(galleriesData);
		setSearchResults(null);
		setShowFilters(false);

		setIsFiltered(false);

		setVideoType(false);
		setImageType(false);
		setPdfType(false);
		setAudioType(false);

		const input = document.querySelector('.galleries-form input');
		input.value = "";
	}

	function openInNewTab(link){
		const urlCleaned = link.replaceAll(" ", "").replaceAll(" ", "");
		return window.open(urlCleaned)
	}

	function toggleFilters(){
		setShowFilters(!showFilters);
	}

	function removeChoice(value){
		const checkedBoxes = document.querySelectorAll('input[name=media_type]:checked');

		checkedBoxes.forEach(element => {
			if(element.getAttribute('value') === value) {
				element.checked = false;
			}
		});

		if(value === 'video'){
			setVideoType(false);
		} else if(value === 'image'){
			setImageType(false);
		} else if(value === 'pdf'){
			setPdfType(false);
		} else if(value === 'audio'){
			setAudioType(false);
		}

		filterGalleries(true, null);
	}

	return (
		<div className="galleries-listing">
			<div className="container-fluid">
				<div className="searchbar-wrapper">
					<form className="galleries-form">
						<input
							type="text"
							placeholder={acfPageOptions.acf?.gallery?.search_by_placeholder}
							onChange={searchNames}
						/>
						<button type="button" onClick={!showAllGalleries ? clearFilter : () => {}}>
							<span className="visually-hidden">{acfPageOptions.acf?.gallery?.submit}</span>
							{showAllGalleries ? <IconSearch /> : <IconClose />}
						</button>
					</form>
					<IconFilters onClick={() => toggleFilters()} onKeyDown={() => toggleFilters()} role="button" tabIndex="-1" />
				</div>
				<div className="listing">
					{galleriesData && galleriesData.length > 0 && showAllGalleries &&
						renderAllGalleries(galleriesData)}
					{searchResults &&
						searchResults.length > 0 &&
						renderAllGalleries(searchResults)}
					{noMatches && <h5 className="no-match">{acfPageOptions.acf?.gallery?.no_matches}</h5>}
				</div>
				<div className={`filter-ctn ${showFilters ? "show" : ""}`}>
					<div className="filter-head">
						<p className="text">{acfPageOptions.acf?.gallery?.filter_by}</p>
						<IconClose onClick={() => toggleFilters()} onKeyDown={() => toggleFilters()} role="button" tabIndex="-1" />
					</div>
					<form className="filter-form">
						<Accordion alwaysOpen>
							<Accordion.Item eventKey="0">
								<Accordion.Header>{acfPageOptions.acf?.gallery?.content_type}</Accordion.Header>
								<Accordion.Body>
									<div className="checkbox-container">
										<Checkbox checked={videoType} onChange={() => setVideoType(!videoType)} value="video" name="media_type" checkboxId="content_type_video" label="Video" />
										<Checkbox checked={imageType} onChange={() => setImageType(!imageType)} value="image" name="media_type" checkboxId="content_type_image" label="Image" />
										<Checkbox checked={pdfType} onChange={() => setPdfType(!pdfType)} value="pdf" name="media_type" checkboxId="content_type_pdf" label="Pdf" />
										<Checkbox checked={audioType} onChange={() => setAudioType(!audioType)} value="audio" name="media_type" checkboxId="content_type_audio" label="Audio" />
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>

						<div className="button-container">
							<Button
								action={() => clearFilter()}
								classes="inverted medium"
								text={acfPageOptions.acf?.gallery?.reset}
							/>

							<Button
								action={() => filterGalleries(true, null)}
								classes="primary medium"
								text={acfPageOptions.acf?.gallery?.done}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

Galleries.defaultProps = {
	galleriesData: null,
	acfPageOptions: null,
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
};

Galleries.propTypes = {
	galleriesData: PropTypes.array,
	acfPageOptions: PropTypes.object,
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
};

export default Galleries;
