import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchWPPartnersData } from 'redux/wppage';

import Partners from 'mobile/pages/Partners';
import { writeTelemetryEvent } from 'redux/playfab';
import { playerGroupSelector, playerLangGroupSelector } from 'selector/player';

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		partnersData: state.wppage.techpartners,
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
		groupLang: playerLangGroupSelector(state),
		acfNavigation: state?.wpcontent?.navigation,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		fetchWPPartnersData,
		writeTelemetryEvent,
	}, dispatch)
)(Partners);




