/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Field from "components/Partials/Field";
import ToggleSwitch from "components/Buttons/ToggleSwitch";
import { PLAYFAB_EVENTS } from 'Constants'

function AdminTool({ playerStatistics, acfPageOptions, updatePlayerStatistics, writePlayerEvent }) {
	const [visible, setVisible] = useState(false);
	const [updateMessage, setUpdateMessage] = useState("");

	function onKeyDown(e) {
		if (e.keyCode === 65 && e.altKey) {
			setVisible(true);
		}
	}

	useEffect(() => {
		document.addEventListener("keydown", onKeyDown);
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
	}, []);

	function closeAdminTool() {
		setVisible(false);
		document
			.getElementById("AdminTool-container")
			.classList.remove("hide-adminTool");
	}

	function onUpdateValue(name, value) {
		updatePlayerStatistics({ name, value });
		setUpdateMessage("Statistic updated!");
		setInterval(() => {
			setUpdateMessage("");
		}, 5000);
	}

	return (
		visible && (
			<div id="AdminTool-container" className="adminTool-overlay">
				<div
					role="button"
					className="close-adminTool"
					aria-label="closeAdminTool"
					tabIndex="-1"
					onClick={() => closeAdminTool()}
					onKeyPress={() => closeAdminTool()}
				>
					<img alt="" src={acfPageOptions?.acf?.theme?.icon?.general?.exit?.image1?.url} />
				</div>
				<h3 className="title">Admin tool</h3>
				<div className="fields-container">
					<div className="update-message">
						<p>{updateMessage}</p>
					</div>
					{acfPageOptions.acf?.global?.admin_tool?.statistics.length > 0 ? (
						acfPageOptions.acf?.global?.admin_tool?.statistics.map((statistic) => {
							const label = statistic?.label;
							const fieldValue = String(playerStatistics[label]);
							if (label === "region" || label === "login") {
								return (
									<Field
										key={label}
										name={label}
										value={fieldValue}
										onBlur={(value) => onUpdateValue(label, value)}
										icon={acfPageOptions?.acf?.theme?.icon?.profile?.edit_profile?.url}
									/>
								);
							}
							return (
								<div key={label} className="stat-container">
									<p>{label}</p>
									<p>{statistic?.description}</p>
									<ToggleSwitch
										checked={playerStatistics[label]}
										onChange={(e) => onUpdateValue(label, e.target.checked)}
									/>
								</div>
							);
						})
					) : (
						<>
							<div className="update-message">
								<p>&rdquo;No admin settings found for region&rdquo;</p>
							</div>
							<Field
								key="region"
								name="region"
								value={playerStatistics["region"]}
								onBlur={async(value) => {
									await writePlayerEvent({
										name: PLAYFAB_EVENTS.change_region,
										body: {
											region: value,
										},
									});
								}}
								icon={acfPageOptions?.acf?.theme?.icon?.profile?.edit_profile?.url}
							/>
						</>
					)}
				</div>
			</div>
		)
	);
}

AdminTool.defaultProps = {
	playerStatistics: null,
	updatePlayerStatistics: () => {},
	writePlayerEvent: () => {},
	acfPageOptions: null,
};

AdminTool.propTypes = {
	playerStatistics: PropTypes.object,
	updatePlayerStatistics: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default AdminTool;
