import React from 'react';
import PropTypes from 'prop-types';
import ProfilePicture from 'assets/placeholders/profile-picture.png';

function LeaderCard({ user, playfabId }) {

	return (
		<div className={user.PlayFabId === playfabId ? 'leadercard my-leadercard' : 'leadercard'}>
			<div className="leader-profile">
				<div className="rank">
					<div className="level">
						<div className={user.Position === 0 ? 'num first-place' : user.Position === 1 ? 'num second-place' : user.Position === 2 ? 'num third-place' : 'num'}>{user.Position + 1}</div>
					</div>
				</div>
				<div className="profile-picture">
					<div className="avatar-ctn">
						<div className={user.Position === 0 ? 'avatar first-place' : user.Position === 1 ? 'avatar second-place' : user.Position === 2 ? 'avatar third-place' : 'avatar'}>
							<img src={user.Profile.AvatarUrl ? user.Profile.AvatarUrl : ProfilePicture} alt="" />
						</div>
					</div>
				</div>
				<div className="profile-info">
					{/* <Link to={ROUTES.PUBLIC_PROFILES.replace(':profileId', user.PlayFabId)}>
						<p>{user.DisplayName && user.DisplayName}</p>
					</Link> */}
					<p>{user.DisplayName && user.DisplayName}</p>
				</div>
			</div>
			<div className="leader-points">
				{user.StatValue}
			</div>
		</div>
	);
}

LeaderCard.defaultProps = {
	user: null,
	playfabId: '',
};

LeaderCard.propTypes = {
	user: PropTypes.object,
	playfabId: PropTypes.string,
};

export default LeaderCard;
