/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const BookAMeetingContext = createContext();

export const BookAMeetingContextProvider = ({ children }) => {
	const [filteredTiles, setFilteredTiles] = useState([]);

	return (
		<BookAMeetingContext.Provider
			value={{
				filteredTiles,
				setFilteredTiles,
			}}
		>
			{children}
		</BookAMeetingContext.Provider>
	);
};

BookAMeetingContextProvider.defaultProps = {
	children: null,
};

BookAMeetingContextProvider.propTypes = {
	children: PropTypes.object,
};
