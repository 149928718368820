import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { setActiveDay } from "redux/agenda";

import {
	agendaHoursSelector,
	agendaDaysSelector,
} from "selector/agenda";

import {
	purchaseTile,
	writePlayerEvent,
	getStoreLoadout,
	getItemInventory,
	writeTelemetryEvent,
} from "redux/playfab";
import { playerLangGroupSelector } from "selector/player";
import CalendarDesktop from "mobile/pages/CalendarDesktop";

function mapStateToProps(state) {
	return {
		...state.agenda,
		acfPageOptions: state.wpcontent.acfPageOptions,
		lang: state.env.lang,
		groupLang: playerLangGroupSelector(state),
		calendarHours: agendaHoursSelector(state),
		days: agendaDaysSelector(state),
	}
}

export default withRouter(
	connect(mapStateToProps, (dispatch) => 
		bindActionCreators(
			{
				setActiveDay,
				purchaseTile,
				writePlayerEvent,
				getStoreLoadout,
				getItemInventory,
				writeTelemetryEvent,
			},
			dispatch
		)
	)(CalendarDesktop)
)