import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { ComPanelContext } from "mobile/context/ComPanelContext";
import { SessionContext } from "mobile/context/SessionContext";
import Chat from "../containers/companel/Chat";
import Qa from "../containers/companel/Qa";
import TechSupport from "../containers/techsupport/TechSupport";

function Companel({
	session,
	minimizeToggle,
	channel,
	stateVideo,
	showCompanelNavigation,
	setShowCompanelNavigation,
	acfPageOptions,
}) {
	function renderActiveTab() {
		const { activeCompanelTab, setActiveCompanelTab } =
			useContext(ComPanelContext);
		const { minimizeToggle, setMinimizeToggle } = useContext(SessionContext);
		switch (activeCompanelTab) {
			case "CHAT":
				return (
					<Chat
						stateVideo={stateVideo}
						session={session}
						acfPageOptions={acfPageOptions}
					/>
				);

			case "Q&A":
				return (
					<Qa
						stateVideo={stateVideo}
						session={session}
						acfPageOptions={acfPageOptions}
					/>
				);
			case "SUPPORT":
				return (
					<TechSupport
						showCompanelNavigation={showCompanelNavigation}
						setShowCompanelNavigation={setShowCompanelNavigation}
					/>
				);

			default:
		}
		return null;
	}

	return renderActiveTab();
}

Companel.defaultProps = {
	showCompanelNavigation: false,
	setShowCompanelNavigation: () => {},
	activity: null,
	channel: null,
	stateVideo: null,
	acfPageOptions: null,
};

Companel.propTypes = {
	showCompanelNavigation: PropTypes.bool,
	setShowCompanelNavigation: PropTypes.func,
	activity: PropTypes.object,
	channel: PropTypes.string,
	stateVideo: PropTypes.string,
	acfPageOptions: PropTypes.object,
};

export default Companel;