import { createSelector } from "@reduxjs/toolkit";
import { now, toLocalTime } from "utils/dateUtils";
import { activitiesSelector } from "selector/catalog";
import { inventorySelector } from "selector/inventory";
import { USERMODERATORSTAT, EXPERTSTAT } from "Constants";

const tileIdFromRouteSelector = (state, props) => props.match?.params?.tileId;
const videoBubbleTileId = (state) => state.video_bubble.tileId;
const storeLoadoutSelector = (state) => state.playfab?.StoreLoadout;
const channelIDFromRouteSelector = (state, props) => parseInt(props.match?.params?.channelId, 10);
const wordpressChannelsSelector = (state) => state.wppage.channels;
const statsSelector = (state) => state.playfab?.Statistics;

/**
 * Validate if an activity is valid and can be joined
 */

export const activityValidTime = (activity, userStats) => {
	if (userStats === 1) {
		// Moderators can join from date_moderator to date_close
		return (
			(!activity.date_moderator.isValid() || activity.date_moderator.isBefore(now())) &&
			(!activity.date_close.isValid() || activity.date_close.isAfter(now()))
		);
	}
	return (
		// Other users can join from date_open to date_close
		(!activity.date_open.isValid() || activity.date_open.isBefore(now())) &&
		(!activity.date_close.isValid() || activity.date_close.isAfter(now()))
	);
}

/**
 * Return if the user is a moderator
 */

export const userModeratorSelector = createSelector([statsSelector], (stats) => {
	if (!stats || stats.length === 0) return null;
	const userModeratorStat = stats.find((stat) => stat?.StatisticName === USERMODERATORSTAT.USERMODERATOR || stat?.StatisticName === EXPERTSTAT.EXPERTSTAT);
	return userModeratorStat?.Value;
});

/**
 * Return if the user has access to the activity
 */

export const hasAccessToActivity = (activity, userStats) => {
	if (!activity) return false;

	if (activity.isPublic || activity.isInInventory) {
		return activityValidTime(activity, userStats);
	}

	return false;
};

/**
 * Populate channels with additionnal info from the store loadout and the Playfab inventory
 */

export const channelsSelector = createSelector(
	[storeLoadoutSelector, activitiesSelector, inventorySelector, wordpressChannelsSelector, userModeratorSelector],
	(storeLoadout, activities, inventory, wordpressChannels, playerUserModerator) => {
		if (!storeLoadout || !activities || !inventory || !wordpressChannels) return null;
		const loadout = storeLoadout.reduce((channelArr, channel) => {
			/**
			 * Build the tiles: filter out unvalid tiles and add additional info the tiles
			 */
			if (channel.tiles.length > 0) {
				const colorCode = channel.name.substr(channel.name.length - 1);

				const validTiles = channel.tiles.reduce((tileArr, tile) => {
					const catalogActivity = activities.find((activity) => activity.itemId === tile?.content?.ItemId);

					// If the tiles is not an activity, it's not added to the channel
					if (catalogActivity) {
						const inventoryActivity = inventory.find(
							(item) => item.itemId === tile?.content?.ItemId && item?.playfab?.InstanceData?.StoreTileId === tile.id
						);

						const isPublic = parseInt(catalogActivity.data.public, 10) === 1;
						const isInInventory = Boolean(inventoryActivity);
						const isQuestionBox = parseInt(catalogActivity.data.question_box, 10) === 1;

						let {
							date_moderator = "",
							date_open = "",
							date_begin = "",
							date_end = "",
							date_close = "",
						} = tile.customPayload;
						date_moderator = toLocalTime(date_moderator);
						date_open = toLocalTime(date_open);
						date_begin = toLocalTime(date_begin);
						date_end = toLocalTime(date_end);
						date_close = toLocalTime(date_close);

						const hasExpired = date_end.isValid() && date_end.isBefore(now());
						const stock = tile.limitedEdition ? tile.limitedEdition - tile.totalPurchase : null;
						const isHappeningToday = date_begin.isSameOrBefore(now(), "day") && date_end.isSameOrAfter(now(), "day");

						const show = date_begin.isValid() && (!date_end.isValid() || !hasExpired) && isHappeningToday;

						/**
						 * Add additionnal activity and inventory info to the tiles under activity
						 */

						const newTile = {
							...tile,
							activity: {
								...tile.content,
								...catalogActivity,
								...inventoryActivity?.playfab?.InstanceData,
								StoreTileId: tile.id,
								canPurchase: !isInInventory && (stock === null || stock > 0) && tile.status === "available",
								canUnregister: isInInventory,
								item_instance_id: inventoryActivity?.playfab?.ItemInstanceId,
								date_moderator,
								date_open,
								date_begin,
								date_end,
								date_close,
								isInInventory,
								isPublic,
								colorCode,
								isQuestionBox,
								customPayload: tile.customPayload,
							},
							stock,
							show,
						};

						newTile.activity.canJoin = hasAccessToActivity(newTile.activity, playerUserModerator);
						newTile.activity.isHappening = activityValidTime(newTile.activity);
						delete newTile.content;

						/**
						 * Group same activity tiles while preferring the one with the lowest available
						 * stock that is not 0
						 */
						const sameActivityTileInArray = tileArr.find(
							(t) =>
								t.activity.ItemId === newTile.activity.ItemId &&
								t.activity.date_begin.isSame(newTile.activity.date_begin) &&
								t.activity.date_end.isSame(newTile.activity.date_end)
						);
						if (sameActivityTileInArray) {
							// Replace tile with new one
							if (
								(sameActivityTileInArray.stock === 0 && newTile.stock > 0) ||
								sameActivityTileInArray.stock > newTile.stock
							) {
								return [...tileArr.filter((t) => t.activity.ItemId !== newTile.activity.ItemId), newTile];
							}

							// Skip over this tile, the one we had in array was "more fitting"
							return tileArr;
						}

						// Add tile as usual
						return [...tileArr, newTile];
					}

					return tileArr;
				}, []);

				//Sort the tiles by date

				validTiles.sort((a, b) => {
					if (a.activity.date_begin.isBefore(b.activity.date_begin)) {
						return -1;
					}

					if (b.activity.date_begin.isBefore(a.activity.date_begin)) {
						return 1;
					}

					return 0;
				});

				const tiles_to_show = validTiles.filter((t) => t.show); //.slice(0, 5);

				// Build the final channel object with the initial channel info, tiles, and wordress data
				const newChannelObj = {
					...channel,
					wp_data: wordpressChannels.find((c) => (c.slug.toLowerCase()) === (channel.name).replace(/ /g,'-').toLowerCase()),
					tiles: validTiles,
					is_locked: tiles_to_show.length === 0,
					tiles_to_show,
				};

				return [...channelArr, newChannelObj];
			}

			return channelArr;
		}, []);

		//Filter channels that are not linked to WordPress channels
		return loadout.filter((channel) => {
			if (!channel || !channel?.wp_data) {
				return null;
			}
			return channel;
		});
	}
);

/**
 * Extract tiles from the channel
 */

export const channelTilesSelector = createSelector([channelsSelector], (channels) => {
	if (!channels) return null;

	return channels.reduce((carry, curr) => {
		return [...carry, ...curr.tiles];
	}, []);
});

/**
 * Filter the featured tiles
 */

export const featuredTilesSelector = createSelector([channelTilesSelector], (tiles) => {
	if (!tiles) return null;
	//TODO: fix bad boolean "0" for true and 1 for false
	// return tiles.filter((tile) => Boolean(tile.activity?.data?.is_featured));
	return tiles.filter((tile) => tile.activity?.data?.is_featured === 1);
});

/**
 * Filter the channel corresponding to the actual route
 */

export const singleChannelSelector = createSelector(
	[channelsSelector, channelIDFromRouteSelector],
	(channels, channelID) => {
		if (channels === null || !channelID) return null;
		return channels.find((c) => c.id === channelID);
	}
);

/**
 * Extract the speakers from the tile to show
 */

export const channelSpeakersSelector = createSelector([singleChannelSelector], (channel) => {
	if (!channel) return null;

	const channelSpeakers = channel.tiles_to_show.reduce((speakers, tile) => {
		const newSpeakers = tile.activity.speakers.filter((s) => !speakers.find((_s) => _s.id === s.id));
		return [...speakers, ...newSpeakers];
	}, []);

	return channelSpeakers.slice(0, 3);
});

/**
 * Return the id of the first channel (default)
 */

export const defaultChannelIdSelector = createSelector([channelsSelector], (channels) => {
	if (channels === null || channels.length === 0) return null;
	return channels[0].id;
});

/**
 * Return the tile matching the tile Id
 */

export const singleTileSelector = createSelector([channelTilesSelector, tileIdFromRouteSelector], (tiles, tileId) => {
	if (!tiles) return null;

	return tiles.find((t) => t.id === tileId);
});

/**
 * Return the activity of a given tile
 */

export const activitySelector = createSelector([singleTileSelector], (tile) => {
	if (!tile) return null;
	return tile.activity;
});

/**
 * Return the tile matching the tile Id of the video bubble
 */

export const videoBubbleTileSelector = createSelector([channelTilesSelector, videoBubbleTileId], (tiles, tileId) => {
	if (!tiles) return null;

	return tiles.find((t) => t.id === tileId);
});

/**
 * Return the activity for the video bubble
 */

export const videoBubbleActivitySelector = createSelector([videoBubbleTileSelector], (tile) => {
	if (!tile) return null;
	return tile.activity;
});
