/* eslint-disable no-unsafe-optional-chaining */
import { createAction, createSlice } from "@reduxjs/toolkit";
import { createDebouncedAsyncAction } from "utils/reduxhelpers";

import { REACT_APP_AS_STACK_APP_ID, POLL_RATES } from "Constants";
import { reportApiError } from "utils/reportApiError";
import { resetObjectives, setObjectiveCompleted } from "redux/events";
import { setAuthStep } from "redux/auth";

import { playfabClientApi, playfabTelemetryApi, realtimeApi } from "api";
import { incrementErrors } from "redux/env";
import { instance } from "as-stack-sdk/sdk";

const stack = instance;

const BASE_CURRENCY_CODE = "TK";

export const changePollingRate = createAction("playfab/changePollingRate");

/**
 *Authentication through Teams or a custom Teams ID set in local storage
 *override_teams_id
 */
export const authTeamsPlayfab = createDebouncedAsyncAction(
	"playfab/fetch",
	stackAction((id, dispatch) => {
		if (stack.IsAuthenticated()) {
			dispatch(setAuthStep("teamsReturning"));
			return new Promise((resolve) => {
				resolve({
					success: true,
					data: {
						LoginResult: {
							PlayFabId: stack.GetPlayFabId(),
						},
					},
				});
			});
		}
		const call = stack.Auth("LoginWithCustomID", { CustomID: id });

		call.then((resolve) => {
			if (!resolve.success) {
				dispatch(setAuthStep("teamsCustomIDDeclined"));
			} else if (resolve.data.LoginResult.NewlyCreated) {
				dispatch(setAuthStep("teamsCustomIDNewUser"));
			} else {
				dispatch(setAuthStep("teamsReturning"));
			}
		});
		return call;
	}, "auth_LoginWithCustomID"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				...action.payload?.data?.LoginResult,
			};
		},
	}
);

/**
 *Authentication through playfab
 */
export const authPlayfab = createDebouncedAsyncAction(
	"playfab/fetch",
	stackAction((id, dispatch) => {
		if (stack.IsAuthenticated()) {
			dispatch(setAuthStep("SessionReturning"));
			return new Promise((resolve) => {
				resolve({
					success: true,
					data: {
						LoginResult: {
							PlayFabId: stack.GetPlayFabId(),
						},
					},
				});
			});
		}

		const call = stack.Auth("LoginWithCustomID", { CustomID: id });
		call.then((resolve) => {
			if (!resolve.success) {
				dispatch(setAuthStep("SessionIDDeclined"));
			} else {
				dispatch(setAuthStep("SessionReturning"));
			}
		});
		return call;
	}, "auth_LoginWithCustomID"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				...action.payload?.data?.LoginResult,
			};
		},
	}
);
/**
 *Authentication through the JWT
 */
export const authPlayfabJWT = createDebouncedAsyncAction(
	"playfab/fetch",
	stackAction((jwt, dispatch) => {
		if (stack.IsAuthenticated()) {
			dispatch(setAuthStep("jwtReturning"));
			return new Promise((resolve) => {
				resolve({
					success: true,
					data: {
						LoginResult: {
							PlayFabId: stack.GetPlayFabId(),
						},
					},
				});
			});
		}

		const call = stack.Auth("LoginWithJWT", { JWT: jwt });
		call.then((resolve) => {
			if (!resolve.success) {
				if (resolve.data.error === "Expired JWT") {
					dispatch(setAuthStep("jwtDeclinedExpired"));
				}

				if (resolve.data.error === "Unable to decode the protected headers.") {
					dispatch(setAuthStep("jwtDeclined"));
				}

				if (resolve.data.error === "Unsupported input") {
					dispatch(setAuthStep("jwtDeclined"));
				}
			} else if (resolve.data.LoginResult.NewlyCreated) {
				dispatch(setAuthStep("jwtNewUser"));
			} else {
				dispatch(setAuthStep("jwtReturning"));
			}
		});

		return call;
	}, "auth_LoginWithJWT"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				...action.payload?.data?.LoginResult,
			};
		},
	}
);


export const authPlayfabJWTNoClient = createDebouncedAsyncAction(
	"playfab/fetch",
	stackAction((jwt, dispatch) => {
		if (stack.IsAuthenticated()) {
			dispatch(setAuthStep("jwtReturning"));
			return new Promise((resolve) => {
				resolve({
					success: true,
					data: {
						LoginResult: {
							PlayFabId: stack.GetPlayFabId(),
						},
					},
				});
			});
		}

		const call = stack.Auth("LoginWithJWTNoClient", { JWT: jwt });
		call.then((resolve) => {
			if (!resolve.success) {
				if (resolve.data.error === "Expired JWT") {
					dispatch(setAuthStep("jwtDeclinedExpired"));
				}

				if (resolve.data.error === "Unable to decode the protected headers.") {
					dispatch(setAuthStep("jwtDeclined"));
				}

				if (resolve.data.error === "Unsupported input") {
					dispatch(setAuthStep("jwtDeclined"));
				}
			} else if (resolve.data.LoginResult.NewlyCreated) {
				dispatch(setAuthStep("jwtNewUser"));
			} else {
				dispatch(setAuthStep("jwtReturning"));
			}
		});

		return call;
	}, "auth_LoginWithJWTNoClient"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				...action.payload?.data?.LoginResult,
			};
		},
	}
);

/**
 *Get the player status from the realtime api
 */
export const getPlayerStatus = createDebouncedAsyncAction(
	"playfab/getPlayerStatus",
	() => {
		return realtimeApi("client/GetPlayerStatus", {
			PlayFabId: stack.GetPlayFabId(),
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				playerStatus: [...action.payload],
			};
		},
	}
);

/**
 *Get the player's playfab profile
 */
export const getPlayerProfile = createDebouncedAsyncAction(
	"playfab/getPlayerProfile",
	stackAction(() => {
		return stack.Client("GetPlayerProfile", {});
	}, "client_GetPlayerProfile"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				PlayerProfile: { ...action.payload?.data?.Profile },
			};
		},
	}
);

/**
 *Get the player's playfab data
 */
export const getPlayerData = createDebouncedAsyncAction(
	"playfab/getPlayerData",
	() => {
		return playfabClientApi("GetUserData");
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				PlayerData: {
					...action.payload?.data?.Data,
				},
			};
		},
	}
);

/**
 *Get the player's email from the playfab profile
 */
export const getPlayerProfileEmail = createDebouncedAsyncAction(
	"playfab/getPlayerProfileEmail",
	() => {
		return playfabClientApi("GetPlayerProfile", {
			PlayFabId: "playfabId",
			ProfileConstraints: {
				ShowContactEmailAddresses: true,
			},
		});
	},
	{
		fulfilled: (state, action) => {
			const emailFoundInProfile =
				action.payload?.data?.PlayerProfile?.ContactEmailAddresses;

			if (emailFoundInProfile.length > 0) {
				return {
					...state,
					PlayerProfile: {
						emailSet: true,
					},
				};
			}
			return {
				...state,
				PlayerProfile: {
					emailSet: false,
				},
			};
		},

		rejected: (state) => {
			return {
				...state,
				PlayerProfile: {
					emailSet: false,
				},
			};
		},
	}
);

/**
 *Check if the email associated with playfab is missing / valid
 */
export const AddOrUpdateContactEmail = createDebouncedAsyncAction(
	"playfab/AddOrUpdateContactEmail",
	(EmailAddress) => {
		return playfabClientApi("AddOrUpdateContactEmail", {
			EmailAddress,
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				teamsEmail: {
					...action.payload?.data?.Data,
				},
			};
		},
	}
);

/**
 *Get the player's read only data
 */
export const getPlayerReadOnlyData = createDebouncedAsyncAction(
	"playfab/getPlayerReadOnlyData",
	() => {
		return playfabClientApi("GetUserReadOnlyData");
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				PlayerReadOnlyData: {
					...action.payload?.data?.Data,
				},
			};
		},
	}
);

/**
 *Get another player's data, used inside the chats to display the player's card
 */
export const getOtherPlayerData = createDebouncedAsyncAction(
	"playfab/getOtherPlayerData",
	(playfabId) => {
		return playfabClientApi("GetPlayerCombinedInfo", {
			PlayFabId: playfabId,

			InfoRequestParameters: {
				GetUserInventory: true,
				GetPlayerStatistics: true,
				GetPlayerProfile: true,
				PlayerStatisticNames: ["level"],
				GetUserData: true,
			},
		});
	},
	{
		fulfilled: (state, action) => {
			const data = action.payload.data.InfoResultPayload?.UserData || {};
			const stats =
				action.payload.data.InfoResultPayload?.PlayerStatistics || [];
			const displayName =
				action.payload.data.InfoResultPayload?.PlayerProfile?.DisplayName;

			return {
				...state,
				OtherPlayerData: {
					...state.OtherPlayerData,
					[action.payload.data.PlayFabId]: {
						data: {
							...Object.keys(data).reduce((c, v) => {
								c[v] = data[v].Value;
								return c;
							}, {}),
						},
						stats: {
							...stats.reduce((c, v) => {
								c[v.StatisticName] = v.Value;
								return c;
							}, {}),
						},
						displayName,
					},
				},
			};
		},
	}
);

/**
 *Update the player's data
 */
export const updatePlayerData = createDebouncedAsyncAction(
	"playfab/updatePlayerData",
	(data, { dispatch }) => {
		const call = playfabClientApi("UpdateUserData", {
			Data: data,
			Permission: "Public",
		});

		call.then(() => {
			setTimeout(() => {
				dispatch(getPlayerData());
			}, 200);
		});

		return call;
	},
	{
		fulfilled: (state) => {
			return state;
		},
	}
);

/**
 *Update the player's statistics
 */
export const updatePlayerStatistics = createDebouncedAsyncAction(
	"playfab/updatePlayerStatistics",
	(data, { dispatch }) => {
		const statToUpdate = [
			{ StatisticName: data.name, Value: Number(data.value), Version: 0 },
		];
		const call = playfabClientApi("UpdatePlayerStatistics", {
			Statistics: statToUpdate,
		});

		call.then(() => {
			setTimeout(() => {
				dispatch(getPlayerStatistics());
			}, 200);
		});

		return call;
	},
	{
		fulfilled: (state) => {
			return state;
		},
	}
);

/**
 *Get the player's statistics from playfab
 */
export const getPlayerStatistics = createDebouncedAsyncAction(
	"playfab/getPlayerStatistics",
	stackAction(() => {
		return stack.Client("GetPlayerStatistics", {});
	}, "client_getPlayerStatistics"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				Statistics: [...action.payload?.data?.Statistics],
			};
		},
	}
);

/**
 *Get the player's playfab inventory
 */
export const getItemInventory = createDebouncedAsyncAction(
	"playfab/getItemInventory",
	stackAction(() => {
		return stack.Client("GetItemInventory", {});
	}, "client_GetItemInventory"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				previousInventory: [...(state.Inventory || [])],
				Inventory: [...action.payload?.data?.items],
			};
		},
	}
);

export const getMeetingStatus = createDebouncedAsyncAction(
	"playfab/getMeetingStatus",
	stackAction((id) => {
		return stack.Service("teams/GetStreamUrl", {
			RoomId: id,
		});
	}, "service_teams_GetStreamUrl"),
	{
		fulfilled: (state, action) => {
			// empty meeting
			if (
				Object.keys(action.payload).length === 0 &&
				action.payload.constructor === Object
			)
				return state;

			return {
				...state,
				MeetingURLs: {
					...state.MeetingURLs,
					[action.payload.data.Result.id]: {
						...action.payload.data.Result,
					},
				},
			};
		},
	}
);

/**
 *Player event sent to playfab
 */
export const writePlayerEvent = createDebouncedAsyncAction(
	"playfab/writePlayerEvent",
	stackAction((params) => {
		return stack.Client("WritePlayerEvent", {
			EventName: params.name,
			Body: JSON.stringify(params.body),
		});
	}, "client_WritePlayerEvent"),
	{}
);

/**
 *Telemetry event sent to playfab
 */
export const writeTelemetryEvent = createDebouncedAsyncAction(
	"playfab/WriteTelemetryEvent",
	(params) => {
		return playfabTelemetryApi("WriteTelemetryEvents", [
			{
				EventNamespace: params.namespace,
				Name: params.name,
				Payload: JSON.stringify(params.body),
			},
		]);
	},
	{
		fulfilled: (state) => {
			return state;
		},
	}
);

/**
 *Upload the player's plafab avatar
 */
export const uploadAvatar = createDebouncedAsyncAction(
	"playfab/uploadAvatar",
	stackAction((blob) => {
		const { url } = stack.GetCallParams("client", "UploadAvatar", {});

		const xhr = new XMLHttpRequest();
		xhr.open("POST", url);
		xhr.setRequestHeader("X-App-Id", stack.appId);

		if (stack.GetSessionTicket()) {
			xhr.setRequestHeader("X-Authentication", stack.GetSessionTicket());
		}

		const formData = new FormData();
		formData.append("ImageFile", blob, stack.GetPlayFabId() + ".jpg");

		return new Promise((resolve, reject) => {
			xhr.onreadystatechange = () => {
				if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
					const response = JSON.parse(xhr.response);
					if (response.success) {
						resolve(response);
					} else {
						reject(response);
					}
				}
			};
			xhr.send(formData);
		});
	}, "client_UploadAvatar"),
	{}
);

/**
 *Acquire an item from the playfab catalog
 */
export const acquireCatalogItem = createDebouncedAsyncAction(
	"playfab/acquireCatalogItem",
	stackAction((params, dispatch, getState) => {
		const state = getState();

		const isMission = params.item_id.charAt(0) === "m";
		const hasItem = Boolean(
			state.playfab[isMission ? "Events" : "Inventory"].find(
				(item) => item.itemId === params.item_id
			)
		);

		if (hasItem) {
			if (isMission) {
				dispatch(
					resetMission({
						item_id: params.item_id,
					})
				);
			} else {
				dispatch(getItemInventory());
			}

			return Promise.resolve({
				success: true,
			});
		}

		const promise = stack.Client("AcquireCatalogItem", {
			ItemId: params.item_id,
		});

		promise.then(() => {
			dispatch(getItemInventory());
			dispatch(getEventsInventory());
		});

		return promise;
	}, "client_acquireCatalogItem"),
	{}
);

/**
 *Consume an item from the player's playfab inventory.
 */
export const consumeInventoryItem = createDebouncedAsyncAction(
	"playfab/consumeInventoryItem",
	stackAction((item_instance_id) => {
		return stack.Client("ConsumeItem", {
			ItemInstanceId: item_instance_id,
		});
	}, "client_ConsumeItem"),
	{}
);

/**
 *Reset a player's mission
 */
export const resetMission = createDebouncedAsyncAction(
	"playfab/resetMission",
	stackAction((param, dispatch, getState) => {
		const state = getState();

		const isMission = param.item_id.charAt(0) === "m";
		const hasItem = Boolean(
			state.playfab[isMission ? "Events" : "Inventory"].find(
				(item) => item.itemId === param.item_id
			)
		);

		if (hasItem) {
			if (isMission) {
				dispatch(resetObjectives());
				return stack.Client("ResetMission", {
					ItemId: param.item_id,
				});
			}
		}
		return Promise.resolve({
			success: true,
		});
	}, "client_ResetMission"),
	{}
);

/**
 *Get the playfab catalog of items
 */
export const getCatalog = createDebouncedAsyncAction(
	"playfab/getItemCatalog",
	stackAction(() => {
		return stack.Client("GetItemCatalog", {});
	}, "client_GetItemCatalog"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				Catalog: [...action.payload?.data?.Catalog],
			};
		},
	}
);

/**
 *Get the playfab store loadout
 */
export const getStoreLoadout = createDebouncedAsyncAction(
	"playfab/getStoreLoadout",
	stackAction(() => {
		return stack.Client("GetStoreLoadout", {});
	}, "client_GetStoreLoadout"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				StoreLoadout: [...action.payload?.data?.StoreLoadout],
			};
		},
	}
);

/**
 *Purchase a tiel from the store
 */
export const purchaseTile = createDebouncedAsyncAction(
	"playfab/purchaseStoreItem",
	stackAction((tile_id, dispatch) => {
		const promise = stack.Client("PurchaseStoreItem", {
			TileId: tile_id,
			WritePlayStreamEvent: 1,
			CurrencyCode: BASE_CURRENCY_CODE,
		});
		promise.then(() => {
			dispatch(getItemInventory());
		});

		return promise;
	}, "client_PurchaseStoreItem"),
	{}
);

export const getEventsInventory = createDebouncedAsyncAction(
	"playfab/getEventsInventory",
	stackAction(() => {
		return stack.Client("GetMissionInventory", {});
	}, "client_GetMissionInventory"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				Events: [...action.payload.data.missions.PlayerMissions],
			};
		},
	}
);

/**
 *Send an invent to playfab ex.: When a player completes a Trivia, or fills questions on the profile page.
 */
export const sendEventInput = createDebouncedAsyncAction(
	"playfab/sendEventInput",
	stackAction((data, dispatch) => {
		dispatch(setObjectiveCompleted(data));

		const call = stack.Client("SendMissionInput", {
			ItemId: data.mission_id,
			ObjectiveId: data.objective_id,
			Input: data.answer,
			Context: JSON.stringify({
				CustomInstanceId: data.instance_id,
			}),
		});
		return call;
	}, "client_SendMissionInput"),
	{}
);

/**
 *Get the mission objectives of the answer game on the profile page
 */
export const getAnswerObjectives = createDebouncedAsyncAction(
	"playfab/getAnswerObjectives",
	stackAction((data) => {
		return stack.Client("GetMissionData", {
			ItemId: data.mission_id,
		});
	}, "client_GetMissionData"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				AnswerObjectives: action.payload?.data?.mission?.objectives,
			};
		},
	}
);

/**
 *Get a specific leaderboard from the instance
 */
export const getInstanceLeaderboard = createDebouncedAsyncAction(
	"playfab/getInstanceLeaderboard",
	stackAction((data) => {
		return stack.Client("GetInstanceLeaderboard", {
			CustomInstanceId: data.instanceId,
			StatName: data.statName,
		});
	}, "client_GetInstanceLeaderboard"),
	{}
);

/**
 *Get the general leaderboard from playfab
 */
export const getLeaderboard = createDebouncedAsyncAction(
	"playfab/getLeaderboard",
	() => {
		return playfabClientApi("GetLeaderboard", {
			StatisticName: "xp",
			ProfileConstraints: {
				ShowAvatarUrl: true,
				ShowDisplayName: true,
			},
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				GeneralLeaderboard: action.payload?.data?.Leaderboard,
			};
		},
	}
);

/**
 *Get the leaderboard around the player
 */
export const getLeaderboardOnPosition = createDebouncedAsyncAction(
	"playfab/getLeaderboardOnPosition",
	(data) => {
		return playfabClientApi("GetLeaderboardAroundPlayer", {
			StatisticName: "xp",
			PlayFabId: data,
			ProfileConstraints: {
				ShowAvatarUrl: true,
				ShowDisplayName: true,
			},
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				OnPositionLeaderboard: action.payload?.data?.Leaderboard,
			};
		},
	}
);

/**
 *Get the leaderboard around the user
 */
export const getLeaderboardAroundCurrentUser = createDebouncedAsyncAction(
	"playfab/getLeaderboardAroundPlayer",
	() => {
		return playfabClientApi("GetLeaderboardAroundPlayer", {
			StatisticName: "xp",
			PlayFabId: null,
			MaxResultsCount: 1,
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				OnUserPositionLeaderboard: action.payload?.data?.Leaderboard,
			};
		},
	}
);

/**
 *Get a stat from the playfab app
 */
export const getAppStat = createDebouncedAsyncAction(
	"playfab/getAppStat",
	stackAction(() => {
		return stack.Analytics("stat/GetAppStat", {
			AppId: REACT_APP_AS_STACK_APP_ID,
		});
	}, "analytics_stat_GetAppStat"),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				AppStats: action.payload.data.AppStats,
			};
		},
	}
);

/**
 *Display poll results
 */
export const setShowPollResults = createDebouncedAsyncAction(
	"events/setShowPollResults",
	(data, { dispatch }) => {
		return dispatch(getAppStat()).then(() => {
			return data;
		});
	},
	{
		fulfilled: (state, action) => {
			const s = {
				...state,
				current_poll_results: [
					...state.current_poll_results,
					{ ...action.payload },
				],
			};

			return s;
		},
	}
);

/**
 *Request a JWT for Jitsi calls
 */
export const getJitsiJwt = createDebouncedAsyncAction(
	"jitsi/getJitsiJwt",
	stackAction(() => {
		return stack.Service("jitsi/GetToken", {});
	}),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				jitsiJwt: [action.payload.data.JWT],
			};
		},
	}
);

/**
 *
 */
export const getFriendsList = createDebouncedAsyncAction(
	"playfab/getFriendsList",
	stackAction(() => {
		return stack.Client("GetFriendsList", {});
	}),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				FriendsList: action.payload.data.FriendsList,
				FriendRequests: action.payload.data.FriendRequests,
			};
		},
	}
);

/**
 *
 */
export const sendFriendRequest = createDebouncedAsyncAction(
	"playfab/sendFriendRequest",
	stackAction((params) => {
		return stack.Client("SendFriendRequest", params);
	}),
	{
		fulfilled: (state) => {
			return {
				...state,
			};
		},
	}
);

/**
 *
 */
export const acceptFriendRequest = createDebouncedAsyncAction(
	"playfab/acceptFriendRequest",
	stackAction((params) => {
		return stack.Client("AcceptFriendRequest", params);
	}),
	{
		fulfilled: (state) => {
			return {
				...state,
			};
		},
	}
);

/**
 *
 */
export const declineFriendRequest = createDebouncedAsyncAction(
	"playfab/declineFriendRequest",
	stackAction((params) => {
		return stack.Client("DeclineFriendRequest", params);
	}),
	{
		fulfilled: (state) => {
			return {
				...state,
			};
		},
	}
);

function stackAction(action, endpoint) {
	return (payload, { dispatch, rejectWithValue, getState }) => {
		return action(payload, dispatch, getState).then((resp) => {
			if (!resp.success) {
				playfabTelemetryApi("WriteTelemetryEvents", [
					{
						EventNamespace: "custom.error",
						Name: String(endpoint),
						Payload: JSON.stringify({
							error: resp.code,
							value: resp.data.error,
							message: resp.message,
						}),
					},
				]);

				dispatch(incrementErrors());
				reportApiError("PlayfabAPI", resp);
				return rejectWithValue(resp);
			}
			return resp;
		});
	};
}

const playfab = createSlice({
	name: "playfab",
	reducers: {
		triggerBlockNotifications: (state) => {
			return {
				...state,
				blocked_notifications: true,
			};
		},
		triggerEnableNotifications: (state) => {
			return {
				...state,
				blocked_notifications: false,
			};
		},
		triggerMyLeaderBoard: (state) => {
			return {
				...state,
				show_personnal_leaderboard: true,
			};
		},
		triggerGeneralLeaderBoard: (state) => {
			return {
				...state,
				show_personnal_leaderboard: false,
			};
		},
	},
	extraReducers: {
		[changePollingRate]: (state, action) => ({
			...state,
			pollRates: {
				...state.pollRates,
				[action.payload.key]: action.payload.rate,
			},
		}),
		...authPlayfab.reducers,
		...authTeamsPlayfab.reducers,
		...authPlayfabJWT.reducers,
		...getPlayerProfile.reducers,
		...getPlayerProfileEmail.reducers,
		...getPlayerData.reducers,
		...getPlayerReadOnlyData.reducers,
		...updatePlayerData.reducers,
		...AddOrUpdateContactEmail.reducers,
		...getPlayerStatistics.reducers,
		...getLeaderboard.reducers,
		...getItemInventory.reducers,
		...getAnswerObjectives.reducers,
		...getCatalog.reducers,
		...getStoreLoadout.reducers,
		...getEventsInventory.reducers,
		...sendEventInput.reducers,
		...getInstanceLeaderboard.reducers,
		...getMeetingStatus.reducers,
		...getAppStat.reducers,
		...setShowPollResults.reducers,
		...getOtherPlayerData.reducers,
		...getJitsiJwt.reducers,
		...getLeaderboardOnPosition.reducers,
		...getLeaderboardAroundCurrentUser.reducers,
		...getPlayerStatus.reducers,
		...getFriendsList.reducers,
		...sendFriendRequest.reducers,
	},
	initialState: {
		current_poll_results: [],
		playerStatus: [],
		pollRates: {
			[POLL_RATES.FETCH_WPACTIVITIES]: 0,
			[POLL_RATES.GETPLAYERPROFILE]: 0,
			[POLL_RATES.GETPLAYERDATA]: 0,
			[POLL_RATES.GETPLAYERSTATISTICS]: 30000,
			[POLL_RATES.GETITEMINVENTORY]: 10000,
			[POLL_RATES.GETCATALOG]: 0,
			[POLL_RATES.GETSTORELOADOUT]: 0,
			[POLL_RATES.GETEVENTSINVENTORY]: 0,
			[POLL_RATES.GET_PLAYER_STATUS]: 1000 * 5,
		},
		blocked_notifications: false,
		show_personnal_leaderboard: true,
		previousInventory: [],
		Inventory: [],
	},
});

export default playfab;

export const {
	triggerBlockNotifications,
	triggerEnableNotifications,
	triggerMyLeaderBoard,
	triggerGeneralLeaderBoard,
} = playfab.actions;
