/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as MicrosoftTeams from '@microsoft/teams-js';
import parse from "html-react-parser";

function TechPartnersContent({
	wpContent, onLoaded, acfPageOptions, authMethod,
}) {
	useEffect(() => {
		if (Object.keys(wpContent).length !== 0) {
			onLoaded();
		}
	}, [wpContent]);

	function goToLink(url) {
		return !authMethod ? window.open(url) : MicrosoftTeams.executeDeepLink(url);
	}
	
	return (
		<div className="single-speaker-inner content-section">
			<div className="row">
				{wpContent.acf?.photo && (
					<div className="speaker-col-image col appear-anim">
						<div className="single-speaker-thumbnail">
							<img src={wpContent.acf?.photo?.sizes?.large} className="wp-post-image" alt="" />
						</div>
					</div>
				)}
				<div className="speaker-col-content col appear-anim">
					<div className="speaker-entry-content">
						<p className="type">Partners</p>
						<h1 className="entry-title">{wpContent?.title?.rendered && parse(wpContent?.title?.rendered)}</h1>
						<p className="role">{wpContent.acf?.subtitle}</p>
						{wpContent.acf?.location && (
							<p className="location"> 
								<i className="icon-location" /> 
								<span>{wpContent.acf?.location}</span>
							</p>
						)}
						<div className="wysiwyg">{wpContent?.content?.rendered && parse(wpContent?.content?.rendered)}</div>
						<ul className="speaker-meta">
							{wpContent.acf?.company && (
								<li className="speaker-meta-item">
									<span className="meta-title">
										{acfPageOptions.acf?.technology_partners_company}
									</span>
									{wpContent.acf?.company}
								</li>
							)}								
							{wpContent.acf?.phone && (
								<li className="speaker-meta-item">
									<span className="meta-title">
										{acfPageOptions.acf?.technology_partners_phone}
									</span>
									<span>{wpContent.acf?.phone}</span>
								</li>
							)}
							{wpContent.acf?.email && (
								<li className="speaker-meta-item"> 
									<span className="meta-title">
										{acfPageOptions.acf?.technology_partners_email} 
									</span> 
									<span>{wpContent.acf?.email}</span>
								</li>
							)}
							{wpContent.acf?.website && (
								<li className="speaker-meta-item "> 
									<span className="meta-title">
										{acfPageOptions.acf?.technology_partners_website} 
									</span>
									<button type="button" onClick={() => goToLink(wpContent.acf?.website)}>{wpContent.acf?.website}</button>
								</li>
							)}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

TechPartnersContent.defaultProps = {
	wpContent: null,
	acfPageOptions: null,
	onLoaded: () => {},
	authMethod: null,
};

TechPartnersContent.propTypes = {
	wpContent: PropTypes.object,
	acfPageOptions: PropTypes.object,
	onLoaded: PropTypes.func,
	authMethod: PropTypes.string,
};

export default memo(TechPartnersContent);
