import React, { useContext, useState, useEffect } from "react";
import { object, func, string, array, number } from "prop-types";
import Trivia from "mobile/components/modals/Trivia";
import Leaderboard from "mobile/components/session/Leaderboard";
import { SessionContext } from "mobile/context/SessionContext";
import Summary from "./Summary";

export default function SessionEvents({
	sessionEvents,
	sendEventInput,
	resetMission,
	playfabId,
	acfPageOptions,
	contextId,
	leaderboard,
	getLeaderboardOnPosition,
	getLeaderboard,
	generalLeaderboard,
	leaderboardOnPosition,
	points,
}) {
	const { fullScreen, setFullScreen } = useContext(SessionContext);
	const [userPoints, setPoints] = useState(null);
	const [showLeaderBoard, setShowLeaderboard] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => {
		getLeaderboard();
	}, [showLeaderBoard]);

	const renderLeaderboard = () => {
		if (Object.keys(leaderboard).length || showLeaderBoard) {
			return (
				<Leaderboard
					playfabId={playfabId}
					fullScreen={fullScreen}
					generalLeaderboard={generalLeaderboard}
					leaderboardOnPosition={leaderboardOnPosition}
					leaderboard={leaderboard.list}
					setFirstRender={setFirstRender}
					acfPageOptions={acfPageOptions}
				/>
			);
		}

		return null;
	};

	const renderSummary = () => {
		if (sessionEvents || firstRender) {
			return null;
		}

		return (
			<Summary
				contextId={contextId}
				setPoints={setPoints}
				playfabId={playfabId}
				leaderboard={leaderboard}
				setShowLeaderboard={setShowLeaderboard}
				points={userPoints}
				setFirstRender={setFirstRender}
				getLeaderboardOnPosition={getLeaderboardOnPosition}
			/>
		);
	};

	const renderTrivia = () => {
		if (!sessionEvents) return null;

		const {
			type,
			data: { layout, answers, question },
			id,
			expiration,
			mission_id,
		} = sessionEvents;

		return (
			<Trivia
				timerColor={acfPageOptions?.acf?.theme?.color?.text?.secondary}
				timerOutlineColor={acfPageOptions?.acf?.theme?.color?.text?.primary}
				icon={acfPageOptions?.acf?.theme?.icon?.profile?.edit_profile?.url}
				key={type.id}
				contextId={contextId}
				fullScreen={fullScreen}
				sendEventInput={sendEventInput}
				resetMission={resetMission}
				leaderboard={leaderboard}
				playfabId={playfabId}
				layout={layout}
				mission_id={mission_id}
				answers={answers}
				question={question}
				expiration={expiration}
				id={id}
				points={points}
				setPoints={setPoints}
				setFirstRender={setFirstRender}
				acfPageOptions={acfPageOptions}
			/>
		);
	};

	return (
		<>
			{renderTrivia()}
			{renderSummary()}
			{renderLeaderboard()}
		</>
	);
}

SessionEvents.propTypes = {
	acfPageOptions: object,
	sendEventInput: func,
	resetMission: func,
	sessionEvents: object,
	contextId: string,
	playfabId: string,
	leaderboard: object,
	getLeaderboardOnPosition: func,
	getLeaderboard: func,
	generalLeaderboard: array,
	leaderboardOnPosition: array,
	points: number,
};

SessionEvents.defaultProps = {
	acfPageOptions: null,
	sendEventInput: () => {},
	getLeaderboardOnPosition: () => {},
	resetMission: () => {},
	sessionEvents: null,
	contextId: "",
	playfabId: "",
	leaderboard: {},
	getLeaderboard: () => {},
	generalLeaderboard: [],
	leaderboardOnPosition: [],
	points: 0,
};
