import React, { useEffect, useRef } from "react";
import Checkbox from "components/Buttons/Checkbox";
import Button from 'mobile/components/buttons/Button';
import { Accordion } from "react-bootstrap";
import { func, object, array, bool } from "prop-types";
import {ReactComponent as IconClose} from "assets/icons/pack1/speakers/icon-close-bold.svg";


function AgendaFilter({
	setExpanded,
	setFilters,
	filters,
	setApplyFilters,
	acfPageOptions,
	setEvents,
	setMyAgenda,
	eventTiles,
	myAgendaTiles,
	clearFilters,
}) {
	const sessionType = [
		{
			name: acfPageOptions?.acf?.agenda?.session_type?.live,
			id: 1,
		},
		{
			name: acfPageOptions?.acf?.agenda?.session_type?.pre_recorded,
			id: 2,
		},
		{
			name: acfPageOptions?.acf?.agenda?.session_type?.virtual_meeting,
			id: 3,
		},
		{
			name: acfPageOptions?.acf?.agenda?.session_type?.recorded,
			id: 4,
		},
	];
	// const speakers = [
	// 	{
	// 		name: acfPageOptions?.acf?.agenda?.speakers?.internal,
	// 		id: 1,
	// 	},
	// 	{
	// 		name: acfPageOptions?.acf?.agenda?.speakers?.external,
	// 		id: 2,
	// 	},
	// ];
	// const regions = [
	// 	{
	// 		name: acfPageOptions?.acf?.agenda?.region?.region_1,
	// 		id: 1,
	// 	},
	// 	{
	// 		name: acfPageOptions?.acf?.agenda?.region?.region_2,
	// 		id: 2,
	// 	},
	// ];
	// const stages = [
	// 	{
	// 		name: acfPageOptions?.acf?.agenda?.stages?.stage_1,
	// 		id: 1,
	// 	},
	// 	{
	// 		name: acfPageOptions?.acf?.agenda?.stages?.stage_2,
	// 		id: 2,
	// 	},
	// ];
	// const categories = [
	// 	{
	// 		name: acfPageOptions?.acf?.agenda?.category?.category_1,
	// 		id: 1,
	// 	},
	// 	{
	// 		name: acfPageOptions?.acf?.agenda?.category?.category_2,
	// 		id: 2,
	// 	},
	// ];

	const isMounted = useRef();

	const topics = [
		{
			name: acfPageOptions?.acf?.agenda?.topic?.topic_red,
			color: "#D92211",
		},
		{
			name: acfPageOptions?.acf?.agenda?.topic?.topic_blue,
			color: "#2F77D5",
		},
		{
			name: acfPageOptions?.acf?.agenda?.topic?.topic_green,
			color: "#59D986",
		},
		{
			name: acfPageOptions?.acf?.agenda?.topic?.topic_orange,
			color: "#F26A1B",
		},
		{
			name: acfPageOptions?.acf?.agenda?.topic?.topic_yellow,
			color: "#F2C849",
		},
		{
			name: acfPageOptions?.acf?.agenda?.topic?.topic_purple,
			color: "#7B61FF",
		},
	];

	useEffect(() => {
		if (clearFilters) {
			const elist = document.getElementsByTagName("input");

			const checkboxList = Array.from(elist);

			checkboxList.forEach((el) => (el.checked = false));
		}
	}, [clearFilters]);

	const handleChecked = (event, categoryName) => {
		const { name, checked } = event.target;

		const key = name.replaceAll(" ", "-").toLowerCase();

		if (filters[categoryName]) {
			if (checked) {
				const newFilters = { ...filters };

				newFilters[categoryName][key] = checked;

				 return setFilters({ ...newFilters });
			}

			if (!checked) {
				delete filters[categoryName][key];

				if (Object.keys(filters[categoryName]).length > 0) {
					setFilters({ ...filters });
				} else {
					delete filters[categoryName];

					setFilters({ ...filters });
					setEvents(eventTiles);
					setMyAgenda(myAgendaTiles);
				}
			}
		} else {
			setApplyFilters(false);
			setFilters({ ...filters, [categoryName]: { [key]: checked } });
			setApplyFilters(true)
		}

		return null;
	};

	const handleValidateFilters = () => {
		setApplyFilters(true);
		setExpanded(false);
	};

	const handleResetFilters = () => {
		setFilters({});
		setApplyFilters(false);

		const elist = document.getElementsByTagName("input");

		const checkboxList = Array.from(elist);

		checkboxList.forEach((el) => (el.checked = false));

		setExpanded(false);
	};

	const renderAccordion = (name, list, filterCategoryName) => {
		return (
			<Accordion>
				<Accordion.Item eventKey="0">
					<Accordion.Header>
						<span className="px-2">{name}</span>
					</Accordion.Header>
					<Accordion.Body>
						{list.map((item) => {
							return (
								<div key={item.id} className="filter-checkbox-wrapper">
									<Checkbox
										name={item.name}
										onChange={(event) =>
											handleChecked(event, filterCategoryName)
										}
									/>
									<div className="filter-item">{item.name}</div>
								</div>
							);
						})}
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		);
	};

	return (
		<form className="agenda filter-content-wrapper">
			<div>
				<p className="text-filter text-uppercase">
					{acfPageOptions?.acf?.agenda?.filter_by}
				</p>

				<IconClose className="close-icon" onClick={() => setExpanded(false)} onKeyDown={() => setExpanded(false)} role="button" tabIndex="-1" />

				<div className="accordion-wrapper">
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								<span className="px-2">
									{acfPageOptions?.acf?.agenda?.topic_title}
								</span>
							</Accordion.Header>
							<Accordion.Body>
								{topics.map((topic) => {
									return (
										<div key={topic.color} className="filter-checkbox-wrapper">
											<Checkbox
												name={topic.name}
												onChange={(event) => handleChecked(event, "topic")}
											/>

											<div
												style={{ backgroundColor: topic.color }}
												className="filter-badge"
											>
												{topic.name}
											</div>
										</div>
									);
								})}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>

					{renderAccordion("Session Type", sessionType, "session")}

					{/* {renderAccordion("Speaker", speakers, "speaker")}

					{renderAccordion("Region", regions, "region")}

					{renderAccordion("Stage", stages, "stage")}

					{renderAccordion("Filter x", categories, "filter")} */}

					<div className="button-wrapper">
						<Button
							action={handleResetFilters}
							classes="inverted medium"
							text={acfPageOptions?.acf?.agenda?.reset_all_button}
						/>

						<Button
							action={handleValidateFilters}
							classes="primary medium"
							text={acfPageOptions?.acf?.agenda?.done_button}
						/>
					</div>
				</div>
			</div>
		</form>
	);
}

AgendaFilter.propTypes = {
	acfPageOptions: object,
	setExpanded: func,
	setFilters: func,
	setApplyFilters: func,
	filters: object,
	setEvents: func,
	setMyAgenda: func,
	eventTiles: array,
	myAgendaTiles: array,
	clearFilters: bool,
};

AgendaFilter.defaultProps = {
	acfPageOptions: null,
	setExpanded: () => {},
	setFilters: () => {},
	setApplyFilters: () => {},
	filters: {},
	setEvents: () => {},
	setMyAgenda: () => {},
	eventTiles: [],
	myAgendaTiles: [],
	clearFilters: false,
};

export default AgendaFilter;
