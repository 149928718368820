import React, { useState } from "react";
import PropTypes from "prop-types";

const SORT_ASC = "asc";
const SORT_DESC = "desc";

function ContactListTable({ usersList, acfContactList, acfIcons }) {
	const [typeFilter, setTypeFilter] = useState(SORT_DESC);
	const [activeFilter, setActiveFilter] = useState("");

	function changeFilter(field) {
		if (typeFilter === SORT_ASC) {
			setTypeFilter(SORT_DESC);
		} else {
			setTypeFilter(SORT_ASC);
		}
		setActiveFilter(field);
	}

	function filterButton(field) {
		return (
			<img
				className={`filter-icon ${
					field === activeFilter ? `filter-icon-${typeFilter}` : ""
				}`}
				src={acfIcons?.filter?.url}
				alt="sort"
				role="presentation"
				onClick={() => {
					changeFilter(field);
				}}
			/>
		);
	}

	function filterUsers() {
		if (typeFilter === SORT_ASC) {
			return usersList.sort((a, b) => {
				if (a[activeFilter] < b[activeFilter]) return -1;
				if (a[activeFilter] > b[activeFilter]) return 1;
				return 0;
			});
		}

		return usersList.sort((a, b) => {
			if (a[activeFilter] > b[activeFilter]) return -1;
			if (a[activeFilter] < b[activeFilter]) return 1;
			return 0;
		});
	}

	// TODO: It's possible to return the name of the param for each column from the acf depending on the requeriments and add dynamically
	return (
		<div className="table-container">
			<div className="header-container">
				<div className="image" />
				<div className="name">
					{acfContactList?.name_filter_label}
					{filterButton("displayName")}
				</div>
				<div className="location">
					{acfContactList?.location_filter_label}
					{filterButton("location")}
				</div>
				<div className="job">
					{acfContactList?.job_title_filter_label}
					{filterButton("job")}
				</div>
				<div className="company">
					{acfContactList?.company_name_filter_label}
					{filterButton("company")}
				</div>
				<div className="button-action" />
			</div>
			{filterUsers().map((user) => {
				return (
					<div
						key={user.playfabId}
						className="table-content"
						onClick={user.clickEvent}
						onKeyPress={user.clickEvent}
						role="button"
						tabIndex="0"
					>
						<div className="image-container">{user.image}</div>
						<div className="user-container">{user.name}</div>
						<div className="location-container">{user.location}</div>
						<div className="company-container">{user.company}</div>
						<div className="job-container">{user.job}</div>
						<div className="button-action-container">{user.action}</div>
					</div>
				);
			})}
		</div>
	);
}

ContactListTable.propTypes = {
	acfContactList: PropTypes.object,
	acfIcons: PropTypes.object,
	usersList: PropTypes.array,
};

ContactListTable.defaultProps = {
	acfContactList: {},
	acfIcons: {},
	usersList: [],
};

export default ContactListTable;
