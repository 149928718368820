/** @format */
/* eslint-disable no-unsafe-optional-chaining */

import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { ROUTES } from "Constants";
import Button from "components/Buttons/Button";

export default class DetailsDialog extends Component {
	constructor(props) {
		super(props);
		this.state = { show: props.show, x: 0, y: 0 };

		this.toggleShow = this.toggleShow.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleScroll = this.handleScroll.bind(this);

		this.cardRef = createRef();
		this.dialogRef = createRef();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		window.addEventListener("scroll", this.handleScroll);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
		window.removeEventListener("scroll", this.handleScroll);
	}

	handleClickOutside(event) {
		const { show } = this.state;
		if (
			this.cardRef &&
			this.dialogRef &&
			!this.cardRef.current.contains(event.target) &&
			!this.dialogRef.current.contains(event.target)
		) {
			if (show) {
				this.toggleShow();
			}
		}
	}

	handleScroll() {
		const { show } = this.state;
		if (show) {
			this.toggleShow();
		}
	}

	toggleShow(e) {
		const { show } = this.state;
		const { direction } = this.props;
		if (!show) {
			if (e.target.parentNode.parentNode.id !== "activity-buttons") {
				const yCenter = e?.clientY <= 300 ? 0 : e?.clientY - 300;
				const xCenter =
					e?.clientX + 250 > window.innerWidth
						? window.innerWidth - 500
						: e?.clientX - 450 < 0
							? 310
							: e?.clientX - 200;
				switch (direction) {
					case "right":
						this.setState({
							show: !show,
							x: e?.clientX + 10,
							y: e?.clientY - 150,
						});
						break;
					case "left":
						this.setState({
							show: !show,
							x: e?.clientX - 450,
							y: e?.clientY - 150,
						});
						break;
					default:
						this.setState({
							show: !show,
							x: xCenter,
							y: yCenter,
						});
						break;
				}
			}
		} else {
			this.setState({
				show: !show,
			});
		}
	}

	render() {
		const { show, x, y } = this.state;
		const { link_to_event_page, child, details, closeIcon, direction } =
			this.props;
		return (
			<div>
				<div
					className="card-container"
					ref={this.cardRef}
					role="button"
					tabIndex="0"
					onClick={this.toggleShow}
					onKeyDown={null}
				>
					{child}
				</div>
				<div
					className={`dialog ${direction} ${show ? "show" : ""}`}
					style={{
						top: y,
						left: x,
					}}
					ref={this.dialogRef}
				>
					<div
						className="close"
						role="button"
						tabIndex="-1"
						onClick={this.toggleShow}
						onKeyDown={null}
					>
						<img src={closeIcon} alt="Close" />
					</div>
					<div className="details-card">
						{details?.activity?.canJoin && <div className="live">Live</div>}
						<div className="title">
							{details?.activity?.content?.title?.rendered &&
								parse(details?.activity?.content?.title?.rendered)}
						</div>
						<div className="host">
							{details?.activity?.content?.acf?.host &&
								parse(details?.activity?.content.acf.host)}
						</div>
						{details?.activity?.content?.acf?.event_excerpt && (
							<div className="excerpt">
								{details?.activity?.content.acf.event_excerpt}
							</div>
						)}
						<div className="button-ctn">
							{details?.activity?.canJoin && (
								<Button
									classes="inverted large"
									text="Join Now"
									link={ROUTES.ACTIVITY.replace(":tileId", details.id)}
								/>
							)}
							
							{link_to_event_page && (
								<Button
									classes="secondary large"
									text="View Event"
									link={ROUTES.EVENT.replace(":tileId", details.id)}
								/>
							)}
						</div>
					</div>
					<div className="connector" />
				</div>
			</div>
		);
	}
}
DetailsDialog.propTypes = {
	closeIcon: PropTypes.string,
	details: PropTypes.object.isRequired,
	child: PropTypes.element.isRequired,
	direction: PropTypes.string,
	show: PropTypes.bool,
	link_to_event_page: PropTypes.bool,
};

DetailsDialog.defaultProps = {
	closeIcon: "",
	direction: "right",
	show: false,
	link_to_event_page: false,
};
