import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ADMINSTAT } from "Constants";
import { chatJoinRoom, chatLeaveRoom, clearMessages, getQuestions, broadcastMessage, clearQuestions } from "redux/chat";
import { getMeetingStatus, writePlayerEvent, writeTelemetryEvent, getJitsiJwt } from "redux/playfab";
import { sessionActivitySelector } from "selector/myAgenda";
import { playerGroupSelector, streamURLSelector, userNameSelector, playerLangGroupSelector } from "selector/player";
import { translationSelector } from "selector/catalog";
import { setHasAttended, setWasLate, setLeftEarly } from "redux/tracking";
import { statSelector, statsSelector } from "selector/stats";
import { attendanceTypeSelector } from "selector/agenda";
import Session from "../Session";

function mapStateToProps(state, props) {
	const activity = sessionActivitySelector(state, props);
	return {
		acfPageOptions: state?.wpcontent?.acfPageOptions,
		playerStatistics: statsSelector(state, props),
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		isCatalogLoaded: Boolean(state.playfab.Catalog),
		activity,
		username: userNameSelector(state),
		playfabId: state.playfab?.PlayFabId,
		prerecordedStreamURL: streamURLSelector(state, { activity }),
		joinMeetingURL: activity?.StoreTileId,
		translations: translationSelector(state),
		hasAttended: state.tracking?.hasAttended,
		wasLate: state.tracking?.wasLate,
		leftEarly: state.tracking?.leftEarly,
		playerGroup: playerGroupSelector(state),
		isAdmin: statSelector(state, { statName: "admin" }) === ADMINSTAT.ADMIN,
		points: statSelector(state, { statName: "xp" }),
		emojis: state.wpcontent?.acfPageOptions?.acf?.theme?.emojis,
		newQuestions: state.chat?.newQuestions,
		newMessages: state.chat?.newMessages,
		newSupportMessages: state.chatSupport?.newMessages,
		channel: state.chat.channel,
		messages: state.chat.messages,
		questions: state.chat.questions,
		groupLang: playerLangGroupSelector(state),
		userAttendanceType: attendanceTypeSelector(state),
	};
}

export default withRouter(
	connect(mapStateToProps, (dispatch) =>
		bindActionCreators(
			{
				chatJoinRoom,
				chatLeaveRoom,
				getMeetingStatus,
				clearMessages,
				writePlayerEvent,
				writeTelemetryEvent,
				getQuestions,
				getJitsiJwt,
				setHasAttended,
				setWasLate,
				setLeftEarly,
				broadcastMessage,
				clearQuestions,
			},
			dispatch
		)
	)(Session)
);
