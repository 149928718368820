import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Profile from '../Profile';
import {
	updatePlayerData,
	writeTelemetryEvent,
	uploadAvatar,
	changePollingRate,
	getLeaderboardOnPosition,
	getFriendsList,
} from '../../../redux/playfab';

import { statsSelector } from '../../../selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		acfPageOptions: state.wpcontent.acfPageOptions,
		authMethod: state.auth?.method,
		navigation: state.wpcontent.navigation,
		leaderboardOnPosition: state.playfab?.OnPositionLeaderboard,
		sessions: state.playfab?.Statistics,
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		getLeaderboardOnPosition,
		changePollingRate,
		updatePlayerData,
		writeTelemetryEvent,
		uploadAvatar,
		getFriendsList,
	}, dispatch)
)(Profile));
