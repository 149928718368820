import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfileCard from 'components/ProfileCard';
import { otherPlayerDataSelector } from 'selector/chat';
import { setActiveProfileCard } from 'redux/profile_card';

function mapStateToProps(state) {
	const activeProfileCard = state.profile_card.active;
	return {
		playfabId: state.profile_card.active,
		playerData: activeProfileCard && otherPlayerDataSelector(state, { PlayFabId: activeProfileCard }),
		acfPageOptions: state.wpcontent.acfPageOptions,

	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		setActiveProfileCard,
	}, dispatch)
)(ProfileCard);
