import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
	chatSupportJoinRoom,
	chatSupportLeaveRoom,
	clearMessages,
	requestSupport,
	triggerNoNewMessages,
	chatSupportConnect,
	chatSupportListen,
	chatSupportStopListen,
	closeSupportSession,
} from "redux/chatSupport";
import { userNameSelector } from "selector/player";
import TechSupport from "../../techSupport/Index";

function mapStateToProps(state) {
	return {
		username: userNameSelector(state),
		channel: state.chatSupport?.channel,
		connectionSupport: state.chatSupport?.connection,
		joined_room: state.chatSupport?.joined_room,
		is_resolved: state.chatSupport?.is_resolved,
		supportOptions:
			state.wpcontent?.acfPageOptions?.acf?.chat_support?.subject_options,
		playfabId: state.playfab?.PlayFabId,
		messages: state.chatSupport?.messages,
		acfPageOptions: state.wpcontent?.acfPageOptions,
		acfIcon: state.wpcontent?.acfPageOptions?.acf?.theme,
	};
}

export default withRouter(
	connect(mapStateToProps, (dispatch) =>
		bindActionCreators(
			{
				chatSupportJoinRoom,
				chatSupportLeaveRoom,
				clearMessages,
				requestSupport,
				triggerNoNewMessages,
				chatSupportConnect,
				chatSupportListen,
				chatSupportStopListen,
				closeSupportSession,
			},
			dispatch
		)
	)(TechSupport)
);
