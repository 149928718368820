import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProfileHeader from "components/Profile/ProfileHeader";
import {
	getPlayerStatistics,
	getLeaderboardOnPosition,
	getLeaderboard,
} from "redux/playfab";
import { userNameSelector, playerGroupSelector } from "selector/player";

function mapStateToProps(state) {
	return {
		displayName: userNameSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		playfabId: state.playfab?.PlayFabId,
		leaderboardOnPosition: state.playfab?.OnPositionLeaderboard,
		playerGroup: playerGroupSelector(state),
		sessions: state.playfab?.Statistics,
		authMethod: state.auth?.method,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			getPlayerStatistics,
			getLeaderboardOnPosition,
			getLeaderboard,
		},
		dispatch
	)
)(ProfileHeader);
