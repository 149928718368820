import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import LoadingGif from "components/LoadingGif";
import Modal from "mobile/components/modals";

function CardModal({eventModal, setEventModal, loading, activity, acfPageOptions, overwriteHeading, partners, type, topic, date, time, link, stockText, stockButtons}) {
	return (
		<Modal className="session-modal" overwriteHeading={overwriteHeading} acfPageOptions={acfPageOptions} modal={eventModal} setModal={setEventModal}>
			<div className="event-text-info">
				<div className="topic-wrap">
					{topic}
					{type}
				</div>
				
				<h4 className="tile-title text-white">
					{activity?.content?.title?.rendered && parse(activity?.content?.title?.rendered)}
				</h4>

				<div className="partners-wrap">
					{partners}
				</div>

				<div className="text-ctn text-white">
					{activity?.content?.acf?.event_excerpt && activity?.content?.acf?.event_excerpt}

					{link}
				</div>
				

				<div className="stock-wrap mt-2">
					{stockText}
				</div>

				<div className="date-wrap">
					<div className="session-time">
						{time}
						{date}
					</div>

					<div className="actions block">
						{loading ? (
							<div className="loading-text">
								<span>{acfPageOptions?.acf?.activity_card_loading}</span>
								<LoadingGif
									acfPageOptions={acfPageOptions}
									status={loading ? "active" : ""}
									cardLoader={loading ? "card-loader" : ""}
								/>
							</div>
						) : (
							stockButtons
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
}

CardModal.defaultProps = {
	eventModal: false,
	loading: false,
	setEventModal: () => {},
	activity: {},
	acfPageOptions: null,
	link: null,
	overwriteHeading: null,
	partners: null,
	type: null,
	topic: null,
	date: null,
	time: null,
	stockText: null,
	stockButtons: null,
};

CardModal.propTypes = {
	eventModal: PropTypes.bool,
	loading: PropTypes.bool,
	setEventModal: PropTypes.func,
	activity: PropTypes.object,
	acfPageOptions: PropTypes.object,
	link: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	overwriteHeading: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	partners: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	type: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	topic: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	date: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	time: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	stockText: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	stockButtons: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
};

export default CardModal;
