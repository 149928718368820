import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchWPContentData } from 'redux/wpcontent';
import WPContent from 'components/Partials/WPContent';

function mapStateToProps(state) {
	return {
		wpContent: state.wpcontent.wpNotifContent,
		acfPageOptions: state.wpcontent.acfPageOptions,
		authMethod: state.auth?.method,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		fetchWPContentData,
	}, dispatch)
)(WPContent);
