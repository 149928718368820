/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import WPContent from "containers/Partials/WPContent";
import Avatar from "containers/Partials/Avatar";
import { ROUTES } from "Constants";
import { scrollTo } from "utils/scrollTo";
import LocationIcon from "assets/icons/icon-location-footer.svg";

function ActivityFooter({
	activity,
	xp,
	username,
	region,
	loadedWpContent,
	clearActivityFooterContent,
	acfPageOptions,
}) {
	const stickyElement = useRef();
	useEffect(() => {
		scrollTo(0);

		const observer = new IntersectionObserver(
			([e]) => {
				e.target.classList.toggle("is-sticky", e.intersectionRatio < 1);
			},
			{
				rootMargin: "-1px 0px 0px 0px",
				threshold: [1],
			}
		);
		observer.observe(stickyElement.current);

		return () => {
			if (stickyElement.current) {
				observer.unobserve(stickyElement.current);
			}
		};
	}, []);

	const [opened, setOpened] = useState(false);
	const [isLoadingContent, setIsLoadingContent] = useState(false);

	const onLoaded = () => setOpened(true);

	useEffect(() => {
		if (opened) {
			scrollTo(window.innerHeight);
		}
	}, [opened]);

	useEffect(() => {
		return () => {
			clearActivityFooterContent();
		};
	}, []);

	let moreInfosClass = "activity-infos";

	if (opened) {
		moreInfosClass = "back-to-top";
	} else if (isLoadingContent) {
		moreInfosClass = "loading";
	}

	function onClickBackToTop() {
		scrollTo(0, {
			onComplete: () => {
				// setLoadedContent(null);
				setIsLoadingContent(false);
				setOpened(false);
			},
		});
	}

	return (
		<div className={`activity-footer ${opened ? 'opened' : ''}`}>
			<div className='above-fold' ref={stickyElement}>
				<div className='content'>
					<div className='profile'>
						<div className='game-ui'>
							<Link to={ROUTES.PROFILE}>
								<Avatar />
							</Link>
							<div className='infos'>
								<div className='name'>{username}</div>
								{region && (
									<div className='location'>
										<img className='icon' src={LocationIcon} alt='location' />
										{region}
									</div>
								)}
								{acfPageOptions.acf?.profile?.header?.show_points && (
									<div className='extra-stats'>
										<div className='stat-container'>
											<div className='stat-value'>{xp} Pts</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className='more-infos'>
						<div className={`togglable current-${moreInfosClass}`}>
							<div className='activity-infos'>
								{!acfPageOptions.acf?.global.hide_activity_title && (
									<>
										<div className='title'>
											{activity?.content?.title?.rendered && parse(activity.content?.title?.rendered)}
										</div>
										<div className='text'>
											{activity?.content?.acf?.event_excerpt && parse(activity.content?.acf?.event_excerpt)}
										</div>
									</>
								)}
							</div>
							<div className='loading'>Please wait, content is loading...</div>
							<div className='back-to-top'>
								<div
									tabIndex='-1'
									role='button'
									className='button'
									onClick={onClickBackToTop}
									onKeyPress={onClickBackToTop}
								>
									Close information panel
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='loaded-content'>
				{loadedWpContent && (
					<WPContent postType={loadedWpContent.post_type} postSlug={loadedWpContent.post_slug} onLoaded={onLoaded} />
				)}
			</div>
		</div>
	);
}

ActivityFooter.defaultProps = {
	acfPageOptions: null,
	activity: null,
	username: "",
	region: "",
	xp: 0,
	loadedWpContent: null,

	clearActivityFooterContent: () => {},
};

ActivityFooter.propTypes = {
	activity: PropTypes.object,
	username: PropTypes.string,
	region: PropTypes.string,

	xp: PropTypes.number,
	loadedWpContent: PropTypes.object,
	clearActivityFooterContent: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default ActivityFooter;
