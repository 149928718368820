import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { channelsSelector, channelTilesSelector, featuredTilesSelector } from 'selector/channels';
import { toLocalTime } from 'utils/dateUtils';

export const activeDaySelector = state => state.agenda.activeDay;
export const activeDaySideSelector = state => state.side_agenda.activeDay;
export const activeChannelIdSelector = state => state.agenda.activeChannel;
export const isAllChannelsActive = state => state.agenda.isAllChannelsActive;
export const attendanceTypeSelector = state => state?.playfab?.PlayerData?.AttendanceType?.Value

function roundTimeQuarterHour(time) {
	const timeToReturn = new Date(time);

	timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
	timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
	timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
	return timeToReturn;
}

/**
* This function is called by agendaTracksSelector and allTracksSelctor.
It transforms tiles into tracks. A track is a tile with two new graphical reprentation properties: timeSlotStart, timeSlotEnd.
These properties define the heights of a tile in the agenda grid. Each hour of the agenda represents 4 slots (1 slot is 15 minutes).
timeSlot is the total number of slots that a track occupies.
*/

const getAgendaTracks = (tiles, hours, activeDay) => {
	if (!tiles || !hours || !activeDay) return null;

	const tracks = [];

	const treatedTiles = [];

	const defaultMinHour = toLocalTime(activeDay).startOf('day');
	const defaultMaxHour = toLocalTime(activeDay).endOf('day');

	tiles.forEach(tile => {
		if (treatedTiles.indexOf(tile.id) === -1) {
			treatedTiles.push(tile.id);

			let minHour = tile.activity.date_begin;
			let maxHour = tile.activity.date_end;

			if (!minHour || minHour.isBefore(defaultMinHour)) {
				minHour = defaultMinHour;
			}

			if (!maxHour || maxHour.isAfter(defaultMaxHour)) {
				maxHour = defaultMaxHour;
			}

			const startTime = dayjs(roundTimeQuarterHour(minHour.format()));
			const endTime = dayjs(roundTimeQuarterHour(maxHour.format()));

			const isDefaultMinHour = startTime.isSameOrBefore(defaultMinHour);
			const isDefaultMaxHour = endTime.isSameOrAfter(defaultMaxHour);

			const timeSlotStart = isDefaultMinHour ? 0 : startTime.hour() * 4 + Math.round(startTime.minute() / 15) + 1;
			const timeSlotEnd = isDefaultMaxHour ? 96 : endTime.hour() * 4 + Math.round(endTime.minute() / 15) + 1;

			const tileObj = {
				...tile,
				timeSlotStart,
				timeSlotEnd,
			};

			if (tracks.length === 0) {
				tracks.push({
					tiles: [tileObj],
				});
			} else {
				let hasPushedTile = false;

				for (let i = 0; i < tracks.length; i += 1) {
					if (!hasPushedTile) {
						const track = tracks[i];

						const tileOverlap = track.tiles.find(trackTile => {
							return (
								(
									timeSlotEnd > trackTile.timeSlotStart
									&& timeSlotEnd <= trackTile.timeSlotEnd
								)
								|| (
									timeSlotStart >= trackTile.timeSlotStart
									&& timeSlotStart < trackTile.timeSlotEnd
								)
								|| (
									timeSlotStart <= trackTile.timeSlotStart
									&& timeSlotEnd >= trackTile.timeSlotEnd
								)
							);
						});

						if (!tileOverlap) {
							track.tiles.push(tileObj);
							hasPushedTile = true;
						}
					}
				}

				if (!hasPushedTile) {
					tracks.push({
						tiles: [tileObj],
					});
				}
			}
		}
	});

	return tracks;
};

/**
* Return every days of the agenda (each day that an event takes place)
*/

export const agendaDaysSelector = createSelector(
	[channelsSelector],
	(channels) => {
		if (!channels) return null;

		const agendaDays = channels.reduce((carry, curr) => {
			curr.tiles.forEach(tile => {
				if (tile.activity.date_begin.isValid()) {
					const dateBeginExists = carry.filter(d => d.isSame(tile.activity.date_begin, 'day')).length > 0;
					if (!dateBeginExists) {
						carry.push(tile.activity.date_begin);
					}
				}
	
				if (tile.activity.date_end.isValid()) {
					const dateEndExists = carry.filter(d => d.isSame(tile.activity.date_end, 'day')).length > 0;
		
					if (!dateEndExists) {
						carry.push(tile.activity.date_end);
					}
				}
			});
			
			return carry;
		}, []);

		agendaDays.sort((a, b) => a.isAfter(b) ? 1 : -1);
	
		return agendaDays;
	},
);

/**
* Return every days of the agenda (each day that an event takes place)
*/

const activeChannelSelector = createSelector(
	[channelsSelector, activeChannelIdSelector],
	(channels, activeChannelId) => {
		if (!channels || !activeChannelId) return null;

		return channels.find(c => c.id === activeChannelId);
	},
);

/**
* Return the tiles of the active channel
*/

export const agendaTilesSelector = createSelector(
	[channelsSelector, activeChannelSelector, activeDaySelector],
	(channels, activeChannel, activeDay) => {
		if (!channels || !activeChannel || !activeDay) return null;

		return activeChannel.tiles.filter(tile => {
			return tile.activity.date_begin.isSameOrBefore(activeDay, 'day') && tile.activity.date_end.isSameOrAfter(activeDay, 'day');
		});
	},
);

/**
* Return every tiles for all the channels of the active day
*/

export const allTilesForActiveDaySelector = createSelector(
	[channelsSelector, activeDaySelector],
	(channels, activeDay) => {
		if (!channels) return null;

		const unflattedTilesArr = channels.reduce((allTiles, channel) => {
			return [...allTiles, channel.tiles];
		}, []);
		return unflattedTilesArr.flat(1).filter(tile => {
			return tile.activity.date_begin.isSameOrBefore(activeDay, 'day') && tile.activity.date_end.isSameOrAfter(activeDay, 'day');
		});
	},
);

/**
* Return every tiles
*/

export const allTilesSelector = createSelector(
	[channelsSelector],
	(channels) => {
		if (!channels) return null;

		const unflattedTilesArr = channels.reduce((allTiles, channel) => {
			return [...allTiles, channel.tiles];
		}, []);
		return unflattedTilesArr.flat(1);
	},
);

/**
* Return every days of the agenda (each day that an event takes place)
*/

export const agendaHoursSelector = createSelector(
	[activeDaySelector],
	(activeDay) => {
		if (!activeDay) return null;

		const minHour = toLocalTime(activeDay).startOf('day');
		const maxHour = toLocalTime(activeDay).endOf('day');

		const timeSpanInHours = maxHour.diff(minHour, 'hour') + 1;
		
		const agendaHours = Array.from({ length: timeSpanInHours }, (d, i) => {
			return minHour.add(i, 'hour');
		});

		return agendaHours;
	},
);

/**
* Turn tiles of a specific day and a specific channel into tracks (a track is a tile with two additional properties that specifie the height of the tile in the UI) - see getAgendaTracks for more details
*/

export const agendaTracksSelector = createSelector(
	[agendaTilesSelector, agendaHoursSelector, activeDaySelector],
	(tiles, hours, activeDay) => {
		if (!tiles || !hours || !activeDay) return null;

		const tracks = getAgendaTracks(tiles, hours, activeDay);

		return tracks;
	},
);

/**
* Turn all tiles into tracks (for the All channels tab)
*/

export const allTracksSelector = createSelector(
	[allTilesForActiveDaySelector, agendaHoursSelector, activeDaySelector],
	(tiles, hours, activeDay) => {
		if (!tiles || !hours || !activeDay) return null;

		const tracks = getAgendaTracks(tiles, hours, activeDay);

		return tracks;
	},
);

/**
* Filter out the registerd tiles
*/

export const registeredTilesSelector = createSelector([
	channelTilesSelector,
], (tiles) => {
	if (!tiles) return null;

	return tiles.filter(tile => tile.activity.isInInventory);
});

/**
* Return the registered tiles of a user (seems like a duplicate)
*/

export const allUserTilesSelector = createSelector([
	registeredTilesSelector,
], (registeredTiles) => {
	if (!registeredTiles) return null;
	return registeredTiles;
});

/**
* Filter out the live tiles
*/

export const allLiveTilesSelector = createSelector([
	allTilesSelector,
], (tiles) => {
	if (!tiles) return null;
	return tiles.filter(tile => tile.activity.isHappening && tile.activity.canJoin);
});

/**
* Return the tiles for the active day
*/

export const activeDayTilesSelector = createSelector([
	featuredTilesSelector,
	registeredTilesSelector,
	activeDaySideSelector,
], (featuredTiles, registeredTiles, activeDay) => {
	if (!featuredTiles || !registeredTiles || !activeDay) return null;

	const curDay = dayjs(activeDay);
	const startOfDay = curDay.startOf('day');
	const endOfDay = curDay.endOf('day');

	function tileOverlap(t1, t2) {
		return (
			(
				t2.end.isAfter(t1.begin)
				&& t2.end.isSameOrAfter(t1.end)
			) 
			|| (
				t2.begin.isSameOrAfter(t1.begin)
				&& t2.begin.isBefore(t1.end)
			)
			|| (
				t2.begin.isSameOrBefore(t1.begin)
				&& t2.end.isSameOrAfter(t2.end)
			)
		);
	}

	const mappedTiles = registeredTiles
		.reduce((c, tile) => {
			const { date_begin, date_end } = tile.activity;
			
			const begin = dayjs(date_begin);
			const end = dayjs(date_end);

			if (begin.isBefore(endOfDay) && end.isAfter(startOfDay)) {
				c.push({
					...tile,
					begin: begin.isBefore(startOfDay) ? startOfDay : begin,
					end: end.isAfter(endOfDay) ? endOfDay : end,
				});
			}

			return c;
		}, []);

	// Intersect with featured tiles. Add them if nothing else is regisetred in their timespan
	featuredTiles.forEach(tile => {
		if (
			tile.activity.date_begin.isSame(activeDay, 'day')
			|| tile.activity.date_end.isSame(activeDay, 'day')
		) {
			const newTile = {
				...tile,
				begin: tile.activity.date_begin.isBefore(startOfDay) ? startOfDay : tile.activity.date_begin,
				end: tile.activity.date_end.isAfter(endOfDay) ? endOfDay : tile.activity.date_end,
			};
			const tileOverlaps = mappedTiles.filter(mt => tileOverlap(mt, newTile)).length;
			if (tileOverlaps === 0) {
				mappedTiles.push(newTile);
			}
		}
	});

	return mappedTiles;
});
