/** @format */

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Events from "components/Events/Events";
import { eventsSelector } from "selector/events";
import { sendEventInput, resetMission } from "redux/playfab";

function mapStateToProps(state) {
	return {
		acfPageOptions: state?.wpcontent?.acfPageOptions,
		events: eventsSelector(state),
		channel: state.chat.channel,
		playfabId: state.playfab.PlayFabId,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			sendEventInput,
			resetMission,
		},
		dispatch
	)
)(Events);
