// virtual / in person
// set default checkbox
// continue button
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ROUTES } from "Constants";
import Modal from "mobile/components/modals";
import RadioCheckToggle from "../partials/RadioCheckToggle";

function ToggleOption({
	acfPageOptions,
	attendanceType,
	updatePlayerData,
	history,
	modal,
	setModal,
	title,
	checkboxValue,
	setCheckboxValue,
	toggleValue,
	setToggleValue,
	warning,
	subtitle,
	modalAction,
	toggleLabelOne,
	toggleLabelTwo,
	checkboxLabel,
	btnLabel,
}) {
	// TODO:Create ACF for attendance modal remove the && modal condition below, will have to update the moment the modal is displayed when the cards are created
	return (
		!localStorage.getItem("hasDefaultAttendanceType") &&
		modal && (
			<Modal
				className="attendance-modal"
				acfPageOptions={acfPageOptions}
				modal={modal}
				setModal={setModal}
			>
				<h3 className="text-white text-center mb-3">{title}</h3>
				<div className="buttons-container d-flex justify-content-center my-3 mx-3">
					<RadioCheckToggle
						className={`toggle ${!toggleValue ? "default-1" : ""}`}
						type="radio"
						id="radio-1"
						name="option-1"
						label={toggleLabelOne}
						value={0}
						checked={toggleValue === false}
						onChange={() => setToggleValue(false)}
					/>
					<RadioCheckToggle
						className={`toggle reversed ${toggleValue ? "default-2" : ""}`}
						type="radio"
						id="option-2"
						name="option-2"
						label={toggleLabelTwo}
						value={1}
						checked={toggleValue}
						onChange={() => setToggleValue(true)}
					/>
				</div>
				<div className="container text-center mt-3 mb-4">
					<span className="availability ">{subtitle}</span>
				</div>
				<div className="container d-flex justify-content-center my-2">
					<RadioCheckToggle
						className="checkbox"
						type="checkbox"
						id="checkbox"
						name="checkbox"
						label={checkboxLabel}
						value={checkboxValue}
						onChange={() => setCheckboxValue(!checkboxValue)}
					/>
				</div>
				{warning && (
					<div className="container text-center">
						<span className="modal-warning">{warning}</span>
					</div>
				)}
				<div className="d-flex justify-content-center mt-3">
					<button
						type="button"
						className={`m-button-container large primary continue-button ${
							toggleValue !== null ? "" : "disabled"
						}`}
						onClick={() => modalAction()}
						disabled={toggleValue !== null && false}
					>
						{btnLabel}
					</button>
				</div>
			</Modal>
		)
	);
}

ToggleOption.defaultProps = {
	acfPageOptions: null,
	attendanceType: null,
	updatePlayerData: () => {},
	modal: false,
	setModal: () => {},
	title: "",
	checkboxValue: null,
	setCheckboxValue: () => {},
	toggleValue: null,
	setToggleValue: () => {},
	warning: "",
	subtitle: "",
	history: null,
	modalAction: () => {},
	toggleLabelOne: "",
	toggleLabelTwo: "",
	checkboxLabel: "",
	btnLabel: "",
};

ToggleOption.propTypes = {
	acfPageOptions: PropTypes.object,
	attendanceType: PropTypes.object,
	updatePlayerData: PropTypes.func,
	history: PropTypes.object,
	modal: PropTypes.bool,
	setModal: PropTypes.func,
	title: PropTypes.string,
	checkboxValue: PropTypes.any,
	setCheckboxValue: () => {},
	toggleValue: PropTypes.any,
	setToggleValue: () => {},
	warning: PropTypes.string,
	subtitle: PropTypes.string,
	modalAction: PropTypes.func,
	toggleLabelOne: PropTypes.string,
	toggleLabelTwo: PropTypes.string,
	checkboxLabel: PropTypes.string,
	btnLabel: PropTypes.string,
};

export default ToggleOption;