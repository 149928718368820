import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { writeTelemetryEvent } from 'redux/playfab';
import { isAccountCompletedSelector, isAccountDeclinedSelector, isBackEndRegistrationCompleteSelector, userNameSelector } from 'selector/player';
import { statSelector } from 'selector/stats';
import Debug from 'components/Partials/Debug';


function mapStateToProps(state) {
	return {
		teamsId: state.teams?.userObjectId,
		playfabId: state.playfab?.PlayFabId,
		channel: state.chat?.channel,
		isMainChatConnected: state.chat?.chatReady,
		currentState: state.auth?.step,
		isAccountCompleted: isAccountCompletedSelector(state),
		isAccountDeclined: isAccountDeclinedSelector(state),
		isRegistered: isBackEndRegistrationCompleteSelector(state),
		displayName: userNameSelector(state),
		region: statSelector(state, { statName: 'region' }),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		writeTelemetryEvent,
	}, dispatch)
)(Debug);
