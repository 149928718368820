/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import PropTypes from "prop-types";
import { REACT_APP_LIGHTSPEED_CLIENT } from "Constants";

/*
 * Placeholder at the end of the event
 */

function EventOver({ acfPageOptions }) {
	//TODO: Use Status component
	return (
		<>
			<div className="status-screens">
				<div className="background-img" />
				<div className="welcome-block">
					{!REACT_APP_LIGHTSPEED_CLIENT && (
						<div className="logo">
							<img
								src={acfPageOptions.acf?.theme?.logo?.main?.sizes?.large}
								alt=""
							/>
						</div>
					)}

					<div className="left">
						{acfPageOptions.acf?.progress_screens?.event_end?.title}
					</div>
					<div className="right">
						<p className="thank-you">
							{acfPageOptions.acf?.progress_screens?.event_end?.description}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

EventOver.defaultProps = {
	acfPageOptions: null,
};

EventOver.propTypes = {
	acfPageOptions: PropTypes.object,
};

export default EventOver;
