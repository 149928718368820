/* eslint-disable react/jsx-no-useless-fragment */
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import parse from "html-react-parser";

function EventsContent({ wpContent, onLoaded }) {
	useEffect(() => {
		onLoaded();
	}, []);
	
	return (
		<>	
			<div className="speaker-schedules-wrapper content-section">
				<div className="row">
					<div className="schedules-header">
						<h2 className="schedule-title">{wpContent?.title.rendered && parse(wpContent?.title.rendered)}</h2>
					</div>
					<div className="schedules-list">
						<div className="schedules-inner">
							<div className="schedules-inner-item">
								<div className="schedules-day-inner">
									<div className="schedules-day-time">
										{wpContent?.acf.event_day}
									</div>
								</div>
								<div className="schedules-item">
									<div className="item-schedules_items">
										<div className="time-schedules">
											<p>
												{wpContent?.acf.event_time_start}
												<span> - </span>
												{wpContent?.acf.event_time_end}
											</p>
										</div>
										<div className="item-wrapper">
											<h3>{wpContent.post_title}</h3>
											<p className="excerpt">{wpContent?.acf.event_excerpt}</p>
											<p className="location">{wpContent?.acf.event_location}</p>
											<div className="item-image-wrapper">
												{wpContent.acf?.event_partners.map(partners => (
													<div className="item-image" key={partners.ID}>
														<p>{partners.post_title}</p>														
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>	
			</div>
		</>
	);
}

EventsContent.defaultProps = {
	wpContent: null,
	onLoaded: () => {},
};

EventsContent.propTypes = {
	wpContent: PropTypes.object,
	onLoaded: PropTypes.func,
};

export default memo(EventsContent);
