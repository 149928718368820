/**
 * @format
 */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import * as MicrosoftTeams from "@microsoft/teams-js";
import dayjs from "dayjs";
import parse from "html-react-parser";
import { now } from "utils/dateUtils";
import { ROUTES } from "Constants";
import IconMail from "assets/icons/icon-mail-border.svg";
import Button from "components/Buttons/Button";
import IconWeb from "assets/icons/icon-web-border.svg";
import Layout from "components/Layout/Title";
import ComPanel from "containers/ComPanel/ComPanel";

import logPageView from "utils/logPageView";
import NotFound from "containers/Pages/NotFound";
/*
 * Single Partner page
 */

function Partner({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	partnerData,
	fetchWPPartnerData,
	acfPageOptions,
	chatJoinRoom,
	chatLeaveRoom,
	username,
	clearMessages,
	authMethod,
	playerGroup,
}) {
	if (!partnerData) {
		return <NotFound />;
	}

	// Force to check the time when the page is loaded
	setTimeout(() => {
		checkTime();
	}, 500);

	const { partnerId } = useParams();
	const isLoadingData = Object.keys(partnerData).length === 0 || partnerId !== partnerData.slug;
	let percentageCompleted = 0;
	let totalLength = 0;
	let videoStarted = false;
	let videoTwentyFive = false;
	let videoFifty = false;
	let videoSeventyFive = false;
	let videoNinety = false;
	let videoComplete = false;

	useEffect(() => {
		if (partnerId) {
			const data = {
				playerGroup,
				partnerId,
			};

			fetchWPPartnerData(data);
		}
	}, []);

	useEffect(() => {
		//Log PageView
		logPageView("partner", playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);

	function goToLink(url, name) {
		window.dataLayer.push({
			event: "generic_event",
			eventCategory: "pdf",
			eventAction: "download",
			eventLabel: name,
		});

		const urlCleaned = url.replaceAll(" ", "").replaceAll(" ", "");
		return !authMethod ? window.open(urlCleaned) : MicrosoftTeams.executeDeepLink(urlCleaned);
	}

	function addBodyClass() {
		document.body.classList.add("withChat");
	}

	function removeBodyClass() {
		document.body.classList.remove("withChat");
	}

	function onTimeUpdate(e) {
		getPercentage(e.currentTarget.getAttribute("attr"));
	}

	function getPercentage(name) {
		const myPlayer = document.querySelector(".videoPartner");

		totalLength = myPlayer.duration;
		percentageCompleted = (myPlayer.currentTime / totalLength) * 100;

		// is 0
		if (!videoStarted && percentageCompleted > 0) {
			videoStarted = true;
			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "play",
				eventLabel: name,
			});
		}
		// is 25
		if (!videoTwentyFive && percentageCompleted > 25) {
			videoTwentyFive = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "25%",
				eventLabel: name,
			});
		}
		// is 50
		if (!videoFifty && percentageCompleted > 50) {
			videoFifty = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "50%",
				eventLabel: name,
			});
		}
		// is 75
		if (!videoSeventyFive && percentageCompleted > 75) {
			videoSeventyFive = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "75%",
				eventLabel: name,
			});
		}

		// is 90
		if (!videoNinety && percentageCompleted > 90) {
			videoNinety = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "90%",
				eventLabel: name,
			});
		}

		// is 100
		if (!videoComplete && percentageCompleted > 99) {
			videoComplete = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "100%",
				eventLabel: name,
			});
		}
	}

	useEffect(() => {
		addBodyClass();
		const channel = partnerId;
		if (channel && username) {
			chatJoinRoom({
				channel,
				username,
				playfabId,
			});
		}
		return () => {
			chatLeaveRoom({
				channel,
			});
			clearMessages();
			removeBodyClass();
		};
	}, [partnerId, username]);

	const [partnerStatus, setPartnerStatus] = useState(false);

	function checkTime() {
		const today = dayjs.utc().local();
		let myTimeSlots = null;
		myTimeSlots = acfPageOptions.acf?.partner?.time_slots;

		if (myTimeSlots) {
			for (let i = 0; i < myTimeSlots.length; i += 1) {
				for (let j = 0; j < myTimeSlots[i].active_start.length; j += 1) {
					const rightBeginDate = dayjs.utc(myTimeSlots[i].active_start[j].hour_start).local();
					const rightEndingDate = dayjs.utc(myTimeSlots[i].active_end[j].hour_end).local();

					if (today.isAfter(rightBeginDate) && today.isBefore(rightEndingDate)) {
						setPartnerStatus(true);
					}
				}
			}
		}
		return null;
	}

	useEffect(() => {
		const interval = setInterval(checkTime(), 30000);

		return () => {
			clearInterval(interval);
		};
	}, [now]);

	const pageInfo = {
		StoreTileId: partnerId,
		isQuestionBox: true,
		content: {
			title: {
				rendered: partnerData.title?.rendered,
			},
		},
	};

	return (
		<Layout
			titleSlot={acfPageOptions.acf?.partner?.title}
			pageClass="partner-page"
			isLoading={isLoadingData}
			backLink={ROUTES.PARTNERS}
			backText={acfPageOptions.acf?.partner?.back_button}
			backgroundUrl={acfPageOptions?.acf?.partner?.background ? acfPageOptions?.acf?.partner?.background : ""}
		>
			<div className="resizable-container">
				<div className="partner-content-wrap">
					<div className="intro-wrap">
						<div className="top-container">
							{partnerData.acf?.logo && (
								<div className="logos-container">
									<div className="logo-ctn">
										<img className="logo" src={partnerData.acf?.logo?.sizes?.medium_large} alt="" />
									</div>
								</div>
							)}
						</div>
						<div className="more-info-wrap">
							{partnerData.acf?.video_link && (
								<video
									className="videoPartner"
									src={partnerData.acf?.video_link}
									// attr={partnerData?.title?.rendered}
									onTimeUpdate={onTimeUpdate}
									controls
								/>
							)}
							<div className="partner-info-content">
								<div className="meta-container">
									<ul className="speaker-meta">
										{partnerData.acf?.emails &&
											partnerData.acf?.emails.map((email) => (
												<li className="speaker-meta-item" key={email.value}>
													<span className="meta-title">
														<img src={IconMail} alt="" width="20px" />
													</span>
													<a href={`mailto:${email.value}`}>{email.value}</a>
												</li>
											))}

										{partnerData.acf?.website && (
											<li className="speaker-meta-item ">
												<span className="meta-title">
													<img src={IconWeb} alt="" width="20px" />
												</span>
												<button type="button" onClick={() => goToLink(partnerData.acf?.website)}>
													{partnerData.acf?.website}
												</button>
											</li>
										)}

										{partnerData.acf?.phone && (
											<li className="speaker-meta-item">
												<div className="icon-phone" />
												{partnerData.acf?.phone}
											</li>
										)}
									</ul>

									{partnerData.acf?.social && (
										<div className="social-ctn">
											{partnerData.acf?.social[0]?.facebook && (
												<button
													type="button"
													onClick={() => goToLink(partnerData.acf.social[0].facebook)}
													className="icon-facebook"
												>
													<span className="hidden">Facebook</span>
												</button>
											)}
											{partnerData.acf?.social[0]?.twitter && (
												<button
													type="button"
													onClick={() => goToLink(partnerData.acf.social[0].twitter)}
													className="icon-twitter"
												>
													<span className="hidden">Twitter</span>
												</button>
											)}
											{partnerData.acf?.social[0]?.youtube && (
												<button
													type="button"
													onClick={() => goToLink(partnerData.acf.social[0].youtube)}
													className="icon-youtube"
												>
													<span className="hidden">Youtube</span>
												</button>
											)}
											{partnerData.acf?.social[0]?.linkedin && (
												<button
													type="button"
													onClick={() => goToLink(partnerData.acf.social[0].linkedin)}
													className="icon-linkedin"
												>
													<span className="hidden">Linkedin</span>
												</button>
											)}
											{partnerData.acf?.social[0]?.instagram && (
												<button
													type="button"
													onClick={() => goToLink(partnerData.acf.social[0].instagram)}
													className="icon-instagram"
												>
													<span className="hidden">Instagram</span>
												</button>
											)}
										</div>
									)}
								</div>

								<div className="wysiwyg">
									{partnerData.acf?.subtitle && <h2 className="sub-title">{partnerData.acf?.subtitle}</h2>}
									<div className="description">
										{partnerData?.content?.rendered && parse(partnerData?.content?.rendered)}
									</div>
								</div>

								<div className="files-download">
									{partnerData.acf?.files_dowload &&
										partnerData.acf?.files_dowload.map((file) => (
											<Button
												action={() => goToLink(file.file_link, file.file_name)}
												icon={acfPageOptions?.acf?.theme?.icon?.partner?.download?.url}
												classes="primary medium download-file"
												text={file.file_name || "Download file"}
												key={file.file_link}
												orientation="right"
												download
											/>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
				{partnerData.acf?.feature_image?.sizes?.large && (
					<div className="partner-image">
						<img src={partnerData.acf?.feature_image?.sizes?.large} alt="partner" />
					</div>
				)}

				<div className="partner-com-panel">
					<ComPanel
						activity={pageInfo}
						collapsable={acfPageOptions?.acf?.partner?.com_panel?.is_collapsable}
						show_chat={acfPageOptions?.acf?.partner?.com_panel?.show_chat}
						show_time_slot={acfPageOptions?.acf?.partner?.com_panel?.show_time_slots}
						defaultTab={acfPageOptions?.acf?.partner?.com_panel?.default_tab}
						partnerData={partnerData}
						partnerStatus={partnerStatus}
						partnerName={partnerData?.title?.rendered}
						showPartnerStatus
					/>
				</div>
			</div>
		</Layout>
	);
}

Partner.defaultProps = {
	playerStatistics: null,
	playfabId: "",
	leaderboardOnUserPosition: [],
	playerData: null,
	partnerData: null,
	fetchWPPartnerData: () => {},
	acfPageOptions: null,
	chatJoinRoom: () => {},
	chatLeaveRoom: () => {},
	clearMessages: () => {},
	username: "",
	authMethod: null,
	playerGroup: null,
};

Partner.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	partnerData: PropTypes.object,
	fetchWPPartnerData: PropTypes.func,
	acfPageOptions: PropTypes.object,
	chatJoinRoom: PropTypes.func,
	chatLeaveRoom: PropTypes.func,
	clearMessages: PropTypes.func,
	username: PropTypes.string,
	authMethod: PropTypes.string,
	playerGroup: PropTypes.string,
};

export default Partner;
