const debugInfos = {
	locale: 'en-us',
	theme: 'default',
	entityId: 'Year End Party',
	subEntityId: '',
	isFullScreen: false,
	sessionId: 'ff5c01cc-600a-4b58-d080-38719543435f',
	chatId: '',
	meetingId: '',
	hostClientType: 'web',
	tenantSKU: 'unknown',
	jsonTabUrl: 'microsoft-teams-json-tab.azurewebsites.net',
	userLicenseType: 'Unknown',
	appSessionId: 'c6c2753e-f5a2-4e24-aa52-dad594591a95',
	isMultiWindow: false,
	appIconPosition: 135,
	frameContext: 'content',
	teamSiteDomain: 'aliceandsmith0.sharepoint.com',
	teamSitePath: '',
	teamSiteUrl: '',
	ringId: 'general',
	tid: '42498b35-332c-47e2-ab25-d5b0cd3ef884',
	loginHint: 'mdesroches@aliceandsmith.com',
	upn: 'mdesroches@aliceandsmith.com',
	userPrincipalName: 'info@aliceandsmith.com',
	//userObjectId: '847a9755-8f39-4935-bd10-fd4b2e288733', // Maxime
	userObjectId: '887b0e59-7703-445e-99f6-d9d8b2717537', // Andrea
};

export default debugInfos;
