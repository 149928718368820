import { createSelector } from "@reduxjs/toolkit";
import sortByKey from 'utils/sortByKey';

const galleryMediasPropSelector = (state, props) => props.galleryData;
const wordPressMediasSelector = state => state.wppage?.gallery_medias;
const mediaSlugFromRoute = (state, props) => props.match?.params?.mediaId;

export const getGalleryFilters = createSelector([galleryMediasPropSelector], (medias) => {
	if (!medias || medias.length === 0) return null;

	let filters = [];

	medias.map((media) => {
		for(let i = 0; i< media?.acf?.object_category?.length; i+=1) {
			filters.push(media?.acf?.object_category[i]);
		}
		filters = filters.sort((
			a,
			b
		) => {
			const catA = a.category_value;
			const catB = b.category_value;
			if (catA > catB) {
				return -1;
			}
			if (catA < catB) {
				return 1;
			}
			return 0;
		});

		filters = filters.sort((x,y) => { return x.category_value === 'general: General' ? -1 : y.category_value === 'general: General' ? 1 : 0; });

		return filters;
	})

	return [...new Map(filters.map(item => [item['category_value'], item])).values()]
});


export const getDealersLoungeFilters = createSelector([galleryMediasPropSelector], (medias) => {
	if (!medias || medias.length === 0) return null;

	const filters = [];

	medias.map((media) => {
		for(let i = 0; i< media?.acf?.object_category?.length; i+=1) {
			filters.push(media?.acf?.object_category[i]);
		}
		return (sortByKey(filters,'category_label'));
	})
	return [...new Map(filters.map(item => [item['category_value'], item])).values()]
});

export const singleMediaSelector = createSelector(
	[wordPressMediasSelector, mediaSlugFromRoute],
	(wordpressMedias, slug) => {
		if (!wordpressMedias || !slug) return null;

		const media = wordpressMedias.find(s => s.slug === slug);
		if (!media) return null;

		return media;
	},
);