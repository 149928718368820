import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchWPInteractiveGalleriesData } from "redux/wppage";
import InteractiveGallery from "components/Pages/InteractiveGallery";
import { writeTelemetryEvent } from "redux/playfab";
import { playerGroupSelector } from "selector/player";
import { getGalleryFilters } from "selector/contentGallery";

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		galleryData: state.wppage.interactive_gallery,
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
		galleryFilters: getGalleryFilters(state, { galleryData: state.wppage.interactive_gallery }),
		navigation: state.wpcontent.navigation,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			fetchWPInteractiveGalleriesData,
			writeTelemetryEvent,
		},
		dispatch
	)
)(InteractiveGallery);
