import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProfileTab from 'components/Profile/ProfileTab';
import { statSelector } from 'selector/stats';
import { PARTNERSTAT } from 'Constants';

import {
	updatePlayerData,
	writePlayerEvent,
	getItemInventory,
} from 'redux/playfab';
import { userNameSelector } from 'selector/player';

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		enableNotifications: state.playfab?.PlayerData?.EnableNotifications,
		displayName: userNameSelector(state),
		playerData: state.playfab?.PlayerData,
		playfabId: state.playfab?.PlayFabId,
		sessions: state.playfab?.Statistics,
		isPartner: statSelector(state, { statName: 'partner' }) === PARTNERSTAT.PARTNER,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		updatePlayerData,
		writePlayerEvent,
		getItemInventory,
	}, dispatch)
)(ProfileTab);
