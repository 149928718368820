/**
 * @format
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Nav, Container } from "react-bootstrap";
import { ROUTES } from "Constants";
import Layout from "../Layout/Title";

function Notices({ noticesData, fetchWPNoticesData, playerGroup, pageSlug }) {
	const [dataLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		};

		return fetchWPNoticesData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	if (dataLoaded) {
		return (
			<Layout
				titleSlot={noticesData?.title?.rendered}
				pageClass="terms-desktop"
				isLoading={
					!dataLoaded ||
					(dataLoaded &&
						Object.keys(noticesData).length === 0 &&
						noticesData.constructor === Object)
				}
			>
				<Nav className="notices-navigation" as="ul">
					<li className="">
						<Link
							to={ROUTES.CODE_OF_CONDUCT}
							className="text-uppercase fw-bold text-center"
						>
							{noticesData?.acf?.code_of_conduct?.page_title}
						</Link>
					</li>
					<li className="">
						<Link
							to={ROUTES.PRIVACY}
							className="text-uppercase fw-bold text-center"
						>
							{noticesData?.acf?.privacy_policy?.page_title}
						</Link>
					</li>
					<li className="">
						<Link
							to={ROUTES.TERMS}
							className="text-uppercase fw-bold text-center"
						>
							{noticesData?.acf?.terms_of_use?.page_title}
						</Link>
					</li>
				</Nav>
			</Layout>
		);
	}
	return null;
}

Notices.defaultProps = {
	noticesData: null,
	pageSlug: null,
	fetchWPNoticesData: () => {},
	playerGroup: null,
};

Notices.propTypes = {
	noticesData: PropTypes.object,
	fetchWPNoticesData: PropTypes.func,
	playerGroup: PropTypes.string,
	pageSlug: PropTypes.string,
};

export default Notices;
