/** @format */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ROUTES } from "Constants";
import Layout from "components/Layout/Title";

import logPageView from 'utils/logPageView';

function ShowRooms({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	acfPageOptions,
	showrooms,
}) {
	useEffect(() => {
		//Log PageView
		logPageView('showrooms', playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);

	function renderShowRoom(showroom) {
		return (
			<Link
				className="showrooms-listing-block align-middle"
				key={`${showroom.slug}`}
				to={ROUTES.SHOWROOM.replace(":showRoomSlug", showroom.slug)}
			>
				{showroom.acf?.showroom_logo && (
					<div className="image-wrap text-center">
						<img
							src={showroom.acf?.showroom_logo?.sizes?.large}
							alt=""
						/>
					</div>
				)}
			</Link>
		);
	}

	return (
		<Layout
			pageClass="showrooms-page"
			titleSlot={acfPageOptions.acf?.showroom?.title || 'Showrooms'}
			isLoading={
				!showrooms ||
				(showrooms && showrooms.length === 0)
			}
			backgroundUrl={acfPageOptions?.acf?.showroom?.background ? acfPageOptions?.acf?.showroom?.background : ''}
		>
			<div className="showrooms-listing-wrap row align-items-center">
				{showrooms &&
					showrooms.length > 0 &&
					showrooms.map((showroom) =>
						renderShowRoom(showroom)
					)}
			</div>
		</Layout>
	);
}

ShowRooms.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	acfPageOptions: {},
	showrooms: [],
}

ShowRooms.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	acfPageOptions: PropTypes.object,
	showrooms: PropTypes.array,
};

export default ShowRooms;
