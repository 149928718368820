import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SessionEvent from "mobile/components/session/SessionEvents";
import { eventsSelector } from "selector/events";
import { sendEventInput, resetMission, getLeaderboard } from "redux/playfab";
import { statSelector } from "selector/stats";

function mapStateToProps(state) {
	return {
		acfPageOptions: state?.wpcontent?.acfPageOptions,
		sessionEvents: eventsSelector(state),
		channel: state.chat.channel,
		playfabId: state.playfab.PlayFabId,
		points: statSelector(state, { statName: "xp" }),
		generalLeaderboard: state.playfab?.GeneralLeaderboard,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			sendEventInput,
			resetMission,
			getLeaderboard,
		},
		dispatch
	)
)(SessionEvent);
