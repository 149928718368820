/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Button from "components/Buttons/Button";

const ContactEmptyState = ({
	acfPageOptions,
}) => {
	const acfContactList = acfPageOptions?.acf?.profile?.contact_list_tab;
	const acfIcons = acfPageOptions?.acf?.theme?.icon?.profile;

	return (
		<div className="contact-empty-state">
			<div className="contact-empty-container">
				<div className="empty-image">
					<img src={acfIcons?.empty_list?.url} alt="empty" />
				</div>
				<div className="empty-message">
					{parse(acfContactList?.empty_list_description)}
				</div>
				<div className="join-button">
					<Button
						action={() => {}}
						classes="primary large"
						text="JOIN NOW"
					/>
				</div>
			</div>
		</div>
	);
}

ContactEmptyState.defaultProps = {
	acfPageOptions: {},
}

ContactEmptyState.propTypes = {
	acfPageOptions: PropTypes.object,
};

export default ContactEmptyState;