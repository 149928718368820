// @ts-check

import { instance } from 'as-stack-sdk/sdk';
import {
	REACT_APP_WP_API,
	REACT_APP_WP_API_ACF,
	REACT_APP_REALTIME_API,
	REACT_APP_PLAYFAB_APP_ID,
	REACT_APP_PLAYFAB_URL,
	REACT_APP_REALTIME_API_KEY,
} from 'Constants';

/**
 *Get data from WordPress API endpoint
 */
function getDataEndpointUrl(endpoint) {
	return `${REACT_APP_WP_API}${endpoint}`;
}

/**
 *Get data from WordPress ACF API endpoint
 */
function getDataEndpointUrlACF(endpoint) {
	return `${REACT_APP_WP_API_ACF}${endpoint}`;
}

export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const METHOD_JSON = 'JSON';

/**
 *Get JSON data from WordPress API endpoint
 */
export function jsonWordpressDataApi(endpoint) {
	const url = getDataEndpointUrl(endpoint);
	return getJSON(url, 'data', METHOD_GET);
}

/**
 *Get JSON data from WordPress API endpoint
 */
export function jsonWordpressACFDataApi(endpoint) {
	const url = getDataEndpointUrlACF(endpoint);
	return getJSON(url, 'data', METHOD_GET);
}

/**
 * Realtime API
 */
export function realtimeApi(endpoint, data = null) {
	const url = `${REACT_APP_REALTIME_API}/${endpoint}`;
	return getJSON(url, {
		'AppId': REACT_APP_PLAYFAB_APP_ID,
		...data,
	}, METHOD_POST, [['X-Api-Key', REACT_APP_REALTIME_API_KEY]]);
}

/**
 *Playfab client API
 */
export function playfabClientApi(endpoint, data, method = METHOD_JSON) {
	const url = `${REACT_APP_PLAYFAB_URL}/Client/${endpoint}`;
	return getJSON(url, data, method, [['X-Authorization', instance.GetSessionTicket()]]);
}

/**
 *Playfab Telemetry API
 */
export function playfabTelemetryApi(endpoint, data, method = METHOD_JSON) {
	const url = `${REACT_APP_PLAYFAB_URL}/Event/${endpoint}`;
	return getJSON(url, { Events: data }, method, [['X-EntityToken', instance.GetEntityToken()]]);
}

export function getJSON(url, data = {}, type = METHOD_GET, headers = []) {
	return new Promise((resolve, reject) => {
		if (!url) {
			reject();
			return;
		}

		let append = '';
		append += url.toString().indexOf('?') >= 0 ? '&' : '?';
		append += 'stackauth=9fa6f124';

		const request = new XMLHttpRequest();
		const method = type === METHOD_JSON ? METHOD_POST : type;
		request.open(method, url + append, true);

		let postData;
		if (type === METHOD_POST) {
			postData = Object.keys(data).reduce((fd, k) => {
				fd.append(k, data[k] === null ? '' : data[k]);
				return fd;
			}, new FormData());
		} else if (type === METHOD_JSON) {
			request.setRequestHeader('Content-Type', 'application/json');
			postData = JSON.stringify(data || '');
		}

		// @ts-ignore
		headers.forEach(header => request.setRequestHeader(...header));

		request.onerror = () => {
		};
		request.onload = () => {
			if (request.status >= 200 && request.status < 400) {
				const res = JSON.parse(request.responseText);
				const receivedData = res;
				resolve(receivedData);
			} else {
				reject();
			}
		};
		request.onerror = reject;
		request.send(postData);
	});
}
