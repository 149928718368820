import React, { useEffect, useState } from "react";

import { ReactComponent as IconTop } from "assets/icons/pack1/bottom-navigation-bar/icon-hamburger-menu-chevron-down.svg";

export default function ScrollToTop() {
	const [isVisible, setIsVisible] = useState(false);
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		const toggleVisibility = () => {
			if (window.pageYOffset > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	return (
		<div className="scroll-to-top">
			{isVisible && (
				<div
					onKeyDown={scrollToTop}
					tabIndex={0}
					role="button"
					aria-label="backToTop"
					onClick={scrollToTop}
				>
					<IconTop />
				</div>
			)}
		</div>
	);
}
