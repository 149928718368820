import React from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { Nav, NavLink, NavItem, Col } from "react-bootstrap";

function ProfileNavigation({ navigation, activeTab, setActiveTab }) {

	const profileTabs = [
		{
			id: 0,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_profile,
			is_displayed:
				navigation.acf?.navigation_acf?.profile?.display_tab_profile,
			url: "",
		},
		{
			id: 1,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_badges,
			is_displayed: navigation.acf?.navigation_acf?.profile?.display_tab_badges,
			url: "badges_prizes",
		},
		{
			id: 2,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_leaderboard,
			is_displayed:
				navigation.acf?.navigation_acf?.profile?.display_tab_leaderboard,
			url: "leaderboard",
		},
		{
			id: 3,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_contact_list,
			is_displayed:
				navigation.acf?.navigation_acf?.profile?.display_tab_contact_list,
			url: "contact_list",
		},
	];

	const { active_tab } = useParams();
	const history = useHistory();

	const toggle = (tab) => {
		if (active_tab !== tab) {
			history.push(`/profile/${tab}`);
		}
	};

	return (
		<Col>
			<Nav className="mt-4 justify-content-between profile-navigation">
				{profileTabs.map((tab) => {
					const isActive = tab.id === activeTab;
					if (!tab?.is_displayed) {
						return null;
					}
					return (
						<NavItem key={tab.id}>
							<NavLink
								className={`pt-0 pb-1 px-0  navigation-tab ${
									isActive ? "active" : ""
								}`}
								onClick={() => {
									setActiveTab(tab.id);
									toggle(tab.url);
								}}
								role="button"
							>
								{tab.name}
							</NavLink>
						</NavItem>
					);
				})}
			</Nav>
		</Col>
	);
}

ProfileNavigation.defaultProps = {
	navigation: null,
	activeTab: null,
};

ProfileNavigation.propTypes = {
	navigation: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	setActiveTab: PropTypes.func.isRequired,
};

export default ProfileNavigation;
