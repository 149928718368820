/**
 * @format
 */

import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import uuid from "react-uuid";
import parse from "html-react-parser";
import { ROUTES } from "Constants";
import Layout from "../Layout/Title";

function Terms({ termsData, fetchWPNoticesData, playerGroup, pageSlug }) {
	const [dataLoaded, setDataLoaded] = useState(false);
	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		};

		return fetchWPNoticesData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	return (
		dataLoaded && (
			<Layout
				titleSlot={termsData?.acf?.terms_of_use?.page_title}
				pageClass="terms-desktop"
				isLoading={
					!dataLoaded ||
					(dataLoaded &&
						Object.keys(termsData).length === 0 &&
						termsData.constructor === Object)
				}
				backText="Back"
				backLink={ROUTES.NOTICES}
			>
				<div className="terms">
					<Container className="notices-anchors">
						<div className="terms-content">
							{parse(termsData?.acf?.terms_of_use?.description)}
						</div>
						<div>
							<ul className="terms-list list-unstyled">
								{termsData?.acf?.terms_of_use?.blocks &&
									termsData.acf.terms_of_use.blocks.map((termsTitles, i) => (
										<li className="rounded p-2 mb-2">
											<a
												href={`#terms_of_use-${i}`}
												key={termsTitles.title}
												className=""
											>
												{termsTitles.title}
											</a>
										</li>
									))}
							</ul>
						</div>
					</Container>
					<Container>
						{termsData?.acf?.terms_of_use?.blocks &&
							termsData.acf.terms_of_use.blocks.map((termsTexts, i) => (
								<Fragment key={uuid()}>
									<div className="term-wrapper" id={`terms_of_use-${i}`}>
										<h4 className="py-3">{termsTexts.title}</h4>
										{parse(termsTexts.text)}
									</div>
								</Fragment>
							))}
					</Container>
				</div>
			</Layout>
		)
	);
}

Terms.defaultProps = {
	termsData: null,
	pageSlug: null,
	fetchWPNoticesData: () => {},
	playerGroup: null,
};

Terms.propTypes = {
	termsData: PropTypes.object,
	fetchWPNoticesData: PropTypes.func,
	playerGroup: PropTypes.string,
	pageSlug: PropTypes.string,
};

export default Terms;
