/** @format */
/* eslint-disable react/no-unstable-nested-components */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import UploadModal from "components/Pages/profile/UploadModal";
import GeneralLeaderboard from "containers/Profile/GeneralLeaderboard";
import ProfileTab from "containers/Profile/ProfileTab";
import BadgesTab from "containers/Profile/BadgesTab";
import ProfileHeader from "containers/Profile/ProfileHeader";
import Layout from "components/Layout/Title";
import ContactList from 'containers/Profile/ContactList';

import logPageView from 'utils/logPageView';

/*
 * Profile page with 3 tabs: profile, leaderbord, badges
 */

function Profile({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	updatePlayerData,
	uploadAvatar,
	getLeaderboardOnPosition,
	acfPageOptions,
	getFriendsList,
	navigation,
}) {
	const [showModal, setShowModal] = useState({});

	useEffect(() => {
		getLeaderboardOnPosition(playfabId);
		getFriendsList();
	}, []);

	useEffect(() => {
		//Log PageView
		logPageView('profile', playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);

	function onClickEditAvatar() {
		onShowModal(true);
	}

	// function goToLink(url) {
	// 	return !authMethod ? window.open(url) : MicrosoftTeams.executeDeepLink(url);
	// }

	function onShowModal(show) {
		setShowModal((prev) => {
			return {
				s: show,
				key: show ? Math.random() : prev.key,
			};
		});
	}

	const profileTabs = [
		{
			id: 0,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_profile,
			is_displayed: navigation.acf?.navigation_acf?.profile?.display_tab_profile,
		},
		{
			id: 1,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_badges,
			is_displayed: navigation.acf?.navigation_acf?.profile?.display_tab_badges,
		},
		{
			id: 2,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_leaderboard,
			is_displayed:
				navigation.acf?.navigation_acf?.profile?.display_tab_leaderboard,
		},
		{
			id: 3,
			name: navigation.acf?.navigation_acf?.profile?.label_tab_contact_list,
			is_displayed:
				navigation.acf?.navigation_acf?.profile?.display_tab_contact_list,
		},
	];

	const [activeTab, setActiveTab] = useState(0);

	function RenderProfileContent() {
		switch (activeTab) {
			case 0:
				/* eslint-disable react/jsx-no-bind */
				return <ProfileTab onClickEditAvatar={onClickEditAvatar} setActiveTab={setActiveTab} contactListTab={3} navigation={navigation}/>;
			case 1:
				return <BadgesTab />;
			case 2:
				return <GeneralLeaderboard />;
			case 3:
				return <ContactList />;
			case 4:
				return <div className="profile-form">Settings</div>;

			default:
		}
	}

	return (
		<Layout
			pageClass="profile-page"
			backgroundUrl={acfPageOptions?.acf?.profile?.background ? acfPageOptions?.acf?.profile?.background : ''}
		>
			<div className="profile-container">
				<ProfileHeader
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					profileTabs={profileTabs}
				/>
				<RenderProfileContent />
				{/* eslint-disable react/jsx-no-bind */}
				<UploadModal
					key={showModal.key}
					show={showModal.s}
					setShow={onShowModal}
					uploadAvatar={uploadAvatar}
					updatePlayerData={updatePlayerData}
					acfPageOptions={acfPageOptions}
				/>
			</div>
		</Layout>
	);
}

Profile.defaultProps = {
	playerStatistics: null,
	playfabId: '',
	leaderboardOnUserPosition: [],
	playerData: null,
	updatePlayerData: () => {},
	uploadAvatar: () => {},
	getLeaderboardOnPosition: () => {},
	acfPageOptions: null,
	getFriendsList: () => {},
	navigation: null,
};

Profile.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	updatePlayerData: PropTypes.func,
	getLeaderboardOnPosition: PropTypes.func,
	uploadAvatar: PropTypes.func,
	acfPageOptions: PropTypes.object,
	getFriendsList: PropTypes.func,
	navigation: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Profile;
