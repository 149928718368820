import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Question from 'components/ComPanel/Questions/Question';
import { voteQuestion, getQuestions, fetchQuestion } from 'redux/chat';
import { userNameSelector } from 'selector/player';

function mapStateToProps(state) {
	return {
		channel: state.chat?.channel,
		displayName: userNameSelector(state, { fallback: state.teams.userPrincipalName }),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		voteQuestion,
		getQuestions,
		fetchQuestion,
	}, dispatch)
)(Question);
