/** @format */

function maintenanceCheckLoop() {
	setTimeout(() => {
		maintenanceCheck();
	}, 60000);
}

export function maintenanceCheck() {
	const url = `https://app.cf.holoscene.events/server_status.json?cache=${new Date()}`;

	fetch(url, {
		method: "GET",
	})
		.then((response) => response.json())
		.then((rsp) => {
			if (
				rsp[document.location.hostname]?.is_maintenance ||
				rsp?.is_maintenance_global
			) {
				if (
					document.location.hostname !== "localhost" &&
					document.location.hostname !==
						"demo.dev.backend.holoscene.events"
				) {
					document.location.assign(
						`${document.location.origin}/maintenance.htm`
					);
				} else {
					console.log(
						`is in maintance mode, but won't redirect to ${document.location.origin}/maintenance.htm so you can keep working...`
					); //don't reload locally
				}
			}
		})
		.catch((e) => {
			console.error("e", e.toString());
		})
		.finally(() => {
			maintenanceCheckLoop();
		});
}
