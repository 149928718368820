import React, { useState, useRef, useEffect } from "react";
import { useStore } from "react-redux";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ROUTES } from "Constants";
import ChatSupportChat from "containers/ChatSupport/ChatSupportChat";
import Button from "components/Buttons/Button";

function ChatSupport({
	chatSupportJoinRoom,
	chatSupportLeaveRoom,
	chatSupportConnect,
	chatSupportListen,
	chatSupportStopListen,
	connectionSupport,
	username,
	playfabId,
	joined_room,
	channel,
	is_resolved,
	support_options,
	requestSupport,
	clearMessages,
	messages,
	triggerNoNewMessages,
	acf_options,
	acfIcon,
	new_messages,
	closeSupportSession,
}) {
	const [showForm, setShowForm] = useState(false);
	const [formSubmitted, setSubmitted] = useState(false);
	const [messageSubmitted, setMessage] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectSupportValue, setSelectSupportValue] = useState("");
	const [messageValue, setMessageValue] = useState("");
	const ref = useRef();
	const state = useStore().getState();

	const [showClosingConfirmation, setShowClosingConfirmation] = useState(false);

	useEffect(() => {
		if (channel !== "" && username && formSubmitted) {
			chatSupportJoinRoom({
				channel,
				username,
				playfabId,
			});
		}
	}, [formSubmitted]);

	useEffect(() => {
		if (joined_room === true) {
			setMessage(true);
		}
		if (joined_room === false && is_resolved === true) {
			const divChat = document.querySelector(".support-chat");

			setTimeout(() => {
				divChat.classList.add("fade-out-chat");
			}, 4100);
			setTimeout(() => {
				chatSupportLeaveRoom({
					channel,
				});
				clearMessages();
				chatSupportStopListen();
				setMessage(false);
				setSubmitted(false);
				divChat.classList.remove("fade-out-chat");
			}, 5000);
		}
		return () => {};
	}, [joined_room]);

	useEffect(() => {
	  setSelectSupportValue(support_options[0]?.option || "");
	}, [support_options])


	useEffect(() => {
		if (showForm !== false) {
			triggerNoNewMessages();
		} else if (is_resolved === true) {
			triggerNoNewMessages();
		}
		return () => {};
	}, [messages, showForm]);

	useEffect(() => {
		if (connectionSupport) {
			chatSupportListen();
		}
	}, [connectionSupport]);

	function sendQuestion() {
		if (messageValue !== "") {
			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "support",
				eventAction: "message support sent",
				eventLabel: messageValue,
			});

			setLoading(true);
			requestSupport({
				Subject: selectSupportValue !== "" ? selectSupportValue : support_options[0]?.option,
				Message: messageValue,
			}).then(() => {
				chatSupportConnect().then(() => {
					setSubmitted(true);
					setLoading(false);
				});
			});
		}
	}

	function handleDefaultSubmit(e) {
		e.preventDefault();
		sendQuestion();
	}

	function handleMessageValue(ev) {
		setMessageValue(ev.target.value);
	}
	function handleSelectSupportValue(ev) {
		setSelectSupportValue(ev.target.value);
	}

	function resetChatSupport() {
		setShowForm(false);
		setSubmitted(false);
		setMessage(false);
		setLoading(false);
		setMessageValue(false);
		clearMessages();
		closeSupportSession();
		setShowClosingConfirmation(false);
		chatSupportListen();
		setSelectSupportValue(support_options[0]?.option || "");
	}

	function confirmationModal() {
		return (
			<CSSTransition in={showClosingConfirmation} timeout={600} appear unmountOnExit>
				<div className="d-flex h-75 justify-content-center align-items-center flex-column">
					<div className="my-5 text-center">
						{acf_options?.chat_support?.close_chat_support_message ||
							"Are you sure you want to close the support session ?"}
					</div>
					<div className="w-75">
						<Button
							classes="large primary my-3"
							action={() => resetChatSupport()}
							text={acf_options?.global?.logout_buttons?.yes_button || "YES"}
						/>
						<Button
							classes="large secondary my-3"
							action={() => setShowClosingConfirmation(false)}
							text={acf_options?.global?.logout_buttons?.no_button || "NO"}
						/>
					</div>
				</div>
			</CSSTransition>
		);
	}

	return (
		<div>
			<CSSTransition in={showForm} nodeRef={ref} classNames="form-transition" timeout={300}>
				<div ref={ref} className="form-chat-support">
					<div className={messageSubmitted === false ? "header-form-support" : "header-form-support chat-active"}>
						<div className="header-top">
							{state?.wpcontent?.navigation?.acf?.navigation_acf?.faq?.show && (
								<Link to={ROUTES.FAQ}>
									<span>{acf_options?.chat_support?.faq_title}</span>
								</Link>
							)}
							<div className="buttons-container">
								<button
									type="button"
									aria-label="Minimize Form"
									tabIndex="-1"
									onClick={() => setShowForm(!showForm)}
									onKeyPress={() => setShowForm(!showForm)}
								>
									<img alt="minimize" src={acfIcon?.icon?.general?.minimize?.url} />
								</button>

								<button
									type="button"
									aria-label="Show Form"
									tabIndex="-1"
									onClick={() => joined_room ? setShowClosingConfirmation(true) : resetChatSupport()}
									onKeyPress={() => setShowClosingConfirmation(true)}
								>
									<img alt="" src={acfIcon?.icon?.general?.exit?.image1?.url} />
								</button>
							</div>
						</div>
						<div className="header-section">
							<h3 className={messageSubmitted === false ? "active" : ""}>
								{acf_options?.chat_support?.message_tab_title}
							</h3>
							<h3 className={messageSubmitted === true ? "active" : ""}>
								{acf_options?.chat_support?.support_tab_title}
							</h3>
						</div>
					</div>
					{showClosingConfirmation ? (
						confirmationModal()
					) : (
						<>
							<div className={messageSubmitted === false ? "core-form-support" : "core-form-support hidden"}>
								{formSubmitted === false ? (
									<form
										className={`form-chat ${is_resolved ? "animation-fade-form" : ""}`}
										onSubmit={handleDefaultSubmit}
									>
										{loading === false ? (
											<>
												<h5 className="form-title">{acf_options?.chat_support?.form_title}</h5>
												<p>{acf_options?.chat_support?.email_label}</p>
												<input type="email" />
												<p>{acf_options?.chat_support?.subject_label}</p>
												<select value={selectSupportValue} id="select_support" onChange={(ev) => handleSelectSupportValue(ev)}>
													{support_options.map((val) => {
														return <option key={val.option}>{val.option}</option>;
													})}
												</select>
												<p>{acf_options?.chat_support?.message_label}</p>
												<textarea id="text_area_support" onChange={(ev) => handleMessageValue(ev)} />
												<div className="header-button-container">
													<Button
														action={
															messageValue === ""
																? null
																: () => {
																	sendQuestion();
																  }
														}
														classes={`secondary large ${messageValue === "" && "disabled"}`}
														text={acf_options?.chat_support?.send_button_label}
													/>
												</div>
											</>
										) : (
											<div className="loading-container">
												<img src={acfIcon.logo?.loading?.sizes?.large} alt="" />
											</div>
										)}
									</form>
								) : (
									<div className="thanks-message-container">
										<div className="thank-you-message">
											{acf_options?.chat_support?.success_message && parse(acf_options?.chat_support?.success_message)}
											{/* TODO: Handle error message
									{acf_options?.chat_support?.success_message && parse(acf_options?.chat_support?.error_message)} */}
										</div>
									</div>
								)}
							</div>
							<div className={messageSubmitted === true ? "core-form-support nopadding" : "core-form-support hidden"}>
								<ChatSupportChat />
							</div>
						</>
					)}
				</div>
			</CSSTransition>
			{showForm === false && (
				<div
					tabIndex="-1"
					role="button"
					onClick={() => setShowForm(!showForm)}
					onKeyPress={() => setShowForm(!showForm)}
					className="chat-support-button"
				>
					<div className="support-icon">
						<img className="normalIcon" src={acf_options?.theme?.icon?.navigation?.tech_support?.image1?.url} alt="" />
						<img className="activeIcon" src={acf_options?.theme?.icon?.navigation?.tech_support?.image2?.url} alt="" />
					</div>
					<p className="support-button-text">
						{acf_options?.chat_support?.chat_support_button_label}
						{new_messages ? <div className="support-icon-message" /> : ""}
					</p>
				</div>
			)}
		</div>
	);
}

ChatSupport.defaultProps = {
	chatSupportJoinRoom: () => {},
	chatSupportLeaveRoom: () => {},
	clearMessages: () => {},
	chatSupportConnect: () => {},
	chatSupportListen: () => {},
	chatSupportStopListen: () => {},
	requestSupport: () => {},
	connectionSupport: false,
	username: "",
	channel: "",
	playfabId: "",
	joined_room: false,
	is_resolved: false,
	support_options: [],

	messages: [],
	triggerNoNewMessages: () => {},
	acf_options: {},
	acfIcon: {},
	new_messages: false,
	closeSupportSession: () => {},
};

ChatSupport.propTypes = {
	chatSupportJoinRoom: PropTypes.func,
	chatSupportLeaveRoom: PropTypes.func,
	chatSupportConnect: PropTypes.func,
	chatSupportListen: PropTypes.func,
	chatSupportStopListen: PropTypes.func,
	connectionSupport: PropTypes.bool,
	clearMessages: PropTypes.func,
	requestSupport: PropTypes.func,
	channel: PropTypes.string,
	username: PropTypes.string,
	playfabId: PropTypes.string,
	joined_room: PropTypes.bool,
	is_resolved: PropTypes.bool,
	support_options: PropTypes.array,
	messages: PropTypes.array,
	triggerNoNewMessages: PropTypes.func,
	acf_options: PropTypes.object,
	acfIcon: PropTypes.object,
	new_messages: PropTypes.bool,
	closeSupportSession: PropTypes.func,
};

export default ChatSupport;
