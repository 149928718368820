import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	getRandomRooms,
} from 'redux/rooms';

import Notification from 'components/Notification';
import { notificationSelector } from 'selector/inventory';
import { singleNotifSpeakerSelector } from 'selector/speakers';
import { addSeenNotification } from 'redux/inventory';
import { consumeInventoryItem } from 'redux/playfab';
import { setActivityFooterContent } from 'redux/wpcontent';

function mapStateToProps(state, props) {
	return {
		acfPageOptions: state?.wpcontent?.acfPageOptions,
		notification: notificationSelector(state, props),
		speaker: singleNotifSpeakerSelector(state, props),
		authMethod: state.auth?.method,
		enableNotifications: state.playfab?.PlayerData?.EnableNotifications?.Value,
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		getRandomRooms,
		addSeenNotification,
		consumeInventoryItem,
		setActivityFooterContent,
	}, dispatch)
)(Notification));
