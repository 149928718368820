import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchWPNoticesData } from "redux/wppage";
import { writeTelemetryEvent } from "redux/playfab";
import { playerGroupSelector } from "selector/player";
import Notices from "../../components/Pages/Notices";

function mapStateToProps(state) {
	return {
		noticesData: state.wppage.notices,
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			fetchWPNoticesData,
			writeTelemetryEvent,
		},
		dispatch
	)
)(Notices);
