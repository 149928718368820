import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { badgesUnlockedRegionSelector } from 'selector/catalog';
import { playerGroupSelector } from 'selector/player';
import BadgesTab from 'components/Profile/BadgesTab';

function mapStateToProps(state) {
	return {
		badgesUnlocked: badgesUnlockedRegionSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
	}, dispatch)
)(BadgesTab));
