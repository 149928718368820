/** @format */
/* eslint-disable no-unsafe-optional-chaining */

import React from "react";
import PropTypes from "prop-types";
import { useDrag } from "react-dnd";
import { BUBBLE_MOBILITY_TYPE } from "utils/constants";
import BubbleContent from "./BubbleContent";

function VideoBubble({
	activity,
	tileId,
	history,
	translationData,
	emptyVideoPlayer,
	bubblePosition,
	setStartTime,
	videoBubbleSrc,
	setBubbleSrc,
}) {
	const defaultOptions = {
		nativeControlsForTouch: true,
		controls: true,
		autoplay: true,
		width: 480,
		height: 300,
		logo: { enabled: false },
	};

	function pushHistory() {
		history.push("/activity/" + activity?.StoreTileId);
	}

	function closeStream() {
		emptyVideoPlayer();
		setStartTime(null);
		setBubbleSrc(null);
	}

	function srcToReturn() {
		if (translationData) {
			return translationData;
		}
		return videoBubbleSrc;
	}

	const [, drag] = useDrag(
		() => ({
			type: BUBBLE_MOBILITY_TYPE,
			item: bubblePosition,
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[bubblePosition]
	);

	return (
		<BubbleContent
			activity={activity}
			bubblePosition={bubblePosition}
			closeStream={() => closeStream()}
			defaultOptions={defaultOptions}
			drag={drag}
			history={history}
			pushHistory={() => pushHistory()}
			srcToReturn={() => srcToReturn()}
			tileId={tileId}
		/>
	);
}

VideoBubble.defaultProps = {
	activity: null,
	translationData: "",
	emptyVideoPlayer: null,
	bubblePosition: {},
	tileId: null,
	setStartTime: null,
	videoBubbleSrc: null,
	setBubbleSrc: () => {},
};

VideoBubble.propTypes = {
	tileId: PropTypes.string,
	activity: PropTypes.object,
	history: PropTypes.object.isRequired,
	translationData: PropTypes.string,
	emptyVideoPlayer: PropTypes.func,
	bubblePosition: PropTypes.object,
	setStartTime: PropTypes.func,
	videoBubbleSrc: PropTypes.string,
	setBubbleSrc: PropTypes.func,
};

export default VideoBubble;
