/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React, { createContext, useState } from 'react';
import PropTypes from "prop-types";

export const ComPanelContext = createContext();

export const ComPanelContextProvider = ({ children }) => {
	const [inputMessage, setInputMessage] = useState('');

	return (
		<ComPanelContext.Provider
			value={{
				inputMessage,
				setInputMessage,
			}}>
			{children}
		</ComPanelContext.Provider>
	);
};


ComPanelContextProvider.defaultProps = {
	children: null,
}

ComPanelContextProvider.propTypes = {
	children: PropTypes.array,
}