import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { writeTelemetryEvent } from "redux/playfab";
import { fetchWPNoticesData, fetchWPPageData } from "redux/wppage";
import { playerGroupSelector } from "selector/player";

import Terms from "components/Pages/Terms";
import { statsSelector } from "selector/stats";

function mapStateToProps(state, props) {
	return {
		termsData: state.wppage.notices,
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			fetchWPNoticesData,
			writeTelemetryEvent,
			fetchWPPageData,
		},
		dispatch
	)
)(Terms);
