import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Emoji from "./Emoji";

function DisplayZone({ completedCallback, duration, limit, width }) {
	const [negativeEndY, setNegativeEndY] = useState(-300);
	const [events, setEvents] = useState([]);

	function onEmojiChat(e) {
		if (e.detail && e.detail.data) {
			setEvents((evts) => [...evts, { name: e.detail.data.toLowerCase(), id: e.detail.id }]);
		}
	}

	function onComplete(id) {
		setEvents((evts) => evts.filter((event) => event.id !== id));
		completedCallback(id);
	}

	function renderEmojis() {
		if (events.length === 0) {
			return null;
		}

		return [...events]
			.reverse()
			.slice(0, limit)
			.map((e, i) => (
				<div key={e.id} className={e.id + " emoji-el"}>
					<Emoji
						delay={i * 500}
						name={e.name}
						id={e.id}
						negativeEndY={negativeEndY}
						width={width}
						duration={duration}
						completedCallback={() => onComplete()}
					/>
				</div>
			));
	}

	function onResize() {
		setNegativeEndY(window.innerHeight * -1);
	}

	useEffect(() => {
		window.addEventListener("emoji_chat", onEmojiChat);
		window.addEventListener("resize", onResize);

		return () => {
			window.removeEventListener("emoji_chat", onEmojiChat);
			window.removeEventListener("resize", onResize);
		};
	}, []);

	return (
		<div className="emojis-display-zone">
			<div className="emojis">{renderEmojis()}</div>
		</div>
	);
}

DisplayZone.defaultProps = {
	completedCallback: () => {},
	duration: 3000,
	limit: 75,
	width: 200,
};

DisplayZone.propTypes = {
	completedCallback: PropTypes.func,
	duration: PropTypes.number,
	limit: PropTypes.number,
	width: PropTypes.number,
};

export default DisplayZone;
