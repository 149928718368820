import React from "react";
import PropTypes from "prop-types";

function Checkbox({ checked, onChange, checkboxId, label, name }) {
	return (
		<div className="checkbox-group">
			<input
				checked={checked}
				onChange={onChange}
				type="checkbox"
				id={checkboxId}
				name={name}
			/>
			<label htmlFor={checkboxId}>{label}</label>
		</div>
	);
}

Checkbox.propTypes = {
	onChange: PropTypes.func,
	checked: PropTypes.bool,
	checkboxId: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
};

Checkbox.defaultProps = {
	onChange: () => {},
	checked: null,
	checkboxId: null,
	label: null,
	name: null,
};

export default Checkbox;
