import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BREAKPOINTS_QUERIES } from "Constants";
import { BreakpointProvider } from 'mobile/context/BreakpointContext';
import Amplify from "aws-amplify";
import AMPLIFY_CONFIG from "./multi-org-aws-exports-override";
import { createFromHostname } from "./GetEnvironment";

import "./scss/index.scss";
import Init from "./Init";
import App from "./containers/App";
import store from "./store";

const environment = createFromHostname(
	process.env.REACT_APP_HOSTNAME || document.location.hostname
);

Sentry.init({
	environment: document.location.hostname,
	dsn: "https://7911086ce4674adea7984463dcea059c@o1116690.ingest.sentry.io/6150388",
	integrations: [new Integrations.BrowserTracing()],
	initialScope: {
		tags: environment,
	},
	tracesSampleRate: 1.0,
	beforeSend: (event) => {
		if (window.location.hostname === "localhost") {
			return null;
		}
		return event;
	},
});

Amplify.configure(AMPLIFY_CONFIG);
Init();

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<DndProvider backend={HTML5Backend}>
				<BreakpointProvider queries={BREAKPOINTS_QUERIES}>
					<App />
				</BreakpointProvider>
			</DndProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
