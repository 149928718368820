import React, {
	useEffect,
	useState,
	useRef,
} from 'react';
import PropTypes from 'prop-types';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

//#endregion
const MIN_LOADING_TIME = 1000;

function LoadingInnerContent(
	{
		isLoading,
		acfPageOptions,
		children,
		className }
) {

	const [isLoadingActivated, setIsLoadingActivated] = useState(isLoading);
	const ref = useRef();
	const usePrevious = value => {
		const propRef = useRef();
		useEffect(() => {
			propRef.current = value;
		});
		return propRef.current;
	}
	const prevIsLoading = usePrevious(isLoading);

	useEffect(() => {
		if(prevIsLoading !==isLoading){
			return queueLoading(isLoading);
		}
		return null;
	})

	useEffect(()=>{
		return () => {clearTimeout(animationTiming);}
	},[])

	const animationTiming = (on) => {
		setTimeout(() => {
			setIsLoadingActivated(on)
		}, on ? 0 : MIN_LOADING_TIME);
	}

	const queueLoading = (on) => {
		animationTiming(on);
	}

	const content = isLoadingActivated ? (
		<>
			<div className="sidenav-left-column" />
			<div className="loading-inner-gif">
				<img alt='loading' src={acfPageOptions?.acf?.theme?.logo?.loading?.sizes?.large} className="loader" />
			</div>
		</>
	) : children;

	return (
		<SwitchTransition>
			<CSSTransition key={`loading_${isLoadingActivated}_${children?.toString()}`} in timeout={700} unmountOnExit appear nodeRef={ref}>
				<div className={className} ref={ref}>
					{content}
				</div>
			</CSSTransition>
		</SwitchTransition>
	)
}

LoadingInnerContent.propTypes = {
	isLoading: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	acfPageOptions: PropTypes.object,
};

LoadingInnerContent.defaultProps = {
	isLoading: false,
	className: null,
	children: null,
	acfPageOptions: null,
}

export default LoadingInnerContent;