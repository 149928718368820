// displays all the companel's for selection
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ChatIcon } from "assets/icons/pack1/chat/chat-menu/icon-chat.svg";
import { ReactComponent as QaIcon } from "assets/icons/pack1/chat/chat-menu/icon-q-and-a.svg";
import { ReactComponent as SupportIcon } from "assets/icons/pack1/chat/chat-menu/icon-tech-support.svg";
import { ComPanelContext } from "mobile/context/ComPanelContext";
import Header from "./index";
import NavOption from "./NavOption";

function Navigation({
	showCompanelNavigation,
	setShowCompanelNavigation,
	session,
	newMessages,
	newQuestions,
	newSupportMessages,
	messages,
	questions,
	stateVideo,
	acfPageOptions,
}) {
	const sessionAcf = session?.content?.acf?.com_panel;
	let defaultTabValue = "none";
	const { activeCompanelTab, setActiveCompanelTab } =
		useContext(ComPanelContext);
	if (sessionAcf?.default_tab === "Q&A" && !sessionAcf?.show_qa) {
		defaultTabValue = "none";
	} else if (sessionAcf?.default_tab === "CHAT" && !sessionAcf?.show_chat) {
		defaultTabValue = "none";
	} else {
		defaultTabValue = sessionAcf?.default_tab;
	}

	return !showCompanelNavigation ? (
		<Header
			setShowCompanelNavigation={setShowCompanelNavigation}
			newMessages={newMessages}
			newQuestions={newQuestions}
			messages={messages}
			questions={questions}
			stateVideo={stateVideo}
			newSupportMessages={newSupportMessages}
		/>
	) : (
		<div className="d-flex flex-column justify-content-between mx-3 my-4">
			{session?.content?.acf?.com_panel?.show_chat && (
				<NavOption
					title={acfPageOptions?.acf?.live_chat_panel?.navigation_com_panel?.chat_title}
					description={acfPageOptions?.acf?.live_chat_panel?.navigation_com_panel?.chat_description}
					Icon={ChatIcon}
					setShowCompanelNavigation={setShowCompanelNavigation}
					tabType="CHAT"
					notificationsCount={newMessages}
					stateVideo={stateVideo}
					acfPageOptions={acfPageOptions}
				/>
			)}

			{session?.content?.acf?.com_panel?.show_qa && (
				<NavOption
					title={acfPageOptions?.acf?.live_chat_panel?.navigation_com_panel?.qa_title}
					description={acfPageOptions?.acf?.live_chat_panel?.navigation_com_panel?.qa_description}
					Icon={QaIcon}
					setShowCompanelNavigation={setShowCompanelNavigation}
					tabType="Q&A"
					notificationsCount={newQuestions}
					stateVideo={stateVideo}
					acfPageOptions={acfPageOptions}
				/>
			)}
			
			<NavOption
				title={acfPageOptions?.acf?.live_chat_panel?.navigation_com_panel?.support_title}
				description={acfPageOptions?.acf?.live_chat_panel?.navigation_com_panel?.support_description}
				Icon={SupportIcon}
				setShowCompanelNavigation={setShowCompanelNavigation}
				tabType="SUPPORT"
				notificationsCount={newSupportMessages}
				acfPageOptions={acfPageOptions}
			/>
		</div>
	);
}

Navigation.defaultProps = {
	showCompanelNavigation: false,
	setShowCompanelNavigation: () => {},
	session: null,
	newMessages: 0,
	newQuestions: 0,
	newSupportMessages: 0,
	messages: [],
	questions: [],
	stateVideo: null,
	acfPageOptions: null,
};

Navigation.propTypes = {
	showCompanelNavigation: PropTypes.bool,
	setShowCompanelNavigation: PropTypes.func,
	session: PropTypes.object,
	newMessages: PropTypes.number,
	newQuestions: PropTypes.number,
	newSupportMessages: PropTypes.number,
	messages: PropTypes.array,
	questions: PropTypes.array,
	stateVideo: PropTypes.string,
	acfPageOptions: PropTypes.object,
};

export default Navigation;
