import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchWPNoticesData } from "redux/wppage";
import Notices from "mobile/pages/Notices";
import { writeTelemetryEvent } from "redux/playfab";
import { playerGroupSelector } from "selector/player";

import { statsSelector } from "selector/stats";

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		noticesData: state.wppage.notices,
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			fetchWPNoticesData,
			writeTelemetryEvent,
		},
		dispatch
	)
)(Notices);
