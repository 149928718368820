import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchWPInteractiveGalleriesData } from "redux/wppage";
import DealersLounge from "components/Pages/DealersLounge";
import { writeTelemetryEvent } from "redux/playfab";
import { playerGroupSelector, userNameSelector } from "selector/player";
import { getDealersLoungeFilters } from "selector/contentGallery";
import { chatJoinRoom, chatLeaveRoom, clearMessages } from 'redux/chat';

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		galleryData: state.wppage.dealers_lounge,
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
		galleryFilters: getDealersLoungeFilters(state, { galleryData: state.wppage.dealers_lounge }),
		username: userNameSelector(state),
		navigation: state.wpcontent.navigation,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			fetchWPInteractiveGalleriesData,
			writeTelemetryEvent,
			chatJoinRoom,
			chatLeaveRoom,
			clearMessages,
		},
		dispatch
	)
)(DealersLounge);
