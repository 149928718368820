import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { badgesUnlockedRegionSelector, badgesRegionSelector } from 'selector/catalog';
import { playerGroupSelector } from 'selector/player';
import Index from '../../profile/badgesTab/Index';

function mapStateToProps(state) {
	return {
		badgesUnlocked: badgesUnlockedRegionSelector(state),
		badges: badgesRegionSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
	}, dispatch)
)(Index));
