import React from 'react';
import PropTypes from 'prop-types';

function Badge({ data, isInInventory }) {
	return (
		<div className={`badge flex ${isInInventory ? 'on' : 'off'}`}>
			<div className="tooltip">
				<div className="reason">{data.reason}</div>
				<div className="name">{data.title}</div>
				<div className="description">{data.description}</div>
			</div>
			<img className="on" src={data.icon} alt="" />
			<img className="off" src={data.iconoff} alt="" />
			<p>{data.title}</p>
		</div>
	);
}

Badge.defaultProps = {
	isInInventory: false,
};

Badge.propTypes = {
	data: PropTypes.object.isRequired,
	isInInventory: PropTypes.bool,
};

export default Badge;
