import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import ProfilePicture from "assets/placeholders/profile-picture.png";

function LeaderCard({ user, playfabId, pointsLabel }) {
	return (
		<Container className={`px-5 py-2 leader-card ${user.PlayFabId === playfabId ? "my-card" : ""}`}>
			<Row className="align-items-center">
				<Col xs={2}>{user.Position}</Col>
				<Col xs={2}>
					<img
						className="rounded-circle w-100"
						src={user.Profile.AvatarUrl ? user.Profile.AvatarUrl : ProfilePicture}
						alt=""
					/>
				</Col>
				<Col xs={5}>{user.DisplayName}</Col>
				<Col xs={3}>
					{user.StatValue} {pointsLabel}
				</Col>
			</Row>
		</Container>
	);
}

LeaderCard.defaultProps = {
	user: null,
	playfabId: "",
	pointsLabel: "",
};

LeaderCard.propTypes = {
	user: PropTypes.object,
	playfabId: PropTypes.string,
	pointsLabel: PropTypes.string,
};

export default LeaderCard;
