import env from "redux/env";
import teams from "redux/teams";
import playfab from "redux/playfab";
import loading from "redux/loading";
import chat from "redux/chat";
import chatSupport from "redux/chatSupport";
import wpcontent from "redux/wpcontent";
import wppage from "redux/wppage";
import inventory from "redux/inventory";
import events from "redux/events";
import leaderboard from "redux/leaderboard";
import agenda from "redux/agenda";
import side_agenda from "redux/side_agenda";
import profile_card from "redux/profile_card";
import video_bubble from "redux/video_bubble";
import auth from "redux/auth";
import rooms from "redux/rooms";
import tracking from "redux/tracking";
import toastNotifications from "redux/toastNotifications";

export const rootReducer = {
	env: env.reducer,
	agenda: agenda.reducer,
	side_agenda: side_agenda.reducer,
	teams: teams.reducer,
	auth: auth.reducer,
	playfab: playfab.reducer,
	loading: loading.reducer,
	chat: chat.reducer,
	chatSupport: chatSupport.reducer,
	wpcontent: wpcontent.reducer,
	wppage: wppage.reducer,
	inventory: inventory.reducer,
	events: events.reducer,
	leaderboard: leaderboard.reducer,
	profile_card: profile_card.reducer,
	video_bubble: video_bubble.reducer,
	rooms: rooms.reducer,
	tracking: tracking.reducer,
	toastNotifications: toastNotifications.reducer,
};
