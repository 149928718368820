import React from "react";
import PropTypes from "prop-types";

function ShowTermTitle(props) {
	const { content, anchorLink } = props;
	const { title } = content || {};

	return (
		<li className="rounded p-2 mb-2">
			<a href={anchorLink} key={title} className="">
				{title}
			</a>
		</li>
	);
}
export default ShowTermTitle;

ShowTermTitle.propTypes = {
	content: PropTypes.object,
	anchorLink: PropTypes.string,
};

ShowTermTitle.defaultProps = {
	content: {},
	anchorLink: "",
};
