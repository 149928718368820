/** @format */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import Layout from "components/Layout/Title";
import Gallery from "components/ContentGallery/Gallery";
import { ROUTES } from "Constants";

import logPageView from "utils/logPageView";
import NotFound from "containers/Pages/NotFound";

function ShowRoom({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	acfPageOptions,
	showroom,
}) {
	if (!showroom) {
		return <NotFound />;
	}
	const [filterParam, setFilterParam] = useState(null);

	useEffect(() => {
		//Log PageView
		logPageView(
			"showroom",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			showroom
		);
	}, []);

	useEffect(() => {
		setFilterParam(acfPageOptions?.acf?.content_gallery?.all_filters);
	}, [acfPageOptions]);

	return (
		<Layout
			pageClass="showroom-page"
			titleSlot={showroom?.title?.rendered}
			backgroundUrl={
				showroom?.acf?.background
					? showroom?.acf?.background
					: acfPageOptions?.acf?.showroom?.background
						? acfPageOptions?.acf?.showroom?.background
						: ""
			}
			backLink={ROUTES.SHOWROOMS}
			backText={acfPageOptions.acf?.showroom?.back_button}
		>
			<div className="showroom-listing-wrap">
				<div className="showroom-iframe-container">
					{showroom?.acf?.iframe && parse(showroom?.acf?.iframe)}
				</div>
				{showroom?.acf?.content_gallery?.length > 0 && (
					<>
						<div className="showroom-list-header">
							{showroom?.acf?.content_gallery_title}
						</div>
						<Gallery
							displayComPanel={false}
							galleryData={showroom?.acf.content_gallery}
							acfPageOptions={acfPageOptions}
							filterParam={filterParam}
						/>
					</>
				)}
			</div>
		</Layout>
	);
}

ShowRoom.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	acfPageOptions: {},
	showroom: {},
};

ShowRoom.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	acfPageOptions: PropTypes.object,
	showroom: PropTypes.object,
};

export default ShowRoom;
