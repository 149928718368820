/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
	memo,
	useEffect,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { now } from 'utils/dateUtils';

function ChatSupportMessage({	
	message,
	timestamp,
	pfid,
	playfabId,
}) {
	const className = 'default';

	const [units, setUnits] = useState('s');
	const timeRef = useRef();
	const isSameUser = playfabId === pfid;


	useEffect(() => {
		const timer = setInterval(() => {
			const nowTimestamp = now().valueOf();
			const diffSecs = dayjs(nowTimestamp).diff(dayjs(timestamp), 'second');
			const diffMins = dayjs(nowTimestamp).diff(dayjs(timestamp), 'minute');
			const diffToShow = diffSecs <= 60 ? diffSecs + ' sec' : Math.floor(diffMins) + ' min';
			
			if (diffSecs >= 60 && units === 's') {
				setUnits('m');
			}

			if (timeRef && timeRef.current) {
				timeRef.current.innerHTML = diffToShow;
			}
		}, units === 's' ? 1000 : 60000);

		return () => {
			clearInterval(timer);
		};
	}, [units, timestamp]);

	return (
		message !== 'Agent Online' && message !== 'Issue Resolved' && (
			<div className={!isSameUser ? 'message ' + className : 'message ' + className + ' reversed'}>
				<div className={!isSameUser ? 'message ' + className: 'msg reversed'}>{message}</div>					
				<div className="time">
					{isSameUser && 'YOU | '} <div className={!isSameUser ? "time-user" : ""} ref={timeRef} />
				</div>
			</div>
		)
	);
}

ChatSupportMessage.defaultProps = {
	message: null,	
	pfid: '',
	playfabId: '',
	timestamp: 0,
};

ChatSupportMessage.propTypes = {
	message: PropTypes.string,
	timestamp: PropTypes.number,
	pfid: PropTypes.string,
	playfabId: PropTypes.string,
};

export default memo(ChatSupportMessage);
