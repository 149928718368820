import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userNameSelector, playerGroupSelector } from 'selector/player';
import Nav from 'mobile/components/Nav';
import { writePlayerEvent } from 'redux/playfab';
import { getCustomListingsByCategories } from 'selector/customListings';
import { statsSelector, statSelector } from 'selector/stats';
import { VPSTAT } from 'Constants';

function mapStateToProps(state, props) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions, 
		username: userNameSelector(state),
		acfOptions: state.wpcontent.acfOptions,
		activity: state?.chat?.channel,
		customListings: getCustomListingsByCategories(state),
		showrooms: state?.wppage.showrooms,
		playerStatistics: statsSelector(state, props),
		navigation: state?.wpcontent?.navigation,
		playerGroup: playerGroupSelector(state),
		isVP: statSelector(state, { statName: 'vp' }) === VPSTAT.VP,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		writePlayerEvent,
	}, dispatch)
)(Nav);
