import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import iconAvatar from "assets/icons/icon-avatar-leaderboard.svg";
import iconTopAvatar from "assets/icons/icon-avatar-top-leaderboard.svg";
import { ROUTES } from "Constants";
import { useHistory } from "react-router-dom";
import MainModal from "components/Modal";

function Leaderboard({
	playfabId,
	getLeaderboard,
	getLeaderboardOnPosition,
	fullScreen,
	generalLeaderboard,
	setFirstRender,
	acfPageOptions,
}) {
	const [showModal, setShowModal] = useState(true);
	const history = useHistory();

	useEffect(() => {
		getLeaderboard();
		getLeaderboardOnPosition();
	}, []);

	const renderImage = () => {
		return iconAvatar;
	};

	const getTopAvatar = () => {
		return iconTopAvatar;
	};

	const renderLeaderboardItemClasses = (id, isFullScreen) => {
		if (id === 1 && isFullScreen) {
			return "my-card-landscape";
		}

		if (id === 1 && !isFullScreen) {
			return "my-card";
		}
		return null;
	};

	const handleGoToBadgePageClick = () => {
		setShowModal(false);
		setFirstRender(true);

		return history.push(`${ROUTES.PROFILE_BADGES}`);
	};

	const renderAvatarLeaderboardClasses = (index, fullScreen) => {
		switch (true) {
			case index === 0 && fullScreen:
				return "avatar first-place-lanscape";

			case index === 0 && !fullScreen:
				return "avatar first-place";

			case index === 1:
				return "avatar second-place";

			case index === 2:
				return "avatar third-place";

			default:
				return "avatar";
		}
	};

	const renderTop3 = () => {
		const top3 = generalLeaderboard
			.slice()
			.sort((a, b) => {
				return a.statValue - b.statValue;
			})
			.slice(0, 3);

		return (
			<div className="leaderboard-tab top-entry-wrapper">
				{top3.map((users, idx) => {
					const isCurrent = playfabId === users.PlayFabId;

					return (
						<div
							className={`top-entry ${idx + 1} ${renderAvatarLeaderboardClasses(
								idx,
								fullScreen
							)}							
							 `}
							key={users.PlayFabId + "_" + users.Position}
						>
							<div className="top-group">
								<div
									className={
										idx === 0
											? "avatar first-place"
											: idx === 1
												? "avatar second-place"
												: idx === 2
													? "avatar third-place"
													: "avatar"
									}
								>
									<img
										className="user-avatar"
										width="100"
										height="100"
										src={getTopAvatar()}
										alt=""
									/>
								</div>
								<div className="level">
									<svg width="16" height="16" viewBox="0 0 40 40">
										<g id="Polygon_2" fill="url(#linear-gradient)">
											<circle
												stroke="#c4c4c4"
												strokeWidth="1"
												cx="20"
												cy="20"
												r="20"
												fill="white"
											/>
										</g>
									</svg>
									<div className="num">{idx + 1}</div>
								</div>
							</div>

							<div className="name">{users.DisplayName.toUpperCase()}</div>
							<div className="value">
								{users.StatValue} {acfPageOptions?.acf?.video_player?.pts_title}
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	const renderGeneralLeaderboard = () => {
		return generalLeaderboard?.map((users) => {
			const isCurrent = playfabId === users.PlayFabId;

			return (
				<div
					style={{
						display: "grid",
						gridGap: 5,
						gridTemplateColumns: "20px 80px 1fr 80px",
					}}
					key={users.PlayFabId}
					className={`${
						isCurrent ? "current-user" : ""
					} ${renderLeaderboardItemClasses(
						users.id,
						fullScreen
					)} && container my-3 leader-card ${fullScreen} ? container-landscape : ""} `}
				>
					<div>{users.Position + 1}</div>
					<div>
						<img
							className="leaderboard-avatar rounded-circle w-100"
							src={renderImage()}
							alt=""
						/>
					</div>
					<div
						className={`name-general-leaderboard  ${
							isCurrent ? "current-user" : ""
						} `}
					>
						{users.DisplayName}
					</div>
					<div className="text-uppercase">
						{users.StatValue} {acfPageOptions?.acf?.video_player?.pts_title}
					</div>
				</div>
			);
		});
	};

	return (
		<div className="profile_leaderboard-page">
			<div className="leaderboard-modal">
				<MainModal
					modal={showModal}
					setModal={setShowModal}
					onHide={() => setShowModal(false)}
					size="lg"
					aria-labelledby="contained-BootstrapModal-title-vcenter"
					centered
					className={` ${fullScreen ? "modal-content-landscape" : null}`}
					acfPageOptions={acfPageOptions}
				>
					<div className="leaderboard-container">
						{generalLeaderboard?.length > 0 && (
							<div className={fullScreen ? "leaderboard-landscape" : null}>
								<div
									className={fullScreen ? "top3-landscape" : "div-info-top-3"}
								>
									{renderTop3()}
								</div>

								<div>
									{renderGeneralLeaderboard()}
									{!fullScreen && (
										<div
											onClick={handleGoToBadgePageClick}
											className="leaderboard-info"
											role="button"
											tabIndex="-1"
											onKeyPress={handleGoToBadgePageClick}
										>
											<span>
												{
													acfPageOptions?.acf?.profile?.leaderboard_tab
														?.check_badges_button
												}
											</span>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</MainModal>
			</div>
		</div>
	);
}

Leaderboard.propTypes = {
	generalLeaderboard: PropTypes.array,
	getLeaderboard: PropTypes.func,
	playfabId: PropTypes.string,
	getLeaderboardOnPosition: PropTypes.func,
	fullScreen: PropTypes.bool,
	setFirstRender: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

Leaderboard.defaultProps = {
	generalLeaderboard: [],
	playfabId: "",
	getLeaderboard: () => {},
	getLeaderboardOnPosition: () => {},
	fullScreen: false,
	setFirstRender: () => {},
	acfPageOptions: null,
};

export default Leaderboard;
