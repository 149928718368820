import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PollResults from 'components/Activity/PollResults';
import { statsSelector } from 'selector/events';

function mapStateToProps(state, props) {
	return {
		stats: statsSelector(state, props),
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
	}, dispatch)
)(PollResults));
