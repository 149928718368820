import { createSlice, createAction } from '@reduxjs/toolkit';
import { APP_MODES, REACT_APP_DEFAULT_LANG } from 'Constants';
import { getParameterByName } from 'utils/urlUtils';

export const incrementErrors = createAction('env/error');

/**
* Refactor: this sets the app mode to single channel or multi channel, not sure if this is used anymore
*/

const env = createSlice({
	name: 'env',
	reducers: {
		setAppMode: (state, action) => {
			return {
				...state,
				app_mode: action.payload.app_mode,
			};
		},
	},
	extraReducers: {
		[incrementErrors]: (state) => {
			return {
				...state,
				errors: [
					...state.errors,
					Date.now(),
				],
			};
		},
	},
	initialState: {
		lang: getParameterByName('lang') || REACT_APP_DEFAULT_LANG,
		app_mode: APP_MODES.MULTI_CHANNEL,
		errors: [],
	},
});

export default env;

export const { setAppMode } = env.actions;
