import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

function ChatZeroState({ useTimer, liveChatPanel, icons }) {
	const [showZeroState, setShowZeroState] = useState(true);
	
	useEffect(() =>{
		if(useTimer) {
			setTimeout(() => {				
				setShowZeroState(false);
			}, 10000 *6 );
		}		
	})	

	return (
		<div className="chat-zero-state">
			<div className={`message-container ${showZeroState ? 'show-zero-state' : 'hide-zero-state'}`}>
				<div className="icon-container">
					<img src={icons?.live?.initial_chat_icon?.url} alt=""/>
				</div>
				<div className="text-container">
					<span className="text">{useTimer ? liveChatPanel?.join_chat_message : liveChatPanel?.initial_chat_message}</span>
				</div>				
			</div>
		</div>
	);
}

ChatZeroState.defaultProps = {
	useTimer: false,
	liveChatPanel: {},
	icons: {},
};

ChatZeroState.propTypes = {
	useTimer: PropTypes.bool,
	liveChatPanel: PropTypes.object,
	icons: PropTypes.object,
};


export default ChatZeroState;
