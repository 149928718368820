/**
 * @format
 */

import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";

import { Container } from "react-bootstrap";

import uuid from "react-uuid";
import logPageView from "utils/logPageView";
import parse from "html-react-parser";
import Layout from "mobile/components/layout/Title";

import ShowTermTitle from "../components/ShowTermTitle";
import ShowTermContent from "../components/ShowTermContent";
import ScrollToTop from "../components/buttons/BackToTop";

function Privacy({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	privacyData,
	fetchWPNoticesData,
	playerGroup,
	pageSlug,
}) {
	const [dataLoaded, setDataLoaded] = useState(false);
	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		};

		return fetchWPNoticesData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"notices",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	return (
		dataLoaded && (
			<Layout
				titleSlot={privacyData?.acf?.terms_of_use?.page_title}
				pageClass="notice"
				headerClass="my-0"
			>
				<div className="terms">
					<Container className="notices-anchors">
						<div className="terms-content">
							{parse(privacyData?.acf?.privacy_policy?.description)}
						</div>
						<div>
							<ul className="terms-list list-unstyled">
								{privacyData?.acf?.privacy_policy?.blocks &&
									privacyData.acf.privacy_policy.blocks.map(
										(termsTitles, i) => (
											<ShowTermTitle
												anchorLink={`#privacy_policy-${i}`}
												content={termsTitles}
											/>
										)
									)}
							</ul>
						</div>
					</Container>
					<Container>
						{privacyData?.acf?.privacy_policy?.blocks &&
							privacyData.acf.privacy_policy.blocks.map((termsTexts, i) => (
								<Fragment key={uuid()}>
									<ShowTermContent
										id={`privacy_policy-${i}`}
										content={termsTexts}
									/>
								</Fragment>
							))}
						<ScrollToTop />
					</Container>
				</div>
			</Layout>
		)
	);
}

Privacy.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	privacyData: null,
	pageSlug: null,
	fetchWPNoticesData: () => {},
	playerGroup: null,
};

Privacy.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	privacyData: PropTypes.object,
	fetchWPNoticesData: PropTypes.func,
	playerGroup: PropTypes.string,
	pageSlug: PropTypes.string,
};

export default Privacy;
