import { createSelector } from '@reduxjs/toolkit';

const leaderboardsStateSelector = (state) => state.leaderboard.list;
const activeLeaderboardStateSelector = (state) => state.leaderboard.active;

/**
* Return the active leaderboard
*/

export const leaderboardSelector = createSelector([
	leaderboardsStateSelector,
	activeLeaderboardStateSelector,
], (leaderboards, activeLeaderboard) => {
	return leaderboards[activeLeaderboard];
});
