import { createSelector } from "@reduxjs/toolkit";
import { departmentsStateSelector, regionsStateSelector } from "selector/wp";
import sortByKey from "utils/sortByKey";

const messageStateSelector = (state) => state.chat.messages;
const messageIdPropSelector = (state, props) => props.id;
const otherPlayerDataStateSelector = (state) => state.playfab?.OtherPlayerData || {};
const playfabIdPropsSelector = (state, props) => props.PlayFabId;
const questionStateSelector = (state) => state.chat.questions;

/**
 *Return specific message by ID
 */
export const messageSelector = createSelector([messageStateSelector, messageIdPropSelector], (messages, messageId) => {
	if (!messages || messages.length === 0) return null;

	return messages.find((m) => m.id === messageId);
});

/**
 *Return another player's data, useful for the playercard component or public profile
 */
export const otherPlayerDataSelector = createSelector(
	[otherPlayerDataStateSelector, playfabIdPropsSelector, regionsStateSelector, departmentsStateSelector],
	(otherPlayers, playfabId, regions, departments) => {
		let found = otherPlayers && otherPlayers[playfabId];

		if (found) {
			found = {
				...JSON.parse(JSON.stringify(found)),
			};

			if (found.data.Region) {
				found.data.Region = regions.find((x) => x.slug === found.data.Region)?.title?.rendered;
			}

			if (found.data.Department) {
				found.data.Department = departments.find((x) => x.slug === found.data.Department)?.title?.rendered;
			}
		}

		return found;
	}
);

/**
 *Return questions accepted / closed only
 */
export const questionsAcceptedSelector = createSelector([questionStateSelector], (questions) => {
	const filteredQuestions = questions.filter((question) => {
		if (!question) {
			return null;
		}
		return question?.status === "accepted" || question?.status === "closed";
	});

	return sortByKey(filteredQuestions, "createdAt");
});

export const chatMessageMentionsSelector = createSelector([messageStateSelector, questionsAcceptedSelector], (messages, questions) => {
	if ((!messages || messages.length === 0) && (!questions || questions.length === 0)) return null;
	const authors = [];
	messages.map((message) => {
		authors.push(message.author);
		return null;
	});
	questions.map((question) => {
		authors.push(question.author);
		return null;
	});
	const mentions = [...new Set(authors)];
	return mentions.sort();
});
