/** @format */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "mobile/components/modals";
import Button from 'mobile/components/buttons/Button';

function Confirmation({ text, onAnswer, modal, setModal, acfPageOptions }) {
	return (
		<Modal className="confirmation" modal={modal} setModal={setModal}>
			<div className="text px-4 text-center">
				<h4>{text}</h4>
			</div>
			<div className="d-flex d-flex justify-content-between answer">
				<Button
					action={() => onAnswer(true)}
					classes="primary medium"
					text={acfPageOptions?.acf?.global?.logout_buttons?.yes_button}
				/>
				<Button
					action={() => onAnswer(false)}
					classes="inverted medium"
					text={acfPageOptions?.acf?.global?.logout_buttons?.no_button}
				/>
			</div>
		</Modal>
	);
}

Confirmation.defaultProps = {
	text: "",
	onAnswer: () => {},
	modal: null,
	setModal: () => {},
	acfPageOptions: null,
};

Confirmation.propTypes = {
	text: PropTypes.string,
	onAnswer: PropTypes.func,
	modal: PropTypes.bool,
	setModal: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default Confirmation;
