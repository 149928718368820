import { memo, useEffect, useState } from 'react';
import { DEBUG } from 'Constants';
import logLocalStorage from 'utils/logLocalStorage';
import DEBUG_DATA from 'utils/VideoPlayerDebug';
import PropTypes from "prop-types";

function VideoPropertyWatcher({ player, method, granular, rule, pollRate, debug }) {
	const [lastValue, setLastValue] = useState(0);
	const key = `${method}${granular ? '_' + granular : ''}`;

	useEffect(() => {

		function watch() {
			if (player && player[method]) {
				let value = player[method]() || null;

				if (granular && value !== null) {
					value = value[granular];
				}

				if (value !== lastValue) {
					logLocalStorage(key, value);
					setLastValue(value);
				}

				DEBUG_DATA[key] = value;
			}
		}

		const watchInterval = setInterval(watch, pollRate);

		return () => {
			clearInterval(watchInterval);
		};
	}, [lastValue, granular, method, player, pollRate, debug, rule, key]);

	return null;
}

export default memo(VideoPropertyWatcher);

VideoPropertyWatcher.defaultProps = {
	player: null, //TODO: is it required?  (quick add to avoid breaking the compile... DNDAID)
	method: null, //TODO: is it required?  (quick add to avoid breaking the compile... DNDAID)
	granular: null, //TODO: is it required?  (quick add to avoid breaking the compile... DNDAID)
	rule: null, //TODO: is it required?  (quick add to avoid breaking the compile... DNDAID)
	pollRate: 500, 
	debug: DEBUG.RULES,
};

VideoPropertyWatcher.propTypes = {
	player: PropTypes.any, //TODO: don't use any (quick add to avoid breaking the compile... DNDAID)
	method: PropTypes.any, //TODO: don't use any (quick add to avoid breaking the compile... DNDAID)
	granular: PropTypes.any, //TODO: don't use any (quick add to avoid breaking the compile... DNDAID)
	rule: PropTypes.any, //TODO: don't use any (quick add to avoid breaking the compile... DNDAID)
	pollRate: PropTypes.number,
	debug: PropTypes.bool,
};