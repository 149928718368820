
import React  from 'react';
import PropTypes from 'prop-types';
import parse from "html-react-parser";
import { Link } from 'react-router-dom';
import { ROUTES } from 'Constants';


export default function CustomListingCard({
	slug,
	acf,
}) {
	return (
		<Link className="custom-listing-card" key={`${slug}`} to={ROUTES.CUSTOM_LISTING.replace(':listingSlug', slug)}>
			{acf?.photo && (
				<div className="image-wrap">
					<img src={acf?.photo?.sizes?.large} alt="" />
				</div>
			)}
			<div className="text-wrap">
				<h2 className="title">{acf?.title && parse(acf?.title)}</h2>
				<p className="subtitle">{acf?.subtitle}</p>
			</div>
		</Link>
	);
}

CustomListingCard.propTypes = {
	slug: PropTypes.string.isRequired,
	acf: PropTypes.object.isRequired,
};
