import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LeaderCard from 'components/Profile/LeaderCard';
import ProfilePicture from 'assets/placeholders/profile-picture.png';
import ContentOuterContainer from 'components/Profile/ContentOuterContainer';
import ContentInnerContainer from 'components/Profile/ContentInnerContainer';
//TODO: Don't use bootstrap like below, if you make custom components make them partials and not just for one component or page
import Row from 'components/Profile/Row';
import ColSm from 'components/Profile/ColSm';
import ColLG from 'components/Profile/ColLg';


function GeneralLeaderboard({
	generalLeaderboard,
	playfabId,
	leaderboardOnPosition,
	triggerMyLeaderBoard,
	triggerGeneralLeaderBoard,
	leaderBoardBool,
	history,
	acfPageOptions,
}) {

	const {leaderboard_option, points_label, points_short_label, position_option, rating, title} = acfPageOptions?.acf?.profile?.leaderboard_tab || {};
	const {first, second, third} = acfPageOptions?.acf?.theme?.color?.place || {};
	const {url: crownURL} = acfPageOptions?.acf?.theme?.icon?.profile?.first_place || {};

	return (
		<ContentOuterContainer>
			<Row>
				<ColSm>
					<ContentInnerContainer customClass="left-side">
						<div className="content-header">
							<div className="main-title">
								<h2>
									{title}
								</h2>
							</div>
						</div>
						{generalLeaderboard && generalLeaderboard.length > 0 && generalLeaderboard.map((val, idx) => {

							return (idx < 3 && (
								<div className="top-leaderboard" key={val.PlayFabId + '-top'}>
									<div className="profile-picture">
										<div className="avatar-ctn">
											{idx === 0
												? (
													<div className="crown">
														<img src={crownURL} alt="" />
													</div>
												) : null}

											<div className={idx === 0 ? 'avatar first-place' : idx === 1 ? 'avatar second-place' : idx === 2 ? 'avatar third-place' : 'avatar'}>
												<img src={val.Profile.AvatarUrl ? val.Profile.AvatarUrl : ProfilePicture} alt="" />
											</div>
											<div className="level">
												<svg width="40" height="40" viewBox="0 0 40 40">
													<g id="Polygon_2" fill="url(#linear-gradient)">
														<circle cx="20" cy="20" r="20" fill={idx === 0 ? first : idx === 1 ? second : idx === 2 ? third : null} />
													</g>
												</svg>
												<div className="num">{idx + 1}</div>
											</div>
										</div>
									</div>
									<div className="div-info-top-3">
										<div className={idx === 0 ? 'first-place' : idx === 1 ? 'second-place' : idx === 2 ? 'third-place' : null}>
											<h5>{ val?.DisplayName}</h5>
											{/* <h2>{restNames}</h2> */}
											<p className="points">
												{val.StatValue}
											</p>
											<p className="points-label">
												{points_short_label}
											</p>
										</div>
									</div>
								</div>
							));
						})}
					</ContentInnerContainer>
				</ColSm>
				<ColLG>
					<ContentInnerContainer customClass="left-side">
						<div className="full-leaderboard">
							<div className="content-header">
								<div className="title-menu">
									<div className="flex">
										<div className="main-title">
											<h2>
												{rating}
											</h2>
										</div>
										{leaderBoardBool === false && (
											<div role="button" tabIndex="-1" onClick={() => triggerMyLeaderBoard()} onKeyDown={() => triggerMyLeaderBoard()}>
												<p className="button-position">{position_option}</p>
											</div>
										)}
										{leaderBoardBool === true && (
											<div role="button" tabIndex="-1" onClick={() => triggerGeneralLeaderBoard()} onKeyDown={() => triggerGeneralLeaderBoard()}>
												<p className="button-position">{leaderboard_option}</p>
											</div>
										)}
									</div>
									<div className="main-title secondary-title">
										{points_label}
									</div>
								</div>
							</div>
							{generalLeaderboard && generalLeaderboard.length > 0 && leaderBoardBool === false && generalLeaderboard.map(user => {
								return <LeaderCard history={history} playfabId={playfabId} user={user} key={playfabId} />;
							})}
							{leaderboardOnPosition && leaderboardOnPosition.length > 0 && leaderBoardBool === true && leaderboardOnPosition.map(user => {
								return <LeaderCard history={history} playfabId={playfabId} user={user} key={playfabId + user.DisplayName} />;
							})}
						</div>
					</ContentInnerContainer>
				</ColLG>
			</Row>
		</ContentOuterContainer>
	);
}

GeneralLeaderboard.propTypes = {
	acfPageOptions:PropTypes.object,
	generalLeaderboard: PropTypes.array,
	playfabId: PropTypes.string,
	leaderboardOnPosition: PropTypes.array,
	triggerMyLeaderBoard: PropTypes.func,
	triggerGeneralLeaderBoard: PropTypes.func,
	leaderBoardBool: PropTypes.bool,
	history: PropTypes.object.isRequired,
};

GeneralLeaderboard.defaultProps = {
	acfPageOptions: null,
	generalLeaderboard: [],
	leaderboardOnPosition: [],
	playfabId: '',
	triggerMyLeaderBoard: () => { },
	triggerGeneralLeaderBoard: () => { },
	leaderBoardBool: false,
};

export default GeneralLeaderboard;
