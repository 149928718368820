/**
 * @format
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Trivia from './Trivia';

function Events({ events, sendEventInput, resetMission, contextId, playfabId, acfPageOptions }) {
	if (!events) return null;
	/**
	 * Return the current poll results with its stats
	 */
	
	return events ? (
		<Trivia
			timerColor={acfPageOptions?.acf?.theme?.color?.text?.secondary}
			timerOutlineColor={acfPageOptions?.acf?.theme?.color?.text?.primary}
			key={events.type.id}
			contextId={contextId}
			sendEventInput={sendEventInput}
			resetMission={resetMission}
			playfabId={playfabId}
			layout={events.data.layout}
			mission_id={events.mission_id}
			answers={events.data.answers}
			question={events.data.question}
			expiration={events.expiration}
			id={events.id}
			category={events?.mission?.data[0]?.dataVal}
		/>
	) : null;
}

Events.defaultProps = {
	acfPageOptions: null,
	sendEventInput: () => {},
	resetMission: () => {},
	events: null,
	contextId: '',
	playfabId: '',
};

Events.propTypes = {
	acfPageOptions: PropTypes.object,
	sendEventInput: PropTypes.func,
	resetMission: PropTypes.func,
	events: PropTypes.object,
	contextId: PropTypes.string,
	playfabId: PropTypes.string,
};

export default memo(Events);
