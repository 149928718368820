import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "react-bootstrap";
import { ReactComponent as LinkedIn } from "assets/icons/pack1/speakers/icon-linkedin.svg";
import { ReactComponent as Facebook } from "assets/icons/pack1/speakers/icon-facebook.svg";
import { ReactComponent as Twitter } from "assets/icons/pack1/speakers/icon-twitter.svg";
import { ReactComponent as Skype } from "assets/icons/pack1/speakers/icon-skype.svg";
import { ReactComponent as Slack } from "assets/icons/pack1/speakers/icon-slack.svg";
import { ReactComponent as Email } from "assets/icons/pack1/speakers/icon-message.svg";

export default function SocialLinks({ socials }) {
	if (!socials) {
		return null;
	}

	const [show, setShow] = useState(false);

	function toggleSocials() {
		setShow(!show);
	}

	const displayIcon = (icon) => {
		switch (icon) {
			case "linkedIn":
				return <LinkedIn />;
			case "twitter":
				return <Twitter />;
			case "email":
				return <Email />;
			case "facebook":
				return <Facebook />;
			case "skype":
				return <Skype />;
			case "slack":
				return <Slack />;
			default:
				return null;
		}
	};

	return (
		<div className="social-links px-0">
			<Container
				className="socials-container"
				onClick={() => toggleSocials()}
				onKeyDown={() => toggleSocials()}
				role="button"
				tabIndex="-1"
			>
				<Row className={`${show ? "show" : "hide"} expanded`}>
					{socials.map((s) => {
						return (
							<Col className="social" key={s.link} >
								<a
									href={s.link}
									target="_blank"
									rel="noreferrer"
									label="social link"
									className="px-0 py-0"
								>
									{displayIcon(s.type)}
								</a>
							</Col>
						);
					})}
				</Row>
				<div className={`socials-toggle ${!show ? "show" : "hide"} `}>
					<Email />
				</div>
				<div className={`social-close ${show ? "show" : "hide"} `}>x</div>
			</Container>
		</div>
	);
}

SocialLinks.defaultProps = {
	socials: null,
};

SocialLinks.propTypes = {
	socials: PropTypes.array,
};
