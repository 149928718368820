import React, { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

export const ComPanelContext = createContext();

export function ComPanelContextProvider({ children }) {
	const [inputMessage, setInputMessage] = useState("");
	const [activeCompanelTab, setActiveCompanelTab] = useState(null);
	const comPanelProviderValue = useMemo(
		() => ({
			inputMessage,
			activeCompanelTab,
			setInputMessage,
			setActiveCompanelTab,
		}),
		[activeCompanelTab]
	);

	return (
		<ComPanelContext.Provider value={comPanelProviderValue}>
			{children}
		</ComPanelContext.Provider>
	);
}

ComPanelContextProvider.defaultProps = {
	children: null,
};

ComPanelContextProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
