/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ContentOuterContainer from "components/Profile/ContentOuterContainer";
import Button from "components/Buttons/Button";
import ContactEmptyState from "components/Profile/ContactEmptyState";
import ContactListTable from "components/Profile/ContactListTable";
import ContactModalInvite from "components/Profile/ContactModalInvite";
import ContactModalSearch from "components/Profile/ContactModalSearch";
import ProfilePicture from 'assets/placeholders/profile-picture.png';
import 'bootstrap/dist/css/bootstrap.min.css';

import GroupImage from "assets/Group.png";

function ContactList({
	acfPageOptions,
	friendsList,
	friendRequests,
	sendFriendRequest,
	users,
	getUsers: getUsersRequest,
	acceptFriendRequest,
	declineFriendRequest,
	playfabId,
	getFriendsList,
}) {
	const getButton = (icon) => {
		return (
			<>
				<Button
					icon={icon?.image2?.url}
					classes="secondary large default"
				/>
				<Button
					icon={icon.image1?.url}
					classes="secondary large hover"
				/>
			</>
		);
	};

	const acfContactList = acfPageOptions?.acf?.profile?.contact_list_tab;
	const ADDED_STATUS = acfContactList?.status_added_label;
	const REQUESTED_STATUS = acfContactList?.status_pending_label;
	const INVITED_STATUS = acfContactList?.status_invite_label;

	const acfIcons = acfPageOptions?.acf?.theme?.icon?.profile;
	const viewProfile = acfContactList?.view_profile_modal;
	const [userSelected, setUserSelected] = useState(null);
	const [showSearchModal, setShowSearchModal] = useState(false);
	const [showInvitationModal, setShowInvitationModal] = useState(false);
	const isAdded = userSelected?.status === ADDED_STATUS || false;
	const [usersList, setUsersList] = useState(getUsers());
	const [usersSearchList, setUsersSearchList] = useState(usersList);
	const [invitationCounter, setInvitationCounter] = useState(0);
	const [invitationUsers, setInvitationUsers] = useState(friendRequests?.filter(
		(invitations) => invitations.To === playfabId
	));

	useEffect(async() => {
		if (!users) {
			await getUsersRequest();
		}
	});

	useEffect(() => {
		setUsersList(getUsers());

		if (usersSearchList?.length === 0) {
			setUsersSearchList(getUsers());
		}
	}, [users, friendsList, friendRequests]);

	function getText() {
		return (
			<div className="invitation-received">
				<span className="text">
					{acfContactList?.invitation_received_title}
				</span>
				<span className="counter">
					{invitationUsers?.length > 0
						? invitationUsers?.length.toString().padStart(2, "0")
						: 0}
				</span>
			</div>
		);
	}

	function getName(name, status) {
		return (
			<div className="table-name-user">
				<span className="name">{name}</span>
				{status !== INVITED_STATUS &&
				<div className="status-user">
					<>
						<span className={`dot-icon dot-icon-${status.toLowerCase()}`}>&nbsp;</span>
						<span className="status">{status}</span>
					</>
				</div>
				}
			</div>
		);
	}

	function validateIsPending(userPlayFab) {
		const isPending = friendRequests?.filter(invite => invite.To === userPlayFab);

		if (isPending?.length > 0) {
			return true;
		}

		return false;
	}

	/**
	 * TODO: Get the user by id with a api request
	 */
	 function getUserById(friend) {
		// TODO: Fix duplicated data from the API
		if (users) {
			return users.filter(user => user.playfabId === friend.FriendPlayFabId)[0]
		}

		return null;
	}


	function getUsers() {
		const parseUsers = [];

		if (friendsList && friendsList.length > 0) {
			friendsList.forEach((friendAdded) => {
				const user = getUserById(friendAdded);
				if (user) {
					const isFriend =
					friendsList?.find(
						(friend) => friend.FriendPlayFabId === user.playfabId
					) || false;
					const isRequest = validateIsPending(user.playfabId);
					let status = INVITED_STATUS;

					if (isFriend) {
						status = ADDED_STATUS;
					} else if (isRequest) {
						status = REQUESTED_STATUS;
					}

					parseUsers.push({
						...user,
						name: getName(user.displayName, status),
						// TODO: get location value from the API
						location: "Montreal, QC",
						// TODO: company value from the API
						company: "Company",
						// TODO: job value from the API
						job: "Job",
						action: getButton(acfIcons.view_contact),
						status,
						image: (
							<img
								className="profile-picture"
								src={user.avatar || ProfilePicture}
								alt="profile"
							/>
						),
						clickEvent: () => setUserSelected({ ...user, status }),
					});
				}
			});
		}

		return parseUsers;
	}

	return (
		<ContentOuterContainer>
			<div className="contact-list-container">
				<div className="left-panel">
					<div className="left-top-panel">
						<div className="my-contacts">
							<span>{acfContactList?.title}</span>
							<div className="contact-counter">{usersList.length}</div>
						</div>
						<div className="invitations">
							<Button
								action={() => setShowSearchModal(true)}
								icon={acfIcons?.magnifying_glass?.url}
								classes="search-input"
								text={acfContactList?.search_bar_placeholder}
							/>
							<Button
								action={() => {
									if (invitationUsers?.length > 0) {
										setShowInvitationModal(true);
									}
								}}
								icon={acfIcons?.view_contact?.image1?.url}
								classes="primary large"
								contentText={getText()}
								orientation="right"
							/>
						</div>
					</div>

					<ContactModalSearch
						acfContactList={acfContactList}
						acfIcons={acfIcons}
						invitationCounter={invitationCounter}
						friendRequests={friendRequests}
						usersList={usersList}
						showSearchModal={showSearchModal}
						setShowSearchModal={setShowSearchModal}
						setUsersSearchList={setUsersSearchList}
						usersSearchList={usersSearchList}
						setUserSelected={setUserSelected}
						sendFriendRequest={sendFriendRequest}
						getFriendsList={getFriendsList}
					/>

					<ContactModalInvite
						showInvitationModal={showInvitationModal}
						acfContactList={acfContactList}
						acfIcons={acfIcons}
						setShowInvitationModal={setShowInvitationModal}
						invitationUsers={invitationUsers}
						invitationCounter={invitationCounter}
						setInvitationCounter={setInvitationCounter}
						acceptFriendRequest={acceptFriendRequest}
						setInvitationUsers={setInvitationUsers}
						declineFriendRequest={declineFriendRequest}
						friendRequests={friendRequests}
						usersList={usersList}
					/>

					<div className="contact-list">
						<div className="status-tabs">
							<Button
								action={() => setShowSearchModal(true)}
								classes="secondary large"
								text="All"
							/>
							<Button
								action={() => setShowSearchModal(true)}
								classes="secondary large"
								text="Added"
							/>
							<Button
								action={() => setShowSearchModal(true)}
								classes="secondary large"
								text="Pending"
							/>
							<Button
								action={() => setShowSearchModal(true)}
								classes="secondary large"
								text="Ignored"
							/>
						</div>
						{usersList?.length > 0 ? (
							<ContactListTable
								usersList={usersList}
								acfContactList={acfContactList}
								acfIcons={acfIcons}
							/>
						) : (
							<ContactEmptyState acfPageOptions={acfPageOptions} />
						)}
					</div>
				</div>
				<div className={`right-panel${!userSelected ? "-empty" : ""}`}>
					{userSelected ? (
						<div className="profile-images-container">
							<img
								className="close-right-panel"
								src={acfPageOptions?.acf?.theme?.icon?.general?.exit?.image1?.url}
								alt="exit icon"
								role="presentation"
								onClick={() => setUserSelected(null)}
							/>
							{isAdded ? (
								<img
									className="profile-background-picture"
									src={userSelected.avatar || ProfilePicture}
									alt="profile background"
								/>
							) : (
								<div className="profile-empty-background" />
							)}
							<div className="profile-container">
								<div className="profile-content">
									{isAdded ? (
										<img
											className="profile-right-picture"
											src={userSelected.avatar || ProfilePicture}
											alt="profile"
										/>
									) : (
										<div className="profile-right-picture letter-image">
											<h2 className="profile-letter-name">
												{userSelected?.displayName?.charAt(0).toUpperCase()}
											</h2>
										</div>
									)}
									<h4 className="name">
										{userSelected?.displayName.length < 18
											? userSelected?.displayName
											: `${userSelected?.displayName.slice(0, 13)}...`}
									</h4>
									<span className="location">Montreal, Quebec</span>
								</div>
							</div>
							<div className="profile-information">
								<h6 className="title">
									{viewProfile?.profile_information_title}
								</h6>
								{viewProfile?.inputs?.length > 0 &&
										viewProfile.inputs.map((input) => {
											return (
												<div className="profile-info-container">
													<div className="profile-info-label">
														{input.label}
													</div>
													<div className="profile-info-content">
														Lorem ipsum
													</div>
												</div>
											);
										})}
								<div className="contact-info-container">
									<h6 className="contact-info">
										{viewProfile?.contact_information_title}
									</h6>
									<div
										className={`profile-info-container ${
											isAdded ? "" : "blur-filter"
										}`}
									>
										<div className="profile-info-label">Email address</div>
										<div className="profile-info-content">
											{isAdded
												? "liannelahavas@genetec.com"
												: "Lorem ipsum dolor sit amet"}
										</div>
									</div>
									<div
										className={`profile-info-container ${
											isAdded ? "" : "blur-filter"
										}`}
									>
										<div className="profile-info-label">Phone Number</div>
										<div className="profile-info-content">
											{isAdded
												? "+1 514690059"
												: "Lorem ipsum dolor sit amet"}
										</div>
									</div>
									{!isAdded && (
										<Button
											action={async() => {
												await sendFriendRequest({
													FriendId: userSelected?.playfabId,
													TokenItemId: acfContactList?.friendship_token,
												});
												getFriendsList(playfabId);
											}}
											classes="primary medium invitation-button"
											text={viewProfile?.send_invitation_button}
											orientation="right"
										/>
									)}
								</div>
							</div>
						</div>
					) : (
						<img src={GroupImage} alt="group" />
					)}
				</div>
			</div>
		</ContentOuterContainer>
	);
}

ContactList.propTypes = {
	acfPageOptions: PropTypes.object,
	friendsList: PropTypes.array,
	friendRequests: PropTypes.array,
	sendFriendRequest: PropTypes.func,
	users: PropTypes.array,
	getUsers: PropTypes.func,
	acceptFriendRequest: PropTypes.func,
	declineFriendRequest: PropTypes.func,
	playfabId: PropTypes.string,
	getFriendsList: PropTypes.func,
};

ContactList.defaultProps = {
	acfPageOptions: null,
	friendsList: null,
	friendRequests: null,
	sendFriendRequest: () => {},
	users: [],
	getUsers: () => {},
	acceptFriendRequest: () => {},
	declineFriendRequest: () => {},
	playfabId: "",
	getFriendsList: () => {},
};

export default ContactList;
