import { createSelector } from '@reduxjs/toolkit';

const loadingSelector = (state) => state.loading;

/**
* Return the loading state
*/

export const isLoadingSelector = createSelector([
	loadingSelector,
], (loading) => {
	return Boolean(Object.values(loading).find(x => x));
});
