/** @format */

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { EXCLUDED_LOGGER_ACTIONS } from "Constants";
import tracker from "middleware/tracker";
import { rootReducer } from './redux';

const middleware = [
	...getDefaultMiddleware({
		immutableCheck: false,
		serializableStateInvariant: false,
	}),
];

function filterActions(getState, action) {
	if (EXCLUDED_LOGGER_ACTIONS.find((x) => action.type.indexOf(x) >= 0))
		return false;
	return true;
}

if (process.env.NODE_ENV === "development") {
	middleware.push(
		createLogger({ collapsed: true, predicate: filterActions })
	);
}

middleware.push(tracker);

const store = configureStore({
	middleware,
	reducer: rootReducer,
});

export default store;
