import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Accordion } from "react-bootstrap";
import LoadingGif from "components/LoadingGif";
import { ROUTES } from "Constants";
import {
	now,
	timeFromNow,
	getDayTwoDigits,
	getTimeStyleShort,
	getWeekdayLong,
	getMonthLong,
	getWeekdayShort,
	getMonthShort,
} from "utils/dateUtils";
import logPageView from "utils/logPageView";
import { ReactComponent as InPersonIcon } from "assets/icons/pack1/agenda/attendance-type/icon-in-person.svg";
import { ReactComponent as PrivateIcon } from "assets/icons/pack1/agenda/attendance-type/icon-private.svg";
import { ReactComponent as PublicIcon } from "assets/icons/pack1/agenda/attendance-type/icon-public.svg";
import { ReactComponent as VirtualIcon } from "assets/icons/pack1/agenda/attendance-type/icon-virtual.svg";
import { ReactComponent as LocationStrokeIcon } from "assets/icons/pack1/session/icon-location-stroke.svg";
import { ReactComponent as AttendanceIcon } from "assets/icons/pack1/session/icon-attendance-type.svg";
import { ReactComponent as EventDescriptionIcon } from "assets/icons/pack1/session/icon-event-description.svg";
import { ReactComponent as SpeakersIcon } from "assets/icons/pack1/session/icon-speakers.svg";
import {ReactComponent as IconVideo} from "assets/icons/pack1/gallery/icon-gallery-video.svg";
import AttendanceModal from "mobile/components/session/AttendanceModal";
import Speaker from "mobile/components/speaker/Speaker";
import Button from "mobile/components/buttons/Button";
import BackButton from "mobile/components/buttons/BackButton";

function SessionDetails({
	id,
	isPage,
	stock,
	activity,
	acfPageOptions,
	sessionDetailsFullSceen,
	setSessionDetailsFullSceen,
	purchaseTile,
	writePlayerEvent,
	writeTelemetryEvent,
	getItemInventory,
	getStoreLoadout,
	groupLang,
	isAdmin,
	attendanceType,
	updatePlayerData,
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
}) {
	if(!activity) return null;

	const ref = useRef();

	const history = useHistory();

	const settings = {
		centerMode: false,
		infinite: false,
		slidesToShow: 1,
		speed: 500,
		initialSlide: 0,
		dots: false,
		arrows: false,
		slidesToScroll: 1,
		swipeToSlide: true,
		variableWidth: true,
	};

	const startString = activity.date_begin.isBefore(now())
		? acfPageOptions?.acf?.activity_card_started
		: acfPageOptions?.acf?.activity_card_starts;
	const endString = activity.date_end.isAfter(now())
		? acfPageOptions?.acf?.activity_card_ends
		: acfPageOptions?.acf?.activity_card_ended;
	const activityType = activity.data.activity_type;
	const dayNum = getDayTwoDigits(activity.date_begin.$d, groupLang);
	const dayString = getWeekdayLong(activity.date_begin.$d, groupLang);
	const dayShortString = getWeekdayShort(activity.date_begin.$d, groupLang);
	const monthString = getMonthLong(activity.date_begin.$d, groupLang);
	const monthStringString = getMonthShort(activity.date_begin.$d, groupLang);
	const fullTimeStart = getTimeStyleShort(activity.date_begin.$d, "en-US");
	const fullTimeEnd = getTimeStyleShort(activity.date_end.$d, "en-US");
	const isPast = activity.date_end.isBefore(now());
	const isFuture = activity.date_begin.isAfter(now());
	const isNow = now().isBetween(activity.date_begin, activity.date_end);

	const [loading, setLoading] = useState(false);

	const [attendanceModal, setAttendanceModal] = useState(false);

	const [checkedAttendanceType, setCheckedAttendanceType] = useState(
		attendanceType?.Value
	);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"session-details",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	function unregister() {
		setLoading(true);
		writePlayerEvent({
			name: "player_unsubscribed_activity",
			body: {
				ItemInstanceId: activity.item_instance_id,
			},
		}).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 2000),

			setTimeout(() => {
				getItemInventory();
			}, 2300),

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "remove from agenda",
				eventAction: activity?.content?.title?.rendered,
			})
		);
	}

	function purchase() {
		setLoading(true);
		purchaseTile(id).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 500),

			setTimeout(() => {
				getItemInventory();
			}, 1000),

			//Engagement event on arriving
			writeTelemetryEvent({
				namespace: "custom.engagement",
				name: "subscribe",
				body: {
					activity: activity?.content?.title?.rendered,
					StoreTileId: activity.StoreTileId,
				},
			}),

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "add from agenda",
				eventAction: activity?.content?.title?.rendered,
			}),
		);
	}

	function requiredCheck(activity_id) {
		let isRequired = false;
		const granted_items = acfPageOptions?.acf?.global?.granted_items;
		if (acfPageOptions?.acf?.global?.granted_items) {
			for (let i = 0; i < granted_items?.length; i += 1) {
				if (granted_items[i].tile_id === activity_id.StoreTileId) {
					isRequired = true;
					break;
				}
			}
		}
		return isRequired;
	}

	function goToLink(url) {
		const urlCleaned = url.replaceAll(" ", "").replaceAll(" ", "");
		return window.open(urlCleaned, '_blank');
	}

	useEffect(() => {
		if (loading) {
			return setLoading(false);
		}
		return null;
	}, [activity.canPurchase, activity.canUnregister]);

	function handleSessionDetailsFullScreen() {
		setSessionDetailsFullSceen(!sessionDetailsFullSceen);
	}

	function triggerClickEvent() {
		setAttendanceModal(true);
	}

	function renderStock() {
		if (stock === null && activity.stock === null) return null;

		return stock > 0 ? (
			<div className="stock">{acfPageOptions?.acf?.event?.limited_stock}</div>
		) : (
			<div className="stock bold">{acfPageOptions?.acf?.event?.fully_booked}</div>
		);
	}

	function renderStockRelatedButtons(type) {
		if (isPast) return null;

		return (
			<div className="actions-wrapper">
				{!activity.canJoin && !requiredCheck(activity) && (
					<div id="activity-buttons" className="event-add-btn">
						{activity.canPurchase && !requiredCheck(activity) && (
							<Button
								action={() => purchase()}
								classes="secondary medium"
								text={acfPageOptions?.acf?.activity_card_add_to_agenda}
							/>
						)}
						{activity.canUnregister && !requiredCheck(activity) && (
							<Button
								action={() => unregister()}
								classes={`inverted ${type}`}
								text={acfPageOptions?.acf?.activity_card_remove_from_agenda}
							/>
						)}
					</div>
				)}

				{(activity.canJoin || isAdmin) && (
					<Button
						action={() =>
							triggerClickEvent()
						}
						classes="primary medium"
						text={acfPageOptions?.acf?.activity_card_join_now}
					/>
				)}
			</div>
		);
	}

	function renderPartners() {
		if (!activity.content?.acf?.host) return null;
		return <div className="hostedby text-white">{parse(activity.content.acf.host)}</div>;
	}

	function renderDate() {
		return (
			<div className="session-date">
				<div className="day">
					<h4 className="dayNum">{dayNum}</h4>
					<div className="day-month-container">
						<span className="dayWeek">{dayShortString.toUpperCase()}</span>
						<span className="monthString">{monthStringString.toUpperCase()}</span>
					</div>
				</div>
				<div className="time align-items-end">
					<h4><span>{acfPageOptions?.acf?.event?.date?.from}</span> {fullTimeStart}</h4>
					<h4><span>{acfPageOptions?.acf?.event?.date?.to}</span> {fullTimeEnd}</h4>
				</div>
			</div>
		);
	}

	function renderTime() {
		if (isFuture) return <div className="time-left"><p>{acfPageOptions?.acf?.event?.time?.in} {`${timeFromNow(activity.date_end)}`}!</p></div>;
		if (isNow) return <div className="time-left"><p>{acfPageOptions?.acf?.event?.time?.now}</p></div>;
		return null;
	}

	function renderTopic() {
		if (activity?.content?.acf?.session_topic) return <div className={`topic ${activity?.content?.acf?.session_topic?.topic}`}>{activity?.content?.acf?.session_topic?.topic_label}</div>;
		return null;
	}

	function renderTypeMessage(){
		if(activity?.content?.acf?.event_type === 'Live' && activity.canJoin){
			return <div className="session-type"><VirtualIcon /> <p>{acfPageOptions?.acf?.event?.session_type?.live_session_happening_now}</p></div>
		}
		if(activity?.content?.acf?.event_type === 'Live' && !activity.canJoin) {
			return <div className="session-type"><VirtualIcon /> <p>{acfPageOptions?.acf?.event?.session_type?.live_session}</p></div>
		}
		if(activity?.content?.acf?.event_type === 'PrivateTeamsRoom' && activity.canJoin){
			return <div className="session-type"><PrivateIcon /> <p>{acfPageOptions?.acf?.event?.session_type?.private_teams_happening_now}</p></div>
		}
		if (activity?.content?.acf?.event_type === 'PrivateTeamsRoom' && !activity.canJoin) {
			return <div className="session-type"><PrivateIcon /> <p>{acfPageOptions?.acf?.event?.session_type?.private_teams}</p></div>
		}

		return null
	}

	function changeAttendanceType(type) {
		setCheckedAttendanceType(type);
	}

	function openInNewTab(link){
		const urlCleaned = link.replaceAll(" ", "").replaceAll(" ", "");
		return window.open(urlCleaned)
	}

	return <div>
		<div className={`session-details ${isPage ? "-page" : ""}`}>
			{isPage ? (
				<>
					<BackButton />
					{activity?.content?.acf?.event_image && (
						<div className="event-image-ctn">
							{!activity?.content?.acf?.video_on_demand && (
								<img src={activity?.content?.acf?.event_image.url} alt="" />
							)}
							{activity?.content?.acf?.video_on_demand?.acf?.video_link && (
								<div className="video-ctn" onClick={() => openInNewTab(activity.content.acf.video_on_demand.acf.video_link)} onKeyDown={() => openInNewTab(activity.content.acf.video_on_demand.acf.video_link)} role="button" aria-label="openInNewTabMobile" tabIndex="-1">
									<img src={activity?.content?.acf?.event_image.url} alt="" />
									<IconVideo />
								</div>
							)}
						</div>
					)}
				</>
			) : (
				<button
					className="companel-fullscreen"
					aria-label="companel fullscreen"
					type="button"
					onClick={() => handleSessionDetailsFullScreen()}
					onKeyDown={() => handleSessionDetailsFullScreen()}
				/>
			)}

			<div className="session-content">
				<div className="topic-wrap">
					{renderTopic()}
					{renderTypeMessage()}
				</div>

				<h4 className="tile-title text-white">
					{activity?.content?.title?.rendered && parse(activity?.content?.title?.rendered)}
				</h4>

				<div className="date-wrap">
					{renderTime()}
					{renderDate()}
				</div>

				<div className="actions mt-4 mb-3">
					{renderStock()}

					{isPage && (
						loading ? (
							<div className="loading-text">
								<span>{acfPageOptions?.acf?.activity_card_loading}</span>
								<LoadingGif
									acfPageOptions={acfPageOptions}
									status={loading ? "active" : ""}
									cardLoader={loading ? "card-loader" : ""}
								/>
							</div>
						) : (
							<div id="activity-buttons" className="event-add-btn">
								{renderStockRelatedButtons('medium')}
							</div>
						)
					)}
				</div>
			</div>

			<Accordion alwaysOpen>
				{activity?.content?.acf?.location && (
					<Accordion.Item eventKey="0">
						<Accordion.Header><LocationStrokeIcon /> {acfPageOptions?.acf?.event?.location?.title}</Accordion.Header>
						<Accordion.Body>
							<div className="location">
								{activity?.content?.acf?.location.text && parse(activity?.content?.acf?.location?.text)}
							</div>

							{activity?.content?.acf?.location.directions && (
								<Button
									action={() => goToLink(activity?.content?.acf?.location?.directions)}
									classes="secondary small"
									text={acfPageOptions?.acf?.event?.location?.button}
								/>
							)}
						</Accordion.Body>
					</Accordion.Item>
				)}
				{activity?.content?.acf?.attendance_type && (
					<Accordion.Item eventKey="1">
						<Accordion.Header><AttendanceIcon /> {acfPageOptions?.acf?.event?.attendance?.title}</Accordion.Header>
						<Accordion.Body>
							{!localStorage.getItem("hasDefaultAttendanceType") &&
								activity.content.acf.attendance_type.map((attendance) => {
									return (
										<div key={attendance.label} className="attendance-ctn">
											<div className="attentendance-type">
												{attendance.value === 'virtual' ? (
													<div className="icon">
														<VirtualIcon />
													</div>
												) : (
													<div className="icon">
														<InPersonIcon />
													</div>
												)}
												<div className="label">{attendance.label}</div>
											</div>
										</div>
									);
								})
							}
							{localStorage.getItem("hasDefaultAttendanceType") &&
								activity?.content?.acf?.attendance_type.length < 2 &&
								activity.content.acf.attendance_type.map((attendance) => {
									return (
										<div key={attendance.label} className="attendance-ctn">
											<div className="attentendance-type">
												{attendance.value === 'virtual' ? (
													<div className="icon">
														<VirtualIcon />
													</div>
												) : (
													<div className="icon">
														<InPersonIcon />
													</div>
												)}
												<div className="label">{attendance.label}</div>
											</div>
										</div>
									);
								})
							}
							{localStorage.getItem("hasDefaultAttendanceType") &&
								activity?.content?.acf?.attendance_type.length >= 2 && (
								<div className="attendance-ctn -icons">
									<div className="attentendance-type -active">
										{checkedAttendanceType === 'virtual' ? (
											<div className="icon">
												<VirtualIcon />
											</div>
										) : (
											<div className="icon">
												<InPersonIcon />
											</div>
										)}

										<div className="label">{checkedAttendanceType}</div>
									</div>

									<div className="selection-block mt-4">
										{checkedAttendanceType === 'virtual' ? (
											<>
												<div className="text-ctn mb-3">
													<p>{acfPageOptions?.acf?.event?.attendance?.virtual_title}</p>
												</div>
												<div className="img-ctn">
													<img src={acfPageOptions?.acf?.event?.attendance_type_icon?.virtual?.url} alt="" />
												</div>
												<div className="btn-attendance-ctn">
													<Button
														action={() =>
															changeAttendanceType('person')
														}
														classes="inverted small"
														text={acfPageOptions?.acf?.event?.attendance?.button}
													/>
												</div>
											</>
										) : (
											<>
												<div className="text-ctn mb-3">
													<p>{acfPageOptions?.acf?.event?.attendance?.in_person_title}</p>
												</div>
												<div className="img-ctn">
													<img src={acfPageOptions?.acf?.event?.attendance_type_icon?.in_person?.url} alt="" />
												</div>
												<div className="btn-attendance-ctn">
													<Button
														action={() =>
															changeAttendanceType('virtual')
														}
														classes="inverted small"
														text={acfPageOptions?.acf?.event?.attendance?.button}
													/>
												</div>
											</>
										)}

									</div>
								</div>
							)}
						</Accordion.Body>
					</Accordion.Item>
				)}
				<Accordion.Item eventKey="2">
					<Accordion.Header><EventDescriptionIcon /> {acfPageOptions?.acf?.event?.event_description?.title}</Accordion.Header>
					<Accordion.Body>
						{renderPartners()}

						<div className="text-ctn text-white">
							{activity?.content?.content?.rendered && parse(activity?.content?.content?.rendered)}
						</div>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="3" className="speaker-accordion">
					<Accordion.Header><SpeakersIcon /> {acfPageOptions?.acf?.event?.speakers?.title}</Accordion.Header>
					<Accordion.Body>
						<div className="speakers-wrapper">
							<Swiper
								breakpoints={{
									375: {
										width: 345,
										slidesPerView: 2.5,
									},
									667: {
										width: 575,
										slidesPerView: 4.5,
									},
								}}
								className="myDetailsSwiper"
							>
								{activity?.speakers.map((child) => {
									return (
										<SwiperSlide key={child.id}>
											<Link to={ROUTES.SPEAKER.replace(":speakerId", child.slug)}>
												<Speaker
													key={`speaker-${child.id}`}
													thumbnail={child?.acf?.photo?.sizes?.large}
													type={child?.acf.type}
													name={child?.title?.rendered}
													jobTitle={child?.acf?.subtitle}
												/>
											</Link>
										</SwiperSlide>
									);
								})}
							</Swiper>
						</div>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>

		</div>
		<AttendanceModal acfPageOptions={acfPageOptions} modal={attendanceModal} setModal={setAttendanceModal} history={history} activity={activity}/>
	</div>;
}
SessionDetails.defaultProps = {
	id: "",
	isPage: false,
	stock: null,
	activity: {},
	acfPageOptions: null,
	sessionDetailsFullSceen: false,
	setSessionDetailsFullSceen: () => {},
	purchaseTile: () => {},
	writePlayerEvent: () => {},
	writeTelemetryEvent: () => {},
	getStoreLoadout: () => {},
	groupLang: null,
	isAdmin: false,
	updatePlayerData: () => {},
	attendanceType: null,
	getItemInventory: () => {},
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
};
SessionDetails.propTypes = {
	id: PropTypes.string,
	isPage: PropTypes.bool,
	stock: PropTypes.number,
	activity: PropTypes.object,
	acfPageOptions: PropTypes.object,
	sessionDetailsFullSceen: PropTypes.bool,
	setSessionDetailsFullSceen: PropTypes.func,
	purchaseTile: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	getStoreLoadout: PropTypes.func,
	getItemInventory: PropTypes.func,
	isAdmin: PropTypes.bool,
	groupLang: PropTypes.string,
	updatePlayerData: PropTypes.func,
	attendanceType: PropTypes.object,
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
};

export default SessionDetails;
