import { createSelector } from '@reduxjs/toolkit';

const randomRoomStateSelector = (state) => state.rooms?.List;
// const randomRoomIdStateSelector = (state) => state.rooms?.activeRoom;
const playfabIdStateSelector = (state) => state.playfab?.PlayFabId;
const roomIdFromRouteSelector = (state, props) => props.match?.params?.roomId;

/**
*Return a specific room
*/
export const randomRoomSingleSelector = createSelector([
	randomRoomStateSelector,
	roomIdFromRouteSelector,
], (rooms, roomId) => {
	if (!rooms || !roomId) return null;
	const foundRoom = rooms.find(room => room?.roomId === roomId);
	if (foundRoom) {
		return foundRoom;
	}
	return 'noFoundRoom';
});

/**
*Return if a player has access to a room
*/
export const randomRoomAllowSelector = createSelector([
	randomRoomSingleSelector,
	playfabIdStateSelector,
], (requestedRoom, playfabId) => {

	if (!requestedRoom || requestedRoom === 'noFoundRoom') return false;
	if (requestedRoom.status === 'open') return true;

	const participants = requestedRoom?.participants?.featured;
	const foundParticipant = participants.find(participant => participant?.playfabId === playfabId);

	if (foundParticipant) return true;
	return false;
});
