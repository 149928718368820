import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ThankYou from 'components/Pages/ThankYou';
import { writePlayerEvent } from 'redux/playfab';

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		writePlayerEvent,
	}, dispatch)
)(ThankYou);
