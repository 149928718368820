import { createSelector } from "@reduxjs/toolkit";

export const inventorySelector = (state) => state.playfab?.Inventory;
const itemIdSelector = (state, props) => props.itemId;
const langSelector = (state) => state.env.lang;
const seenNotificationsSelector = (state) => state.inventory.seen_notifications;
/**
 * Return the item passed in props
 */

export const itemInventorySelector = createSelector(
	[inventorySelector, itemIdSelector],
	(inventory, itemId) => {
		const inv = inventory || [];

		return inv.find((item) => item.itemId === itemId) || null;
	}
);

/**
 * Parse the notification (turn the notif object {datakey, dataval} into a flat object)
 */

function parseNotif(notif, lang) {
	const data = notif.data.reduce((c, d) => {
		let key = d.dataKey.toLowerCase().trim().replace(" ", "_");

		const lngIndex = key.indexOf("_" + lang);
		if (lngIndex >= 0) {
			key = key.substring(0, lngIndex);
		}

		c[key] = d.dataVal;
		return c;
	}, {});

	return {
		id: notif.playfab.ItemInstanceId,
		title: data["title"],
		content: data["content"],
		cta: data["cta"],
		icon: data["custom_icon"],
		is_push_to_playstream: data["push_to_playstream"] === 1,
		is_consume_on_click: data["consume_on_click"] === 1,
		target_url: data["target_url"],
		firstName: data["first_name"],
		lastName: data["last_name"],
		post_type: notif.playfab.Tags?.[0],
		link_to_room: notif.playfab?.InstanceData?.roomId,
		type: data["call_to action"],
	};
}

export const PRIORITIES = ["Low", "Normal", "High", "Takeover"];

/**
 * Find the notification in the inventory
 */

export const notificationSelector = createSelector(
	[inventorySelector, seenNotificationsSelector, langSelector],
	(inventory, seenNotifications, lang) => {
		const inv =
			(inventory &&
				inventory.filter((item) => {
					return ![...seenNotifications]
						.sort((a, b) => {
							return PRIORITIES.indexOf(a) - PRIORITIES.indexOf(b);
						})
						.find((x) => {
							return x === item.playfab.ItemInstanceId;
						});
				})) ||
			[];

		const notif = inv.find((item) => item.type.title === "Notifications");


		return notif ? parseNotif(notif, lang) : null;
	
	}
);
