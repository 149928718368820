import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

function InputField({ name, value, onBlur, disabled }) {
	const [val, setVal] = useState(value || "");

	useEffect(() => {
		setVal(value);
	}, [value]);

	const slug = name.toLowerCase().replace(" ", "_");
	const ref = useRef();

	function onChange(e) {
		setVal(e.target.value);
	}

	function onInputBlur(e) {
		if (value !== e.target.value) {
			onBlur(e.target.value);
		}
	}

	return (
		<div className="inputfield-group py-3">
			<div className="input-wrapper">
				<label htmlFor={slug}>{name}</label>
				<input
					ref={ref}
					id={slug}
					name={slug}
					type="text"
					onChange={(e) => onChange(e)}
					onBlur={(e) => onInputBlur(e)}
					value={val}
					disabled={disabled}
					maxLength="35"
					className={value !=="" ? `filled` : ""}
				/>
			</div>
		</div>
	);
}

InputField.defaultProps = {
	disabled: false,
};

InputField.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onBlur: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default InputField;
