/* eslint-disable default-param-last */
const objectDefaults = {
	input: {
		object: 'player',
		property: '',
	},
	compareTo: [
		{
			object: 'player',
			property: '',
			operator: '===',
			value: null,
		},
	],
};

function Rule(input, compareToParams) {
	this.input = input;
	this.compareTo = compareTo;
	this.evaluate = () => {
		const value = getValue(input);
		const res = compareTo(value, compareToParams);

		return res;
	};
}

function getValue(prop) {
	const { object, property } = prop;

	const parts = property.split('.');
	
	return parts.reduce((c, v) => {
		if (v.indexOf('()') >= 0) {
			return c[v.replace('()', '')]();
		}

		if (!c) return null;
		return c[v];
		
	}, object);
}

function compareTo(value, params) {
	const res = params.map(p => {
		const compareValue = p.value === undefined ? getValue(p) : p.value;

		switch (p.operator) {
			case '===': return value === compareValue;
			case '<=': return value <= compareValue;
			case '>=': return value >= compareValue;
			case '<': return value < compareValue;
			case '>': return value > compareValue;
			default: return true;
		}
	});
	
	return res.filter(x => x === false).length === 0;
}

function parseInput(input, objects) {
	const object = objects[input.object];

	return {
		object,
		property: input.property,
	};
}

Rule.fromObject = (params = objectDefaults, objects) => {
	const input = parseInput(params.input, objects);
	return new Rule(input, params.compareTo.map(x => {
		return {
			...x,
			...parseInput(x, objects),
		};
	}));
};

export default Rule;
