import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import first from 'utils/first';
import parseItemData from 'utils/parseItemData';

export const eventsStateSelector = (state) => state.playfab.Events;
const currentObjectivesSelector = (state) => state.events.current_objectives;
const statsStateSelector = (state) => state.playfab.AppStats;
const currentPollResultsSelector = (state) => state.playfab.current_poll_results;
const currentLangSelector = (state) => state.env.lang;

/**
* Select the first event that matches the current objectives (only take it if it's not expiraed or completed) and build a new event object:
* - mission: event
* - objective: mission
* - expiration: expiration date
* - mission
*/

export const eventsSelector = createSelector([
	eventsStateSelector,
	currentObjectivesSelector,
	currentLangSelector,
], (events, currentObjectives, lang = 'en') => {
	if (!events || !currentObjectives) return null;

	const now = dayjs().valueOf();
	// event can contain multiple events if they all match the current objective
	const event = events.reduce((carry, evt) => {
		if (evt && Object.prototype.hasOwnProperty.call(evt, "objectives")) {
			const e = evt?.objectives.find((obj) => {
				const cur = currentObjectives[obj.id];
				return cur && cur.expiration > now && !cur.completed;
			});

			if (e) {
				carry.push({
					mission: evt,
					objective: e,
					expiration: currentObjectives[e.id].expiration,
				});
			}
		}

		return carry;
	}, []);

	//make sure to only take the first event
	const evt = first(event.map(e => {
		return {
			...e,
			...parseItemData(e.objective, lang),
			mission_id: e.mission.itemId,
		};
	}));

	return evt;
});

/**
* Return the current poll results with its stats
*/

export const statsSelector = createSelector([
	statsStateSelector,
	currentPollResultsSelector,
], (stats = [], currentPollResults = []) => {
	return currentPollResults.map((result) => {
		const found = result.statNames.map(stat => {
			let foundStat = stats.find(s => s.statName === stat.statName);
			
			if (foundStat) {
				foundStat = {
					...stat,
					...foundStat,
				};
			}
			return foundStat;
		}).filter(x => x);

		if (found.length === 0) return null;

		return {
			...result,
			stats: found,
		};
	}).filter(x => x);
});
