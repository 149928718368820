import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminTool from 'components/Partials/AdminTool';
import {
	updatePlayerData, updatePlayerStatistics, writePlayerEvent,
} from 'redux/playfab';
import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		acfPageOptions: state.wpcontent.acfPageOptions,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		updatePlayerData,
		updatePlayerStatistics,
		writePlayerEvent,
	}, dispatch)
)(AdminTool);
