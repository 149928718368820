import { createSelector } from '@reduxjs/toolkit';
import {
	CATALOG, DEFAULT_USERNAME, USERHASEMMAILSTAT, REGISTRATIONSTAT, REGIONSTAT, GROUP,
} from 'Constants';
import { inventorySelector } from './inventory';

const streamURLStateSelector = (state) => state.playfab?.MeetingURLs;
const streamIdPropsSelector = (state, props) => props.activity?.StoreTileId;
const userNameStateSelector = (state) => state.playfab?.PlayerProfile?.DisplayName;
const chatAuthStateSelector = (state) => state.playfab?.PlayerReadOnlyData?.chat_auth?.Value;
const playerDataSelector = (state) => state.playfab?.PlayerData;
const statsSelector = (state) => state.playfab?.Statistics;
const avatarStateSelector = (state) => state.playfab?.PlayerData?.Avatar;
const regionStateSelector = (state) => state.playfab?.PlayerData?.region?.Value;
const regionsStateSelector = (state) => state.wpcontent?.regions;
const groupLangStateSelector = (state) => state.wpcontent?.acfPageOptions?.acf?.global?.group_language?.groups_list;

export const streamURLSelector = createSelector([
	streamURLStateSelector,
	streamIdPropsSelector,
], (urls, id) => {
	if (!urls || !id) return null;

	return urls[id] ? urls[id].join_url : null;
});

/**
*Return the player's username
*/
export const userNameSelector = createSelector([
	userNameStateSelector,
	(state, props) => props?.fallback,
], (username, fallback) => {
	return username && username.trim() !== '' ? username : (fallback || DEFAULT_USERNAME);
});

/**
*Return if the player has an email associated with his account, mandatory to access the platform
*/
export const playerHasEmailSelector = createSelector(
	[statsSelector],
	(stats) => {
		if (!stats || stats.length === 0) return null;

		const HasEmail = stats.find((stat) => stat?.StatisticName === USERHASEMMAILSTAT.USEREMAIL);
		return HasEmail?.Value;
	},
);

/**
*Return if the player is authenticated to the chat, mandatory to access the platform
*/
export const chatAuthSelector = createSelector([
	chatAuthStateSelector,
	(state, props) => props?.fallback,
], (chatAuth, fallback) => {
	return chatAuth !== '' ? chatAuth : (fallback || DEFAULT_USERNAME);
});

export const joinMeetingURLSelector = createSelector([
	streamURLStateSelector,
	streamIdPropsSelector,
], (urls, id) => {
	if (!urls || !id) return null;

	return urls[id] ? urls[id].join_url : null;
});

/**
*Return if the player has the banned item in his inventory
*/
export const isBannedSelector = createSelector(
	[inventorySelector],
	(inventory) => {
		if (!inventory) return false;

		return Boolean(inventory.find(item => item.playfab.DisplayName === CATALOG.banned_user_item));
	}
);

/**
*Return if the player has the update needed item in his inventory
*/
export const updateNeededItemSelector = createSelector(
	[inventorySelector],
	(inventory) => {
		if (!inventory) return null;

		return inventory.find(item => item.itemId === CATALOG.update_needed_item);
	}
);

export const userEnabledNotificationsSelector = createSelector(
	[playerDataSelector],
	(playerData) => {
		if (!playerData) return null;
		return playerData?.EnableNotifications?.Value || '';
	}
);

export const userSessionIdSelector = createSelector(
	[playerDataSelector],
	(playerData) => {
		if (!playerData) return null;
		return playerData?.SessionId?.Value || '';
	}
);

export const avatarSelector = createSelector([
	avatarStateSelector,
], (avatar) => {
	if (!avatar) return '';

	return `${avatar.Value}?v=${avatar.LastUpdated}`;
});

export const regionSelector = createSelector([
	regionStateSelector,
	regionsStateSelector,
], (region, regions) => {
	if (!region || !regions) return '';

	return regions.find(r => r.slug === region)?.title?.rendered || '';
});

/**
*Return if the player has the Account Completed Item in his inventory, mandatory to access the platform
*/
export const isAccountCompletedSelector = createSelector(
	[inventorySelector],
	(inventory) => {
		if (!inventory) return false;

		return Boolean(inventory.find(item => item.playfab.DisplayName === CATALOG.account_completed_item));
	}
);

/**
*Return if the player has the Declined item in his inventory
*/
export const isAccountDeclinedSelector = createSelector(
	[inventorySelector],
	(inventory) => {
		if (!inventory) return false;

		return Boolean(inventory.find(item => item.playfab.DisplayName === CATALOG.account_declined_item));
	}
);

export const isInInventorySelector = createSelector(
	[inventorySelector],
	(inventory) => {
		if (!inventory) return false;

		// return Boolean(inventory.find(item => item.itemId === idToCheck));
		return null;
	}
);

export const isBackEndRegistrationCompleteSelector = createSelector(
	[statsSelector],
	(stats)=>{
		if (!stats || stats.length === 0) return null;
		return Boolean(stats.find((stat) => stat?.StatisticName === REGISTRATIONSTAT.BACKEND));
	}
);

/**
*Return the user's region from Playfab
*/

export const playerGroupSelector = createSelector(
	[statsSelector],
	(stats) => {
		if (!stats || stats.length === 0) return null;

		const RegionStat = stats.find((stat) => stat?.StatisticName === REGIONSTAT.REGION);
		return GROUP[RegionStat?.Value];
	},
);


export const playerLangGroupSelector = createSelector(
	[statsSelector, groupLangStateSelector],
	(stats, groupLangs) => {
		if (!stats || stats.length === 0 || !groupLangs) return 'en-US';
		const RegionStat = stats.find((stat) => stat?.StatisticName === REGIONSTAT.REGION);
		const groupLang = groupLangs?.find (group => group.value === String(RegionStat?.Value));
		return groupLang?.language;
	}
)
