import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GitInfo from "react-git-info/macro";
import {
	REACT_APP_APP_NAME,
	BUILD_DATETIME,
	REACT_APP_WP_API,
	REACT_APP_WP_API_ACF,
	REACT_APP_STACK_API,
} from "Constants";
import { averageMsCalls, debugTracker, maxMsCalls, numberOfCalls } from "middleware/tracker";
import { now } from "utils/dateUtils";
import IconCheckMark from "assets/icons/icon-check-mark.svg";
import IconXMark from "assets/icons/icon-x-mark.svg";
import IconCancel from "assets/icons/icon-cancel.svg";

const gitInfo = GitInfo();
const START = now().format();
const START_TS = Date.now();
const { detect } = require("detect-browser");

const browser = detect();

function Debug({
	playfabId,
	teamsId,
	channel,
	isMainChatConnected,
	currentState,
	isAccountCompleted,
	isAccountDeclined,
	isRegistered,
	displayName,
	region,
	showDebug,
	setShowDebug,
}) {
	const [visible, setVisible] = useState(false);
	const [mins, setMins] = useState(0);
	const [hiveCalls, setHiveCalls] = useState({
		total: 0,
		rejected: 0,
		average: 0,
		max: 0,
	});
	const [chatCalls, setChatCalls] = useState({
		total: 0,
		rejected: 0,
		average: 0,
		max: 0,
	});
	const [contentCalls, setContentCalls] = useState({
		total: 0,
		rejected: 0,
		average: 0,
		max: 0,
	});
	const [wpApi, setWpApi] = useState("");
	const [wpApiAcf, setWpApiAcf] = useState("");
	const [chatApi, setChatApi] = useState("");
	const [stackApi, setStackApi] = useState("");
	const [clipboardUpdateMessage, setClipboardUpdateMessage] = useState("");

	const requirements = `Display Name: ${displayName}\nPlayFabId: ${playfabId}\nAccount Completed: ${isAccountCompleted === false ? "False" : "True"}\nAccount Declined: ${isAccountDeclined === false ? "False" : "True"}\nRegistration Completed: ${isRegistered === false ? "False" : "True"}\nRegion: ${region}`;
	const debugCalls = JSON.stringify(debugTracker(), null, 4);

	let interval = null;

	useEffect(() => {
		if(showDebug) {
			setVisible(true)
		}

	}, [showDebug])

	function copyDebug(content) {
		const textarea = document.createElement("textarea");
		textarea.textContent = content;
		textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
		document.body.appendChild(textarea);
		textarea.select();
		try {
			setClipboardUpdateMessage("success");
			return document.execCommand("copy"); // Security exception may be thrown by some browsers.
		} catch (ex) {
			// console.warn('Copy to clipboard failed.', ex);
			setClipboardUpdateMessage("error");
			return false;
		} finally {
			document.body.removeChild(textarea);
			setInterval(() => {
				setClipboardUpdateMessage("");
			}, 8000);
		}
	}

	function onMouseEnter() {
		interval = setInterval(update, 500);
		update();
		document.getElementById("debug-container").classList.add("show-debug");
	}

	function stopInterval() {
		clearInterval(interval);
		interval = null;
	}

	function onMouseLeave() {
		stopInterval();
	}

	function onKeyDown(e) {
		if (e.keyCode === 68 && e.altKey) {
			setVisible(true);
		}
	}

	function update() {
		const nowTs = Date.now();
		const diff = Math.floor((nowTs - START_TS) / 1000 / 60);

		setMins(diff);
		setHiveCalls({
			total: Math.round(numberOfCalls(["playfab"])),
			rejected: Math.round(numberOfCalls(["playfab"], ["rejected"])),
			average: Math.round(averageMsCalls(["playfab"], ["rejected", "fulfilled"])),
			max: Math.round(maxMsCalls(["playfab"])),
		});
		setChatCalls({
			total: Math.round(numberOfCalls(["chat"])),
			rejected: Math.round(numberOfCalls(["chat"], ["rejected"])),
			average: Math.round(averageMsCalls(["chat"])),
			max: Math.round(maxMsCalls(["chat"], ["rejected", "fulfilled"])),
		});
		setContentCalls({
			total: Math.round(numberOfCalls(["content", "wppage", "wpcontent"])),
			rejected: Math.round(numberOfCalls(["content", "wppage", "wpcontent"], ["rejected"])),
			average: Math.round(averageMsCalls(["content", "wppage", "wpcontent"])),
			max: Math.round(maxMsCalls(["content", "wppage", "wpcontent"], ["rejected", "fulfilled"])),
		});
	}

	function pingEndPoints(url, setter) {
		fetch(url).then((response) => {
			if (response.status === 200) {
				setter(
					<span className="valid-response">
						{response.status}
						<img className="muted" src={IconCheckMark} alt="" />
					</span>
				);
				return null;
			}
			setter(
				<span className="rejected-response">
					{response.status}
					<img className="muted" src={IconXMark} alt="" />
				</span>
			);
			return null;
		}).catch((e) => {
			setter(
				<span className="rejected-response">
					{e.status}
					<img className="muted" src={IconXMark} alt="" />
				</span>
			);
			return null;
		});
	}

	useEffect(() => {
		document.addEventListener("keydown", onKeyDown);
		stopInterval();
		return () => {
			document.removeEventListener("keydown", onKeyDown);
			stopInterval();
		};
	}, []);

	useEffect(() => {
		pingEndPoints(REACT_APP_WP_API, setWpApi);
		pingEndPoints(REACT_APP_WP_API_ACF, setWpApiAcf);
		pingEndPoints(REACT_APP_STACK_API, setStackApi);
	}, []);
	useEffect(() => {
		if (isMainChatConnected === true) {
			setChatApi(
				<span className="valid-response">
					200
					<img className="muted" src={IconCheckMark} alt="" />
				</span>
			);
		} else {
			setChatApi(
				<span className="rejected-response">
					500
					<img className="muted" src={IconXMark} alt="" />
				</span>
			);
		}
	}, [isMainChatConnected]);

	function closeDebugger() {
		setVisible(false);
		setShowDebug(false);
		document.getElementById("debug-container").classList.remove("hide-debug");
	}

	return (
		visible && (
			<div id="debug-container" className="debug-overlay" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				<div
					role="button"
					className="close-debug"
					aria-label="closeDebug"
					tabIndex="-1"
					onClick={() => closeDebugger()}
					onKeyPress={() => closeDebugger()}
				>
					<img alt="" src={IconCancel} />
				</div>
				<div>{`App Name: ${REACT_APP_APP_NAME}`}</div>
				<div>{`Teams ID: ${teamsId}`}</div>
				<div>{`PlayFab ID: ${playfabId}`}</div>
				<div>{`Display Name: ${displayName}`}</div>
				<div>{`Build Date: ${BUILD_DATETIME}`}</div>
				<div>{`Commit ID: ${gitInfo.commit.shortHash}`}</div>
				<div>{`Commit Date: ${gitInfo.commit.date}`}</div>
				<div>{`Chat Channel: ${channel}`}</div>
				<div>{`Session Status: ${currentState}`}</div>
				<br />
				<div>{`Page opened since: ${START}`}</div>
				<div>{`Running since ${mins} minute${mins > 1 ? "s" : ""}`}</div>
				<br />
				<span className={`clipboard-status-message ${clipboardUpdateMessage}`}>{clipboardUpdateMessage === 'success' ? 'Copied to clipboard!' : clipboardUpdateMessage === 'error' ? 'Error copying values to clipboard.' : ''}</span>
				<br />
				<table className="requirements-table">
					<tbody>
						<tr>
							<th>Requirements</th>
							<th>Status</th>
						</tr>

						<tr>
							<td>Account Completed</td>
							<td>{isAccountCompleted === false ? "False" : "True"}</td>
						</tr>
						<tr>
							<td>Account Declined</td>
							<td>{isAccountDeclined === false ? "False" : "True"}</td>
						</tr>
						<tr>
							<td>Registration Completed</td>
							<td>{isRegistered === false ? "False" : "True"}</td>
						</tr>
						<tr>
							<td>Region</td>
							<td>{region}</td>
						</tr>
					</tbody>
				</table>
				<div
					className="debug-calls"
					role="button"
					onClick={() => copyDebug(requirements)}
					onKeyPress={() => copyDebug(requirements)}
					tabIndex="-1"
				>
					Copy Requirements
				</div>
				<br />
				<table className="resolution-table">
					<tbody>
						<tr>
							<th>Screen resolution</th>
						</tr>
						<tr>
							<td>Available width</td>
							<td>{window.screen.availWidth}</td>
							<td>Available height</td>
							<td>{window.screen.availHeight}</td>
						</tr>
						<tr>
							<td>Inner width</td>
							<td>{window.innerWidth}</td>
							<td>Inner height</td>
							<td>{window.innerHeight}</td>
						</tr>
					</tbody>
				</table>
				<br />
				<table className="browser-table">
					<tbody>
						<tr>
							<th>Browser info</th>
						</tr>
						{browser && (
							<>
								<tr>
									<td>Browser</td>
									<td>{browser.name}</td>
								</tr>
								<tr>
									<td>Version</td>
									<td>{browser.version}</td>
								</tr>
								<tr>
									<td>OS</td>
									<td>{browser.os}</td>
								</tr>
							</>
						)}
						<tr>
							<td>Zoom ratio:</td>
							<td>{window.devicePixelRatio}</td>
						</tr>
					</tbody>
				</table>
				<br />
				<table className="response-table">
					<tbody>
						<tr>
							<th>Endpoint</th>
							<th>Response</th>
						</tr>

						<tr>
							<td>WP_API</td>
							<td>{wpApi}</td>
						</tr>
						<tr>
							<td>WP_API_ACF</td>
							<td>{wpApiAcf}</td>
						</tr>
						<tr>
							<td>CHAT_API</td>
							<td>{chatApi}</td>
						</tr>
						<tr>
							<td>STACK_API</td>
							<td>{stackApi}</td>
						</tr>
					</tbody>
				</table>
				<br />
				<table>
					<tbody>
						<tr>
							<th aria-label="Blank space" />
							<td>CPM</td>
							<td>
								<span role="img" aria-label="up">
									calls
								</span>
							</td>
							<td>
								<span role="img" aria-label="exclamation">
									errors
								</span>
							</td>
							<td>avg ms</td>
							<td>max ms</td>
						</tr>
						<tr>
							<th>Hive</th>
							<td>{Math.round(hiveCalls.total / (mins || 1))}</td>
							<td>{hiveCalls.total}</td>
							<td>{hiveCalls.rejected}</td>
							<td>{hiveCalls.average}</td>
							<td>{hiveCalls.max}</td>
						</tr>
						<tr>
							<th>Chat</th>
							<td>{Math.round(chatCalls.total / (mins || 1))}</td>
							<td>{chatCalls.total}</td>
							<td>{chatCalls.rejected}</td>
							<td>{chatCalls.average}</td>
							<td>{chatCalls.max}</td>
						</tr>
						<tr>
							<th>Content</th>
							<td>{Math.round(contentCalls.total / (mins || 1))}</td>
							<td>{contentCalls.total}</td>
							<td>{contentCalls.rejected}</td>
							<td>{contentCalls.average}</td>
							<td>{contentCalls.max}</td>
						</tr>
					</tbody>
				</table>
				<div
					className="debug-calls"
					role="button"
					onClick={() => copyDebug(debugCalls)}
					onKeyPress={() => copyDebug(debugCalls)}
					tabIndex="-1"
				>
					Copy Debug Calls
				</div>
			</div>
		)
	);
}

Debug.defaultProps = {
	playfabId: "",
	teamsId: "",
	channel: "",
	isMainChatConnected: null,
	currentState: null,
	isAccountCompleted: null,
	isAccountDeclined: null,
	isRegistered: null,
	displayName: "",
	region: null,
	showDebug: false,
	setShowDebug: () => {},
};

Debug.propTypes = {
	playfabId: PropTypes.string,
	teamsId: PropTypes.string,
	channel: PropTypes.string,
	isMainChatConnected: PropTypes.bool,
	currentState: PropTypes.string,
	isAccountCompleted: PropTypes.bool,
	isAccountDeclined: PropTypes.bool,
	isRegistered: PropTypes.bool,
	displayName: PropTypes.string,
	region: PropTypes.number,
	showDebug:PropTypes.bool,
	setShowDebug: PropTypes.func,
};

export default Debug;
