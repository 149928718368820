import React from "react";
import PropTypes from "prop-types"
import NotificationPoint from "./NotificationPoint"

function Tab({ title, activeTab, setActiveTab, newElements, iconClass, icon, tabType }){

	return (
		<div className="button-tab">
			<button type='button' onClick={() => setActiveTab(tabType)} className={`chat-select ${activeTab === tabType ? "active" : ""}`}>
				<img className={iconClass} src={icon} alt='' />
				<span>{title}</span>
			</button>
			{activeTab && newElements && <NotificationPoint />}
		</div>
	);
};

Tab.defaultProps = {
	title: null,
	activeTab: null,
	newElements: null,
	iconClass: null,
	icon: null,
	tabType: null,
	setActiveTab: () => {},
};

Tab.propTypes = {
	title: PropTypes.string,
	activeTab: PropTypes.string,
	newElements: PropTypes.bool,
	iconClass: PropTypes.string,
	icon: PropTypes.string,
	tabType: PropTypes.string,
	setActiveTab: PropTypes.func,
};

export default Tab;
