/**
 * @format
 */

/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import uuid from "react-uuid";

import {
	getDayTwoDigits,
	getTimeStyleShort,
	getWeekdayShort,
	getMonthShort,
} from "utils/dateUtils";
import Tile from "containers/Partials/LeftPanel/Tile";

function MyAgenda({
	days,
	activeDay,
	setActiveDay,
	tiles,
	isActive,
	acfPageOptions,
	groupLang,
	agendaHours,
}) {
	useEffect(() => {
		if (!activeDay && days) {
			const date = dayjs().date();

			let today = days.find((d) => d.date() === date);
			if (!today) today = days.find((d) => d.date() >= date);

			if (today) setActiveDay(today);
		}
	}, [activeDay, days]);

	const timelineRef = useRef();
	const scrollerRef = useRef();

	useEffect(() => {
		if (timelineRef?.current && scrollerRef?.current) {
			const { offsetTop } = timelineRef.current || {};

			scrollerRef.current.scrollTo({
				top: offsetTop - window.innerHeight / 2,
			});
		}
	}, [isActive]);

	const date = dayjs(activeDay);

	function goToNextDay() {
		goToDay();
	}

	function goToPrevDay() {
		goToDay(-1);
	}

	function goToDay(direction = 1) {
		const curDate = date.date();
		const curMonth = date.month();
		const i = days.findIndex(
			(d) => d.date() === curDate && d.month() === curMonth
		);

		const nextDay = days[i + direction];

		if (nextDay) {
			setActiveDay(nextDay);
		}
	}

	function getTiles() {
		return tiles.map((tile, index) => {
			return (
				<Tile
					tile={tile}
					index={index}
					key={uuid()}
				/>
			);
		});
	}

	const d = new Date();
	const pctDayElapsed =
		(d.getHours() * 3600 +
			d.getMinutes() * 60 +
			d.getSeconds() +
			d.getMilliseconds() / 1000) /
		86400;

	if (!activeDay) return null;

	return (
		<div className="side-agenda">
			<div className="date-selector">
				<div
					className="arrow-left"
					aria-label="Previous Day"
					role="button"
					tabIndex="-1"
					onClick={goToPrevDay}
					onKeyPress={goToPrevDay}
				>
					<img
						className="normalIcon"
						src={
							acfPageOptions.acf?.theme?.icon?.navigation?.previous
								?.image1?.sizes?.thumbnail
						}
						alt=""
					/>
					<img
						className="activeIcon"
						src={
							acfPageOptions.acf?.theme?.icon?.navigation?.previous
								?.image2?.sizes?.thumbnail
						}
						alt=""
					/>
				</div>
				<div className="date-ctn">
					<div className="date">
						{getDayTwoDigits(date.$d, groupLang)}
					</div>
					<div className="day">
						{getWeekdayShort(date.$d, groupLang)}
					</div>
					<div className="month">
						{getMonthShort(date.$d, groupLang)}
					</div>
				</div>
				<div
					className="arrow-right"
					aria-label="Arrow Right"
					role="button"
					tabIndex="-1"
					onClick={goToNextDay}
					onKeyPress={goToNextDay}
				>
					<img
						className="normalIcon"
						src={
							acfPageOptions.acf?.theme?.icon?.navigation?.next
								?.image1?.sizes?.thumbnail
						}
						alt=""
					/>
					<img
						className="activeIcon"
						src={
							acfPageOptions.acf?.theme?.icon?.navigation?.next
								?.image2?.sizes?.thumbnail
						}
						alt=""
					/>
				</div>
			</div>
			<div className="scroller" ref={scrollerRef}>
				<div className="hour-grid">
					{agendaHours &&
						agendaHours.map((timeSlot, index) => (
							<div
								className={`hour hour-${index}`}
								key={timeSlot}
							>
								{getTimeStyleShort(timeSlot.$d, "en-US")}
							</div>
						))}
					<div
						ref={timelineRef}
						className="time-line"
						style={{ top: `${pctDayElapsed * 100}%` }}
					/>
					{getTiles()}
				</div>
			</div>
		</div>
	);
}

MyAgenda.defaultProps = {
	days: null,
	activeDay: null,
	setActiveDay: () => {},
	tiles: null,
	isActive: false,
	acfPageOptions: null,
	groupLang: null,
	agendaHours: null,
};

MyAgenda.propTypes = {
	days: PropTypes.array,
	activeDay: PropTypes.string,
	setActiveDay: PropTypes.func,
	tiles: PropTypes.array,
	isActive: PropTypes.bool,
	acfPageOptions: PropTypes.object,
	groupLang: PropTypes.string,
	agendaHours: PropTypes.array,
};

export default MyAgenda;
