import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { ReactComponent as DefaultAvatar } from "assets/icons/pack1/chat/chat-box/icon-profile-pic-s.svg";
import { now } from "utils/dateUtils";

function Message({
	message,
	author,
	timestamp,
	displayName,
	otherPlayerData,
	newSupportMessages,
	canScroll,
	acfPageOptions,
}) {
	const [showAnswer, setShowAnswer] = useState(false);
	const [units, setUnits] = useState("s");
	const timeRef = useRef();
	const isSameAuthorChat = author === displayName;
	const [isNewMessage, setIsNewMessage] = useState(true);

	useEffect(() => {
		const timer = setInterval(
			() => {
				const nowTimestamp = now().valueOf();

				const diffSecs = dayjs(nowTimestamp).diff(dayjs(timestamp), "second");
				const diffMins = dayjs(nowTimestamp).diff(dayjs(timestamp), "minute");

				const diffToShow =
					diffSecs <= 60 ? diffSecs + " sec" : Math.floor(diffMins) + " min";
				if (diffSecs >= 60 && units === "s") {
					setUnits("m");
				}
				if (diffSecs >= 30) {
					setIsNewMessage(false);
				}

				if (timeRef && timeRef.current) {
					timeRef.current.innerHTML = diffToShow;
				}
			},
			units === "s" ? 1000 : 60000
		);

		return () => {
			clearInterval(timer);
		};
	}, [units, timestamp]);

	return (
		<>
			{newSupportMessages > 0 && canScroll()}
			{message !== "Agent Online" && message !== "Issue Resolved" && (
				<div className="d-flex mobile-message mx-3 my-2">
					<div className="message-avatar">
						<div className={`${isSameAuthorChat && "own-message"}`}>
							{otherPlayerData && otherPlayerData.data.Avatar ? (
								<img src={otherPlayerData.data.Avatar} alt="" />
							) : (
								<DefaultAvatar
									className={`${isSameAuthorChat && "own-message"}`}
								/>
							)}
						</div>
					</div>
					<div
						className={`d-flex flex-row message-container w-100 p-3 justify-content-between ${
							isSameAuthorChat ? "own-message" : "support-message"
						}`}
					>
						<div className="d-flex flex-column left">
							<span className="mb-1">
								{isSameAuthorChat ? acfPageOptions?.chat_support?.user_name : acfPageOptions?.chat_support?.tech_name}
							</span>
							<div className="msg w-100">{message}</div>
						</div>
						<div className="d-flex flex-column text-end align-items-end right">
							<span className="time" ref={timeRef} />
							<span className="new-message">
								{isNewMessage && newSupportMessages > 0 && "new"}
							</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

Message.defaultProps = {
	message: null,
	timestamp: null,
	author: null,
	displayName: null,
	otherPlayerData: null,
	newSupportMessages: 0,
	canScroll: () => {},
	acfPageOptions: null,
};

Message.propTypes = {
	message: PropTypes.string,
	timestamp: PropTypes.object,
	author: PropTypes.string,
	displayName: PropTypes.string,
	otherPlayerData: PropTypes.object,
	newSupportMessages: PropTypes.number,
	canScroll: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default Message;
