import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import parse from "html-react-parser";

function SelectField({
	name,
	options,
	value,
	onChange,
	acfPageOptions,
	disabled,
	placeholder,
}) {
	if (!options || options.length === 0) return "Loading...";

	const slug = name.toLowerCase().replace(" ", "_");
	const customStyles = {
		control: (provided) => ({
			...provided,
			background: "transparent",
			outline: "none",
			border: "none",
			borderRadius: "none",
		}),
		container: (provided) => ({
			...provided,
			outline: "none",
		}),
		input: (provided) => ({
			...provided,
			color: "white",
			fontSize: "1.3em",
		}),
		placeholder: (provided) => ({
			...provided,
			color: "white",
			fontSize: "1.3em",
		}),
		singleValue: (provided) => ({
			...provided,
			color: "white",
			fontSize: "1.3em",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: "white",
			transform: "scale(0.8)",
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		option: (provided, state) => ({
			...provided,
			color: "white",
			fontSize: "1.3em",
			background: state.isFocused ? "rgba(255, 255, 255, 0.1)" : "transparent",
		}),
		menu: (provided) => ({
			...provided,
			background: "#262A32",
		}),
	};

	const val = options.find((option) => option.value === value);
	const formatOptionLabel = (data) => {
		return <span>{data?.label && parse(data?.label)}</span>;
	};

	return (
		<div className="selectfield-group pb-3">
			<div className="select-wrapper">
				<label htmlFor={slug}>{name && parse(name)}</label>
				<Select
					placeholder={placeholder || "Select..."}
					styles={customStyles}
					options={options}
					value={val}
					onChange={onChange}
					menuPlacement="bottom"
					formatOptionLabel={formatOptionLabel}
					isDisabled={disabled}
					isSearchable={false}
					className={`mobile-select ${val !== "" ? `filled` : ""}`}
					classNamePrefix="react-select"
				/>
			</div>
		</div>
	);
}

SelectField.propTypes = {
	name: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.string,
	onChange: PropTypes.func,
	acfPageOptions: PropTypes.object,
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
};

SelectField.defaultProps = {
	name: "",
	options: [],
	value: "",
	onChange: () => {},
	acfPageOptions: {},
	disabled: false,
	placeholder: "",
};

export default SelectField;
