/**
 * @format
 */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useStore } from "react-redux";
import { broadcastMessage, MESSAGE_EMOJI } from "redux/chat";

function EmojiController({defaultTab}) {
	const dispatch = useDispatch();
	const state = useStore().getState();

	const [emojiLimitReached, setEmojiLimitReached] = useState(false);
	const emojis = state.wpcontent?.acfPageOptions?.acf?.theme?.emojis;
	const emojiIcon = state.wpcontent?.acfPageOptions?.acf?.theme?.icon?.live?.emojis?.url;
	useEffect(() => {
		if (emojiLimitReached) {
			setTimeout(() => { setEmojiLimitReached(false); }, 1000);
		}
	}, [emojiLimitReached]);

	function emitChange(key, name) {
		if (!emojiLimitReached) {
			window.dataLayer.push({
				'event': 'generic_event',
				'eventCategory': 'emoji',
				'eventAction': 'click',
				'eventLabel': name,
			});

			dispatch(broadcastMessage({
				type: MESSAGE_EMOJI,
				message: key,
				channel: state.chat.channel,
				playfabId: state.playfab.PlayFabId,
			}));
			setEmojiLimitReached(true);
		}
	}

	return (
		<div className={`emoji-controller ${!defaultTab ? 'no-com-panel' : ''}`}>
			<div className="icons-container">
				<button
					type="button"
					aria-label="Emoji Button"
					className="emoji-button"
				>
					<img src={emojiIcon} alt="" />
				</button>
				<div className="emoji-controller-ctn">
					<div className="controller">
						{emojis && emojis.map((emoji, key) => (
							emoji?.name && <img onClick={() => emitChange(`${key}`, `${emoji?.name}`)} key={`${emoji?.image?.sizes?.thumbnail}`} className="controller-emoji" src={emoji?.image?.sizes?.thumbnail} alt={emoji.name} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

EmojiController.defaultProps = {
	defaultTab: null,
}

EmojiController.propTypes = {
	defaultTab: PropTypes.string,
}

export default EmojiController;
