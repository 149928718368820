import { createSlice } from "@reduxjs/toolkit";
import { createDebouncedAsyncAction } from "utils/reduxhelpers";
import { instance } from "as-stack-sdk/sdk";

const stack = instance;

function stackAction(action) {
	return (payload, { dispatch, getState }) => {
		return action(payload, dispatch, getState).then((resp) => {
			return resp;
		});
	};
}

/**
 *Get all available Jitsi random rooms
 */
export const getRandomRooms = createDebouncedAsyncAction(
	"rooms/getRandomRooms",
	stackAction(() => {
		const call = stack.Manager("GetRandomRooms", {});
		return call;
	}),

	{
		fulfilled: (state, action) => {
			return {
				...state,
				// Commented because of crash
				// TypeError undefined is not iterable (cannot read property Symbol(Symbol.iterator))
				List: action.payload?.RandomRooms ? [...action.payload.RandomRooms] : [],
			};
		},
		//Set the List to an empty array if the call is rejected
		rejected: (state) => {
			return {
				...state,
				List: [],
			};
		},
	}
);

/**
 *Create a Jitsi random room
 */
export const createRandomRooms = createDebouncedAsyncAction(
	"rooms/createRandomRooms",
	stackAction((data) => {
		return stack.Manager("CreateRandomRoom", { Subject: data?.roomName, Public: data?.isPublic });
	}),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				activeRoom: action?.payload?.RandomRoom?.roomId,
			};
		},
	}
);

/**
 *Invite a player to a random Jitsi room
 */
export const inviteToRandomRoom = createDebouncedAsyncAction(
	"rooms/inviteToRandomRoom",
	stackAction((data) => {
		return stack.Manager("InviteToRandomRoom", { RoomId: data?.roomId, Participants: data?.participants });
	}),
	{
		fulfilled: () => {},
	}
);

/**
 *Return all the users
 */
export const getUsers = createDebouncedAsyncAction(
	"rooms/getUsers",
	stackAction(() => {
		return stack.Manager("GetUsers", {});
	}),
	{
		fulfilled: (state, action) => {
			return {
				...state,
				users: action.payload?.Users,
			};
		},
	}
);

const rooms = createSlice({
	name: "rooms",
	reducers: {
		setActiveRoom: (state, action) => {
			return {
				...state,
				activeRoom: action.payload,
			};
		},
		resetActiveRoom: (state) => {
			return {
				...state,
				activeRoom: null,
			};
		},
	},
	extraReducers: {
		...getRandomRooms.reducers,
		...createRandomRooms.reducers,
		...inviteToRandomRoom.reducers,
		...getUsers.reducers,
	},
	initialState: {
		activeRoom: null,
		users: null,
	},
});

export default rooms;

export const { setActiveRoom, resetActiveRoom } = rooms.actions;
