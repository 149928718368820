/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Jitsi from 'containers/Jitsi';
import { JITSI_SCRIPT_URL } from 'Constants';
import Button from 'components/Buttons/Button';

function MeetingStarted({ url, joinMeetingURL, acfPageOptions, activity }) {
	const [joinJitsi, setJoinJitsi] = useState(false);

	function loadJitsiScript() {
		return new Promise((resolve) => {
			const script = document.createElement('script');
			script.src = JITSI_SCRIPT_URL;
			script.async = true;
			script.onload = resolve;
			document.body.appendChild(script);
		});
	}

	const initialiseJitsi = async() => {
		if (!window.JitsiMeetExternalAPI) {
			await loadJitsiScript();
		}
	};

	useEffect(() => {
		initialiseJitsi();

		return () => {
			if (document.body.lastChild.tagName === 'SCRIPT') {
				document.body.removeChild(document.body.lastChild);
			}
		};
	}, []);

	return (
		<div className="meeting-started">
			{joinJitsi === false ? (
				<>
					<p>{acfPageOptions?.acf?.private_meeting?.welcome?.title}</p>
					<Button
						action={() => setJoinJitsi(true)}
						classes="primary large join-button"
						text={acfPageOptions?.acf?.private_meeting?.welcome?.button_label}
					/>
				</>
			) : (
				<>
					<Jitsi url={url} joinMeetingURL={joinMeetingURL} setJoinJitsi={setJoinJitsi} activity={activity} />
				</>
			)}
		</div>
	);
}

MeetingStarted.defaultProps = {
	joinMeetingURL: null,
	acfPageOptions: null,
	activity: null,
};

MeetingStarted.propTypes = {
	url: PropTypes.string.isRequired,
	joinMeetingURL: PropTypes.string,
	acfPageOptions: PropTypes.object,
	activity: PropTypes.object,
};

export default MeetingStarted;
