import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Agenda from "components/Pages/Agenda";
import { changePollingRate, writeTelemetryEvent } from "redux/playfab";
import { setActiveDay, setActiveChannel, setActiveTile, setActiveTilePosition } from "redux/agenda";
import {
	agendaDaysSelector,
	agendaHoursSelector,
	agendaTracksSelector,
	allTracksSelector,
	allTilesSelector,
} from "selector/agenda";
import { channelsSelector } from "selector/channels";
import { playerGroupSelector, playerLangGroupSelector } from "selector/player";

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		...state.agenda,
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		channels: channelsSelector(state),
		allTiles: allTilesSelector(state),
		days: agendaDaysSelector(state),
		agendaHours: agendaHoursSelector(state),
		agendaTracks: agendaTracksSelector(state),
		allTracks: allTracksSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
		groupLang: playerLangGroupSelector(state),
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			changePollingRate,
			setActiveDay,
			setActiveChannel,
			setActiveTile,
			setActiveTilePosition,
			writeTelemetryEvent,
		},
		dispatch
	)
)(Agenda);
