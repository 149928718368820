import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { badgeSelector } from 'selector/catalog';
import {
	sendEventInput,
	getItemInventory,
	getAnswerObjectives,
} from 'redux/playfab';
import Answers from 'components/Profile/Answers';

function mapStateToProps(state) {
	const badge = badgeSelector(state, { badgeId: state?.wpcontent?.acfPageOptions?.acf?.profile?.profile_tab?.badge_id });
	
	return {
		acfPageOptions: state?.wpcontent?.acfPageOptions,
		hasBadge: badge && badge.isInInventory,
		answerObjectives: state.playfab?.AnswerObjectives,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		sendEventInput,
		getItemInventory,
		getAnswerObjectives,
	}, dispatch)
)(Answers);
