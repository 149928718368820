import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from 'components/Partials/Avatar';
import { avatarSelector } from 'selector/player';

function mapStateToProps(state) {
	return {
		avatar: avatarSelector(state),
	};
}

export default connect(mapStateToProps, (dispatch) => bindActionCreators({}, dispatch))(Avatar);
