import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

function Field({
	name,
	value,
	onBlur,
	unstyled,
	asTextarea,
	icon,
	disabled,
}) {
	const [val, setVal] = useState(value || '');

	useEffect(() => {
		setVal(value);
	}, [value]);

	const slug = name.toLowerCase().replace(' ', '_');
	const ref = useRef();

	function onChange(e) {
		resize();
		setVal(e.target.value);
	}

	function resize() {
		if (asTextarea) {
			ref.current.style.height = 'auto';
			ref.current.style.height = ref.current.scrollHeight + 'px';
		}
	}

	function onInputBlur(e) {
		if (value !== e.target.value) {
			onBlur(e.target.value);
		}
	}

	function getInput() {
		const props = {
			id: 'slug',
			name: 'slug',
			type: 'text',
			onChange,
			onBlur: onInputBlur,
		};
		// eslint-disable-next-line react/jsx-props-no-spreading
		if (asTextarea) return <textarea rows="1" ref={ref} {...props} data-gramm_editor="false" value={val} />;
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <input ref={ref} {...props} value={val} disabled={disabled} />;
	}

	useEffect(() => {
		resize();
	});

	useEffect(() => {
		window.addEventListener('resize', resize);

		return () => {
			window.removeEventListener('resize', resize);
		};
	}, []);

	return (
		<div className={`field ${unstyled ? 'unstyled' : ''}`}>
			<label htmlFor={slug}>{name}</label>
			<div className="input-wrapper">
				{getInput()}
				{!disabled && <img className="icon" src={icon} alt="edit" />}
			</div>
		</div>
	);
}

Field.defaultProps = {
	unstyled: false,
	asTextarea: false,
	icon: "",
	disabled: false,
};

Field.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onBlur: PropTypes.func.isRequired,
	unstyled: PropTypes.bool,
	asTextarea: PropTypes.bool,
	icon: PropTypes.string,
	disabled: PropTypes.bool,
};

export default Field;
