/** @format */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import { ROUTES, REACT_APP_AS_STACK_APP_ID } from "Constants";
import NotFound from "containers/Pages/NotFound";
import MyAgenda from "containers/Partials/LeftPanel/MyAgenda";
import Home from "mobile/pages/containers/Home";
import Agenda from "mobile/pages/containers/Agenda";
import Speaker from "mobile/pages/containers/Speaker";
import Speakers from "mobile/pages/containers/Speakers";
import Partners from "mobile/pages/containers/Partners";
import Partner from "mobile/pages/containers/Partner";
import Session from "mobile/pages/containers/Session";
import Galleries from "mobile/pages/containers/Galleries";
import Nav from "mobile/components/containers/Nav";
import Faq from "mobile/pages/containers/Faq";
import Notices from "mobile/pages/containers/Notices";
import Terms from "mobile/pages/containers/Terms";
import Privacy from "mobile/pages/containers/Privacy";
import Notifications from "mobile/components/containers/Notifications";
import { Index as TechSupport } from "mobile/pages/TechSupport";
import Status from "containers/Pages/Status";
import Calendar from "mobile/pages/containers/Calendar";
import useWindowSize from "utils/useWindowSize";
import SessionDetails from "./components/containers/session/SessionDetails";

import Profile from "./pages/containers/Profile";
import { ComPanelContextProvider } from "./context/ComPanelContext";
import { TechSupportContextProvider } from "./context/TechSupportContext";
import { SessionContextProvider } from "./context/SessionContext";


function Routes({ setMobileOnly, mobileOnly, windowSize, setShowDebug, isDesktop }) {
	const size = useWindowSize();

	const history = useHistory();
	//TODO: remove when pushing live
	//will only work on demo dev and local.wp
	const [tileId, setTileId] = useState(null);
	
	useEffect(() => {
		const SESSION_PATH = location.pathname.split("/")[1];
		if (SESSION_PATH === "session") {
			setTileId(history.location.pathname.split("/")[2]);
		}
	}, []);

	useEffect(() => {
		return history.listen(() => {
			setTileId(history.location.pathname.split("/")[2]);
		});
	}, [history]);

	document.documentElement.style.setProperty("--tablet-breakpoint", 992);

	return (
		<ComPanelContextProvider>
			<TechSupportContextProvider>
				<SessionContextProvider>
					<Nav setShowDebug={setShowDebug} mobileOnly={mobileOnly} setMobileOnly={setMobileOnly} windowSize={windowSize} isDesktop={isDesktop} />
					<Notifications />
					<TechSupport />
					<Session tileId={tileId} />
					<Switch>
						{/* TODO: Add Mobile Nav here */}
						<Route path={ROUTES.MAIN_HUB} exact>
							<Home isDesktop={isDesktop} />
						</Route>
						<Route path="/profile/:active_tab?">
							<Profile />
						</Route>
						<Route path={ROUTES.AGENDA}>
							{size.width <
								getComputedStyle(document.documentElement).getPropertyValue(
									"--tablet-breakpoint"
								)  ? (
									<Agenda />
								) : (
									<Calendar />
								)}
						</Route>
						<Route path={ROUTES.SPEAKERS} exact>
							<Speakers />
						</Route>
						<Route path={ROUTES.SPEAKER}>
							<Speaker />
						</Route>
						<Route path={ROUTES.PARTNERS} exact>
							<Partners />
						</Route>
						<Route path={ROUTES.PARTNER}>
							<Partner />
						</Route>
						<Route path="/myagenda">
							<MyAgenda />
						</Route>
						<Route path={ROUTES.FAQ}>
							<Faq pageSlug="pages?slug=faq" />
						</Route>
						<Route path={ROUTES.NOTICES} exact>
							<Notices />
						</Route>
						<Route path={ROUTES.TERMS}>
							<Terms pageSlug="pages?slug=terms-of-use" />
						</Route>
						<Route path={ROUTES.PRIVACY}>
							<Privacy pageSlug="pages?slug=privacy-policy" />
						</Route>
						<Route path={ROUTES.GALLERY} exact>
							<Galleries />
						</Route>
						<Route path={ROUTES.SESSION} />
						<Route
							path={ROUTES.SESSION_DETAILS}
							render={(props) => (
								<SessionDetails
									key={props.match.params.tileId}
									tileId={props.match.params.tileId}
									isPage
								/>
							)}
						/>
						<Route exact path={ROUTES.TECHSUPPORT} />
						<Route path={ROUTES.ERROR_404}>
							<NotFound />
						</Route>
						{/* If no routes found redirect to 404 page */}
						{/* TODO: Redirect to 404 when all mobile first pages complete */}
						<Redirect to="/" />
					</Switch>
				</SessionContextProvider>
			</TechSupportContextProvider>
		</ComPanelContextProvider>
	);
}

Routes.defaultProps = {
	mobileOnly: false,
	setMobileOnly: () => {},
	match: {},
	setShowDebug: () => {},
	windowSize: null,
	isDesktop: true,
};

Routes.propTypes = {
	mobileOnly: PropTypes.bool,
	setMobileOnly: PropTypes.func,
	match: PropTypes.shape({
		params: PropTypes.shape({
			tileId: PropTypes.node,
		}),
	}),
	setShowDebug: PropTypes.func,
	windowSize: PropTypes.number,
	isDesktop: PropTypes.bool, 
};

export default Routes;
