/* eslint-disable */

import { location } from '@microsoft/teams-js';
import { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import App from 'App';
import { MAX_ERRORS_BEFORE_REBOOT, ADMINSTAT, } from 'Constants';
import { isLoadingSelector } from 'selector/loading';
import { isBannedSelector, updateNeededItemSelector, userSessionIdSelector, playerGroupSelector, userEnabledNotificationsSelector } from 'selector/player';
import { isPlatformClosedSelector, isEventOverSelector } from 'selector/platform';
import { setAppMode } from 'redux/env';
import { getPlayerData, getPlayerReadOnlyData, updatePlayerData, consumeInventoryItem } from 'redux/playfab';
import { fetchWPMobileTheme, fetchWPChannelsData, fetchWPSpeakersData, fetchWPGalleriesData, fetchWPTagsData, fetchWPCustomListings, fetchWPPartnersData, fetchWPInteractiveGalleriesData, fetchWpDealerLoungeData, fetchWpShowroomData, fetchWPCustomListingCategories, fetchWpContentGalleryData, fetchWPPageData } from 'redux/wppage';
import { statSelector } from 'selector/stats';

function mapStateToProps(state) {
	return {
		region: statSelector(state, { statName: 'region' }),
		texts: state.texts,
		isLoading: isLoadingSelector(state),
		teamsSessionId: state.teams?.sessionId,
		userSessionId: userSessionIdSelector(state),
		isBanned: isBannedSelector(state),
		needsUpdateItem: updateNeededItemSelector(state),
		tooManyErrors: false,
		acfPageOptions: state.wpcontent.acfPageOptions,
		mobileTheme: state.wppage.mobileTheme,
		isPlatformClosed: isPlatformClosedSelector(state),
		isEventOver: isEventOverSelector(state),
		isAdmin: statSelector(state, { statName: 'admin' }) === ADMINSTAT.ADMIN,
		playerGroup: playerGroupSelector(state),
		userHasEnabledNotifications: userEnabledNotificationsSelector(state),
		navigation: state?.wpcontent?.navigation,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		setAppMode,
		fetchWPMobileTheme,
		fetchWPChannelsData,
		fetchWPSpeakersData,
		fetchWPGalleriesData,
		fetchWPTagsData,
		getPlayerData,
		getPlayerReadOnlyData,
		updatePlayerData,
		consumeInventoryItem,
		fetchWPCustomListings,
		fetchWPPartnersData,
		fetchWPInteractiveGalleriesData,
		fetchWpShowroomData,
		fetchWpDealerLoungeData,
		fetchWPCustomListingCategories,
		fetchWpContentGalleryData,
		fetchWPPageData,
	}, dispatch)
)(App);
