import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createToast } from "redux/toastNotifications";
import {
	broadcastMessage,
	clearNewMessages,
	chatSupportJoinRoom,
	chatSupportLeaveRoom,
	clearMessages,
	requestSupport,
	triggerNoNewMessages,
	chatSupportConnect,
	chatSupportListen,
	chatSupportStopListen,
	closeSupportSession,
	triggerCancelled,
} from "redux/chatSupport";
import { userNameSelector } from "selector/player";
import { statSelector } from "selector/stats";
import { USER_LEVELS, CATALOG } from "Constants";
import { itemInventorySelector } from "selector/inventory";
import Chat from "../../techSupport/Chat";

function mapStateToProps(state) {
	return {
		connection: state.chatSupport.connection,
		channel: state.chatSupport.channel,
		messages: state.chatSupport.messages,
		joined_room: state.chatSupport.joined_room,
		openDate: state.chatSupport.openDate,
		is_resolved: state.chatSupport.is_resolved,
		topic: state.chatSupport.subject,
		ownPlayfabId: state.playfab?.PlayFabId,
		displayName: userNameSelector(state, {
			fallback: state.teams.userPrincipalName,
		}),
		isModerator:
			statSelector(state, { statName: "userlevel" }) === USER_LEVELS.MODERATOR,
		canChat: !itemInventorySelector(state, { itemId: CATALOG.disable_chat }),
		playfabId: state.playfab?.PlayFabId,
		newSupportMessages: state.chatSupport?.newMessages,
		hasChatted: state.tracking?.hasChatted,
		acfPageOptions: state.wpcontent?.acfPageOptions,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			broadcastMessage,
			clearNewMessages,
			chatSupportJoinRoom,
			chatSupportLeaveRoom,
			clearMessages,
			triggerCancelled,
			requestSupport,
			triggerNoNewMessages,
			chatSupportConnect,
			chatSupportListen,
			chatSupportStopListen,
			closeSupportSession,
			createToast,
		},
		dispatch
	)
)(Chat);
