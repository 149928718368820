import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Speaker from 'mobile/pages/Speaker';
import { singleSpeakerSelector } from 'selector/speakers'; //TODO: move to mobile
import { fetchWPSpeakersData, fetchWPChannelsData } from 'redux/wppage';
import { fetchWPActivities } from 'redux/wpcontent';
import { writeTelemetryEvent } from 'redux/playfab';
import { playerGroupSelector } from 'selector/player';

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,authMethod: state.auth?.method,
		playerGroup: playerGroupSelector(state),

		speaker: singleSpeakerSelector(state, props),
		acfPageOptions: state.wpcontent.acfPageOptions,
		
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		fetchWPChannelsData,
		fetchWPActivities,
		fetchWPSpeakersData,
		writeTelemetryEvent,
	}, dispatch)
)(Speaker));