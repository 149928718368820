import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { statSelector } from "selector/stats";
import { PARTNERSTAT } from "Constants";
import { updatePlayerData, writePlayerEvent, getItemInventory } from "redux/playfab";
import { userNameSelector, playerGroupSelector } from "selector/player";
import ProfileTab from "../../profile/profileTab/Index";

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		enableNotifications: state.playfab?.PlayerData?.EnableNotifications,
		profilePrivacy: state.playfab?.PlayerData?.ProfilePrivacy,
		attendanceType: state.playfab?.PlayerData?.AttendanceType,
		displayName: userNameSelector(state),
		playerData: state.playfab?.PlayerData,
		playfabId: state.playfab?.PlayFabId,
		sessions: state.playfab?.Statistics,
		isPartner: statSelector(state, { statName: "partner" }) === PARTNERSTAT.PARTNER,
		leaderboardOnPosition: state.playfab?.OnPositionLeaderboard,
		playerGroup: playerGroupSelector(state),
		authMethod: state.auth?.method,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			updatePlayerData,
			writePlayerEvent,
			getItemInventory,
		},
		dispatch
	)
)(ProfileTab);
