import { createSlice } from '@reduxjs/toolkit';

const side_agenda = createSlice({
	name: 'side_agenda',
	reducers: {
		setActiveDay: {
			reducer: (state, action) => {
				return {
					...state,
					activeDay: action.payload,
				};
			},
			prepare: (date) => {
				return {
					payload: date.format(),
				};
			},
		},
	},
	extraReducers: {},
	initialState: {
		activeDay: null,
	},
});

export default side_agenda;

export const { setActiveDay } = side_agenda.actions;
