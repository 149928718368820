import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowRoom from 'components/Pages/ShowRoom';
import { getShowRoomSelected } from 'selector/showRoom';
import { playerGroupSelector } from 'selector/player';

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		acfPageOptions: state.wpcontent.acfPageOptions,
		showroom: getShowRoomSelected(state, props),
		playerGroup: playerGroupSelector(state),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
	}, dispatch)
)(ShowRoom);
