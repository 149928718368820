import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Accordion } from 'react-bootstrap';
import uuid from 'react-uuid';
import Button from 'components/Buttons/Button';
import SelectField from 'components/Partials/SelectField';
import {
	getDayTwoDigits,
	getTimeStyleShort,
	toLocalTime,
	getWeekdayShort,
	getDateStyleLong,
	getDateStyleShort,
} from 'utils/dateUtils';
import { BookAMeetingContext } from 'components/BookAMeeting/BookAMeetingContext';
import CustomDate from './CustomDate';
import CustomTime from './CustomTime';

function BookAMeeting({
	groupLang,
	topics,
	expertLoadout,
	acfPageOptions,
	playfabId,
	playerData,
	displayName,
	BookAppointmentWithExpert,
	purchaseTile,
	getStoreLoadout,
	getItemInventory,
	updatePlayerData,
	booked_meetings,
	getPlayerData,
}) {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const { filteredTiles, setFilteredTiles } = useContext(BookAMeetingContext);
	const [filteredDates, setFilteredDates] = useState([]);
	const [appointmentRequestSent, setAppointmentRequestSent] = useState(false);
	const [chosenDate, setChosenDate] = useState(null);

	const onBookAMeetingSubmit = (e) => {
		e.preventDefault();
		return bookMeetingSubmit();
	};

	const bookMeetingSubmit = () => {
		filterExpertTiles();
		getPlayerData();
	};

	useEffect(() => {
		getPlayerData();
		setFilteredTiles(expertLoadout);
	}, [expertLoadout]);

	function onUpdateValue(value) {
		document.getElementById(
			'bookMeetingTopicLabel'
		).innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.selected_topic}: <span class="value" id="selected-topic" data-topic=${value.value}>${value.value}</span>`;

		const filteredTilesByTopic = expertLoadout.filter(
			(tile) => tile?.customPayload?.topic_description === value.value
		);
		document.getElementById(
			'bookMeetingDateLabel'
		).innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.select_date_label}`;
		document.getElementById(
			'bookMeetingTimeLabel'
		).innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.select_time_label}`;
		setFilteredTiles(filteredTilesByTopic);
		setFilteredDates(filteredTilesByTopic);
		setChosenDate(null);
	}

	function filterUniqueDates(array) {
		const results = [];
		const uniqueDates = [];
		array.map((tile) => {
			const tileDate = {
				intialDate: tile.customPayload['date_begin'],
				dayNum: getDayTwoDigits(toLocalTime(tile.customPayload['date_begin']).$d, groupLang),
			};
			return results.push(tileDate);
		});
		[...new Map(results.map((item) => [item['dayNum'], item])).values()].map((date) =>
			uniqueDates.push(date.intialDate)
		);
		return uniqueDates.sort((
			a,
			b
		) => {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});
	}

	function filterUniqueTimeSlots(array) {
		const results = [];
		const uniqueDates = [];
		array.map((tile) => {
			const tileDate = {
				intialDate: tile.customPayload['date_begin'],
				dayNum: getDayTwoDigits(toLocalTime(tile.customPayload['date_begin']).$d, groupLang),
			};
			return results.push(tileDate);
		});

		[...new Map(results.map((item) => [item['intialDate'], item])).values()].map((date) =>
			uniqueDates.push(date.intialDate)
		);

		uniqueDates.sort((
			a,
			b
		) => {
			if (a < b) {
				return -1;
			}
			if (a > b) {
				return 1;
			}
			return 0;
		});

		return uniqueDates.filter(item =>{
			if(booked_meetings.includes(item)) return null;
			return true;});
	}
	//TODO:Do a verification check after each input change to remove a disabled status on the booking button.
	function filterExpertTiles() {
		const selectedDate = document.getElementById('selected-date');
		const selectedTime = document.getElementById('selected-time');
		const selectedTopic = document.getElementById('selected-topic');
		const textareaValue = document.getElementById('text_area_comment').value;

		if (!selectedTopic) {
			document.getElementById(
				'bookMeetingTopicLabel'
			).innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.select_topic_label}<span class="value-error" id="selected-topic"> *</span>`;
		}
		if (!selectedTime) {
			document.getElementById(
				'bookMeetingTimeLabel'
			).innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.select_time_label}<span class="value-error" id="selected-topic"> *</span>`;
		}
		if (!selectedDate) {
			document.getElementById(
				'bookMeetingDateLabel'
			).innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.select_date_label}<span class="value-error" id="selected-topic"> *</span>`;
		}
		if (textareaValue === '') {
			document.getElementById(
				'text-area-header'
			).innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.subject_label}<span class="value-error" id="selected-topic"> *</span>`;
		}

		const comment = document.getElementById('text_area_comment').value;
		if (selectedTopic && selectedTime && selectedDate && textareaValue !== '') {
			const expertTile = filteredDates.filter((tile) => {
				return tile.customPayload.date_begin === selectedTime.dataset.slot;
			});

			const data = {
				comment,
				date_begin: expertTile[0]?.customPayload?.date_begin,
				date_end: expertTile[0]?.customPayload?.date_end,
				dealer_distributor_number: playerData?.dealer_distributor_number?.Value,
				displayName,
				expert_play_fab_id: expertTile[0]?.customPayload?.expert_play_fab_id,
				playfabId,
				storeTileId: expertTile[0]?.id,
				topic: selectedTopic.dataset.topic,
				item_notification_id: expertTile[0]?.customPayload?.item_notification_id,
			};
			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "book a meeting",
				eventAction: "confirmation",
				eventLabel: selectedTopic.dataset.topic,
			});
			const value = `${booked_meetings},${expertTile[0]?.customPayload?.date_begin}`;
			BookAppointmentWithExpert(data).then((response) => {
				if (response.payload.code === 200) {
					purchaseTile(expertTile[0]?.id).then(
						setTimeout(() => {
							getStoreLoadout();
						}, 1500),
						setTimeout(() => {
							getItemInventory();
						}, 2000),
						setTimeout(() => {
							updatePlayerData({ booked_meetings: value });
						}, 2000),
						setAppointmentRequestSent(true),
					);
				}
			});
		}
	}

	return (
		<>
			<div className='button-meeting-modal-container'>
				<Button
					action={() => {
						handleShow(true);
						setFilteredTiles(expertLoadout);
						setAppointmentRequestSent(false);
						setChosenDate(null);
						getPlayerData();
					}}
					classes='primary medium book-a-meeting-button'
					text={acfPageOptions?.acf?.book_a_meeting?.button_label}
				/>
			</div>

			<Modal centered show={show} onHide={handleClose} id='modal-bookmeeting' dialogClassName='modal-bookmeeting'>
				<Modal.Body id='modal-body'>
					{(!expertLoadout || expertLoadout.length === 0) ? (
						<div className='modal-bookmeeting-container'>
							<button type='button' className='close' onClick={() => handleClose()}>
								<img src={acfPageOptions?.acf?.theme?.icon?.general?.exit?.image1?.url} alt='Close' />
							</button>
							<div className='thank-you-message'>
								<span>{acfPageOptions?.acf?.book_a_meeting?.no_availabilities}</span>
							</div>
						</div>
					) : !appointmentRequestSent ? (
						<div className='modal-bookmeeting-container'>
							<div className='modal-bookmeeting-header'>
								<h5>{acfPageOptions?.acf?.book_a_meeting?.modal_title}</h5>
							</div>
							<div className='modal-bookmeeting-body'>
								<form onSubmit={onBookAMeetingSubmit}>
									<Accordion defaultActiveKey='0' flush>
										<Accordion.Item eventKey='0'>
											<Accordion.Header>
												<div id='bookMeetingTopicLabel'>{acfPageOptions?.acf?.book_a_meeting?.select_topic_label}</div>
											</Accordion.Header>
											<Accordion.Body>
												<SelectField
													key='topic'
													name='topic'
													options={topics} //matching the required format for the options array [{value:'', label:''},...]
													value=''
													onChange={(option) => onUpdateValue(option)}
													acfPageOptions={acfPageOptions}
													placeholder={acfPageOptions?.acf?.book_a_meeting?.select_topic_placeholder}
												/>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey='1'>
											<Accordion.Header>
												<div id='bookMeetingDateLabel'>{acfPageOptions?.acf?.book_a_meeting?.select_date_label}</div>
											</Accordion.Header>
											<Accordion.Body>
												{filterUniqueDates(filteredTiles).map((date) => (
													<CustomDate
														eventKey={getDateStyleShort(toLocalTime(date).$d, groupLang)}
														inputValue={getDateStyleShort(toLocalTime(date).$d, groupLang)}
														textValue={getDateStyleLong(toLocalTime(date).$d, groupLang)}
														forValue={'date-' + uuid()}
														acfPageOptions={acfPageOptions}
														date={date}
														groupLang={groupLang}
														key={uuid()}
														setFilteredDates={setFilteredDates}
														filteredDates={filteredDates}
														chosenDate={chosenDate}
														setChosenDate={setChosenDate}
													>
														<span className='dayText'>{getWeekdayShort(toLocalTime(date).$d, groupLang)}</span>
														<span className='dayValue'>{getDayTwoDigits(toLocalTime(date).$d, groupLang)}</span>
													</CustomDate>
												))}
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey='2'>
											<Accordion.Header>
												<div id='bookMeetingTimeLabel'>{acfPageOptions?.acf?.book_a_meeting?.select_time_label}</div>
											</Accordion.Header>
											<Accordion.Body>
												<div className='dateWrapper'>
													{filterUniqueTimeSlots(filteredDates).map((slot) => (
														<CustomTime
															slot={slot}
															acfPageOptions={acfPageOptions}
															eventKey={getTimeStyleShort(toLocalTime(slot).$d, 'en-Us')}
															inputValue={getTimeStyleShort(toLocalTime(slot).$d, 'en-Us')}
															forValue={'time-' + uuid()}
															key={uuid()}
														>
															<span className='timeText'>{getTimeStyleShort(toLocalTime(slot).$d, 'en-Us')}</span>
														</CustomTime>
													))}
												</div>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey='3'>
											<Accordion.Header><div id="text-area-header">{acfPageOptions?.acf?.book_a_meeting?.subject_label}</div></Accordion.Header>
											<Accordion.Body>
												<textarea
													id='text_area_comment'
													placeholder={acfPageOptions?.acf?.book_a_meeting?.subject_placeholder}
												/>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</form>
							</div>
							<div className='modal-bookmeeting-footer'>
								<Button
									action={() => {
										bookMeetingSubmit();
									}}
									classes='primary medium fit'
									text={acfPageOptions?.acf?.book_a_meeting?.send_button_label}
								/>
							</div>
						</div>
					) : (
						<div className='modal-bookmeeting-container'>
							<button type='button' className='close' onClick={() => handleClose()}>
								<img src={acfPageOptions?.acf?.theme?.icon?.general?.exit?.image1?.url} alt='Close' />
							</button>
							<div className='thank-you-message'>
								<span>{acfPageOptions?.acf?.book_a_meeting?.thank_you_message}</span>
							</div>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}

BookAMeeting.propTypes = {
	topics: PropTypes.array,
	expertLoadout: PropTypes.array,
	groupLang: PropTypes.string,
	acfPageOptions: PropTypes.object,
	playfabId: PropTypes.string,
	playerData: PropTypes.object,
	displayName: PropTypes.string,
	BookAppointmentWithExpert: PropTypes.func,
	purchaseTile: PropTypes.func,
	getStoreLoadout: PropTypes.func,
	getItemInventory: PropTypes.func,
	updatePlayerData: PropTypes.func,
	getPlayerData: PropTypes.func,
	booked_meetings: PropTypes.string,
};

BookAMeeting.defaultProps = {
	expertLoadout: [],
	topics: [],
	groupLang: null,
	acfPageOptions: null,
	playfabId: '',
	playerData: null,
	displayName: null,
	BookAppointmentWithExpert: () => {},
	purchaseTile: () => {},
	getStoreLoadout: () => {},
	getItemInventory: () => {},
	updatePlayerData: () => {},
	getPlayerData: () => {},
	booked_meetings: '',
};

export default BookAMeeting;
