import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { Accordion, useAccordionButton } from "react-bootstrap";

function CustomToggle({ children, eventKey }) {
	const decoratedOnClick = useAccordionButton(eventKey, () => {
		return null;
	});

	return (
		<button type="button" onClick={decoratedOnClick}>
			{children}
		</button>
	);
}

CustomToggle.propTypes = {
	eventKey: PropTypes.number,
	children: PropTypes.object,
};

CustomToggle.defaultProps = {
	eventKey: "",
	children: null,
};

function ShowAskQuestions({ faqSingle, eventKey }) {
	const { faq_answer, faq_question } = faqSingle || {};

	return (
		<Accordion className="my-3 faq-single-accordion">
			<Accordion.Item eventKey="0" key={faq_answer} className="border-0">
				<Accordion.Header>{faq_question}</Accordion.Header>
				<Accordion.Body className="faq-content pt-0">
					{parse(faq_answer)}
					<CustomToggle eventKey={eventKey}>
						<i className="position-absolute right-0">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M5.70711 7.79289C5.31658 7.40237 4.68342 7.40237 4.29289 7.79289C3.90237 8.18342 3.90237 8.81658 4.29289 9.20711L5.70711 7.79289ZM12 15.5L11.2929 16.2071L12 16.9142L12.7071 16.2071L12 15.5ZM19.7071 9.20711C20.0976 8.81658 20.0976 8.18342 19.7071 7.79289C19.3166 7.40237 18.6834 7.40237 18.2929 7.79289L19.7071 9.20711ZM4.29289 9.20711L11.2929 16.2071L12.7071 14.7929L5.70711 7.79289L4.29289 9.20711ZM12.7071 16.2071L19.7071 9.20711L18.2929 7.79289L11.2929 14.7929L12.7071 16.2071Z"
									fill="#ABC400"
								/>
							</svg>
						</i>
					</CustomToggle>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}
export default ShowAskQuestions;

ShowAskQuestions.propTypes = {
	faqSingle: PropTypes.object,
	eventKey: PropTypes.number,
};

ShowAskQuestions.defaultProps = {
	faqSingle: {},
	eventKey: null,
};
