import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import logPageView from "utils/logPageView";
import { Container, Row } from "react-bootstrap";
import TopThreeCard from "./TopThreeCard";
import LeaderCard from "./LeaderCard";

function LeaderboardTab({
	generalLeaderboard,
	getLeaderboard,
	getLeaderboardOnPosition,
	playfabId,
	leaderboardOnPosition,
	acfPageOptions,
	playerStatistics,
	leaderboardOnUserPosition,
	playerData, 
}) {
	useEffect(() => {
		getLeaderboard();
		getLeaderboardOnPosition();
	}, []);
	
	useEffect(() => {
		// Log PageView.
		logPageView(
			"leaderboard",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	const nodeRef = React.useRef(null);

	return (
		<CSSTransition in appear timeout={1000} classNames="fade" nodeRef={nodeRef}>
			<Container className="leaderboard-tab">
				<Row className="py-4 align-items-end">
					{generalLeaderboard &&
						generalLeaderboard.length > 0 &&
						generalLeaderboard
							.slice(0, 3)
							.map((user, index) => (
								<TopThreeCard
									user={user}
									position={index}
									key={user.Position}
									pointsLabelShort={acfPageOptions?.acf?.profile?.leaderboard_tab?.points_short_label}
								/>
							))}
				</Row>
				<Row>
					{leaderboardOnPosition &&
						leaderboardOnPosition.length > 0 &&
						(leaderboardOnPosition.length > 5
							? leaderboardOnPosition.slice(3, -2).map((user) => {
								return (
									<LeaderCard
										playfabId={playfabId}
										user={user}
										key={user.Position}
										pointsLabel={acfPageOptions?.acf?.profile?.leaderboard_tab?.points_label}
									/>
								);
							  })
							: leaderboardOnPosition.map((user) => {
								return (
									<LeaderCard
										playfabId={playfabId}
										user={user}
										key={user.Position}
										pointsLabel={acfPageOptions?.acf?.profile?.leaderboard_tab?.points_label}
									/>
								);
							  }))}
				</Row>
			</Container>
		</CSSTransition>
	);
}

LeaderboardTab.propTypes = {
	acfPageOptions: PropTypes.object,
	generalLeaderboard: PropTypes.array,
	getLeaderboard: PropTypes.func,
	playfabId: PropTypes.string,
	getLeaderboardOnPosition: PropTypes.func,
	leaderboardOnPosition: PropTypes.array,
	playerStatistics: PropTypes.object,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
};

LeaderboardTab.defaultProps = {
	acfPageOptions: null,
	generalLeaderboard: [],
	leaderboardOnPosition: [],
	playfabId: "",
	getLeaderboard: () => {},
	getLeaderboardOnPosition: () => {},
	playerStatistics: null,
	leaderboardOnUserPosition: [],
	playerData: null,
};

export default LeaderboardTab;
