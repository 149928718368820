import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import logPageView from "utils/logPageView";
import TechSupport from "mobile/components/containers/techsupport/TechSupport";
import Layout from "mobile/components/layout/Title";

export function Index({
	acfPageOptions,
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
}) {
	const [activeSupport, setActiveSupport] = useState(false);
	const history = useHistory();
	//Show tech-support component
	function openTechSupport() {
		setActiveSupport(true);
	}
	
	useEffect(() => {
		const PATH = location.pathname.split("/")[1];
		if (PATH === "tech-support") openTechSupport();
	}, []);

	useEffect(() => {
		return history.listen(() => {
			const PATH = location.pathname.split("/")[1];
			if (PATH !== "tech-support") {
				setActiveSupport(false);
			} else {
				openTechSupport();
			}
		});
	}, [history]);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"tech-support",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	return (
		<Layout
			titleSlot={acfPageOptions?.acf?.chat_support?.tech_name || "Tech Support"}
			pageClass={`support-page ${activeSupport ? "active" : ""}`}
			headerClass="tech-support-header"
			backText={acfPageOptions?.acf?.chat_support?.back_button}
		>
			<TechSupport />
		</Layout>
	);
}

Index.defaultProps = {
	acfPageOptions: null,
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
};

Index.propTypes = {
	acfPageOptions: PropTypes.object,
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
};
