/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import PropTypes from "prop-types";

function Card({ media, acfPageOptions, setViewedMedia, single }){
	const media_type = media?.acf?.content_gallery?.acf?.media_type || media?.acf?.media_type;
	function MediaIcon() {
		switch (media_type) {
			case "image":
				return (
					<img
						className='gallery-icon'
						src={acfPageOptions?.acf?.theme?.icon?.gallery?.media_type?.image?.sizes?.thumbnail}
						alt='images icon'
					/>
				);
			case "video":
				return (
					<img
						className='gallery-icon'
						src={acfPageOptions?.acf?.theme?.icon?.gallery?.media_type?.video?.sizes?.thumbnail}
						alt='video icon'
					/>
				);
			case "pdf":
				return (
					<img
						className='gallery-icon'
						src={acfPageOptions?.acf?.theme?.icon?.gallery?.media_type?.pdf?.sizes?.thumbnail}
						alt='pdf icon'
					/>
				);
			default: return null;
		}
	}

	function goToLink(url) {
		const urlCleaned = url.replaceAll(" ", "").replaceAll(" ", "");
		return window.open(urlCleaned)
	}

	function mediaAction() {
		if (media_type === "pdf") {
			goToLink(media?.acf?.content_gallery?.acf?.pdf_link || media?.acf?.pdf_link);

			window.dataLayer.push({
				'event': 'generic_event',
				'eventCategory': 'pdf',
				'eventAction': 'download',
				'eventLabel': media?.acf?.content_gallery?.post_title || media?.post_title,
			});

		} else {
			setViewedMedia(media);
		}
	}

	return (
		<div className={`gallery-card-container col-lg-6 col-xl-3 p-2 ${single ? 'single-card' : ''}`}>
			<div
				tabIndex='-1'
				role='button'
				className='gallery-card'
				onClick={() => mediaAction()}
				onKeyPress={() => mediaAction()}
			>
				<div className='top-card'>
					<MediaIcon />
					<div className='image-wrap'>
						<img
							src={media?.acf?.content_gallery?.acf?.preview_image?.sizes?.large || media?.acf?.preview_image?.sizes?.large}
							alt=''
						/>
					</div>
				</div>
				<div className='text-ctn'>
					<p className='title'>{media?.acf?.content_gallery?.post_title || media?.post_title}</p>
				</div>
			</div>
		</div>
	);
};

Card.defaultProps = {
	media: null,
	acfPageOptions: null,
	setViewedMedia: () => {},
	single: false,
};

Card.propTypes = {
	media: PropTypes.object,
	acfPageOptions: PropTypes.object,
	setViewedMedia: PropTypes.func,
	single: PropTypes.bool,
};

export default Card;
