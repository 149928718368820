import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ActivityRoom from 'components/Activity/ActivityRoom';
import { ADMINSTAT } from 'Constants';

import {
	chatJoinRoom, chatLeaveRoom, clearMessages, getQuestions,
} from 'redux/chat';
import {
	getMeetingStatus, writePlayerEvent, writeTelemetryEvent, getJitsiJwt,
} from 'redux/playfab';
import { myAgendaActivitySelector } from 'selector/myAgenda';
import { playerGroupSelector, streamURLSelector, userNameSelector } from 'selector/player';
import { emptyVideoPlayer, setTranslation, setTileId } from 'redux/video_bubble';
import { translationSelector } from 'selector/catalog';
import { setHasAttended, setWasLate, setLeftEarly } from 'redux/tracking';
import { statSelector, statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	const activity = myAgendaActivitySelector(state, props);
	return {
		playerStatistics: statsSelector(state, props),
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		isCatalogLoaded: Boolean(state.playfab.Catalog),
		activity,
		username: userNameSelector(state),
		playfabId: state.playfab?.PlayFabId,
		prerecordedStreamURL: streamURLSelector(state, { activity }),
		joinMeetingURL: activity?.StoreTileId,
		translations: translationSelector(state),
		hasAttended: state.tracking?.hasAttended,
		wasLate: state.tracking?.wasLate,
		bubble_translation: state.video_bubble?.selectedTranslation,
		leftEarly: state.tracking?.leftEarly,
		video_bubble: state.video_bubble,
		playerGroup: playerGroupSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		isAdmin: statSelector(state, { statName: 'admin' }) === ADMINSTAT.ADMIN,
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		chatJoinRoom,
		chatLeaveRoom,
		getMeetingStatus,
		clearMessages,
		writePlayerEvent,
		writeTelemetryEvent,
		getQuestions,
		setTranslation,
		emptyVideoPlayer,
		getJitsiJwt,
		setHasAttended,
		setWasLate,
		setLeftEarly,
		setTileId,
	}, dispatch)
)(ActivityRoom));
