import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { expertTileTopicSelector, expertTileSelector } from 'selector/bookAMeeting';
import { playerLangGroupSelector, userNameSelector } from 'selector/player';
import BookAMeeting from 'components/BookAMeeting/BookAMeeting';
import { BookAppointmentWithExpert } from 'redux/bookAMeeting';
import { purchaseTile, getStoreLoadout, getItemInventory, updatePlayerData, getPlayerData } from 'redux/playfab';

function mapStateToProps(state) {
	return {
		expertLoadout: expertTileSelector(state),
		topics: expertTileTopicSelector(state, { customPayloadKey: 'topic_description' }) || [],
		groupLang: playerLangGroupSelector(state),
		acfPageOptions: state.wpcontent.acfPageOptions,
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		displayName: userNameSelector(state),
		booked_meetings: state.playfab?.PlayerData?.booked_meetings?.Value,
	};
}

export default connect(mapStateToProps, (dispatch) => bindActionCreators({BookAppointmentWithExpert, purchaseTile, getStoreLoadout, getItemInventory, updatePlayerData, getPlayerData}, dispatch))(BookAMeeting);
