/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import parse from "html-react-parser";
import Button from "components/Buttons/Button";
import LoadingGif from "components/LoadingGif";
import { ROUTES, REACT_APP_LIGHTSPEED_CLIENT } from "Constants";
import {
	now,
	timeFromNow,
	getDayTwoDigits,
	getTimeStyleShort,
	getWeekdayLong,
	getMonthLong,
	getWeekdayShort,
	getMonthShort,
} from "utils/dateUtils";

function ChannelTile({
	id,
	stock,
	small,
	withDate,
	link,
	activity,
	className,
	purchaseTile,
	writePlayerEvent,
	writeTelemetryEvent,
	getItemInventory,
	acfPageOptions,
	onMouseEnter,
	onMouseLeave,
	getStoreLoadout,
	groupLang,
	isAdmin,
}) {
	if (!activity) return null;

	const history = useHistory();
	const startString = activity.date_begin.isBefore(now())
		? acfPageOptions?.acf?.activity_card_started
		: acfPageOptions?.acf?.activity_card_starts;
	const endString = activity.date_end.isAfter(now())
		? acfPageOptions?.acf?.activity_card_ends
		: acfPageOptions?.acf?.activity_card_ended;
	const activityType = activity.data.activity_type;
	const dayNum = getDayTwoDigits(activity.date_begin.$d, groupLang);
	const dayString = getWeekdayLong(activity.date_begin.$d, groupLang);
	const dayShortString = getWeekdayShort(activity.date_begin.$d, groupLang);
	const monthString = getMonthLong(activity.date_begin.$d, groupLang);
	const monthStringString = getMonthShort(activity.date_begin.$d, groupLang);
	const fullTimeStart = getTimeStyleShort(activity.date_begin.$d, "en-US");
	const fullTimeEnd = getTimeStyleShort(activity.date_end.$d, "en-US");
	const isPast = activity.date_end.isBefore(now());
	const [loading, setLoading] = useState(false);

	function unregister() {
		setLoading(true);
		writePlayerEvent({
			name: "player_unsubscribed_activity",
			body: {
				ItemInstanceId: activity.item_instance_id,
			},
		}).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 2000),

			setTimeout(() => {
				getItemInventory();
			}, 2300),

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "remove from agenda",
				eventAction: activity?.content?.title?.rendered,
			})
		);
	}

	function purchase() {
		setLoading(true);
		purchaseTile(id).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 500),

			setTimeout(() => {
				getItemInventory();
			}, 1000),

			//Engagement event on arriving
			writeTelemetryEvent({
				namespace: "custom.engagement",
				name: "subscribe",
				body: {
					activity: activity?.content?.title?.rendered,
					StoreTileId: activity.StoreTileId,
				},
			}),

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "add from agenda",
				eventAction: activity?.content?.title?.rendered,
			})
		);
	}

	function requiredCheck(activity_id) {
		let isRequired = false;
		const granted_items = acfPageOptions.acf?.global?.granted_items;
		if (acfPageOptions.acf?.global?.granted_items) {
			for (let i = 0; i < granted_items?.length; i += 1) {
				if (granted_items[i].tile_id === activity_id.StoreTileId) {
					isRequired = true;
					break;
				}
			}
		}
		return isRequired;
	}

	useEffect(() => {
		if (loading) {
			return setLoading(false);
		}
		return null;
	}, [activity.canPurchase, activity.canUnregister]);

	function renderStock() {
		if (stock === null) return null;
		const pluralPhrasing = `${stock} ${acfPageOptions.acf?.activity_card_places_left}`;
		const singularPhrasing = `${stock} ${acfPageOptions.acf?.activity_card_place_left}`;

		return stock > 0 ? (
			<div className="stock">{stock > 1 ? pluralPhrasing : singularPhrasing}</div>
		) : (
			<div className="stock">{acfPageOptions.acf?.activity_card_no_place_left}</div>
		);
	}

	function renderPartners() {
		if (!activity.content?.acf?.host) return null;
		return <span className="hostedby">{parse(activity.content.acf.host)}</span>;
	}

	function renderDate() {
		if (small) {
			return (
				<div className="date">
					<div className="day">
						<h4 className="dayNum">{dayNum}</h4>
						<div className="day-month-container">
							<span className="dayWeek">{dayString.toUpperCase()}</span>
							<span className="monthString">{monthString.toUpperCase()}</span>
						</div>
					</div>
					<div className="hours">
						<span className="bigNum">{fullTimeStart}</span>
					</div>
				</div>
			);
		}
		if (withDate) {
			return (
				<div className="date">
					<div className="day">
						<h4 className="dayNum">{dayNum}</h4>
						<div className="day-month-container">
							<span className="dayWeek">{dayShortString.toUpperCase()}</span>
							<span className="monthString">{monthStringString.toUpperCase()}</span>
						</div>
					</div>
					<div className="time align-items-end">
						<h4>{fullTimeStart}</h4>
					</div>
				</div>
			);
		}
		return (
			<div className="date">
				{/* <div className="day">
					<span className="dayNum bigNum">
						{dayNum}
					</span>
					<div className="day-month-container">
						<span className="dayWeek">{dayString}</span>
						<span className="monthString">{monthString}</span>
					</div>
				</div>
				<div className="hours">
					<span className="bigNum">{hourNum}</span>
					<span className="hour-label">h</span>
					<span className="bigNum minutes">{MinNum}</span>
					<span className="cycle-label">{cycle}</span>
				</div> */}
				<div className="time">
					<div className="start">
						<span>{fullTimeStart}</span>
					</div>
					<span className="separator">-</span>
					<div className="end">
						<span>{fullTimeEnd}</span>
					</div>
				</div>
			</div>
		);
	}

	function triggerClickEvent(eventLink, eventName) {
		window.dataLayer.push({
			event: "generic_event",
			eventCategory: "event",
			eventAction: "enter event",
			eventLabel: eventName,
		});

		history.push(eventLink);
	}

	function renderLink() {
		if (!link) return null;

		return <Link to={ROUTES.EVENT.replace(":tileId", id)} className="overlay-link" />;
	}

	function renderStockRelatedButtons() {
		if (isPast) return null;

		return (
			<>
				{activity.canPurchase && !activity.canJoin && !requiredCheck(activity) && (
					<Button
						action={() => purchase()}
						classes="secondary large add-to-agenda"
						icon={acfPageOptions.acf?.theme?.icon?.activity_card?.agenda?.add?.sizes?.thumbnail}
						text={acfPageOptions?.acf?.activity_card_add_to_agenda}
						orientation="right"
					/>
				)}
				{activity.canUnregister && !activity.canJoin && !requiredCheck(activity) && (
					<Button
						action={() => unregister()}
						classes="secondary large remove-from-agenda"
						icon={acfPageOptions.acf?.theme?.icon?.activity_card?.agenda?.remove?.sizes?.thumbnail}
						text={acfPageOptions?.acf?.activity_card_remove_from_agenda}
						orientation="right"
					/>
				)}

				{renderStock()}
			</>
		);
	}

	const pastClass = isPast ? "past" : "";
	const smallClass = small ? "small" : "";
	const withDateClass = withDate ? "withDate" : "";
	const teamsPrivateClass = activityType === "TeamsPrivateRoom" ? "teams-private" : "";

	const getColorClass = (tileColor) => {
		switch (tileColor) {
			case "1":
				return "primary-gradient-background";
			case "2":
				return "secondary-gradient-background";
			case "3":
				return "tertiary-gradient-background";
			default:
				return "primary-gradient-background";
		}
	};

	const colorClass = getColorClass(activity?.colorCode);

	return (
		<div
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={`activity-card agenda-activity-card ${colorClass} ${smallClass} ${withDateClass} ${pastClass} ${teamsPrivateClass} ${className}`}
		>
			<div className="infos">
				<div className="info-top-content">
					<div className="event-text-info">
						<div className="agenda-only">{renderPartners()}</div>
						<h4 className="tile-title">
							{activity?.content?.title?.rendered && parse(activity?.content?.title?.rendered)}
						</h4>
						<span className="instructions">{acfPageOptions.acf?.activity_card_instructions}</span>
					</div>
					{loading ? (
						<div className="loading-text">
							<span>{acfPageOptions?.acf?.activity_card_loading}</span>
							<LoadingGif
								acfPageOptions={acfPageOptions}
								status={loading ? "active" : ""}
								cardLoader={loading ? "card-loader" : ""}
							/>
						</div>
					) : (
						!REACT_APP_LIGHTSPEED_CLIENT &&<div id="activity-buttons" className="event-add-btn">
							{renderStockRelatedButtons()}
						</div>
					)}
				</div>
				{/* {activity?.content?.acf?.icon?.sizes?.thumbnail && (
					<div className="icon-ctn">
						<img src={activity?.content?.acf?.icon?.sizes?.thumbnail} alt="" />
					</div>
				)} */}
				<div className="tile-bottom">
					<div className="days-action">
						<div className="remaining">
							{activity.date_begin.isValid() &&
							!activity.date_begin.isBefore(now()) ? (
									<div>{`${startString} ${timeFromNow(
										activity.date_begin
									)}`}</div>
								) : (
									activity.date_end.isValid() && (
										<div>{`${endString} ${timeFromNow(activity.date_end)}`}</div>
									)
								)}
						</div>
					</div>

					<div className="d-flex flex-row justify-content-between w-100 align-items-center">
						{renderDate()}
						<div className="actions">
							<div className="actions-wrapper">
								{(activity.canJoin || isAdmin) && (
									<Button
										action={() =>
											triggerClickEvent(
												ROUTES.ACTIVITY.replace(":tileId", id),
												activity?.content?.title?.rendered
											)
										}
										classes="primary large bold"
										icon={
											acfPageOptions?.acf?.theme?.icon?.activity_card?.join
												?.image_1?.sizes?.thumbnail
										}
										icon2={
											acfPageOptions?.acf?.theme?.icon?.activity_card?.join
												?.image_2?.sizes?.thumbnail
										}
										text={acfPageOptions.acf?.activity_card_join_now}
									/>
								)}
								{renderLink()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

ChannelTile.defaultProps = {
	id: "",
	stock: null,
	small: false,
	withDate: false,
	link: false,
	activity: {},
	className: null,
	acfPageOptions: null,
	purchaseTile: () => {},
	writePlayerEvent: () => {},
	writeTelemetryEvent: () => {},
	onMouseEnter: () => {},
	onMouseLeave: () => {},
	getStoreLoadout: () => {},
	groupLang: null,
	isAdmin: false,
};

ChannelTile.propTypes = {
	id: PropTypes.string,
	stock: PropTypes.number,
	small: PropTypes.bool,
	withDate: PropTypes.bool,
	link: PropTypes.bool,
	className: PropTypes.string,
	activity: PropTypes.object,
	acfPageOptions: PropTypes.object,
	purchaseTile: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	getStoreLoadout: PropTypes.func,
	getItemInventory: PropTypes.func.isRequired,
	groupLang: PropTypes.string,
	isAdmin: PropTypes.bool,
};

export default ChannelTile;
