import React, { useRef, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { toLocalTime, getTimeStyleShort } from "utils/dateUtils";
import { PLAYFAB_EVENTS } from "Constants";
import { SessionContext } from "mobile/context/SessionContext";
import WelcomeMessage from "../WelcomeMessage";
import SendMessageInput from "../SendMessageInput";
import Message from "../../containers/companel/Message";

function Index({
	channel,
	messages,
	displayName,
	broadcastMessage,
	ownPlayfabId,
	session,
	setHasChatted,
	hasChatted,
	writePlayerEvent,
	writeTelemetryEvent,
	acfPageOptions,
	clearNewMessages,
	messageAuthors,
	newMessages,
	stateVideo,
}) {
	const msgEnd = useRef(null);
	const messageWrapper = useRef(null);
	const chatInput = useRef(null);
	const [showInput, setShowInput] = useState(false);
	const [inputMessage, setInputMessage] = useState("");
	const [isScrollable, setIsScrollable] = useState(false);
	const { minimizeToggle, setMinimizeToggle } = useContext(SessionContext);

	useEffect(() => {
		if (showInput) {
			chatInput.current.focus();
		}
	}, [showInput]);

	function sendMessage(e) {
		e.preventDefault();

		if (inputMessage !== "") {
			if (!hasChatted.includes(session?.StoreTileId)) {
				writePlayerEvent({
					name: PLAYFAB_EVENTS.player_meeting_event,
					body: {
						HasChatted: 1,
						RoomId: session?.StoreTileId,
						Type: "message",
					},
				});
				setHasChatted(session?.StoreTileId);
			}

			broadcastMessage({
				type: "userMessage",
				message: inputMessage,
				username: displayName,
				channel,
				playfabId: ownPlayfabId,
			});

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "chat",
				eventAction: "message sent",
				eventLabel: inputMessage,
			});

			writeTelemetryEvent({
				namespace: "custom.chatlogs",
				name: "message",
				body: {
					playfabid: ownPlayfabId,
					chatroomid: session?.StoreTileId,
					chatroom: session?.content?.title?.rendered,
					displayname: displayName,
					message: inputMessage,
				},
			});
		}

		setInputMessage("");
		setTimeout(() => {
			if (document.getElementById("input")) {
				document.getElementById("input").focus();
			}
		}, 500);
	}

	function scrollToBottom() {
		setTimeout(() => {
			msgEnd.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		}, 100);
		clearNewMessages();
	}

	function canScroll() {
		if (messageWrapper?.current) {
			const { scrollTop, scrollHeight, clientHeight } = messageWrapper.current;
			if (scrollHeight - clientHeight - scrollTop < 250) {
				scrollToBottom();
				setIsScrollable(true);
			} else {
				setIsScrollable(false);
			}
		}

		return null;
	}

	return (
		<div className="d-flex flex-column mobile-chat mt-5" ref={messageWrapper}>
			<div className="messages-list">
				{messages.length > 0
					? messages.slice(-30).map((m) => {
						return (
							<Message
								channel={channel}
								id={m.id}
								key={m.id}
								displayName={displayName}
								type="chatMessage"
								canScroll={() => canScroll()}
								session={session}
							/>
						);
					  })
					: stateVideo !== "ended" && (
						<WelcomeMessage message={acfPageOptions?.acf?.live_chat_panel?.be_first_to_start_chat} />
					  )}
				{stateVideo === "ended" && (
					<div className="mx-4 my-3">
						<span className="chat-closed pb-2">
							{getTimeStyleShort(toLocalTime(session.date_end).$d, "en-US")} -
							{acfPageOptions?.acf?.live_chat_panel?.chat_closed}
						</span>
						{/* TODO: UNCOMMENT CODE WHEN MODERATED ROOM ARE CREATED */}
						{/* <div className="join-moderated-room d-flex flex-column">
							<span className="my-2">Discussion to be pursued</span>
							<button
								type="button"
								className="d-flex align-items-center justify-content-center p-2 "
							>
								JOIN MODERATED ROOM
							</button>
						</div> */}
					</div>
				)}
				<div style={{ paddingTop: 60 }} ref={msgEnd} />
			</div>
			{!isScrollable && newMessages > 0 && messages.length > 4 && (
				<button
					className="new-messages-btn p-2"
					type="button"
					onClick={() => scrollToBottom()}
				>{`${newMessages} NEW ${
						newMessages > 1 ? "MESSAGES" : "MESSAGE"
					}`}</button>
			)}
			{!minimizeToggle && stateVideo !== "ended" && (
				<SendMessageInput
					inputMessage={inputMessage}
					setInputMessage={setInputMessage}
					messageAuthors={messageAuthors}
					placeHolder={acfPageOptions?.acf?.live_chat_panel?.type_a_message_placeholder}
					formAction={(e) => sendMessage(e)}
					disabled={stateVideo === "ended"}
				/>
			)}
		</div>
	);
}

Index.defaultProps = {
	channel: null,
	messages: [],
	displayName: null,
	ownPlayfabId: "",
	broadcastMessage: () => {},
	session: null,
	hasChatted: null,
	setHasChatted: () => {},
	writePlayerEvent: () => {},
	writeTelemetryEvent: () => {},
	clearNewMessages: null,
	acfPageOptions: {},
	messageAuthors: null,
	newMessages: 0,
	stateVideo: null,
};

Index.propTypes = {
	channel: PropTypes.string,
	messages: PropTypes.array,
	displayName: PropTypes.string,
	broadcastMessage: PropTypes.func,
	ownPlayfabId: PropTypes.string,
	session: PropTypes.object,
	hasChatted: PropTypes.array,
	setHasChatted: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	clearNewMessages: PropTypes.func,
	acfPageOptions: PropTypes.object,
	messageAuthors: PropTypes.array,
	newMessages: PropTypes.number,
	stateVideo: PropTypes.string,
};

export default Index;