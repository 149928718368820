import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Auth from 'components/Auth';
import { setJWT, setLoginMethod, setAuthStep } from 'redux/auth';
import { fetchTeamsData } from 'redux/teams';
import { writeTelemetryEvent, authPlayfab } from 'redux/playfab';

function mapStateToProps(state) {
	return {
		userObjectId: state.teams.userObjectId,
		playerId: state.playfab?.PlayFabId,
		userJWT: state.auth.userJWT,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		setJWT,
		setAuthStep,
		writeTelemetryEvent,
		authPlayfab,
		fetchTeamsData,
		setLoginMethod,
	}, dispatch)
)(Auth);
