/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import React, { memo, useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import ChatMessage from "containers/Chat/ChatMessage";
import { PLAYFAB_EVENTS, COMPANEL_TABS } from "Constants";
import ChatBorder from "components/ComPanel/Chat/ChatBorder";
import ToggleSwitch from "components/Buttons/ToggleSwitch";
import ChatZeroState from "components/ComPanel/Chat/ChatZeroState";
import { ComPanelContext } from "components/ComPanel/Context/ComPanelContext";
import Mentions from "components/ComPanel/Chat/Mentions";

function Chat({
	channel,
	messages,
	displayName,
	broadcastMessage,
	ownPlayfabId,
	canChat,
	activity,
	setHasChatted,
	hasChatted,
	writePlayerEvent,
	writeTelemetryEvent,
	acfPageOptions,
	activeTab,
	setActiveTab,
	partnerStatus,
	showPartnerStatus,
	partnerName,
	clearNewMessages,
	messageAuthors,
}) {
	const msgEnd = useRef(null);
	const chatInput = useRef(null);
	const messageContainer = useRef(null);
	const liveChatPanel = acfPageOptions?.live_chat_panel;
	const icons = acfPageOptions?.theme?.icon;

	const { inputMessage, setInputMessage } = useContext(ComPanelContext);

	const [showInput, setShowInput] = useState(false);
	const [sendAsQuestion, setSendAsQuestion] = useState(false);
	const [firstVisit, setFirstVisit] = useState(true);

	useEffect(() => {
		if (showInput) {
			chatInput.current.focus();
		}
	}, [showInput]);

	useEffect(() => {
		clearNewMessages();
	}, []);

	const NotificationPoint = () => {
		return <div className="notification-point" />;
	};

	function scrollToBottom() {
		msgEnd.current.scrollIntoView({ behavior: "instant", block: "nearest" });
		clearNewMessages();
	}

	function sendMessage(e) {
		e.preventDefault();

		if (inputMessage !== "") {
			if (!hasChatted.includes(activity?.StoreTileId)) {
				writePlayerEvent({
					name: PLAYFAB_EVENTS.player_meeting_event,
					body: {
						HasChatted: 1,
						RoomId: activity?.StoreTileId,
						Type: "message",
					},
				});
				setHasChatted(activity?.StoreTileId);
			}

			broadcastMessage({
				type: "userMessage",
				message: inputMessage,
				username: displayName,
				channel,
				playfabId: ownPlayfabId,
			});

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "chat",
				eventAction: "message sent",
				eventLabel: inputMessage,
			});

			writeTelemetryEvent({
				namespace: "custom.chatlogs",
				name: "message",
				body: {
					playfabid: ownPlayfabId,
					chatroomid: activity?.StoreTileId,
					chatroom: activity?.content?.title?.rendered,
					displayname: displayName,
					message: inputMessage,
				},
			});
		}

		setInputMessage("");
		setTimeout(() => {
			if (document.getElementById("input")) {
				document.getElementById("input").focus();
			}
		}, 500);
	}

	function canScroll() {
		if (messageContainer?.current) {
			const { scrollTop, scrollHeight } = messageContainer.current;

			return scrollTop >= scrollHeight / 3;
		}

		return false;
	}

	useEffect(() => {
		if ((activeTab === "chat") || firstVisit) {
			setFirstVisit(false);
			scrollToBottom();
		}
	}, [messages]);

	const PartnerInfo = () => {
		return (
			<>
				<div className="partner-status-container">
					<h4>
						{partnerName} {acfPageOptions.partner?.status?.label}
						<div
							className={`status-container ${
								partnerStatus ? "online-status" : "offline-status"
							}`}
						>
							{partnerStatus ? (
								<>
									{acfPageOptions?.partner?.status?.online}{" "}
									<NotificationPoint />
								</>
							) : (
								acfPageOptions?.partner?.status?.offline
							)}
						</div>
					</h4>
				</div>
				<ChatBorder />
			</>
		);
	};

	function roomTypeValidation() {
		if (activity.data?.activity_type !== "MeetingRoom") setShowInput(true);
	}

	return (
		<div className="chat">
			<div className="chat-messages-container">
				<div className="messages-list" ref={messageContainer}>
					{showPartnerStatus && partnerStatus && <PartnerInfo />}
					{messages.length > 0 ? (
						messages.slice(-30).map((m) => {
							return (
								<ChatMessage
									channel={channel}
									id={m.id}
									key={m.id}
									displayName={displayName}
									partnerName={partnerName}
								/>
							);
						})
					) : (
						<ChatZeroState liveChatPanel={liveChatPanel} icons={icons} />
					)}
					<div ref={msgEnd} />
				</div>
				{canChat && (
					<>
						<ChatBorder />
						<form className="controls" onSubmit={sendMessage}>
							<div className="form-container">
								<div
									className={`top-container ${
										showInput && !showPartnerStatus ? "" : "hide-input"
									}`}
								>
									<input
										type="text"
										name="text"
										className="input"
										placeholder="Type your message"
										onChange={(e) => {
											setInputMessage(e.target.value);
										}}
										value={inputMessage}
										autoComplete="off"
										ref={chatInput}
									/>
								</div>
								<div className="bottom-container">
									{!showInput || showPartnerStatus ? (
										<>
											<input
												type="text"
												name="text"
												className="input"
												placeholder="Type your message"
												onChange={(e) => {
													setInputMessage(e.target.value);
												}}
												value={inputMessage}
												id="input"
												tabIndex="-1"
												autoComplete="off"
												onFocus={() => roomTypeValidation()}
											/>
											<Mentions
												messageAuthors={messageAuthors}
												inputMessage={inputMessage}
												setInputMessage={setInputMessage}
											/>
										</>
									) : (
										!showPartnerStatus && (
											<div className="toggle-container">
												<div className="toggle-item">
													<ToggleSwitch
														checked={sendAsQuestion}
														onChange={() => {
															setSendAsQuestion(!sendAsQuestion);
															setActiveTab(COMPANEL_TABS.QA);
														}}
													/>
													<label htmlFor="ask-live">
														{liveChatPanel?.toggle_send_question}
													</label>
												</div>
											</div>
										)
									)}
									<div className="icons-container">
										<button
											type="submit"
											aria-label="Send Button"
											className="send-button"
										>
											<img src={icons?.live?.send?.url} alt="" />
										</button>
									</div>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
		</div>
	);
}

Chat.defaultProps = {
	channel: null,
	messages: [],
	displayName: null,
	ownPlayfabId: "",
	broadcastMessage: () => {},
	canChat: false,
	activity: null,
	hasChatted: null,
	setHasChatted: () => {},
	writePlayerEvent: () => {},
	writeTelemetryEvent: () => {},
	clearNewMessages: null,
	partnerStatus: false,
	showPartnerStatus: false,
	partnerName: "",
	acfPageOptions: {},
	activeTab: null,
	setActiveTab: () => {},
	messageAuthors: null,
};

Chat.propTypes = {
	channel: PropTypes.string,
	messages: PropTypes.array,
	displayName: PropTypes.string,
	broadcastMessage: PropTypes.func,
	canChat: PropTypes.bool,
	ownPlayfabId: PropTypes.string,
	activity: PropTypes.object,
	hasChatted: PropTypes.array,
	setHasChatted: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	clearNewMessages: PropTypes.func,
	partnerStatus: PropTypes.bool,
	showPartnerStatus: PropTypes.bool,
	partnerName: PropTypes.string,
	acfPageOptions: PropTypes.object,
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func,
	messageAuthors: PropTypes.array,
};

export default memo(Chat);
