import React from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import BackButton from 'mobile/components/buttons/BackButton';
import LoadingInnerContent from "containers/LoadingInnerContent";

function Layout({
	isLoading,
	titleSlot,
	pageClass,
	showBack,
	backLink,
	backText,
	//rightSlot, // TODO: Remove?
	children,
	backgroundUrl,
	headerClass,
}) {
	const customBackground = {
		background: `url(${backgroundUrl.url})`,
		backgroundSize: 'cover',
	};

	function renderHeader() {
		if (titleSlot || showBack) {
			return (
				<header className={`mobile-page-header ${headerClass}`}>
					<Container fluid>
						<div className="header-row d-flex align-items-center justify-content-between">
							{showBack && (
								<div className="left back-button px-0">
									<BackButton link={backLink} localBack={backText} />
								</div>
							)}
							{titleSlot && (
								<div className="right page-title text-end text-uppercase">
									<span className="title">{titleSlot}</span>
								</div>
							)}
						</div>
					</Container>
				</header>
			);
		}
		return null;
	}
	return (
		<LoadingInnerContent isLoading={isLoading} className="loadingWrapper">
			<div className={`mobile-page-wrapper ${pageClass} mobile-layout-title`}>
				<div className="background" style={backgroundUrl ? customBackground: {}} />
				{renderHeader()}
				{children && (
					<main className="mobile-page-content">{children}</main>
				)}
			</div>
		</LoadingInnerContent>
	);
}

Layout.defaultProps = {
	titleSlot: null,
	showBack: true,
	backLink: null,
	backText: null,
	//rightSlot: null,
	isLoading: false,
	backgroundUrl: '',
	headerClass: '',
};

Layout.propTypes = {
	pageClass: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	titleSlot: PropTypes.string,
	showBack: PropTypes.bool,
	backLink: PropTypes.string,
	backText: PropTypes.string,
	//rightSlot: PropTypes.any,
	isLoading: PropTypes.bool,
	backgroundUrl: PropTypes.string,
	headerClass: PropTypes.string,
};
export default Layout;
