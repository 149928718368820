/** @format */
import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import parse from "html-react-parser";
import LoadingGif from "components/LoadingGif";
import { ROUTES } from "Constants";
import {
	now,
	timeFromNow,
	getDayTwoDigits,
	getTimeStyleShort,
	getWeekdayLong,
	getMonthLong,
	getWeekdayShort,
	getMonthShort,
} from "utils/dateUtils";

import { ReactComponent as CalendarAddIcon } from "assets/icons/pack1/bottom-navigation-bar/icon-calendar-add.svg";
import { ReactComponent as CalendarRemoveIcon } from "assets/icons/pack1/bottom-navigation-bar/icon-calendar-remove.svg";

import { ReactComponent as InPersonIcon } from "assets/icons/pack1/agenda/attendance-type/icon-in-person.svg";
import { ReactComponent as PrivateIcon } from "assets/icons/pack1/agenda/attendance-type/icon-private.svg";
import { ReactComponent as PublicIcon } from "assets/icons/pack1/agenda/attendance-type/icon-public.svg";
import { ReactComponent as VirtualIcon } from "assets/icons/pack1/agenda/attendance-type/icon-virtual.svg";

import CardModal from "./CardModal";
import AttendanceModal from "../containers/session/AttendanceModal";
import Button from "../buttons/Button";

function SessionCard({
	id,
	stock,
	link,
	activity,
	className,
	purchaseTile,
	writePlayerEvent,
	writeTelemetryEvent,
	getItemInventory,
	acfPageOptions,
	onMouseEnter,
	onMouseLeave,
	getStoreLoadout,
	groupLang,
	isAdmin,
	isDesktop,
}) {
	if (!activity) return null;
	const ref = useRef();

	const history = useHistory();
	const startString = activity.date_begin.isBefore(now())
		? acfPageOptions?.acf?.activity_card_started
		: acfPageOptions?.acf?.activity_card_starts;
	const endString = activity.date_end.isAfter(now())
		? acfPageOptions?.acf?.activity_card_ends
		: acfPageOptions?.acf?.activity_card_ended;
	const activityType = activity.data.activity_type;
	const dayNum = getDayTwoDigits(activity.date_begin.$d, groupLang);
	const dayString = getWeekdayLong(activity.date_begin.$d, groupLang);
	const dayShortString = getWeekdayShort(activity.date_begin.$d, groupLang);
	const monthString = getMonthLong(activity.date_begin.$d, groupLang);
	const monthStringString = getMonthShort(activity.date_begin.$d, groupLang);
	const fullTimeStart = getTimeStyleShort(activity.date_begin.$d, "en-US");
	const fullTimeEnd = getTimeStyleShort(activity.date_end.$d, "en-US");
	const isPast = activity.date_end.isBefore(now());
	const isFuture = activity.date_begin.isAfter(now());
	const isNow = now().isBetween(activity.date_begin, activity.date_end);

	const [loading, setLoading] = useState(false);

	const [eventModal, setEventModal] = useState(false);
	const [attendanceModal, setAttendanceModal] = useState(false);

	function unregister() {
		setLoading(true);
		writePlayerEvent({
			name: "player_unsubscribed_activity",
			body: {
				ItemInstanceId: activity.item_instance_id,
			},
		}).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 2000),

			setTimeout(() => {
				getItemInventory();
			}, 2300),

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "remove from agenda",
				eventAction: activity?.content?.title?.rendered,
			})
		);
	}

	function purchase() {
		setLoading(true);
		purchaseTile(id).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 500),

			setTimeout(() => {
				getItemInventory();
			}, 1000),

			//Engagement event on arriving
			writeTelemetryEvent({
				namespace: "custom.engagement",
				name: "subscribe",
				body: {
					activity: activity?.content?.title?.rendered,
					StoreTileId: activity.StoreTileId,
				},
			}),

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "add from agenda",
				eventAction: activity?.content?.title?.rendered,
			}),
		);
	}

	function requiredCheck(activity_id) {
		let isRequired = false;
		const granted_items = acfPageOptions.acf?.global?.granted_items;
		if (acfPageOptions.acf?.global?.granted_items) {
			for (let i = 0; i < granted_items?.length; i += 1) {
				if (granted_items[i].tile_id === activity_id.StoreTileId) {
					isRequired = true;
					break;
				}
			}
		}
		return isRequired;
	}

	useEffect(() => {
		if (loading) {
			return setLoading(false);
		}
		return null;
	}, [activity.canPurchase, activity.canUnregister]);

	function renderStock() {
		if (stock === null) return null;

		return stock > 0 ? (
			<div className="stock">{acfPageOptions?.acf?.event?.limited_stock}</div>
		) : (
			<div className="stock bold">{acfPageOptions?.acf?.event?.fully_booked}</div>
		);
	}

	function renderPartners() {
		if (!activity.content?.acf?.host) return null;
		return (
			<div className="hostedby text-white">
				{parse(activity.content.acf.host)}
			</div>
		);
	}

	function renderDate() {
		return (
			<div className="session-date">
				<div className="day">
					<h4 className="dayNum">{dayNum}</h4>
					<div className="day-month-container">
						<span className="dayWeek">{dayShortString.toUpperCase()}</span>
						<span className="monthString">
							{monthStringString.toUpperCase()}
						</span>
					</div>
				</div>
				<div className="time align-items-end">
					<h4>
						<span>{acfPageOptions?.acf?.event?.date?.from}</span> {fullTimeStart}
					</h4>
					<h4>
						<span>{acfPageOptions?.acf?.event?.date?.to}</span> {fullTimeEnd}
					</h4>
				</div>
			</div>
		);
	}

	function renderTime() {
		if (isFuture)
			return (
				<div className="time-left">
					<p>
						{acfPageOptions?.acf?.event?.time?.in}{" "}
						{`${timeFromNow(activity.date_end)}`}!
					</p>
				</div>
			);
		if (isNow)
			return (
				<div className="time-left">
					<p>{acfPageOptions?.acf?.event?.time?.now}</p>
				</div>
			);
		return null;
	}

	function openEventModal() {
		setEventModal(true);
	}

	function triggerClickEvent() {
		if(localStorage.getItem("hasDefaultAttendanceType")){
			history.push(ROUTES.SESSION.replace(":tileId", activity.StoreTileId));
		} else {
			setEventModal(false);
			setAttendanceModal(true);
		}
	}

	function renderStockRelatedButtons(type) {
		if (isPast) return null;

		return (
			<div className="actions-wrapper">
				{!activity.canJoin && !requiredCheck(activity) && (
					<div id="activity-buttons" className="event-add-btn">
						{activity.canPurchase && !requiredCheck(activity) && (
							<Button
								action={() => purchase()}
								classes="secondary medium"
								text={acfPageOptions?.acf?.activity_card_add_to_agenda}
							/>
						)}
						{activity.canUnregister && !requiredCheck(activity) && (
							<Button
								action={() => unregister()}
								classes={`inverted ${type}`}
								text={acfPageOptions?.acf?.activity_card_remove_from_agenda}
							/>
						)}
					</div>
				)}

				{(activity.canJoin || isAdmin) && (
					<Button
						action={() => triggerClickEvent()}
						classes="primary medium"
						text={acfPageOptions.acf?.activity_card_join_now}
					/>
				)}
			</div>
		);
	}

	function renderTopic() {
		if (activity?.content?.acf?.session_topic)
			return (
				<div
					className={`topic ${activity?.content?.acf?.session_topic?.topic}`}
				>
					{activity?.content?.acf?.session_topic?.topic_label}
				</div>
			);
		return null;
	}

	function renderTypeMessage(){
		if(activity.content.acf.event_type === 'Live' && activity.canJoin){
			return <div className="session-type"><VirtualIcon /> <p>{acfPageOptions?.acf?.event?.session_type?.live_session_happening_now}</p></div>
		}
		if(activity.content.acf.event_type === 'Live' && !activity.canJoin) {
			return <div className="session-type"><VirtualIcon /> <p>{acfPageOptions?.acf?.event?.session_type?.live_session}</p></div>
		}
		if(activity.content.acf.event_type === 'PrivateTeamsRoom' && activity.canJoin){
			return <div className="session-type"><PrivateIcon /> <p>{acfPageOptions?.acf?.event?.session_type?.private_teams_happening_now}</p></div>
		}
		if (activity.content.acf.event_type === 'PrivateTeamsRoom' && !activity.canJoin) {
			return <div className="session-type"><PrivateIcon /> <p>{acfPageOptions?.acf?.event?.session_type?.private_teams}</p></div>
		}

		return null;
	}

	function renderLink() {
		if (!link) return null;

		return (
			<Link
				to={ROUTES.SESSION_DETAILS.replace(":tileId", id)}
				className="more-link"
			>
				{acfPageOptions?.acf?.event?.more_info}
			</Link>
		);
	}

	const pastClass = isPast ? "past" : "";
	const teamsPrivateClass =
		activityType === "TeamsPrivateRoom" ? "teams-private" : "";

	return (
		<div>
			<div
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className={`${!isDesktop ? "m-session-card" : "d-session-card"} agenda-activity-card ${pastClass} ${teamsPrivateClass} ${className}`}
			>
				<div className="infos">
					<div className="p-3 info-top-content">
						<div className="topic-wrap">{renderTopic()}</div>

						<div className="event-text-info">
							<h4 className="tile-title">
								{activity?.content?.title?.rendered &&
									parse(activity?.content?.title?.rendered)}
							</h4>
							{renderPartners()}
						</div>
					</div>
					<div className="p-3 tile-bottom">
						{renderTime()}

						<div className="tile-ctn block">
							{renderDate()}

							<div className="actions block">
								{renderStock()}

								{loading ? (
									<div className="loading-text">
										<span>{acfPageOptions?.acf?.activity_card_loading}</span>
										<LoadingGif
											acfPageOptions={acfPageOptions}
											status={loading ? "active" : ""}
											cardLoader={loading ? "card-loader" : ""}
										/>
									</div>
								) : (
									<div id="activity-buttons" className="event-add-btn">
										{renderStockRelatedButtons('medium')}
									</div>
								)}

								<div
									ref={ref}
									className="overlay-link"
									aria-label="openModal"
									tabIndex="-1"
									role="button"
									onKeyPress={() => openEventModal()}
									onClick={() => openEventModal()}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CardModal activity={activity} eventModal={eventModal} setEventModal={setEventModal} acfPageOptions={acfPageOptions} partners={renderPartners()} type={renderTypeMessage()} topic={renderTopic()} date={renderDate()} time={renderTime()} link={renderLink()} stock={stock} stockText={renderStock()} loading={loading} stockButtons={renderStockRelatedButtons('small')} isAdmin={isAdmin} />

			<AttendanceModal acfPageOptions={acfPageOptions} modal={attendanceModal} setModal={setAttendanceModal} history={history} activity={activity}/>
		</div>
	);
}

SessionCard.defaultProps = {
	id: "",
	stock: null,
	link: false,
	activity: {},
	className: null,
	acfPageOptions: null,
	purchaseTile: () => {},
	writePlayerEvent: () => {},
	writeTelemetryEvent: () => {},
	onMouseEnter: () => {},
	onMouseLeave: () => {},
	getStoreLoadout: () => {},
	groupLang: null,
	isAdmin: false,
	isDesktop: false,
};

SessionCard.propTypes = {
	id: PropTypes.string,
	stock: PropTypes.number,
	link: PropTypes.bool,
	className: PropTypes.string,
	activity: PropTypes.object,
	acfPageOptions: PropTypes.object,
	purchaseTile: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	getStoreLoadout: PropTypes.func,
	getItemInventory: PropTypes.func.isRequired,
	groupLang: PropTypes.string,
	isAdmin: PropTypes.bool,
	isDesktop: PropTypes.bool,
};

export default SessionCard;
