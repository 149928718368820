/* eslint-disable no-unsafe-optional-chaining */
import { HubConnectionBuilder } from "@microsoft/signalr";
import { createSlice } from "@reduxjs/toolkit";
import { createDebouncedAsyncAction } from "utils/reduxhelpers";
import uuid from "react-uuid";
import {
	acquireCatalogItem,
	resetMission,
	setShowPollResults,
} from "redux/playfab";
import { setObjectives } from "redux/events";
import DEBUG_DATA from "utils/VideoPlayerDebug";
import { fetchLeaderboard } from "redux/leaderboard";
import { debugTracker } from "middleware/tracker";
import { REACT_APP_CHAT_API, ROUTES } from "Constants";
import { allTilesSelector } from "selector/agenda";
import { userNameSelector, chatAuthSelector } from "selector/player";
import { instance } from "as-stack-sdk/sdk";
import { createToast } from "./toastNotifications";

const stack = instance;

export const ON_MESSAGE_EVENT = "broadcastMessage";

export const MESSAGE_EMOJI = "_EMOJI_";
export const MESSAGE_SYSTEM = "_SYSTEM_";
export const MESSAGE_GRANT_ITEM = "_GRANT_ITEM_";
export const MESSAGE_START_OBJECTIVE = "_START_OBJECTIVE_";
export const MESSAGE_RESET_MISSION = "_RESET_MISSION_";
export const MESSAGE_VIDEO_DEBUG = "_VIDEO_DEBUG_";
export const MESSAGE_LEADERBOARD = "_LEADERBOARD_";
export const MESSAGE_SHOW_POLL_RESULTS = "_SHOW_POLL_RESULTS_";
export const MESSAGE_TRACKER_DEBUG = "_TRACKER_DEBUG_";
export const MESSAGE_MODERATOR = "_MODERATOR_";
export const REMOVE_MESSAGE = "_REMOVE_MSG_";
export const CHAT_MESSAGE = "_CHAT_";

/** @type {HubConnection} */
let connection = null;
const MAX_MESSAGES_LENGTH = 200;

export const chatConnect = createDebouncedAsyncAction(
	"chat/connect",
	(data, { dispatch, getState }) => {
		return new Promise((resolve) => {
			const state = getState();
			connection = new HubConnectionBuilder()
				.withUrl(REACT_APP_CHAT_API + "?username=" + chatAuthSelector(state))
				.withAutomaticReconnect({
					nextRetryDelayInMilliseconds: () => {
						return 500;
					},
				})
				//.configureLogging(window.signalR.LogLevel.Trace)
				.build();

			connection.start({ withCredentials: false }).then(() => {
				dispatch(setChatReady());
				connection.onreconnected = () => {
					const channel = state?.chat?.channel;
					const username = userNameSelector(state);

					if (channel && username) {
						dispatch(
							chatJoinRoom({
								channel,
								displayName: username,
							})
						);
					}
				};

				resolve({
					connection: true,
					messages: [],
				});
			});
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				...action.payload,
			};
		},
	}
);

export const chatLeaveRoom = createDebouncedAsyncAction(
	"chat/leave-room",
	(data) => {
		return new Promise((resolve) => {
			if (data.channel) {
				connection.send("LeaveRoom", data.channel).then(() => {
					return resolve();
				});
			} else {
				resolve();
			}
		});
	},
	{
		fulfilled: (state) => {
			return {
				...state,
				channel: null,
			};
		},
	}
);

export const chatJoinRoom = createDebouncedAsyncAction(
	"chat/join-room",
	(data, { dispatch }) => {
		return new Promise((resolve) => {
			connection.send("JoinRoom", data.channel).then(() => {
				dispatch(
					broadcastMessage({
						username: MESSAGE_SYSTEM,
						message: data.username + " JOINED",
						channel: data.channel,
						pfid: data.playfabId,
					})
				);
				resolve({
					channel: data.channel,
				});
			});
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				channel: action.payload.channel,
			};
		},
	}
);

export const broadcastMessage = createDebouncedAsyncAction(
	"chat/broadcastMessage",
	(data) => {
		return connection
			.send(ON_MESSAGE_EVENT, data.username, {
				type: data.type,
				content: data.message,
				group: data.channel,
				pfid: data.playfabId,
				key: null,
			})
			.then(() => ({ ...data }));
	},
	{}
);

/**
 *Execute action depending of recieved message type
 */
export const onMessageAction =
	(dispatch) => (n, content, pfid, type, messages) => {
		if (!pfid || !type) {
			return;
		}
		const m = content;
		const username = n;
		if (username === "_SYSTEM_") {
			if (type === "_CHAT_") {
				const parsedMessage = JSON.parse(m);
				const systemMessage = parsedMessage?.msg;
				dispatch(
					chatMessageCallback({
						id: type + new Date().getTime(),
						author: "Admin",
						message: systemMessage,
						pfid,
					})
				);
			} else if (type === MESSAGE_GRANT_ITEM) {
				dispatch(
					acquireCatalogItem({
						item_id: m,
					})
				);
			} else if (type === MESSAGE_START_OBJECTIVE) {
				dispatch(setObjectives([m]));
			} else if (type === MESSAGE_RESET_MISSION) {
				dispatch(
					resetMission({
						item_id: m,
					})
				);
			} else if (type === MESSAGE_LEADERBOARD) {
				const data = JSON.parse(m);
				dispatch(fetchLeaderboard(data));
			} else if (type === MESSAGE_SHOW_POLL_RESULTS) {
				const data = JSON.parse(m);
				dispatch(setShowPollResults(data));
			} else if (type === MESSAGE_VIDEO_DEBUG) {
				const u = MESSAGE_VIDEO_DEBUG;
				dispatch(
					chatMessageCallback({
						id: u + new Date().getTime(),
						author: u,
						message: JSON.stringify(DEBUG_DATA, null, 4),
					})
				);
			} else if (type === MESSAGE_TRACKER_DEBUG) {
				const u = MESSAGE_TRACKER_DEBUG;
				dispatch(
					chatMessageCallback({
						id: u + new Date().getTime(),
						author: u,
						message: JSON.stringify(debugTracker(), null, 4),
					})
				);
			} else if (type === REMOVE_MESSAGE) {
				const u = REMOVE_MESSAGE;
				const messagePlayFab = JSON.parse(m);

				dispatch(
					removeChatMessageCallback({
						id: u + new Date().getTime(),
						author: u,
						messages: messages.filter((message) => {
							if (
								message.message === messagePlayFab.msg &&
								message.user === messagePlayFab.author
							) {
								return null;
							}

							return message;
						}),
					})
				);
			}
		} else {
			if (type === MESSAGE_EMOJI) {
				const event = new CustomEvent("emoji_chat", {
					detail: { data: m, id: "event-" + Date.now() },
				});
				window.dispatchEvent(event);
				return;
			}
			dispatch(
				chatMessageCallback({
					id: type + new Date().getTime(),
					author: username,
					message: m,
					pfid,
				})
			);
		}
	};

export const chatListen = createDebouncedAsyncAction(
	"chat/listen",
	(data, { dispatch, getState }) => {
		connection.on(ON_MESSAGE_EVENT, (n, m) => {
			try {
				const state = getState();
				const messages = state?.chat?.messages;
				const { content, pfid, type } = JSON.parse(m);
				return onMessageAction(dispatch)(n, content, pfid, type, messages);
			} catch (e) {
				return onMessageAction(dispatch)(n, m, null, null, null);
			}
		});

		return Promise.resolve();
	},
	{}
);

export const chatStopListen = createDebouncedAsyncAction(
	"chat/stop-listen",
	() => {
		connection.off(ON_MESSAGE_EVENT);
		return Promise.resolve();
	},
	{}
);

export const chatMessageCallback = createDebouncedAsyncAction(
	"chat/messageCallback",
	(msg, { getState, dispatch }) => {
		const state = getState();
		const tiles = allTilesSelector(state);
		const session = tiles.find((t) => t.id === state.chat.channel);
		const displayName = userNameSelector(state);
		const index = msg.message.indexOf(displayName);

		if (index >= 0) {
			dispatch(
				createToast({
					content: `${msg.author} ${state.wpcontent.acfPageOptions?.chat_support?.chat_mentioned} ${session?.activity?.content?.title?.rendered}.`,
					type: "Toast",
					id: uuid(),
					target_url: ROUTES.SESSION.replace(
						":tileId",
						session?.activity?.StoreTileId
					),
					title: state.wpcontent.acfPageOptions?.chat_support?.new_mention,
					mention: true,
				})
			);
		}

		return Promise.resolve({
			messages: [msg],
		});
	},
	{
		fulfilled: (state, action) => {
			const messages = [
				...state?.messages,
				...action.payload.messages.map((x) => {
					return {
						...x,
						timestamp: Date.now(),
					};
				}),
			]
				.reverse()
				.slice(0, MAX_MESSAGES_LENGTH)
				.reverse();
			return {
				...state,
				messages,
				newMessages: state.newMessages + action.payload.messages.length,
			};
		},
	}
);

export const removeChatMessageCallback = createDebouncedAsyncAction(
	"chat/removeMessageCallback",
	(msg) => {
		return Promise.resolve({
			messages: msg.messages,
		});
	},
	{
		fulfilled: (state, action) => {
			return {
				...state,
				messages: action.payload.messages,
			};
		},
	}
);

/**
 *Get all the questions during a live event
 */
export const getQuestions = createDebouncedAsyncAction(
	"questions/getQuestions",
	stackAction((id) => {
		return stack.Manager("GetQuestions", {
			RoomId: id,
		});
	}),
	{
		fulfilled: (state, action) => {
			const questions = action.payload.Questions.filter((question) => {
				if (!question) {
					return null;
				}
				return question?.status === "accepted" || question?.status === "closed";
			});

			return {
				...state,
				questions,
				newQuestions: questions.length - state?.questions.length,
			};
		},
	}
);

/**
 *Submit a new question during the live event
 */
export const postQuestion = createDebouncedAsyncAction(
	"questions/postQuestion",
	stackAction((question) => {
		return stack.Manager("PostQuestion", {
			RoomId: question.RoomId,
			Message: question.Message,
			Anonymous: question.Anonymous,
			Live: question.Live,
		});
	}),
	{}
);

/**
 *Like or dislike a question during a live event
 */
export const voteQuestion = createDebouncedAsyncAction(
	"questions/voteQuestion",
	stackAction((question) => {
		return stack.Manager("VoteQuestion", {
			QuestionId: question.questionId,
			Vote: question.vote,
		});
	}),
	{}
);

function stackAction(action) {
	return (payload, { dispatch, getState }) => {
		return action(payload, dispatch, getState).then((resp) => {
			return resp;
		});
	};
}

const chat = createSlice({
	name: "chat",
	reducers: {
		clearMessages: (state) => {
			return {
				...state,
				messages: [],
			};
		},
		clearQuestions: (state) => {
			return {
				...state,
				questions: [],
			};
		},

		setChatReady: (state) => {
			return {
				...state,
				chatReady: true,
			};
		},

		fetchQuestion: (state) => {
			return {
				...state,
				fetch: !state.fetch,
			};
		},

		clearNewQuestions: (state) => {
			return {
				...state,
				newQuestions: 0,
			};
		},

		clearNewMessages: (state) => {
			return {
				...state,
				newMessages: 0,
			};
		},
	},
	extraReducers: {
		...chatConnect.reducers,
		...chatMessageCallback.reducers,
		...removeChatMessageCallback.reducers,
		...chatJoinRoom.reducers,
		...chatLeaveRoom.reducers,
		...chatListen.reducers,
		...getQuestions.reducers,
		...voteQuestion.reducers,
	},
	initialState: {
		messages: [],
		emojis: [],
		channel: "",
		connection: false,
		questions: [],
		chatReady: false,
		fetch: false,
		newMessages: 0,
		newQuestions: 0,
	},
});

export default chat;

export const {
	setChatChannel,
	clearMessages,
	clearQuestions,
	setChatReady,
	fetchQuestion,
	clearNewQuestions,
	clearNewMessages,
} = chat.actions;
