import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import logPageView from "utils/logPageView";
import GroupContainer from "./GroupContainer";

function BadgesTab({ 
	acfPageOptions, 
	badges, 
	badgesUnlocked,
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
}) {
	
	useEffect(() => {
		// Log PageView.
		logPageView(
			"badges_prizes",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	const nodeRef = React.useRef(null);
	
	return (
		<CSSTransition in appear timeout={1000} classNames="fade" nodeRef={nodeRef}>
			<div className="pt-4 badges-prizes-tab">
				{acfPageOptions.acf?.profile?.badges_tab?.badges_title &&
				acfPageOptions.acf?.profile?.badges_tab?.badges_explanations && (
					<>
						<Container>
							<Row>
								<Col>
									<h2>{acfPageOptions.acf?.profile?.badges_tab?.badges_title}</h2>
									<p>
										{acfPageOptions.acf?.profile?.badges_tab?.badges_explanations}
									</p>
								</Col>
							</Row>
						</Container>
						<Col xs={12}>
							<GroupContainer
								acfPageOptions={acfPageOptions}
								cards={badgesUnlocked}
								type="badge"
								title={acfPageOptions.acf?.profile?.badges_tab?.badge_group_title}
								count={{ badges, badgesUnlocked }}
							/>
						</Col>
					</>
				)}
				{acfPageOptions.acf?.profile?.badges_tab?.prizes_title &&
				acfPageOptions.acf?.profile?.badges_tab?.prizes_explanations && (
					<div className="pt-4">
						<Container>
							<Row>
								<Col>
									<h2>{acfPageOptions.acf?.profile?.badges_tab?.prizes_title}</h2>
									{acfPageOptions.acf?.profile?.badges_tab?.prizes_explanations &&
										parse(
											acfPageOptions.acf?.profile?.badges_tab?.prizes_explanations
										)}
								</Col>
							</Row>
						</Container>
						<Col xs={12}>
							<GroupContainer
								acfPageOptions={acfPageOptions}
								cards={acfPageOptions.acf?.profile?.badges_tab?.prizes}
								type="prize"
								title={acfPageOptions.acf?.profile?.badges_tab?.prize_group_title}
							/>
						</Col>
					</div>
				)}
			</div>
		</CSSTransition>
	);
}

BadgesTab.defaultProps = {
	acfPageOptions: null,
	badges: [],
	badgesUnlocked: [],
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
};

BadgesTab.propTypes = {
	acfPageOptions: PropTypes.object,
	badges: PropTypes.array,
	badgesUnlocked: PropTypes.array,
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
};

export default BadgesTab;
