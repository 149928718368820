import { createSelector } from '@reduxjs/toolkit';
import { departmentsStateSelector, regionsStateSelector } from './wp';

const messageStateSelector = (state) => state.chatSupport.messages;
const messageIdPropSelector = (state, props) => props.id;
const otherPlayerDataStateSelector = (state) => state.playfab?.OtherPlayerData || {};
const playfabIdPropsSelector = (state, props) => props.PlayFabId;

/**
* Get the support message from the messageIdProp
*/

export const supportMessageSelector = createSelector([
	messageStateSelector,
	messageIdPropSelector,
], (messages, messageId) => {
	if (!messages || messages.length === 0) return null;

	return messages.find(m => m.id === messageId);
});

/**
* Get the other player data and add additional region info
*/

export const otherPlayerDataSelector = createSelector([
	otherPlayerDataStateSelector,
	playfabIdPropsSelector,
	regionsStateSelector,
	departmentsStateSelector,
], (otherPlayers, playfabId, regions, departments) => {
	let found = otherPlayers && otherPlayers[playfabId];

	if (found) {
		found = {
			...JSON.parse(JSON.stringify(found)),
		};

		if (found.data.Region) {
			found.data.Region = regions.find(x => x.slug === found.data.Region)?.title?.rendered;
		}

		if (found.data.Department) {
			found.data.Department = departments.find(x => x.slug === found.data.Department)?.title?.rendered;
		}
	}

	return found;
});
