import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Button from "components/Buttons/Button";
import ProfilePicture from 'assets/placeholders/profile-picture.png';

function ContactModalInvite({
	showInvitationModal,
	acfContactList,
	acfIcons,
	setShowInvitationModal,
	invitationUsers,
	invitationCounter,
	setInvitationCounter,
	acceptFriendRequest,
	friendRequests,
	declineFriendRequest,
	usersList,
	setInvitationUsers,
}) {
	const viewProfile = acfContactList?.view_profile_modal;

	function removeInvitation(ItemInstanceId) {
		if (invitationUsers.length === 1) {
			setShowInvitationModal(false);
		}

		setInvitationUsers(
			invitationUsers.filter(
				(invite) =>
					invite.ItemInstanceId !== ItemInstanceId
			)
		);
	}

	function getInvitationProfile(invitationUser) {
		const { To } = invitationUser;
		const userInvitation = usersList?.find((user) => user.playfabId === To);

		return (
			<>
				<div className="profile-avatar-container">
					<div className="profile-picture-content">
						<img
							className="profile-right-picture"
							src={userInvitation?.avatar || ProfilePicture}
							alt="profile"
						/>
						<h4 className="name">
							{userInvitation?.displayName.length < 18
								? userInvitation?.displayName
								: `${userInvitation?.displayName.slice(0, 13)}...`}
						</h4>
						<span className="location">Montreal, Quebec</span>
					</div>
					<img
						className="profile-background-picture"
						src={userInvitation?.avatar || ProfilePicture}
						alt="profile background"
					/>
				</div>
				<div
					className={`profile-info-${
						invitationCounter > 1 &&
						invitationCounter + 1 <= friendRequests.length
							? "partial"
							: "full"
					}`}
				>
					<div className="profile-invitation-container">
						<h6 className="title">{viewProfile?.profile_information_title}</h6>
						<div className="profile-info-container">
							<div className="profile-info-label">
								{viewProfile?.inputs[0]?.label}
							</div>
							<div className="profile-info-content">Lorem ipsum</div>
						</div>
						<div className="profile-info-container">
							<div className="profile-info-label">
								{viewProfile?.inputs[1]?.label}
							</div>
							<div className="profile-info-content">Lorem ipsum</div>
						</div>
						<div className="profile-info-container">
							<div className="profile-info-label">
								{viewProfile?.inputs[2]?.label}
							</div>
							<div className="profile-info-content">Lorem ipsum</div>
						</div>
						<div className="address-container">
							<div className="profile-info-container">
								<div className="profile-info-label">
									{viewProfile?.inputs[3]?.label}
								</div>
								<div className="profile-info-content">Lorem ipsum</div>
							</div>
							<div className="profile-info-container">
								<div className="profile-info-label">
									{viewProfile?.inputs[4]?.label}
								</div>
								<div className="profile-info-content">Lorem ipsum</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	return (
		<Modal
			centered
			show={showInvitationModal}
			onHide={() => {
				setShowInvitationModal(false);
			}}
			id="modal-invitation"
			dialogClassName="modal-invitation"
		>
			<Modal.Body id="modal-body">
				<div className="modal-content">
					<div className="modal-invitation-container">
						<div className="modal-invitation-header">
							<h4>
								{invitationUsers?.length}{" "}
								{acfContactList?.invitation_received_title}
							</h4>
						</div>
						<div className="modal-invitation-body">
							{invitationCounter >= 1 && (
								<div className="arrow-back">
									<img
										role="presentation"
										src={acfIcons?.next_invitation?.url}
										alt="prev"
										onClick={() => setInvitationCounter(invitationCounter - 1)}
									/>
								</div>
							)}
							{invitationUsers?.length > 0 &&
								getInvitationProfile(invitationUsers[invitationCounter])}

							<div className="arrow-next">
								<img
									role="presentation"
									src={acfIcons?.next_invitation?.url}
									alt="next"
									onClick={() => {
										if (invitationCounter + 1 < invitationUsers?.length) {
											setInvitationCounter(invitationCounter + 1);
										}
									}}
								/>
							</div>
						</div>
						<div className="modal-invitation-footer">
							<Button
								action={() => {
									acceptFriendRequest({
										TokenId: friendRequests[invitationCounter]?.TokenId,
									});
									removeInvitation(
										invitationUsers[invitationCounter].ItemInstanceId
									);
								}}
								classes="primary large"
								text={acfContactList?.accept_invitation_button}
							/>
							<Button
								action={() => {
									declineFriendRequest({
										TokenId: invitationUsers[invitationCounter]?.TokenId,
									});
									removeInvitation(
										invitationUsers[invitationCounter].ItemInstanceId
									);
								}}
								classes="secondary large"
								text={acfContactList?.refuse_invitation_button}
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

ContactModalInvite.propTypes = {
	showInvitationModal: PropTypes.bool,
	acfContactList: PropTypes.object,
	acfIcons: PropTypes.object,
	setShowInvitationModal: PropTypes.func,
	invitationUsers: PropTypes.array,
	invitationCounter: PropTypes.number,
	setInvitationCounter: PropTypes.func,
	acceptFriendRequest: PropTypes.func,
	friendRequests: PropTypes.array,
	declineFriendRequest: PropTypes.func,
	usersList: PropTypes.array,
	setInvitationUsers: PropTypes.func,
};

ContactModalInvite.defaultProps = {
	showInvitationModal: () => {},
	acfContactList: {},
	acfIcons: PropTypes.object,
	setShowInvitationModal: () => {},
	invitationUsers: [],
	invitationCounter: 0,
	setInvitationCounter: () => {},
	acceptFriendRequest: () => {},
	declineFriendRequest: () => {},
	friendRequests: [],
	usersList: [],
	setInvitationUsers: () => {},
};

export default ContactModalInvite;
