import React from "react";
import PropTypes from "prop-types";

function Answer({ moderator, answer }) {
	return (
		<div className="answer">
			<span>{moderator}</span>
			<span>{answer}</span>
		</div>
	);
}

Answer.defaultProps = {
	moderator: "",
	answer: "",
};
Answer.propTypes = {
	moderator: PropTypes.string,
	answer: PropTypes.string,
};

export default Answer;
