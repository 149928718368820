import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { broadcastMessage, clearNewMessages } from "redux/chat";
import { userNameSelector } from "selector/player";
import { statSelector } from "selector/stats";
import { USER_LEVELS, CATALOG } from "Constants";
import { itemInventorySelector } from "selector/inventory";
import { chatMessageMentionsSelector } from "selector/chat";
import { setHasChatted } from "redux/tracking";
import { writePlayerEvent, writeTelemetryEvent } from "redux/playfab";
import Chat from "../../companel/chat/Index";

function mapStateToProps(state) {
	return {
		connection: state.chat.connection,
		channel: state.chat.channel,
		messages: state.chat.messages,
		ownPlayfabId: state.playfab?.PlayFabId,
		displayName: userNameSelector(state, {
			fallback: state.teams.userPrincipalName,
		}),
		isModerator: statSelector(state, { statName: "userlevel" }) === USER_LEVELS.MODERATOR,
		canChat: !itemInventorySelector(state, { itemId: CATALOG.disable_chat }),
		hasChatted: state.tracking?.hasChatted,
		newMessages: state.chat?.newMessages,
		acfPageOptions: state.wpcontent?.acfPageOptions,
		messageAuthors: chatMessageMentionsSelector(state),
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			broadcastMessage,
			setHasChatted,
			writePlayerEvent,
			writeTelemetryEvent,
			clearNewMessages,
		},
		dispatch
	)
)(Chat);
