import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid } from "swiper";
import Details from "./Details";
import Single from "./Single";

function GroupContainer({ cards, type, title, count, acfPageOptions }) {
	const [showDetails, setShowDetails] = useState(false);
	const [viewedElement, setViewedElement] = useState(null);

	useEffect(() => {
		if (viewedElement) {
			setShowDetails(true);
		}
	}, [viewedElement]);

	return (
		<div className="py-4 cards-container position-relative">
			<div className="px-3 d-flex justify-content-between container-header">
				<p>{title}</p>
				<p>
					{count && `${count?.badgesUnlocked.length}/${count?.badges.length}`}
				</p>
			</div>
			{cards.length <= 6 ? (
				<div className="d-flex flex-wrap flex-row justify-content-around">
					{cards.map((card) => {
						return (
							<Single
								key={uuid()}
								details={card}
								isInInventory={card.isInInventory}
								setViewedElement={setViewedElement}
								type={type}
							/>
						);
					})}
				</div>
			) : (
				<Swiper
					breakpoints={{
						375: {
							width: 365,
							slidesPerView: 3.4,
							grid:{
								rows: 2,
								fill: "row",
							},
						},
						667: {
							width: 667,
							slidesPerView: 6.4,
							grid:{
								rows: 2,
								fill: "row",
							},
						},
					}}
					modules={[Grid]}
					className="mySwiper"
				>
					{cards.map((card) => {
						return (
							<SwiperSlide key={uuid()}>
								<Single
									details={card}
									isInInventory={card.isInInventory}
									setViewedElement={setViewedElement}
									type={type}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
			)}
			<Details
				setViewedElement={setViewedElement}
				setShowDetails={setShowDetails}
				showDetails={showDetails}
				viewedElement={viewedElement}
				acfPageOptions={acfPageOptions}
				type={type}
			/>
		</div>
	);
}

GroupContainer.defaultProps = {
	cards: [],
	title: "",
	type: "",
	count: null,
	acfPageOptions: null,
};

GroupContainer.propTypes = {
	cards: PropTypes.array,
	title: PropTypes.string,
	type: PropTypes.string,
	count: PropTypes.object,
	acfPageOptions: PropTypes.object,
};

export default GroupContainer;
