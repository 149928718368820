/**
 * @format
 */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import CustomListingCard from "components/CustomListings/CustomListingCard";
import Layout from "components/Layout/Title";

function CustomListingCategory({ lists, category }) {
	if (!category || !lists) {
		return null;
	}

	return (
		<Layout titleSlot={category?.name} pageClass="custom-listing-category">
			<div className="wrap">
				{lists.map((listing) => (
					<CustomListingCard key={listing?.id} {...listing} />
				))}
			</div>
		</Layout>
	);
}

CustomListingCategory.defaultProps = {
};

CustomListingCategory.propTypes = {
	lists: PropTypes.array.isRequired,
	category: PropTypes.object.isRequired,
};

export default CustomListingCategory;
