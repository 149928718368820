import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ActivityFooter from 'containers/Activity/ActivityFooter';
import Leaderboard from 'containers/Events/Leaderboard';
import ActivityLeftColumn from 'components/Activity/ActivityLeftColumn';
import ActivityRightColumn from 'components/Activity/ActivityRightColumn';
import MeetingStarted from 'components/Activity/MeetingStarted';
import EmojiComponent from 'components/Events/Emojis/EmojiComponent';
import ProfileCard from 'containers/ProfileCard';
import { ROUTES } from 'Constants';

function RandomRoomSingle({
	chatJoinRoom,
	chatLeaveRoom,
	username,
	playfabId,
	clearMessages,
	getJitsiJwt,
	rooms,
	canJoin,
	activeRoom,
	getRandomRooms,
	history,
	acfPageOptions,
}) {
	const { roomId } = useParams();

	useEffect(() => {
		if (!rooms || !activeRoom) return null;
		if ((activeRoom === 'noFoundRoom' || canJoin === false) && rooms) {
			return history.push(ROUTES.ALL_ROOMS);
		}
		return null;
	}, [activeRoom, rooms, canJoin]);

	useEffect(() => {
		getJitsiJwt();
		if (rooms.length === 0) {
			getRandomRooms();
		}
	}, []);

	useEffect(() => {
	}, []);

	useEffect(() => {
		const channel = roomId;
		if (channel && username) {
			chatJoinRoom({
				channel,
				username,
				playfabId,
			});
		}

		return () => {
			chatLeaveRoom({
				channel,
			});
			clearMessages();
		};
	}, [roomId, username]);

	const pageInfo = {
		StoreTileId: roomId,
		isQuestionBox: true,
		content: {
			title: {
				rendered: activeRoom?.subject,
			},
			acf: {
				event_excerpt: '',
				com_panel: {
					default_tab: "none",
					is_collapsable: true,
					show_chat: false,
					show_emojis: false,
					show_qa: false,
					show_time_slots: false,
				},
			},
		},
		data: {
			activity_type: 'MeetingRoom',
		},
	};

	return (
		<div className="activity-room">
			<div className="top-left">
				<ActivityLeftColumn />
				<MeetingStarted url={roomId} meetingTitle={activeRoom?.subject} acfPageOptions={acfPageOptions}/>
				<Leaderboard channel={pageInfo?.StoreTileId} />
				<div className="emojis-container">
					<EmojiComponent />
				</div>
			</div>
			<ActivityRightColumn activity={pageInfo} />
			<ActivityFooter activity={pageInfo} />
			<ProfileCard />
		</div>
	);
}

RandomRoomSingle.defaultProps = {
	chatJoinRoom: () => { },
	chatLeaveRoom: () => { },
	clearMessages: () => { },
	getJitsiJwt: () => { },
	username: '',
	playfabId: '',
	rooms: [],
	getRandomRooms: () => { },
	canJoin: null,
	activeRoom: null,
	acfPageOptions: null,
};

RandomRoomSingle.propTypes = {
	chatJoinRoom: PropTypes.func,
	chatLeaveRoom: PropTypes.func,
	clearMessages: PropTypes.func,
	getJitsiJwt: PropTypes.func,
	username: PropTypes.string,
	playfabId: PropTypes.string,
	rooms: PropTypes.array,
	getRandomRooms: PropTypes.func,
	canJoin: PropTypes.bool,
	activeRoom: PropTypes.object,
	history: PropTypes.object.isRequired,
	acfPageOptions: PropTypes.object,
};

export default memo(RandomRoomSingle);
