import React, { useContext } from "react";
import PropTypes from "prop-types";
import Count from "mobile/components/notifications/Count";
import { ComPanelContext } from "mobile/context/ComPanelContext";

function NavOption({
	acfPageOptions,
	title,
	description,
	Icon,
	tabType,
	setShowCompanelNavigation,
	notificationsCount,
	stateVideo,
}) {
	const { activeCompanelTab, setActiveCompanelTab } =
		useContext(ComPanelContext);
	function handleNavigation(tabName) {
		setActiveCompanelTab(tabName);
		setShowCompanelNavigation(false);
	}

	return (
		<div
			className={`d-flex flex-direction-column align-items-center com-panel-navoption my-3 ${
				activeCompanelTab === tabType ? "active" : ""
			}`}
			onClick={() => handleNavigation(tabType)}
			onKeyPress={() => handleNavigation(tabType)}
			tabIndex="-1"
			role="button"
		>
			<div className="left">
				<h5>
					{title}{" "}
					{stateVideo === "ended" && (
						<span className="closed px-1 py-1">{acfPageOptions?.acf?.global?.closed_button}</span>
					)}
				</h5>

				<span>{description}</span>
			</div>
			<div className="right">
				{notificationsCount > 0 && (
					<Count notificationsCount={notificationsCount} />
				)}
				<Icon />
			</div>
		</div>
	);
}

NavOption.defaultProps = {
	title: "",
	description: "",
	Icon: null,
	tabType: "",
	setShowCompanelNavigation: () => {},
	notificationsCount: 0,
	stateVideo: null,
	acfPageOptions: null,
};

NavOption.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	Icon: PropTypes.object,
	tabType: PropTypes.string,
	setShowCompanelNavigation: PropTypes.func,
	notificationsCount: PropTypes.number,
	stateVideo: PropTypes.string,
	acfPageOptions: PropTypes.object,
};

export default NavOption;
