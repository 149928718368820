import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomListingCategory from 'components/CustomListings/CustomListingCategory';
import { getCustomListingFromCategory } from 'selector/customListings';
import { writeTelemetryEvent } from 'redux/playfab';

function mapStateToProps(state, props) {
	return {
		...getCustomListingFromCategory(state, props),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		writeTelemetryEvent,
	}, dispatch)
)(CustomListingCategory);
