/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import * as Sentry from "@sentry/react";

import PropTypes from 'prop-types';
import { AsStackUtility } from 'as-stack-sdk/utility.js';
import GA from 'GA';
import { BUILD_DATETIME } from 'Constants';


function Auth({
	children,
	userJWT,
	setAuthStep,
	setJWT,
	playerId,
	authPlayfab,
	userObjectId,
	writeTelemetryEvent,
	fetchTeamsData,
	setLoginMethod,
}) {

	const utility = new AsStackUtility();

	/**
	*Check if there is a session cookie or playfabId cookie for the login
	*If there are not, log using the JWT from the url
	*/
	useEffect(() => {
		const sessionTicket = utility.GetCookie('SessionTicket');
		const playfabId = utility.GetCookie('PlayFabId');

		if (playfabId && sessionTicket) {
			const startCall = Date.now();
			authPlayfab(playfabId).then((d) => {
				const endCall = Date.now();
				const callDuration = endCall - startCall;
				writeTelemetryEvent({
					namespace: 'custom.state',
					name: 'authentication',
					body: {
						app_version: BUILD_DATETIME,
						status: d.payload.code,
						delay: callDuration,
					},
				});

				GA.setUserProperties({
					userId: d.payload.data.LoginResult.PlayFabId,
				});

			});
		} else {
			const url = String(window.location);
			const JWT = url.split('#id_token=')[1];
			if (JWT) {
				setAuthStep('jwtInit');
				setJWT(JWT);
			} else {
				setAuthStep('teamsInit');
				setLoginMethod('TeamsLogIn');
				fetchTeamsData();
			}
		}

	}, []);

	/**
	*Render the app the there is a valid PlayerId, JWT
	*/
	function renderApp() {		
				
		if (playerId) {
			Sentry.setUser({ "playerId": playerId });
			return (<>{children}</>);
		}

		if (userJWT) {
			return (<>{children}</>);
		}

		if (userObjectId) {
			return (<>{children}</>);
		}

		return (null);
	}

	return renderApp();
}

Auth.defaultProps = {
	userJWT: null,
	playerId: null,
	userObjectId: null,
	teamsState: null,
	getJWT: () => { },
	setAuthStep: () => { },
	writeTelemetryEvent: () => { },
	fetchTeamsData: () => { },
	setLoginMethod: () => { },
	children: null,
};

Auth.propTypes = {
	userJWT: PropTypes.string,
	playerId: PropTypes.string,
	userObjectId: PropTypes.string,
	getJWT: PropTypes.func,
	setAuthStep: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	setLoginMethod: PropTypes.func,
	fetchTeamsData: PropTypes.func,
	children: PropTypes.object,
	teamsState: PropTypes.object,
};

export default Auth;
