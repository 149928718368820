import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ProfilePicture from 'assets/placeholders/profile-picture.png';

/*
* Public profile with information accessible to all users
*/

function PublicProfile({
	acfPageOptions,
	user_data,
	getLeaderboardOnPosition,
	leaderboardPosition,
	getOtherPlayerData,
}) {

	const [id, setId] = useState('');
	const { profileId } = useParams();

	useEffect(() => {
		const JWT = profileId;
		setId(JWT);
		getOtherPlayerData(JWT);
		getLeaderboardOnPosition(JWT);
	}, []);

	function getStat(type) {
		let data = '';
		for (let i = 0; i < leaderboardPosition.length; i += 1) {
			if (id === leaderboardPosition[i].PlayFabId) {
				if (type === 'points') {
					data = leaderboardPosition[i].StatValue;
				} else if (type === 'position') {
					data = leaderboardPosition[i].Position + 1;
				}
			}
		}
		return data;
	}
	return (
		<div className="public-profile-page page-wrapper">
			<div className="sidenav-left-column" />
			<div className="background" />
			<div className="site-wrapper">
				<div className="profile-container">
					<div className="user">
						<div className="center">
							<div className="avatar-profile-ctn">
								<div className="avatar-ctn">
									<div className="avatar">
										<img src={user_data[id] && user_data[id].data?.Avatar ? user_data[id].data?.Avatar : ProfilePicture} alt="" />
									</div>
								</div>
							</div>
							<div className="top-infos">
								<div className="username">
									<h2 className="name">{user_data[id] && user_data[id].displayName}</h2>
								</div>
							</div>
							<div className="extra-stats">
								<div className="stat-container">
									<div className="stat-value"><h4>{getStat('points')}</h4></div>
									<div className="stat-name">{acfPageOptions.acf?.profile?.header?.points_title}</div>

								</div>
							</div>
						</div>
					</div>
					<div className="bottom-section">
						<div className="bottom-content">
							{acfPageOptions.acf?.profile?.profile_tab?.inputs.length > 0 && acfPageOptions.acf?.profile?.profile_tab?.inputs.map(input=>{
								return (
									<div className="info-container">
										<div className="info-subtitle">{input?.label}</div>
										<p className="info-content">{user_data[id] && user_data[id].data[input?.label]}</p>
									</div>)
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

PublicProfile.defaultProps = {
	acfPageOptions: null,
	user_data: {},
	getLeaderboardOnPosition: () => { },
	leaderboardPosition: [],
	getOtherPlayerData: () => { },
};

PublicProfile.propTypes = {
	acfPageOptions: PropTypes.object,
	user_data: PropTypes.object,
	getLeaderboardOnPosition: PropTypes.func,
	leaderboardPosition: PropTypes.array,
	getOtherPlayerData: PropTypes.func,
};

function Badge({
	data,
	isInInventory,
}) {
	return (
		<div className={`badge ${isInInventory ? 'on' : 'off'}`}>
			<div className="tooltip">
				<div className="reason">{data.reason}</div>
				<div className="name">{data.title}</div>
				<div className="description">{data.description}</div>
			</div>
			<img className="on" src={data.icon} alt="" />
			<img className="off" src={data.iconoff} alt="" />
		</div>
	);
}

Badge.defaultProps = {
	isInInventory: false,
};

Badge.propTypes = {
	data: PropTypes.object.isRequired,
	isInInventory: PropTypes.bool,
};

export default PublicProfile;
