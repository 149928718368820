/* eslint-disable no-unsafe-optional-chaining */
import { createDebouncedAsyncAction } from 'utils/reduxhelpers';
import { instance } from 'as-stack-sdk/sdk';

const stack = instance;

function stackAction(action) {
	return (payload, { dispatch, getState }) => {
		return action(payload, dispatch, getState).then((resp) => {
			return resp;
		});
	};
}

/**
 *Create a expert room
 */
export const BookAppointmentWithExpert = createDebouncedAsyncAction(
	'bookAMeeting/BookAppointmentWithExpert',
	stackAction((data) => {
		return  stack.Client('BookAppointmentWithExpert', data);
	}),
	{}
);
