import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PublicProfile from 'components/Pages/PublicProfile';
import { getLeaderboardOnPosition, getOtherPlayerData } from 'redux/playfab';

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		user_data: state.playfab?.OtherPlayerData,
		leaderboardPosition: state.playfab?.OnPositionLeaderboard,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		getLeaderboardOnPosition,
		getOtherPlayerData,
	}, dispatch)
)(PublicProfile);
