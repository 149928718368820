/* eslint-disable react/function-component-definition */
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Avatar from "containers/Partials/Avatar";
import { getLeaderboard, getLeaderboardOnPosition } from "redux/playfab";

const ProfileHeader = ({
	leaderboardOnPosition,
	playfabId,
	sessions,
	displayName,
	acfPageOptions,
	profileTabs,
	activeTab,
	setActiveTab,
	getLeaderboard,
	getLeaderboardOnPosition,
}) => {
	const noop = () => {};

	function getStat(type) {
		let data = "";
		for (let i = 0; i < leaderboardOnPosition.length; i += 1) {
			if (playfabId === leaderboardOnPosition[i].PlayFabId) {
				if (type === "points") {
					data = leaderboardOnPosition[i].StatValue;
				} else if (type === "position") {
					for (let a = 0; a < sessions.length; a += 1) {
						if (sessions[a].StatisticName === "sessions") {
							data = sessions[a].Value;
						}
					}
				}
			}
		}
		return data;
	}

	useEffect(() => {
		if ((activeTab === 2)) {
			getLeaderboard();
			getLeaderboardOnPosition();
		}
	}, [activeTab]);

	return (
		<div className="profile-header">
			<div className="user">
				<div className="center">
					<div className="avatar-profile-ctn">
						<Avatar />
					</div>
					<div className="top-infos">
						<div className="username">
							<span className="name">{displayName}</span>
						</div>
					</div>
					{acfPageOptions.acf?.profile?.header?.show_points && (
						<div className="extra-stats">
							<div className="stat-container">
								<div className="stat-value">{getStat("points")}</div>
								<div className="stat-name">
									<div className="stat-content">
										{acfPageOptions.acf?.profile?.header?.points_title}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="navigation-tabs">
				{profileTabs.map((tab) => {
					const isActive = tab.id === activeTab;
					if (!tab?.is_displayed) {
						return null;
					}
					return (
						<div
							className={`navigation-tab ${isActive ? "active" : ""}`}
							key={tab.id}
							role="button"
							tabIndex="-1"
							onKeyPress={noop}
							onClick={() => {
								setActiveTab(tab.id);
							}}
						>
							<span>{tab.name}</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

ProfileHeader.defaultProps = {
	acfPageOptions: null,
	playfabId: "",
	leaderboardOnPosition: [],
	sessions: [],
	displayName: "",
	getLeaderboard: () =>{},
	getLeaderboardOnPosition: () =>{},
};

ProfileHeader.propTypes = {
	playfabId: PropTypes.string,
	leaderboardOnPosition: PropTypes.array,
	sessions: PropTypes.array,
	displayName: PropTypes.string,
	acfPageOptions: PropTypes.object,
	profileTabs: PropTypes.array.isRequired,
	activeTab: PropTypes.number.isRequired,
	setActiveTab: PropTypes.func.isRequired,
	getLeaderboard: PropTypes.func,
	getLeaderboardOnPosition: PropTypes.func,
};

export default ProfileHeader;
