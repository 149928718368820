import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ComPanel from 'containers/ComPanel/ComPanel';

function ActivityRightColumn({ activity }) {
	const activityAcf = activity?.content?.acf?.com_panel;
	let defaultTabValue = 'none';

	if (activityAcf?.default_tab === 'qa' && !activityAcf?.show_qa) {
		defaultTabValue = 'none';
	} else if (activityAcf?.default_tab === 'chat' && !activityAcf?.show_chat) {
		defaultTabValue = 'none';
	} else {
		defaultTabValue = activityAcf?.default_tab;
	}

	return (
		<div className='activity-right-column'>
			{activity?.customPayload?.topic_description  ? (
				<ComPanel activity={activity} withEmojis={false} show_qa={false} show_chat partnerStatus={false} collapsable defaultTab='chat' />
			) : (
				<ComPanel
					activity={activity}
					withEmojis={activityAcf?.show_emojis}
					collapsable={activityAcf?.is_collapsable}
					show_chat={activityAcf?.show_chat}
					show_qa={activityAcf?.show_qa}
					show_time_slot={activityAcf?.show_time_slots}
					defaultTab={defaultTabValue}
				/>
			)}
		</div>
	);
}

ActivityRightColumn.defaultProps = {
	activity: null,
};

ActivityRightColumn.propTypes = {
	activity: PropTypes.object,
};

export default memo(ActivityRightColumn);
