import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BookAMeetingContext } from 'components/BookAMeeting/BookAMeetingContext';
import { getDayTwoDigits, toLocalTime } from 'utils/dateUtils';

function CustomDate({
	children,
	inputValue,
	forValue,
	acfPageOptions,
	date,
	groupLang,
	setFilteredDates,
	chosenDate,
	setChosenDate,
}) {
	const { filteredTiles } = useContext(BookAMeetingContext);

	function onDateChange(e) {
		setFilteredDates(
			filteredTiles.filter((tile) => {
				const tileDay = getDayTwoDigits(toLocalTime(tile.customPayload.date_begin).$d, groupLang);
				const dateDay = getDayTwoDigits(toLocalTime(date).$d, groupLang);
				return tileDay === dateDay;
			})
		);
		setChosenDate(e.target.value);
		document.getElementById(
			'bookMeetingDateLabel'
		).innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.selected_date}: <span class="value" id="selected-date" data-date=${date}>${inputValue}</span>`;
		document.getElementById(
			'bookMeetingTimeLabel'
		).innerHTML = `${acfPageOptions?.acf?.book_a_meeting?.select_time_label}`;
	}
	return (
		<div className={`${chosenDate === inputValue && 'active'} bookmeeting-radio-ctn`}>
			<input type='radio' id={forValue} onChange={(e) => onDateChange(e)} name='date' value={inputValue} />
			<label id={'label-' + inputValue} htmlFor={forValue}>
				{children}
			</label>
		</div>
	);
}

CustomDate.propTypes = {
	children: PropTypes.array,
	inputValue: PropTypes.string,
	forValue: PropTypes.string,
	acfPageOptions: PropTypes.object,
	date: PropTypes.string,
	groupLang: PropTypes.string,
	setFilteredDates: PropTypes.func,
	chosenDate: PropTypes.string,
	setChosenDate: PropTypes.func,
};

CustomDate.defaultProps = {
	children: null,
	inputValue: '',
	forValue: '',
	acfPageOptions: null,
	date: null,
	groupLang: null,
	setFilteredDates: () => {},
	chosenDate: '',
	setChosenDate: () => {},
};

export default CustomDate;
