/*
 * @format
 */

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";

import "react-image-crop/src/ReactCrop.scss";
import { CSSTransition } from "react-transition-group";
import Button from "components/Buttons/Button";

const MAX_IMAGE_SIZE = 250;

function UploadModal({
	uploadAvatar,
	updatePlayerData,
	show,
	setShow,
	acfPageOptions,
}) {
	const [crop, setCrop] = useState({ aspect: 1 / 1, unit: "%", width: 50 });
	const [src, setSrc] = useState(null);
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);
	const [croppedImageBlob, setCroppedImageBlob] = useState(null);
	const [imageRef, setImageRef] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const [showWarning, setShowWarning] = useState(false);

	function CloseModal() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="21.736"
				height="21.737"
				viewBox="0 0 21.736 21.737"
			>
				<g
					id="Groupe_802"
					data-name="Groupe 802"
					transform="translate(10.868 -8.04) rotate(45)"
				>
					<path
						id="Tracé_349"
						data-name="Tracé 349"
						d="M0,0V26.74"
						transform="translate(13.37)"
						fill="#737980"
						stroke="#737980"
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						id="Tracé_350"
						data-name="Tracé 350"
						d="M0,0V26.74"
						transform="translate(26.74 13.37) rotate(90)"
						fill="#737980"
						stroke="#737980"
						strokeLinecap="round"
						strokeWidth="2"
					/>
				</g>
			</svg>
		);
	}

	function onImageLoaded(image) {
		setImageRef(image);
	}

	function onCropComplete() {
		makeClientCrop();
	}

	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () => setSrc(reader.result));
			reader.readAsDataURL(e.target.files[0]);
		}
	}

	async function makeClientCrop() {
		if (imageRef && crop.width && crop.height) {
			const blob = await getCroppedImg(imageRef, "newFile.jpeg");
			setCroppedImageBlob(blob);
			setCroppedImageUrl(window.URL.createObjectURL(blob));
		}
	}

	function getCroppedImg(image, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;

		const width = MAX_IMAGE_SIZE;

		setShowWarning(crop.width * scaleX < MAX_IMAGE_SIZE);

		canvas.width = width;
		canvas.height = width;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			width,
			width
		);

		return new Promise((resolve) => {
			canvas.toBlob(
				(blob) => {
					blob.name = fileName;
					resolve(blob);
				},
				"image/jpeg",
				0.8
			);
		});
	}

	const id = `picture_field_${Math.random()}`;

	function onClickAccept() {
		setIsLoading(true);

		uploadAvatar(croppedImageBlob)
			.then((response) => {
				updatePlayerData({
					Avatar: response.payload.data.AvatarUrl,
				}).then(() => {
					setShow(false);
				});
			})
			.catch(() => {
				setShow(false);
			});
	}

	function getLoading() {
		return (
			<div className="loadingWrapper">
				<div className="loading-inner-gif">
					<span className="loader" />
				</div>
				{
					acfPageOptions?.acf?.profile?.profile_tab
						?.uploading_profile_picture_label
				}
			</div>
		);
	}

	function getContent() {
		if (isLoading) return getLoading();
		return (
			<>
				<div className="align-center">
					<div className="input">
						<input
							className={src ? "" : "empty"}
							type="file"
							onChange={onSelectFile}
							accept="image/*"
							id={id}
						/>
						<label
							className={` ${src ? "replace-upload" : "start-upload"}`}
							htmlFor={id}
							data-placeholder={
								acfPageOptions?.acf?.profile?.profile_tab
									?.start_uploading_profile_picture_label
							}
							data-replace={
								acfPageOptions?.acf?.profile?.profile_tab
									?.replace_profile_picture_label
							}
						/>
					</div>
					{croppedImageUrl && (
						<div className="accept">
							{!showWarning && croppedImageUrl && (
								<button type="button" className="choose" onClick={onClickAccept}>
									{
										acfPageOptions?.acf?.profile?.profile_tab
											?.accept_profile_picture_upload_label
									}
								</button>
							)}
						</div>
					)}
				</div>
				{/* eslint-disable react/jsx-no-bind */}
				{src && (
					<ReactCrop
						src={src}
						crop={crop}
						onImageLoaded={onImageLoaded}
						onComplete={onCropComplete}
						onChange={(newCrop) => setCrop(newCrop)}
						keepSelection
						circularCrop
					/>
				)}
				{croppedImageUrl && (
					<div className="bottom">
						{showWarning && (
							<div className="warning">
								{
									acfPageOptions?.acf?.profile?.profile_tab
										?.profile_picture_crop_warning
								}
							</div>
						)}
					</div>
				)}
			</>
		);
	}

	const modalRef = useRef();
	/* eslint-disable jsx-a11y/click-events-have-key-events */
	return (
		<CSSTransition
			in={show}
			timeout={600}
			appear
			nodeRef={modalRef}
			unmountOnExit
		>
			<div className="upload-modal" ref={modalRef}>
				<div className="inner">
					<div className="close" onClick={() => setShow(false)}>
						<CloseModal />
					</div>
					<div className="title">
						{
							acfPageOptions?.acf?.profile?.profile_tab
								?.profile_picture_upload_modal_title
						}
					</div>

					{getContent()}
				</div>
			</div>
		</CSSTransition>
	);
}

UploadModal.defaultProps = {
	uploadAvatar: () => {},
	updatePlayerData: () => {},
	show: false,
	setShow: () => {},
	acfPageOptions: null,
};

UploadModal.propTypes = {
	uploadAvatar: PropTypes.func,
	updatePlayerData: PropTypes.func,
	show: PropTypes.bool,
	setShow: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default UploadModal;
