import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import parse from "html-react-parser";
import { now } from "utils/dateUtils";
import { ReactComponent as DefaultAvatar } from "assets/icons/pack1/chat/chat-box/icon-profile-pic-s.svg";
import { ReactComponent as IconLike } from "assets/icons/pack1/chat/chat-box/icon-like.svg";
import Answer from "./qa/Answer";

function Message({
	acfPageOptions,
	type,
	author,
	channel,
	message,
	timestamp,
	displayName,
	otherPlayerData,
	questionData,
	voteQuestion,
	newMessages,
	newQuestions,
	getQuestions,
	fetchQuestion,
	canScroll,
	canScrollQuestions,
	getOtherPlayerData,
	pfid,
}) {
	const [showAnswer, setShowAnswer] = useState(false);
	const [units, setUnits] = useState("s");
	const timeRef = useRef();
	const questionTimeRef = useRef();
	const isSameAuthorChat = author === displayName;
	const isSameAuthorQuestion = questionData?.author === displayName;
	const upVotes = useRef(questionData?.upVote);
	const [questionVoted, setQuestionVoted] = useState("");
	const [isNewMessage, setIsNewMessage] = useState(true);
	const [isNewQuestion, setIsNewQuestion] = useState(true);

	function onClickReactQuestion(vote) {
		if (!localStorage.getItem("question-" + questionData.id)) {
			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "vote Q&A",
				eventAction: `${vote}`,
				eventLabel: `${questionData?.question}`,
			});

			voteQuestion({
				questionId: questionData.id,
				vote,
			});
			upVotes.current += 1;
			setQuestionVoted(vote);
			fetchQuestion();
			getQuestions(channel);
			localStorage.setItem("question-" + questionData.id, vote);
		}
	}

	useEffect(() => {
		if (type === "question") {
			const myVote = localStorage.getItem("question-" + questionData.id);
			if (myVote != null) {
				setQuestionVoted(myVote);
			}
		}
	}, []);

	useEffect(() => {
		if (!otherPlayerData) {
			getOtherPlayerData(pfid);
		}
	}, []);

	function checkMentions(text) {
		const index = text.indexOf(displayName);
		if (index >= 0) {
			const highlightedText =
				text.substring(0, index) +
				"<span class='highlight'>" +
				displayName +
				"</span>" +
				text.substring(index + displayName.length);
			return parse(highlightedText);
		}
		return text;
	}

	useEffect(() => {
		const timer = setInterval(
			() => {
				const nowTimestamp = now().valueOf();

				const diffSecs = dayjs(nowTimestamp).diff(dayjs(timestamp), "second");
				const diffMins = dayjs(nowTimestamp).diff(dayjs(timestamp), "minute");

				const diffToShow =
					diffSecs <= 60 ? diffSecs + " sec" : Math.floor(diffMins) + " min";
				if (diffSecs >= 60 && units === "s") {
					setUnits("m");
				}
				if (diffSecs >= 30) {
					setIsNewMessage(false);
				}

				if (timeRef && timeRef.current) {
					timeRef.current.innerHTML = diffToShow;
				}
			},
			units === "s" ? 1000 : 60000
		);
		const questionTimer = setInterval(
			() => {
				const questionTimestamp = questionData?.createdAt;
				const nowTimestamp = now().valueOf();
				const diffSecs = dayjs(nowTimestamp).diff(
					dayjs(questionTimestamp),
					"second"
				);
				const diffMins = dayjs(nowTimestamp).diff(
					dayjs(questionTimestamp),
					"minute"
				);
				const diffToShow =
					diffSecs <= 60 ? diffSecs + " sec" : Math.floor(diffMins) + " min";
				if (diffSecs >= 60 && units === "s") {
					setUnits("m");
				}
				if (diffSecs >= 30) {
					setIsNewQuestion(false);
				}

				if (questionTimeRef && questionTimeRef.current) {
					questionTimeRef.current.innerHTML = diffToShow;
				}
			},
			units === "s" ? 1000 : 60000
		);

		return () => {
			clearInterval(timer);
			clearInterval(questionTimer);
		};
	}, [units, timestamp]);

	function renderMessage() {
		switch (type) {
			case "chatMessage":
				return (
					<>
						<div className="message-avatar">
							<div className={`${isSameAuthorChat && "own-message"}`}>
								{otherPlayerData && otherPlayerData.data.Avatar ? (
									<img src={otherPlayerData.data.Avatar} alt="" />
								) : (
									<DefaultAvatar
										className={`${isSameAuthorChat && "own-message"}`}
									/>
								)}
							</div>
						</div>
						<div
							className={`d-flex flex-row message-container w-100 p-3 justify-content-between ${
								isSameAuthorChat ? "own-message" : ""
							}`}
						>
							<div className="d-flex flex-column left">
								<span className="mb-1">{author}</span>
								<div className="msg w-100">{checkMentions(message)}</div>
							</div>
							<div className="d-flex flex-column text-end align-items-end right">
								<span className="time" ref={timeRef} />
								<span className="new-message">
									{isNewMessage && newMessages > 0 && "new"}
								</span>
							</div>
						</div>
					</>
				);
			case "question":
				return (
					<>
						<div className="message-avatar">
							<div className={`${isSameAuthorQuestion && "own-message"}`}>
								{otherPlayerData && otherPlayerData.data.Avatar ? (
									<img src={otherPlayerData.data.Avatar} alt="" />
								) : (
									<DefaultAvatar
										className={`${isSameAuthorQuestion && "own-message"}`}
									/>
								)}
							</div>
						</div>
						<div
							className={`d-flex flex-row message-container w-100 p-3 justify-content-between mb-2 ${
								isSameAuthorQuestion && "own-message"
							}`}
						>
							<div className="d-flex flex-column left">
								<span className="mb-1">{questionData?.author}</span>
								<div className="msg w-100">{questionData?.question}</div>
								{questionData.answer && showAnswer && (
									<Answer
										moderator="Moderator name"
										answer={questionData?.answer}
									/>
								)}
								{questionData.answer && (
									<button
										type="button"
										onClick={() => setShowAnswer(!showAnswer)}
									>
										{showAnswer ? (
											<div className="d-flex align-items-center">
												<div className="arrow-up" />{" "}
												<span className="mx-2">Hide the answer!</span>
											</div>
										) : (
											<div className="d-flex align-items-center">
												<div className="arrow-down" />{" "}
												<span className="mx-2">Show the answer!</span>
											</div>
										)}
									</button>
								)}
							</div>
							<div className="d-flex flex-column text-end align-items-end right">
								<span className="time" ref={questionTimeRef} />
								<span className="new-message">
									{isNewQuestion && newQuestions > 0 && "new"}
								</span>

								<div
									className="likes px-2 mt-2"
									tabIndex="-1"
									onClick={() => onClickReactQuestion("up")}
									onKeyPress={() => onClickReactQuestion("up")}
									role="button"
								>
									<span className="count px-2">{upVotes.current}</span>
									<IconLike
										className={`${questionVoted ? "questionVoted" : ""}`}
									/>
								</div>
							</div>
						</div>
					</>
				);
			default:
		}
		return null;
	}

	return (
		<>
			{(newMessages > 0 && canScroll()) ||
				(newQuestions > 0 && canScrollQuestions())}
			<div className="d-flex mobile-message mx-3 my-2">{renderMessage()}</div>
		</>
	);
}

Message.defaultProps = {
	type: "",
	author: null,
	channel: null,
	message: null,
	displayName: null,
	otherPlayerData: null,
	timestamp: 0,
	questionData: null,
	voteQuestion: () => {},
	getQuestions: () => {},
	fetchQuestion: () => {},
	acfPageOptions: null,
	newMessages: 0,
	newQuestions: 0,
	canScroll: () => {},
	canScrollQuestions: () => {},
	getOtherPlayerData: () => {},
	pfid: "",
};
Message.propTypes = {
	type: PropTypes.string,
	author: PropTypes.string,
	channel: PropTypes.string,
	message: PropTypes.string,
	displayName: PropTypes.string,
	fetchQuestion: PropTypes.func,
	timestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	otherPlayerData: PropTypes.object,
	questionData: PropTypes.object,
	voteQuestion: PropTypes.func,
	getQuestions: PropTypes.func,
	acfPageOptions: PropTypes.object,
	newMessages: PropTypes.number,
	newQuestions: PropTypes.number,
	canScroll: PropTypes.func,
	canScrollQuestions: PropTypes.func,
	getOtherPlayerData: PropTypes.func,
	pfid: PropTypes.string,
};

export default Message;
