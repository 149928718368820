import { createSlice } from '@reduxjs/toolkit';
import { toUTC } from 'utils/dateUtils';

/**
* This handles the state for the mission objectives. It's used for stream events like Trivia, Poll...
* Refactor: could be renamed streamEvents for clarity
*/

const events = createSlice({
	name: 'events',
	reducers: {
		/**
		*Reset the mission objectives
		*/
		resetObjectives: (state) => {
			return {
				...state,
				current_objectives: {},
			};
		},
		/**
		*Set the mission objectives
		*/
		setObjectives: (state, action) => {
			const current_objectives = {
				...state.current_objectives,
			};

			action.payload.forEach(objective => {
				const obj = JSON.parse(objective);
				current_objectives[parseInt(obj.item_id, 10)] = { expiration: toUTC(obj.expiration).valueOf() };
			});

			return {
				...state,
				current_objectives,
			};
		},
		/**
		* Set the objective completed
		*/
		setObjectiveCompleted: (state, action) => {
			const s = {
				...state,
				current_objectives: {
					...state.current_objectives,
				},
			};

			const objId = action.payload.objective_id;
			if (s.current_objectives[objId]) {
				s.current_objectives[objId] = {
					...s.current_objectives[objId],
					completed: true,
				};
			}

			return s;
		},
	},
	extraReducers: {
	},
	initialState: {
		current_objectives: {},
	},
});

export default events;

export const { setObjectives, setObjectiveCompleted, resetObjectives } = events.actions;
