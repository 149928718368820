/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import Tab from 'components/ComPanel/Tab';
import { COMPANEL_TABS } from 'Constants';

const TabSwitcher = ({
	acfPageOptions,
	activeTab,
	setActiveTab,
	show_time_slot,
	show_qa,
	newMessages,
	newQuestions,
	partnerStatus,
	show_chat,
}) => {
	function ChatTab() {
		if (show_chat === true && show_qa === false && partnerStatus === false) {
			return (
				<Tab
					title={acfPageOptions?.acf?.live_chat_panel?.chat_title}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					newElements={newMessages}
					iconClass='icon-chat'
					icon={acfPageOptions?.acf?.theme?.icon?.live?.live_chat?.url}
					tabType={COMPANEL_TABS.CHAT}
				/>
			);
		}
		if (show_chat === false && partnerStatus === false) {
			return null;
		}
		if (show_qa === true && partnerStatus === false) {
			return (
				<Tab
					title={acfPageOptions?.acf?.live_chat_panel?.chat_title}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					newElements={newMessages}
					iconClass='icon-chat'
					icon={acfPageOptions?.acf?.theme?.icon?.live?.live_chat?.url}
					tabType={COMPANEL_TABS.CHAT}
				/>
			);
		}
		if (partnerStatus === false) {
			return null;
		}
		if (partnerStatus === true) {
			return (
				<Tab
					title={acfPageOptions?.acf?.live_chat_panel?.chat_title}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					newElements={newMessages}
					iconClass='icon-chat'
					icon={acfPageOptions?.acf?.theme?.icon?.live?.live_chat?.url}
					tabType={COMPANEL_TABS.CHAT}
				/>
			);
		}
		return null;
	}

	return (
		<div className='list-selector'>
			<div className='buttons-container' role='button' tabIndex='-1'>
				<ChatTab />
				{show_qa && (
					<Tab
						title={acfPageOptions?.acf?.live_chat_panel?.question_title}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						newElements={newQuestions}
						iconClass='icon-qa'
						icon={acfPageOptions?.acf?.theme?.icon?.live?.live_question?.url}
						tabType={COMPANEL_TABS.QA}
					/>
				)}
				{show_time_slot && (
					<Tab
						title={acfPageOptions.acf?.partner?.availabilities_label}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						iconClass='icon-schedule'
						icon={acfPageOptions?.acf?.theme?.icon?.partner?.availabilities?.url}
						tabType={COMPANEL_TABS.AVAILABILITIES}
					/>
				)}
			</div>
		</div>
	);
};

TabSwitcher.defaultProps = {
	show_time_slot: null,
	show_qa: null,
	show_chat: null,
	setActiveTab: () => {},
	acfPageOptions: null,
	newMessages: null,
	newQuestions: null,
	activeTab: null,
	partnerStatus: null,
};

TabSwitcher.propTypes = {
	show_time_slot: PropTypes.bool,
	show_qa: PropTypes.bool,
	show_chat: PropTypes.bool,
	newMessages: PropTypes.bool,
	newQuestions: PropTypes.bool,
	setActiveTab: PropTypes.func,
	acfPageOptions: PropTypes.object,
	activeTab: PropTypes.string,
	partnerStatus: PropTypes.bool,
};

export default TabSwitcher;
