
import React from 'react';
import parse from "html-react-parser";
import PropTypes from 'prop-types';
import BackButton from 'containers/BackButton';
import Button from "components/Buttons/Button";
import { ROUTES } from 'Constants';

function Lists({ lists }) {
	if (lists && lists.length > 0) {
		return lists.map(list => (
			<div className="list" key={JSON.stringify(list)}>
				<p className="list-title">{list?.list_title}</p>
				<ul>
					{
						list.list_items.map(listItem => (
							<li className="list-item" key={JSON.stringify(listItem)}>
								<div className="list-item-content">{listItem?.list_item_text && parse(listItem?.list_item_text)}</div>
								{
									listItem.list_item_photo ? <img src={listItem?.list_item_photo?.sizes?.large} alt="" /> : null
								}
							</li>
						))
					}
				</ul>
			</div>
		));
	}

	return null;
}
Lists.defaultProps = {
	lists: null,
};

Lists.propTypes = {
	lists: PropTypes.array,
};


export default function CustomListing({
	category,
	listing,
	acfPageOptions,
}) {

	function getLink(url) {
		const urlCleaned = url.replaceAll(' ', '').replaceAll(' ', '');
		window.open(urlCleaned);
	}

	return (
		<div className="custom-listing page-wrapper">
			<div className="sidenav-left-column" />
			<div className="content-column">
				<div className="background" />
				<div className="site-wrapper">
					<h3 className="page-title">{listing?.acf?.title}</h3>
					<BackButton
						link={ROUTES.CATEGORY.replace(':categorySlug', category?.slug)}
						 />
					<div className="wrapper">
						<div className="custom-listing-inner">
							<div className="row">
								{listing?.acf?.photo && (
									<div className="image col appear-anim">
										<div className="thumbnail">
											<img src={listing?.acf?.photo?.sizes?.large} className="wp-post-image" alt="" />
										</div>
									</div>
								)}
								<div className="content col appear-anim">
									<div className="entry-content">
										<h1 className="title">{listing?.acf?.title && parse(listing?.acf?.title)}</h1>
										<p className="subtitle">{listing?.acf?.subtitle}</p>
										<div className="wysiwyg">{listing?.acf?.text && parse(listing?.acf?.text)}</div>

										<Lists lists={listing?.acf?.lists} />
									</div>
								</div>

								<div className="files-download">
									{listing.acf?.files_download && listing.acf?.files_download.map(file => (
										<Button
											action={() => getLink(file.file_link)}
											icon={acfPageOptions?.acf?.theme?.icon?.partner?.download?.url}
											classes="primary medium download-file"
											text={file.file_name || 'Download file'}
											key={file.file_link}
											orientation='right'
											download
										/>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

CustomListing.propTypes = {
	category: PropTypes.object.isRequired,
	listing: PropTypes.object.isRequired,
	acfPageOptions: PropTypes.object.isRequired,
};
