import { createSelector } from '@reduxjs/toolkit';

const showRoomsSelector = (state) => state?.wppage?.showrooms;
const showRoomSelected = (_, props) => props.match.params.showRoomSlug

/**
 *
 */
export const getShowRoomSelected = createSelector(
	[showRoomsSelector, showRoomSelected],
	(showRooms, roomSelected) => {
		const showRoom = showRooms.filter(showroom => showroom.slug === roomSelected)[0]

		if (!showRoom) return null;

		return showRoom;
	}
);