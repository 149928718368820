/*
 * @format
 */
import React, { useEffect, useState, useCallback } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import PropTypes from "prop-types";
import { useStore } from "react-redux";
import dayjs from "dayjs";
import { useDrop } from "react-dnd";

import {
	MAX_ERRORS_BEFORE_REBOOT,
	APP_MODES,
	ROUTES,
	REACT_APP_AS_STACK_APP_ID,
	REACT_APP_DEFAULT_NO_APP_VIDEO,
	REACT_APP_LOADING_LOGO,
} from "Constants";
import { setStyleTheme } from "utils/styleUtils";
import { setMobileStyleTheme } from "utils/mobileStyleUtils";
import { numberOfCalls } from "middleware/tracker";
import { useBreakpoint } from 'mobile/context/BreakpointContext';
import AdminTool from "containers/Partials/AdminTool";
import ActivityRoom from "containers/Activity/ActivityRoom";
import Faq from "containers/Pages/Faq";
import WPpage from "containers/Pages/WPPage";
import Profile from "containers/Pages/Profile";
import PublicProfile from "containers/Pages/PublicProfile";
import MainHub from "containers/Pages/MainHub";
import Speakers from "containers/Pages/Speakers";
import Speaker from "containers/Pages/Speaker";
import Partners from "containers/Pages/Partners";
import Partner from "containers/Pages/Partner";
import Agenda from "containers/Pages/Agenda";
import Debug from "containers/Partials/Debug";
import Auth from "containers/Auth";
import Notifications from "containers/Notifications";
import Playfab from "containers/Playfab";
import Event from "containers/Pages/Event";
import ChatConnection from "containers/ChatConnection";
import ChatSupport from "containers/ChatSupport/ChatSupport";
import VideoBubble from "containers/VideoBubble";
import LoadingAuth from "containers/LoadingAuth";
import Navigation from "containers/Partials/Navigation";
import VideoPlayer from "containers/VideoPlayer/VideoPlayer";
import { getParameterByName } from "utils/urlUtils";
import PageViewTracker from "components/Partials/PageViewTracker";
import AllChats from "containers/Chat/AllChats";
import ShowRooms from "containers/Pages/ShowRooms";
import ShowRoom from "containers/Pages/ShowRoom";
import RandomRoomSingle from "containers/Chat/RandomRoomSingle";
import { BUBBLE_MOBILITY_TYPE } from "utils/constants";
import CustomListingCategory from "containers/CustomListings/CustomListingCategory";
import CustomListing from "containers/CustomListings/CustomListing";
import InteractiveGallery from "containers/Pages/InteractiveGallery";
import DealersLounge from "containers/Pages/DealersLounge";
import NotFound from "containers/Pages/NotFound";
import Status from "containers/Pages/Status";
import MobileRoutes from "mobile/Routes";
import ProgressScreens from "components/ProgressScreens";
import Notices from "containers/Pages/Notices";
import CodeOfConduct from "containers/Pages/CodeOfConduct";
import Terms from "containers/Pages/Terms";
import Privacy from "containers/Pages/Privacy";

function App({
	region,
	isLoading,
	isBanned,
	needsUpdateItem,
	tooManyErrors,
	teamsSessionId,
	userSessionId,
	setAppMode,
	fetchWPChannelsData,
	fetchWPSpeakersData,
	fetchWPGalleriesData,
	fetchWPTagsData,
	getPlayerData,
	getPlayerReadOnlyData,
	updatePlayerData,
	consumeInventoryItem,
	isPlatformClosed,
	isEventOver,
	acfPageOptions,
	mobileTheme,
	isAdmin,
	fetchWPCustomListings,
	playerGroup,
	fetchWPPartnersData,
	fetchWPInteractiveGalleriesData,
	fetchWpDealerLoungeData,
	fetchWpShowroomData,
	fetchWPCustomListingCategories,
	fetchWpContentGalleryData,
	fetchWPPageData,
	userHasEnabledNotifications,
	navigation,
}) {
	/**
	 * Check the app's mode and fetch the Wordpress content
	 */
	useEffect(() => {
		const app_mode = getParameterByName("app_mode");
		if (app_mode in APP_MODES) {
			setAppMode({ app_mode });
		}

		const data = {
			playerGroup,
			pageSlug: "pages?slug=faq",
		};
		//******DISABLED API CALLS NOT NEEDED FOR LIGHTSPEED EVENT******
		if (playerGroup) {
			fetchWPChannelsData(data);
			fetchWPSpeakersData(data);
			// fetchWPGalleriesData(data);
			// fetchWPTagsData(data);
			// fetchWPCustomListings(data);
			fetchWPPartnersData(data);
			// fetchWPInteractiveGalleriesData(data);
			// fetchWpShowroomData(data);
			// fetchWpDealerLoungeData(data);
			// fetchWPCustomListingCategories(data);
			// fetchWpContentGalleryData(data);
			fetchWPPageData(data);
		}
	}, [playerGroup]);

	const [showDebug, setShowDebug] = useState(false);

	//Reload window if to many errrors
	const [errorCount] = useState(1);
	//TODO: Fix: Warning: Cannot update a component (`App`) while rendering a different component (`MediaModal`). To locate the bad setState() call inside `MediaModal`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
	// window.onerror = () => {
	// 	setErrorCount(errorCount + 1);

	// 	return false;
	// };

	// Check if the desktop breakpoint is reached.
	// TODO: Optimize for performances.
	const breakpoints = useBreakpoint();
	const [isDesktop, setIsDesktop] = useState(true);

	const [showRegionCheck, setShowRegionCheck] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setShowRegionCheck(true);
		}, 20000);
	}, []);

	//ERROR Reloader TODO: refactor out
	useEffect(() => {
		window.dataLayer.push({
			originalLocation:
				document.location.protocol +
				"//" +
				document.location.hostname +
				document.location.pathname +
				document.location.search,
		});

		window.dataLayer.push({
			event: "generic_event",
			eventCategory: "account",
			eventAction: "login success",
			eventLabel: "",
		});

		window.addEventListener("message", (e) => {
			switch (e.data.eventType) {
				// MPEmbed is ready
				case "mpembed.ready":
					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "mpembed.ready",
						eventAction: "",
					});
					break;

				// Model is loaded
				case "model.loaded":
					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "model.loaded",
						eventAction: "",
					});
					break;

				// Rooms are loaded
				case "rooms.loaded":
					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "rooms.loaded",
						eventAction: e.data,
					});
					break;

				// Entering a room
				case "room.enter":
					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "room.enter",
						eventAction: e.data.room.id,
					});
					break;

				// Mattertag is clicked
				case "tag.click":
					window.dataLayer.push({
						event: "generic_event",
						eventCategory: "showroom click",
						eventAction: "click",
						eventLabel: e.data.sid,
					});
					break;

				// Sending Analytics to Google (must have &ga= param in url or config)
				case "analytics.google":
					window.dataLayer.push({
						event: "generic_event",
						eventCategory: e.data.event_category,
						eventAction: "click",
						eventLabel: e.data.event_label,
					});
					break;
				default:
					break;
			}
		});
	}, []);

	useEffect(() => {
		//TODO: errorCount not working
		if (errorCount > MAX_ERRORS_BEFORE_REBOOT) {
			location.reload();
		}

		//Work around to count network call fails used
		if (
			numberOfCalls(["playfab"], ["rejected"]) > MAX_ERRORS_BEFORE_REBOOT ||
			numberOfCalls(["chat"], ["rejected"]) > MAX_ERRORS_BEFORE_REBOOT ||
			numberOfCalls(["content", "wppage", "wpcontent"], ["rejected"]) >
				MAX_ERRORS_BEFORE_REBOOT
		) {
			//TODO: add report to admin with relevant info
			location.reload();
		}
	}, [errorCount]);

	const { lang } = useStore().getState().env;

	dayjs.locale(lang);

	const [canViewApp, setCanViewApp] = useState(true);
	const [shouldFetchPlayerData, setShouldFetchPlayerData] = useState(false);
	const [shouldCheckForNeedsUpdateItem, setShouldCheckForNeedsUpdateItem] =
		useState(false);
	const [styleThemeLoaded, setStyleThemeLoaded] = useState(false);
	const [styleMobileThemeLoaded, setMobileStyleThemeLoaded] = useState(false);
	const [mobileOnly, setMobileOnly] = useState(false);

	//Default video player options
	const defaultOptions = {
		nativeControlsForTouch: false,
		controls: false,
		autoplay: true,
		logo: { enabled: false },
		debug: false,
	};

	function onUpdateValue(name, value) {
		updatePlayerData({ [name]: value });
	}

	/**
	 * The user can see the app if the player's inventory does not contain the BannedAccountItem, NeedsUpdateItem or if
	 * the app has not encountered too many errors. The item is dropped from Playfab to force a refresh on the player's
	 * inventory. The update item was created when the app was available on Microsoft Teams and required a reload from
	 * user.
	 */
	useEffect(() => {
		if (isBanned || needsUpdateItem || tooManyErrors) {
			setCanViewApp(false);
		} else if (!canViewApp) {
			setCanViewApp(true);
		}
	}, [isBanned, needsUpdateItem, tooManyErrors]);

	useEffect(() => {
		if (
			!userHasEnabledNotifications &&
			acfPageOptions.acf?.global?.enable_notifications
		) {
			onUpdateValue(
				"EnableNotifications",
				acfPageOptions.acf?.global?.enable_notifications.toString()
			);
		}
	}, [acfPageOptions]);

	useEffect(() => {
		if (
			teamsSessionId &&
			userSessionId !== null &&
			teamsSessionId !== userSessionId
		) {
			updatePlayerData({ SessionId: teamsSessionId }).then(() => {
				setShouldFetchPlayerData(true);
			});

			setShouldCheckForNeedsUpdateItem(true);
		}
	}, [teamsSessionId, userSessionId, needsUpdateItem]);

	useEffect(() => {
		if (!acfPageOptions) {
			return null;
		}
		if (
			Object.keys(acfPageOptions).length === 0 &&
			acfPageOptions.constructor === Object
		) {
			return null;
		}
		if (
			!styleThemeLoaded &&
			acfPageOptions?.acf?.theme &&
			acfPageOptions.acf.theme.length !== 0 &&
			Object.prototype.hasOwnProperty.call(acfPageOptions.acf.theme, "color") &&
			Object.prototype.hasOwnProperty.call(acfPageOptions.acf.theme, "text")
		) {
			if (window.innerWidth > 992) {
				setStyleTheme(acfPageOptions);
				return setStyleThemeLoaded(true);
			}
		}
		return null;
	}, [acfPageOptions, styleThemeLoaded]);

	useEffect(() => {
		if (!mobileTheme) {
			return null;
		}
		if (
			Object.keys(mobileTheme).length === 0 &&
			mobileTheme.constructor === Object
		) {
			return null;
		}
		if (
			!styleMobileThemeLoaded &&
			mobileTheme?.acf?.theme &&
			mobileTheme.acf.theme.length !== 0 &&
			Object.prototype.hasOwnProperty.call(mobileTheme.acf.theme, "color") &&
			Object.prototype.hasOwnProperty.call(mobileTheme.acf.theme, "text")
		) {
			if (window.innerWidth < 992) {
				setMobileStyleTheme(mobileTheme);
				return setMobileStyleThemeLoaded(true);
			}
		}
		return null;
	}, [mobileTheme, styleMobileThemeLoaded]);

	/**
	 *Fetch player data if it needs and inventory update.
	 */
	useEffect(() => {
		if (shouldFetchPlayerData) {
			setShouldFetchPlayerData(false);
			getPlayerData();
			getPlayerReadOnlyData();
		}
	}, [shouldFetchPlayerData, needsUpdateItem]);

	/**
	 *If the player needs an update and has the item in his inventory, the item is consumed.
	 */
	useEffect(() => {
		if (shouldCheckForNeedsUpdateItem && needsUpdateItem) {
			setShouldCheckForNeedsUpdateItem(false);
			consumeInventoryItem(needsUpdateItem.playfab.ItemInstanceId);
		}
	}, [shouldCheckForNeedsUpdateItem, needsUpdateItem]);

	/**
	 *Reload the page if too many errors are detected. Note that the tooManyErrors value is never set to true.
	 *This feature needs a remodel.
	 */
	useEffect(() => {
		if (tooManyErrors) {
			window.location.reload();
		}
	}, [tooManyErrors]);

	/**
	 *Display a screen if the player has the BannedItem, UpdateItem or toomany errors
	 */
	function renderNoApp() {
		//TODO: Use progress screen component
		if (needsUpdateItem) {
			return (
				<>
					<div className="no-app-video">
						<VideoPlayer
							src={REACT_APP_DEFAULT_NO_APP_VIDEO}
							options={defaultOptions}
						/>
					</div>
					<div className="no-app">
						<h1>Update needed!</h1>
						<p>It seems a newer version of the app is available!</p>
						<p>
							Please log out of Microsoft Teams, then log back in to access the
							application.
						</p>
					</div>
				</>
			);
		}

		//TODO: Use progress screen component
		if (isBanned) {
			return (
				<ProgressScreens
					isAdmin={isAdmin}
					isEventOver={isEventOver}
					isPlatformClosed={isPlatformClosed}
					isBanned={isBanned}
				/>				
			);
		}

		//TODO: Use progress screen component
		return (
			<>
				<div className="no-app-video">
					<VideoPlayer
						src={REACT_APP_DEFAULT_NO_APP_VIDEO}
						options={defaultOptions}
					/>
				</div>
				<div className="no-app">
					<h1>Uh oh!</h1>
					<p>It seems an error occured.</p>
					<p>
						Please log out of Microsoft Teams, then log back in to access the
						application.
					</p>
				</div>
			</>
		);
	}
	const initialPosition = {
		left: window.innerWidth * 0.3,
		top: window.innerHeight * 0.3,
	};

	const [bubblePosition, setBubblePosition] = useState(initialPosition);

	const moveBubble = useCallback(
		(left, top) => {
			setBubblePosition({ left, top });
		},
		[bubblePosition, setBubblePosition]
	);

	const [, drop] = useDrop(
		() => ({
			accept: BUBBLE_MOBILITY_TYPE,
			drop(item, monitor) {
				const delta = monitor.getDifferenceFromInitialOffset();
				const left = Math.round(item.left + delta.x);
				const top = Math.round(item.top + delta.y);
				moveBubble(left, top);
				return undefined;
			},
		}),
		[moveBubble]
	);


	function checkIfDesktop() {

		setIsDesktop(
			!breakpoints.xs &&
			!breakpoints.sm &&
			!breakpoints.md
		);
	}
	useEffect(() => {
		checkIfDesktop();
	}, [breakpoints]);

	function useWindowSize() {
		/*
            DOES NOT WORK WITH DIMENSIONS EMULATOR, you must resize the whole browser window!
        */
		const [windowSize, setWindowSize] = useState({
			width: undefined,
			height: undefined,
		});
		useEffect(() => {
			// Handler to call on window resize
			function handleResize() {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}
			// Add event listener
			window.addEventListener("resize", handleResize);
			// Call handler right away so state gets updated with initial window size
			handleResize();
			// Remove event listener on cleanup
			return () => window.removeEventListener("resize", handleResize);
		}, []); // Empty array ensures that effect is only run on mount
		return windowSize;
	}
	/**
	 *If the user can see the app render it else display the screens above.
	 */
	function renderApp() {
		document.documentElement.style.setProperty("--tablet-breakpoint", 768);
		const size = useWindowSize();
		if (canViewApp && playerGroup) {
			return (
				<Router>
					<div ref={drop}>
						<ProgressScreens
							isAdmin={isAdmin}
							isEventOver={isEventOver}
							isPlatformClosed={isPlatformClosed}
							isBanned={isBanned}
						/>
						<PageViewTracker />
						{size.width < getComputedStyle(document.documentElement).getPropertyValue("--tablet-breakpoint") || mobileOnly ? (
							 //Disable Mobile by WP
							acfPageOptions.acf?.global?.disable_mobile ? (
								<div id="root">
									<Status
										title={acfPageOptions.acf?.global?.small_screen_title}
										description={acfPageOptions.acf?.global?.small_screen_text}
										type="mobile"
									/>
								</div> 
							) : (
								//All Mobile only
								<MobileRoutes
									setShowDebug={setShowDebug}
									setMobileOnly={setMobileOnly}
									mobileOnly={mobileOnly}
									windowSize={size}
									isDesktop={isDesktop}
								/>
							) //Everything but mobile
						) : (
							!mobileOnly && (
								<>
									<Notifications />
									<Navigation />
									<ChatSupport />
									<VideoBubble bubblePosition={bubblePosition} />
									<Switch>
										<Route exact path="/">
											<MainHub />
										</Route>
										{/* SETUP REAL FAQ HERE */}
										<Route exact path={ROUTES.FAQ}>
											<Faq pageSlug="pages?slug=faq" />
										</Route>
										<Route
											path={ROUTES.CATEGORY}
											render={({ history, location, match, staticContext }) => {
												return (
													<CustomListingCategory
														key={match.params.categorySlug}
														history={history}
														location={location}
														match={match}
														staticContext={staticContext}
													/>
												);
											}}
										/>
										<Route
											path={ROUTES.CUSTOM_LISTING}
											render={({ history, location, match, staticContext }) => {
												return (
													<CustomListing
														key={match.params.listingSlug}
														history={history}
														location={location}
														match={match}
														staticContext={staticContext}
													/>
												);
											}}
										/>
										<Route exact path={ROUTES.PROFILE}>
											<Profile />
										</Route>
										<Route exact path={ROUTES.PUBLIC_PROFILES}>
											<PublicProfile />
										</Route>
										<Route exact path={ROUTES.SPEAKERS}>
											<Speakers />
										</Route>
										<Route path={ROUTES.SPEAKER}>
											<Speaker />
										</Route>
										<Route exact path={ROUTES.ALL_ROOMS}>
											<AllChats />
										</Route>
										<Route exact path={ROUTES.SHOWROOMS}>
											<ShowRooms />
										</Route>
										<Route
											path={ROUTES.SHOWROOM}
											render={({ history, location, match, staticContext }) => {
												return (
													<ShowRoom
														key={match.params.showRoomSlug}
														history={history}
														location={location}
														match={match}
														staticContext={staticContext}
													/>
												);
											}}
										/>

										<Route
											path={ROUTES.RANDOM_ROOM_SINGLE}
											render={({ history, location, match, staticContext }) => {
												return (
													<RandomRoomSingle
														key={match.params.roomId}
														history={history}
														location={location}
														match={match}
														staticContext={staticContext}
													/>
												);
											}}
										/>

										<Route exact path={ROUTES.PARTNERS}>
											<Partners />
										</Route>
										<Route path={ROUTES.PARTNER}>
											<Partner />
										</Route>

										<Route
											path={ROUTES.ACTIVITY}
											render={({ history, location, match, staticContext }) => {
												return (
													<ActivityRoom
														key={match.params.tileId}
														history={history}
														location={location}
														match={match}
														staticContext={staticContext}
													/>
												);
											}}
										/>
										<Route path={ROUTES.EVENT}>
											<Event />
										</Route>
										{/*Link to single event page*/}
										<Route
											path={`/events/${navigation?.acf?.navigation_acf?.event?.tile_id}`}
										>
											<Event />
										</Route>

										{/* Validate if we kill this page */}
										<Route exact path={ROUTES.AGENDA}>
											<Agenda />
										</Route>

										<Route exact path={ROUTES.INTERACTIVE_GALLERY}>
											<InteractiveGallery />
										</Route>

										<Route exact path={ROUTES.DEALER_LOUNGE}>
											<DealersLounge />
										</Route>
										<Route exact path={ROUTES.NOTICES}>
											<Notices />
										</Route>
										<Route path={ROUTES.CODE_OF_CONDUCT}>
											<CodeOfConduct pageSlug="pages?slug=code-of-conduct" />
										</Route>
										<Route path={ROUTES.TERMS}>
											<Terms pageSlug="pages?slug=terms-of-use" />
										</Route>
										<Route path={ROUTES.PRIVACY}>
											<Privacy pageSlug="pages?slug=privacy-policy" />
										</Route>
										<Route path="/404">
											<NotFound />
										</Route>
										{/* If no routes found redirect to 404 page */}
										<Redirect to="/404" />
									</Switch>
								</>
							)
						)}
					</div>
				</Router>
			);
		}

		return renderNoApp();
	}

	// Temp block for Lightspeed 2024 day 3
	return (
		<div className="status-screens">
			<div
				className="background-img"
				style={{
					backgroundImage: "url(https://wp.cf.holoscene.events/demo/2024/lightspeed/background/lightspeed-bg-progress.png)",
				}}
			/>
			<div
				className="welcome-block"
			>
				<div className="logo">
					<img
						src={
							// logo ||
							// acfPageOptions.acf?.theme?.logo?.main?.sizes?.large ||
							REACT_APP_LOADING_LOGO
						}
						alt=""
					/>
				</div>
				<div className="title">
					<h2>Day 3 sessions will be hosted on Zoom</h2>
				</div>
				<div className="description">
					<p>
						The Zoom link has been shared via Slack Channel and Email. For any questions contact:<br />summit@lightspeedhq.com
					</p>
				</div>
			</div>
		</div>
	);

	/*
	return (
		<>
			<Debug setShowDebug={setShowDebug} showDebug={showDebug} />
			{isAdmin && <AdminTool />}
			<LoadingAuth
				setShowDebug={setShowDebug}
				showDebug={showDebug}
				show={isLoading}
			/>
			<Auth>
				<Playfab>
					<ChatConnection>
						<div className={`App ${REACT_APP_AS_STACK_APP_ID !== "we8edz" ? "prod" : ""}`}>{renderApp()}</div>
					</ChatConnection>
				</Playfab>
			</Auth>
			{showRegionCheck && region === -1 && (
				<Status
					title="Account incomplete"
					description=""
					// message={message}
					// redirectURL={redirect}
				/>
			)}
		</>
	);
	*/
}

App.defaultProps = {
	isLoading: true,
	isBanned: false,
	needsUpdateItem: null,
	tooManyErrors: false,
	teamsSessionId: null,
	userSessionId: null,
	acfPageOptions: null,
	mobileTheme: null,
	isPlatformClosed: false,
	isEventOver: false,
	isAdmin: false,
	playerGroup: null,
	userHasEnabledNotifications: "",
	region: null,
	navigation: null,
	fetchWPCustomListings: () => {},
};

App.propTypes = {
	isLoading: PropTypes.bool,
	isPlatformClosed: PropTypes.bool,
	isEventOver: PropTypes.bool,
	isBanned: PropTypes.bool,
	needsUpdateItem: PropTypes.object,
	tooManyErrors: PropTypes.bool,
	teamsSessionId: PropTypes.string,
	userSessionId: PropTypes.string,
	setAppMode: PropTypes.func.isRequired,
	fetchWPChannelsData: PropTypes.func.isRequired,
	fetchWPSpeakersData: PropTypes.func.isRequired,
	fetchWPGalleriesData: PropTypes.func.isRequired,
	fetchWPTagsData: PropTypes.func.isRequired,
	getPlayerData: PropTypes.func.isRequired,
	getPlayerReadOnlyData: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	consumeInventoryItem: PropTypes.func.isRequired,
	acfPageOptions: PropTypes.object,
	mobileTheme: PropTypes.object,
	isAdmin: PropTypes.bool,
	playerGroup: PropTypes.string,
	fetchWPPartnersData: PropTypes.func.isRequired,
	fetchWPInteractiveGalleriesData: PropTypes.func.isRequired,
	fetchWpDealerLoungeData: PropTypes.func.isRequired,
	fetchWpShowroomData: PropTypes.func.isRequired,
	fetchWPCustomListingCategories: PropTypes.func.isRequired,
	fetchWPCustomListings: PropTypes.func,
	fetchWpContentGalleryData: PropTypes.func.isRequired,
	fetchWPPageData: PropTypes.func.isRequired,
	userHasEnabledNotifications: PropTypes.string,
	region: PropTypes.number,
	navigation: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default App;
