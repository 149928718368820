/**
 * @format
 */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import uuid from 'react-uuid'
import { Link, Redirect } from "react-router-dom";
import parse from "html-react-parser";
import { ROUTES, GROUP } from "Constants";
import Layout from "components/Layout/Title";
import logPageView from 'utils/logPageView';
import {
	getDayTwoDigits,
	getTimeStyleShort,
	getWeekdayShort,
	toLocalTime,
	getMonthLong,
} from 'utils/dateUtils';

/*
 * Displays all the partners
 */

function Partners({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	acfPageOptions,
	acfNavigation,
	playerGroup,
	partnersData,
	groupLang,
}) {
	const collapseAvailabilitiesIcon = acfPageOptions?.acf?.theme?.icon?.partner?.collapse_availabilities?.url && acfPageOptions?.acf?.theme?.icon?.partner?.collapse_availabilities?.url;
	const expandAvailabilitiesIcon = acfPageOptions?.acf?.theme?.icon?.partner?.expand_availabilities?.url && acfPageOptions?.acf?.theme?.icon?.partner?.expand_availabilities?.url;

	if (acfNavigation?.acf && !acfNavigation.acf?.navigation_acf?.partners?.show) {
		return <Redirect to="/404" />;
	}

	useEffect(() => {
		//Log PageView
		logPageView('partners', playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);

	/**
	 * TODO: Move to another file
	 */
	const PlayerGroup = ({ slot }) => {
		const { active_start, active_end } = slot || {};
		const [openSchedule, setOpenSchedule] = useState(false);
		const dayNum = getDayTwoDigits(toLocalTime(active_start[0]?.hour_start).$d, groupLang)
		const monthString = getMonthLong(toLocalTime(active_start[0]?.hour_start).$d, groupLang);
		const weekday = getWeekdayShort(toLocalTime(active_start[0]?.hour_start).$d, groupLang);
		return (
			<div className="slot" key={uuid()}>
				<span className="day">
					{weekday}
				</span>
				<div className="date">
					<span className="number">
						{dayNum}
					</span>
					<span className="month">
						{monthString}
					</span>
				</div>
				{openSchedule && (
					<div className="hours">
						{active_start &&
							active_start.map((hour, idx) => (
								<div
									className="hour"
									key={uuid()}
								>
									<span className="value">
										{getTimeStyleShort(toLocalTime(hour.hour_start).$d, 'en-Us')}
									</span>
									<span className="text">-</span>
									<span className="value">
										{getTimeStyleShort(toLocalTime(active_end[idx]?.hour_end).$d, 'en-Us')}
									</span>
								</div>
							))}
					</div>
				)}
				{!expandAvailabilitiesIcon && !collapseAvailabilitiesIcon ?
					<div
						className={`arrow-container ${
							openSchedule ? "arrow-down" : ""
						}`}
					>
						<button
							type="button"
							aria-label="openSchedule"
							onClick={() => setOpenSchedule(!openSchedule)}
						>
							<div className="icon-arrow" />
						</button>
					</div>
					:
					<div
						className="arrow-container-img"
						tabIndex='-1'
						role='button'
						aria-label="openSchedule"
						onClick={() => setOpenSchedule(!openSchedule)}
						onKeyPress={() => setOpenSchedule(!openSchedule)}
					>
						<img
							src={openSchedule ? expandAvailabilitiesIcon : collapseAvailabilitiesIcon} alt=''
						/>
					</div>
				}
			</div>
		);
	};

	return (
		<Layout
			titleSlot={acfPageOptions.acf?.partners?.title}
			pageClass="partners-page"
			isLoading={
				!partnersData || (partnersData && partnersData.length === 0)
			}
			backgroundUrl={acfPageOptions?.acf?.partners?.background ? acfPageOptions?.acf?.partners?.background : ''}
		>
			<div className="section-container">
				<div>
					<div className="booth-section">
						{acfPageOptions.acf?.partners?.description &&
							parse(acfPageOptions.acf?.partners?.description)}
					</div>
					<div className="partners-listing-wrap">
						{partnersData &&
							partnersData.length > 0 &&
							partnersData.map((partners) => (
								<Link
									className={`partners-listing-block ${acfPageOptions?.acf?.partner?.show_partner_name && partners.acf?.hover_text ? "show-partner-name" : ""}`}
									key={uuid()}
									to={ROUTES.PARTNER.replace(
										":partnerId",
										partners.slug
									)}
								>
									<div className="img-container">
										<img
											src={
												partners.acf?.logo?.sizes
													?.medium_large
											}
											alt=""
										/>
									</div>
									
									<div className="partner-name">
										{partners.acf?.hover_text && (
											partners.acf?.hover_text
										)}
									</div>
								</Link>
							))}
					</div>
				</div>
				<div className="right-section">
					<div className="intro-ctn">
						<div
							className={
								playerGroup === GROUP[0]
									? "schedule-container layout-all-time"
									: "schedule-container"
							}
						>
							{acfPageOptions?.acf?.partner?.show_time_slot && (
								<div className="time-slots">
									{acfPageOptions.acf &&
										acfPageOptions.acf?.partner?.time_slots &&
										acfPageOptions.acf?.partner?.time_slots.map(
											(slot) => (
												<PlayerGroup
													slot={slot}
													key={uuid()}
												/>
											)
										)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

Partners.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	acfPageOptions: null,
	partnersData: [],
	playerGroup: null,
	groupLang: null,
	acfNavigation: null,
};

Partners.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	partnersData: PropTypes.array,
	acfPageOptions: PropTypes.object,
	acfNavigation: PropTypes.any,
	playerGroup: PropTypes.string,
	groupLang: PropTypes.string,
};

export default Partners;
