import { createSlice } from '@reduxjs/toolkit';

const loading = createSlice({
	name: 'loading',
	reducers: {
		setLoading: (state, action) => {
			return {
				...state,
				[action.payload.key]: action.payload.value,
			};
		},
	},
	extraReducers: {},
	initialState: {
		auth: true,
	},
});

export default loading;

export const { setLoading } = loading.actions;
