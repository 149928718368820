import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createToast } from 'redux/toastNotifications';
import {
	authPlayfab,
	authTeamsPlayfab,
	authPlayfabJWT,
	getLeaderboardAroundCurrentUser,
	getPlayerProfile,
	getPlayerData,
	getPlayerReadOnlyData,
	getPlayerStatistics,
	getItemInventory,
	getCatalog,
	getStoreLoadout,
	getEventsInventory,
	writeTelemetryEvent,
	updatePlayerData,
	purchaseTile,
	getPlayerStatus,
} from 'redux/playfab';
import { setAuthStep, setDataLoaded, setChatAuth } from 'redux/auth';
import Playfab from 'components/Playfab';
import { fetchWPActivities, fetchWPAcfPageOptions, fetchWPNavigation } from 'redux/wpcontent';
import { fetchWPMobileTheme } from 'redux/wppage';
import {
	isAccountCompletedSelector, isAccountDeclinedSelector, playerGroupSelector,
} from 'selector/player';
import { getRandomRooms, getUsers } from 'redux/rooms';
import { inventorySelector } from 'selector/inventory';

function mapStateToProps(state) {
	return {
		teamsUserObjectId: state.teams?.userObjectId,
		playerId: state.playfab?.PlayFabId,
		userJWT: state.auth.userJWT,
		playerChatAuth: state.playfab?.PlayerReadOnlyData?.chat_auth?.Value,
		pollRates: state.playfab?.pollRates,
		isAccountCompleted: isAccountCompletedSelector(state),
		isAccountDeclined: isAccountDeclinedSelector(state),
		IsInvalidLoginJwt: state.playfab?.IsInvalidLoginJwt,
		acfPageOptions: state.wpcontent.acfPageOptions,
		mobileTheme: state.wppage.mobileTheme,
		inventory: inventorySelector(state),
		previousInventory: state.playfab?.previousInventory,
		isDataLoaded: state.auth?.dataLoaded,
		playerStatus: state.playfab?.playerStatus,
		playerGroup: playerGroupSelector(state),
		channel: state.chat.channel,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		authPlayfab,
		authTeamsPlayfab,
		authPlayfabJWT,
		getLeaderboardAroundCurrentUser,
		getPlayerProfile,
		getPlayerData,
		getPlayerReadOnlyData,
		getPlayerStatistics,
		getItemInventory,
		getCatalog,
		updatePlayerData,
		getStoreLoadout,
		setDataLoaded,
		setAuthStep,
		setChatAuth,
		getEventsInventory,
		fetchWPActivities,
		fetchWPAcfPageOptions,
		fetchWPMobileTheme,
		writeTelemetryEvent,
		getRandomRooms,
		purchaseTile,
		getPlayerStatus,
		fetchWPNavigation,
		getUsers,
		createToast,
	}, dispatch)
)(Playfab);
