/**
 * @format
 */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react";
import { useDispatch, useStore } from "react-redux";
import { broadcastMessage, MESSAGE_EMOJI } from "redux/chatSupport";

function EmojiChatSupportController() {
	const dispatch = useDispatch();
	const state = useStore().getState();

	const [emojiLimitReached, setEmojiLimitReached] = useState(false);
	const emojis = state.wpcontent?.acfPageOptions?.acf?.theme?.emojis;

	useEffect(() => {
		if (emojiLimitReached) {
			setTimeout(() => {
				setEmojiLimitReached(false);
			}, 1000);
		}
	}, [emojiLimitReached]);

	function emitChange(name) {
		if (!emojiLimitReached) {
			dispatch(
				broadcastMessage({
					type: MESSAGE_EMOJI,
					message: name,
					channel: state.chatSupport.channel,
					playfabId: state.playfab.PlayFabId,
				})
			);
			setEmojiLimitReached(true);
		}
		// const event = new CustomEvent('emoji_chat', { detail: { data: name, id: 'event-' + Date.now() } });
		// window.dispatchEvent(event);
	}

	return (
		<div className="controller">
			{emojis &&
				emojis.map((emoji, key) => {
					if (emoji.name === "") return null;
					return (
						<img
							key={emoji.name}
							onClick={() => emitChange(`${key}`)}
							className="controller-emoji"
							src={emoji?.image?.sizes?.thumbnail}
							alt={emoji.name}
						/>
					);
				})}
		</div>
	);
}

export default EmojiChatSupportController;
