/** @format */
/* eslint-disable react/function-component-definition */

import React from "react";
import PropTypes from "prop-types";
import BackButton from "containers/BackButton";
import LoadingInnerContent from "containers/LoadingInnerContent";

const Layout = ({
	isLoading,
	titleSlot,
	pageClass,
	backLink,
	backText,
	historyReturn,
	rightSlot,
	children,
	backgroundUrl,
}) => {
	const customBackground = {
		background: `url(${backgroundUrl.url})`,
		backgroundSize: "cover",
	};

	function renderHeader() {
		if (titleSlot || backLink) {
			return (
				<div className="page-header">
					<div className="left">
						{titleSlot && <h3 className="page-title">{titleSlot}</h3>}
						{backLink &&
							(historyReturn ? (
								<BackButton localBack={backText} />
							) : (
								<BackButton link={backLink} localBack={backText} />
							))}
					</div>
					{rightSlot && <div className="right">{rightSlot}</div>}
				</div>
			);
		}
		return null;
	}
	return (
		<LoadingInnerContent isLoading={isLoading} className="loadingWrapper">
			<div className={`layout-title page-wrapper ${pageClass}`}>
				{/* <Navigation /> */}
				<div className="sidenav-left-column" />
				<div className="content-column">
					<div
						className="background"
						style={backgroundUrl ? customBackground : {}}
					/>
					<div className="site-wrapper">
						{renderHeader()}
						{children && <div className="page-content">{children}</div>}
					</div>
				</div>
			</div>
		</LoadingInnerContent>
	);
};

Layout.defaultProps = {
	titleSlot: null,
	backLink: null,
	historyReturn: null,
	backText: null,
	rightSlot: null,
	isLoading: false,
	backgroundUrl: "",
};

Layout.propTypes = {
	pageClass: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	titleSlot: PropTypes.string,
	historyReturn: PropTypes.bool,
	backLink: PropTypes.string,
	backText: PropTypes.string,
	rightSlot: PropTypes.any,
	isLoading: PropTypes.bool,
	backgroundUrl: PropTypes.string,
};
export default Layout;
