import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LoadingJitsi from "components/LoadingJitsi";
import {
	PLAYFAB_EVENTS,
	DOMAIN_MAGIC_COOKIE,
	REACT_APP_JITSI_MAGIC_COOKIE,
} from "Constants";

import {
	configHost,
	interfaceConfigHost,
	configDefaultParticipant,
	interfaceConfigDefaultParticipant,
} from "./JitsiConfig";

function Jitsi({
	url,
	jitsiJwtToken,
	userRole,
	displayName,
	writePlayerEvent,
	setJoinJitsi,
	joinMeetingURL,
	acfPageOptions,
	activity,
}) {
	let localUserId;
	let userRaisedHand = false;
	let userHasSpoken = false;

	const [iframeLoading, setIframeLoading] = useState(true);
	let api;

	function videoConferenceJoined(event) {
		api.executeCommand("displayName", displayName);
		localUserId = event["id"];
		writePlayerEvent({
			name: PLAYFAB_EVENTS.player_meeting_event,
			body: {
				HasJoined: 1,
				MeetingId: joinMeetingURL,
				RoomType: "MeetingRoom",
			},
		});
		setIframeLoading(false);
	}
	function dominantSpeakerChanged(event) {
		if (localUserId === event["id"] && userHasSpoken === false) {
			writePlayerEvent({
				name: PLAYFAB_EVENTS.player_meeting_event,
				body: {
					HasSpoken: 1,
					MeetingId: url,
				},
			});
			userHasSpoken = true;
		}
	}
	function raiseHandUpdated(event) {
		if (localUserId === event["id"] && userRaisedHand === false) {
			writePlayerEvent({
				name: PLAYFAB_EVENTS.player_meeting_event,
				body: {
					HasRaisedHand: 1,
					MeetingId: url,
				},
			});
			userRaisedHand = true;
		}
	}

	function readyToClose() {
		setJoinJitsi(false);
	}

	const initIframeAPI = (jitsiToken, config, interFaceConfig) => {
		const domain = DOMAIN_MAGIC_COOKIE;
		const options = {
			roomName: REACT_APP_JITSI_MAGIC_COOKIE + "/" + url,
			jwt: jitsiToken,
			width: "100%",
			height: "100%",
			configOverwrite: config,
			interfaceConfigOverwrite: interFaceConfig,
			parentNode: document.querySelector("#jitsi-container"),
		};

		if (window.JitsiMeetExternalAPI) {
			api = new window.JitsiMeetExternalAPI(domain, options);
			api.addEventListener(
				"videoConferenceJoined",
				videoConferenceJoined,
				false
			);
			api.addEventListener(
				"dominantSpeakerChanged",
				dominantSpeakerChanged,
				false
			);
			api.addEventListener("raiseHandUpdated", raiseHandUpdated, false);
			api.addEventListener("readyToClose", readyToClose, false);
			api.executeCommand("toggleTileView");
		}
	};

	useEffect(() => {
		if (userRole === 1) {
			initIframeAPI(jitsiJwtToken, configHost, interfaceConfigHost);
		} else {
			initIframeAPI(
				jitsiJwtToken,
				configDefaultParticipant,
				interfaceConfigDefaultParticipant
			);
		}
		// initIframeAPI(jitsiJwtToken, configHost, interfaceConfigHost);

		return () => {
			api.removeListener("videoConferenceJoined", videoConferenceJoined);
			api.removeListener("dominantSpeakerChanged", dominantSpeakerChanged);
			api.removeListener("raiseHandUpdated", raiseHandUpdated);
			api.removeListener("readyToClose", readyToClose);
		};
	}, []);

	return (
		<>
			<LoadingJitsi show={iframeLoading} acfPageOptions={acfPageOptions} />

			{/* eslint-disable-next-line jsx-a11y/alt-text */}
			<div id="jitsi-container" />
		</>
	);
}

Jitsi.defaultProps = {
	url: "",
	jitsiJwtToken: null,
	userRole: null,
	displayName: null,
	joinMeetingURL: null,
	writePlayerEvent: () => {},
	setJoinJitsi: () => {},
	acfPageOptions: null,
	activity: null,
};

Jitsi.propTypes = {
	url: PropTypes.string,
	jitsiJwtToken: PropTypes.string,
	userRole: PropTypes.number,
	displayName: PropTypes.string,
	joinMeetingURL: PropTypes.string,
	writePlayerEvent: PropTypes.func,
	setJoinJitsi: PropTypes.func,
	acfPageOptions: PropTypes.object,
	activity: PropTypes.object,
};

export default Jitsi;
