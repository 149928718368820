import React, { useState } from "react";
import PropTypes from "prop-types";

function Translations({
	activity,
	translations,
	acfPageOptions,
	url,
	setUrl,
	setAdjustedTime,
}) {
	const [showTranslations, setTranslations] = useState(false);

	function changeTranslation(source) {
		setUrl(source);
		setTranslations(false);
		setAdjustedTime((Date.now() - activity.date_begin.valueOf()) / 1000);
	}

	function handleTranslations(e) {
		e.stopPropagation();
		setTranslations(!showTranslations);
	}

	return (
		activity?.data.live_translation !== null && (
			<div className="mobile-translations">
				<div
					role="button"
					aria-label="Translations list"
					tabIndex="-1"
					onClick={(e) => handleTranslations(e)}
					className="button-toggle-translate"
					onKeyDown={(e) => handleTranslations(e)}
				>
					<img
						src={acfPageOptions?.acf?.theme?.icon?.live?.translations?.url}
						alt=""
					/>
				</div>

				<div className="div-translation-options">
					{showTranslations === true &&
						activity?.data.live_translation !== null &&
						translations.map((val) => {
							return (
								<div
									key={val.id}
									role="button"
									tabIndex="-1"
									className={
										val.data.output === url
											? "button-change-translate active-translation"
											: "button-change-translate"
									}
									onClick={() => changeTranslation(val.data.output)}
									onKeyDown={() => changeTranslation(val.data.output)}
								>
									<p>{val.playfab.DisplayName}</p>
								</div>
							);
						})}
				</div>
			</div>
		)
	);
}

Translations.defaultProps = {
	activity: null,
	translations: [],
	acfPageOptions: null,
	url: "",
	setUrl: () => {},
	setAdjustedTime: () => {},
};

Translations.propTypes = {
	activity: PropTypes.object,
	translations: PropTypes.array,
	acfPageOptions: PropTypes.object,
	url: PropTypes.string,
	setUrl: PropTypes.func,
	setAdjustedTime: PropTypes.func,
};

export default Translations;
