import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import VideoBubble from 'components/VideoPlayer/VideoBubble';
import { emptyVideoPlayer, setTileId, setStartTime, setBubbleSrc } from 'redux/video_bubble';
import { videoBubbleActivitySelector } from 'selector/channels';

function mapStateToProps(state) {
	return {
		activity: videoBubbleActivitySelector(state),
		translationData: state.video_bubble?.selectedTranslation,
		tileId: state.video_bubble?.tileId,
		volume: state.video_bubble?.volume,
		elapsepTime: state.video_bubble?.elapsepTime,
		videoBubbleSrc: state.video_bubble?.src,
		// prerecordedStreamURL: streamURLSelector(state),
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		emptyVideoPlayer,
		setTileId,
		setStartTime,
		setBubbleSrc,
	}, dispatch)
)(VideoBubble));
