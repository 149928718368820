import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { ReactComponent as SendIcon } from "assets/icons/pack1/chat/chat-box/icon-send.svg";
import MentionsSuggestions from "./chat/MentionsSuggestions";

function SendMessageInput({
	placeHolder,
	inputMessage,
	setInputMessage,
	messageAuthors,
	formAction,
	disabled,
}) {
	return (
		<div className={`send-message-input ${disabled ? "input-disabled" : ""}`}>
			<form onSubmit={(e) => formAction(e)}>
				<InputGroup>
					<FormControl
						id="input"
						onChange={(e) => setInputMessage(e.target.value)}
						placeholder={placeHolder}
						aria-label={placeHolder}
						aria-describedby="basic-addon2"
						value={inputMessage}
						disabled={disabled}
						autoComplete="off"
					/>
					<MentionsSuggestions
						messageAuthors={messageAuthors}
						inputMessage={inputMessage}
						setInputMessage={setInputMessage}
					/>
					<Button
						type="button"
						variant="outline-secondary"
						id="button-addon2"
						onClick={(e) => formAction(e)}
					>
						<SendIcon />
					</Button>
				</InputGroup>
			</form>
		</div>
	);
}

SendMessageInput.defaultProps = {
	placeHolder: "",
	inputMessage: "",
	setInputMessage: () => {},
	messageAuthors: null,
	formAction: () => {},
	disabled: false,
};

SendMessageInput.propTypes = {
	placeHolder: PropTypes.string,
	inputMessage: PropTypes.string,
	setInputMessage: PropTypes.func,
	messageAuthors: PropTypes.array,
	formAction: PropTypes.func,
	disabled: PropTypes.bool,
};

export default SendMessageInput;