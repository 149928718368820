import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import iconExit from "assets/icons/icon-exit-pip.svg";
import iconTab from "assets/icons/icon-back-to-tab.svg";
import VideoPlayer from "containers/VideoPlayer/VideoPlayer";

function BubbleContent({
	activity,
	bubblePosition,
	closeStream,
	defaultOptions,
	drag,
	history,
	pushHistory,
	srcToReturn,
	tileId,
}) {
	return (
		<div className="video-bubble" style={bubblePosition} ref={drag}>
			{tileId &&
				activity &&
				activity?.canJoin &&
				!history.location.pathname.includes("activity") &&
				(<div className="container-video-bubble">
					<div className="container-video-actions">
						<div
							role="button"
							aria-label="pushHistory"
							tabIndex="-1"
							onClick={() => pushHistory()}
							onKeyPress={() => pushHistory()}
						>
							<img alt="" src={iconTab} />
						</div>
						<div
							role="button"
							aria-label="closeStream"
							tabIndex="-1"
							onClick={() => closeStream()}
							onKeyPress={() => closeStream()}
						>
							<img alt="" src={iconExit} />
						</div>
					</div>
					{
						activity.data.activity_type === 'Prerecorded' ? <VideoPlayer src={srcToReturn()} adjustedTime={(Date.now() - activity.date_begin.valueOf()) / 1000} options={defaultOptions} disableFullScreen={false} activity={activity} isFromVideoBubble/> : <VideoPlayer src={srcToReturn()} options={defaultOptions} disableFullScreen={false} activity={activity} isFromVideoBubble/>
					}
					<div className="footer-bubble">
						<p>
							{activity?.content?.title?.rendered && parse(activity?.content?.title?.rendered)}
						</p>
					</div>
				</div>
				)}
		</div>
	);
}

BubbleContent.defaultProps = {
	activity: {},
	bubblePosition: {},
	closeStream: null,
	defaultOptions: {},
	drag: () => { },
	history: {},
	pushHistory: null,
	srcToReturn: null,
	tileId: null,
};

BubbleContent.propTypes = {
	activity: PropTypes.object,
	bubblePosition: PropTypes.object,
	closeStream: PropTypes.func,
	defaultOptions: PropTypes.object,
	drag: PropTypes.func,
	history: PropTypes.object,
	pushHistory: PropTypes.func,
	srcToReturn: PropTypes.func,
	tileId: PropTypes.string,
};

export default BubbleContent;
