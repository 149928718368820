/* eslint-disable react/function-component-definition */
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "./Buttons/Button";

const Modal = ({ modal, setModal, acfPageOptions, children, className }) => {
	const modalContainer = useRef();

	const handleClick = (e) => {
		if (modalContainer.current.contains(e.target)) {
			return;
		}

		setModal(!modal);
	};

	useEffect(() => {
		if (modal) {
			document.addEventListener("mousedown", handleClick);
		}

		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [modal]);

	const closeModal = () => setModal(!modal);

	return (
		<div className={`modal ${className} ${modal && "show-modal"}`}>
			<div className="modal-container modal-content" ref={modalContainer}>
				<Button
					action={closeModal}
					classes="toggle-modal"
					icon={acfPageOptions?.acf?.theme?.icon?.general?.exit?.image2?.url}
				/>
				{children}
			</div>
		</div>
	);
};

Modal.defaultProps = {
	modal: false,
	setModal: () => {},
	acfPageOptions: null,
	className: null,
};

Modal.propTypes = {
	modal: PropTypes.bool,
	setModal: PropTypes.func,
	acfPageOptions: PropTypes.object,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

export default Modal;
