/**
 * @format
 */

import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { AudioPlayer } from "components/AudioPlayer/AudioPlayer";

function MediaModal({
	media,
	showMediaModal,
	setShowMediaModal,
	setViewedMedia,
	acfPageOptions,
}) {
	const media_type =
		media?.acf?.content_gallery?.acf?.media_type || media?.acf?.media_type;
	let percentageCompleted = 0;
	let totalLength = 0;
	let videoStarted = false;
	let videoTwentyFive = false;
	let videoFifty = false;
	let videoSeventyFive = false;
	let videoNinety = false;
	let videoComplete = false;

	function closeModal() {
		setShowMediaModal(false);
		setViewedMedia(null);
	}

	function onTimeUpdate(e) {
		getPercentage(e.currentTarget.getAttribute("attr"));
	}

	function getPercentage(name) {
		const myPlayer = document.querySelector(".videoModal");

		totalLength = myPlayer.duration;
		percentageCompleted = (myPlayer.currentTime / totalLength) * 100;

		// is 0
		if (!videoStarted && percentageCompleted > 0) {
			videoStarted = true;
			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "play",
				eventLabel: name,
			});
		}
		// is 25
		if (!videoTwentyFive && percentageCompleted > 25) {
			videoTwentyFive = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "25%",
				eventLabel: name,
			});
		}
		// is 50
		if (!videoFifty && percentageCompleted > 50) {
			videoFifty = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "50%",
				eventLabel: name,
			});
		}
		// is 75
		if (!videoSeventyFive && percentageCompleted > 75) {
			videoSeventyFive = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "75%",
				eventLabel: name,
			});
		}

		// is 90
		if (!videoNinety && percentageCompleted > 90) {
			videoNinety = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "90%",
				eventLabel: name,
			});
		}

		// is 100
		if (!videoComplete && percentageCompleted > 99) {
			videoComplete = true;

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "video",
				eventAction: "100%",
				eventLabel: name,
			});
		}
	}

	function mediaContent() {
		switch (media_type) {
			case "image":
				return (
					<img
						className="modal-image"
						src={
							media?.acf?.content_gallery?.acf?.preview_image?.sizes?.large ||
							media?.acf?.preview_image?.sizes?.large
						}
						alt="images icon"
					/>
				);
			case "video":
				return (
					<video
						className="videoModal"
						src={
							media?.acf?.content_gallery?.acf?.video_link ||
							media?.acf?.video_link
						}
						// attr={media?.title?.rendered || media?.post_title}
						onTimeUpdate={onTimeUpdate}
						controls
					/>
				);
			case "audio":
				return (
					<AudioPlayer
						src={
							media?.acf?.content_gallery?.acf?.audio_link ||
							media?.acf?.audio_link
						}
						acfPageOptions={acfPageOptions}
						media={media}
					/>
				);
			default:
				return null;
		}
	}
	return (
		<Modal
			centered
			show={showMediaModal}
			onHide={() => {
				closeModal();
			}}
			id="media-modal"
			dialogClassName="modal-90w media-modal"
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
		>
			<Modal.Header>
				<button
					type="button"
					aria-label="Minimize Form"
					tabIndex="-1"
					onClick={() => closeModal()}
					onKeyPress={() => closeModal()}
				>
					<img
						alt="minimize"
						src={
							acfPageOptions?.acf?.theme?.icon?.general?.exit?.image2?.sizes
								?.thumbnail
						}
					/>
				</button>
			</Modal.Header>
			<Modal.Body className={`${media_type === "video" ? "modal-video" : ""}`}>
				{media_type && mediaContent()}
			</Modal.Body>

			{media_type !== "audio" && (
				<Modal.Footer>
					<Container>
						<Row>
							<Col>
								<h5>
									<span className="font-weight-bold">
										{(media?.title?.rendered &&
											parse(media?.title?.rendered)) ||
											media?.post_title}
									</span>
								</h5>
							</Col>
						</Row>
						<Row>
							<Col>
								{media?.content?.rendered && parse(media?.content?.rendered)}
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			)}
		</Modal>
	);
}

MediaModal.defaultProps = {
	media: null,
	showMediaModal: null,
	acfPageOptions: null,
	setShowMediaModal: () => {},
	setViewedMedia: () => {},
};

MediaModal.propTypes = {
	media: PropTypes.object,
	acfPageOptions: PropTypes.object,
	showMediaModal: PropTypes.bool,
	setShowMediaModal: PropTypes.func,
	setViewedMedia: PropTypes.func,
};

export default MediaModal;
