import React, { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

export const TechSupportContext = createContext();

export function TechSupportContextProvider({ children }) {
	const [request, setRequest] = useState("");
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [selectSupportValue, setSelectSupportValue] = useState("");
	const techSupportProviderValue = useMemo(
		() => ({
			request,
			setRequest,
			formSubmitted,
			setFormSubmitted,
			selectSupportValue,
			setSelectSupportValue,
		}),
		[request, formSubmitted, selectSupportValue]
	);

	return (
		<TechSupportContext.Provider value={techSupportProviderValue}>
			{children}
		</TechSupportContext.Provider>
	);
}

TechSupportContextProvider.defaultProps = {
	children: null,
};

TechSupportContextProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	  ]),
};
