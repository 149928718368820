/**
 * @format
 */

import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import uuid from "react-uuid";
import parse from "html-react-parser";
import { ROUTES } from "Constants";
import Layout from "../Layout/Title";

function CodeOfConduct({
	codeOfConductData,
	fetchWPNoticesData,
	playerGroup,
	pageSlug,
}) {
	const [dataLoaded, setDataLoaded] = useState(false);
	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		};

		return fetchWPNoticesData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	return (
		dataLoaded && (
			<Layout
				titleSlot={codeOfConductData?.acf?.code_of_conduct?.page_title}
				pageClass="terms-desktop"
				isLoading={
					!dataLoaded ||
					(dataLoaded &&
						Object.keys(codeOfConductData).length === 0 &&
						codeOfConductData.constructor === Object)
				}
				backText="Back"
				backLink={ROUTES.NOTICES}
			>
				<div className="terms">
					<Container className="notices-anchors">
						<div className="terms-content">
							{parse(codeOfConductData?.acf?.code_of_conduct?.description)}
						</div>
						<div>
							<ul className="terms-list list-unstyled">
								{codeOfConductData?.acf?.code_of_conduct?.blocks &&
									codeOfConductData.acf.code_of_conduct.blocks.map(
										(termsTitles, i) => (
											<li className="rounded p-2 mb-2">
												<a
													href={`#code_of_conduct-${i}`}
													key={termsTitles.title}
													className=""
												>
													{termsTitles.title}
												</a>
											</li>
										)
									)}
							</ul>
						</div>
					</Container>
					<Container>
						{codeOfConductData?.acf?.code_of_conduct?.blocks &&
							codeOfConductData.acf.code_of_conduct.blocks.map(
								(termsTexts, i) => (
									<Fragment key={uuid()}>
										<div className="term-wrapper" id={`code_of_conduct-${i}`}>
											<h4 className="py-3">{termsTexts.title}</h4>
											{parse(termsTexts.text)}
										</div>
									</Fragment>
								)
							)}
					</Container>
				</div>
			</Layout>
		)
	);
}

CodeOfConduct.defaultProps = {
	codeOfConductData: null,
	pageSlug: null,
	fetchWPNoticesData: () => {},
	playerGroup: null,
};

CodeOfConduct.propTypes = {
	codeOfConductData: PropTypes.object,
	fetchWPNoticesData: PropTypes.func,
	playerGroup: PropTypes.string,
	pageSlug: PropTypes.string,
};

export default CodeOfConduct;
