/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const ContentInnerContainer = ({
	children,
	customClass,
}) => {
	return (
		<div className={`content-inner-container ${customClass}`}>
			{children}
		</div>
	);
}

ContentInnerContainer.defaultProps = {
	customClass: '',
}

ContentInnerContainer.propTypes = {
	children: PropTypes.node.isRequired,
	customClass: PropTypes.string,
};

export default ContentInnerContainer;
