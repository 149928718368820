/** @format */
/* eslint-disable react/jsx-no-useless-fragment */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import ActivityCard from "containers/Channel/ActivityCard";
import { ROUTES } from "Constants";
import Layout from "components/Layout/Title";
import logPageView from 'utils/logPageView';
import NotFound from "containers/Pages/NotFound";

/*
 * Event Page displaying the details of a single event
 */

function Event({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	tile,
	activity,
	fetchWPChannelsData,
	fetchWPActivities,
	fetchWPSpeakersData,
	acfPageOptions,
	playerGroup,
	navigation,
}) {
	if (!activity && acfPageOptions?.acf) {
		return <NotFound />;
	}

	useEffect(() => {

		const data = {
			playerGroup,
		};

		fetchWPChannelsData(data);
		fetchWPActivities(data);
		fetchWPSpeakersData(data);

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	}, []);
	const { tileId } = useParams();
	// const isLoadingData = !activity || !activity?.content;
	const isLoadingData = !tile || tileId !== activity.StoreTileId

	useEffect(() => {
		if (!isLoadingData) {

			//Log PageView
			logPageView('event', playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
		}

		return () => {};
	}, [isLoadingData]);

	function renderSimilarEvents() {
		return null;

		/*if (!activity.content.acf?.similar_events) return null;

		return (
			<div className="similar-events">
				<h2 className="section-title">Similar Events</h2>

				<div className="small-channel-tiles">
					{activity.content.acf?.similar_events.map(event => (
						<div className="channel-tile -small">
							<div className="infos">
								<div className="tile-info">
									<span className="status">LIVE</span>
								</div>

								<h3>{event?.post_name}</h3>

								<div className="tile-bottom">
									<div className="date">
										<div className="day">
											<span className="dayNum bigNum">
												8
											</span>
											<div>
												<span className="dayWeek">Wed</span>
												<span className="monthString">Dec</span>
											</div>
										</div>
										<div className="hours">
											<span className="bigNum">5</span>
											<span className="hour-label">to</span>
											<span className="cycle-label">6 pm</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		);/** */
	}

	function renderSpeakerLink(speaker) {
		if (speaker.acf?.as_single_page === "yes") {
			return (
				<Link to={ROUTES.SPEAKER.replace(":speakerId", speaker.slug)}>
					<h4 className="title">{speaker?.title?.rendered && parse(speaker?.title?.rendered)}</h4>
					<p className="role">{speaker?.acf?.subtitle}</p>
					{speaker?.acf?.location && (
						<p className="location">
							<i className="icon-location" />
							<span>{speaker?.acf?.location}</span>
						</p>
					)}
				</Link>
			);
		}
		return (
			<>
				<h2 className="title">
					{speaker?.title?.rendered &&
						parse(speaker?.title?.rendered)}
				</h2>
				<p className="role">{speaker?.acf?.subtitle}</p>
			</>
		);
	}

	function renderEventSpeakers() {
		if (!activity?.speakers || activity?.speakers?.length === 0) return null;

		return (
			<div className="event-speaker-wrap">
				<h4 className="section-title">
					{acfPageOptions?.acf?.speakers?.title}
				</h4>
				{activity?.speakers.map((speaker) => (
					<div className="speaker-listing-block" key={speaker.id}>
						{speaker?.acf?.photo && (
							<div className="image-wrap">
								<img
									src={speaker?.acf?.photo?.url}
									alt={speaker?.title?.rendered}
								/>
							</div>
						)}
						<div className="text-wrap">
							{renderSpeakerLink(speaker)}
						</div>
					</div>
				))}
			</div>
		); /** */
	}

	return (
		<Layout
			titleSlot={acfPageOptions?.acf?.event?.title}
			pageClass="event-single-page"
			backLink={navigation?.acf?.navigation_acf?.events?.show ? ROUTES.AGENDA : ROUTES.MAIN_HUB }
			historyReturn
			backText={acfPageOptions?.acf?.event?.back_button}
			isLoading={isLoadingData}
			backgroundUrl={acfPageOptions?.acf?.event?.background ? acfPageOptions?.acf?.event?.background : ''}
		>
			<div className={`event-single-wrap ${!activity?.speakers || activity?.speakers?.length === 0 ? 'no-speakers' : ''}`}>
				<div className="event-left-wrap">
					<div className="event-single-block">
						{/* eslint-disable-next-line react/jsx-props-no-spreading*/}
						<ActivityCard {...tile} />
						<div className="event-single-content">
							<div className="wysiwyg">
								{tile?.activity?.content?.content?.rendered &&
									parse(activity?.content?.content?.rendered)}
							</div>
						</div>
					</div>

					{renderSimilarEvents()}
				</div>

				{renderEventSpeakers()}
			</div>
		</Layout>
	);
}

Event.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	tile: null,
	activity: null,
	acfPageOptions: null,
	playerGroup: null,
	navigation: null,
};

Event.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	tile: PropTypes.object,
	activity: PropTypes.object,
	acfPageOptions: PropTypes.object,
	fetchWPChannelsData: PropTypes.func.isRequired,
	fetchWPActivities: PropTypes.func.isRequired,
	fetchWPSpeakersData: PropTypes.func.isRequired,
	playerGroup: PropTypes.string,
	navigation: PropTypes.object,
};

export default Event;
