import React, {
	memo, useEffect, useRef, useState, 
} from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import dayjs from 'dayjs';

function PollResult({ result }) {
	if (!result) return null;

	const [show, setShow] = useState();

	const duration = (dayjs(result.expiration).valueOf() - dayjs().valueOf());

	useEffect(() => {
		if (duration > 0) {
			setShow(true);
		}

		setTimeout(() => {
			setShow(false);
		}, duration);
	}, []);

	const ref = useRef();

	function hide() {
		setShow(false);
	}

	return (
		<CSSTransition in={show} appear timeout={600} unmountOnExit nodeRef={ref}>
			<div className="poll-result" ref={ref} onClick={hide} onKeyDown={hide} role="button" tabIndex="-1">
				{result.stats.map(stat => {
					return (
						<div key={stat.statName} className="stat">
							<div className="name">{stat.statDisplayName}</div>
							<div className="value">{stat.statValue}</div>
						</div>
					);
				})}
			</div>
		</CSSTransition>
	);
}

PollResult.defaultProps = {
	result: null,
};

PollResult.propTypes = {
	result: PropTypes.object,
};

function PollResults({ stats }) {
	if (!stats || stats.length === 0) return null;
	
	return (
		<div className="poll-results">
			{stats.map(result => {
				return (
					<PollResult result={result} key={result.expiration} />
				);
			})}
		</div>
	);
}

PollResults.defaultProps = {
	stats: [],
};

PollResults.propTypes = {
	stats: PropTypes.array,
};

export default memo(PollResults);
