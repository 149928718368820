/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { COMPANEL_TABS } from "Constants";

const PanelCollapse = ({
	show_time_slot,
	show_chat,
	show_qa,
	acfPageOptions,
	setActiveTab,
	collapsable,
	partnerStatus,
}) => {
	const [showComPanel, setShowComPanel] = useState(0);
	const topLeft = document.querySelector(".top-left");
	const mediasListing = document.querySelector('.medias-listing-wrap');
	const resizableContainer = document.querySelector('.resizable-container');
	// const emojiController = document.querySelector(".emoji-controller");
	const comPanel = document.querySelector(".partner-com-panel");
	const chat = showComPanel === 1 ? 0 : 1;
	const galleryFilterCtn = document.querySelector('.gallery-filters');
	const bookingButtonCtn = document.querySelector('.booking-button-ctn');
	const galleryChat = document.querySelector('.gallery-chat');
	const calcWidth = 'calc(100% - var(--activity-right-col-width))';
	const partnerPageWidth = 'calc(100% - var(--chatWidth))';
	const smallChatWidth = 'calc(100% - var(--smallChatWidth))';
	const mediumChatWidth = 'calc(100% - var(--mediumChatWidth))';

	useEffect(() => {
	  handleShowComPanel()
	}, [])


	function handleShowComPanel() {
		const chatContainer = document.querySelector(".activity-right-column");
		const chatLeftColumn = document.querySelector(".com-panel");

		if(chatContainer && chatLeftColumn){
			if ( chat === 0 ) {
				chatContainer.classList.remove("open-chat");
				chatContainer.classList.add("close-chat");
				chatContainer.classList.remove("open-chat");

				// waiting 1/2 sec until the panel is closed
				setTimeout(() => {
					if (comPanel) comPanel.classList.add("closed");
				}, 500);

				if (galleryFilterCtn) galleryFilterCtn.style.maxWidth = '94%';
				if (bookingButtonCtn) bookingButtonCtn.style.maxWidth = '94%';

				// if (emojiController) emojiController.style.right = "0";

				if (galleryChat) galleryChat.style.zIndex = "-1";

				if (topLeft) {
					topLeft.style.width = "100%";
					topLeft.style.flex = "1";
				}

				if (mediasListing) {
					mediasListing.style.maxWidth  = "95%";
					mediasListing.style.width  = "100%";
					mediasListing.style.flex = "1";
				}
				if (resizableContainer) {
					resizableContainer.style.maxWidth  = "85%";
					resizableContainer.style.width  = "100%";
					resizableContainer.style.flex = "1";
				}

			} else {
				chatContainer.classList.add("open-chat");
				chatContainer.classList.remove("close-chat");

				if (comPanel) {
					comPanel.classList.remove("closed");
				}

				if (galleryChat) galleryChat.style.zIndex = "2";

				// if (emojiController) emojiController.style.right = "";
				// chatLeftColumn.style.flex = "1";
				if (topLeft) {
					topLeft.style.width = calcWidth;
					topLeft.style.flex = "auto";
				}

				if (galleryFilterCtn) {
					galleryFilterCtn.style.maxWidth = calcWidth;
					bookingButtonCtn.style.maxWidth = calcWidth;
				}

				if (mediasListing) {
					mediasListing.style.maxWidth = calcWidth;
					mediasListing.style.flex = "auto";
				}
				if (resizableContainer) {
					if(window.innerWidth > 1440) {
						resizableContainer.style.maxWidth = partnerPageWidth;
					}
					else if(window.innerWidth > 1280) {
						resizableContainer.style.maxWidth = mediumChatWidth;
					} else {
						resizableContainer.style.maxWidth = smallChatWidth;

					}
					resizableContainer.style.flex = "auto";
				}
			}
		}

		setShowComPanel(chat);
	}

	function panelCollapseHeight() {
		let panelHeight;
		if (show_chat && show_time_slot && show_qa) {
			panelHeight = "chat-qa-availabilities";
		} else if (show_chat && show_qa) {
			panelHeight = "chat-qa";
		} else if (show_chat && show_time_slot) {
			panelHeight = "chat-availabilities";
		} else {
			panelHeight = "single";
		}
		return panelHeight;
	}

	function ChatTab() {
		if (show_chat === false) {
			return null;
		}
		if ((show_qa === true && partnerStatus === false) || (show_qa === false && partnerStatus === false && show_chat === true)) {
			return (
				<div
					className='label-container chat-tab'
					tabIndex='-1'
					role='button'
					onClick={() => {
						handleShowComPanel();
						setActiveTab(COMPANEL_TABS.CHAT);
					}}
					onKeyDown={() => handleShowComPanel()}
				>
					<span>{acfPageOptions?.acf?.live_chat_panel?.chat_title}</span>
				</div>
			);
		}
		if (partnerStatus === false) {
			return null;
		}
		if (partnerStatus === true) {
			return (
				<div
					className='label-container chat-tab'
					tabIndex='-1'
					role='button'
					onClick={() => {
						handleShowComPanel();
						setActiveTab(COMPANEL_TABS.CHAT);
					}}
					onKeyDown={() => handleShowComPanel()}
				>
					<span>{acfPageOptions?.acf?.live_chat_panel?.chat_title}</span>
				</div>
			);
		}
		return null;
	}

	return (
		collapsable && (
			<div className={`com-panel-toggle ${showComPanel ? "without-background" : ""} ${panelCollapseHeight()} `}>
				<div
					className={`icon ${showComPanel === 1 ? "opened" : "closed"}`}
					tabIndex='-1'
					role='button'
					aria-label="showComPanel"
					onClick={handleShowComPanel}
					onKeyPress={handleShowComPanel}
				>
					<img className='opened' src={acfPageOptions?.acf?.theme?.icon?.partner?.collapse_chat?.url} alt='' />
					<img className='closed' src={acfPageOptions?.acf?.theme?.icon?.partner?.expand_chat?.url} alt='' />
				</div>
				{!showComPanel && (
					<>
						<ChatTab />
						{show_qa && (
							<div
								className='label-container qa-tab'
								tabIndex='-1'
								role='button'
								onClick={() => {
									handleShowComPanel();
									setActiveTab(COMPANEL_TABS.QA);
								}}
								onKeyDown={() => handleShowComPanel()}
							>
								<span>{acfPageOptions?.acf?.live_chat_panel?.question_title}</span>
							</div>
						)}

						{show_time_slot && (
							<div
								className='label-container availabilities-tab'
								tabIndex='-1'
								role='button'
								onClick={() => {
									handleShowComPanel();
									setActiveTab(COMPANEL_TABS.AVAILABILITIES);
								}}
								onKeyDown={() => handleShowComPanel()}
							>
								<span>{acfPageOptions.acf?.partner?.availabilities_label}</span>
							</div>
						)}
					</>
				)}
			</div>
		)
	);
};

PanelCollapse.defaultProps = {
	show_time_slot: null,
	show_chat: null,
	show_qa: null,
	acfPageOptions: null,
	setActiveTab: () => {},
	collapsable: null,
	partnerStatus: null,
};

PanelCollapse.propTypes = {
	show_time_slot: PropTypes.bool,
	show_chat: PropTypes.bool,
	show_qa: PropTypes.bool,
	acfPageOptions: PropTypes.object,
	setActiveTab: PropTypes.func,
	collapsable: PropTypes.bool,
	partnerStatus: PropTypes.bool,
};

export default PanelCollapse;
