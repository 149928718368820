import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as SearchIcon } from "assets/icons/pack1/speakers/icon-search.svg";
import { ReactComponent as CloseIcon } from "assets/icons/icon-x-mark.svg";
import { object, func } from "prop-types";

function AgendaSearch({ onChange, acfPageOptions }) {
	const ref = useRef();
	const input = useRef();
	const [val, setVal] = useState("");

	useEffect(() => {
		if (!val && document.activeElement !== input.current) {
			ref.current.classList.remove("active");
		}
	}, [val]);

	const handleAddClass = () => {
		ref.current.classList.add("active");
	};

	const handleChange = (event) => {
		setVal(event.target.value);
		onChange(event.target.value);
	};

	const handleClearInput = () => {
		input.current.focus();
		setVal("");
		onChange("");
	};

	const onInputBlur = () => {
		if (!val) {
			ref.current.classList.remove("active");
		}
	};

	const handleClickSearchIcon = () => {
		input.current.focus()
	}

	return (
		<div ref={ref} className="search-box">
			<input
				id="search"
				name="search"
				type="text"
				onBlur={onInputBlur}
				onChange={handleChange}
				value={val}
				ref={input}
				placeholder={acfPageOptions?.acf?.agenda?.search_placeholder}
			/>
			<div
				role="button"
				tabIndex="-1"
				onKeyPress={handleAddClass}
				onClick={handleAddClass}
				className="search-btn"
			>
				{val ? (
					<CloseIcon onClick={handleClearInput} className="close-search-icon" />
				) : (
					<SearchIcon onClick={handleClickSearchIcon} className="search-icon" />
				)}
			</div>
		</div>
	);
}

AgendaSearch.defaultProps = {
	acfPageOptions: null,
};

AgendaSearch.propTypes = {
	onChange: func.isRequired,
	acfPageOptions: object,
};

export default AgendaSearch;
