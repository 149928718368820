import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Gallery from 'components/ContentGallery/Gallery';

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		navigation: state.wpcontent.navigation,
	};
}

export default connect(mapStateToProps, (dispatch) => bindActionCreators({}, dispatch))(Gallery);
