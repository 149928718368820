import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchWPPartnerData } from 'redux/wppage';
import { writeTelemetryEvent } from 'redux/playfab';
import Partner from 'components/Pages/Partner';
import { chatJoinRoom, chatLeaveRoom, clearMessages } from 'redux/chat';
import { playerGroupSelector, userNameSelector } from 'selector/player';

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		partnerData: state.wppage.techpartnerSingle,
		acfPageOptions: state.wpcontent.acfPageOptions,
		username: userNameSelector(state),
		playerGroup: playerGroupSelector(state),
		method: state.auth?.method,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		fetchWPPartnerData,
		writeTelemetryEvent,
		chatJoinRoom,
		chatLeaveRoom,
		clearMessages,
	}, dispatch)
)(Partner);
