import { createSlice } from '@reduxjs/toolkit';

/**
*Player profile card used in the chat when you click on a player's name to display his information
*/
const profile_card = createSlice({
	name: 'profile_card',
	reducers: {
		setActiveProfileCard: {
			reducer: (state, action) => {
				return {
					...state,
					active: action.payload,
				};
			},
		},
	},
	extraReducers: {},
	initialState: {
		active: null,
	},
});

export default profile_card;

export const { setActiveProfileCard } = profile_card.actions;
