// @ts-check

/**
 * @param {string} path
 * @returns {string}
 */
export function tx(path, texts) {
	if (texts) {
		const parts = path.split('.');
		let obj = texts;

		while (parts.length !== 0) {
			const part = parts.shift();
			if (!obj[part]) return '';
			if (parts.length === 0) return obj[part];
			obj = obj[part];
		}
	}
	return '';
}

export function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}
