import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from "html-react-parser";
import Layout from "components/Layout/Title";

function WPPage({
	pageSlug,
	wpPageContent,
	fetchWPPageData,
	playerGroup,
}) {
	const [dataLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		}

		return fetchWPPageData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	return (
		<Layout
			titleSlot={
				(wpPageContent?.title?.rendered && parse(wpPageContent?.title?.rendered)) ||
				null
			}
			pageClass="page-wrapper"
			isLoading={
				!dataLoaded
			}
		>
			<div className="wysiwyg">{wpPageContent?.content?.rendered && parse(wpPageContent?.content?.rendered)}</div>
		</Layout>
	);
}

WPPage.defaultProps = {
	pageSlug: null,
	wpPageContent: null,
	fetchWPPageData: () => {},
	playerGroup: null,

};

WPPage.propTypes = {
	pageSlug: PropTypes.string,
	wpPageContent: PropTypes.object,
	fetchWPPageData: PropTypes.func,
	playerGroup: PropTypes.string,
};

export default WPPage;
