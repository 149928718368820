import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowRooms from 'components/Pages/ShowRooms';
import { playerGroupSelector } from 'selector/player';

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		partnersData: state.wppage.techpartners,
		acfPageOptions: state.wpcontent.acfPageOptions,
		showrooms: state?.wppage.showrooms,
		playerGroup: playerGroupSelector(state),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
	}, dispatch)
)(ShowRooms);
