/**
 * @format
 */
import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import parse from "html-react-parser";
import Layout from "mobile/components/layout/Title";
import logPageView from "utils/logPageView";
import { ROUTES } from "Constants";
import ShowAskQuestions from "../components/ShowAskQuestions";

function Faq({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	faqData,
	fetchWPPageData,
	acfPageOptions,
	playerGroup,
	pageSlug,
}) {
	const [dataLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		};

		return fetchWPPageData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"faq",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	/**
	 * TODO: Move to their own component
	 */
	return (
		<Layout
			titleSlot={
				(faqData?.title?.rendered && parse(faqData?.title?.rendered)) || "FAQ"
			}
			pageClass="faq-toggles"
			isLoading={
				!faqData ||
				(faqData &&
					Object.keys(faqData).length === 0 &&
					faqData.constructor === Object)
			}
			backgroundUrl={
				acfPageOptions?.acf?.faq?.background ? acfPageOptions?.acf?.faq?.background : ""
			}
			headerClass="faqs-container"
			backLink={ROUTES.HOME}
			backText=" "
		>
			<Container className="pb-5">
				{faqData?.acf?.topic &&
					faqData.acf.topic.map((faqTopic) => (
						<Fragment key={faqTopic.title}>
							<h2 className="text-uppercase pt-2">{faqTopic.title}</h2>
							{faqTopic.faqs &&
								faqTopic.faqs.map((faqSingle, index) => (
									<ShowAskQuestions
										key={faqSingle.faq_question}
										faqSingle={faqSingle}
										eventKey={index}
									/>
								))}
						</Fragment>
					))}
			</Container>
		</Layout>
	);
}

Faq.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	faqData: null,
	pageSlug: null,
	fetchWPPageData: () => { },
	acfPageOptions: null,
	playerGroup: null,
};

Faq.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	faqData: PropTypes.object,
	fetchWPPageData: PropTypes.func,
	acfPageOptions: PropTypes.object,
	playerGroup: PropTypes.string,
	pageSlug: PropTypes.string,
};

export default Faq;
