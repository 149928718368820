import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import AttendanceModal from "mobile/components/session/AttendanceModal";
import { updatePlayerData } from "redux/playfab";

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerData: state.playfab?.PlayerData,
		attendanceType: state.playfab?.PlayerData?.AttendanceType?.Value,
	};
}

export default withRouter(
	connect(mapStateToProps, (dispatch) => bindActionCreators({ updatePlayerData }, dispatch))(AttendanceModal)
);
