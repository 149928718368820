/** @format */

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NotFound from "components/Pages/NotFound";

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		acfNavigation: state.wpcontent.navigation,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators({}, dispatch)
)(NotFound);
