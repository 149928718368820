import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

function ShowTermContent(props) {
	const { content, id } = props;
	const { text, title } = content || {};

	return (
		<div className="term-wrapper" id={id}>
			<h4 className="py-3">{title}</h4>
			{parse(text)}
		</div>
		// TODO add back to top
	);
}
export default ShowTermContent;

ShowTermContent.propTypes = {
	content: PropTypes.object,
	id: PropTypes.string,
};

ShowTermContent.defaultProps = {
	content: {},
	id: "",
};
