import React from "react";
import PropTypes from 'prop-types';

function Filter({ filter, setFilterParam, filterParam }) {
	return (
		<button type="button" className="filter" onClick={() => setFilterParam(filter)}>
			{filter?.category_label}
			<div className={`gallery-filters-underline ${filter?.category_value === filterParam?.category_value ?'active' : ''}`} />
		</button>
	);
}

Filter.defaultProps = {
	filter: null,
	filterParam: null,
	setFilterParam: () => {},
};

Filter.propTypes = {
	filterParam: PropTypes.object,
	setFilterParam: PropTypes.func,
	filter: PropTypes.object,
};

export default Filter;
