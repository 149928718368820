/** @format */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import parse from "html-react-parser";
import { now } from "utils/dateUtils";
import { ROUTES } from "Constants";

function Tile({
	tile,
	index,
	acfPageOptions,
	writePlayerEvent,
	getStoreLoadout,
	getItemInventory,
}) {
	const icon =
		acfPageOptions?.acf?.theme?.icon?.activity_card?.join?.image_1?.url;
	const hover =
		acfPageOptions?.acf?.theme?.icon?.activity_card?.join?.image_2?.url;
	const remove =
		acfPageOptions.acf?.theme?.icon?.activity_card?.agenda?.remove?.sizes
			?.thumbnail;

	const ref = useRef();
	const localBegin = tile.begin;
	const hourStart = localBegin.hour();
	const minuteStart = Math.floor((localBegin.minute() / 60) * 4);

	const localEnd = tile.end;
	const hourEnd = localEnd.hour();
	const minuteEnd = Math.floor((localEnd.minute() / 60) * 4);

	const gridStart = hourStart * 4 + minuteStart;
	const gridEnd = hourEnd * 4 + minuteEnd;

	const nowDate = now();

	const isLive = nowDate.isBefore(localEnd) && nowDate.isAfter(localBegin);

	const featuredTileClass = tile.activity?.data?.is_featured
		? "featured"
		: null;

	const tileProps = {
		key: tile.id,
		className:
			`tile-${index}` +
			(isLive ? " is-live" : "") +
			` ${featuredTileClass}`,
		to: ROUTES.ACTIVITY.replace(":tileId", tile.activity.StoreTileId),
		style: {
			gridRowStart: gridStart + 1,
			gridRowEnd: gridEnd + 1,
		},
	};

	const isSmall = gridEnd - gridStart < 4;
	if (isSmall) {
		tileProps["data-small"] = true;
	}

	const title =
		tile?.activity?.content?.title?.rendered || tile?.activity?.DisplayName;

	const titleRef = useRef();

	const getColorClass = (tileColor) => {
		switch (tileColor) {
			case "2":
				return "secondary-gradient-background";
			case "3":
				return "tertiary-gradient-background";
			default:
				return "primary-gradient-background";
		}
	};

	const colorClass = getColorClass(tile?.activity?.colorCode);

	function unregister() {
		writePlayerEvent({
			name: "player_unsubscribed_activity",
			body: {
				ItemInstanceId: tile?.activity.item_instance_id,
			},
		}).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 2000),

			setTimeout(() => {
				getItemInventory();
			}, 2300)
		);
	}

	function requiredCheck(activity_id) {
		let isRequired = false;
		const granted_items = acfPageOptions.acf?.global?.granted_items;
		if (acfPageOptions.acf?.global?.granted_items) {
			for (let i = 0; i < granted_items?.length; i += 1) {
				if (granted_items[i].tile_id === activity_id.StoreTileId) {
					isRequired = true;
					break;
				}
			}
		}
		return isRequired;
	}

	useEffect(() => {
		if (titleRef.current) {
			titleRef.current.style.setProperty(
				"--height",
				titleRef.current.scrollHeight + "px"
			);
		}
	}, [title]);

	const comp = isLive ? (
		<div ref={ref} {...tileProps}>
			<div className={`inner ${colorClass}`}>
				<NavLink {...tileProps}>
					<div className="live">
						{acfPageOptions.acf?.global?.activity_card_live}
					</div>
					<div className="title" ref={titleRef}>
						{title && parse(title)}
					</div>
				</NavLink>
				<div className="row-btns">
					<NavLink to={tileProps.to} className="col text-end">
						<div className="icon-join-wrapper">
							<img className="icon-join" src={icon} alt="" />
							<img
								className="icon-join hover-icon"
								src={hover}
								alt=""
							/>
						</div>
					</NavLink>
					{(tile?.activity?.canUnregister && !tile?.activity?.customPayload?.topic_description  &&
					!requiredCheck(tile?.activity)) && (
						<div
							className="col text-end icon-remove-wrapper"
							onClick={unregister}
							onKeyDown={() => {}}
							role="button"
							aria-label="unregister"
							tabIndex={-1}
						>
							<img
								className="icon-remove"
								src={remove}
								alt=""
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	) : (
		<div ref={ref} {...tileProps}>
			<div className={`inner ${colorClass}`}>
				<div className="title" ref={titleRef}>
					{title && parse(title)}
				</div>
				<div className="row-btns">
					<div className="icon-join-wrapper">&nbsp;</div>
					{(tile?.activity?.canUnregister &&
						!tile?.activity?.customPayload?.topic_description &&
						!requiredCheck(tile?.activity)) && (
						<div
							className="col text-end icon-remove-wrapper"
							onClick={unregister}
							onKeyDown={() => {}}
							role="button"
							aria-label="unregister"
							tabIndex={-1}
						>
							<img
								className="icon-remove"
								src={remove}
								alt=""
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);

	if (acfPageOptions?.acf) {
		return (
			<CSSTransition
				key={tile.activity.StoreTileId}
				timeout={600}
				nodeRef={ref}
			>
				{comp}
			</CSSTransition>
		);
	}
	return null;
}

Tile.defaultProps = {
	index: null,
	icon: null,
	hover: null,
	writePlayerEvent: () => {},
	getStoreLoadout: () => {},
	getItemInventory: () => {},
	acfPageOptions: null,
};

Tile.propTypes = {
	tile: PropTypes.object.isRequired,
	index: PropTypes.number,
	icon: PropTypes.string,
	hover: PropTypes.string,
	writePlayerEvent: PropTypes.func,
	getStoreLoadout: PropTypes.func,
	getItemInventory: PropTypes.func,
	acfPageOptions: PropTypes.object,
};
export default Tile;
