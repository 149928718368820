import React from "react";
import { Container} from "react-bootstrap";
import PropTypes from "prop-types";

function Points({ acfPageOptions, leaderboardOnPosition, sessions, playfabId }) {
	function getStat(type) {
		let data = "";
		for (let i = 0; i < leaderboardOnPosition.length; i += 1) {
			if (playfabId === leaderboardOnPosition[i].PlayFabId) {
				if (type === "points") {
					data = leaderboardOnPosition[i].StatValue;
				} else if (type === "position") {
					for (let a = 0; a < sessions.length; a += 1) {
						if (sessions[a].StatisticName === "sessions") {
							data = sessions[a].Value;
						}
					}
				}
			}
		}
		return data;
	}

	return acfPageOptions.acf?.profile?.header?.show_points ? (
		<Container className="rounded pb-1 pt-2 px-2 profile-points">
			<span>
				{getStat("points")}
				<sup className="px-1">
					{acfPageOptions.acf?.profile?.header?.points_title}
				</sup>
			</span>
		</Container>
	) : null;
}

Points.defaultProps = {
	acfPageOptions: null,
	leaderboardOnPosition: [],
	sessions: [],
	playfabId: "",
};

Points.propTypes = {
	acfPageOptions: PropTypes.object,
	leaderboardOnPosition: PropTypes.array,
	sessions: PropTypes.array,
	playfabId: PropTypes.string,
};

export default Points;
