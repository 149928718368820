/** @format */
/* eslint-disable no-unsafe-optional-chaining */

import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ParticipantCard from "containers/Rooms/ParticipantCard";
import LoadingInnerContent from "containers/LoadingInnerContent";
import Button from "components/Buttons/Button";
import Modal from "components/Modal";
import LoadingGif from "components/LoadingGif";
import Layout from "components/Layout/Title";
import { REACT_APP_APP_NAME, ROUTES, USERMODERATORROOMSTAT, ROOMSETTINGS } from "Constants";

import logPageView from "utils/logPageView";

function AllChats({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	getRandomRooms,
	createRandomRooms,
	inviteToRandomRoom,
	getUsers,
	rooms,
	activeRoom,
	users,
	setActiveRoom,
	resetActiveRoom,
	mod_status,
	acfPageOptions,
}) {
	const [showRoomModal, setShowRoomModal] = useState(false);
	const [showParticipantModal, setShowParticipantModal] = useState(false);
	const [revealParticipantsList, setRevealParticipantsList] = useState(false);
	const [roomSubmit, setRoomSubmit] = useState(false);
	const [roomName, setRoomName] = useState("");
	const [roomPrivacy, setRoomPrivacy] = useState("");
	const [participant, setParticipant] = useState("");
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [inviteList, setInviteList] = useState([]);
	const { add_room_label, created_by_label, full_room_label, invite_label, join_label, participants_label, title } =
		acfPageOptions?.acf?.chat_rooms || {};
	const { create_room_label, public_label, private_label, room_name_label, room_type_label, select_option_label } =
		acfPageOptions?.acf?.chat_rooms?.add_room_modal || {};
	const {
		invite_button_label,
		placeholder,
		title: participants_title,
	} = acfPageOptions?.acf?.chat_rooms?.add_participants_modal || {};

	useEffect(() => {
		getUsers();
		const getRoomsInterval = setInterval(() => {
			getRandomRooms();
			getUsers();
		}, 60000);

		return () => {
			clearInterval(getRoomsInterval);
		};
	}, []);

	useEffect(() => {
		if (!roomSubmit) return null;
		setRevealParticipantsList(true);
		return getRandomRooms();
	}, [roomSubmit]);

	useEffect(() => {
		if (!revealParticipantsList) {
			setInviteList([]);
			return resetActiveRoom();
		}
		return getUsers();
	}, [revealParticipantsList]);

	useEffect(() => {
		if (!users) return null;

		return setFilteredUsers(users);
	}, [users]);

	useEffect(() => {
		if (!participant || participant === "")
			return () => {
				//users = users - invited users
				// ...
				setFilteredUsers(users);
			};

		const resultUsers = users.filter((user) => {
			return user?.displayName?.toLowerCase().includes(participant.toLowerCase());
		});

		//users = users - invited users
		// ...

		return setFilteredUsers(resultUsers);
	}, [participant]);

	useEffect(() => {
		//Log PageView
		logPageView("rooms", playerStatistics, playfabId, playerData, leaderboardOnUserPosition[0]?.Position, null);
	}, []);

	const addParticipantList = (selectedUser) => {
		let updatedList = filteredUsers;

		for (let i = 0; i < updatedList.length; i += 1) {
			if (updatedList[i].playfabId === selectedUser.playfabId) {
				updatedList = updatedList.filter((el) => el.playfabId !== selectedUser.playfabId);
			}
		}

		return setFilteredUsers(updatedList);
	};

	const removeParticipantList = (selectedUser) => {
		return setFilteredUsers([selectedUser, ...filteredUsers]);
	};

	const onRoomFormSubmit = (e) => {
		e.preventDefault();
		return onNewRoomSubmit();
	};

	const onNewRoomSubmit = () => {
		if (!roomName || !roomPrivacy) return null;

		let isPublic = 0;
		if (roomPrivacy === ROOMSETTINGS.PUBLIC) {
			isPublic = 1;
		}
		return createRandomRooms({ roomName, isPublic }).then(() => {
			setShowRoomModal(false);
			setRoomSubmit(true);
		});
	};

	const displayParticipantsList = (room) => {
		if (!room) return null;
		setActiveRoom(room?.roomId);
		setShowParticipantModal(true);
		return setRevealParticipantsList(true);
	};

	const inviteParticipants = () => {
		inviteToRandomRoom({ roomId: activeRoom, participants: inviteList });
		setShowParticipantModal(false);
		setShowRoomModal(false);
		return setRevealParticipantsList(false);
	};

	const onUpdateValue = (value) => {
		setParticipant(value);
	};

	const getRightUser = (id) => {
		let rightUser = null;
		for (let i = 0; i < users.length; i += 1) {
			if (id === users[i].playfabId) {
				rightUser = users[i];
			}
		}
		return rightUser;
	};

	return (
		<Layout
			titleSlot={title}
			rightSlot={
				mod_status === USERMODERATORROOMSTAT.MODERATOR && (
					<Button
						action={() => setShowRoomModal(true)}
						classes="primary large button-add-room"
						text={add_room_label}
					/>
				)
			}
			pageClass="all-chat-page"
			isLoading={!rooms}
			backgroundUrl={acfPageOptions?.acf?.chat_rooms?.background ? acfPageOptions?.acf?.chat_rooms?.background : ""}
		>
			{rooms?.List &&
				rooms?.List?.length > 0 &&
				rooms?.List.map((room) => (
					<div key={room.roomId} className="room">
						<div className="room-container">
							<div className={room?.public ? "room public" : "room private"}>
								<div className="room-info">
									<h4 className="room-name">{room?.subject}</h4>
									<div className="additional-info">
										{room?.public ? (
											<p className="public-label">{public_label}</p>
										) : (
											<p className="private-label">{private_label}</p>
										)}
										<p className="created-by">
											{room?.owner
												? created_by_label + " " + room?.owner?.displayName
												: created_by_label + " " + REACT_APP_APP_NAME}
										</p>
										{mod_status === USERMODERATORROOMSTAT.MODERATOR && (
											<Button
												action={() => {
													return displayParticipantsList(room);
												}}
												classes="primary medium invite-button"
												text={invite_label}
											/>
										)}
										<div className="room-actions">
											{room?.userCount < ROOMSETTINGS.MAXUSERCOUNT ? (
												<div className="join-group">
													<div className="user-count"><span>{room?.userCount + " " + participants_label}</span></div>
													<Button
														link={ROUTES.RANDOM_ROOM_SINGLE.replace(":roomId", room.roomId)}
														classes="primary medium join-button"
														text={join_label}
													/>
												</div>
											) : (
												<div className="full-room">{full_room_label}</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			<Modal modal={showRoomModal} setModal={setShowRoomModal} acfPageOptions={acfPageOptions}>
				<div className="modal-first-form">
					<h4 className="modal-title">{create_room_label}</h4>
					<form onSubmit={onRoomFormSubmit}>
						<span>{room_name_label}</span>
						<input type="text" value={roomName} onChange={(e) => setRoomName(e.target.value)} />
						<span>{room_type_label}</span>
						<label className="select-label">
							<select value={roomPrivacy} onChange={(e) => setRoomPrivacy(e.target.value)}>
								<option>{select_option_label}</option>
								<option value="Public">{public_label}</option>
								<option value="Private">{private_label}</option>
							</select>
						</label>
						<Button
							action={
								roomName && roomPrivacy
									? () => {
										onNewRoomSubmit();
									  }
									: null
							}
							classes={`primary large create-room-button ${roomName && roomPrivacy ? "" : "disabled"}`}
							text={create_room_label}
						/>
					</form>
				</div>
			</Modal>
			<Modal modal={showParticipantModal} setModal={setShowParticipantModal} acfPageOptions={acfPageOptions}>
				<div className="modal-first-form">
					<h4 className="modal-title">{participants_title}</h4>
				</div>
				<input
					placeholder={placeholder}
					type="text"
					value={participant}
					className="input-search-invite"
					onChange={(e) => onUpdateValue(e.target.value)}
				/>
				<LoadingGif acfPageOptions={acfPageOptions} status={!filteredUsers ? "active" : ""} />
				<div className={`div-container-users ${!filteredUsers ? "content-loading" : ""}`}>
					<LoadingInnerContent isLoading={!inviteList} className="loadingWrapper">
						{inviteList &&
							inviteList.length > 0 &&
							inviteList.map((val, index) => {
								return (
									<ParticipantCard
										// eslint-disable-next-line react/no-array-index-key
										key={val + index}
										user={getRightUser(val)}
										activeRoom={activeRoom}
										removeParticipantList={removeParticipantList}
										inviteList={inviteList}
										setInviteList={setInviteList}
									/>
								);
							})}
						{inviteList && inviteList.length > 0 && <hr />}
					</LoadingInnerContent>
					<LoadingInnerContent isLoading={!filteredUsers} className="loadingWrapper">
						{filteredUsers &&
							filteredUsers.length > 0 &&
							filteredUsers.slice(0, ROOMSETTINGS.MAXPARTICIPANTCARDDISPLAYED).map((user, idx) => (
								<ParticipantCard
									// eslint-disable-next-line react/no-array-index-key
									key={user?.displayName + idx}
									user={user}
									activeRoom={activeRoom}
									addParticipantList={addParticipantList}
									inviteList={inviteList}
									setInviteList={setInviteList}
								/>
							))}
					</LoadingInnerContent>
				</div>
				<Button
					action={() => {
						return inviteParticipants();
					}}
					classes="primary large button-submit-invite"
					text={invite_button_label}
				/>
			</Modal>
		</Layout>
	);
}

AllChats.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	rooms: null,
	activeRoom: null,
	users: null,
	getRandomRooms: () => {},
	getUsers: () => {},
	createRandomRooms: () => {},
	inviteToRandomRoom: () => {},
	setActiveRoom: () => {},
	resetActiveRoom: () => {},
	mod_status: 0,
	acfPageOptions: null,
};

AllChats.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	activeRoom: PropTypes.string,
	acfPageOptions: PropTypes.object,
	rooms: PropTypes.object,
	users: PropTypes.array,
	getRandomRooms: PropTypes.func,
	getUsers: PropTypes.func,
	createRandomRooms: PropTypes.func,
	inviteToRandomRoom: PropTypes.func,
	setActiveRoom: PropTypes.func,
	resetActiveRoom: PropTypes.func,
	mod_status: PropTypes.number,
};

export default memo(AllChats);
