import React, { useRef } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { CSSTransition } from "react-transition-group";

function LoadingJitsi({ show, acfPageOptions }) {
	const ref = useRef();

	return (
		<CSSTransition in={show} appear timeout={600} unmountOnExit nodeRef={ref}>
			<div ref={ref} className="jitsi-loading-screen">
				<div className="loadingWrapper-jitsi">
					<img className="gif-loading" src={acfPageOptions.acf?.theme?.logo?.loading?.sizes?.large} alt="" />
					{acfPageOptions.acf?.private_meeting?.setup?.title && parse(acfPageOptions.acf?.private_meeting?.setup?.title)}
				</div>
			</div>
		</CSSTransition>
	);
}

LoadingJitsi.defaultProps = {
	show: false,
	acfPageOptions: null,
};

LoadingJitsi.propTypes = {
	show: PropTypes.bool,
	acfPageOptions: PropTypes.object,
};

export default LoadingJitsi;
