/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
	PLAYFAB_EVENTS,
	REACT_APP_LOADING_BACKGROUND,
	REACT_APP_LIGHTSPEED_CLIENT,
} from "Constants";

/*
    Banned page displayed if the user has the Account Banned item in the inventory
 */

function Banned({ acfPageOptions, writePlayerEvent }) {
	useEffect(() => {
		writePlayerEvent({
			name: PLAYFAB_EVENTS.player_banned_page,
			body: {
				Banned: 1,
			},
		});
	}, []);
	//TODO: Use Status component
	return (
		<>
			<div className="status-screens">
				<div
					className="background-img"
					style={{
						backgroundImage:
							acfPageOptions.acf?.progress_screens?.background?.url ||
							"url(" + REACT_APP_LOADING_BACKGROUND + ")",
					}}
				/>
				<div className="welcome-block">
					{!REACT_APP_LIGHTSPEED_CLIENT && (
						<div className="logo">
							<img
								src={acfPageOptions.acf?.theme?.logo?.main?.sizes?.large}
								alt=""
							/>
						</div>
					)}

					<div className="left">
						{
							acfPageOptions.acf?.progress_screens?.banned?.title
						}
					</div>
					<div className="right">
						<p className="thank-you">
							{
								acfPageOptions.acf?.progress_screens?.banned?.description
							}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

Banned.defaultProps = {
	acfPageOptions: null,
	writePlayerEvent: () => {},
};

Banned.propTypes = {
	acfPageOptions: PropTypes.object,
	writePlayerEvent: PropTypes.func,
};

export default Banned;
