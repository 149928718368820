import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchWPNoticesData, fetchWPPageData } from "redux/wppage";
import { writeTelemetryEvent } from "redux/playfab";
import { playerGroupSelector } from "selector/player";
import CodeOfConduct from "components/Pages/CodeOfConduct";

function mapStateToProps(state, props) {
	return {
		codeOfConductData: state.wppage.notices,
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			fetchWPNoticesData,
			writeTelemetryEvent,
			fetchWPPageData,
		},
		dispatch
	)
)(CodeOfConduct);
