import { createSlice } from '@reduxjs/toolkit';

const agenda = createSlice({
	name: 'agenda',
	reducers: {
		/**
		*Set the active channel after a user selects it or a route leads to it
		*/
		setActiveChannel: (state, action) => {
			return {
				...state,
				activeChannel: action.payload,
			};
		},
		/**
		*Set the active day after a user selects it or a route leads to it
		*/	
		setActiveDay: {
			reducer: (state, action) => {
				return {
					...state,
					activeDay: action.payload,
				};
			},
			prepare: (date) => {
				return {
					payload: date.format(),
				};
			},
		},
		/**
		*Set the active tile on hover (to expand it)
		*/
		setActiveTile: (state, action) => {
			return {
				...state,
				activeTile: action.payload,
			};
		},
		/**
		*Set the active tile position to make some tiles disappear on hover (Refactor: deprecated)
		*/
		setActiveTilePosition: (state, action) => {
			return {
				...state,
				activeTilePosition: action.payload,
			};
		},
	},
	extraReducers: {},
	initialState: {
		activeDay: null,
		activeChannel: null,
		activeTile: null,
		activeTilePosition: null,
	},
});

export default agenda;

export const {
	setActiveChannel,
	setActiveDay,
	setActiveTile,
	setActiveTilePosition,
} = agenda.actions;
