import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	chatConnect,
	chatListen,
	chatStopListen,
	chatJoinRoom,
} from 'redux/chat';
import ChatConnection from 'components/ChatConnection';
import {userNameSelector} from 'selector/player'

function mapStateToProps(state) {
	return {
		connection: state.chat.connection,
		channel: state.chat.channel,
		displayName: userNameSelector(state),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		chatConnect,
		chatListen,
		chatStopListen,
		chatJoinRoom,
	}, dispatch)
)(ChatConnection);
