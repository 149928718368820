import React, { useEffect } from "react";
import PropTypes from "prop-types";
import uuid from "react-uuid";
import { Container, Row } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import InputField from "mobile/components/partials/InputField";
import SelectField from "mobile/components/partials/SelectField";
import { PARTNERSTAT } from "Constants";
import logPageView from "utils/logPageView";
import Settings from "mobile/components/profile/profileTab/Settings";

function ProfileTab({
	acfPageOptions,
	isPartner,
	playerData,
	updatePlayerData,
	enableNotifications,
	profilePrivacy,
	attendanceType,
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
}) {

	const nodeRef = React.useRef(null);
	
	useEffect(() => {
		// Log PageView.
		logPageView(
			"profile",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);
	
	function onUpdateValue(name, value) {
		updatePlayerData({ [name]: value });
	}
	return (
		<CSSTransition in appear timeout={1000} classNames="fade" nodeRef={nodeRef}>
			<Container>
				{acfPageOptions.acf?.profile?.profile_tab?.inputs.length > 0 &&
					acfPageOptions.acf?.profile?.profile_tab?.inputs.map((input) => {
						if (
							isPartner === false &&
							input.player_data_key === PARTNERSTAT.PROFILE_FIELD_NAME
						)
							return null;

						if (!input?.values) {
							return (
								<Row key={uuid()} className="px-3">
									<InputField
										key={uuid()}
										name={input?.label}
										value={
											(playerData &&
												playerData[input?.player_data_key]?.Value) ||
											""
										} //reading playerData with the dynamic value of the label
										onBlur={(value) =>
											onUpdateValue(input?.player_data_key, value)
										} //dynamic assignment to playerData
										isPartner={isPartner}
										icon={
											acfPageOptions?.acf?.theme?.icon?.profile?.edit_profile?.url
										}
										disabled={input?.disabled}
									/>
								</Row>
							);
						}
						return (
							<Row key={uuid()} className="px-3">
								<SelectField
									key={uuid()}
									name={input?.label}
									options={input?.values.map((inputValue) => {
										return { ...inputValue, label: inputValue?.value };
									})} //matching the required format for the options array [{value:'', label:''},...]
									value={
										(playerData && playerData[input?.player_data_key]?.Value) ||
										""
									}
									onChange={(option) =>
										onUpdateValue(input?.player_data_key, option.value)
									}
									acfPageOptions={acfPageOptions}
									disabled={input?.disabled}
								/>
							</Row>
						);
					})}

				<Settings
					acfPageOptions={acfPageOptions}
					updatePlayerData={updatePlayerData}
					enableNotifications={enableNotifications}
					profilePrivacy={profilePrivacy}
					attendanceType={attendanceType}
				/>
			</Container>
		</CSSTransition>
	);
}

ProfileTab.defaultProps = {
	acfPageOptions: null,
	playerData: null,
	isPartner: false,
	updatePlayerData: () => {},
	enableNotifications: null,
	profilePrivacy: null,
	attendanceType: null,
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
};

ProfileTab.propTypes = {
	acfPageOptions: PropTypes.object,
	playerData: PropTypes.object,
	isPartner: PropTypes.bool,
	updatePlayerData: PropTypes.func,
	enableNotifications: PropTypes.object,
	profilePrivacy: PropTypes.object,
	attendanceType: PropTypes.object,
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
};

export default ProfileTab;
