import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ParticipantCard from 'components/Rooms/ParticipantCard';
import {
	inviteToRandomRoom,
} from 'redux/rooms';

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent?.acfPageOptions,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		inviteToRandomRoom,
	}, dispatch)
)(ParticipantCard);
