/**
 * @format
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Layout from "mobile/components/layout/Title";
import { Nav, Container } from "react-bootstrap";
import { ROUTES } from "Constants";
import logPageView from "utils/logPageView";

function Notices({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	noticesData,
	fetchWPNoticesData,
	playerGroup,
	pageSlug,
}) {
	const [dataLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		};

		return fetchWPNoticesData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	useEffect(() => {
		// Log PageView.
		logPageView(
			"Notices",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);
	
	if (dataLoaded){
		return (
			<Layout
				titleSlot={noticesData?.title?.rendered}
				pageClass="terms"
				headerClass="terms-container"
				backLink={ROUTES.HOME}
				backText=" "
			>
				<Container>
					<Nav className="notices-navigation d-block" as="ul">
						<li className="bg-m-tertiary mb-3 rounded-2">
							<Link
								to={ROUTES.TERMS}
								className="text-uppercase fw-bold text-center"
							>
								{noticesData?.acf?.terms_of_use?.page_title}
							</Link>
						</li>
						<li className="bg-m-tertiary mb-3 rounded-2">
							<Link
								to={ROUTES.PRIVACY}
								className="text-uppercase fw-bold text-center"
							>
								{noticesData?.acf?.privacy_policy?.page_title}
							</Link>
						</li>
					</Nav>
				</Container>
			</Layout>
		);
	}
	return null;
}

Notices.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	noticesData: null,
	pageSlug: null,
	fetchWPNoticesData: () => {},
	playerGroup: null,
};

Notices.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	noticesData: PropTypes.object,
	fetchWPNoticesData: PropTypes.func,
	playerGroup: PropTypes.string,
	pageSlug: PropTypes.string,
};

export default Notices;
