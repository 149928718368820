import { createSelector } from "@reduxjs/toolkit";

import parseItemData from "utils/parseItemData";
import { inventorySelector } from "selector/inventory";
import { playerGroupSelector } from "selector/player";

const rawCatalogSelector = (state) => state.playfab?.Catalog;
const activitiesDataSelector = (state) => state.wpcontent.activities;
const wordpressSpeakersSelector = (state) => state.wppage.speakers;
const langSelector = (state) => state.env.lang;
const badgeIdPropsSelector = (state, props) => props.badgeId;

/**
 *Return the catalog in the specified lang
 */
export const catalogSelector = createSelector([rawCatalogSelector, langSelector], (rawCatalog = [], lang = "en") => {
	return rawCatalog.map((item) => parseItemData(item, lang));
});

/**
 *Return the items of class Activity and the Speakers related
 */
export const activitiesSelector = createSelector(
	[catalogSelector, activitiesDataSelector, wordpressSpeakersSelector],
	(catalogFull, activitiesData, wordpressSpeakers) => {
		if (catalogFull) {
			return catalogFull.reduce((carry, curr) => {
				const activity = { ...curr };

				if (activity.playfab.ItemClass === "Activity") {
					const additionnalData = activitiesData.find((data) => {
						return data.slug === activity.data.wordpress_slug;
					});
					activity.content = additionnalData;

					// Check if activity.content.acf.event_partners is an array before using .find()
					//This does not fix the issue. For some reason the wordpress does not return event_partners but returns {
					// "code": "rest_no_route",
					// "message": "No route was found matching the URL and request method.",
					// "data": {
					// "status": 404
					// }
					//This breaks the platform because it is needed to display on the event page and on the speaker page
					if (Array.isArray(activity?.content?.acf?.event_partners)) {
						activity.speakers = wordpressSpeakers
							.filter((speaker) => {
								const hasSpeakers = activity?.content?.acf?.event_partners;

								return (
									hasSpeakers &&
									Boolean(
										activity?.content?.acf?.event_partners.find(
											(activity_speaker) => activity_speaker.ID === speaker.id
										)
									)
								);
							})
							.slice(0, 3);
					}

					return [...carry, activity];
				}

				return carry;
			}, []);
		}

		return null;
	}
);


/**
 *Return if the player has or not the badges in his inventory
 */
export const badgesSelector = createSelector([inventorySelector, catalogSelector], (inventory, catalog) => {
	if (!inventory || !catalog) return [];

	const badgesCatalog = catalog.filter((item) => {
		return item.type.title === "Badge";
	});

	const badges = inventory.filter((item) => {
		return item.type.title === "Badge";
	});

	return badgesCatalog.map((badge) => {
		return {
			...badge,
			isInInventory: Boolean(badges.find((x) => x.itemId === badge.itemId)),
		};
	});
});

/**
 * Return if the player has or not the badges in his inventory according to the group he is in
 */

export const badgesUnlockedRegionSelector = createSelector(
	[inventorySelector, catalogSelector, playerGroupSelector],
	(inventory, catalog, group) => {
		if (!inventory || !catalog) return [];

		const badgesCatalog = catalog.filter((item) => {
			return item.type.title === "Badge" && item.playfab.Tags.includes(group);
		});

		const badges = inventory.filter((item) => {
			return item.type.title === "Badge";
		});

		return badgesCatalog.map((badge) => {
			return {
				...badge,
				isInInventory: Boolean(badges.find((x) => x.itemId === badge.itemId)),
			};
		});
	}
);

export const badgesRegionSelector = createSelector(
	[inventorySelector, catalogSelector, playerGroupSelector],
	(inventory, catalog, group) => {
		if (!inventory || !catalog) return [];

		const badgesCatalog = catalog.filter((item) => {
			return item.type.title === "Badge" && item.playfab.Tags.includes(group);
		});

		return badgesCatalog;
	}
);

/**
 *Return a specific badge by ID in his inventory
 */
export const badgeSelector = createSelector([badgesSelector, badgeIdPropsSelector], (badges, badgeId) => {
	if (!badges) return false;

	return badges.find((badge) => badge.itemId === badgeId);
});

/**
 *Return the live event translations available
 */
export const translationSelector = createSelector([catalogSelector], (catalog) => {
	if (!catalog) return [];

	return catalog.filter((item) => {
		return item.playfab.ItemClass === "Translation Services";
	});
});
