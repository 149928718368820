import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { setBubbleVolume, setMuted, setStartTime, setBubbleSrc, emptyVideoPlayer } from 'redux/video_bubble';

function mapStateToProps(state) {
	return {
		volume: state.video_bubble?.volume,
		muted: state.video_bubble?.muted,
		startTime: state.video_bubble?.startTime,
		videoBubbleSrc: state.video_bubble?.src,
		channel: state.chat.channel,
	};
}

export default withRouter(connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			setBubbleVolume,
			setStartTime,
			setMuted,
			setBubbleSrc,
			emptyVideoPlayer,
		},
		dispatch
	)
)(VideoPlayer));
