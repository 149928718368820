/**
 * @format
 */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import Layout from "components/Layout/Title";

import logPageView from "utils/logPageView";

function Faq({
	playerStatistics,
	playfabId,
	leaderboardOnUserPosition,
	playerData,
	faqData,
	fetchWPPageData,
	acfPageOptions,
	playerGroup,
	pageSlug,
}) {
	const [dataLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		if (dataLoaded) {
			return null;
		}

		const data = {
			pageSlug,
			playerGroup,
		};

		return fetchWPPageData(data).then(() => {
			return setDataLoaded(true);
		});
	}, [playerGroup]);

	useEffect(() => {
		//Log PageView
		logPageView(
			"faq",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);
	}, []);

	/**
	 * TODO: Move to their own component
	 */
	function ShowAskQuestions({ faqSingle }) {
		const { faq_answer, faq_question } = faqSingle || {};
		const [showAnswer, setShowAnswer] = useState(false);

		return (
			<button type="button" onClick={() => setShowAnswer(!showAnswer)}>
				<li key={faq_answer}>
					<img
						src={
							showAnswer
								? acfPageOptions?.acf?.theme?.icon?.general?.collapse?.url
								: acfPageOptions?.acf?.theme?.icon?.general?.expand?.url
						}
						alt=""
					/>
					<div className="title-block">
						<h3 className="subtitle">{faq_question}</h3>
					</div>
					{showAnswer && <div className="faq-content">{parse(faq_answer)}</div>}
				</li>
			</button>
		);
	}

	return (
		<Layout
			titleSlot={
				(faqData?.title?.rendered && parse(faqData?.title?.rendered)) || null
			}
			pageClass="faq"
			isLoading={
				!faqData ||
				(faqData &&
					Object.keys(faqData).length === 0 &&
					faqData.constructor === Object)
			}
			backgroundUrl={
				acfPageOptions?.acf?.faq?.background ? acfPageOptions?.acf?.faq?.background : ""
			}
		>
			{faqData.acf?.tutorial_url && (
				<div className="section-video">
					<div className="video-description">
						<h4>{faqData.acf?.tutorial_title}</h4>
						<div>
							{faqData.acf?.tutorial_description &&
								parse(faqData.acf?.tutorial_description)}
						</div>
					</div>
					<video
						src={faqData.acf?.tutorial_url}
						width={490}
						height={300}
						controls
					/>
				</div>
			)}

			<div className="faq-list">
				<ul>
					{faqData?.acf?.topic &&
						faqData.acf.topic.map((faqTopic, key) => {
							return (
								(faqTopic.faqs &&
									faqTopic.faqs.map((faqSingle, index) => (
										<ShowAskQuestions key={index} faqSingle={faqSingle} />
									))) ||
								null
							);
						})}
				</ul>
			</div>
		</Layout>
	);
}

Faq.defaultProps = {
	playerStatistics: null,
	playfabId: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	faqData: null,
	pageSlug: null,
	fetchWPPageData: () => {},
	playerGroup: null,
};

Faq.propTypes = {
	playerStatistics: PropTypes.object,
	playfabId: PropTypes.string,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	faqData: PropTypes.object,
	fetchWPPageData: PropTypes.func,
	playerGroup: PropTypes.string,
	pageSlug: PropTypes.string,
};

export default Faq;
