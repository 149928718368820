import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import QA from "components/ComPanel/Questions/QA";
import { getQuestions, clearNewQuestions, postQuestion } from "redux/chat";
import { questionsAcceptedSelector } from "selector/chat";

function mapStateToProps(state) {
	return {
		newQuestions: state.chat?.newQuestions,
		acfPageOptions: state.wpcontent.acfPageOptions,
		questions: questionsAcceptedSelector(state),
		fetchQuestions: state.chat?.fetch,
	};
}

export default withRouter(
	connect(mapStateToProps, (dispatch) =>
		bindActionCreators(
			{
				getQuestions,
				clearNewQuestions,
				postQuestion,
			},
			dispatch
		)
	)(QA)
);
