/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function ChatConnection({
	connection,
	channel,
	chatConnect,
	chatListen,
	chatStopListen,
	displayName,
	children,
}) {
	useEffect(() => {
		if (connection) {
			chatListen();
		} else {
			chatConnect();
		}

		return () => {
			chatStopListen();
		};
	}, [channel, connection, displayName]);

	return !connection ? null : <>{children}</>;
}

ChatConnection.defaultProps = {
	connection: false,
	channel: null,
	displayName: null,
	children: null,
	chatConnect: () => {},
	chatListen: () => {},
	chatStopListen: () => {},
};

ChatConnection.propTypes = {
	connection: PropTypes.bool,
	channel: PropTypes.string,
	displayName: PropTypes.string,
	children: PropTypes.object,
	chatConnect: PropTypes.func,
	chatListen: PropTypes.func,
	chatStopListen: PropTypes.func,
};

export default ChatConnection;
