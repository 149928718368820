/** @format */

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ADMINSTAT } from 'Constants';

import {
	purchaseTile,
	writePlayerEvent,
	getStoreLoadout,
	getItemInventory,
	writeTelemetryEvent,
} from "redux/playfab";
import ActivityCard from "components/AgendaActivityCard";
import { playerLangGroupSelector } from "selector/player";
import { statSelector } from 'selector/stats';

function mapStateToProps(state, ownProps) {
	return {
		...ownProps,
		teamsUserObjectId: state.teams.userObjectId,
		acfPageOptions: state.wpcontent.acfPageOptions,
		lang: state.env.lang,
		groupLang: playerLangGroupSelector(state),
		isAdmin: statSelector(state, { statName: 'admin' }) === ADMINSTAT.ADMIN,
	};
}

export default withRouter(
	connect(mapStateToProps, (dispatch) =>
		bindActionCreators(
			{
				purchaseTile,
				writePlayerEvent,
				getStoreLoadout,
				getItemInventory,
				writeTelemetryEvent,
			},
			dispatch
		)
	)(ActivityCard)
);
