import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Jitsi from "containers/Jitsi";
import { JITSI_SCRIPT_URL } from "Constants";
import Button from "components/Buttons/Button";

function Meeting({ url, joinMeetingURL, acfPageOptions }) {
	const [joinJitsi, setJoinJitsi] = useState(false);

	function loadJitsiScript() {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = JITSI_SCRIPT_URL;
			script.async = true;
			script.onload = resolve;
			document.body.appendChild(script);
		});
	}

	const initialiseJitsi = async() => {
		if (!window.JitsiMeetExternalAPI) {
			await loadJitsiScript();
		}
	};

	useEffect(() => {
		initialiseJitsi();

		return () => {
			if (document.body.lastChild.tagName === "SCRIPT") {
				document.body.removeChild(document.body.lastChild);
			}
		};
	}, []);

	return (
		<div className="d-flex flex-column justify-content-center align-items-center  meeting">
			{joinJitsi === false ? (
				<>
					<p>{acfPageOptions?.acf?.private_meeting?.welcome?.title}</p>
					<Button
						action={() => setJoinJitsi(true)}
						classes="primary large join-button"
						text={acfPageOptions?.acf?.private_meeting?.welcome?.button_label}
					/>
				</>
			) : (
				<Jitsi url={url} joinMeetingURL={joinMeetingURL} setJoinJitsi={setJoinJitsi} />
			)}
		</div>
	);
}

Meeting.defaultProps = {
	joinMeetingURL: null,
	acfPageOptions: null,
};

Meeting.propTypes = {
	url: PropTypes.string.isRequired,
	joinMeetingURL: PropTypes.string,
	acfPageOptions: PropTypes.object,
};

export default Meeting;
