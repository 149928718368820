import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { ADMINSTAT } from "Constants";
import {
	updatePlayerData,
	purchaseTile,
	writePlayerEvent,
	getStoreLoadout,
	getItemInventory,
	writeTelemetryEvent,
} from "redux/playfab";
import { sessionActivitySelector } from "selector/myAgenda";
import { playerGroupSelector, playerLangGroupSelector } from "selector/player";
import { statSelector, statsSelector } from "selector/stats";

import SessionDetails from "mobile/pages/SessionDetails";

function mapStateToProps(state, props) {
	const activity = sessionActivitySelector(state, props);
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerStatistics: statsSelector(state, props),
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		isCatalogLoaded: Boolean(state.playfab.Catalog),
		activity,
		playfabId: state.playfab?.PlayFabId,
		playerGroup: playerGroupSelector(state),
		lang: state.env.lang,
		groupLang: playerLangGroupSelector(state),
		isAdmin: statSelector(state, { statName: "admin" }) === ADMINSTAT.ADMIN,
		attendanceType: state.playfab?.PlayerData?.AttendanceType,
	};
}

export default withRouter(
	connect(mapStateToProps, (dispatch) =>
		bindActionCreators(
			{
				updatePlayerData,
				purchaseTile,
				writePlayerEvent,
				getStoreLoadout,
				getItemInventory,
				writeTelemetryEvent,
			},
			dispatch
		)
	)(SessionDetails)
);
