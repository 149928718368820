import { createSlice } from '@reduxjs/toolkit';
import { createDebouncedAsyncAction } from 'utils/reduxhelpers';
import { getInstanceLeaderboard } from 'redux/playfab';

function keyFromAction(action) {
	const { payload } = action.payload; // 2x voluntary
	const { CustomInstanceId, StatName } = payload.params;
	return CustomInstanceId + '_' + StatName;
}
/**
*Fetch leaderboard from Playfab and put it in the list state
*/
export const fetchLeaderboard = createDebouncedAsyncAction(
	'leaderboard/fetchLeaderboard',
	(data, { dispatch }) => {
		return dispatch(getInstanceLeaderboard(data)).then((results) => {
			results.meta = data;

			dispatch(leaderboard.actions.showLeaderboard(results));

			return results;
		});
	},
	{
		fulfilled: (state, action) => {
			const key = keyFromAction(action);

			return {
				...state,
				list: {
					...state.list,
					[key]: {
						meta: action.payload.meta,
						list: action.payload.payload.data.Leaderboard,
					},
				},
			};
		},
	},
);

const leaderboard = createSlice({
	name: 'leaderboard',
	reducers: {
		showLeaderboard: (state, action) => {
			const key = keyFromAction(action);

			return {
				...state,
				active: key,
			};
		},
		hideLeaderboard: (state) => {
			return {
				...state,
				active: null,
			};
		},
	},
	extraReducers: {
		...fetchLeaderboard.reducers,
	},
	initialState: {
		list: {},
		active: null,
	},
});

export default leaderboard;

export const { setLeaderboard, hideLeaderboard } = leaderboard.actions;
