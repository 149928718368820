import { createSelector } from '@reduxjs/toolkit';

const statsStateSelector = (state) => state.playfab?.Statistics;
const statNameSelector = (state, props) => props.statName;

/**
* Parse the stats into a flat object
*/

export const statsSelector = createSelector([
	statsStateSelector,
], (stats) => {
	if (!stats) return null;
	return stats.reduce((c, v) => {
		c[v.StatisticName] = v.Value;
		return c;
	}, {});
});

export const statSelector = createSelector([
	statsSelector,
	statNameSelector,
],
(stats, statName) => {
	if (!stats) return -1;

	return stats[statName] || 0;
});
