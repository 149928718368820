/** @format */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ROUTES } from "Constants";
import { Nav, Navbar } from "react-bootstrap";
import NavOption from "mobile/components/partials/NavOption";
import { ReactComponent as CollapsedIcon } from "assets/icons/pack1/bottom-navigation-bar/icon-hamburger-menu-default.svg";
import { ReactComponent as ExpandedIcon } from "assets/icons/pack1/bottom-navigation-bar/icon-hamburger-menu-chevron-down.svg";
import LogOut from "./modals/LogOut";

function Navigation({ acfPageOptions, navigation, mobileOnly, setMobileOnly, setShowDebug }) {
	const [expanded, setExpanded] = useState(false);
	const [showLogOutModal, setShowLogOutModal] = useState(false);

	function onClickShowLogOutModal() {
		setShowLogOutModal(true);
	}
	
	return (
		<Navbar
			expanded={expanded}
			fixed="bottom"
			bg="dark"
			expand={false} //TODO: use lg when old desktop removed
			variant="dark"
		>
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav
					className="pb-5 me-auto vh-100 justify-content-end text-uppercase fw-bold"
					as="ul"
				>
					{navigation?.acf?.navigation_acf?.speakers?.show && (
						<NavOption
							onClick={() => setExpanded(false)}
							link={ROUTES.SPEAKERS}
							text={
								navigation?.acf?.navigation_acf?.speakers?.title ||
								acfPageOptions.acf?.speakers?.title
							}
							itemName={
								navigation?.acf?.navigation_acf?.speakers?.item_name ||
								acfPageOptions.acf?.speakers?.item_name
							}
							order={navigation?.acf?.navigation_acf?.speakers?.nav_order}
							acfPageOptions={acfPageOptions}
						/>
					)}
					{navigation?.acf?.navigation_acf?.partners?.show && (
						<NavOption
							onClick={() => setExpanded(false)}
							link={ROUTES.PARTNERS}
							text={
								navigation?.acf?.navigation_acf?.partners?.title ||
								acfPageOptions.acf?.partners?.title
							}
							itemName={
								navigation?.acf?.navigation_acf?.partners?.item_name ||
								acfPageOptions.acf?.partners?.item_name
							}
							order={navigation?.acf?.navigation_acf?.partners?.nav_order}
							acfPageOptions={acfPageOptions}
						/>
					)}
					{navigation?.acf?.navigation_acf?.partner?.show && (
						<NavOption
							onClick={() => setExpanded(false)}
							link={ROUTES.PARTNER.replace(
								":partnerId",
								navigation?.acf?.navigation_acf?.partner?.target?.post_name
							)}
							text={
								navigation?.acf?.navigation_acf?.partner?.title ||
								acfPageOptions.acf?.partner?.title
							}
							itemName={
								navigation?.acf?.navigation_acf?.partner?.item_name ||
								acfPageOptions.acf?.partner?.item_name
							}
							order={navigation?.acf?.navigation_acf?.partner?.nav_order}
							acfPageOptions={acfPageOptions}
						/>
					)}

					{navigation?.acf?.navigation_acf?.sponsors?.show && (
						<NavOption
							onClick={() => setExpanded(false)}
							link={ROUTES.SPONSORS}
							text={
								navigation?.acf?.navigation_acf?.sponsors?.title ||
								acfPageOptions.acf?.sponsors?.title
							}
							itemName={
								navigation?.acf?.navigation_acf?.sponsors?.item_name ||
								acfPageOptions.acf?.sponsors?.item_name
							}
							order={navigation?.acf?.navigation_acf?.sponsors?.nav_order}
							acfPageOptions={acfPageOptions}
						/>
					)}
					{navigation?.acf?.navigation_acf?.faq?.show && (
						<NavOption
							onClick={() => setExpanded(false)}
							link={ROUTES.FAQ}
							text={
								navigation?.acf?.navigation_acf?.faq?.title ||
								acfPageOptions.acf?.faq?.title
							}
							itemName={
								navigation?.acf?.navigation_acf?.faq?.item_name ||
								acfPageOptions.acf?.faq?.item_name
							}
							order={navigation?.acf?.navigation_acf?.faq?.nav_order}
							acfPageOptions={acfPageOptions}
						/>
					)}
					{navigation?.acf?.navigation_acf?.support?.show && (
						<NavOption
							onClick={() => setExpanded(false)}
							link={ROUTES.TECHSUPPORT}
							text={
								navigation?.acf?.navigation_acf?.support?.title ||
								acfPageOptions.acf?.support?.title
							}
							itemName={
								navigation?.acf?.navigation_acf?.support?.item_name ||
								acfPageOptions.acf?.support?.item_name
							}
							order={navigation?.acf?.navigation_acf?.support?.nav_order}
							acfPageOptions={acfPageOptions}
						/>
					)}
					{navigation?.acf?.navigation_acf?.notices?.show && (
						<NavOption
							onClick={() => setExpanded(false)}
							link={ROUTES.NOTICES}
							itemName={
								navigation?.acf?.navigation_acf?.notices?.item_name ||
								acfPageOptions.acf?.notices?.item_name
							}
							text={
								navigation?.acf?.navigation_acf?.notices?.title ||
								acfPageOptions.acf?.notices?.title
							}
							order={navigation?.acf?.navigation_acf?.notices?.nav_order}
							acfPageOptions={acfPageOptions}
						/>
					)}

					<hr style={{ order: "200", width: "140px" }} className="text-white" />

					<NavOption
						onClick={() => setExpanded(false)}
						logOut="true"
						link="/"
						text={navigation?.acf?.navigation_acf?.log_out?.title}
						itemName={navigation?.acf?.navigation_acf?.log_out?.item_name}
						onClickShowLogOutModal={() => onClickShowLogOutModal()}
						order="201"
						withLink={false}
						acfPageOptions={acfPageOptions}
					/>
					{/* <NavOption
						onClick={() => {
							//setExpanded(false);
							setMobileOnly(!mobileOnly);
						}}
						text={mobileOnly? "[ Back to Normal Mode ]" : "[ Enable Mobile Only Mode ]"}
						order="202"
						withLink={false}
						acfPageOptions={acfPageOptions}
						highlightable
					/> */}

			
					{/* <LogOutModal
						key={showModal.key}
						show={showModal.s}
						setShow={onShowModal}
					/> */}
					{showLogOutModal && (
						<LogOut
							acfPageOptions={acfPageOptions}
							showLogOutModal={showLogOutModal}
							setShowLogOutModal={setShowLogOutModal}
						/>
					)}

					<NavOption
						onClick={() => {
							setShowDebug(true);
						}}
						text="[ Show debugger ]"
						order="203"
						withLink={false}
					/>
				</Nav>
			</Navbar.Collapse>

			<Nav
				className="flex-row mb-0 bottom-nav d-flex w-100 justify-content-between align-items-center"
				as="ul"
			>
				<NavOption
					onClick={() => setExpanded(false)}
					link={ROUTES.HOME}
					text={
						navigation?.acf?.navigation_acf?.home?.title ||
						acfPageOptions.acf?.home?.title
					}
					itemName={
						navigation?.acf?.navigation_acf?.home?.item_name ||
						acfPageOptions.acf?.home?.item_name
					}
					showText={false}
					order={navigation?.acf?.navigation_acf?.home?.nav_order}
					acfPageOptions={acfPageOptions}
				/>
				<NavOption
					onClick={() => setExpanded(false)}
					link={ROUTES.AGENDA}
					text={
						navigation?.acf?.navigation_acf?.event?.title ||
						acfPageOptions.acf?.event?.title
					}
					itemName={
						navigation?.acf?.navigation_acf?.event?.item_name ||
						acfPageOptions.acf?.event?.item_name
					}
					showText={false}
					order={navigation?.acf?.navigation_acf?.event?.nav_order}
					acfPageOptions={acfPageOptions}
				/>
				<NavOption
					onClick={() => setExpanded(false)}
					link={ROUTES.GALLERY}
					text={
						navigation?.acf?.navigation_acf?.gallery?.title ||
						acfPageOptions.acf?.gallery?.title
					}
					itemName={
						navigation?.acf?.navigation_acf?.interactive_gallery?.item_name ||
						acfPageOptions.acf?.interactive_gallery?.item_name
					}
					showText={false}
					order={navigation?.acf?.navigation_acf?.gallery?.nav_order}
					acfPageOptions={acfPageOptions}
				/>
				<NavOption
					onClick={() => setExpanded(false)}
					link={ROUTES.PROFILE}
					text={
						navigation?.acf?.navigation_acf?.profile?.label_tab_profile ||
						acfPageOptions.acf?.profile?.label_tab_profile
					}
					itemName={
						navigation?.acf?.navigation_acf?.profile?.item_name ||
						acfPageOptions.acf?.profile?.item_name
					}
					showText={false}
					order={navigation?.acf?.navigation_acf?.profile?.nav_order}
					acfPageOptions={acfPageOptions}
				/>

				<li style={{ order: "4" }}>
					<Navbar.Toggle
						onClick={() => setExpanded(expanded ? false : "expanded")}
						aria-controls="basic-navbar-nav"
						className="p-0 mt-2 border-0 shadow-none"
					>
						<CollapsedIcon className="collapsed-icon" />
						<ExpandedIcon className="expanded-icon" />
					</Navbar.Toggle>
				</li>
			</Nav>
		</Navbar>
	);
}
Navigation.defaultProps = {
	acfPageOptions: null,
	navigation: null,
	mobileOnly: false,
	setMobileOnly: () => {},
	setShowDebug: () => {},
};

Navigation.propTypes = {
	acfPageOptions: PropTypes.object,
	navigation: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	mobileOnly: PropTypes.bool,
	setMobileOnly: PropTypes.func,
	setShowDebug: PropTypes.func,
};
export default Navigation;
