import React, { useEffect } from "react";
import PropTypes from "prop-types";
import uuid from 'react-uuid';
import { Col, Row } from "react-bootstrap";
import ComPanel from "containers/ComPanel/ComPanel";
import BookAMeeting from "containers/BookAMeeting/BookAMeeting";
import Filter from "components/ContentGallery/Filter";
import MediasList from "components/ContentGallery/MediasList";
import { BookAMeetingContextProvider } from "components/BookAMeeting/BookAMeetingContext";
import SelectField from "components/Partials/SelectField";
import { REACT_APP_BRP_CLIENT } from "Constants";

function Gallery({
	galleryData,
	galleryFilters,
	acfPageOptions,
	filterParam,
	setFilterParam,
	navigation,
	displayComPanel,
	displayFilters,
	displayBooking,
}) {
	useEffect(() => {
		if (galleryFilters) {
			filterMedias();
		}
	}, []);
	const selectFiltersOption = [
		{
			value: acfPageOptions?.acf?.content_gallery?.all_filters.category_value,
			label: acfPageOptions?.acf?.content_gallery?.all_filters.category_label,
			...acfPageOptions?.acf?.content_gallery?.all_filters,
		},
	];

	if (galleryFilters) {
		galleryFilters.forEach((filter) => {
			const { category_value: value, category_label: label } = filter;
			selectFiltersOption.push({
				value,
				label,
				...filter,
			});
		});
	}

	const dealersLoungeComPanel = {
		StoreTileId: navigation.acf?.navigation_acf?.dealers_lounge?.title,
		content: {
			title: {
				rendered: navigation.acf?.navigation_acf?.dealers_lounge?.title,
			},
		},
		data: {
			activity_type: "Default",
		},
	};

	function filterMedias(filter) {
		return galleryData.filter((media) => {
			if (!media?.acf?.object_category) {
				return media;
			}
			return media?.acf?.object_category.find((category) => category?.category_value === filter?.category_value);
		});
	}

	function renderList() {
		if (galleryData && galleryData.length > 0) {
			if (!galleryFilters || galleryFilters.length === 0) {
				return <MediasList key={uuid()} acfPageOptions={acfPageOptions} medias={galleryData} />;
			}
			if (filterParam && filterParam.category_value === acfPageOptions?.acf?.content_gallery?.all_filters.category_value) {
				return galleryFilters.map((filter) => {
					return (
						<MediasList
							key={uuid()}
							acfPageOptions={acfPageOptions}
							medias={filterMedias(filter)}
							filterParam={filter}
						/>
					);
				});
			}
			return <MediasList key={uuid()} acfPageOptions={acfPageOptions} medias={filterMedias(filterParam)} filterParam={filterParam} />;
		}
		return null;
	}

	return (
		<>
			{galleryFilters && galleryFilters.length > 0 && (
				<>
					<div className="gallery-filters">
						{displayFilters === true && !REACT_APP_BRP_CLIENT ? (
							<>
								<div className="filter-dropdown">
									<SelectField
										options={selectFiltersOption}
										value={filterParam?.category_value}
										onChange={setFilterParam}
										acfPageOptions={acfPageOptions}
									/>
								</div>
								<Row
									className={`text-center m-0 p-0 align-items-end ${
										!REACT_APP_BRP_CLIENT ? "filter-listing-wrap" : ""
									}`}
								>
									<Col className="m-0 p-0">
										<Filter
											filter={acfPageOptions?.acf?.content_gallery?.all_filters}
											setFilterParam={setFilterParam}
											filterParam={filterParam}
										/>
									</Col>
									{galleryFilters.map((filter) => {
										return (
											<Col key={uuid()} className="m-0 p-0 ">
												<Filter
													key={uuid()}
													filter={filter}
													setFilterParam={setFilterParam}
													filterParam={filterParam}
												/>
											</Col>
										);
									})}
								</Row>
							</>
						) : (
							displayFilters === true &&
							REACT_APP_BRP_CLIENT && (
								<Row
									className={`text-center m-0 p-0 align-items-end ${
										!REACT_APP_BRP_CLIENT ? "filter-listing-wrap" : ""
									}`}
								>
									<Col className="m-0 p-0">
										<Filter
											filter={acfPageOptions?.acf?.content_gallery?.all_filters}
											setFilterParam={setFilterParam}
											filterParam={filterParam}
										/>
									</Col>
									{galleryFilters.map((filter) => {
										return (
											<Col key={uuid()} className="m-0 p-0 ">
												<Filter
													key={uuid()}
													filter={filter}
													setFilterParam={setFilterParam}
													filterParam={filterParam}
												/>
											</Col>
										);
									})}
								</Row>
							)
						)}
					</div>
					<div className="booking-button-ctn">
						{displayBooking === true && navigation.acf?.navigation_acf?.dealers_lounge?.show_book_a_meeting && (
							<BookAMeetingContextProvider>
								<BookAMeeting />
							</BookAMeetingContextProvider>
						)}
					</div>
				</>
			)}

			<div className={`gallery ${galleryData.length <= 1 ? 'single' : ''}`}>
				<div className="medias-listing-wrap">{renderList()}</div>
				{displayComPanel === true && (
					<div className="gallery-chat">
						<ComPanel
							activity={dealersLoungeComPanel}
							show_chat={acfPageOptions?.acf?.dealer_lounge?.com_panel?.show_chat}
							show_qa={acfPageOptions?.acf?.dealer_lounge?.com_panel?.show_qa}
							defaultTab={acfPageOptions?.acf?.dealer_lounge?.com_panel?.default_tab}
							collapsable={acfPageOptions?.acf?.dealer_lounge?.com_panel?.is_collapsable}
						/>
					</div>
				)}
			</div>
		</>
	);
}

Gallery.defaultProps = {
	galleryData: [],
	galleryFilters: [],
	acfPageOptions: null,
	filterParam: null,
	setFilterParam: () => {},
	navigation: {},
	displayComPanel: false,
	displayFilters: false,
	displayBooking: false,
};

Gallery.propTypes = {
	galleryData: PropTypes.array,
	galleryFilters: PropTypes.array,
	acfPageOptions: PropTypes.object,
	filterParam: PropTypes.object,
	setFilterParam: PropTypes.func,
	navigation: PropTypes.object,
	displayComPanel: PropTypes.bool,
	displayFilters: PropTypes.bool,
	displayBooking: PropTypes.bool,
};

export default Gallery;
