/** @format */
/* eslint-disable react/jsx-no-useless-fragment */

import React, { useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import Status from "containers/Pages/Status";
import { validateEmail } from "utils/textUtils";
import { REACT_APP_LOGIN_REDIRECT_URL, REACT_APP_APP_NAME } from "Constants";

function Loading({
	show,
	currentState,
	teamsUserPrincipalName,
	AddOrUpdateContactEmail,
	setAuthStep,
	setLoading,
	isEmailSet,
	isDataLoaded,
	isChatAuthSet,
	isMainChatConnected,
	isAccountCompleted,
	isAccountDeclined,
	acfPageOptions,
	setShowDebug,
}) {
	const ref = useRef();

	useEffect(() => {
		//Append Email to trigger Aventri Auth
		if (
			currentState === "teamsReturning" ||
			currentState === "SessionReturning"
		) {
			if (teamsUserPrincipalName) {
				if (!validateEmail(teamsUserPrincipalName)) {
					setAuthStep("teamsInvalidMail");
				} else {
					//If there is no override ID proceed with the update of the email
					if (!localStorage.getItem("override_teams_id")) {
						AddOrUpdateContactEmail(teamsUserPrincipalName);
					}
					setAuthStep("teamsAventriAuth");
				}
			}
		}

		if (currentState === "teamsCustomIDNewUser") {
			if (teamsUserPrincipalName) {
				if (!validateEmail(teamsUserPrincipalName)) {
					setAuthStep("teamsInvalidMail");
				} else {
					AddOrUpdateContactEmail(teamsUserPrincipalName).then(
						setAuthStep("teamsAventriAuth")
					);
				}
			}
		}

		//Waiting for aventi validation
		if (
			currentState === "teamsReturning" ||
			currentState === "SessionReturning" ||
			currentState === "jwtNewUser" ||
			currentState === "jwtReturning" ||
			currentState === "teamsAventriAuth"
		) {
			//TODO: AVENTRI TIMEOUT
			if (isAccountCompleted) {
				setAuthStep("AventriApprouved");
			}

			if (isAccountDeclined) {
				setAuthStep("AventriDeclined");
			}
		}

		if (currentState === "jwtDeclinedExpired") {
			window.location.replace(REACT_APP_LOGIN_REDIRECT_URL);
		}

		if (currentState === "AventriApprouved") {
			if (isDataLoaded && isChatAuthSet && isMainChatConnected) {
				if (isChatAuthSet) {
					if (isMainChatConnected) {
						setTimeout(() => {
							setLoading({ key: "auth", value: false });
						}, 1000);
					}
				}
			}
		}
	}, [
		currentState,
		isAccountCompleted,
		isDataLoaded,
		isMainChatConnected,
		isAccountDeclined,
		isEmailSet,
	]);

	function getMessage({ message, title, redirect, description }) {
		return (
			<>
				<Status
					title={title || acfPageOptions.acf?.progress_screens?.setup?.title}
					description={
						description ||
						acfPageOptions.acf?.progress_screens?.setup?.description
					}
					message={message}
					redirectURL={redirect}
					type="auth"
					setShowDebug={setShowDebug}
				/>
			</>
		);
	}

	const loadingArray = {
		//Loading state
		loading: {
			message: "0.1 Loading theme",
			redirect: "",
			log: "%c0.1Loading",
			title: "Welcome",
			description: "Loading ...",
		},
		//First Entry Point
		welcomeInit: {
			message: "1.0 Authentication in progress",
			redirect: "",
			log: "%c1.0welcomeInit",
		},
		//JWT entry Point
		jwtInit: {
			message: "2.0 Requesting user permission",
			redirect: "",
			log: "%c2.0jwtInit",
		},
		//JWT Declined //TO DO Maybe redirect to log in ?
		jwtDeclinedExpired: {
			message: "Error ID 4.1.1 - Token expired",
			redirect: "",
			log: "%c4.1jwtDeclinedExpired",
		},
		//JWT Declined
		jwtDeclined: {
			message: "Error ID 4.1.2 - Token declined",
			redirect: "",
			log: "%c4.1jwtDeclined",
		},
		//JWT Timeout
		jwtTimeOut: {
			message: "Error ID 4.1.3 - Token time-out",
			redirect: "",
			log: "%c4.1jwtTimeOut",
		},
		//JWT New User
		jwtNewUser: {
			message: "4.2 Setting up your new account",
			redirect: "",
			log: "%c4.2jwtNewUser",
		},
		//JWT Returning
		jwtReturning: {
			message: `4.6 Session ready, loading ${REACT_APP_APP_NAME} Virtual Events`,
			redirect: "",
			log: "%c4.6jwtReturning",
		},
		//Session Returning
		SessionReturning: {
			message: `4.6 Session ready, loading ${REACT_APP_APP_NAME} Virtual Events`,
			redirect: "",
			log: "%c4.6SessionReturning",
		},
		//Session SessionDeclined
		SessionDeclined: {
			message: "4.6 Unauthorized User",
			redirect: "",
			log: "%c4.6SessionDeclined",
		},
		//Teams entry Point
		teamsInit: {
			message: "3.0 MS Team Framework Loading",
			redirect: "",
			log: "%c3.0teamsInit",
		},
		//Teams Found
		teamsLoading: {
			message: "3.0 MS Team Framework Loading",
			redirect: "",
			log: "%cteamsLoading",
		},
		//Teams Auth
		teamsTimeOut: {
			message: ">Error ID 3.2.1 - Teams time-out",
			redirect: "",
			log: "%c3.1teamsTimeOut",
		},
		//Teams Auth
		teamsAuth: {
			message: "3.1 Requesting MS Team permission",
			redirect: "",
			log: "%c3.1teamsAuth",
		},
		//Teams InValidMail
		teamsInvalidMail: {
			message: "Error ID 3.2.2 - Teams invalid email",
			redirect: "",
			log: "%c3.2teamsInvalidMail",
		},
		//Teams ADIDMissing
		teamsADIDMissing: {
			message: "Error ID 3.2.3 - Teams Id missing",
			redirect: "",
			log: "%c3.2teamsADIDMissing",
		},
		//Teams ADIDMissing
		teamsDisplayNameMissing: {
			message: "3.2 Missing Display Name",
			redirect: "",
			log: "%c3.2teamsDisplayNameMissing",
		},
		//Teams CustomIDDeclined
		teamsCustomIDDeclined: {
			message: "Error ID 3.3.1 - Teams custom ID declined",
			redirect: "",
			log: "%c3.3teamsCustomIDDeclined",
		},
		//Teams CustomIDDeclined
		teamsCustomIDTimeout: {
			message: "Error ID 3.3.2 - Teams custom ID time-out",
			redirect: "",
			log: "%c3.3teamsCustomIDTimeout",
		},
		//Teams CustomIDNewUser
		teamsCustomIDNewUser: {
			message: "3.4",
			redirect: "",
			log: "%c3.4teamsCustomIDNewUser",
		},
		teamsAventriAuth: {
			message: "3.4",
			redirect: "",
			log: "%c3.5teamsAventriAuth",
		},
		//Teams Returning
		teamsReturning: {
			message: "",
			redirect: "",
			log: "%c3.9teamsReturning",
		},
		//Aventri TimeOut TODO: AVENTRI TIMEOUT
		AventriTimeout: {
			message: "Error ID 4.3 - Aventri time-out",
			redirect: "",
			log: "%cAventriTimeout",
		},
		//Aventri Declined
		AventriDeclined: {
			message: "Error ID 4.4 - Aventri declined",
			redirect: "",
			log: "%cAventriDeclined",
		},
		//Aventri Appprouved
		AventriApprouved: {
			message: `Session ready, loading ${REACT_APP_APP_NAME} Virtual Events`,
			redirect: "",
			log: "%cAventriApprouved",
			title: "Welcome",
			description: "You will be connected shortly",
		},
		//Aventri Appprouved
		dataReady: {
			message: `Session ready, loading ${REACT_APP_APP_NAME} Virtual Events`,
			redirect: "",
			log: "%cDataReady",
		},
		//EndFallBack
		endFallBack: {
			message: "Not Logged In, Redirecting you!",
			redirect: REACT_APP_LOGIN_REDIRECT_URL,
			log: "%cboth jwtInit and teamsInit fail redirecting to B2C LOGIN",
		},
	};

	function renderStep() {
		const state = currentState || "endFallBack";
		const { message, redirect, log, title, description } = loadingArray[
			state
		] || {
			message: "Fallback Default, Redirecting you!",
			redirect: REACT_APP_LOGIN_REDIRECT_URL,
			log: "%cboth jwtInit and teamsInit fail redirecting to B2C LOGIN",
		};
		const {
			message: messageLoading,
			title: titleLoading,
			description: descriptionLoading,
		} = loadingArray["loading"];

		return getMessage({
			message: acfPageOptions.acf ? message : messageLoading,
			title: acfPageOptions.acf ? title : titleLoading,
			description: acfPageOptions.acf ? description : descriptionLoading,
			redirect: redirect || null,
		});
	}

	return (
		<CSSTransition
			in={show}
			appear
			timeout={show ? 1500 : 600}
			unmountOnExit
			nodeRef={ref}
		>
			<div ref={ref}>
				<>{renderStep()}</>
			</div>
		</CSSTransition>
	);
}

Loading.defaultProps = {
	show: false,
	teamsUserPrincipalName: null,
	currentState: "welcomeInit",
	AddOrUpdateContactEmail: () => {},
	setAuthStep: () => {},
	setLoading: () => {},
	isDataLoaded: false,
	isChatAuthSet: false,
	isEmailSet: null,
	isMainChatConnected: false,
	isAccountCompleted: null,
	isAccountDeclined: null,
	acfPageOptions: null,
	setShowDebug: () => {},
};

Loading.propTypes = {
	show: PropTypes.bool,
	currentState: PropTypes.string,
	setAuthStep: PropTypes.func,
	setLoading: PropTypes.func,
	teamsUserPrincipalName: PropTypes.string,
	AddOrUpdateContactEmail: PropTypes.func,
	isDataLoaded: PropTypes.bool,
	isChatAuthSet: PropTypes.bool,
	isEmailSet: PropTypes.number,
	isMainChatConnected: PropTypes.bool,
	isAccountCompleted: PropTypes.bool,
	isAccountDeclined: PropTypes.bool,
	acfPageOptions: PropTypes.object,
	setShowDebug: PropTypes.func,
};

export default Loading;
