import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ComPanelContext } from "mobile/context/ComPanelContext";
import Indicator from "mobile/components/notifications/Indicator";

function Header({
	setShowCompanelNavigation,
	newMessages,
	newQuestions,
	messages,
	questions,
	stateVideo,
	newSupportMessages,
}) {
	const { activeCompanelTab, setActiveCompanelTab } =
		useContext(ComPanelContext);
	return (
		<>
			<button
				className="navigation-button"
				type="button"
				onClick={() => setShowCompanelNavigation(true)}
			>
				<div className="line" />
				<div className="middle-line" />
				<div className="line" />
				{((newMessages > 0 && activeCompanelTab !== "CHAT") ||
					(newQuestions > 0 && activeCompanelTab !== "Q&A") || (newSupportMessages > 0 && activeCompanelTab !== "SUPPORT")) && <Indicator />}
			</button>
			{
				stateVideo === "ended" && activeCompanelTab !== "SUPPORT" && <span className="closed-tab px-3 py-1">{activeCompanelTab} CLOSED</span>
			}
			<div className="d-flex justify-content-end px-3 py-1">
				<span className="position-absolute">{activeCompanelTab}</span>
			</div>
		</>
	);
}

Header.defaultProps = {
	setShowCompanelNavigation: () => {},
	newMessages: 0,
	newQuestions: 0,
	messages: [],
	questions: [],
	stateVideo: null,
	newSupportMessages: 0,
};

Header.propTypes = {
	setShowCompanelNavigation: PropTypes.func,
	newMessages: PropTypes.number,
	newQuestions: PropTypes.number,
	messages: PropTypes.array,
	questions: PropTypes.array,
	stateVideo: PropTypes.string,
	newSupportMessages: PropTypes.number,
};

export default Header;
