import React from "react";
import PropTypes from "prop-types";

function QuestionZeroState({ acfPageOptions }) {
	return (
		<div className="question-zero-state">
			<div className="message-container">
				<div className="icon-container">
					<img src={acfPageOptions?.acf?.theme?.icon?.live?.join_chat_icon?.url} alt=""/>
				</div>
				<div className="text-container">
					<span className="text">{acfPageOptions?.acf?.live_chat_panel?.initial_question_message}</span>
				</div>
			</div>
		</div>
	);
}

QuestionZeroState.defaultProps = {
	acfPageOptions: {},
}

QuestionZeroState.propTypes = {
	acfPageOptions: PropTypes.object,
};

export default QuestionZeroState;
