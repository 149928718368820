import { createSlice } from '@reduxjs/toolkit';

const tracking = createSlice({
	name: 'tracking',
	reducers: {
		setHasAttended: (state, action) => {
			return {
				...state,
				hasAttended: [
					...state.hasAttended,
					action.payload,
				],
			};
		},
		setWasLate: (state, action) => {
			return {
				...state,
				wasLate: [
					...state.wasLate,
					action.payload,
				],
			};
		},
		setHasSpoken: (state, action) => {
			return {
				...state,
				hasSpoken: [
					...state.hasSpoken,
					action.payload,
				],
			};
		},
		setHasRaisedHand: (state, action) => {
			return {
				...state,
				hasRaisedHand: [
					...state.hasRaisedHand,
					action.payload,
				],
			};
		},
		setHasChatted: (state, action) => {
			return {
				...state,
				hasChatted: [
					...state.hasChatted,
					action.payload,
				],
			};
		},
		setLeftEarly: (state, action) => {
			return {
				...state,
				leftEarly: [
					...state.leftEarly,
					action.payload,
				],
			};
		},
	},
	extraReducers: {
	},
	initialState: {
		hasAttended: [],
		wasLate: [],
		hasSpoken: [],
		hasRaisedHand: [],
		hasChatted: [],
		leftEarly: [],
	},
});

export default tracking;

export const {
	setHasAttended,
	setWasLate,
	setHasRaisedHand,
	setHasSpoken,
	setHasChatted,
	setLeftEarly,
} = tracking.actions;
