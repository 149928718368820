/** @format */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory, Redirect } from "react-router-dom";
import { PLAYFAB_EVENTS, ROUTES } from "Constants";
import { ComPanelContext } from "mobile/context/ComPanelContext";
import { SessionContext } from "mobile/context/SessionContext";
import logPageView from "utils/logPageView";
import { now, toLocalTime } from "utils/dateUtils";
import Meeting from "mobile/components/session/Meeting";
import Navigation from "mobile/components/companel/header/Navigation";
import ComPanel from "mobile/components/containers/companel/ComPanel";
import SessionEvents from "mobile/components/containers/session/SessionEvents";
import SessionVideoPlayer from "../components/SessionVideoPlayer";
import SessionDetails from "./SessionDetails";

function Session({
	playerStatistics,
	leaderboardOnUserPosition,
	playerData,
	activity,
	chatJoinRoom,
	chatLeaveRoom,
	username,
	playfabId,
	joinMeetingURL,
	history,
	getMeetingStatus,
	clearMessages,
	writePlayerEvent,
	writeTelemetryEvent,
	translations,
	getQuestions,
	getJitsiJwt,
	setHasAttended,
	hasAttended,
	wasLate,
	setWasLate,
	leftEarly,
	setLeftEarly,
	acfPageOptions,
	isAdmin,
	points,
	emojis,
	broadcastMessage,
	newMessages,
	newQuestions,
	newSupportMessages,
	clearQuestions,
	channel,
	messages,
	questions,
	groupLang,
	userAttendanceType,
}) {
	//If no activity, return 404 page
	if (!activity) {
		<Redirect to="/" />;
	}

	// const VID_URL = "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4";
	let timer = null;
	
	const [activeSession, setActiveSession] = useState(false);
	const [videoHeight, setVideoHeight] = useState(null);
	const { fullScreen, setFullScreen, sessionAttendanceType, setSessionAttendanceType, minimizeToggle, setMinimizeToggle } = useContext(SessionContext);
	const sessionHistory = useHistory();
	const [shouldFetchMeetingURL, setShouldFetchMeetingURL] = useState(false);
	const [url, setUrl] = useState("");
	const [stateVideo, setStateVideo] = useState(null);
	const [adjustedTime, setAdjustedTime] = useState(null);
	const [feedSwitched, setFeedSwitched] = useState(false);
	const { activeCompanelTab, setActiveCompanelTab } =
		useContext(ComPanelContext);
	const [showCompanelNavigation, setShowCompanelNavigation] = useState(true);
	const [showSessionDetails, setShowSessionDetails] = useState(false);
	const [sessionDetailsFullSceen, setSessionDetailsFullSceen] = useState(false);
	const [fullComPanel, setFullComPanel] = useState(false);
	const videoplayerContainer = document.querySelector(
		".session-player-wrapper"
	);
	const [session, setSession] = useState(null);

	const [steamVideoKey, setSteamVideoKey] = useState(1);
	const refreshStreamVideo = () => setSteamVideoKey(steamVideoKey + 1);

	useEffect(() => {
		if (activity) {
			setSession(activity);
		}
	}, [activity]);

	function handleCompanelFullScreen() {
		if (videoplayerContainer) {
			setFullComPanel(!fullComPanel);
		}
	}

	useEffect(() => {
		if (videoplayerContainer) {
			if (fullComPanel) {
				videoplayerContainer.style.maxHeight = 0;
			} else {
				videoplayerContainer.style.maxHeight = "1400px";
			}
		}
	}, [fullComPanel]);

	useEffect(() => {
		if (videoplayerContainer) {
			if (sessionDetailsFullSceen) {
				videoplayerContainer.style.maxHeight = 0;
			} else {
				videoplayerContainer.style.maxHeight = "600px";
			}
		}
	}, [sessionDetailsFullSceen]);

	//Check if user can join, If admin = 1, go through || event has started
	useEffect(() => {
		if (activity && !session?.canJoin && !isAdmin) {
			history.push(ROUTES.AGENDA);
		}
	}, [activity]);

	useEffect(() => {
		const fetchQuestionsInterval = setInterval(() => {
			if (channel) {
				getQuestions(channel);
			}
		}, 30000);

		return () => {
			clearInterval(fetchQuestionsInterval);
		};
	}, []);

	//Fetch Meeting URL
	useEffect(() => {
		let timeout = null;
		if (shouldFetchMeetingURL) {
			getMeetingStatus(session?.StoreTileId);

			timeout = setTimeout(() => {
				setShouldFetchMeetingURL(false);
			}, 5000);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, [shouldFetchMeetingURL]);

	//Join the chat channel
	useEffect(() => {
		const channel = session?.StoreTileId;
		if (channel && username) {
			chatJoinRoom({
				channel,
				username,
				playfabId,
			});
		}
		getQuestions(session?.StoreTileId);
	}, [session?.StoreTileId, username]);

	//Check if stream has ended, if yes redirect user to homepage
	useEffect(() => {
		function time() {
			if (toLocalTime(activity.date_close).$d <= toLocalTime(Date.now()).$d) {
				history.push(ROUTES.ROOT);
				return true;
			}
			timer = setTimeout(time, 1000);
			return false;
		}

		if (stateVideo === "ended" && !isAdmin) time();

		return () => {
			clearTimeout(timer);
		};
	}, [stateVideo, activity?.data, adjustedTime]);

	//Track user behavior
	useEffect(() => {
		writePlayerEvent({
			name: PLAYFAB_EVENTS.player_joined_stream,
			body: {
				StoreTileId: session?.StoreTileId,
				ActivityName: session?.content?.title?.rendered,
				ActivityType: session?.data.activity_type,
			},
		});

		//Engagement event on arriving
		writeTelemetryEvent({
			namespace: "custom.engagement",
			name: "enter_room",
			body: {
				activity: session?.content?.title?.rendered,
				StoreTileId: session?.StoreTileId,
			},
		});

		// Log PageView.
		logPageView(
			"Activity",
			playerStatistics,
			playfabId,
			playerData,
			leaderboardOnUserPosition[0]?.Position,
			null
		);

		tracking();

		return () => {
			const channel = session?.StoreTileId;
			chatLeaveRoom({
				channel,
			});
			clearMessages();
			clearQuestions();

			if (
				!leftEarly.includes(session?.StoreTileId) &&
				session?.date_end.isAfter(now().add(15, "minutes"))
			) {
				writePlayerEvent({
					name: PLAYFAB_EVENTS.player_meeting_event,
					body: {
						LeftEarly: 1,
						MeetingId: session?.StoreTileId,
					},
				});
				setLeftEarly(session?.StoreTileId);
			}
		};
	}, []);

	//SYNC STREAM
	useEffect(() => {
		function time() {
			if (toLocalTime(session?.date_close).$d <= toLocalTime(Date.now()).$d) {
				history.push(ROUTES.ROOT);
				return true;
			}
			timer = setTimeout(time, 1000);
			return false;
		}

		if (stateVideo === "ended" && !isAdmin) time();

		return () => {
			clearTimeout(timer);
		};
	}, [stateVideo, session?.data, adjustedTime]);

	//Show session component
	function openSession() {
		setActiveSession(true);
	}

	const exitSession = () => {
		setActiveSession(false);
		setMinimizeToggle(false);
		setUrl("");
		const channel = session?.StoreTileId;
		chatLeaveRoom({
			channel,
		});
		clearMessages();
		clearQuestions();
		setActiveCompanelTab("");
		setShowCompanelNavigation(true);
		setStateVideo(null);
		setSessionAttendanceType(null);
	};

	//If url contains session display "session" component
	useEffect(() => {
		const SESSION_PATH = location.pathname.split("/")[1];
		if (SESSION_PATH === "session") {
			openSession();
		}
	}, []);

	//If URL doesnt contain "session" minimize component
	useEffect(() => {
		return sessionHistory.listen(() => {
			const SESSION_PATH = location.pathname.split("/")[1];
			if (stateVideo === null && SESSION_PATH !== "session" && channel !== null){
				exitSession();
				setActiveSession(false);
			} else if (stateVideo === null && SESSION_PATH === "session"){
				openSession();
				setMinimizeToggle(false);
			} else if (SESSION_PATH !== "session" && stateVideo === "playing"){
				setMinimizeToggle(true);

			} else if (stateVideo !== "playing") {
				exitSession();
				setActiveSession(false);
			} else {
				openSession();
				setMinimizeToggle(false);
			}
		});
	}, [sessionHistory, stateVideo]);

	//Track user behavior
	function tracking() {
		if (!hasAttended.includes(session?.StoreTileId)) {
			setHasAttended(session?.StoreTileId);
			writePlayerEvent({
				name: PLAYFAB_EVENTS.player_meeting_event,
				body: {
					HasAttended: 1,
					MeetingId: session?.StoreTileId,
				},
			});
		}

		if (
			!wasLate.includes(session?.StoreTileId) &&
			now().isAfter(session?.date_begin.add(15, "minutes"))
		) {
			setWasLate(session?.StoreTileId);
			writePlayerEvent({
				name: PLAYFAB_EVENTS.player_meeting_event,
				body: {
					WasLate: 1,
					MeetingId: session?.StoreTileId,
				},
			});
		}
	}

	useEffect(() => {
		getPlayerState();
		refreshStreamVideo();
	}, [session, sessionAttendanceType]);

	function getPlayerState() {
		//if URL is empty
		if (url === "") {
			//if the session type is default or of type Prerecorded and that the Activity has not started
			if (
				session?.data.activity_type === "Default" ||
				(session?.data.activity_type === "Prerecorded" &&
					activity?.date_begin.isBefore(now()))
			) {
				setStateVideo("playing");
				setUrl(session?.data.stream_url);
				if (session?.date_begin === Number) {
					setAdjustedTime(
						(Date.now() - session.activity.date_begin.valueOf()) / 1000
					);
				}
			}

			if (session?.data.activity_type === "MeetingRoom") {
				if (!joinMeetingURL) {
					if (!shouldFetchMeetingURL) {
						setShouldFetchMeetingURL(true);
					}
				} else {
					getJitsiJwt();
					setStateVideo("meeting");
					setUrl(joinMeetingURL);
				}
			}
		}

		if (stateVideo === "meeting") {
			return (
				<Meeting
					url={url}
					joinMeetingURL={joinMeetingURL}
					meetingTitle={session?.content?.title?.rendered}
					acfPageOptions={acfPageOptions}
				/>
			);
		}
		return (
			<SessionVideoPlayer
				url={url}
				key={steamVideoKey}
				activeSession={activeSession}
				setActiveSession={setActiveSession}
				setVideoHeight={setVideoHeight}
				fullScreen={fullScreen}
				setFullScreen={setFullScreen}
				points={points}
				translations={translations}
				session={session}
				acfPageOptions={acfPageOptions}
				src={url}
				setUrl={setUrl}
				setAdjustedTime={setAdjustedTime}
				adjustedTime={adjustedTime}
				feedSwitched={feedSwitched}
				setFeedSwitched={setFeedSwitched}
				channel={session?.StoreTileId}
				emojis={emojis}
				showSessionDetails={showSessionDetails}
				setShowSessionDetails={setShowSessionDetails}
				playfabId={playfabId}
				broadcastMessage={broadcastMessage}
				setStateVideo={setStateVideo}
				stateVideo={stateVideo}
				chatLeaveRoom={chatLeaveRoom}
				clearMessages={clearMessages}
				clearQuestions={clearQuestions}
				setShowCompanelNavigation={setShowCompanelNavigation}
				userAttendanceType={userAttendanceType}
			/>
		);
	}

	return (
		<>
			<SessionEvents contextId={session?.StoreTileId} fullScreen={fullScreen} acfPageOptions={acfPageOptions} />
			<div
				className={`session ${activeSession ? "active" : ""} ${
					minimizeToggle ? "minimize" : ""
				} ${fullScreen ? "full-screen" : ""}`}
			>
				<div
					id="session-player-wrapper"
					className={`session-player-wrapper ${
						fullScreen ? "full-screen" : ""
					}`}
				>
					{getPlayerState()}
				</div>

				<section
					id="com-panel-container"
					className={`com-panel container ${
						!showSessionDetails && !fullScreen ? "active" : ""
					}`}
				>
					<button
						className="companel-fullscreen"
						aria-label="companel fullscreen"
						type="button"
						onClick={() => handleCompanelFullScreen()}
						onKeyDown={() => handleCompanelFullScreen()}
					/>
					<div className="companel-navigation">
						<Navigation
							showCompanelNavigation={showCompanelNavigation}
							setShowCompanelNavigation={setShowCompanelNavigation}
							session={session}
							newMessages={newMessages}
							newQuestions={newQuestions}
							newSupportMessages={newSupportMessages}
							messages={messages}
							questions={questions}
							stateVideo={stateVideo}
							acfPageOptions={acfPageOptions}
						/>
					</div>
					{!showCompanelNavigation && !minimizeToggle && (
						<ComPanel
							activeCompanelTab={activeCompanelTab}
							session={session}
							minimizeToggle={minimizeToggle}
							stateVideo={stateVideo}
							showCompanelNavigation={showCompanelNavigation}
							setShowCompanelNavigation={setShowCompanelNavigation}
							acfPageOptions={acfPageOptions}
						/>
					)}
				</section>
				<section
					className={`session-details-wrap ${
						showSessionDetails && !fullScreen ? "active" : ""
					}`}
				>
					<SessionDetails
						acfPageOptions={acfPageOptions}
						activity={session}
						sessionDetailsFullSceen={sessionDetailsFullSceen}
						setSessionDetailsFullSceen={setSessionDetailsFullSceen}
						groupLang={groupLang}
					/>
				</section>
			</div>
		</>
	);
}

Session.defaultProps = {
	playerStatistics: null,
	leaderboardOnUserPosition: [],
	playerData: null,
	activity: null,
	chatJoinRoom: () => {},
	chatLeaveRoom: () => {},
	getMeetingStatus: () => {},
	clearMessages: () => {},
	writePlayerEvent: () => {},
	writeTelemetryEvent: () => {},
	getQuestions: () => {},
	getJitsiJwt: () => {},
	username: "",
	playfabId: "",
	joinMeetingURL: null,
	translations: [],
	setHasAttended: () => {},
	hasAttended: null,
	setWasLate: () => {},
	wasLate: null,
	setLeftEarly: () => {},
	leftEarly: null,
	acfPageOptions: () => null,
	isAdmin: false,
	points: 0,
	emojis: null,
	broadcastMessage: () => {},
	newMessages: 0,
	newQuestions: 0,
	newSupportMessages: 0,
	clearQuestions: () => {},
	channel: "",
	messages: [],
	questions: [],
	groupLang: null,
	userAttendanceType: "",
};

Session.propTypes = {
	playerStatistics: PropTypes.object,
	leaderboardOnUserPosition: PropTypes.array,
	playerData: PropTypes.object,
	activity: PropTypes.object,
	chatJoinRoom: PropTypes.func,
	chatLeaveRoom: PropTypes.func,
	getMeetingStatus: PropTypes.func,
	clearMessages: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	getJitsiJwt: PropTypes.func,
	username: PropTypes.string,
	playfabId: PropTypes.string,
	translations: PropTypes.array,
	history: PropTypes.object.isRequired,
	joinMeetingURL: PropTypes.string,
	getQuestions: PropTypes.func,
	setHasAttended: PropTypes.func,
	hasAttended: PropTypes.array,
	setWasLate: PropTypes.func,
	wasLate: PropTypes.array,
	setLeftEarly: PropTypes.func,
	leftEarly: PropTypes.array,
	acfPageOptions: PropTypes.object,
	isAdmin: PropTypes.bool,
	points: PropTypes.number,
	emojis: PropTypes.array,
	broadcastMessage: PropTypes.func,
	newMessages: PropTypes.number,
	newQuestions: PropTypes.number,
	newSupportMessages: PropTypes.number,
	clearQuestions: PropTypes.func,
	channel: PropTypes.string,
	messages: PropTypes.array,
	questions: PropTypes.array,
	groupLang: PropTypes.string,
	userAttendanceType: PropTypes.string,
};

export default Session;