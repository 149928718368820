import React, { memo, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { SessionContext } from "mobile/context/SessionContext";
import { ComPanelContext } from "mobile/context/ComPanelContext";

const AsyncNotification = loadable((props) => import(`./${props.type}`), {
	cacheKey: (props) => props.type,
});

function Notification({
	notification,
	toastNotifications,
	markAsRead,
	newSupportMessages,
}) {
	const [view, setView] = useState(null);
	const { fullScreen, setFullScreen } = useContext(SessionContext);
	const { activeCompanelTab, setActiveCompanelTab } =
		useContext(ComPanelContext);
	useEffect(() => {
		if (notification) {
			return setView("TakeOver");
		}

		return null;
	}, [notification]);

	useEffect(() => {
		localStorage.removeItem("toastNotifications");
		return () => {
			localStorage.setItem(
				"toastNotifications",
				JSON.stringify(toastNotifications)
			);
		};
	}, [toastNotifications]);

	return (
		<div className={`notifications ${fullScreen ? "full-screen" : ""}`}>
			{view && <AsyncNotification type={view} notification={notification} />}
			{toastNotifications.map((notification) => {
				if (newSupportMessages === 0 && notification?.author === "_SYSTEM_") {
					return null;
				}
				if(notification?.mention === true && activeCompanelTab === "CHAT"){
					return null;
				}
				return (
					<AsyncNotification
						type={notification?.type}
						notification={notification}
						markAsRead={markAsRead}
						key={notification?.id}
						icon={notification?.icon}
					/>
				);
			})}
		</div>
	);
}

Notification.defaultProps = {
	notification: {},
	toastNotifications: [],
	markAsRead: () => {},
	newSupportMessages: 0,
};

Notification.propTypes = {
	notification: PropTypes.object,
	toastNotifications: PropTypes.array,
	markAsRead: PropTypes.func,
	newSupportMessages: PropTypes.number,
};

export default memo(Notification);
