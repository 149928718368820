import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { ReactComponent as LockIcon } from "assets/icons/pack1/gamification/icon-lock.svg";

function Single({ details, isInInventory, setViewedElement, type }) {
	function badgeAction() {
		setViewedElement(details);
	}
	return type === "badge" ? (
		<Card
			className="align-items-center badges-prizes-card"
			onClick={() => badgeAction()}
		>
			<Card.Body className="d-flex justify-content-center align-items-center flex-column">
				<Card.Img
					className={`rounded-circle ${isInInventory ? "unlocked" : "locked"}`}
					variant="top"
					src={details?.data?.icon}
				/>
				{!isInInventory && (
					<span className="position-absolute rounded-circle text-center locked-icon">
						<LockIcon />
					</span>
				)}
				<span className="text-center">{details?.data?.title}</span>
			</Card.Body>
			<Card.Footer className="border-top-0 points">
				<span>12 PTS</span>
			</Card.Footer>
		</Card>
	) : (
		<Card
			className="align-items-center badges-prizes-card"
			onClick={() => badgeAction()}
		>
			<Card.Body className="d-flex justify-content-center align-items-center flex-column">
				<Card.Img
					className="rounded-circle"
					variant="top"
					src={details?.image}
				/>
				<span className="text-center">{details?.title}</span>
			</Card.Body>
		</Card>
	);
}

Single.defaultProps = {
	details: null,
	setViewedElement: () => {},
	isInInventory: false,
	type: "",
};

Single.propTypes = {
	details: PropTypes.object,
	isInInventory: PropTypes.bool,
	setViewedElement: PropTypes.func,
	type: PropTypes.string,
};

export default Single;
