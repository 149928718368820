import React from 'react';
import PropTypes from 'prop-types';
import parse from "html-react-parser";
import Badge from './Badge';
import ContentOuterContainer from './ContentOuterContainer';
import ContentInnerContainer from './ContentInnerContainer';

function BadgesTab({
	badgesUnlocked,
	acfPageOptions,
}) {

	const displayBadge = (badge) => {
		if (!badge) {
			return null;
		}

		if (Object.keys(badge).length === 0 && badge.constructor === Object) {
			return null;
		}

		if (!Object.prototype.hasOwnProperty.call(badge, 'data')) {
			return null;
		}

		if (Object.keys(badge.data).length === 0 && badge.data.constructor === Object) {
			return null;
		}

		//Don't display if every values are undefined or null
		if (!Object.values(badge.data).every(data => data)) {
			return null;
		}

		return (
			<Badge
				key={badge.itemId}
				data={badge.data}
				isInInventory={badge.isInInventory}
			/>
		)
	}

	return (
		<ContentOuterContainer>
			{acfPageOptions.acf?.profile?.badges_tab?.badges_title && acfPageOptions.acf?.profile?.badges_tab?.badges_explanations && (
				<ContentInnerContainer customClass="left-side">
					<h1>{acfPageOptions.acf?.profile?.badges_tab?.badges_title}</h1>
					<p>{acfPageOptions.acf?.profile?.badges_tab?.badges_explanations}</p>
					<div className="badges-container">
						{badgesUnlocked.map((badge) => {
							return displayBadge(badge);
						})}
					</div>
				</ContentInnerContainer>
			)}
			{acfPageOptions.acf?.profile?.badges_tab?.prizes_title && acfPageOptions.acf?.profile?.badges_tab?.prizes_explanations && (
				<ContentInnerContainer customClass="left-side">
					<h1>{acfPageOptions.acf?.profile?.badges_tab?.prizes_title}</h1>
					<div className="prizes-explanations">
						<div>
							{acfPageOptions.acf?.profile?.badges_tab?.prizes_explanations &&
								parse(acfPageOptions.acf?.profile?.badges_tab?.prizes_explanations)}
						</div>
					</div>
					<div className="container-prizes">
						{acfPageOptions.acf?.profile?.badges_tab?.prizes &&
							acfPageOptions.acf?.profile?.badges_tab?.prizes.map((slot) => {
								return (
									<div key={slot.title} className="singular-prize">
										<img src={slot.image} alt="" />
										<h2>{slot.title}</h2>
										<p>{slot.description}</p>
										<p>{slot.fine_print}</p>
									</div>
								);
							})}
					</div>
				</ContentInnerContainer>
			)}
		</ContentOuterContainer>
	);
}

BadgesTab.defaultProps = {
	badgesUnlocked: [],
	acfPageOptions: null,
};

BadgesTab.propTypes = {
	badgesUnlocked: PropTypes.array,
	acfPageOptions: PropTypes.object,
};

export default BadgesTab;
