import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BackButton from 'components/BackButton';

function mapStateToProps(state) {
	return {
		acfPageOptions: state?.wpcontent?.acfPageOptions,
	};
}

export default withRouter(connect(
	mapStateToProps,
)(BackButton));
