import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import gsap, { Power3 } from 'gsap';
import { CSSTransition } from 'react-transition-group';
import dayjs from 'dayjs';
import ProfilePicture from 'assets/placeholders/profile-picture.png';

import { AWS_AVATAR_URL } from 'Constants';

const ANIMATION_DURATION = 1200;

function getAvatarUrl(playfabId) {
	return AWS_AVATAR_URL(playfabId);
}

function Leaderboard({
	acfPageOptions,
	leaderboard,
	playfabId,
	hideLeaderboard,
}) {
	if (!leaderboard || !leaderboard.list) return null;

	const top3 = leaderboard.list.slice(0, 3);

	const [show, setShow] = useState(false);

	const listRef = useRef();
	const leaderboardRef = useRef();

	function onEntered() {
		const element = listRef.current.querySelector('.current-user');
		if (!element) return;

		const offset = element.offsetTop - leaderboardRef.current.clientHeight / 2 + element.clientHeight / 2;

		gsap.to(listRef.current, {
			duration: 3,
			y: Math.round(-offset),
			ease: Power3.easeInOut,
		});
	}

	function onError(e) {
		e.preventDefault();
		e.currentTarget.src = ProfilePicture;
		return false;
	}

	useEffect(() => {
		const now = dayjs().valueOf();
		const expiration = dayjs(leaderboard?.meta?.expiration || now + 30).valueOf();
		const timeout = expiration - now - ANIMATION_DURATION;

		const t = setTimeout(() => {
			setShow(false);
		}, timeout); // for animateout

		setShow(true);

		return () => {
			clearTimeout(t);
		};
	}, [leaderboard]);

	return (
		<CSSTransition
			in={show}
			appear
			nodeRef={leaderboardRef}
			timeout={ANIMATION_DURATION}
			onEntered={() => onEntered()}
			onExited={() => {
				hideLeaderboard();
			}}
			unmountOnExit
			key={leaderboard.meta.expiration}
		>
			<div className="leaderboard" ref={leaderboardRef}>
				<div className="left">
					<div className="inner-container">
						<div className="top-3">
							{top3.map((entry, i) => {
								const isCurrent = playfabId === entry.playfabId;
								const isFirst = i === 0;
								return (
									<div className={`top-entry ${i + 1} ${isCurrent ? 'current-user' : ''}`} key={entry.playfabId + '_' + entry.rank}>
										<div className="top-group">
											<div className="rank">
												{isFirst ? <img alt="" src={acfPageOptions?.acf?.theme?.icon?.profile?.first_place?.sizes?.thumbnail}/>: null}
											</div>
											<div className="avatar">
												<img width="300" height="300" src={getAvatarUrl(entry.playfabId)} onError={onError} alt="" />
											</div>
											<div className="level">
												<svg width="32" height="32" viewBox="0 0 40 40">
													<g id="Polygon_2" fill="url(#linear-gradient)">
														<circle cx="20" cy="20" r="20" fill={acfPageOptions?.acf?.theme?.color?.primary}/>
													</g>
												</svg>
												<div className="num">{i + 1}</div>
											</div>
										</div>
										<div className="name">{entry.displayName}</div>
										<div className="value">{entry.statValue}</div>
										<p className="points-label">
											{acfPageOptions?.acf?.leaderboard_tab?.points_short_label}
										</p>
									</div>
								);
							})}
						</div>
						<p className="top-3-title">{acfPageOptions?.acf?.leaderboard_tab?.title}</p>
					</div>

				</div>
				<div className="right">
					<div className="list" ref={listRef}>
						{leaderboard.list.map((entry) => {
							const isCurrent = playfabId === entry.playfabId;

							return (
								<div className={`leaderboard-entry ${isCurrent ? 'current-user' : ''}`} key={entry.playfabId + '_' + entry.rank}>
									<div className="leader-profile">
										<div className="rank">{entry.rank}</div>
										<div className="avatar"><img width="300" height="300" src={getAvatarUrl(entry.playfabId)} onError={onError} alt="" /></div>
										<div className="name">{entry.displayName}</div>
									</div>
									<div className="value">{entry.statValue}</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}

Leaderboard.defaultProps = {
	acfPageOptions: null,
	leaderboard: null,
	playfabId: null,
	hideLeaderboard: () => { },
};

Leaderboard.propTypes = {
	acfPageOptions: PropTypes.object,
	leaderboard: PropTypes.object,
	playfabId: PropTypes.string,
	hideLeaderboard: PropTypes.func,
};

export default Leaderboard;
