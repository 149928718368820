import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ContactList from 'components/Profile/ContactList';
import { getFriendsList, sendFriendRequest, acceptFriendRequest, declineFriendRequest } from 'redux/playfab';
import {
	getUsers,
} from 'redux/rooms';

function mapStateToProps(state) {
	return {
		acfPageOptions: state.wpcontent?.acfPageOptions,
		friendsList: state?.playfab?.FriendsList,
		friendRequests: state?.playfab?.FriendRequests,
		users: state.rooms?.users,
		playfabId: state.playfab?.PlayFabId,
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		getFriendsList,
		sendFriendRequest,
		getUsers,
		acceptFriendRequest,
		declineFriendRequest,
	}, dispatch)
)(ContactList));