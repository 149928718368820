import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchWPPageData } from 'redux/wppage';
import Faq from 'components/Pages/Faq';
import { writeTelemetryEvent } from 'redux/playfab';
import { playerGroupSelector } from 'selector/player';

import { statsSelector } from 'selector/stats';

function mapStateToProps(state, props) {
	return {
		playerStatistics: statsSelector(state, props),
		playfabId: state.playfab?.PlayFabId,
		playerData: state.playfab?.PlayerData,
		leaderboardOnUserPosition: state.playfab?.OnUserPositionLeaderboard,
		faqData: state.wppage.pageContent,
		acfPageOptions: state.wpcontent.acfPageOptions,
		playerGroup: playerGroupSelector(state),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		fetchWPPageData,
		writeTelemetryEvent,
	}, dispatch)
)(Faq);
