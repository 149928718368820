import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	broadcastMessage,
} from 'redux/chat';
import ChatSupportChat from 'components/ChatSupport/ChatSupportChat';
import { userNameSelector } from 'selector/player';
import { statSelector } from 'selector/stats';
import { USER_LEVELS, CATALOG } from 'Constants';
import { itemInventorySelector } from 'selector/inventory';

function mapStateToProps(state) {
	return {
		connection: state.chatSupport.connection,
		channel: state.chatSupport.channel,
		messages: state.chatSupport.messages,
		joined_room: state.chatSupport.joined_room,
		subject: state.chatSupport.subject,
		ownPlayfabId: state.playfab?.PlayFabId,
		displayName: userNameSelector(state, { fallback: state.teams.userPrincipalName }),
		isModerator: statSelector(state, { statName: 'userlevel' }) === USER_LEVELS.MODERATOR,
		canChat: !itemInventorySelector(state, { itemId: CATALOG.disable_chat }),
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		broadcastMessage,
	}, dispatch)
)(ChatSupportChat);
