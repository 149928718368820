import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

function RadioCheckToggle({ name, label, checked, type, id, value, onChange, disabled, className }) {
	return (
		<Form.Check
			className={className}
			type={type}
			id={id}
			name={name}
			label={label}
			value={value}
			checked={checked}
			onChange={(ev) => onChange(ev)}
			disabled={disabled}
		/>
	);
}

RadioCheckToggle.defaultProps = {
	name: null,
	label: "",
	checked: null,
	type: null,
	id: null,
	value: false,
	onChange: () => {},
	disabled: false,
	className: "",
};

RadioCheckToggle.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	checked: PropTypes.bool,
	type: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]), 
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
};

export default RadioCheckToggle;
