import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";
import { SessionContext } from "mobile/context/SessionContext";
import RadioCheckToggle from "mobile/components/partials/RadioCheckToggle";
import { ReactComponent as SettingIcon } from "assets/icons/pack1/menus/main-menu/icon-settings.svg";

function Settings({
	acfPageOptions,
	updatePlayerData,
	enableNotifications,
	profilePrivacy,
	attendanceType,
}) {
	const [checkedNotificationsToggle, setCheckedNotificationsToggle] = useState(
		enableNotifications?.Value && JSON.parse(enableNotifications?.Value)
	);
	const [checkedProfilePrivacy, setCheckedProfilePrivacy] = useState(
		profilePrivacy?.Value && JSON.parse(profilePrivacy?.Value)
	);
	const [checkedAttendanceType, setCheckedAttendanceType] = useState(
		attendanceType?.Value || "ask"
	);
	const { minimizeToggle } = useContext(SessionContext);

	const switchNotifications = () => {
		setCheckedNotificationsToggle(!checkedNotificationsToggle);
		onUpdateValue("EnableNotifications", String(!checkedNotificationsToggle));
	};

	const switchAttendanceType = (ev) => {
		setCheckedAttendanceType(ev.target.value);
		onUpdateValue("AttendanceType", String(ev.target.value));
		if(ev.target.value !== 'ask') {
			localStorage.setItem("hasDefaultAttendanceType", true);
		} else {
			localStorage.removeItem("hasDefaultAttendanceType", true);
		}
	};

	const switchProfilePrivacy = () => {
		setCheckedProfilePrivacy(!checkedProfilePrivacy);
		onUpdateValue("ProfilePrivacy", String(!checkedProfilePrivacy));
	};

	function onUpdateValue(name, value) {
		updatePlayerData({ [name]: value });
	}

	return (
		<Accordion className={`profile-settings ${minimizeToggle ? "pb-5" : ''}`}>
			<Accordion.Item eventKey="0">
				{/*TODO Create settings icon cogweheel ACF and the accordion button :after */}
				<Accordion.Header>
					<SettingIcon />
					<span className="px-2">{acfPageOptions.acf?.profile?.profile_tab?.settings_title}</span>
				</Accordion.Header>
				<Accordion.Body>
					<p>Privacy</p>
					{/*TODO Create privacy label ACF*/}
					<div className="container setting-container p-3 mb-2">
						<div className="d-flex justify-content-between align-items-center mb-2">
							<span>{acfPageOptions.acf?.profile?.profile_tab?.public_profile_title}</span>
							{/*TODO Create public profile label ACF*/}
							<RadioCheckToggle
								id="profile"
								type="switch"
								checked={checkedProfilePrivacy}
								onChange={switchProfilePrivacy}
								className="mb-3 mobile-toggle"
							/>
						</div>
						<div className="d-flex justify-content-between align-items-center">
							<span>
								{acfPageOptions.acf?.profile?.profile_tab?.notifications_title}
							</span>
							<RadioCheckToggle
								id="notifications"
								type="switch"
								checked={checkedNotificationsToggle}
								onChange={switchNotifications}
								className="mb-3 mobile-toggle"
							/>
						</div>
					</div>
					<p>{acfPageOptions.acf?.profile?.profile_tab?.attendance_type_title}</p>
					<div className="container setting-container p-3 mb-2">
						<p>{acfPageOptions.acf?.profile?.profile_tab?.attendance_type_description}</p>
						{/*TODO Create attendance type ACF*/}
						<RadioCheckToggle
							className="mb-3 mobile-radio"
							type="radio"
							id="radio-1"
							name="attendance"
							label="Virtual"
							value="virtual"
							checked={checkedAttendanceType === "virtual"}
							onChange={(ev) => switchAttendanceType(ev)}
						/>
						<RadioCheckToggle
							className="mb-3 mobile-radio"
							type="radio"
							id="radio-2"
							name="attendance"
							label="In-Person"
							value="in-person"
							checked={checkedAttendanceType === "in-person"}
							onChange={(ev) => switchAttendanceType(ev)}
						/>
						<RadioCheckToggle
							className="mb-3 mobile-radio"
							type="radio"
							id="radio-3"
							name="attendance"
							label="Ask me everytime before joining the event"
							value="ask"
							checked={checkedAttendanceType === "ask"}
							onChange={(ev) => switchAttendanceType(ev)}
						/>
					</div>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}

Settings.defaultProps = {
	acfPageOptions: null,
	updatePlayerData: () => {},
	enableNotifications: null,
	profilePrivacy: null,
	attendanceType: null,
};

Settings.propTypes = {
	acfPageOptions: PropTypes.object,
	updatePlayerData: PropTypes.func,
	enableNotifications: PropTypes.object,
	profilePrivacy: PropTypes.object,
	attendanceType: PropTypes.object,
};

export default Settings;
