import React from "react";
import PropTypes from "prop-types";
import ProfilePicture from "assets/icons/pack1/profile/profile/icon-user-pic-placeholder.svg";
import { ReactComponent as EditIcon } from "assets/icons/pack1/profile/icon-edit.svg";

function Avatar({ avatar, displayName, acfPageOptions, onClickEditAvatar }) {
	return (
		<div className="d-flex justify-content-start align-items-center">
			<div className="d-flex flex-row justify-content-start align-items-center profile-avatar"
				onClick={() => onClickEditAvatar()}
				onKeyDown={() => onClickEditAvatar()}
				role="button"
				tabIndex="-1"
			>
				<img
					className="rounded-circle"
					src={avatar || ProfilePicture}
					alt="profile avatar"
				/>
				<EditIcon className="edit-icon rounded-circle p-1" />
			</div>

			<div className="d-flex flex-column px-3">
				<span className="text-uppercase text-white m-profile-name">{displayName}</span>
			</div>
		</div>
	);
}

Avatar.defaultProps = {
	avatar: "",
	displayName: "",
	acfPageOptions: null,
	onClickEditAvatar: () => {},
};

Avatar.propTypes = {
	avatar: PropTypes.string,
	displayName: PropTypes.string,
	acfPageOptions: PropTypes.object,
	onClickEditAvatar: PropTypes.func,
};

export default Avatar;
