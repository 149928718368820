//Reference: https://github.com/jitsi/jitsi-meet/blob/master/config.js
export const configHost = {
	disableModeratorIndicator: false,
	disableReactions: true,
	disableReactionsModeration: false,
	disablePolls: true,
	disableSelfView: false,
	disableSelfViewSettings: false,
	screenshotCapture: {
		enabled: false,
		mode: "recording",
	},
	disableAudioLevels: false,
	enableNoAudioDetection: true,
	enableSaveLogs: false,
	disableShowMoreStats: false,
	enableNoisyMicDetection: true,
	// startAudioOnly: true,
	// startWithAudioMuted: true,
	cameraFacingMode: "user",
	resolution: 720,
	disableRemoveRaisedHandOnFocus: false,
	// startWithVideoMuted: true,
	transcription: {
		enalbed: false,
		translationLanguages: ["en", "es", "fr"],
		translationLanguagesHead: ["en"],
	},
	requireDisplayName: true,
	welcomePage: {
		disabled: true,
		// customUrl: ''
	},
	// defaultLocalDisplayName: "Moderator",
	// defaultRemoteDisplayName: "Moderator",
	hideDominantSpeakerBadge: false,
	disableProfile: true,
	hideEmailInSettings: true,
	prejoinConfig: {
		// When 'true', it shows an intermediate page before joining, where the user can configure their devices.
		// This replaces `prejoinPageEnabled`. Defaults to true.
		enabled: false,
		// Hides the participant name editing field in the prejoin screen.
		// If requireDisplayName is also set as true, a name should still be provided through
		// either the jwt or the userInfo from the iframe api init object in order for this to have an effect.
		hideDisplayName: true,
		// List of buttons to hide from the extra join options dropdown.
		hideExtraJoinButtons: ["no-audio", "by-phone"],
		readOnlyName: true,
	},
	enableInsecureRoomNameWarning: false,
	enableAutomaticUrlCopy: false,
	toolbarButtons: [
		"camera",
		"chat",
		// "closedcaptions",
		"desktop",
		//    'download',
		//    'embedmeeting',
		//    'etherpad',
		//    'feedback',
		"filmstrip",
		"fullscreen",
		"hangup",
		//    'help',
		//    'highlight',
		//    'invite',
		//    'linktosalesforce',
		//    'livestreaming',
		"microphone",
		//    'noisesuppression',
		"participants-pane",
		//    'profile',
		"raisehand",
		"recording",
		//    'security',
		"select-background",
		//    'settings',
		//    'shareaudio',
		//    'sharedvideo',
		//    'shortcuts',
		//    'stats',
		"tileview",
		"toggle-camera",
		"videoquality",
		"whiteboard",
	],

	toolbarConfig: {
		// Moved from interfaceConfig.INITIAL_TOOLBAR_TIMEOUT
		// The initial number of milliseconds for the toolbar buttons to be visible on screen.
		initialTimeout: 20000,
		// Moved from interfaceConfig.TOOLBAR_TIMEOUT
		// Number of milliseconds for the toolbar buttons to be visible on screen.
		timeout: 4000,
		// Moved from interfaceConfig.TOOLBAR_ALWAYS_VISIBLE
		// Whether toolbar should be always visible or should hide after x milliseconds.
		alwaysVisible: false,
		// Indicates whether the toolbar should still autohide when chat is open
		autoHideWhileChatIsOpen: false,
	},
	// Options related to the remote participant menu.
	remoteVideoMenu: {
		//     // Whether the remote video context menu to be rendered or not.
		//     disabled: true,
		//     // If set to true the 'Switch to visitor' button will be disabled.
		disableDemote: true,
		//     // If set to true the 'Kick out' button will be disabled.
		//     disableKick: true,
		//     // If set to true the 'Grant moderator' button will be disabled.
		disableGrantModerator: true,
		//     // If set to true the 'Send private message' button will be disabled.
		disablePrivateChat: true,
	},
	// buttonsWithNotifyClick: [
	//     'camera',
	//     {
	//         key: 'chat',
	//         preventExecution: false
	//     },
	//     {
	//         key: 'closedcaptions',
	//         preventExecution: true
	//     },
	//     'desktop',
	//     'download',
	//     'embedmeeting',
	//     'end-meeting',
	//     'etherpad',
	//     'feedback',
	//     'filmstrip',
	//     'fullscreen',
	//     'hangup',
	//     'hangup-menu',
	//     'help',
	//     {
	//         key: 'invite',
	//         preventExecution: false
	//     },
	//     'livestreaming',
	//     'microphone',
	//     'mute-everyone',
	//     'mute-video-everyone',
	//     'noisesuppression',
	//     'participants-pane',
	//     'profile',
	//     {
	//         key: 'raisehand',
	//         preventExecution: true
	//     },
	//     'recording',
	//     'security',
	//     'select-background',
	//     'settings',
	//     'shareaudio',
	//     'sharedvideo',
	//     'shortcuts',
	//     'stats',
	//     'tileview',
	//     'toggle-camera',
	//     'videoquality',
	//     // The add passcode button from the security dialog.
	//     {
	//         key: 'add-passcode',
	//         preventExecution: false
	//     },
	//     'whiteboard',
	// ],
	// participantMenuButtonsWithNotifyClick: [
	//     'allow-video',
	//     {
	//         key: 'ask-unmute',
	//         preventExecution: false
	//     },
	//     'conn-status',
	//     'flip-local-video',
	//     'grant-moderator',
	//     {
	//         key: 'kick',
	//         preventExecution: true
	//     },
	//     {
	//         key: 'hide-self-view',
	//         preventExecution: false
	//     },
	//     'mute',
	//     'mute-others',
	//     'mute-others-video',
	//     'mute-video',
	//     'pinToStage',
	//     'privateMessage',
	//     {
	//         key: 'remote-control',
	//         preventExecution: false
	//     },
	//     'send-participant-to-room',
	//     'verify',
	// ],
	disableInviteFunctions: true,
	doNotStoreRoom: true,
	participantsPane: {
		//     // Enables feature
		enabled: true,
		//     // Hides the moderator settings tab.
		//     hideModeratorSettingsTab: false,
		//     // Hides the more actions button.
		//     hideMoreActionsButton: false,
		//     // Hides the mute all button.
		//     hideMuteAllButton: false,
	},
	// breakoutRooms: {
	//     // Hides the add breakout room button. This replaces `hideAddRoomButton`.
	//     hideAddRoomButton: false,
	//     // Hides the auto assign participants button.
	//     hideAutoAssignButton: false,
	//     // Hides the join breakout room button.
	//     hideJoinRoomButton: false,
	// },
	virtualBackgrounds: [
		"https://wp.cf.holoscene.events/demo/2024/lightspeed/background/lightspeed-bg-1.png",
		"https://wp.cf.holoscene.events/demo/2024/lightspeed/background/lightspeed-bg-2.png",
	],
	disableVirtualBackground: false,
	disableAddingBackgroundImages: false,
	hideConferenceSubject: true,
	hideConferenceTimer: true,
	hideParticipantsStats: true,
	// subject: "Conference Subject",
	// localSubject: "Conference Local Subject",
	disableFilmstripAutohiding: true,
	disableChatSmileys: true,
	giphy: {
		//     // Whether the feature is enabled or not.
		enabled: false,
		//     // SDK API Key from Giphy.
		//     sdkKey: '',
		//     // Display mode can be one of:
		//     // - tile: show the GIF on the tile of the participant that sent it.
		//     // - chat: show the GIF as a message in chat
		//     // - all: all of the above. This is the default option
		//     displayMode: 'all',
		//     // How long the GIF should be displayed on the tile (in milliseconds).
		//     tileTime: 5000,
		//     // Limit results by rating: g, pg, pg-13, r. Default value: g.
		//     rating: 'pg',
		//     // The proxy server url for giphy requests in the web app.
		//     proxyUrl: 'https://giphy-proxy.example.com',
	},
	whiteboard: {
		//     // Whether the feature is enabled or not.
		enabled: true,
		//     // The server used to support whiteboard collaboration.
		//     // https://github.com/jitsi/excalidraw-backend
		//     collabServerBaseUrl: 'https://excalidraw-backend.example.com',
		//     // The user access limit to the whiteboard, introduced as a means
		//     // to control the performance.
		userLimit: 150,
		//     // The url for more info about the whiteboard and its usage limitations.
		//     limitUrl: 'https://example.com/blog/whiteboard-limits,
	},
	conferenceInfo: {
		// those labels will not be hidden in tandem with the toolbox.
		alwaysVisible: ["raised-hands-count"],
		// those labels will be auto-hidden in tandem with the toolbox buttons.
		autoHide: [
			"subject",
			"conference-timer",
			// "participants-count",
			"e2ee",
			// "video-quality",
			"insecure-room",
			"highlight-moment",
			"top-panel-toggle",
		],
	},
};
export const interfaceConfigHost = {
	BRAND_WATERMARK_LINK: "",
	CLOSE_PAGE_GUEST_HINT: false,
	DEFAULT_BACKGROUND: "#004F94",
	DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
	DISABLE_PRESENCE_STATUS: true,
	DISABLE_RINGING: true,
	DISABLE_TRANSCRIPTION_SUBTITLES: false,
	DISABLE_VIDEO_BACKGROUND: false,
	DISPLAY_WELCOME_FOOTER: true,
	DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
	DISPLAY_WELCOME_PAGE_CONTENT: false,
	DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
	HIDE_INVITE_MORE_HEADER: true,
	LANG_DETECTION: true,
	MOBILE_APP_PROMO: false,
	RECENT_LIST_ENABLED: false,
	REMOTE_THUMBNAIL_RATIO: 1,
	SETTINGS_SECTIONS: [
		"devices",
		"language",
		"moderator",
		"profile",
		"calendar",
		"sounds",
		"more",
	],
	SHOW_BRAND_WATERMARK: false,
	SHOW_CHROME_EXTENSION_BANNER: false,
	SHOW_JITSI_WATERMARK: false,
	SHOW_POWERED_BY: false,
	SHOW_PROMOTIONAL_CLOSE_PAGE: false,
	VERTICAL_FILMSTRIP: true,
	VIDEO_LAYOUT_FIT: "both",
	VIDEO_QUALITY_LABEL_DISABLED: false,
};
export const configDefaultParticipant = {
	disableModeratorIndicator: false,
	disableReactions: true,
	disableReactionsModeration: false,
	disablePolls: true,
	disableSelfView: false,
	disableSelfViewSettings: false,
	screenshotCapture: {
		enabled: false,
		mode: "recording",
	},
	disableAudioLevels: false,
	enableNoAudioDetection: true,
	enableSaveLogs: false,
	disableShowMoreStats: false,
	enableNoisyMicDetection: true,
	// startAudioOnly: true,
	// startWithAudioMuted: true,
	cameraFacingMode: "user",
	resolution: 720,
	disableRemoveRaisedHandOnFocus: false,
	// startWithVideoMuted: true,
	transcription: {
		enalbed: false,
		translationLanguages: ["en", "es", "fr"],
		translationLanguagesHead: ["en"],
	},
	requireDisplayName: true,
	welcomePage: {
		disabled: true,
		// customUrl: ''
	},
	defaultLocalDisplayName: "User",
	defaultRemoteDisplayName: "User",
	hideDominantSpeakerBadge: false,
	disableProfile: true,
	hideEmailInSettings: true,
	prejoinConfig: {
		// When 'true', it shows an intermediate page before joining, where the user can configure their devices.
		// This replaces `prejoinPageEnabled`. Defaults to true.
		enabled: false,
		// Hides the participant name editing field in the prejoin screen.
		// If requireDisplayName is also set as true, a name should still be provided through
		// either the jwt or the userInfo from the iframe api init object in order for this to have an effect.
		hideDisplayName: true,
		// List of buttons to hide from the extra join options dropdown.
		hideExtraJoinButtons: ["no-audio", "by-phone"],
		readOnlyName: true,
	},
	enableInsecureRoomNameWarning: false,
	enableAutomaticUrlCopy: false,
	toolbarButtons: [
		"camera",
		"chat",
		// "closedcaptions",
		"desktop",
		//    'download',
		//    'embedmeeting',
		//    'etherpad',
		//    'feedback',
		"filmstrip",
		"fullscreen",
		"hangup",
		//    'help',
		//    'highlight',
		//    'invite',
		//    'linktosalesforce',
		//    'livestreaming',
		"microphone",
		//    'noisesuppression',
		"participants-pane",
		//    'profile',
		"raisehand",
		//    'recording',
		//    'security',
		//    'select-background',
		//    'settings',
		//    'shareaudio',
		//    'sharedvideo',
		//    'shortcuts',
		//    'stats',
		"tileview",
		"toggle-camera",
		"videoquality",
		"whiteboard",
	],
	remoteVideoMenu: {
		//     // Whether the remote video context menu to be rendered or not.
		//     disabled: true,
		//     // If set to true the 'Switch to visitor' button will be disabled.
		disableDemote: true,
		//     // If set to true the 'Kick out' button will be disabled.
		//     disableKick: true,
		//     // If set to true the 'Grant moderator' button will be disabled.
		disableGrantModerator: true,
		//     // If set to true the 'Send private message' button will be disabled.
		disablePrivateChat: true,
	},
	virtualBackgrounds: [
		"https://wp.cf.holoscene.events/demo/2024/lightspeed/background/lightspeed-bg-1.png",
		"https://wp.cf.holoscene.events/demo/2024/lightspeed/background/lightspeed-bg-2.png",
	],
	disableVirtualBackground: false,
	disableAddingBackgroundImages: false,
	toolbarConfig: {
		// Moved from interfaceConfig.INITIAL_TOOLBAR_TIMEOUT
		// The initial number of milliseconds for the toolbar buttons to be visible on screen.
		initialTimeout: 20000,
		// Moved from interfaceConfig.TOOLBAR_TIMEOUT
		// Number of milliseconds for the toolbar buttons to be visible on screen.
		timeout: 4000,
		// Moved from interfaceConfig.TOOLBAR_ALWAYS_VISIBLE
		// Whether toolbar should be always visible or should hide after x milliseconds.
		alwaysVisible: false,
		// Indicates whether the toolbar should still autohide when chat is open
		autoHideWhileChatIsOpen: false,
	},
	// buttonsWithNotifyClick: [
	//     'camera',
	//     {
	//         key: 'chat',
	//         preventExecution: false
	//     },
	//     {
	//         key: 'closedcaptions',
	//         preventExecution: true
	//     },
	//     'desktop',
	//     'download',
	//     'embedmeeting',
	//     'end-meeting',
	//     'etherpad',
	//     'feedback',
	//     'filmstrip',
	//     'fullscreen',
	//     'hangup',
	//     'hangup-menu',
	//     'help',
	//     {
	//         key: 'invite',
	//         preventExecution: false
	//     },
	//     'livestreaming',
	//     'microphone',
	//     'mute-everyone',
	//     'mute-video-everyone',
	//     'noisesuppression',
	//     'participants-pane',
	//     'profile',
	//     {
	//         key: 'raisehand',
	//         preventExecution: true
	//     },
	//     'recording',
	//     'security',
	//     'select-background',
	//     'settings',
	//     'shareaudio',
	//     'sharedvideo',
	//     'shortcuts',
	//     'stats',
	//     'tileview',
	//     'toggle-camera',
	//     'videoquality',
	//     // The add passcode button from the security dialog.
	//     {
	//         key: 'add-passcode',
	//         preventExecution: false
	//     },
	//     'whiteboard',
	// ],
	// participantMenuButtonsWithNotifyClick: [
	//     'allow-video',
	//     {
	//         key: 'ask-unmute',
	//         preventExecution: false
	//     },
	//     'conn-status',
	//     'flip-local-video',
	//     'grant-moderator',
	//     {
	//         key: 'kick',
	//         preventExecution: true
	//     },
	//     {
	//         key: 'hide-self-view',
	//         preventExecution: false
	//     },
	//     'mute',
	//     'mute-others',
	//     'mute-others-video',
	//     'mute-video',
	//     'pinToStage',
	//     'privateMessage',
	//     {
	//         key: 'remote-control',
	//         preventExecution: false
	//     },
	//     'send-participant-to-room',
	//     'verify',
	// ],
	disableInviteFunctions: true,
	doNotStoreRoom: true,
	participantsPane: {
		//     // Enables feature
		enabled: true,
		//     // Hides the moderator settings tab.
		hideModeratorSettingsTab: true,
		//     // Hides the more actions button.
		//     hideMoreActionsButton: false,
		//     // Hides the mute all button.
		hideMuteAllButton: true,
	},
	breakoutRooms: {
		//     // Hides the add breakout room button. This replaces `hideAddRoomButton`.
		hideAddRoomButton: true,
		//     // Hides the auto assign participants button.
		 hideAutoAssignButton: true,
		//     // Hides the join breakout room button.
		hideJoinRoomButton: false,
	},
	hideConferenceSubject: true,
	hideConferenceTimer: true,
	hideParticipantsStats: true,
	// subject: "Conference Subject",
	// localSubject: "Conference Local Subject",
	disableFilmstripAutohiding: true,
	disableChatSmileys: true,
	giphy: {
		//     // Whether the feature is enabled or not.
		enabled: false,
		//     // SDK API Key from Giphy.
		//     sdkKey: '',
		//     // Display mode can be one of:
		//     // - tile: show the GIF on the tile of the participant that sent it.
		//     // - chat: show the GIF as a message in chat
		//     // - all: all of the above. This is the default option
		//     displayMode: 'all',
		//     // How long the GIF should be displayed on the tile (in milliseconds).
		//     tileTime: 5000,
		//     // Limit results by rating: g, pg, pg-13, r. Default value: g.
		//     rating: 'pg',
		//     // The proxy server url for giphy requests in the web app.
		//     proxyUrl: 'https://giphy-proxy.example.com',
	},
	whiteboard: {
		//     // Whether the feature is enabled or not.
		enabled: true,
		//     // The server used to support whiteboard collaboration.
		//     // https://github.com/jitsi/excalidraw-backend
		//     collabServerBaseUrl: 'https://excalidraw-backend.example.com',
		//     // The user access limit to the whiteboard, introduced as a means
		//     // to control the performance.
		userLimit: 500,
		//     // The url for more info about the whiteboard and its usage limitations.
		//     limitUrl: 'https://example.com/blog/whiteboard-limits,
	},
	conferenceInfo: {
		// those labels will not be hidden in tandem with the toolbox.
		alwaysVisible: ["raised-hands-count"],
		// those labels will be auto-hidden in tandem with the toolbox buttons.
		autoHide: [
			"subject",
			"conference-timer",
			// "participants-count",
			"e2ee",
			// "video-quality",
			"insecure-room",
			"highlight-moment",
			"top-panel-toggle",
		],
	},
};
export const interfaceConfigDefaultParticipant = {
	BRAND_WATERMARK_LINK: "",
	CLOSE_PAGE_GUEST_HINT: false,
	DEFAULT_BACKGROUND: "#004F94",
	DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
	DISABLE_PRESENCE_STATUS: true,
	DISABLE_RINGING: true,
	DISABLE_TRANSCRIPTION_SUBTITLES: true,
	DISABLE_VIDEO_BACKGROUND: false,
	DISPLAY_WELCOME_FOOTER: false,
	DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
	DISPLAY_WELCOME_PAGE_CONTENT: false,
	DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
	HIDE_INVITE_MORE_HEADER: true,
	LANG_DETECTION: false,
	MOBILE_APP_PROMO: false,
	RECENT_LIST_ENABLED: false,
	REMOTE_THUMBNAIL_RATIO: 1,
	SETTINGS_SECTIONS: [
		// "devices",
		// "language",
		// "moderator",
		// "profile",
		// "calendar",
		"sounds",
		// "more",
	],
	SHOW_BRAND_WATERMARK: false,
	SHOW_CHROME_EXTENSION_BANNER: false,
	SHOW_JITSI_WATERMARK: false,
	SHOW_POWERED_BY: false,
	SHOW_PROMOTIONAL_CLOSE_PAGE: false,
	VERTICAL_FILMSTRIP: true,
	VIDEO_LAYOUT_FIT: "both",
	VIDEO_QUALITY_LABEL_DISABLED: false,
};
