/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Field from 'components/Partials/Field';
import Button from "components/Buttons/Button";

function Answers({
	acfPageOptions,
	hasBadge,
	sendEventInput,
	getItemInventory,
	getAnswerObjectives,
	answerObjectives,
}) {
	const [isSending, setIsSending] = useState(false);
	const [showWrongAnswer, setShowWrongAnswer] = useState(false);
	const [showSuccess, setShowSuccess] = useState(hasBadge);
	//TODO: use the short hand for every components using acfPageOptions
	const {questions, mission_id} = acfPageOptions?.acf?.profile?.profile_tab || {};
	const [answers, setAnswers] = useState([]);

	useEffect(() => {
		setShowSuccess(hasBadge);
	}, [hasBadge]);

	useEffect(() =>{
		if(!questions){
			return null;
		}
		if(questions && questions.length === 0){
			return null;
		}
		//Initializing answers with the acfPageOptions questions and an empty value
		return setAnswers(questions.map(question => ({...question, value: ''})) )
	},[questions])

	useEffect(()=>{
		if(!mission_id){
			return null;
		}

		return getAnswerObjectives({mission_id});

	}, [mission_id])

	function onSend() {
		setIsSending(true);
		setShowWrongAnswer(false);
		setShowSuccess(hasBadge || false);

		if(!answers || answers.length === 0){
			return null;
		}

		if(!answerObjectives || answerObjectives && answerObjectives.length === 0){
			return null;
		}

		const eventInputPromises = answers
			.map((singleAnswer, index) => {
				if(!Object.prototype.hasOwnProperty.call(answerObjectives[index], 'id')){
					return null;
				}
				const objective_id = answerObjectives[index]?.id;
				const answer = singleAnswer?.value;
				const eventInputPromise = (
					sendEventInput({
						mission_id,
						objective_id,
						answer,
						instance_id: 'profile-ban-zero',
					})
				)
				return eventInputPromise;
			})

		Promise.all(eventInputPromises)
			.then((responses) => {
				setIsSending(false);
				const AllSuccessfullAnswersGiven = responses.every(resp => resp?.payload?.success)
				if (AllSuccessfullAnswersGiven) {
					setShowSuccess(true);
					setShowWrongAnswer(false);
					getItemInventory();
				}
				else {
					const objectivesAlreadyCompleted = responses.every(response => response?.payload?.data?.error === "Objective already completed")
					if(!objectivesAlreadyCompleted){
						setShowWrongAnswer(true);
					}
				}
			})
		return eventInputPromises;
	}

	const updateAnswers = (index, e) => {
		const answersToUpdate = [...answers];
		if(!answersToUpdate || answersToUpdate && answersToUpdate.length === 0){
			return null;
		}
		if(!Object.prototype.hasOwnProperty.call(answersToUpdate[index],'value')) {
			return null;
		}
		answersToUpdate[index].value = e;
		return setAnswers(answersToUpdate);
	}

	return (
		<div className={`answers ${isSending ? 'sending' : ''}`}>

			{!showSuccess && (
				<>
					 {
						 	answers && answers.length > 0 && answers.map((answer, index) => {
							return <Field key={answer?.question} icon={acfPageOptions?.acf?.theme?.icon?.profile?.edit_profile?.url} name={answer?.question} value={answer?.value} onBlur={(e)=>updateAnswers(index, e)} />
						 })
					 }
				</>
			)}

			{showWrongAnswer && <div className="wrong-answer">{acfPageOptions?.acf?.profile?.profile_tab?.wrong_answer_prompt}</div>}
			{showSuccess && <div className="good-answer">{acfPageOptions?.acf?.profile?.profile_tab?.good_answer_prompt}</div>}
			{!showSuccess && <Button action={() => onSend()} classes="secondary large fit" text={acfPageOptions?.acf?.profile?.profile_tab?.send_button} />}

		</div>
	);
}

Answers.defaultProps = {
	acfPageOptions: null,
	sendEventInput: () => {},
	getItemInventory: () => {},
	getAnswerObjectives: () => {},
	hasBadge: false,
	answerObjectives: [],
};

Answers.propTypes = {
	acfPageOptions: PropTypes.object,
	sendEventInput: PropTypes.func,
	getItemInventory: PropTypes.func,
	getAnswerObjectives: PropTypes.func,
	hasBadge: PropTypes.bool,
	answerObjectives: PropTypes.array,
};

export default Answers;
