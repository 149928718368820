
export function setStyleTheme(acfPageOptions){
	const root = document.documentElement;

	function parseUrl(url) {
		return `url(${url})`
	}

	//// SET Colors
	//Colors (theme.color)
	root?.style.setProperty('--primary-color', acfPageOptions?.acf?.theme?.color?.primary ? acfPageOptions?.acf?.theme?.color?.primary : '');
	root?.style.setProperty('--secondary-color', acfPageOptions?.acf?.theme?.color?.secondary ? acfPageOptions?.acf?.theme?.color?.secondary : '');

	//Loop Background Colors (theme.color.background)
	Object.keys(acfPageOptions?.acf?.theme?.color?.background).forEach((key) => {
		const valueName = acfPageOptions?.acf?.theme?.color?.background[key];

		root?.style.setProperty('--'+ key +'-background-color', valueName || '');
	});

	root?.style.setProperty('--primary-gradient', acfPageOptions?.acf?.theme?.color?.gradient?.primary || '');
	root?.style.setProperty('--secondary-gradient', acfPageOptions?.acf?.theme?.color?.gradient?.secondary || '');
	root?.style.setProperty('--tertiary-gradient', acfPageOptions?.acf?.theme?.color?.gradient?.tertiary || '');

	//Loop Place Colors (theme.color.place)
	Object.keys(acfPageOptions?.acf?.theme?.color?.place).forEach((key) => {
		const valueName = acfPageOptions?.acf?.theme?.color?.place[key];

		root?.style.setProperty('--'+ key +'-place', valueName || '');
	});

	//Loop Place Colors (theme.color.text)
	Object.keys(acfPageOptions?.acf?.theme?.color?.text).forEach((key) => {
		const valueName = acfPageOptions?.acf?.theme?.color?.text[key];

		root?.style.setProperty('--'+ key +'-text-color', valueName || '');
	});

	//// SET Textes
	//Body
	Object.keys(acfPageOptions?.acf?.theme?.text?.body).forEach((key) => {
		const valueName = acfPageOptions?.acf?.theme?.text?.body[key];

		root?.style.setProperty('--body-'+ key +'-size', valueName.size || '');
		root?.style.setProperty('--body-'+ key +'-line-height', valueName.line_height || '');
	});
	
	//Heading
	Object.keys(acfPageOptions?.acf?.theme?.text?.heading).forEach((key) => {
		const valueName = acfPageOptions?.acf?.theme?.text?.heading[key];

		root?.style.setProperty('--heading-'+ key +'-size', valueName.size || '');
		root?.style.setProperty('--heading-'+ key +'-line-height', valueName.line_height || '');
	});

	// //// SET FONTS
	root?.style.setProperty('--title-font', acfPageOptions?.acf?.theme?.text?.heading?.font ? acfPageOptions?.acf?.theme?.text?.heading?.font : '');
	root?.style.setProperty('--content-font', acfPageOptions?.acf?.theme?.text?.body?.font ? acfPageOptions?.acf?.theme?.text?.body?.font : '');

	//// SET Logo
	root?.style.setProperty('--logo-main', acfPageOptions?.acf?.theme?.logo?.main?.url ? acfPageOptions?.acf?.theme?.logo?.main?.url : '');
	root?.style.setProperty('--logo-secondary', acfPageOptions?.acf?.theme?.logo?.secondary?.url ? acfPageOptions?.acf?.theme?.logo?.secondary?.url : '');
	root?.style.setProperty('--logo-loading', acfPageOptions?.acf?.theme?.logo?.loading?.url ? acfPageOptions?.acf?.theme?.logo?.loading?.url : '');

	//// SET Backgrounds
	root?.style.setProperty('--background-live-primary', acfPageOptions?.acf?.theme?.background?.live?.primary?.url ? parseUrl(acfPageOptions?.acf?.theme?.background?.live?.primary?.url) : '');
	root?.style.setProperty('--background-live-bottom', acfPageOptions?.acf?.theme?.background?.live?.bottom?.url ? parseUrl(acfPageOptions?.acf?.theme?.background?.live?.bottom?.url) : '');
	root?.style.setProperty('--background-primary', acfPageOptions?.acf?.theme?.background?.primary?.url ? parseUrl(acfPageOptions?.acf?.theme?.background?.primary?.url) : '');
	root?.style.setProperty('--background-private_meeting', acfPageOptions?.acf?.theme?.background?.private_meeting?.url ? parseUrl(acfPageOptions?.acf?.theme?.background?.private_meeting?.url) : '');
	root?.style.setProperty('--background-secondary', acfPageOptions?.acf?.theme?.background?.secondary?.url ? parseUrl(acfPageOptions?.acf?.theme?.background?.secondary?.url) : '');
	root?.style.setProperty('--background-small-screen', acfPageOptions?.acf?.theme?.background?.small_screen?.url ? parseUrl(acfPageOptions?.acf?.theme?.background?.small_screen?.url) : '');
	root?.style.setProperty('--background-progress-screen', acfPageOptions.acf?.progress_screens?.background?.url ? parseUrl(acfPageOptions.acf?.progress_screens?.background?.url) : '');

	// SET Icons
	root?.style.setProperty('--icon-general-expand', acfPageOptions?.acf?.theme?.icon?.general?.expand?.url ? parseUrl(acfPageOptions?.acf?.theme?.icon?.general?.expand?.url) : '');
	root?.style.setProperty('--icon-general-minimize', acfPageOptions?.acf?.theme?.icon?.general?.minimize?.url ? parseUrl(acfPageOptions?.acf?.theme?.icon?.general?.minimize?.url) : '');

	root?.style.setProperty('--icon-tech-support', acfPageOptions?.acf?.theme?.icon?.navigation?.tech_support?.image1?.url ? parseUrl(acfPageOptions?.acf?.theme?.icon?.navigation?.tech_support?.image1?.url) : '');
	root?.style.setProperty('--icon-tech-support-hover', acfPageOptions?.acf?.theme?.icon?.navigation?.tech_support?.image2?.url ? parseUrl(acfPageOptions?.acf?.theme?.icon?.navigation?.tech_support?.image2?.url) : '');

	//// SET Buttons
	//Large Icon
	root?.style.setProperty('--button-large-icon-background_color', acfPageOptions?.acf?.theme?.button?.large?.icon?.background_color ? acfPageOptions?.acf?.theme?.button?.large?.icon?.background_color : '');
	root?.style.setProperty('--button-large-icon-border_color', acfPageOptions?.acf?.theme?.button?.large?.icon?.border_color ? acfPageOptions?.acf?.theme?.button?.large?.icon?.border_color : '');
	root?.style.setProperty('--button-large-icon-border_size', acfPageOptions?.acf?.theme?.button?.large?.icon?.border_size ? acfPageOptions?.acf?.theme?.button?.large?.icon?.border_size : '');
	root?.style.setProperty('--button-large-icon-hover-background_color', acfPageOptions?.acf?.theme?.button?.large?.icon?.hover?.background_color ? acfPageOptions?.acf?.theme?.button?.large?.icon?.hover?.background_color : '');
	root?.style.setProperty('--button-large-icon-hover-border_color', acfPageOptions?.acf?.theme?.button?.large?.icon?.hover?.border_color ? acfPageOptions?.acf?.theme?.button?.large?.icon?.hover?.border_color : '');
	root?.style.setProperty('--button-large-icon-hover-text_color', acfPageOptions?.acf?.theme?.button?.large?.icon?.hover?.text_color ? acfPageOptions?.acf?.theme?.button?.large?.icon?.hover?.text_color : '');
	root?.style.setProperty('--button-large-icon-text_color', acfPageOptions?.acf?.theme?.button?.large?.icon?.text_color ? acfPageOptions?.acf?.theme?.button?.large?.icon?.text_color : '');

	//Large Primary
	root?.style.setProperty('--button-large-primary-background_color', acfPageOptions?.acf?.theme?.button?.large?.primary?.background_color ? acfPageOptions?.acf?.theme?.button?.large?.primary?.background_color : '');
	root?.style.setProperty('--button-large-primary-border_color', acfPageOptions?.acf?.theme?.button?.large?.primary?.border_color ? acfPageOptions?.acf?.theme?.button?.large?.primary?.border_color : '');
	root?.style.setProperty('--button-large-primary-border_size', acfPageOptions?.acf?.theme?.button?.large?.primary?.border_size ? acfPageOptions?.acf?.theme?.button?.large?.primary?.border_size : '');
	root?.style.setProperty('--button-large-primary-hover-background_color', acfPageOptions?.acf?.theme?.button?.large?.primary?.hover?.background_color ? acfPageOptions?.acf?.theme?.button?.large?.primary?.hover?.background_color : '');
	root?.style.setProperty('--button-large-primary-hover-border_color', acfPageOptions?.acf?.theme?.button?.large?.primary?.hover?.border_color ? acfPageOptions?.acf?.theme?.button?.large?.primary?.hover?.border_color : '');
	root?.style.setProperty('--button-large-primary-hover-text_color', acfPageOptions?.acf?.theme?.button?.large?.primary?.hover?.text_color ? acfPageOptions?.acf?.theme?.button?.large?.primary?.hover?.text_color : '');
	root?.style.setProperty('--button-large-primary-text_color', acfPageOptions?.acf?.theme?.button?.large?.primary?.text_color ? acfPageOptions?.acf?.theme?.button?.large?.primary?.text_color : '');

	//Large Secondary
	root?.style.setProperty('--button-large-secondary-background_color', acfPageOptions?.acf?.theme?.button?.large?.secondary?.background_color ? acfPageOptions?.acf?.theme?.button?.large?.secondary?.background_color : '');
	root?.style.setProperty('--button-large-secondary-border_color', acfPageOptions?.acf?.theme?.button?.large?.secondary?.border_color ? acfPageOptions?.acf?.theme?.button?.large?.secondary?.border_color : '');
	root?.style.setProperty('--button-large-secondary-border_size', acfPageOptions?.acf?.theme?.button?.large?.secondary?.border_size ? acfPageOptions?.acf?.theme?.button?.large?.secondary?.border_size : '');
	root?.style.setProperty('--button-large-secondary-hover-background_color', acfPageOptions?.acf?.theme?.button?.large?.secondary?.hover?.background_color ? acfPageOptions?.acf?.theme?.button?.large?.secondary?.hover?.background_color : '');
	root?.style.setProperty('--button-large-secondary-hover-border_color', acfPageOptions?.acf?.theme?.button?.large?.secondary?.hover?.border_color ? acfPageOptions?.acf?.theme?.button?.large?.secondary?.hover?.border_color : '');
	root?.style.setProperty('--button-large-secondary-hover-text_color', acfPageOptions?.acf?.theme?.button?.large?.secondary?.hover?.text_color ? acfPageOptions?.acf?.theme?.button?.large?.secondary?.hover?.text_color : '');
	root?.style.setProperty('--button-large-secondary-text_color', acfPageOptions?.acf?.theme?.button?.large?.secondary?.text_color ? acfPageOptions?.acf?.theme?.button?.large?.secondary?.text_color : '');

	//Large Tertiary
	root?.style.setProperty('--button-large-tertiary-background_color', acfPageOptions?.acf?.theme?.button?.large?.tertiary?.background_color ? acfPageOptions?.acf?.theme?.button?.large?.tertiary?.background_color : '');
	root?.style.setProperty('--button-large-tertiary-border_color', acfPageOptions?.acf?.theme?.button?.large?.tertiary?.border_color ? acfPageOptions?.acf?.theme?.button?.large?.tertiary?.border_color : '');
	root?.style.setProperty('--button-large-tertiary-border_size', acfPageOptions?.acf?.theme?.button?.large?.tertiary?.border_size ? acfPageOptions?.acf?.theme?.button?.large?.tertiary?.border_size : '');
	root?.style.setProperty('--button-large-tertiary-hover-background_color', acfPageOptions?.acf?.theme?.button?.large?.tertiary?.hover?.background_color ? acfPageOptions?.acf?.theme?.button?.large?.tertiary?.hover?.background_color : '');
	root?.style.setProperty('--button-large-tertiary-hover-border_color', acfPageOptions?.acf?.theme?.button?.large?.tertiary?.hover?.border_color ? acfPageOptions?.acf?.theme?.button?.large?.tertiary?.hover?.border_color : '');
	root?.style.setProperty('--button-large-tertiary-hover-text_color', acfPageOptions?.acf?.theme?.button?.large?.tertiary?.hover?.text_color ? acfPageOptions?.acf?.theme?.button?.large?.tertiary?.hover?.text_color : '');
	root?.style.setProperty('--button-large-tertiary-text_color', acfPageOptions?.acf?.theme?.button?.large?.tertiary?.text_color ? acfPageOptions?.acf?.theme?.button?.large?.tertiary?.text_color : '');

	//Medium Primary
	root?.style.setProperty('--button-medium-primary-background_color', acfPageOptions?.acf?.theme?.button?.medium?.primary?.background_color ? acfPageOptions?.acf?.theme?.button?.medium?.primary?.background_color : '');
	root?.style.setProperty('--button-medium-primary-border_color', acfPageOptions?.acf?.theme?.button?.medium?.primary?.border_color ? acfPageOptions?.acf?.theme?.button?.medium?.primary?.border_color : '');
	root?.style.setProperty('--button-medium-primary-border_size', acfPageOptions?.acf?.theme?.button?.medium?.primary?.border_size ? acfPageOptions?.acf?.theme?.button?.medium?.primary?.border_size : '');
	root?.style.setProperty('--button-medium-primary-hover-background_color', acfPageOptions?.acf?.theme?.button?.medium?.primary?.hover?.background_color ? acfPageOptions?.acf?.theme?.button?.medium?.primary?.hover?.background_color : '');
	root?.style.setProperty('--button-medium-primary-hover-border_color', acfPageOptions?.acf?.theme?.button?.medium?.primary?.hover?.border_color ? acfPageOptions?.acf?.theme?.button?.medium?.primary?.hover?.border_color : '');
	root?.style.setProperty('--button-medium-primary-hover-text_color', acfPageOptions?.acf?.theme?.button?.medium?.primary?.hover?.text_color ? acfPageOptions?.acf?.theme?.button?.medium?.primary?.hover?.text_color : '');
	root?.style.setProperty('--button-medium-primary-text_color', acfPageOptions?.acf?.theme?.button?.medium?.primary?.text_color ? acfPageOptions?.acf?.theme?.button?.medium?.primary?.text_color : '');

	//Medium Secondary
	root?.style.setProperty('--button-medium-secondary-background_color', acfPageOptions?.acf?.theme?.button?.medium?.secondary?.background_color ? acfPageOptions?.acf?.theme?.button?.medium?.secondary?.background_color : '');
	root?.style.setProperty('--button-medium-secondary-border_color', acfPageOptions?.acf?.theme?.button?.medium?.secondary?.border_color ? acfPageOptions?.acf?.theme?.button?.medium?.secondary?.border_color : '');
	root?.style.setProperty('--button-medium-secondary-border_size', acfPageOptions?.acf?.theme?.button?.medium?.secondary?.border_size ? acfPageOptions?.acf?.theme?.button?.medium?.secondary?.border_size : '');
	root?.style.setProperty('--button-medium-secondary-hover-background_color', acfPageOptions?.acf?.theme?.button?.medium?.secondary?.hover?.background_color ? acfPageOptions?.acf?.theme?.button?.medium?.secondary?.hover?.background_color : '');
	root?.style.setProperty('--button-medium-secondary-hover-border_color', acfPageOptions?.acf?.theme?.button?.medium?.secondary?.hover?.border_color ? acfPageOptions?.acf?.theme?.button?.medium?.secondary?.hover?.border_color : '');
	root?.style.setProperty('--button-medium-secondary-hover-text_color', acfPageOptions?.acf?.theme?.button?.medium?.secondary?.hover?.text_color ? acfPageOptions?.acf?.theme?.button?.medium?.secondary?.hover?.text_color : '');
	root?.style.setProperty('--button-medium-secondary-text_color', acfPageOptions?.acf?.theme?.button?.medium?.secondary?.text_color ? acfPageOptions?.acf?.theme?.button?.medium?.secondary?.text_color : '');

	//Small
	root?.style.setProperty('--button-small-primary-background_color', acfPageOptions?.acf?.theme?.button?.small?.background_color ? acfPageOptions?.acf?.theme?.button?.small?.background_color : '');
	root?.style.setProperty('--button-small-primary-border_color', acfPageOptions?.acf?.theme?.button?.small?.border_color ? acfPageOptions?.acf?.theme?.button?.small?.border_color : '');
	root?.style.setProperty('--button-small-primary-border_size', acfPageOptions?.acf?.theme?.button?.small?.border_size ? acfPageOptions?.acf?.theme?.button?.small?.border_size : '');
	root?.style.setProperty('--button-small-primary-hover-background_color', acfPageOptions?.acf?.theme?.button?.small?.hover?.background_color ? acfPageOptions?.acf?.theme?.button?.small?.hover?.background_color : '');
	root?.style.setProperty('--button-small-primary-hover-border_color', acfPageOptions?.acf?.theme?.button?.small?.hover?.border_color ? acfPageOptions?.acf?.theme?.button?.small?.hover?.border_color : '');
	root?.style.setProperty('--button-small-primary-hover-text_color', acfPageOptions?.acf?.theme?.button?.small?.hover?.text_color ? acfPageOptions?.acf?.theme?.button?.small?.hover?.text_color : '');
	root?.style.setProperty('--button-small-primary-text_color', acfPageOptions?.acf?.theme?.button?.small?.text_color ? acfPageOptions?.acf?.theme?.button?.small?.text_color : '');

	//Toggle Enabled
	root?.style.setProperty('--button-toggle-enabled-background_color', acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.background_color ? acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.background_color : '');
	root?.style.setProperty('--button-toggle-enabled-border_color', acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.border_color ? acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.border_color : '');
	root?.style.setProperty('--button-toggle-enabled-border_size', acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.border_size ? acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.border_size : '');
	root?.style.setProperty('--button-toggle-enabled-hover-background_color', acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.hover?.background_color ? acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.hover?.background_color : '');
	root?.style.setProperty('--button-toggle-enabled-hover-border_color', acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.hover?.border_color ? acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.hover?.border_color : '');
	root?.style.setProperty('--button-toggle-enabled-hover-text_color', acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.hover?.text_color ? acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.hover?.text_color : '');
	root?.style.setProperty('--button-toggle-enabled-text_color', acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.text_color ? acfPageOptions?.acf?.theme?.button?.toggle?.enabled?.text_color : '');

	//Toggle Disabled
	root?.style.setProperty('--button-toggle-disabled-background_color', acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.background_color ? acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.background_color : '');
	root?.style.setProperty('--button-toggle-disabled-border_color', acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.border_color ? acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.border_color : '');
	root?.style.setProperty('--button-toggle-disabled-border_size', acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.border_size ? acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.border_size : '');
	root?.style.setProperty('--button-toggle-disabled-hover-background_color', acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.hover?.background_color ? acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.hover?.background_color : '');
	root?.style.setProperty('--button-toggle-disabled-hover-border_color', acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.hover?.border_color ? acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.hover?.border_color : '');
	root?.style.setProperty('--button-toggle-disabled-hover-text_color', acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.hover?.text_color ? acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.hover?.text_color : '');
	root?.style.setProperty('--button-toggle-disabled-text_color', acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.text_color ? acfPageOptions?.acf?.theme?.button?.toggle?.disabled?.text_color : '');
}
