import React from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";

function BackButton({ link, localBack, acfPageOptions }) {
	const history = useHistory();
	
	if (link)
		return (
			<div className="return-ctn">
				<Link to={link}>
					<span>
						{localBack || acfPageOptions?.acf?.global?.back}
					</span>
				</Link>
			</div>
		);
	return (
		<div className="return-ctn">
			<button type="button" onClick={history.goBack}>
				<span>
					{localBack || acfPageOptions?.acf?.global?.back}
				</span>
			</button>
		</div>
	);
}

BackButton.defaultProps = {
	localBack: null,
	acfPageOptions: null,
};

BackButton.propTypes = {
	link: PropTypes.string.isRequired,
	localBack: PropTypes.string,
	acfPageOptions: PropTypes.object,
};

export default BackButton;
