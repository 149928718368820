import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingInnerContent from 'components/LoadingInnerContent';

function mapStateToProps(state) {
	return {
		acfPageOptions: state?.wpcontent?.acfPageOptions,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
	}, dispatch)
)(LoadingInnerContent);
