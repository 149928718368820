import { memo, useEffect, useState } from 'react';
import logLocalStorage from 'utils/logLocalStorage';
import DEBUG_DATA from 'utils/VideoPlayerDebug';
import PropTypes from "prop-types";

function VideoEventReporter({
	event,
	player,
	prop,
	method,
	rule,
	hasInitial,
}) {
	const [isInitial, setIsInitial] = useState(hasInitial);
	const [lastValue, setLastValue] = useState(null);

	useEffect(() => {
		function onEvent() {
			if (event === 'error') {
				log(player.error());
			} else {
				if (method) log(player[method]());
				if (prop) log(player[prop]);
			}

			if (isInitial) setIsInitial(false);
		}

		function log(value) {
			if (value !== lastValue) {
				logLocalStorage(event, value);

				if ((rule && rule(value))) {
					// Do API stuff
				}

				setLastValue(value);
				DEBUG_DATA[event] = value;
			}
		}

		if (player) {
			player.removeEventListener(event, onEvent);
			player.addEventListener(event, onEvent);
		}

		return () => {
			// Dont need to remove, dispose cleans it up
		};
	});

	return null;
}

export default memo(VideoEventReporter);

VideoEventReporter.defaultProps = {
	event: null, //TODO: is it required?  (quick add to avoid breaking the compile... DNDAID)
	player: null, //TODO: is it required?  (quick add to avoid breaking the compile... DNDAID)
	prop: null, //TODO: is it required?  (quick add to avoid breaking the compile... DNDAID)
	method: null, //TODO: is it required?  (quick add to avoid breaking the compile... DNDAID)
	rule: null, //TODO: is it required?  (quick add to avoid breaking the compile... DNDAID)
	hasInitial: true,
};

VideoEventReporter.propTypes = {
	event: PropTypes.string,
	player: PropTypes.any, //TODO: don't use any (quick add to avoid breaking the compile... DNDAID)
	prop: PropTypes.any, //TODO: don't use any (quick add to avoid breaking the compile... DNDAID)
	method: PropTypes.any, //TODO: don't use any (quick add to avoid breaking the compile... DNDAID)
	rule: PropTypes.any, //TODO: don't use any (quick add to avoid breaking the compile... DNDAID)
	hasInitial: PropTypes.bool,
};