import React from 'react';
import PropTypes from "prop-types";

function StreamStatus({message}) {
	return (
		<div className="stream-status">
			<p>{message}</p>
		</div>
	);
}

StreamStatus.defaultProps = {
	message: null,
}

StreamStatus.propTypes = {
	message: PropTypes.string,
}

export default StreamStatus;
