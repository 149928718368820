/*
 * @format
 */
import React, { useEffect, useState, setState } from "react";
import PropTypes from "prop-types";
import { Modal as BootstrapModal } from "react-bootstrap";

function Modal({
	acfPageOptions,
	overwriteHeading,
	modal,
	setModal,
	heading,
	children,
	footing,
	timerLength,
	points,
	closeButton,
	clickOffClose,
	enabledEscKey,
	className,
}) {
	const [seconds, setSeconds] = useState(
		timerLength > 60
			? timerLength - Math.floor(timerLength / 60) * 60
			: timerLength
	);
	const [minutes, setMinutes] = useState(
		timerLength > 60 ? Math.floor(timerLength / 60) : 0
	);
	const [timerBarPercent, setTimerBarPercent] = useState(100);

	const handleClose = () => setModal(false);

	function displayTimerText() {
		if (timerBarPercent >= 5)
			return minutes > 0
				? `${minutes}:${("0" + seconds).slice(-2)}`
				: seconds;

		return null;
	}

	useEffect(() => {
		let interval = null;
		if ((timerLength && seconds >= 0) || minutes >= 1) {
			if (seconds < 1) {
				if (!minutes) {
					handleClose();
				} else {
					setSeconds(59);
					setMinutes(minutes - 1);
				}
			}
			interval = setInterval(() => {
				setSeconds(seconds - 1);
				setTimerBarPercent(
					((minutes * 60 + seconds - 1) / timerLength) * 100
				);
			}, 1000);
		} else if (!timerLength && seconds !== 0) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [timerLength, seconds]);

	return (
		<BootstrapModal
			show={modal}
			onHide={handleClose}
			size="lg"
			aria-labelledby="contained-BootstrapModal-title-vcenter"
			centered
			keyboard={enabledEscKey} //disables esc to close
			className={className}
		>
			{overwriteHeading && (
				<BootstrapModal.Header closeButton={closeButton}>
					<BootstrapModal.Title
						id="contained-BootstrapModal-title-vcenter"
					>
						{overwriteHeading}
					</BootstrapModal.Title>
				</BootstrapModal.Header>
			)}

			{!overwriteHeading && (
				<BootstrapModal.Header
					closeButton={closeButton}
					className={timerLength && "with-timer p-0"}
				>
					{points && <div className="points">{points} PTS</div>}
					<BootstrapModal.Title
						id="contained-BootstrapModal-title-vcenter"
						className={
							timerLength && "w-100 d-flex justify-content-end"
						}
					>
						{heading}
						{timerLength && (
							<div
								className={`timer-bar text-start `}
								style={{
									width: `${timerBarPercent}%`,
								}}
							>
								<span className="px-1 timer-percent">
									{displayTimerText()}
								</span>
							</div>
						)}
					</BootstrapModal.Title>
				</BootstrapModal.Header>
			)}

			<BootstrapModal.Body>{children}</BootstrapModal.Body>
			{footing && (
				<BootstrapModal.Footer>{footing}</BootstrapModal.Footer>
			)}
		</BootstrapModal>
	);
}

Modal.defaultProps = {
	acfPageOptions: null,
	overwriteHeading: null,
	modal: null,
	setModal: () => {},
	heading: null,
	footing: null,
	timerLength: null,
	points: null,
	closeButton: true,
	clickOffClose: false,
	enabledEscKey: false,
	className: null,
};

Modal.propTypes = {
	modal: PropTypes.bool,
	setModal: PropTypes.func,
	acfPageOptions: PropTypes.object,
	overwriteHeading: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	heading: PropTypes.string,
	children: PropTypes.node.isRequired,
	footing: PropTypes.object,
	timerLength: PropTypes.number,
	points: PropTypes.number,
	closeButton: PropTypes.bool,
	clickOffClose: PropTypes.bool,
	enabledEscKey: PropTypes.bool,
	className: PropTypes.string,
};

export default Modal;