import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	broadcastMessage,
} from 'redux/chat';
import ChatMessage from 'components/ComPanel/Chat/ChatMessage';
import { statSelector } from 'selector/stats';
import { USER_LEVELS, CATALOG, PARTNERSTAT } from 'Constants';
import { itemInventorySelector } from 'selector/inventory';
import { getOtherPlayerData } from 'redux/playfab';
import { setActiveProfileCard } from 'redux/profile_card';
import { messageSelector, otherPlayerDataSelector } from 'selector/chat';


function mapStateToProps(state, props) {
	const message = messageSelector(state, props);
	return {
		...message,
		isModerator: statSelector(state, { statName: 'userlevel' }) === USER_LEVELS.MODERATOR,
		canChat: !itemInventorySelector(state, { itemId: CATALOG.disable_chat }),
		otherPlayerData: message && otherPlayerDataSelector(state, { PlayFabId: message.pfid }),
		isPartner: statSelector(state, { statName: 'partner' }) === PARTNERSTAT.PARTNER,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		broadcastMessage,
		getOtherPlayerData,
		setActiveProfileCard,
	}, dispatch)
)(ChatMessage);
