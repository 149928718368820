import { createSlice } from '@reduxjs/toolkit';

const video_bubble = createSlice({
	name: 'video_bubble',
	reducers: {
		/**
		 *Set the tile Id associated with the video bubble
		 */
		setTileId: (state, action) => {
			return {
				...state,
				tileId: action.payload,
			};
		},
		/**
		 *Set the translation of the video bubble
		 */
		setTranslation: (state, action) => {
			return {
				...state,
				selectedTranslation: action.payload,
			};
		},
		/**
		 * Init the video player
		 */
		emptyVideoPlayer: (state) => {
			return {
				...state,
				tileId: null,
				selectedTranslation: null,
			};
		},
		/**
		 * Set currrent volume to video bubble
		 */
		setBubbleVolume: (state, action) => {
			return {
				...state,
				volume: action.payload,
			};
		},

		/**
		 *
		 */
		setStartTime: (state, action) => {
			return {
				...state,
				startTime: action.payload,
			};
		},

		/**
		 * Set is the video is muted
		 */
		setMuted: (state, action) => {
			return {
				...state,
				muted: action.payload,
			};
		},
		/**
		 *Set the translation of the video bubble
		 */
		setBubbleSrc: (state, action) => {
			return {
				...state,
				src: action.payload,
			};
		},
	},
	extraReducers: {},
	initialState: {
		selectedTranslation: null,
		tileId: null,
		volume: 1,
		startTime: null,
		muted: false,
		src: null,
	},
});

export default video_bubble;

export const { setTranslation, emptyVideoPlayer, setTileId, setBubbleVolume, setStartTime, setMuted, setBubbleSrc } =
	video_bubble.actions;
