import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Leaderboard from 'components/Events/Leaderboard';
import { leaderboardSelector } from 'selector/leaderboard';
import { hideLeaderboard } from 'redux/leaderboard';

function mapStateToProps(state) {
	return {
		acfPageOptions: state?.wpcontent?.acfPageOptions,
		leaderboard: leaderboardSelector(state),
		playfabId: state.playfab.PlayFabId,
	};
}

export default connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		hideLeaderboard,
	}, dispatch)
)(Leaderboard);
