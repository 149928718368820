import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as NoticesIcon } from "assets/icons/pack1/menus/main-menu/icon-privacy-policy.svg";
import { ReactComponent as FAQIcon } from "assets/icons/pack1/menus/main-menu/icon-faq.svg";
import { ReactComponent as SponsorsIcon } from "assets/icons/pack1/menus/main-menu/icon-sponsor.svg";
import { ReactComponent as SupportIcon } from "assets/icons/pack1/menus/main-menu/icon-help-and-support.svg";
import { ReactComponent as SpeakersIcon } from "assets/icons/pack1/menus/main-menu/icon-speakers.svg";
import { ReactComponent as PartnersIcon } from "assets/icons/pack1/menus/main-menu/icon-partners.svg";
import { ReactComponent as LogOutIcon } from "assets/icons/pack1/menus/main-menu/icon-logout.svg";
import { ReactComponent as AgendaIcon } from "assets/icons/pack1/bottom-navigation-bar/icon-calendar.svg";
import { ReactComponent as GalleryIcon } from "assets/icons/pack1/bottom-navigation-bar/icon-gallery.svg";
import { ReactComponent as HomeIcon } from "assets/icons/pack1/bottom-navigation-bar/icon-home.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/pack1/bottom-navigation-bar/icon-user.svg";

function MenuIcons(props) {
	const { itemName } = props;
	return (
		<>
			{(() => {
				switch (itemName) {
					case "notices":
						return <NoticesIcon />;
					case "faq":
						return <FAQIcon />;
					case "sponsors":
						return <SponsorsIcon />;
					case "support":
						return <SupportIcon />;
					case "speakers":
						return <SpeakersIcon />;
					case "partners":
						return <PartnersIcon />;
					case "logout":
						return <LogOutIcon />;
					case "event":
						return <AgendaIcon />;
					case "home":
						return <HomeIcon />;
					case "gallery":
						return <GalleryIcon />;
					case "profile":
						return <ProfileIcon />;
					default:
						return null;
				}
			})()}
		</>
	);
}

export default MenuIcons;

MenuIcons.propTypes = {
	itemName: PropTypes.string,
};

MenuIcons.defaultProps = {
	itemName: "",
};
