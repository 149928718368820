import React from "react";
import PropTypes from "prop-types";

function ToggleSwitchLabel({ checked, onChange, acfPageOptions }) {
	return (
		<div className="toggle-item toggle-label">
			<label className="switch">
				<input checked={checked} type="checkbox" onChange={onChange} />
				<div className="slider round">
					<span className="on">{acfPageOptions?.acf?.profile?.profile_tab?.enabled_notification_label}</span>
					<span className="off">{acfPageOptions?.acf?.profile?.profile_tab?.disabled_notification_label}</span>
				</div>
			</label>
		</div>
	);
}

ToggleSwitchLabel.propTypes = {
	acfPageOptions: PropTypes.object,
	onChange: PropTypes.func,
	checked: PropTypes.bool,
};

ToggleSwitchLabel.defaultProps = {
	onChange: () => {},
	checked: null,
	acfPageOptions: null,
};

export default ToggleSwitchLabel;
