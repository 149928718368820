import React, { useRef, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import uuid from "react-uuid";
import { PLAYFAB_EVENTS } from "Constants";
import { toLocalTime, getTimeStyleShort } from "utils/dateUtils";
import { ComPanelContext } from "mobile/context/ComPanelContext";
import { SessionContext } from "mobile/context/SessionContext";
import Message from "mobile/components/containers/companel/Message";
import ToggleOption from "mobile/components/modals/ToggleOption";
import SendMessageInput from "../SendMessageInput";
import WelcomeMessage from "../WelcomeMessage";

function Qa({
	channel,
	displayName,
	newQuestions,
	questions,
	fetchQuestions,
	getQuestions,
	fetchQuestion,
	clearNewQuestions,
	postQuestion,
	writePlayerEvent,
	stateVideo,
	session,
	createToast,
	acfPageOptions,
}) {
	const questionEnd = useRef(null);
	const chatInput = useRef(null);
	const questionWrapper = useRef(null);
	const [showInput, setShowInput] = useState(false);
	const [inputMessage, setInputMessage] = useState("");
	const [showValidationModal, setShowValidationModal] = useState(false);
	const [askAnnoymously, setAskAnnoymously] = useState(null);
	const [askLive, setAskLive] = useState(null);
	const [modalConfirmed, setModalConfirmed] = useState(false);
	const { activeCompanelTab, setActiveCompanelTab } =
		useContext(ComPanelContext);
	const [isScrollable, setIsScrollable] = useState(false);
	const { minimizeToggle, setMinimizeToggle } = useContext(SessionContext);

	useEffect(() => {
		if (showInput) {
			chatInput.current.focus();
		}
	}, [showInput]);

	useEffect(() => {
		scrollToBottom();
	}, []);

	function onQuestionSubmit(e) {
		e.preventDefault();
		setShowValidationModal(true);
	}

	function sendQuestion() {
		const live = askLive ? 1 : 0;
		const anonymous = askAnnoymously ? 1 : 0;

		try {
			if (inputMessage !== "") {
				postQuestion({
					RoomId: channel,
					Message: inputMessage,
					Anonymous: anonymous,
					Live: live,
				});

				window.dataLayer.push({
					event: "generic_event",
					eventCategory: "qa",
					eventAction: "message sent",
					eventLabel: inputMessage,
				});

				writePlayerEvent({
					name: PLAYFAB_EVENTS.player_questionbox_ask,
					body: { RoomId: channel },
				});

				writePlayerEvent({
					name: PLAYFAB_EVENTS.player_meeting_event,
					body: {
						HasChatted: 1,
						RoomId: channel,
						Type: "question",
					},
				});
				setInputMessage("");
				setModalConfirmed(null);
				setAskLive(null);
				setShowValidationModal(null);
				setAskAnnoymously(null);
				setAskLive(null);

				createToast({
					content: acfPageOptions?.acf?.notification?.question_sent,
					type: "Toast",
					title: acfPageOptions?.acf?.notification?.question_title,
					id: uuid(),
				});
			}
		} catch (error) {
			console.error(error);
		}
		return null;
	}
	useEffect(() => {
		if (!showValidationModal) {
			setModalConfirmed(null);
			setAskLive(null);
			setAskAnnoymously(null);
			setAskLive(null);
		}
	}, [showValidationModal]);

	useEffect(() => {
		if (modalConfirmed) {
			sendQuestion();
		}
	}, [modalConfirmed]);

	function scrollToBottom() {
		setTimeout(() => {
			questionEnd.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		}, 100);
		clearNewQuestions();
	}

	function canScrollQuestions() {
		if (questionWrapper?.current) {
			const { scrollTop, scrollHeight, clientHeight } = questionWrapper.current;
			if (scrollHeight - clientHeight - scrollTop < 250) {
				scrollToBottom();
				setIsScrollable(true);
			} else {
				setIsScrollable(false);
			}
		}

		return null;
	}

	return (
		<div className="d-flex flex-column mobile-chat mt-5" ref={questionWrapper}>
			<div className="questions-list">
				{questions.length > 0 ? (
					questions.slice(-30).map((m) => {
						return (
							<Message
								channel={channel}
								id={m.id}
								key={m.id}
								questionData={m}
								displayName={displayName}
								type="question"
								canScrollQuestions={() => canScrollQuestions()}
							/>
						);
					})
				) : (
					<WelcomeMessage message={acfPageOptions?.acf?.live_chat_panel?.be_first_to_ask_question} />
				)}
				{stateVideo === "ended" && (
					<div className="mx-4 my-3">
						<span className="chat-closed pb-2">
							{getTimeStyleShort(toLocalTime(session.date_end).$d, "en-US")} -
							{acfPageOptions?.acf?.live_chat_panel?.qa_closed}
						</span>
					</div>
				)}
				<div style={{ paddingTop: 60 }} ref={questionEnd} />
			</div>
			{!isScrollable && newQuestions > 0 && questions.length > 4 && (
				<button
					className="new-messages-btn p-2"
					type="button"
					onClick={() => scrollToBottom()}
				>
					{`${newQuestions} NEW ${newQuestions > 1 ? "QUESTIONS" : "QUESTION"}`}
				</button>
			)}
			{!minimizeToggle && stateVideo !== "ended" && (
				<SendMessageInput
					inputMessage={inputMessage}
					setInputMessage={setInputMessage}
					placeHolder={acfPageOptions?.acf?.live_chat_panel?.ask_question}
					formAction={(e) => onQuestionSubmit(e)}
					disabled={stateVideo === "ended"}
				/>
			)}
			{showValidationModal && (
				<ToggleOption
					modal={showValidationModal}
					setModal={setShowValidationModal}
					title={acfPageOptions?.acf?.live_chat_panel?.want_question?.title}
					checkboxValue={askLive}
					setCheckboxValue={setAskLive}
					toggleValue={askAnnoymously}
					setToggleValue={setAskAnnoymously}
					warning={acfPageOptions?.acf?.live_chat_panel?.want_question?.message}
					modalAction={() => setModalConfirmed(true)}
					toggleLabelOne={acfPageOptions?.acf?.live_chat_panel?.want_question?.with_name}
					toggleLabelTwo={acfPageOptions?.acf?.live_chat_panel?.want_question?.anonymously}
					checkboxLabel={acfPageOptions?.acf?.live_chat_panel?.want_question?.request_live}
					btnLabel={acfPageOptions?.acf?.live_chat_panel?.want_question?.button}
				/>
			)}
		</div>
	);
}

Qa.defaultProps = {
	questions: [],
	fetchQuestions: false,
	channel: null,
	displayName: null,
	getQuestions: () => {},
	fetchQuestion: () => {},
	newQuestions: false,
	clearNewQuestions: () => {},
	postQuestion: () => {},
	writePlayerEvent: () => {},
	stateVideo: null,
	session: null,
	createToast: () => {},
	acfPageOptions: null,
};

Qa.propTypes = {
	questions: PropTypes.array,
	fetchQuestions: PropTypes.bool,
	channel: PropTypes.string,
	getQuestions: PropTypes.func,
	fetchQuestion: PropTypes.func,
	newQuestions: PropTypes.number,
	displayName: PropTypes.string,
	clearNewQuestions: PropTypes.func,
	postQuestion: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	stateVideo: PropTypes.string,
	session: PropTypes.object,
	createToast: PropTypes.func,
	acfPageOptions: PropTypes.object,
};

export default Qa;