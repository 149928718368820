export default function logPageView(page, stats, playfabID, player, position, showroom) {
	window.dataLayer.push({
		'event': 'pageview',
		'virtualPagePath': document.location.pathname,
		'virtualPageTitle': page,
		"language": stats?.region,
		"pageType": page,
		"userId": playfabID,
		"userDepartement": player?.Department?.Value,
		"userGeographicLocation": stats?.region,
		"userLoginStatus": 'logged-in',
		"userPoints": stats?.xp,
		"userRanking": position + 1,
		"showRoomName": showroom?.title?.rendered,
		"showRoomId": showroom?.id,
	});
}
