import React, { useRef, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import uuid from "react-uuid";
import { PLAYFAB_EVENTS } from "Constants";
import { toLocalTime } from "utils/dateUtils";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ReactComponent as TimerIcon } from "assets/icons/pack1/chat/chat-box/icon-time.svg";
import { TechSupportContext } from "mobile/context/TechSupportContext";
import { ComPanelContext } from "mobile/context/ComPanelContext";
import SendMessageInput from "../companel/SendMessageInput";
import Message from "../containers/techsupport/Message";
import Confirmation from "../modals/Confirmation";

function Chat({
	channel,
	messages,
	displayName,
	broadcastMessage,
	ownPlayfabId,
	activity,
	setHasChatted,
	hasChatted,
	writePlayerEvent,
	writeTelemetryEvent,
	acfPageOptions,
	clearNewMessages,
	messageAuthors,
	minimizeToggle,
	newSupportMessages,
	topic,
	joined_room,
	is_resolved,
	openDate,
	chatSupportJoinRoom,
	chatSupportLeaveRoom,
	clearMessages,
	requestSupport,
	triggerNoNewMessages,
	chatSupportConnect,
	chatSupportListen,
	chatSupportStopListen,
	closeSupportSession,
	playfabId,
	connection,
	supportOptions,
	triggerCancelled,
	createToast,
	setShowCompanelNavigation,
	showCompanelNavigation,
}) {
	const location = useLocation();
	const history = useHistory();
	const msgEnd = useRef(null);
	const messageWrapper = useRef(null);
	const chatInput = useRef(null);
	const [showInput, setShowInput] = useState(false);
	const [inputMessage, setInputMessage] = useState("");
	const [isScrollable, setIsScrollable] = useState(false);
	const [showCloseSupportModal, setShowCloseSupportModal] = useState(false);
	const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);
	const {
		request,
		setRequest,
		formSubmitted,
		setFormSubmitted,
		selectSupportValue,
		setSelectSupportValue,
	} = useContext(TechSupportContext);
	const { activeCompanelTab } = useContext(ComPanelContext);

	//Join the chat support channel if the user has submitted the form and has a displayname
	useEffect(() => {
		if (channel !== "" && displayName && formSubmitted) {
			chatSupportJoinRoom({
				channel,
				displayName,
				playfabId,
			});
		}
	}, [formSubmitted]);

	//Create a toast notification when the tech support representative joins the chat and the user is not on the support tab
	useEffect(() => {
		if (
			joined_room === true &&
			activeCompanelTab !== "SUPPORT" &&
			messages.length < 3
		) {
			createToast({
				content: acfPageOptions?.acf?.notification?.tech_support_available,
				type: "Toast",
				id: uuid(),
			});
		}
		return () => {};
	}, [joined_room]);

	//Listen to the chat support channel when connected to it
	useEffect(() => {
		if (connection) {
			chatSupportListen();
		}
	}, [connection]);

	//Focus the input text that sends message when it is displayed
	useEffect(() => {
		if (showInput) {
			chatInput.current.focus();
		}
	}, [showInput]);

	//Reset the chat support component when the user cancels the request or when the conversation is closed after termination
	function resetChatSupport() {
		setFormSubmitted(false);
		clearMessages();
		chatSupportLeaveRoom({
			channel,
		});
		closeSupportSession();
		chatSupportStopListen();
		setSelectSupportValue(supportOptions[0]?.option || "");
		setRequest("");
	}

	//Scroll to the bottom of the messages on initial render
	useEffect(() => {
		scrollToBottom();
	}, []);

	//Send message to chat
	function sendMessage(e) {
		e.preventDefault();

		if (inputMessage !== "") {
			if (!hasChatted.includes(activity?.StoreTileId)) {
				writePlayerEvent({
					name: PLAYFAB_EVENTS.player_meeting_event,
					body: {
						HasChatted: 1,
						RoomId: activity?.StoreTileId,
						Type: "message",
					},
				});
				setHasChatted(activity?.StoreTileId);
			}

			broadcastMessage({
				type: "userMessage",
				message: inputMessage,
				username: displayName,
				channel,
				playfabId: ownPlayfabId,
			});

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "chat",
				eventAction: "message sent",
				eventLabel: inputMessage,
			});

			writeTelemetryEvent({
				namespace: "custom.chatlogs",
				name: "message",
				body: {
					playfabid: ownPlayfabId,
					chatroomid: activity?.StoreTileId,
					chatroom: activity?.content?.title?.rendered,
					displayname: displayName,
					message: inputMessage,
				},
			});
		}

		//Empty the message input and refocus the field after sending the previous message
		setInputMessage("");
		setTimeout(() => {
			if (document.getElementById("input")) {
				document.getElementById("input").focus();
			}
		}, 500);
	}

	//Scroll to the bottom of the messages list
	function scrollToBottom() {
		setTimeout(() => {
			msgEnd.current.scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "nearest",
			});
		}, "3em");
		clearNewMessages();
	}

	//If the window should auto scroll to the bottom of the messages or not. Depends if the user scrolled up a distance to read previous messages
	function canScroll() {
		if (messageWrapper?.current) {
			const { scrollTop, scrollHeight, clientHeight } = messageWrapper.current;
			if (scrollHeight - clientHeight - scrollTop < 250) {
				scrollToBottom();
				setIsScrollable(true);
			} else {
				setIsScrollable(false);
			}
		}

		return null;
	}

	//Displays the close support modal
	function CloseSupport(value) {
		if (value === true) {
			resetChatSupport();
		}
		setShowCloseSupportModal(false);
	}
	//TODO: Delete this if not used
	/* function handleCloseSupportModal(e) {
		setShowCloseSupportModal(true);
	} */

	//Displays the cancel request modal
	function handleCancelRequestModal(e) {
		setShowCancelRequestModal(true);
	}

	//Cancel the chat support request after form submission
	function CancelRequest(value) {
		if (value === true) {
			resetChatSupport();
			broadcastMessage({
				type: "userMessage",
				message: "User cancelled the request.",
				username: "_REQUEST_CANCELLED",
				channel,
				playfabId: ownPlayfabId,
			});
			triggerCancelled();
		}
		setShowCancelRequestModal(false);
	}

	return (
		<div className="d-flex flex-column support-chat mt-2" ref={messageWrapper}>
			<div className="d-flex justify-content-between align-items-center">
				<span className="d-flex flex-direction-row align-items-center mx-3 my-1 support-topic">
					<TimerIcon className="timer-icon" />
					{acfPageOptions?.acf?.chat_support?.topic_title} {topic}
				</span>
			</div>
			<div className="messages-list">
				<Message
					channel={channel}
					id="initial-message"
					key="initial-message"
					displayName={displayName}
					type="chatMessage"
					canScroll={() => canScroll()}
					author={displayName}
					message={request}
					timestamp={toLocalTime(openDate)}
					acfPageOptions={acfPageOptions}
				/>
				{messages.length > 0 &&
					messages.slice(-30).map((m) => {
						return (
							<Message
								channel={channel}
								id={m.id}
								key={m.id}
								displayName={displayName}
								type="chatMessage"
								canScroll={() => canScroll()}
								acfPageOptions={acfPageOptions}
							/>
						);
					})}
				<button
					type="button"
					className="cancel-request pt-2"
					onClick={(e) => handleCancelRequestModal(e)}
				>
					{acfPageOptions?.acf?.chat_support?.cancel_request}
				</button>
				<div style={{ paddingTop: 180 }} ref={msgEnd} />
			</div>
			{!isScrollable && newSupportMessages > 0 && messages.length > 4 && (
				<button
					className="new-messages-btn p-2"
					type="button"
					onClick={() => scrollToBottom()}
				>{`${newSupportMessages} NEW ${
						newSupportMessages > 1 ? "MESSAGES" : "MESSAGE"
					}`}</button>
			)}
			{!minimizeToggle && (
				<>
					{!joined_room && is_resolved && (
						<div className="d-flex flex-row p-3 align-items-center">
							<div className="d-flex flex-column w-50">
								<span className="title">
									{acfPageOptions?.acf?.chat_support?.terminated_session}
								</span>
							</div>
							<button
								type="button"
								className="close-btn p-2"
								onClick={() => resetChatSupport()}
							>
								{acfPageOptions?.acf?.chat_support?.close_conversation}
							</button>
						</div>
					)}
					<SendMessageInput
						inputMessage={inputMessage}
						setInputMessage={setInputMessage}
						messageAuthors={messageAuthors}
						placeHolder={acfPageOptions?.acf?.chat_support?.send_input_placeholder}
						formAction={(e) => sendMessage(e)}
						disabled={!joined_room}
					/>
				</>
			)}
			{showCloseSupportModal && (
				<Confirmation
					onAnswer={(e) => CloseSupport(e)}
					text={acfPageOptions?.acf?.chat_support?.close_chat_support_message}
					modal={showCloseSupportModal}
					setModal={setShowCloseSupportModal}
					acfPageOptions={acfPageOptions}
				/>
			)}
			{showCancelRequestModal && (
				<Confirmation
					onAnswer={(e) => CancelRequest(e)}
					text={acfPageOptions?.acf?.chat_support?.cancel_chat_support_message}
					modal={showCancelRequestModal}
					setModal={setShowCancelRequestModal}
					acfPageOptions={acfPageOptions}
				/>
			)}
		</div>
	);
}

Chat.defaultProps = {
	channel: null,
	messages: [],
	displayName: null,
	ownPlayfabId: "",
	broadcastMessage: () => {},
	activity: null,
	hasChatted: null,
	setHasChatted: () => {},
	writePlayerEvent: () => {},
	writeTelemetryEvent: () => {},
	clearNewMessages: null,
	acfPageOptions: {},
	messageAuthors: null,
	minimizeToggle: false,
	newSupportMessages: 0,
	topic: "",
	joined_room: false,
	is_resolved: false,
	openDate: "",
	chatSupportJoinRoom: () => {},
	chatSupportLeaveRoom: () => {},
	clearMessages: () => {},
	requestSupport: () => {},
	triggerNoNewMessages: () => {},
	chatSupportConnect: () => {},
	chatSupportListen: () => {},
	chatSupportStopListen: () => {},
	closeSupportSession: () => {},
	playfabId: "",
	connection: false,
	supportOptions: [],
	triggerCancelled: () => {},
	createToast: () => {},
	showCompanelNavigation: false,
	setShowCompanelNavigation: () => {},
};

Chat.propTypes = {
	channel: PropTypes.string,
	messages: PropTypes.array,
	displayName: PropTypes.string,
	broadcastMessage: PropTypes.func,
	ownPlayfabId: PropTypes.string,
	activity: PropTypes.object,
	hasChatted: PropTypes.array,
	setHasChatted: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	clearNewMessages: PropTypes.func,
	acfPageOptions: PropTypes.object,
	messageAuthors: PropTypes.array,
	minimizeToggle: PropTypes.bool,
	newSupportMessages: PropTypes.number,
	topic: PropTypes.string,
	joined_room: PropTypes.bool,
	is_resolved: PropTypes.bool,
	openDate: PropTypes.string,
	chatSupportJoinRoom: PropTypes.func,
	chatSupportLeaveRoom: PropTypes.func,
	clearMessages: PropTypes.func,
	requestSupport: PropTypes.func,
	triggerNoNewMessages: PropTypes.func,
	chatSupportConnect: PropTypes.func,
	chatSupportListen: PropTypes.func,
	chatSupportStopListen: PropTypes.func,
	closeSupportSession: PropTypes.func,
	playfabId: PropTypes.string,
	connection: PropTypes.bool,
	supportOptions: PropTypes.array,
	triggerCancelled: PropTypes.func,
	createToast: PropTypes.func,
	showCompanelNavigation: PropTypes.bool,
	setShowCompanelNavigation: PropTypes.func,
};

export default Chat;