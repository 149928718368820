import React, { useState, useRef, useEffect } from "react";
import parse from "html-react-parser";
import PropTypes from "prop-types";

function AudioPlayer({ src, acfPageOptions, media }){
	// state
	const [isPlaying, setIsPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [currentVolume, setCurrentVolume] = useState(100);
	const [previousVolume, setPreviousVolume] = useState(100);

	const [muted, setMuted] = useState(false);

	// references
	const audioPlayer = useRef(); // reference our audio component
	const progressBar = useRef(); // reference our progress bar
	const animationRef = useRef(); // reference the animation
	const volumeBarRef = useRef(); //reference the volume bar

	useEffect(() => {
		const seconds = Math.floor(audioPlayer.current.duration);
		setDuration(seconds);
		progressBar.current.max = seconds;
	}, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

	const calculateTime = (secs) => {
		const minutes = Math.floor(secs / 60);
		const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const seconds = Math.floor(secs % 60);
		const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${returnedMinutes}:${returnedSeconds}`;
	};

	const togglePlayPause = () => {
		const prevValue = isPlaying;
		setIsPlaying(!prevValue);
		if (!prevValue) {
			audioPlayer.current.play();
			animationRef.current = requestAnimationFrame(whilePlaying);
		} else {
			audioPlayer.current.pause();
			cancelAnimationFrame(animationRef.current);
		}
	};

	const whilePlaying = () => {
		if (!audioPlayer.current) {
			return cancelAnimationFrame(whilePlaying);
		}
		progressBar.current.value = audioPlayer.current.currentTime;
		changePlayerCurrentTime();
		animationRef.current = requestAnimationFrame(whilePlaying);
		return null;
	};

	const changeRange = () => {
		audioPlayer.current.currentTime = progressBar.current.value;
		changePlayerCurrentTime();
	};

	const changeVolume = () => {
		audioPlayer.current.volume = volumeBarRef.current.value / 100;
		changePlayerCurrentVolume();
	};

	const toggleMute = () => {
		setMuted(!muted);
		setPreviousVolume(volumeBarRef.current.value);
		if (!muted) {
			setCurrentVolume(0);
			audioPlayer.current.volume = 0 / 100;
			volumeBarRef.current.style.setProperty("--seek-before-width-volume", `0%`);
			document.getElementsByClassName("volumeBar")[0].value = "0";
		} else {
			setCurrentVolume(previousVolume);
			audioPlayer.current.volume = previousVolume / 100;
			volumeBarRef.current.style.setProperty("--seek-before-width-volume", `${previousVolume}%`);
			document.getElementsByClassName("volumeBar")[0].value = previousVolume;
		}
	};

	const changePlayerCurrentTime = () => {
		progressBar.current.style.setProperty("--seek-before-width", `${(progressBar.current.value / duration) * 100}%`);
		setCurrentTime(progressBar.current.value);
	};

	const changePlayerCurrentVolume = () => {
		volumeBarRef.current.style.setProperty("--seek-before-width-volume", `${volumeBarRef.current.value}%`);
		setCurrentVolume(volumeBarRef.current.value);
	};

	const backThirty = () => {
		audioPlayer.current.currentTime -= 15;
		progressBar.current.value = audioPlayer.current.currentTime;
		changePlayerCurrentTime();
		changeRange();
	};

	const forwardThirty = () => {
		audioPlayer.current.currentTime += 15;
		progressBar.current.value = audioPlayer.current.currentTime;
		changePlayerCurrentTime();
		changeRange();
	};

	const onLoadedMetadata = () => {
		const seconds = Math.floor(audioPlayer.current.duration);
		setDuration(seconds);
		progressBar.current.max = seconds;
	};

	return (
		<div className='audioPlayer'>
			<audio ref={audioPlayer} src={src} preload='metadata' onLoadedMetadata={onLoadedMetadata} />

			<div className='player-top'>
				<div className='thumbnail'>
					<img
						alt='podcast-thumbnail'
						src={
							media?.acf?.content_gallery?.acf?.preview_image?.sizes?.large || media?.acf?.preview_image?.sizes?.large
						}
					/>
				</div>
				<div className='title'>
					<span>{(media?.title?.rendered && parse(media?.title?.rendered)) || media?.post_title}</span>
				</div>
			</div>
			<div className='player-bottom'>
				<div className='prograssbar-container'>
					<button type='button' aria-label="Backward" className='forwardBackward' onClick={backThirty}>
						<img
							className='normalIcon'
							src={acfPageOptions.acf?.theme?.icon?.gallery?.audio_player?.backward?.sizes?.thumbnail}
							alt=''
						/>
					</button>
					{/* current time */}
					<div className='currentTime'>{calculateTime(currentTime)}</div>

					{/* progress bar */}

					<input type='range' className='progressBar' defaultValue='0' ref={progressBar} onChange={changeRange} />

					{/* duration */}
					<div className='duration'>{duration && !isNaN(duration) && calculateTime(duration)}</div>
					<button type='button' aria-label="Forward" className='forwardBackward' onClick={forwardThirty}>
						<img
							className='normalIcon'
							src={acfPageOptions.acf?.theme?.icon?.gallery?.audio_player?.forward?.sizes?.thumbnail}
							alt=''
						/>
					</button>
					{/*volume bar*/}
					<div className='volume-container'>
						<button type='button' aria-label="Mute" onClick={toggleMute} className='muteVolume'>
							{currentVolume <= 0 ? (
								<span className='mute'>
									<img
										src={acfPageOptions.acf?.theme?.icon?.gallery?.audio_player?.mute?.sizes?.thumbnail}
										alt='video icon'
									/>
								</span>
							) : (
								<span className='volume'>
									<img
										src={acfPageOptions.acf?.theme?.icon?.gallery?.audio_player?.volume?.sizes?.thumbnail}
										alt='video icon'
									/>
								</span>
							)}
						</button>
						<input type='range' className='volumeBar' defaultValue='100' ref={volumeBarRef} onChange={changeVolume} />
					</div>
					<span className='volume-level'>{currentVolume}</span>
					<button type='button' aria-label="PlayPause" onClick={togglePlayPause} className='playPause'>
						{isPlaying ? (
							<img
								src={acfPageOptions.acf?.theme?.icon?.gallery?.audio_player?.pause?.sizes?.thumbnail}
								alt='video icon'
							/>
						) : (
							<img src={acfPageOptions.acf?.theme?.icon?.gallery?.audio_player?.play?.sizes?.thumbnail} alt='video icon' />
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

AudioPlayer.defaultProps = {
	src: null,
	acfPageOptions: null,
	media: null,
};

AudioPlayer.propTypes = {
	src: PropTypes.string,
	acfPageOptions: PropTypes.object,
	media: PropTypes.object,
};

export { AudioPlayer };
