import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { ReactComponent as LockIcon } from "assets/icons/pack1/gamification/icon-lock.svg";
import { ReactComponent as CloseIcon } from "assets/icons/pack1/navigation/collapsable-section/icon-close.svg";

function Details({
	setViewedElement,
	setShowDetails,
	showDetails,
	viewedElement,
	acfPageOptions,
	type,
}) {
	function closeModal() {
		setShowDetails(false);
		setViewedElement(null);
	}
	return (
		showDetails && (
			<div className="details">
				<Modal.Dialog className="p-4">
					<Modal.Header>
						<button type="button" aria-label="closeModalMobile" onClick={() => closeModal()}>
							<CloseIcon />
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className="d-flex flex-row p-3 justify-content-between">
							{type === "badge" ? (
								<>
									<div className="d-flex flex-column px-1 align-items-center text-center badge-image-container">
										<img
											className={`rounded-circle ${
												viewedElement?.isInInventory ? "unlocked" : "locked"
											}`}
											src={viewedElement?.data?.icon}
											alt="badge icon"
										/>
										{!viewedElement?.isInInventory && (
											<span className="position-absolute rounded-circle text-center locked-icon">
												<LockIcon />
											</span>
										)}
										<span className="title">{viewedElement?.data?.title}</span>
										<span className="mb-1 points">12 PTS</span>
									</div>
									<div className="d-flex flex-column px-1 details-description">
										<p>Lorem ipsum, dolor sit amet consectetur</p>
										<span>{acfPageOptions.acf?.profile?.badges_tab?.badge_how_to}</span>
										<p>{viewedElement?.data?.description}</p>
									</div>
								</>
							) : (
								<>
									<div className="d-flex flex-column px-1 align-items-center text-center badge-image-container">
										<img
											className="rounded-circle"
											src={viewedElement?.image}
											alt="badge icon"
										/>
										<span className="title">{viewedElement?.title}</span>
									</div>
									<div className="d-flex flex-column px-1 details-description">
										<span className="mb-1">{viewedElement?.fine_print}</span>
										<p>{viewedElement?.description}</p>
									</div>
								</>
							)}
						</div>
					</Modal.Body>
				</Modal.Dialog>
			</div>
		)
	);
}

Details.defaultProps = {
	viewedElement: null,
	showDetails: null,
	setShowDetails: () => {},
	setViewedElement: () => {},
	acfPageOptions: null,
	type: "",
};

Details.propTypes = {
	viewedElement: PropTypes.object,
	showDetails: PropTypes.bool,
	setShowDetails: PropTypes.func,
	setViewedElement: PropTypes.func,
	acfPageOptions: PropTypes.object,
	type: PropTypes.string,
};

export default Details;
