import React, { useState } from "react";
import { number, func } from "prop-types";
import TriviaIndexModal from "../modals/TriviaIndex";

function Summary({ points, setShowLeaderboard }) {
	const [showModal, setShowModal] = useState(true);

	const handleClose = () => {
		setShowModal(false);
	};

	const handleShowLeaderboard = () => {
		setShowLeaderboard(true);
	};

	return (
		<TriviaIndexModal
			modal={showModal}
			setModal={handleClose}
			onHide={handleClose}
			className="summary-modal"
		>
			<div className="modal-content">
				<div className="summary-text-message">
					CONGRATULATIONS <br />
					YOU HAVE EARNED!
				</div>

				{points && (
					<div className="summary-points-wrapper">
						<div>
							<div className="summary-points">{points}</div>

							<div className="summary-points-text">PTS</div>
						</div>
					</div>
				)}
				<div
					role="button"
					tabIndex="-1"
					onKeyPress={handleShowLeaderboard}
					onClick={handleShowLeaderboard}
					className="summary-leaderboard-text"
				>
					Check the leaderboard
				</div>
			</div>
		</TriviaIndexModal>
	);
}

Summary.propTypes = {
	points: number,
	setShowLeaderboard: func,
};

Summary.defaultProps = {
	points: null,
	setShowLeaderboard: () => {},
};

export default Summary;
