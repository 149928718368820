import React, { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useStore } from "react-redux";

export const SessionContext = createContext();

export function SessionContextProvider({ children }) {
	const state = useStore().getState();
	const [fullScreen, setFullScreen] = useState(false);
	const [sessionAttendanceType, setSessionAttendanceType] = useState(state?.playfab?.PlayerData?.AttendanceType?.Value || "virtual");
	const [minimizeToggle, setMinimizeToggle] = useState(false);
	const sessionProviderValue = useMemo(
		() => ({ fullScreen, setFullScreen, sessionAttendanceType, setSessionAttendanceType, minimizeToggle, setMinimizeToggle }),
		[fullScreen, sessionAttendanceType, minimizeToggle]
	);

	return (
		<SessionContext.Provider value={sessionProviderValue}>
			{children}
		</SessionContext.Provider>
	);
}

SessionContextProvider.defaultProps = {
	children: null,
};

SessionContextProvider.propTypes = {
	children: PropTypes.array,
};
