/** @format */
import React from "react";
import PropTypes from "prop-types";
import Status from "containers/Pages/Status";

function NotFound({ acfPageOptions, acfNavigation }) {
	if (!acfPageOptions?.acf || !acfNavigation?.acf) return null;
	
	return (
		<Status
			// TODO: set title in WP
			title="404 - PAGE NOT FOUND"
			logo="https://wp.cf.holoscene.events/demo/2022/04/13145112/logo-404.png"
			description=" " //NOTE: Space required to override default
			homeLink
			type='404'
		/>
	);
}

NotFound.defaultProps = {
	acfPageOptions: null,
	acfNavigation: null,
};

NotFound.propTypes = {
	acfPageOptions: PropTypes.object,
	acfNavigation: PropTypes.any,
};

export default NotFound;
