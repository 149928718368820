import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Button from "components/Buttons/Button";
import { ROUTES, REACT_APP_LIGHTSPEED_CLIENT } from "Constants";
import {
	now,
	getDayTwoDigits,
	getTimeStyleShort,
	getMinuteNumeric,
	getHourTwoDigits,
	getWeekdayShort,
	getMonthShort,
	getWeekdayLong,
} from "utils/dateUtils";

function ChannelTile({
	id,
	stock,
	small,
	noHours,
	withDate,
	link,
	activity,
	className,
	purchaseTile,
	writePlayerEvent,
	writeTelemetryEvent,
	getItemInventory,
	acfPageOptions,
	lang,
	onMouseEnter,
	onMouseLeave,
	getStoreLoadout,
	groupLang,
	history,
	isAdmin,
}) {
	if (!activity) return null;

	const activityType = activity.data.activity_type;
	const dayNum = getDayTwoDigits(activity.date_begin.$d, groupLang);
	const hourNum = getHourTwoDigits(activity.date_begin.$d, groupLang);
	const MinNum = getMinuteNumeric(activity.date_begin.$d, groupLang);
	const dayShortString = getWeekdayShort(activity.date_begin.$d, groupLang);
	const monthString = getMonthShort(activity.date_begin.$d, groupLang);
	const dayString = getWeekdayLong(activity.date_begin.$d, groupLang);
	const fullTimeStart = getTimeStyleShort(activity.date_begin.$d, groupLang);
	const isPast = activity.date_end.isBefore(now());
	const [loading, setLoading] = useState(false);

	function unregister() {
		setLoading(true);
		writePlayerEvent({
			name: "player_unsubscribed_activity",
			body: {
				ItemInstanceId: activity.item_instance_id,
			},
		}).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 2000),

			setTimeout(() => {
				getItemInventory();
			}, 2300),

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "remove from agenda",
				eventAction: activity?.content?.title?.rendered,
			})
		);
	}

	function purchase() {
		setLoading(true);
		purchaseTile(id).then(
			setTimeout(() => {
				getStoreLoadout();
			}, 500),

			setTimeout(() => {
				getItemInventory();
			}, 1000),

			//Engagement event on arriving
			writeTelemetryEvent({
				namespace: "custom.engagement",
				name: "subscribe",
				body: {
					activity: activity?.content?.title?.rendered,
					StoreTileId: activity.StoreTileId,
				},
			}),

			window.dataLayer.push({
				event: "generic_event",
				eventCategory: "add from agenda",
				eventAction: activity?.content?.title?.rendered,
			})
		);
	}

	function requiredCheck(activity_id) {
		let isRequired = false;
		const granted_items = acfPageOptions.acf?.global?.granted_items;
		if (acfPageOptions.acf?.global?.granted_items) {
			for (let i = 0; i < granted_items?.length; i += 1) {
				if (granted_items[i].tile_id === activity_id.StoreTileId) {
					isRequired = true;
					break;
				}
			}
		}
		return isRequired;
	}

	useEffect(() => {
		if (loading) {
			return setLoading(false);
		}
		return null;
	}, [activity.canPurchase, activity.canUnregister]);

	function renderStock() {
		if (stock === null) return null;
		const pluralPhrasing = `${stock} ${
			lang === "en" ? "places left" : "places disponibles"
		}!`;
		const singularPhrasing = `Only ${stock} ${
			lang === "en" ? "place left" : "place disponible"
		}!`;

		return stock > 0 ? (
			<div className="stock">
				{stock > 1 ? pluralPhrasing : singularPhrasing}
			</div>
		) : (
			<div className="stock">
				{acfPageOptions.acf?.activity_card_no_place_left}
			</div>
		);
	}

	function renderPartners() {
		if (!activity.content?.acf?.host) return null;
		return <span className="hostedby">{parse(activity.content.acf.host)}</span>;
	}

	function renderDate() {
		if (small) {
			return (
				<div className="date">
					<div className="day">
						<h4 className="dayNum">{dayNum}</h4>
						<div className="day-month-container">
							<span className="dayWeek">{dayString.toUpperCase()}</span>
							<span className="monthString">{monthString.toUpperCase()}</span>
						</div>
					</div>
					<div className="hours">
						<span className="bigNum">{hourNum}</span>
						{/*TODO: Remove with the new agenda merged*/}
						{/* <span className="hour-label">H</span> */}
						<span className="bigNum minutes">
							{MinNum === "0" ? "" : MinNum}
						</span>
						{/*TODO: Remove with the new agenda merged*/}
						{/* <span className="cycle-label">{cycle.toUpperCase()}</span> */}
					</div>
				</div>
			);
		}
		if (noHours) {
			return (
				<div className="date">
					<div className="day">
						<span className="dayWeek">{dayString}</span>
						<span className="comma">,</span>
						<span className="monthString">{monthString}</span>
						<span className="dayNum bigNum">{dayNum.toUpperCase()}</span>
					</div>
				</div>
			);
		}
		if (withDate) {
			return (
				<div className="date">
					<div className="hours">
						<span className="bigNum">{hourNum}</span>
						<span className="hour-label">:</span>
						<span className="bigNum minutes">{MinNum}</span>
						{/*TODO: Remove with the new agenda merged*/}
						{/* <span className="cycle-label">{cycle}</span> */}
					</div>
					<div className="day">
						<span className="dayWeek">{dayString}</span>
						<span className="monthString">{monthString}</span>
						<span className="dayNum bigNum">{dayNum}</span>
					</div>
				</div>
			);
		}

		return (
			<div className="date">
				<div className="day">
					<h4 className="dayNum">{dayNum}</h4>
					<div className="day-month-container">
						<span className="dayWeek">{dayShortString.toUpperCase()}</span>
						<span className="monthString">{monthString.toUpperCase()}</span>
					</div>
				</div>
				<div className="time align-items-end">
					<h4>{fullTimeStart}</h4>
				</div>
			</div>
		);
	}

	function triggerClickEvent(eventLink, eventName) {
		window.dataLayer.push({
			event: "generic_event",
			eventCategory: "event",
			eventAction: "enter event",
			eventLabel: eventName,
		});

		history.push(eventLink);
	}

	function renderLink() {
		if (!link) return null;

		return (
			<Link to={ROUTES.EVENT.replace(":tileId", id)} className="overlay-link" />
		);
	}

	function renderStockRelatedButtons() {
		if (isPast) return null;

		return (
			<>
				{activity.canPurchase && (
					<Button
						action={() => purchase()}
						classes="secondary large add-to-agenda"
						icon={
							acfPageOptions.acf?.theme?.icon?.activity_card?.agenda?.add?.sizes
								?.thumbnail
						}
						text={acfPageOptions?.acf?.activity_card_add_to_agenda}
					/>
				)}
				{activity.canUnregister && !requiredCheck(activity) && (
					<Button
						action={() => unregister()}
						classes="secondary large remove-from-agenda"
						icon={
							acfPageOptions.acf?.theme?.icon?.activity_card?.agenda?.remove
								?.sizes?.thumbnail
						}
						text={acfPageOptions?.acf?.activity_card_remove_from_agenda}
					/>
				)}

				{renderStock()}
			</>
		);
	}

	const pastClass = isPast ? "past" : "";
	const smallClass = small ? "small" : "";
	const withDateClass = withDate ? "withDate" : "";
	const teamsPrivateClass =
		activityType === "TeamsPrivateRoom" ? "teams-private" : "";

	const getColorClass = (tileColor) => {
		switch (tileColor) {
			case "1":
				return "primary-gradient-background";
			case "2":
				return "secondary-gradient-background";
			case "3":
				return "tertiary-gradient-background";
			default:
				return "primary-gradient-background";
		}
	};

	const colorClass = getColorClass(activity?.colorCode);

	return (
		<div
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={`activity-card ${colorClass} ${smallClass} ${withDateClass} ${pastClass} ${teamsPrivateClass} ${className}`}
		>
			<div className="infos">
				<div className="infos-content">
					<div className="tile-info">{renderPartners()}</div>

					<h4 className="tile-title">
						{activity?.content?.title?.rendered &&
							parse(activity?.content?.title?.rendered)}
					</h4>
					<div className="agenda-only">{renderPartners()}</div>
				</div>
				{activity?.content?.acf?.icon?.sizes?.thumbnail && (
					<div className="icon-ctn">
						<img src={activity?.content?.acf?.icon?.sizes?.thumbnail} alt="" />
					</div>
				)}
				<div className="tile-bottom">
					{renderDate()}

					<div className="actions">
						{loading ? (
							<div>{acfPageOptions?.acf?.activity_card_loading}</div>
						) : (
							<div className="actions-wrapper">
								{(activity.canJoin || isAdmin) && (
									<Button
										action={() =>
											triggerClickEvent(
												ROUTES.ACTIVITY.replace(":tileId", id),
												activity?.content?.title?.rendered
											)
										}
										classes="primary large"
										icon={
											acfPageOptions?.acf?.theme?.icon?.activity_card?.join
												?.image_1?.sizes?.thumbnail
										}
										icon2={
											acfPageOptions?.acf?.theme?.icon?.activity_card?.join
												?.image_2?.sizes?.thumbnail
										}
										text={acfPageOptions.acf?.activity_card_join_now}
									/>
								)}

								{/* <div className="remaining">
									{activity.date_begin.isValid() && !activity.date_begin.isBefore(now()) ?
										<span>{`${startString} ${timeFromNow(activity.date_begin)}`}</span> :
										activity.date_end.isValid() && <span>{`${endString} ${timeFromNow(activity.date_end)}`}</span>}
								</div> */}
								{!REACT_APP_LIGHTSPEED_CLIENT && (
									<div id="activity-buttons">{renderStockRelatedButtons()}</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			{acfPageOptions?.acf?.home?.link_to_event_page && renderLink()}
		</div>
	);
}

ChannelTile.defaultProps = {
	id: "",
	stock: null,
	small: false,
	noHours: false,
	withDate: false,
	link: false,
	activity: {},
	className: null,
	acfPageOptions: null,
	purchaseTile: () => {},
	writePlayerEvent: () => {},
	writeTelemetryEvent: () => {},
	onMouseEnter: () => {},
	onMouseLeave: () => {},
	getStoreLoadout: () => {},
	lang: "en",
	groupLang: null,
	isAdmin: false,
};

ChannelTile.propTypes = {
	id: PropTypes.string,
	stock: PropTypes.number,
	small: PropTypes.bool,
	noHours: PropTypes.bool,
	withDate: PropTypes.bool,
	link: PropTypes.bool,
	className: PropTypes.string,
	activity: PropTypes.object,
	acfPageOptions: PropTypes.object,
	purchaseTile: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	writePlayerEvent: PropTypes.func,
	writeTelemetryEvent: PropTypes.func,
	getStoreLoadout: PropTypes.func,
	getItemInventory: PropTypes.func.isRequired,
	lang: PropTypes.string,
	groupLang: PropTypes.string,
	history: PropTypes.object.isRequired,
	isAdmin: PropTypes.bool,
};

export default ChannelTile;
