function sendEvent(event = '', data = {}) {
	window.gtag('event', event, data);
}

function pageView(route = '') {
	window.gtag('config', window.UA_TAG_ID, { page_path: route });
}

function setUserProperties(properties = {}) {
	window.gtag('set', 'user_properties', {
		...properties,
	});
}

export default {
	sendEvent,
	setUserProperties,
	pageView,
};
