/* eslint-disable */
import React from "react";
import { useEffect } from "react";
import { now, getTimeStyleShort } from "utils/dateUtils";
import { ReactComponent as NavigationLeft } from "assets/icons/pack1/calendar/navigation_left.svg";
import { ReactComponent as NavigationRight } from "assets/icons/pack1/calendar/navigation_right.svg";
import { getHalfHour } from "utils/getHalfHour";
import dayjs from "dayjs";

const CalendarDesktop = ({ days, activeDay, setActiveDay }) => {
	const minutes = dayjs().get("minute");

	useEffect(() => {
		if (days && days.length && !activeDay) {
			const today = now();
			const todayInDays = days.find((day) => day.isSame(today, "day"));

			setActiveDay(todayInDays || days[0]);
		}
	}, [days]);

	useEffect(() => {
		renderTimeLine();

		document.getElementById("timeline").scrollIntoView({behavior: "smooth",
		block: "start",
		inline: "start"});
	}, [minutes]);

	const navigateToNextDirection = (direction) => {
		if (direction === "right") {
			window.scrollBy({ left: window.innerHeight, behavior: "smooth" });
		} else {
			window.scrollBy({ left: -window.innerHeight, behavior: "smooth" });
		}
	};

	const renderCalendarHours = () => {
		const data = getHalfHour();

		return data?.map((timeslot, _) => {
			return (
				<div className="time-slot" key={timeslot}>
					<p className="date-timeline">
						{getTimeStyleShort(timeslot.$d, "en-US")}
					</p>
					<div className="schedule-grid" />
				</div>
			);
		});
	};

	const renderTimeLine = () => {
		const hours = dayjs().get("hour");
		const minutes = dayjs().get("minute");

		const left = ((hours + minutes / 100) / 24) * 100;

		return <div id="timeline" className="timeline" style={{ left: `${left}%` }} />;
	};

	return (
		<div className="calendar-container">
			<div className="time-slot-grid">
				{renderCalendarHours()}
				{renderTimeLine()}
			</div>
			<button
				className="left-button"
				onClick={() => navigateToNextDirection("left")}
			>
				<NavigationLeft className="left-button" />
			</button>
			<button
				className="right-button"
				onClick={() => navigateToNextDirection("right")}
			>
				<NavigationRight className="right-button" />
			</button>
		</div>
	);
};

export default CalendarDesktop;
