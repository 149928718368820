import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	voteQuestion,
	getQuestions,
	fetchQuestion,
	clearNewQuestions,
	postQuestion,
} from "redux/chat";
import { userNameSelector } from "selector/player";
import { questionsAcceptedSelector } from "selector/chat";
import { writePlayerEvent } from "redux/playfab";
import { createToast } from "redux/toastNotifications";
import Qa from "../../companel/qa/Index";

function mapStateToProps(state) {
	return {
		channel: state.chat?.channel,
		displayName: userNameSelector(state, {
			fallback: state.teams.userPrincipalName,
		}),
		newQuestions: state.chat?.newQuestions,
		questions: questionsAcceptedSelector(state),
		fetchQuestions: state.chat?.fetch,
	};
}

export default connect(mapStateToProps, (dispatch) =>
	bindActionCreators(
		{
			voteQuestion,
			getQuestions,
			fetchQuestion,
			clearNewQuestions,
			postQuestion,
			writePlayerEvent,
			createToast,
		},
		dispatch
	)
)(Qa);
