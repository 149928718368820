import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { getCatalog, writeTelemetryEvent } from 'redux/playfab';
import HomeHeader from 'mobile/components/home/Header';

function mapStateToProps(state, props) {
	return {
		acfPageOptions: state.wpcontent.acfPageOptions,
		lang: state.env.lang,
	};
}

export default withRouter(connect(
	mapStateToProps,
	dispatch => bindActionCreators({
		getCatalog,
		writeTelemetryEvent,
	}, dispatch)
)(HomeHeader));
